import { useEffect } from 'react';
import i18n from 'i18next';
import { errorToast } from '../../utils/toast';
import { SiteData } from '../../types/sites';
import { useSpecificSiteAttribute } from '../useSpecificSiteAttribute';
import { VARIANT } from '../../configs/siteAndTrustAttributes';

const DEFAULT_VARIANT = 'phlebotomy';

const addOrReplaceTranslationResources = async (variant: string) => {
  try {
    const { variantResources } = await import(`../../locales/variantResources`);
    const specificVariantResources = variantResources[variant];

    Object.keys(specificVariantResources).forEach((lng) => {
      Object.keys(specificVariantResources[lng]).forEach(async (ns: string) => {
        if (i18n.hasResourceBundle(lng, ns)) {
          // Remove old bundle if one loaded already
          i18n.removeResourceBundle(lng, ns);
        }
        // @ts-ignore
        i18n.addResourceBundle(lng, ns, specificVariantResources[lng][ns]);
      });
    });
  } catch (error) {
    console.error('Error adding translation resources:', error);
    errorToast('Error adding translation resources:');
  }
};

export const useHandleSiteTranslationResource = (site?: SiteData) => {
  const variant = useSpecificSiteAttribute(site, VARIANT);
  useEffect(() => {
    if (site) {
      addOrReplaceTranslationResources(variant?.value_str || DEFAULT_VARIANT);
    }
  }, [site, variant]);
};
