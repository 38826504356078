import React, { ReactElement } from 'react';
import {
  ITimePlannerHeaderProps,
  TimePlannerTopControl,
} from './TimePlannerTopControl';
import { ITimePlannerBodyProps, TimePlannerBody } from './TimePlannerBody';
import { isDefaultFullWeekTemplateDay } from '../../utils/apptTemplateUtils';

type Props = ITimePlannerBodyProps & ITimePlannerHeaderProps;

/**
 * Selected date is set. This defines how the component behaves and
 * makes it rather complex. Consider refactor.
 */
export const TimePlanner = ({
  showOnline,
  selectedDate,
  setSelectedDate,
  dateFormat,
  filterDate,
  minDate,
  maxDate,
  ...bodyProps
}: Props): ReactElement => {
  return (
    <>
      {isDefaultFullWeekTemplateDay(selectedDate) ? (
        <div
          style={{
            marginBottom: 'var(--s3)',
          }}
        />
      ) : (
        <TimePlannerTopControl
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          dateFormat={dateFormat}
          filterDate={filterDate}
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
      <TimePlannerBody
        showOnline={showOnline}
        selectedDate={selectedDate}
        {...bodyProps}
      />
    </>
  );
};
