import Popup from 'reactjs-popup';
import styled, { css } from 'styled-components';
import { cssIf } from 'styles/partials/cssIf';
import { toRem } from 'utils/helpers';

export const StyledPopup = styled(Popup)`
  &-overlay {
    overflow: auto;
    &::before {
      content: '';
      background-color: var(--textprimary);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.6;
    }
  }
  &-content {
    background-color: transparent;
    width: 100%;
    max-width: ${toRem(640)}rem;
    & > div {
      overflow: hidden;
    }
  }
`;
export const PopupIconWrapper = styled.div<{
  readonly status?: string;
  readonly inlineIcon?: boolean;
}>`
  text-align: center;
  font-size: ${toRem(52)}rem;
  margin: auto auto var(--s3);
  width: ${toRem(94)}rem;
  height: ${toRem(94)}rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--white);
  background-color: ${({ status }) =>
    status === 'delete'
      ? 'var(--rednegative)'
      : status === 'warning'
      ? 'var(--yellowaction)'
      : 'var(--greenprimary)'};
  ${({ inlineIcon, status }) =>
    inlineIcon &&
    css`
      width: auto;
      height: auto;
      font-size: ${toRem(94)}rem;
      background-color: transparent;
      color: ${status === 'delete'
        ? 'var(--rednegative)'
        : status === 'warning'
        ? 'var(--yellowaction)'
        : 'var(--greenaccent)'};
    `}
`;
type PropsWithCompact = { compact?: boolean; uppercase?: boolean };
export const PopupTitle = styled.h5<PropsWithCompact>`
  font-size: var(--h3);
  text-align: center;
  margin: 0 0 var(--s3);
  text-transform: ${({ uppercase, compact }): 'uppercase' | 'none' => {
    if (typeof uppercase === 'boolean') {
      return uppercase ? 'uppercase' : 'none';
    } else {
      return compact ? 'none' : 'uppercase';
    }
  }};
  font-weight: 500;
  ${cssIf('compact')`
    margin-bottom: var(--s2);
  `}
`;
export const PopupDesc = styled.p<PropsWithCompact>`
  font-size: var(--body);
  text-align: center;
  margin: 0 0 var(--s4);
  ${cssIf('compact')`
    margin-bottom: var(--s3);
  `}
`;
