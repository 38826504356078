import DatePickerInput from 'components/common/Forms/DatePickerInput';
import { GlobalInputProps } from 'components/common/Forms/Input';
import React, { ReactElement } from 'react';
import { FilterProps } from 'react-table';
import { useEffectOnce } from 'react-use';

type CustomInputProps = Omit<GlobalInputProps, 'name'> & { name?: string };

interface Props extends FilterProps<object> {
  inputProps?: CustomInputProps;
}

export default function FilterByDatePicker({
  column: { filterValue, setFilter, id },
  inputProps,
}: Props): ReactElement {
  const inputName = inputProps?.name || id;
  useEffectOnce(() => {
    if (inputProps?.defaultValue) {
      setFilter(inputProps.defaultValue);
    }
  });
  return (
    <DatePickerInput
      {...inputProps}
      noSpaceForHelperText
      id={id}
      selected={filterValue}
      className="head-date-picker"
      name={inputName}
      onChange={(value) => {
        setFilter(value || undefined);
      }}
    />
  );
}
