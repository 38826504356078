import {
  DialogFormWrapper,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import { CardLoader } from 'components/common/Loading';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FaUserCheck } from 'react-icons/fa';

import Input from '../../../../components/common/Forms/Input';
import { SUPPORT_EMAILS_ATTRIBUTE } from '../../../../configs/siteAndTrustAttributes';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { emailPatternSeparatedBySpaces } from '../../../../utils/helpers';
import { UseGeneralSitePreferencesFormReturn } from './GeneralSitePreferencesHooks';
import { ATTRIBUTE_SEPARATOR } from '../../../../configs/constants';

interface Props {
  isFetching: boolean;
  attributesForm: UseGeneralSitePreferencesFormReturn;
}

export const SettingsSupport: React.FC<Props> = ({
  isFetching,
  attributesForm,
}) => {
  const { t } = useCommonTranslation();

  const {
    attributeRegister,
    control,
    formState: { errors },
  } = attributesForm;
  const watchedValues = useWatch({ control });

  return (
    <DialogFormWrapper>
      <FormHeader>
        <FaUserCheck />
        <FormTitle>{t('support')}</FormTitle>
      </FormHeader>
      <FormRow>
        <Input
          type="string"
          {...attributeRegister(
            SUPPORT_EMAILS_ATTRIBUTE,
            (e) => e.target.value,
            {
              pattern: {
                value: emailPatternSeparatedBySpaces,
                message: t(
                  'must-valid-email-addresses-space-separated',
                ) as string,
              },
            },
          )}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === SUPPORT_EMAILS_ATTRIBUTE,
            )?.value_str
          }
          label={t('support-emails')}
          placeholder={t('support-emails-placeholder')}
          hasError={Boolean(
            errors[
              (ATTRIBUTE_SEPARATOR + SUPPORT_EMAILS_ATTRIBUTE) as 'attributes'
            ],
          )}
          helperText={
            errors[(ATTRIBUTE_SEPARATOR + SUPPORT_EMAILS_ATTRIBUTE) as 'id']
              ?.message
          }
        />
      </FormRow>
      {isFetching && <CardLoader fillWrapper={true} />}
    </DialogFormWrapper>
  );
};
