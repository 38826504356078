import {
  useChangeUserPasswordMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
} from '../../query/users';
import { StaffSignupFormValues } from '../../types/users';
import { getUserFormData } from '../../utils/user';
import { useSite } from '../../context/SiteContext';
import { useCallback } from 'react';
import {
  firebaseDeleteUser,
  secondaryFirebaseApp,
} from '../../services/firebase';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { errorToast } from '../../utils/toast';

const generateRandomPassword = () => Math.random().toString(36).slice(-8);

const firebaseSignupWithoutSignIn = async (email: string, password: string) => {
  return await createUserWithEmailAndPassword(
    getAuth(secondaryFirebaseApp),
    email,
    password,
  );
};

export const useStaffUserCreateUpdate = ({
  onFinished,
}: {
  onFinished?: () => unknown;
} = {}) => {
  const { mutateAsync: createUser, isLoading: isCreateUserLoading } =
    useCreateUserMutation();
  const { mutateAsync: updateUser, isLoading: isUpdateUserLoading } =
    useUpdateUserMutation();
  const { mutateAsync: updatePassword, isLoading: isPasswordLoading } =
    useChangeUserPasswordMutation();

  const { activeSite } = useSite();

  const staffUserCreate = useCallback(
    async (data: StaffSignupFormValues) => {
      let password = data.confirmPassword || generateRandomPassword();
      const firebaseUser = await firebaseSignupWithoutSignIn(
        data.email,
        password,
      );
      const { userInfo } = getUserFormData({
        ...data,
        disabilities: 0,
        active_site_id: activeSite?.id,
      });
      try {
        await createUser({
          ...userInfo,
          role_id: data.role_id,
          active_site_id: userInfo.active_site_id!,
        });
      } catch (e) {
        try {
          await firebaseDeleteUser(firebaseUser.user);
          return e;
        } catch (e) {
          throw e;
        }
      }
    },
    [createUser, activeSite],
  );

  const staffUserUpdate = useCallback(
    async (data: StaffSignupFormValues) => {
      const { userInfo } = getUserFormData({
        ...data,
        disabilities: 0,
        active_site_id: activeSite?.id,
      });
      await updateUser(userInfo);
      if (data.confirmPassword) {
        await updatePassword({
          id: userInfo.id!!,
          password: data.confirmPassword,
        });
      }
    },
    [activeSite, updatePassword, updateUser],
  );

  const staffUserCreateOrUpdate: (
    data: StaffSignupFormValues,
    isUpdateUser: boolean,
  ) => Promise<void> = async (data, isUpdateUser) => {
    try {
      let handledError = undefined;
      if (isUpdateUser) {
        handledError = await staffUserUpdate(data);
      } else {
        handledError = await staffUserCreate(data);
      }
      if (handledError === undefined && onFinished) {
        onFinished();
      }
    } catch (e) {
      errorToast(e);
    }
  };

  return {
    staffUserCreateOrUpdate,
    isLoading: isPasswordLoading || isCreateUserLoading || isUpdateUserLoading,
  };
};
