import { UserMemberRelation } from '../../types/authentication';
import { RELATIONSHIPS } from '../../configs/constants';
import { useCommonTranslation } from '../i18n/useCommonTranslation';
import { useMemo } from 'react';

export const useMemberRelationLabel = (relation?: UserMemberRelation) => {
  const { t } = useCommonTranslation();
  const relations = RELATIONSHIPS(t);
  return useMemo(() => {
    return (relation !== undefined && relations[relation]) || 'unknown';
  }, [relation, relations]);
};
