import { useMemo } from 'react';
import {
  DEPARTMENT_LEAD,
  GP_SURGERY,
  RECEPTIONIST,
  ROLE_OFFSITE_RECEPTIONIST,
  ROLE_SPECIALIST_PLUS_WITH_REPORTS,
  SPECIALIST,
  SPECIALIST_PLUS,
  THIRD_PARTY,
  UserRoleId,
} from '../../../../configs/constants';
import { useRole } from '../../../../context/RoleContext';
import { useSite } from '../../../../context/SiteContext';
import { useAllGPSurgeries } from '../../../../hooks/referrals/useAllGPSurgeries';
import { useSiteBooleanAttributes } from '../../../../hooks/useAttribute';
import { gpSurgeryRequiredTrust } from '../../../../utils/trustUtil';
import { SITE_ATTRIBUTE_ALLOW_GP_SURGERY_ROLE } from '../../../../configs/siteAndTrustAttributes';

export const useStaffRolesAndGPData = () => {
  const { activeTrust } = useSite();
  const { allRoles } = useRole();

  const [allowGPRole] = useSiteBooleanAttributes(
    SITE_ATTRIBUTE_ALLOW_GP_SURGERY_ROLE,
  );
  const renderedRoles: UserRoleId[] = [
    RECEPTIONIST,
    ROLE_OFFSITE_RECEPTIONIST,
    THIRD_PARTY,
    SPECIALIST,
    SPECIALIST_PLUS,
    ROLE_SPECIALIST_PLUS_WITH_REPORTS,
    DEPARTMENT_LEAD,
  ];
  if (allowGPRole && gpSurgeryRequiredTrust(activeTrust))
    renderedRoles.push(GP_SURGERY);

  const rolesData = allRoles?.filter((role) => {
    return renderedRoles.includes(role.id);
  });

  const { data: GPData } = useAllGPSurgeries();

  const roleIdsCommaSeparated = useMemo(
    () => rolesData?.map((role) => role.id).join(),
    [rolesData],
  );

  return {
    rolesData,
    GPData,
    roleIdsCommaSeparated,
  };
};
