import { initializeApp } from 'firebase/app';
import {
  confirmPasswordReset as firebaseConfirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateEmail,
  updatePassword,
  User,
  UserCredential,
  deleteUser,
  verifyPasswordResetCode as firebaseVerifyPasswordResetCode,
} from 'firebase/auth';
import { IFirebaseError } from '../types/common';
import { TFunction } from 'i18next';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

/**
 * We don't want the user to sign in when creating staff users. Based on https://stackoverflow.com/questions/37517208/firebase-kicks-out-current-user/38013551#38013551
 */
export const secondaryFirebaseApp = initializeApp(firebaseConfig, 'Secondary');

export const auth = getAuth(firebaseApp);
export const googleAuthProvider = new GoogleAuthProvider();

googleAuthProvider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = (): Promise<UserCredential> =>
  signInWithPopup(auth, googleAuthProvider);

export const signInWithEmail = (
  email: string,
  password: string,
): Promise<UserCredential> => signInWithEmailAndPassword(auth, email, password);

export const firebaseSignup = (
  email: string,
  password: string,
): Promise<UserCredential> =>
  createUserWithEmailAndPassword(auth, email, password);

export const signOut = (): Promise<void> => auth.signOut();
export const firebaseRemoveUser = () => auth.currentUser?.delete();

export const getFirebaseEmailCredentials: (
  email: string,
  currentPassword: string,
) => Promise<UserCredential> = (email, currentPassword) =>
  reauthenticateWithCredential(
    auth.currentUser as User,
    EmailAuthProvider.credential(email, currentPassword),
  );

export const isFirebaseError = (error: any): error is IFirebaseError => {
  return error && error.code !== undefined && error.message !== undefined;
};

/**
 * Returns a translated error message for a Firebase error.
 * All slashes in error code are replaced with dashes and final translation key is combined with prefix.
 *
 * If a custom translation prefix is provided, it will be used as a prefix for the translation key.
 * If no translation is found for the custom translation prefix, the default translation prefix will be used (`firebase-errors.`).
 * If no translation is found for the default translation prefix, the error message will be returned as is from error itself.
 *
 * @param error - Firebase error
 * @param t - i18n translation function
 * @param customTPrefix - Optional custom prefix for translation
 * @returns
 */
export const firebaseErrorMessage = (
  error: IFirebaseError,
  t: TFunction,
  customTPrefix?: string,
): string => {
  const tCode = error.code.replace('/', '-');
  return t(
    [`${customTPrefix}${tCode}`, `firebase-errors.${tCode}`],
    error.message,
  );
};

export const updateCurrentUserPassword: (password: string) => Promise<void> = (
  password,
) => updatePassword(auth.currentUser as User, password);

export const updateCurrentUserEmail = (email: string) =>
  updateEmail(auth.currentUser as User, email);

export const sendPasswordResetEmail = (email: string) =>
  firebaseSendPasswordResetEmail(auth, email);

export const verifyPasswordResetCode = (code: string) =>
  firebaseVerifyPasswordResetCode(auth, code);

export const confirmPasswordReset = (oobCode: string, newPassword: string) =>
  firebaseConfirmPasswordReset(auth, oobCode, newPassword);

export const firebaseDeleteUser = (user: User) => {
  return deleteUser(user);
};
