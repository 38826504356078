import { KeyboardEventHandler, useCallback } from 'react';

export const useTextOnlyKeyDown = () => {
  return useCallback<KeyboardEventHandler<HTMLInputElement>>((event) => {
    const acceptedSpecialKeys = ['Enter', 'Backspace'];
    // accept only letters
    if (
      acceptedSpecialKeys.indexOf(event.key) === -1 &&
      !event.key.match(/^[a-zA-Z]$/)
    ) {
      event.preventDefault();
      return;
    }
  }, []);
};
