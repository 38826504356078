import { useEffect } from 'react';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useSiteNumericArrayAttribute } from '../../../../hooks/useAttribute';
import { errorToast } from '../../../../utils/toast';

export const useTimePlanningWeekDays = ({
  showErrorOnUndefinedWeekDays = false,
}: { showErrorOnUndefinedWeekDays?: boolean } = {}) => {
  const { t } = useCommonTranslation();
  const weekDays = useSiteNumericArrayAttribute('weekDays');
  useEffect(() => {
    if (!weekDays && showErrorOnUndefinedWeekDays) {
      errorToast(t('time-planning-week-days-not-set'));
    }
  }, [weekDays, showErrorOnUndefinedWeekDays, t]);
  return weekDays;
};
