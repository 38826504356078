import {
  CacheAndStaleTimeForUnlikelyChanged,
  useMutationWithToastError,
  useQueryWithToastError,
} from './queryUtils';
import { useQueryClient } from 'react-query';
import { UseMutationOptions } from 'react-query/types/react/types';
import {
  addNewCCG,
  deleteCCG,
  getAllCCG,
  mergeCCGs,
  updateCCG,
} from '../services/ccg';
import { CCGFilters, CCGFormValues } from '../types/ccg';

const CCG_QUERY_KEY = 'ccg';
export const useQueryCCG = (filters?: CCGFilters) => {
  return useQueryWithToastError(
    [CCG_QUERY_KEY, filters],
    () => getAllCCG(filters),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
    },
  );
};

export const useMutateCreateCCG = (
  options?: UseMutationOptions<any, any, CCGFormValues>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<CCGFormValues>(
    [CCG_QUERY_KEY],
    (siteUpdate) => addNewCCG(siteUpdate),
    {
      onSettled: () => client.invalidateQueries([CCG_QUERY_KEY]),
      ...options,
    },
  );
};

export const useMutateUpdateCCG = (
  options?: UseMutationOptions<any, any, CCGFormValues>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<CCGFormValues>(
    [CCG_QUERY_KEY],
    (siteUpdate) => updateCCG(siteUpdate),
    {
      onSettled: () => client.invalidateQueries([CCG_QUERY_KEY]),
      ...options,
    },
  );
};

export const useMutateDeleteCCG = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<string>(
    [CCG_QUERY_KEY],
    (params) => deleteCCG(params),
    {
      onSettled: () => client.invalidateQueries([CCG_QUERY_KEY]),
      ...options,
    },
  );
};

interface CCGMerge {
  sourceId: string;
  targetId: string;
}

export const useMutateMergeCCGs = (
  options?: UseMutationOptions<any, any, CCGMerge>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<CCGMerge>(
    [CCG_QUERY_KEY],
    (props) => mergeCCGs(props.targetId, props.sourceId),
    {
      onSettled: () => client.invalidateQueries([CCG_QUERY_KEY]),
      ...options,
    },
  );
};
