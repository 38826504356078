import { PatientLayout } from 'containers/PatientLayout';
import React, { useEffect, useState } from 'react';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { useHistory } from 'react-router';
import { LOGIN_ROUTE, patientURL } from '../AppRoutes';
import { errorToast, successToast } from '../../utils/toast';
import { Loading } from '../../components/common/Loading';
import {
  INewPasswordForm,
  NewPasswordInput,
} from '../../components/views-components/patient/profile/NewPasswordInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  DialogFormWrapper,
  FormActionsStyle,
  FormBody,
  FormTitle,
} from '../../components/common/Forms/Forms.styled';
import { CommonButton, CommonLink } from '../../components/common/Forms/Button';
import { toRem } from '../../utils/helpers';
import { Card, CardHeader } from '../../components/common';
import { FaLock } from 'react-icons/fa';
import {
  confirmPasswordReset,
  verifyPasswordResetCode,
} from '../../services/firebase';

export const EmailActionHandler: React.FC = () => {
  const { t } = useCommonTranslation();
  const { replace } = useHistory();
  const [loading, setLoading] = useState(true);
  const [oobCode, setOobCode] = useState<string>();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    if (mode !== 'resetPassword') {
      errorToast(t('reset-password-invalid-mode'));
      replace(LOGIN_ROUTE);
    }
    const oobCode = urlParams.get('oobCode');
    if (!oobCode) {
      errorToast(t('reset-password-missing-code'));
      replace(LOGIN_ROUTE);
    } else {
      // Check if the password reset action is valid
      verifyPasswordResetCode(oobCode).catch((err) => {
        console.error(err);
        errorToast(t('reset-password-invalid-code'));
        replace(LOGIN_ROUTE);
      });
      setLoading(false);
      setOobCode(oobCode);
    }
  }, [setLoading, replace, t]);

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = useForm<INewPasswordForm>();

  const submitProfileData: SubmitHandler<INewPasswordForm> = async ({
    newPasswordForm: { password },
  }) => {
    const isPasswordsValid = await trigger([
      'newPasswordForm.password',
      'newPasswordForm.confirm_password',
    ]);
    if (isPasswordsValid) {
      setLoading(true);
      confirmPasswordReset(oobCode!, password)
        .then(() => {
          successToast(t('reset-password-submit-success'));
          replace(LOGIN_ROUTE);
        })
        .catch((err) => {
          console.error(err);
          errorToast(t('reset-password-submit-failed'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <PatientLayout graphics="unauth">
      <Card>
        <CardHeader>
          <FaLock />
          <FormTitle>{t('change-password')}</FormTitle>
        </CardHeader>
        <DialogFormWrapper style={{ paddingTop: 'var(--s2)' }}>
          <FormBody onSubmit={handleSubmit(submitProfileData)}>
            {loading && <Loading />}
            <NewPasswordInput
              register={register}
              errors={errors}
              watch={watch}
            />

            <FormActionsStyle>
              <CommonButton
                variant="primary"
                type="submit"
                disabled={Boolean(Object.keys(errors).length)}
                style={{ minWidth: `${toRem(160)}rem` }}
              >
                {t('save-changes')}
              </CommonButton>
              <CommonLink
                type="button"
                variant="secondary"
                style={{ minWidth: `${toRem(160)}rem` }}
                to={patientURL}
              >
                {t('back-to-menu')}
              </CommonLink>
            </FormActionsStyle>
          </FormBody>
        </DialogFormWrapper>
      </Card>
    </PatientLayout>
  );
};
