import {
  Cell,
  Column,
  TableCellProps,
  UseTableInstanceProps,
} from 'react-table';
import {
  CellWrapper,
  EllipseText,
  TableOuterWrapper,
  TableWrapper,
  Tbody,
  Td,
  Tr,
} from './Table.styled';
import TableLoader from './TableLoader';
import { TableHeaderWithFilters } from './TableHeaderWithFilters';

import { getStyles } from './TableUtils';
import React from 'react';
import { IObjectWithIdAndSelected } from '../../types/table';

const cellProps = <D extends object>(
  props: Partial<TableCellProps>,
  { cell }: { cell: Cell<D> },
) => {
  return getStyles(props, cell.column.align, {
    ...(cell.column.style ?? {}),
    ...(cell.column.cellStyle ?? {}),
    ...(cell.column.disableResizing
      ? {
          minWidth: cell.column.minWidth, // Updated Due to set "TYPE" in Cubicle Details form to minWidth: 80
          maxWidth: cell.column.maxWidth,
          width: 'auto', // Updated Due to set "TYPE" in Cubicle Details form to minWidth: 80
        }
      : {}),
  });
};

export type NewTableComponentProps<D extends object> = Pick<
  UseTableInstanceProps<D>,
  'getTableProps' | 'prepareRow' | 'headerGroups' | 'getTableBodyProps' | 'rows'
> & {
  isLoading?: boolean;
  columns: Column<D>[];
  selectedIds?: string[];
};

export const TableComponent = <D extends IObjectWithIdAndSelected>({
  getTableProps,
  prepareRow,
  isLoading,
  columns,
  headerGroups,
  getTableBodyProps,
  rows,
}: NewTableComponentProps<D>) => {
  return (
    <div style={{ position: 'relative', flex: 1, width: '100%' }}>
      {isLoading && <TableLoader />}
      <TableOuterWrapper>
        <TableWrapper {...getTableProps()}>
          <TableHeaderWithFilters
            columns={columns}
            headerGroups={headerGroups}
          />
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const selected = row.original?.isSelected;
              return (
                <Tr {...row.getRowProps()} selected={selected}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps(cellProps)}>
                        <CellWrapper
                          align={cell.column.align}
                          display={'inline-grid'}
                        >
                          <EllipseText
                            align={cell.column.align}
                            selectedRow={selected}
                          >
                            {cell.render('Cell')}
                          </EllipseText>
                        </CellWrapper>
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </TableWrapper>
      </TableOuterWrapper>
    </div>
  );
};
