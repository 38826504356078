import { uniq } from 'lodash';
import { SetStateAction, useCallback, useEffect } from 'react';
import { UseQueryOptions } from 'react-query';
import { useHistory } from 'react-router';
import {
  DISABLE_SELF_CHECK_IN,
  PRE_CHECK_IN_QUESTION,
} from '../../../configs/siteAndTrustAttributes';
import { useGetSiteName } from '../../../hooks/patient/myAppointments/useGetSiteName';
import {
  useSiteBooleanAttributes,
  useSiteStringAttributes,
} from '../../../hooks/useAttribute';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { useUserAppointments } from '../../../query/appointments';
import { patientURL } from '../../../routes/AppRoutes';
import { PatientSite } from '../../../types/sites';
import { USER_APPOINTMENTS_TYPES_BOOKED } from '../../../utils/appointmentUtil';
import { errorToast } from '../../../utils/toast';
import { useAuth } from '../../AuthContext';
import { useSite } from '../../SiteContext';
import { patientContext } from '../PatientContext';

export type PatientCheckInState = {
  siteId: string | undefined;
  siteName: string | undefined;
  answer: boolean | null;
};
export const patientCheckInInit = (): PatientCheckInState => ({
  siteId: undefined,
  siteName: undefined,
  answer: null,
});

export const usePatientCheckIn = () => {
  const updatePatientState = patientContext.useUpdateState();
  const setPatientState = patientContext.useSetState();
  return {
    checkIn: patientContext.useState().checkIn,
    /**
     * Updates booking state using lodash merge function.
     * It can't be used for setting undefined properties.
     * For setting undefined properties use `setBooking`.
     */
    updateCheckIn: useCallback(
      (update: Partial<PatientCheckInState>) => {
        return updatePatientState({ checkIn: update });
      },
      [updatePatientState],
    ),
    setCheckIn: useCallback(
      (value: SetStateAction<PatientCheckInState>) => {
        setPatientState((prevPatientState) => ({
          ...prevPatientState,
          checkIn:
            value instanceof Function ? value(prevPatientState.checkIn) : value,
        }));
      },
      [setPatientState],
    ),
  };
};

export enum PatientCheckInView {
  Disabled,
  PickSite,
  PreCheckIn,
  PreCheckInNo,
  Form,
}

export const usePatientCheckInView = () => {
  const history = useHistory();
  const { t } = useCommonTranslation();
  const {
    checkIn: { answer, siteId },
  } = usePatientCheckIn();
  const [preCheckinQuestion] = useSiteStringAttributes(PRE_CHECK_IN_QUESTION);
  const [disableSelfCheckin] = useSiteBooleanAttributes(DISABLE_SELF_CHECK_IN);
  const { activeSite } = useSite();
  const showPreCheckIn = preCheckinQuestion && answer === null;

  let view: PatientCheckInView;
  if (!siteId) {
    view = PatientCheckInView.PickSite;
  } else if (disableSelfCheckin) {
    view = PatientCheckInView.Disabled;
  } else if (showPreCheckIn) {
    view = PatientCheckInView.PreCheckIn;
  } else if (answer === false) {
    view = PatientCheckInView.PreCheckInNo;
  } else {
    view = PatientCheckInView.Form;
  }

  useEffect(() => {
    if (view === PatientCheckInView.Disabled) {
      errorToast(
        new Error(
          t('patient-check-in-disabled', { siteName: activeSite.name }),
        ),
      );
      history.push(patientURL);
    }
  }, [view, history, t, activeSite]);

  return view;
};

export const useResetPatientCheckIn = () => {
  const setState = patientContext.useSetState();
  return useCallback(() => {
    return setState((state) => ({ ...state, checkIn: patientCheckInInit() }));
  }, [setState]);
};

export const useResetPatientCheckInOnUnmount = () => {
  const reset = useResetPatientCheckIn();
  useEffect(() => reset, [reset]);
};

export const useCheckInSites = (
  options?: UseQueryOptions<any, any, PatientSite[]>,
) => {
  const { sangixUser } = useAuth();
  const getSiteName = useGetSiteName();
  return useUserAppointments(USER_APPOINTMENTS_TYPES_BOOKED, sangixUser?.id, {
    ...options,
    select(data) {
      return uniq((data.data?.booked || []).map((appt) => appt.site_id))
        .map((siteId) => {
          const siteName = getSiteName(siteId);
          return siteName === undefined
            ? undefined
            : { id: siteId, name: siteName };
        })
        .filter((site): site is PatientSite => site !== undefined)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  });
};
