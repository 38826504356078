import { Col, Grid } from '../common/Grid';

export const Grid4to8 = ({
  leftBox,
  rightBox,
}: {
  leftBox: React.ReactNode;
  rightBox?: React.ReactNode;
}) => (
  <Grid>
    <Col md={4}>{leftBox}</Col>
    {rightBox && <Col md={8}>{rightBox}</Col>}
  </Grid>
);
