import { SelectOption } from '../../types/common';
import { useMemo } from 'react';
import { useAllGPSurgeries } from './useAllGPSurgeries';
import {
  GPFiltersNoPagination,
  GPResponseData,
} from '../../types/generalPractices';
import { UseQueryOptions } from 'react-query/types/react/types';
import { AxiosResponse } from 'axios';
import { useSite } from '../../context/SiteContext';

export const useGPSurgeriesOptions = ({
  gpFilters,
  options,
  noToken,
  sortWithGPIdFirst,
}: {
  gpFilters?: GPFiltersNoPagination;
  options?: UseQueryOptions<AxiosResponse<GPResponseData>, any, GPResponseData>;
  noToken?: boolean;
  sortWithGPIdFirst?: string;
}) => {
  const { isLoading, data: GPData } = useAllGPSurgeries(
    gpFilters,
    options,
    noToken,
  );
  const { activeTrust } = useSite();
  const otherGPSortId = sortWithGPIdFirst || activeTrust?.unknown_gp_surgery_id;
  const gpSurgeriesOptions: SelectOption[] = useMemo(
    () =>
      (GPData &&
        GPData.map((gp) => ({
          label: gp.name,
          value: gp.id,
        })).sort((a, b) => {
          if (otherGPSortId) {
            if (a.value === otherGPSortId) {
              return -1;
            }
            if (b.value === otherGPSortId) {
              return 1;
            }
          }
          return a.label.localeCompare(b.label);
        })) ||
      [],
    [GPData, otherGPSortId],
  );
  return { isLoading, gpSurgeriesOptions };
};
