import { Card, CardFooter, CardHeader } from 'components/common';
import { CommonLink } from 'components/common/Forms/Button';
import {
  PopupIconWrapper,
  PopupTitle,
} from 'components/Popup/ConfirmDialog/ConfirmDialog.styled';
import { useWaitingListURL } from 'hooks/patient/waitingList/useWaitingListURL';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { FaClock } from 'react-icons/fa';
import { WaitingListHelpContent } from './WaitingList.styled';

export const WaitingListHelp = () => {
  const { t } = useCommonTranslation();
  const formURL = useWaitingListURL();

  return (
    <Card>
      <CardHeader>
        <PopupIconWrapper inlineIcon>
          <FaClock />
        </PopupIconWrapper>
        <PopupTitle>{t('waiting-list-help-title')}</PopupTitle>
      </CardHeader>
      <WaitingListHelpContent>
        <p>{t('waiting-list-help-p1')}</p>
        <h3>{t('waiting-list-help-h1')}</h3>
        <p>{t('waiting-list-help-p2')}</p>
        <p>{t('waiting-list-help-p3')}</p>
        <h3>{t('waiting-list-help-h2')}</h3>
        <p>{t('waiting-list-help-p4')}</p>
      </WaitingListHelpContent>
      <CardFooter spaceBetween>
        <CommonLink size="large" variant="secondary" to={formURL}>
          {t('back-to-waiting-list')}
        </CommonLink>
      </CardFooter>
    </Card>
  );
};
