import React from 'react';
import {
  IAppointmentUserDetailsProps,
  PatientReadOnlyDetails,
} from '../../../../common/patient/PatientReadOnlyDetails';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { MergeItemBox } from '../../../../../components/common/Merge/MergeItemBox';

type IMergePatientSelectBoxProps = IAppointmentUserDetailsProps & {
  onSelectClicked: () => void;
  isSelecting: boolean;
};

export const MergePatientSelectBox: React.FunctionComponent<
  IMergePatientSelectBoxProps
> = ({ onSelectClicked, isSelecting, ...rest }) => {
  const { t } = useCommonTranslation();
  return (
    <MergeItemBox
      isSelecting={isSelecting}
      onSelectClicked={onSelectClicked}
      selectingLabel={t('selecting-patient')}
      selectLabel={t('select-patient')}
    >
      <PatientReadOnlyDetails {...rest} />
    </MergeItemBox>
  );
};
