import { UseMutationOptions, useQuery, useQueryClient } from 'react-query';

import {
  createTrustMiniSiteCategory,
  deleteTrustMiniSiteCategory,
  getTrustMiniSite,
  updateTrustMiniSiteCategory,
} from '../services/trustMiniSite';
import {
  CreateTrustMiniSiteCategoryRequest,
  UpdateTrustMiniSiteCategoryRequest,
} from '../types/trustMiniSites';
import { MILLIS_IN_MINUTE } from '../utils/dateUtil';
import {
  CacheAndStaleTimeForUnlikelyChanged,
  useMutationWithToastError,
} from './queryUtils';

const TRUST_MINI_SITES_QUERY_KEY = 'trust_mini_sites';

export const useTrustMiniSite = (id?: string) => {
  return useQuery(
    [TRUST_MINI_SITES_QUERY_KEY, { id: id }],
    () => getTrustMiniSite(id!!),
    {
      select: (trustMiniSiteResponse) => {
        // Sort categories and sites to keep the mini site consistent
        trustMiniSiteResponse.data.categories?.sort(
          (a, b) => a.name.localeCompare(b.name) || a.id.localeCompare(b.id),
        );
        trustMiniSiteResponse.data.sites?.sort(
          (a, b) => a.name.localeCompare(b.name) || a.id.localeCompare(b.id),
        );
        return trustMiniSiteResponse.data;
      },
      enabled: Boolean(id),
      ...CacheAndStaleTimeForUnlikelyChanged,
      refetchInterval: 15 * MILLIS_IN_MINUTE,
    },
  );
};

export const useMutateCreateTrustMiniSiteCategory = (
  trustId: string,
  options?: UseMutationOptions<any, any, CreateTrustMiniSiteCategoryRequest>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<CreateTrustMiniSiteCategoryRequest>(
    [TRUST_MINI_SITES_QUERY_KEY],
    (params) => createTrustMiniSiteCategory(params),
    {
      onSettled: () =>
        client.invalidateQueries([TRUST_MINI_SITES_QUERY_KEY, { id: trustId }]),
      ...options,
    },
  );
};

export const useMutateUpdateTrustMiniSiteCategory = (
  trustId: string,
  options?: UseMutationOptions<any, any, UpdateTrustMiniSiteCategoryRequest>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<UpdateTrustMiniSiteCategoryRequest>(
    [TRUST_MINI_SITES_QUERY_KEY],
    (params) => updateTrustMiniSiteCategory(params),
    {
      onSettled: () =>
        client.invalidateQueries([TRUST_MINI_SITES_QUERY_KEY, { id: trustId }]),
      ...options,
    },
  );
};

export const useMutateDeleteTrustMiniSiteCategory = (
  trustId: string,
  options?: UseMutationOptions<any, any, string>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<string>(
    [TRUST_MINI_SITES_QUERY_KEY],
    (params) => deleteTrustMiniSiteCategory(params),
    {
      onSettled: () =>
        client.invalidateQueries([TRUST_MINI_SITES_QUERY_KEY, { id: trustId }]),
      ...options,
    },
  );
};
