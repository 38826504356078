import styled from 'styled-components';
import { h4 } from 'styles/Typography';
import { toRem } from 'utils/helpers';

export const DayNavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--s3);
  margin-bottom: ${toRem(24)}rem;
`;
export const TimePlannerDay = styled.h4`
  ${h4};
  text-transform: uppercase;
  margin: 0 var(--s3);
`;
export const TimePlannerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const TimePlannerInner = styled.div`
  & + & {
    margin-left: var(--s4);
  }
`;
export const TimePlannerHoursNav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: var(--s1);

  & > button {
    font-size: ${toRem(20)}rem;
  }
`;
export const TimePlannerTitle = styled.h4`
  ${h4};
  font-weight: 700;
  /* text-align: center; */
  margin-bottom: var(--s3);
  display: flex;
  justify-content: center;

  & + & {
    margin-left: var(--s4);
  }
`;
export const TimePlannerScrollableArea = styled.div`
  display: flex;
`;
export const TimePlannerTitles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
`;
export const TimePlannerActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: var(--s4);

  button + button {
    margin-top: var(--s2);
  }
`;
