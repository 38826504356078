import { KioskBGImage } from './KioskBGImage';
import { KioskContent, KioskPage, KioskWrapper } from './Kiosk.styled';
import { KioskHeader } from './KioskHeader';

export const KioskLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <KioskWrapper>
      <KioskBGImage />
      <KioskPage>
        <KioskHeader />
        <KioskContent>{children}</KioskContent>
      </KioskPage>
    </KioskWrapper>
  );
};
