import {
  CacheAndStaleTimeForUnlikelyChanged,
  useQueryWithToastError,
} from './queryUtils';
import { getAllRoles } from '../services/users';
import { UseQueryOptions } from 'react-query/types/react/types';
import { AxiosResponse } from 'axios';
import { RoleResponseData } from '../types/common';

const ROLES = 'roles';
export const useRoles = (
  options?: UseQueryOptions<
    AxiosResponse<RoleResponseData>,
    any,
    RoleResponseData
  >,
) => {
  return useQueryWithToastError([ROLES], () => getAllRoles(), {
    select: (data) => data.data,
    ...CacheAndStaleTimeForUnlikelyChanged,
    ...options,
  });
};
