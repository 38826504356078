import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { Card } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import { Col, Grid } from 'components/common/Grid';
import { PageHelmet } from 'components/common/PageHelmet';
import { StaffSettingsTimePlanningTree } from 'configs/RoutesConfig';
import { range } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';

import CheckboxInput from '../../../../components/common/Forms/CheckboxInput';
import { ControlledDatePickerInput } from '../../../../components/common/Forms/controlled/ControlledDatePickerInput';
import { ControlledSelect } from '../../../../components/common/Forms/controlled/ControlledSelect';
import {
  FormActionsStyle,
  FormBody,
  FormHeader,
  FormRow,
  FormTitle,
} from '../../../../components/common/Forms/Forms.styled';
import { CardLoader } from '../../../../components/common/Loading';
import { useConfirmDialog } from '../../../../components/Popup/ConfirmDialog/confirmDialogHook';
import {
  SITE_WEEK_DAYS,
  STAFF_CLOSE_HOUR_IN_SECONDS,
  STAFF_OPEN_HOUR_IN_SECONDS,
} from '../../../../configs/siteAndTrustAttributes';
import { useResourceTypesWithCubiclesAssigned } from '../../../../hooks/resourceTypes/useResourceTypesWithCubiclesAssigned';
import {
  useSiteNumericArrayAttribute,
  useSiteIntAttributes,
} from '../../../../hooks/useAttribute';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useMutateMassCancelAppointment } from '../../../../query/appointments';
import { SelectOption } from '../../../../types/common';
import {
  addDays,
  DATE_FNS_HOUR_12,
  formatDate,
  formatDateOnlyISO,
  formatDateOnlySite,
  getServerTime,
  isAfterOrSameDay,
  isBeforeOrSameDay,
  parseIsoDate,
  SECONDS_IN_HOUR,
  setHours,
  startOfDay,
} from '../../../../utils/dateUtil';
import { StaffLayout } from '../../StaffLayout';
import {
  MassCancelFormValues,
  useMassCancelValidation,
} from './StaffMassCancelHooks';
import { useMaxAndMinFutureDateForStaffBooking } from '../../../../hooks/patient/booking/useMaxAndMinFutureDateForBooking';

const createHourOptions = (from: number, to: number): SelectOption<number>[] =>
  range(from, to + 1).map((hour) => ({
    key: hour,
    label: String(hour),
    value: hour,
  }));

const ALL_CALENDAR_TEMPLATES_VALUE = '-1';

export default function StaffMassCancel() {
  const { t } = useCommonTranslation();
  const { confirm } = useConfirmDialog();

  const [openHourInSeconds, closeHourInSeconds] = useSiteIntAttributes(
    STAFF_OPEN_HOUR_IN_SECONDS,
    STAFF_CLOSE_HOUR_IN_SECONDS,
  );

  const {
    isLoading: isOpeningHoursLoading,
    openHour,
    closeHour,
    fromHourOptions,
    toHourOptions,
  } = useMemo(() => {
    if (
      typeof openHourInSeconds !== 'number' ||
      typeof closeHourInSeconds !== 'number'
    ) {
      return { isLoading: true };
    }
    const openHour = Math.floor(openHourInSeconds / SECONDS_IN_HOUR);
    const closeHour = Math.ceil(closeHourInSeconds / SECONDS_IN_HOUR);
    return {
      isLoading: false,
      openHour,
      closeHour,
      fromHourOptions: createHourOptions(openHour, closeHour - 1),
      toHourOptions: createHourOptions(openHour + 1, closeHour),
    };
  }, [openHourInSeconds, closeHourInSeconds]);

  const {
    resourceTypesWithCubicleAssigned: resourceTypes,
    isLoading: isResourceTypesLoading,
  } = useResourceTypesWithCubiclesAssigned();
  const hasMultipleResourceTypes = Boolean(
    resourceTypes && resourceTypes.length > 1,
  );

  const isLoading = isOpeningHoursLoading || isResourceTypesLoading;

  const resourceTypeOptions = useMemo(() => {
    if (!resourceTypes) {
      return [];
    }
    const options: SelectOption<string>[] = [
      {
        key: ALL_CALENDAR_TEMPLATES_VALUE,
        label: t('all'),
        value: ALL_CALENDAR_TEMPLATES_VALUE,
      },
      ...resourceTypes.map((resourceType) => ({
        key: resourceType.id,
        label: resourceType.name,
        value: resourceType.id,
      })),
    ];
    return options;
  }, [resourceTypes, t]);

  const { mutateAsync: massCancelAppointment } = useMutateMassCancelAppointment(
    {
      onSuccess: () => {
        confirm({
          title: t('mass-cancel-success'),
          icon: <FaCheckCircle />,
          status: 'success',
          actionsTopMargin: true,
          confirmButtonText: t('close'),
          confirmButtonVariant: 'secondary',
          hideCancelButton: true,
          onConfirm: () => {},
        });
      },
    },
  );

  const validateResolver = useMassCancelValidation({
    hasMultipleResourceTypes,
  });
  const {
    register,
    control,
    formState,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<MassCancelFormValues>({
    resolver: validateResolver,
    defaultValues: {
      fromHour: openHour,
      toHour: closeHour,
      clearApptTemplate: true,
    },
  });
  const values = watch();

  const openMassCancelConfirmDialog = () => {
    handleSubmit(() => {
      const {
        fromDate: fromDateString,
        toDate: toDateString,
        fromHour,
        toHour,
        clearApptTemplate,
        resourceTypeId,
      } = values;
      const fromDate = parseIsoDate(fromDateString);
      const toDate = parseIsoDate(toDateString);
      confirm({
        title: t('confirm-mass-cancel'),
        description: (
          <>
            {t('confirm-mass-cancel-description-line1')}
            <br />
            {t('confirm-mass-cancel-description-line2', {
              fromDate: formatDateOnlySite(fromDate),
              toDate: formatDateOnlySite(toDate),
              fromHour: formatDate(
                setHours(new Date(), fromHour),
                DATE_FNS_HOUR_12,
              ),
              toHour: formatDate(
                setHours(new Date(), toHour),
                DATE_FNS_HOUR_12,
              ),
            })}
          </>
        ),
        icon: <FaExclamationTriangle />,
        status: 'delete',
        actionsTopMargin: true,
        confirmButtonText: t('yes'),
        confirmButtonVariant: 'danger',
        cancelButtonText: t('no'),
        onConfirm: () => {
          massCancelAppointment({
            date: formatDateOnlyISO(fromDate),
            // add 1 day to end date, it is exclusive
            to_date: formatDateOnlyISO(addDays(toDate, 1)),
            from_hour: fromHour,
            to_hour: toHour,
            clear_appt_template: clearApptTemplate,
            resource_type_id:
              resourceTypeId === ALL_CALENDAR_TEMPLATES_VALUE
                ? undefined
                : resourceTypeId,
          });
        },
      });
    })();
  };

  const { maxDate: maxSite } = useMaxAndMinFutureDateForStaffBooking();
  const weekDays = useSiteNumericArrayAttribute(SITE_WEEK_DAYS);

  const isDayAvailable = useCallback(
    (date: Date) => {
      return (
        isAfterOrSameDay(startOfDay(date), startOfDay(getServerTime())) &&
        isBeforeOrSameDay(startOfDay(date), startOfDay(maxSite)) &&
        weekDays?.includes(date.getDay())
      );
    },
    [maxSite, weekDays],
  );

  return (
    <StaffLayout>
      <PageHelmet title={t('mass-cancel')} />
      <Grid>
        <Col md={5}>
          <Card>
            {isLoading ? (
              <CardLoader />
            ) : (
              <>
                <LinkCardTabs tabs={StaffSettingsTimePlanningTree(t)} />
                <FormHeader>
                  <FormTitle>{t('mass-cancel')}</FormTitle>
                </FormHeader>
                <FormBody>
                  <FormRow>
                    <ControlledDatePickerInput
                      control={control}
                      formState={formState}
                      helperText={errors.fromDate?.message}
                      hasError={Boolean(errors.fromDate)}
                      name="fromDate"
                      label={t('date-from')}
                      placeholder={t('pick-date')}
                      filterDate={isDayAvailable}
                      onChange={(date) => {
                        if (date && !values.toDate) {
                          setValue('toDate', date);
                        }
                      }}
                    />
                    <ControlledDatePickerInput
                      control={control}
                      formState={formState}
                      helperText={errors.toDate?.message}
                      hasError={Boolean(errors.toDate)}
                      name="toDate"
                      label={t('date-to')}
                      placeholder={t('pick-date')}
                      filterDate={isDayAvailable}
                    />
                  </FormRow>
                  <FormRow>
                    <ControlledSelect
                      control={control}
                      formState={formState}
                      helperText={errors.fromHour?.message}
                      hasError={Boolean(errors.fromHour)}
                      name="fromHour"
                      label={t('hour-from')}
                      options={fromHourOptions}
                    />
                    <ControlledSelect
                      control={control}
                      formState={formState}
                      helperText={errors.toHour?.message}
                      hasError={Boolean(errors.toHour)}
                      name="toHour"
                      label={t('hour-to')}
                      options={toHourOptions}
                    />
                  </FormRow>
                  {hasMultipleResourceTypes && (
                    <FormRow>
                      <ControlledSelect
                        control={control}
                        formState={formState}
                        helperText={errors.resourceTypeId?.message}
                        hasError={Boolean(errors.resourceTypeId)}
                        name="resourceTypeId"
                        label={t('calendar-template')}
                        options={resourceTypeOptions}
                      />
                    </FormRow>
                  )}
                  <FormRow>
                    <CheckboxInput
                      label={t('clear-appt-template')}
                      checked={values.clearApptTemplate}
                      {...register('clearApptTemplate')}
                    />
                  </FormRow>
                  <FormActionsStyle align="center" topMargin={true}>
                    <CommonButton
                      variant="danger"
                      type="button"
                      onClick={() => openMassCancelConfirmDialog()}
                    >
                      {t('submit-mass-cancel')}
                    </CommonButton>
                  </FormActionsStyle>
                </FormBody>
              </>
            )}
          </Card>
        </Col>
      </Grid>
    </StaffLayout>
  );
}
