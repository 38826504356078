import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { Card, CardHeader } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import { FormTitle } from 'components/common/Forms/Forms.styled';
import { PageHelmet } from 'components/common/PageHelmet';
import ProfileForm from 'components/views-components/patient/profile/ProfileForm';
import { ProfileTabsTree } from 'configs/RoutesConfig';
import { PatientLayout } from 'containers/PatientLayout';
import { ReactElement, useState } from 'react';
import { FaUserCheck, FaUserCog } from 'react-icons/fa';
import { InlineConfirm } from '../../../../components/Popup/ConfirmDialog/InlineConfirm';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';

export default function ProfilePage(): ReactElement {
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const { t } = useCommonTranslation();

  return (
    <PatientLayout graphics="patient">
      <PageHelmet title={t('my-profile')} />
      {!isFormSuccess ? (
        <Card>
          <CardHeader>
            <FaUserCog />
            <FormTitle>{t('my-profile')}</FormTitle>
          </CardHeader>
          <LinkCardTabs tabs={ProfileTabsTree(t)} fluid />
          <ProfileForm setIsFormSuccess={setIsFormSuccess} />
        </Card>
      ) : (
        <InlineConfirm
          isLoading={false}
          icon={<FaUserCheck />}
          inlineIcon
          title={t('profile-updated-successfully')}
          status="success"
          actions={[
            <CommonButton
              variant="primary"
              type="button"
              key={'ok-confirmation'}
              onClick={() => setIsFormSuccess(false)}
            >
              {t('ok')}
            </CommonButton>,
          ]}
        />
      )}
    </PatientLayout>
  );
}
