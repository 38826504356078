import { useCommonTranslation } from '../i18n/useCommonTranslation';
import { useMemo } from 'react';
import { getColumn } from '../../utils/table';
import { Column } from 'react-table';
import { AdminStatisticsFiveYearsItem } from './useAdminStatisticsLastFiveYearsData';

export const useAdminStatisticsFiveYearsColumns = ({
  currentYear,
}: {
  currentYear: number;
}) => {
  const { t } = useCommonTranslation();

  const columns = useMemo(() => {
    return [
      getColumn({
        Header: t('trust-name'),
        accessor: 'trustName',
        width: 20,
        disableFilters: true,
        disableSortBy: true,
      }),
      getColumn({
        Header: t('site-name'),
        accessor: 'siteName',
        width: 40,
        disableFilters: true,
        disableSortBy: true,
      }),
      ...([0, 1, 2, 3, 4, 5].map((differenceFormCurrentYear) =>
        getColumn({
          Header: `${currentYear - differenceFormCurrentYear}`,
          accessor: (item: AdminStatisticsFiveYearsItem) =>
            item[
              differenceFormCurrentYear.toString() as keyof AdminStatisticsFiveYearsItem
            ],
          width: 14,
          disableSortBy: true,
          disableFilters: true,
        }),
      ) as Column<AdminStatisticsFiveYearsItem>[]),
    ] as Column<AdminStatisticsFiveYearsItem>[];
  }, [t, currentYear]);
  return { columns };
};
