import styled from 'styled-components';
import { Tr } from '../../../../components/Table/Table.styled';
import { toRemStr } from '../../../../utils/helpers';

export const DefaultTimePlanningDaySelectRow = styled(Tr)<{
  isSelected: boolean;
}>`
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 var(--s3);
  border-radius: var(--s4);
  border: ${toRemStr(2)} solid transparent;
  border-color: ${({ isSelected }) =>
    isSelected ? 'var(--redaccent)' : 'none'};
`;
