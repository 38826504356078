import React from 'react';
import {
  TimePlanningItemColor,
  TimePlanningList,
  TimePlanningListItem,
  TooltipListTitle,
} from '../../../../components/common/Tooltips/Tooltips.styled';
import {
  FaBolt,
  FaExclamationCircle,
  FaPencilAlt,
  FaUtensils,
} from 'react-icons/fa';
import { TimePlanningTooltipWrapper } from '../../../../components/common/Tooltips/TimePlanningTooltipWrapper';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';

interface IAppointmentsHeaderTooltipProps {}

export const AppointmentsHeaderTooltip: React.FunctionComponent<
  IAppointmentsHeaderTooltipProps
> = () => {
  const { t } = useCommonTranslation();
  return (
    <TimePlanningTooltipWrapper minWidth={200}>
      <TooltipListTitle>{t('current')}</TooltipListTitle>
      <TimePlanningList>
        <TimePlanningListItem>
          <TimePlanningItemColor
            shape="button"
            style={{
              background: 'var(--primary)',
            }}
          >
            B
          </TimePlanningItemColor>
          {t('booked')}
        </TimePlanningListItem>
        <TimePlanningListItem>
          <TimePlanningItemColor
            shape="button"
            style={{
              background: 'var(--yellowaction)',
            }}
          >
            Q
          </TimePlanningItemColor>
          {t('in-queue')}
        </TimePlanningListItem>
        <TimePlanningListItem>
          <TimePlanningItemColor
            shape="button"
            style={{
              background: 'var(--blueprimary)',
            }}
          >
            IN
          </TimePlanningItemColor>
          {t('in-cubicle')}
        </TimePlanningListItem>
      </TimePlanningList>
      <TooltipListTitle>{t('past')}</TooltipListTitle>
      <TimePlanningList>
        <TimePlanningListItem>
          <TimePlanningItemColor
            shape="radio"
            style={{
              background: 'var(--greenprimary)',
            }}
          />
          {t('test-taken')}
        </TimePlanningListItem>
        <TimePlanningListItem>
          <TimePlanningItemColor
            shape="radio"
            style={{
              background: 'var(--rednegative)',
            }}
          />
          {t('test-not-taken')}
        </TimePlanningListItem>
        <TimePlanningListItem>
          <TimePlanningItemColor
            shape="button"
            style={{
              background: 'var(--rednegative)',
            }}
          >
            {t('not-prepared-abbrev')}
          </TimePlanningItemColor>
          {t('not-prepared')}
        </TimePlanningListItem>
        <TimePlanningListItem>
          <TimePlanningItemColor
            shape="button"
            style={{
              background: 'var(--rednegative)',
            }}
          >
            {t('mia')}
          </TimePlanningItemColor>
          {t('mia')}
        </TimePlanningListItem>
        <TimePlanningListItem>
          <TimePlanningItemColor
            shape="button"
            style={{
              background: 'var(--rednegative)',
            }}
          >
            {t('dna')}
          </TimePlanningItemColor>
          {t('dna')}
        </TimePlanningListItem>
      </TimePlanningList>
      <TooltipListTitle>{t('all')}</TooltipListTitle>
      <TimePlanningList>
        <TimePlanningListItem>
          <FaPencilAlt />
          {t('edit-appointment')}
        </TimePlanningListItem>
        <TimePlanningListItem>
          <FaExclamationCircle />
          {t('alert')}
        </TimePlanningListItem>
        <TimePlanningListItem>
          <FaUtensils />
          {t('appointment-flag-fast')}
        </TimePlanningListItem>
        <TimePlanningListItem>
          <FaBolt />
          {t('appointment-flag-urgent')}
        </TimePlanningListItem>
      </TimePlanningList>
    </TimePlanningTooltipWrapper>
  );
};
