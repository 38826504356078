import { useMemo } from 'react';
import { useSiteSelectedApptFlags } from './useSiteSelectedApptFlags';

export const useParseApptFlags = (apptFlagsBitwise?: number) => {
  const siteFlagsSelected = useSiteSelectedApptFlags();
  return useMemo(() => {
    return apptFlagsBitwise
      ? siteFlagsSelected.filter((flag) => (flag.id & apptFlagsBitwise) !== 0)
      : [];
  }, [apptFlagsBitwise, siteFlagsSelected]);
};
