import React, { ReactElement } from 'react';
import {
  TimeSlotChips,
  TimeSlotTitle,
  TimeSlotWrapper,
} from './AppointmentsTimes.styled';
import { AppointmentCardControl, SlotAppointment } from './SlotAppointment';
import { HourMinuteFormatted } from '../../../utils/dateUtil';
import { AppointmentForFlow } from '../../../hooks/staff/useAppointmentsFlow';

type Props = {
  hour: number;
  minute: number;
  time: HourMinuteFormatted;
  appointments: AppointmentForFlow[];
} & AppointmentCardControl;

export const TimeSlot = ({
  time,
  appointments,
  ...rest
}: Props): ReactElement => (
  <TimeSlotWrapper>
    <TimeSlotTitle>{time}</TimeSlotTitle>

    <TimeSlotChips>
      {appointments.map((appointment, index) => {
        return (
          <SlotAppointment
            {...rest}
            key={index}
            appointment={appointment}
            reverseIndex={appointments.length - index}
          />
        );
      })}
    </TimeSlotChips>
  </TimeSlotWrapper>
);
