import { AxiosResponse } from 'axios';
import { UseMutationOptions, UseQueryOptions } from 'react-query';
import {
  checkInKioskAppointment,
  getKioskAppointments,
  getLCD,
  GetLCDParams,
  KioskAppointmentsCheckinData,
  KioskAppointmentsFilters,
  KioskUpdateUserData,
  updateKioskUser,
} from 'services/public';
import { getSiteById } from 'services/sites';
import { SiteData } from 'types/sites';

import { MILLIS_IN_MINUTE } from '../utils/dateUtil';
import {
  CacheAndStaleTimeForUnlikelyChanged,
  useMutationWithToastError,
  useQueryWithToastError,
} from './queryUtils';
import { KioskAppointmentsResponseData } from '../types/appointments';

const KIOSK_APPOINTMENTS_QUERY_KEY = 'kiosk-appointments';

export const useKioskAppointments = (
  params: KioskAppointmentsFilters,
  options?: UseQueryOptions<AxiosResponse<KioskAppointmentsResponseData>>,
) => {
  return useQueryWithToastError(
    [KIOSK_APPOINTMENTS_QUERY_KEY, params],
    () => getKioskAppointments(params),
    {
      cacheTime: 0,
      staleTime: 0,
      enabled: !!params.siteId && !!params.input,
      ...options,
    },
  );
};

export const useMutateCheckInKioskAppointment = (
  options?: UseMutationOptions<any, any, KioskAppointmentsCheckinData>,
) => {
  return useMutationWithToastError<KioskAppointmentsCheckinData>(
    [KIOSK_APPOINTMENTS_QUERY_KEY],
    (params) => checkInKioskAppointment(params),
    options,
  );
};

export const useMutateUpdateKioskUser = (
  options?: UseMutationOptions<any, any, KioskUpdateUserData>,
) => {
  return useMutationWithToastError<KioskUpdateUserData>(
    [KIOSK_APPOINTMENTS_QUERY_KEY],
    (params) => updateKioskUser(params),
    options,
  );
};

const PUBLIC_SITE_QUERY_KEY = 'public-site';
export const usePublicSiteQuery = (
  siteId?: string,
  options: UseQueryOptions<AxiosResponse<SiteData>> = {},
) => {
  return useQueryWithToastError(
    [PUBLIC_SITE_QUERY_KEY, siteId],
    () => getSiteById(siteId!),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
      refetchInterval: 15 * MILLIS_IN_MINUTE,
      enabled: !!siteId,
      ...options,
    },
  );
};
const LCD_KEY = 'lcd';
export const useLCDQuery = (params: GetLCDParams) => {
  return useQueryWithToastError([LCD_KEY, params], () => getLCD(params), {
    refetchInterval: 5000,
  });
};
