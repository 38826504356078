import { useEnteredCubicle } from 'hooks/staff/useEnteredCubicle';
import { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import {
  FaCalendarAlt,
  FaChartBar,
  FaCube,
  FaEnvelope,
  FaUserClock,
  FaUserCog,
} from 'react-icons/fa';

import { SideMenu } from '../../components/SideMenu/SideMenu';
import {
  ALL_ROLES_BUT_SPECIALIST,
  ALL_STAFF_ROLES_BUT_RECEPTIONIST_AND_THIRD_PARTY_AND_SURGERY,
  ALL_STAFF_ROLES_BUT_SPECIALIST_AND_THIRD_PARTY_AND_SURGERY,
  GET_EMAIL_TEMPLATE_ROLES,
} from '../../configs/constants';
import { MultiRoleCheckFunction, useRole } from '../../context/RoleContext';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import {
  useHasAdminAccessToSelectedSite,
  useHasReportsAccessOnSelectedSite,
} from '../../hooks/user/useHasAdminAccessToSelectedSite';
import { STAFF_SETTINGS_REFERRALS_URL } from '../../routes/AppRoutes';
import { MenuItemProps } from '../../types/common';

const getSettingsSubmenu = ({
  hasSelectedSiteAdminAccess,
  hasAnyOfRoles,
  t,
}: {
  hasSelectedSiteAdminAccess: boolean;
  hasAnyOfRoles: MultiRoleCheckFunction;
  t: TFunction;
}) => {
  const submenu = [
    {
      to: '/staff/settings/users',
      label: t('users'),
    },
  ];
  if (hasSelectedSiteAdminAccess) {
    submenu.push(
      ...[
        {
          to: '/staff/settings/time-planning',
          label: t('time-planning'),
        },
        {
          to: '/staff/settings/cubicle',
          label: t('cubicle'),
        },
        {
          to: STAFF_SETTINGS_REFERRALS_URL,
          label: t('referrals'),
        },
        ...(hasAnyOfRoles(...GET_EMAIL_TEMPLATE_ROLES)
          ? [
              {
                to: '/staff/settings/emails',
                label: t('emails'),
              },
            ]
          : []),
        {
          to: '/staff/settings/preferences',
          label: t('preferences'),
        },
      ],
    );
  }
  return submenu;
};
export const StaffMenu: React.FunctionComponent = () => {
  const { hasAnyOfRoles } = useRole();
  const hasSelectedSiteAdminAccess = useHasAdminAccessToSelectedSite();
  const hasReportAccessToSelectedSite = useHasReportsAccessOnSelectedSite();
  const { t } = useCommonTranslation();
  const { enteredCubicle } = useEnteredCubicle();
  const menuItems = useMemo(() => {
    const items: MenuItemProps[] = [];
    if (hasAnyOfRoles(...ALL_ROLES_BUT_SPECIALIST)) {
      items.push(
        ...[
          {
            to: '/staff/calendar',
            icon: <FaCalendarAlt />,
            label: t('calendar'),
          },
          {
            to: '/staff/appointments',
            icon: <FaUserClock />,
            label: t('appointments'),
          },
        ],
      );
    }
    if (
      hasAnyOfRoles(
        ...ALL_STAFF_ROLES_BUT_RECEPTIONIST_AND_THIRD_PARTY_AND_SURGERY,
      )
    ) {
      items.push({
        to: '/staff/cubicle',
        icon: <FaCube />,
        label: t('cubicle'),
        status: enteredCubicle?.name,
      });
    }

    if (
      hasAnyOfRoles(
        ...ALL_STAFF_ROLES_BUT_SPECIALIST_AND_THIRD_PARTY_AND_SURGERY,
      )
    ) {
      const submenu = getSettingsSubmenu({
        hasSelectedSiteAdminAccess,
        hasAnyOfRoles,
        t,
      });
      items.push({
        to: '/staff/settings',
        icon: <FaUserCog />,
        label: t('settings'),
        subMenu: submenu,
      });
    }

    if (hasReportAccessToSelectedSite) {
      items.push({
        to: '/staff/reports',
        icon: <FaChartBar />,
        label: t('reports'),
        subMenu: [
          {
            to: '/staff/reports/reports',
            label: t('reports'),
          },
          {
            to: '/staff/reports/supervisor',
            label: t('supervisor'),
          },
          {
            to: '/staff/reports/patients',
            label: t('report-patients'),
          },
          {
            to: '/staff/reports/statistics',
            label: t('statistics'),
          },
        ],
      });
    }
    items.push({
      to: '/staff/contact-us',
      icon: <FaEnvelope />,
      label: t('support'),
    });
    return items;
  }, [
    t,
    hasAnyOfRoles,
    enteredCubicle,
    hasSelectedSiteAdminAccess,
    hasReportAccessToSelectedSite,
  ]);
  return <SideMenu navigations={menuItems} />;
};
