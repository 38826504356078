import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { CommonButton as Button } from '../Forms/Button';
import { PaginationWrapper } from './Pagination.styled';

export interface NewPaginationProps {
  pageLimit: number;
  offset: number;
  setOffset: (newOffset: number) => any;
  itemsCount: number;
}

export const NewPagination = ({
  pageLimit,
  itemsCount,
  offset,
  setOffset,
}: NewPaginationProps) => {
  const prevDisabled = offset <= 0;
  const nextDisabled = itemsCount <= pageLimit;

  return (
    <PaginationWrapper>
      <Button
        size="auto"
        variant="primary"
        iconOnly
        disabled={prevDisabled}
        onClick={() => {
          setOffset(offset - pageLimit);
        }}
      >
        <FaChevronLeft />
      </Button>
      <Button
        size="auto"
        variant="primary"
        iconOnly
        disabled={nextDisabled}
        onClick={() => {
          setOffset(offset + pageLimit);
        }}
      >
        <FaChevronRight />
      </Button>
    </PaginationWrapper>
  );
};
