import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { cssIf } from 'styles/partials/cssIf';
import { toRem } from 'utils/helpers';

export const DashboardWrapperReversed = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  gap: var(--s3) var(--s3);
  margin: var(--s3);
`;
export const DashboardLink = styled(Link)<{ disabled?: boolean }>`
  flex-grow: 1;
  height: ${toRem(120)}rem;
  min-width: 20rem;
  background: var(--greenaccent);
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: ${toRem(20)}rem;
  font-weight: 700;
  border-radius: var(--s3);
  width: calc(50% - var(--s2));

  &:hover {
    text-decoration: none;
    color: var(--white);
  }

  .dashboard-icon {
    font-size: ${toRem(42)}rem;
    margin-bottom: var(--s3);
  }

  div& {
    background-color: var(--disableddimmed);
    cursor: not-allowed;
  }

  ${cssIf('disabled')`
    pointer-events: none;
    background: var(--disableddimmed);
  `}
`;
