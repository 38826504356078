import React, { useState } from 'react';

import { LinkCardTabs } from '../../../components/Card/CardTabs/LinkCardTabs';
import { Card } from '../../../components/common';
import { FormTitle } from '../../../components/common/Forms/Forms.styled';
import { Col, Grid } from '../../../components/common/Grid';
import { CardLoader } from '../../../components/common/Loading';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { ICardTabs } from '../../../types/common';
import { SiteData } from '../../../types/sites';
import { createSubject } from '../../../utils/subject';
import { CheckInPreferences } from './checkIn/CheckInPreferences';
import { CustomSettings } from './custom/CustomSettings';
import { EmailsPreferences } from './emails/EmailsPreferences';
import { AppointmentFlagsPreferences } from './flags/AppointmentFlagsPreferences';
import { GeneralSitePreferences } from './general/GeneralSitePreferences';
import { SiteLinks } from './links/SiteLinks';
import {
  SitePreferencesBackButton,
  SitePreferencesHeaderWrapper,
  SitePreferencesRightControls,
  SitePreferencesSaveButton,
} from './sitePreferences.styled';
import { SitePreferencesTab } from './SitePreferencesHooks';
import { SiteGuidelines } from './guidelines/SiteGuidelines';

export const TYPE_STAFF: SitePreferencesType = 'staff';
export const TYPE_ADMIN: SitePreferencesType = 'admin';

export const SITE_PREFERENCES_FORM_ID = 'sitePreferencesForm';

export interface ISitePreferencesProps {
  site?: SiteData;
  submitHandler: (siteData: SiteData) => any;
  isFetching: boolean;
}

export type SitePreferencesType = 'admin' | 'staff';

export type ISitePreferencesWithTypeProps = ISitePreferencesProps & {
  type: SitePreferencesType;
};

type Props = ISitePreferencesWithTypeProps & {
  selectedTabKey: SitePreferencesTab;
  tabsConfig: ICardTabs[];
};

export const SitePreferences: React.FunctionComponent<Props> = ({
  site,
  type,
  selectedTabKey,
  tabsConfig,
  ...rest
}) => {
  const { t } = useCommonTranslation();
  // save event for preferences tabs without form
  const [saveEvent] = useState(() => createSubject<void>());

  return (
    <Grid>
      {!site ? (
        <Col>
          <CardLoader />
        </Col>
      ) : (
        <>
          <Col
            style={{
              minWidth: '100%',
            }}
          >
            <Card>
              <SitePreferencesHeaderWrapper>
                {type === 'admin' && (
                  <FormTitle>
                    {site.name} {t('preferences')}
                  </FormTitle>
                )}
                <LinkCardTabs tabs={tabsConfig} noMargin={true} />
                <SitePreferencesRightControls>
                  {type === 'admin' && (
                    <SitePreferencesBackButton
                      to={'/admin/departments'}
                      type="button"
                      variant="secondary"
                    >
                      {t('back')}
                    </SitePreferencesBackButton>
                  )}
                  {selectedTabKey !== 'links' && (
                    <SitePreferencesSaveButton
                      variant="primary"
                      type="submit"
                      form={SITE_PREFERENCES_FORM_ID}
                      onClick={() => {
                        saveEvent.next();
                      }}
                    >
                      {t('save')}
                    </SitePreferencesSaveButton>
                  )}
                </SitePreferencesRightControls>
              </SitePreferencesHeaderWrapper>
            </Card>
          </Col>
          {selectedTabKey === 'general' && (
            <GeneralSitePreferences site={site} type={type} {...rest} />
          )}
          {selectedTabKey === 'emails' && (
            <EmailsPreferences site={site} {...rest} />
          )}
          {selectedTabKey === 'settings-check-in-tab' && (
            <CheckInPreferences type={type} site={site} {...rest} />
          )}
          {selectedTabKey === 'custom-settings' && (
            <CustomSettings type={type} site={site} {...rest} />
          )}
          {selectedTabKey === 'appointment-flags' && (
            <AppointmentFlagsPreferences
              site={site}
              saveEvent={saveEvent}
              {...rest}
            />
          )}
          {selectedTabKey === 'links' && <SiteLinks site={site} />}
          {selectedTabKey === 'guidelines' && (
            <SiteGuidelines saveEvent={saveEvent} />
          )}
        </>
      )}
    </Grid>
  );
};
