import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
}

export const PageHelmet = ({ title }: Props): ReactElement => {
  const { i18n } = useTranslation();
  return (
    <Helmet
      titleTemplate="Sangix Appointments - %s"
      defaultTitle="Appointments"
      title={title}
    >
      <html lang={i18n.language} />
    </Helmet>
  );
};
