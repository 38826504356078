import { Factory, Model } from 'miragejs';
import { Appointment, AppointmentStatus, UserAppointmentResponseData } from '../types/appointments';
import {
  DATE_FNS_DATE_TIME_TO_MINUTE_ONLY_ISO_FORMAT,
  DateOnlyISOString,
  formatDate,
  formatDateOnlyISO,
  isAfter,
  isBefore,
  isInFuture,
  isInPast,
  ISODateTime,
  isToday,
  padTime,
  parseDate,
  parseIsoDate,
  setHours,
  setMinutes,
  subDays,
} from '../utils/dateUtil';
import { addDays, formatISO, isWeekend, setMilliseconds, setSeconds } from 'date-fns';
import {
  getMockServer,
  MockEndpoint,
  MockServer,
  wrapCountResponseArray,
  wrapCountResponseCollection,
} from './server';
import { DEFAULT_LOGGED_IN_TEST_USER, TEST_USER_2 } from './testUsers';
import { TEST_RESOURCE_TYPE_5_MIN } from './resourceTypes';
import { DeepPartial } from 'react-hook-form';
import { DeepReadonly } from 'types/utils/DeepReadonly';
import { APPOINTMENT_FLAG_ALERT, getAppointmentFlagId } from '../utils/appointmentUtil';

export type PartialAppointment = Partial<Appointment>;

const apptMinuteLength = 30;

export const appointmentModel = Model.extend<PartialAppointment>({});

export const mockApptDefaults: PartialAppointment = {
  appt_length: apptMinuteLength,
  appt_time: formatISO(
    addDays(
      setHours(setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0), 8),
      1,
    ),
  ),
  checkin_time: formatISO(new Date()),
  status: 'FREE',
  booking_type: 'STAFF',
  staff_only: false,
  user_full_name: `${DEFAULT_LOGGED_IN_TEST_USER.firstname} ${DEFAULT_LOGGED_IN_TEST_USER.lastname}`,
  resource_type_name: TEST_RESOURCE_TYPE_5_MIN.name,
  resource_type_abbreviation: TEST_RESOURCE_TYPE_5_MIN.abbreviation,
  resource_type_id: TEST_RESOURCE_TYPE_5_MIN.id,
  user_member_id: DEFAULT_LOGGED_IN_TEST_USER.id,
  gp_surgery_id: DEFAULT_LOGGED_IN_TEST_USER.gp_surgery_id,
  user_account_id: DEFAULT_LOGGED_IN_TEST_USER.user_account_id,
  staff_full_name: 'Ford Prefect',
  tubes: 1,
  forms: 4,
};
export const appointmentFactory = Factory.extend<PartialAppointment>({
  id(index) {
    return `${index}`;
  },
  ...mockApptDefaults,
});

const getAppointmentTime = (
  date: 'tomorrow' | 'today',
  hour: number,
  minute: number,
) => {
  return formatISO(
    addDays(
      setHours(
        setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), minute),
        hour,
      ),
      date === 'tomorrow' ? 1 : 0,
    ),
  );
};

export const getAppointmentTimeStartOfNextHour = () => {
  const currentDate = new Date();
  return formatISO(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      currentDate.getHours() + 1,
    ),
  );
};

export type TimeTuple = [number, number]; // [hours, minutes] in UTC
type CreateTypeParams = {
  timeList: TimeTuple[];
  length: number;
  status: AppointmentStatus;
  date: Date;
};
export const createAppts = ({
                              timeList,
                              length = 5,
                              status = 'FREE',
                              date = new Date(),
                            }: CreateTypeParams): PartialAppointment[] => {
  const apptsGenerated = timeList.map((time: TimeTuple) => {
    const apptDateObj = new Date(date);
    apptDateObj.setHours(time[0]);
    apptDateObj.setMinutes(time[1]);
    return {
      ...mockApptDefaults,
      id: `appt-${formatDate(
        apptDateObj,
        DATE_FNS_DATE_TIME_TO_MINUTE_ONLY_ISO_FORMAT,
      )}`,
      appt_length: length,
      appt_time: `${formatDate(apptDateObj)}T${padTime(time[0])}:${padTime(
        time[1],
      )}:00Z`,
      status,
    };
  });
  return status === 'FREE'
    ? apptsGenerated.filter(
      (appt) => !isBefore(parseIsoDate(appt.appt_time), new Date()),
    )
    : apptsGenerated;
};
export const createApptsAnyDay =
  (
    timeList: TimeTuple[],
    length = 5,
    status: AppointmentStatus = 'FREE',
  ): MockEndpoint<PartialAppointment[]> =>
    (schema, req) => {
      const { fromDate = formatDate(new Date()) } = req.queryParams;
      const date = new Date(fromDate as string);
      return createAppts({ timeList, length, status, date });
    };

export const mockAvailableAppts = (
  getResponse: MockEndpoint<PartialAppointment[]>,
  mockServer = getMockServer(),
) => {
  mockServer.get('appt/available', (schema, request) =>
    wrapCountResponseArray(getResponse(schema, request)),
  );
};

// all business days are available for booking
export const mockAvailableDaysAllBusiness = (
  excludeDays: ISODateTime[] = [],
  server = getMockServer(),
) => {
  server.get('appt/availableDays', (schema, request) => {
    return createDatesAvailableResponse(
      request.queryParams as Record<string, string>,
      (date, isoDate) =>
        !isWeekend(date) && excludeDays.indexOf(isoDate) === -1,
    );
  });
};

const createDatesAvailableResponse = (
  queryParams: Record<string, string>,
  filterResultDate: (date: Date, dateIso: ISODateTime) => boolean,
) => {
  const { fromDate, toDate } = queryParams;
  // BE does not include the day you search to, that's why we subtract 1 day
  const toDateObj = subDays(parseDate(toDate), 1);
  let dateIndex = parseDate(fromDate);
  const datesAvailable: DateOnlyISOString[] = [];
  while (
    isBefore(dateIndex, toDateObj) ||
    formatDateOnlyISO(dateIndex) === formatDateOnlyISO(toDateObj)
    ) {
    const isoDate = formatDate(dateIndex);
    if (
      (!isInPast(dateIndex) || isToday(dateIndex)) &&
      filterResultDate(dateIndex, isoDate)
    ) {
      datesAvailable.push(isoDate);
    }
    dateIndex = addDays(dateIndex, 1);
  }

  return wrapCountResponseArray(datesAvailable);
};

export const mockAvailableDays = (
  datesAvailable: ISODateTime[] = [],
  server = getMockServer(),
) => {
  server.get('appt/availableDays', (schema, request) => {
    return createDatesAvailableResponse(request.queryParams as Record<string, string>, (_, isoDate) => {
      return datesAvailable.includes(isoDate);
    });
  });
};

export const mockBookAppt = (
  endpoint: MockEndpoint<number>,
  server = getMockServer(),
) => {
  server.post('appt/:id/book', endpoint);
};

export const mockCancelApptByToken = (
  endpoint: MockEndpoint<number> = () => 200,
  server = getMockServer(),
) => {
  return server.get('/appt/emailCancel/:token', endpoint);
};

export const mockGetSelfCheckInAppointments = (
  endpoint: MockEndpoint<PartialAppointment[]>,
  server = getMockServer(),
) => {
  return server.get('/userProfile/check-in', (...args) => {
    return wrapCountResponseArray(endpoint(...args));
  });
};

export const mockSaveSelfCheckIn = (
  endpoint: MockEndpoint<''>,
  server = getMockServer(),
) => {
  return server.post('appt/:apptId/check-in/:code', (...args) =>
    endpoint(...args),
  );
};

export type MockUserAppointmentResponse = DeepReadonly<
  DeepPartial<UserAppointmentResponseData>
>;
export const mockGetUserAppts = (
  endpoint: MockEndpoint<MockUserAppointmentResponse>,
  server = getMockServer(),
) => {
  return server.get('appt/user/:userId', (...args) => endpoint(...args));
};

export const mockCancelUserAppt = (
  endpoint: MockEndpoint<number>,
  server = getMockServer(),
) => {
  return server.post('appt/:id/cancel', (...args) => endpoint(...args));
};

export const appointmentEndpoints = (server: MockServer) => {
  const today = new Date();
  server.get('appt', (schema) =>
    wrapCountResponseCollection(schema.all('appointment')),
  );

  server.get('appt/existing', (schema, request) => {
    const statuses = request.queryParams.status as unknown as
      | Array<AppointmentStatus>
      | undefined;
    const fromDate = request.queryParams.fromDate as string;
    const toDate = request.queryParams.toDate as string;
    return wrapCountResponseCollection(
      schema.where(
        'appointment',
        (appt) =>
          appt.appt_time &&
          appt.status !== 'FREE' &&
          appt.status !== 'CANCELLED' &&
          (!statuses || statuses.includes(appt.status!)) &&
          isBefore(parseIsoDate(fromDate), parseIsoDate(appt.appt_time)) &&
          isAfter(parseIsoDate(toDate), parseIsoDate(appt.appt_time)),
      ),
    );
  });
  mockGetUserAppts((schema, request) => {
    const userId = request.params.userId;
    return {
      booked: schema.where(
        'appointment',
        (appt) =>
          appt.status === 'BOOKED' &&
          appt.user_member_id === userId &&
          isInFuture(parseIsoDate(appt.appt_time!!)),
      ).models,
      canceled: schema.where(
        'appointment',
        (appt) => appt.status === 'CANCELLED' && appt.user_member_id === userId,
      ).models,
      past: schema.where(
        'appointment',
        (appt) =>
          appt.status !== 'FREE' &&
          appt.status !== 'CANCELLED' &&
          appt.status !== 'BOOKED' &&
          appt.user_member_id === userId,
      ).models,
    };
  }, server);

  mockAvailableAppts(
    createApptsAnyDay(
      [
        [8, 15],
        // [15, 30],
        // [18, 0],
      ],
      15,
      'FREE',
    ),
    server,
  );
  mockAvailableDays(
    [
      formatDateOnlyISO(addDays(today, 14)),
      formatDateOnlyISO(addDays(today, 80)),
      formatDateOnlyISO(addDays(today, 82)),
      formatDateOnlyISO(addDays(today, 83)),
      formatDateOnlyISO(addDays(today, 84)),
      formatDateOnlyISO(addDays(today, 85)),
      formatDateOnlyISO(addDays(today, 86)),
      formatDateOnlyISO(addDays(today, 200)),
    ],
    server,
  );

  mockGetSelfCheckInAppointments(
    createApptsAnyDay(
      [
        [8, 15],
        [15, 30],
        [18, 0],
      ],
      15,
      'BOOKED',
    ),
    server,
  );

  server.post('appt/:id/call', (schema, request) => {
    const id = request.params.id;
    schema.find('appointment', id)?.update({
      status: 'CALLED',
    });
    return 200;
  });
  server.post('appt/:id/mia', (schema, request) => {
    const id = request.params.id;
    schema.find('appointment', id)?.update({ status: 'MIA' });
    return 200;
  });
  server.post('appt/:id/process', (schema, request) => {
    const id = request.params.id;
    schema.find('appointment', id)?.update({ status: 'PROCESSING' });
    return 200;
  });
  server.post('appt/:id/lock', (schema, request) => {
    const id = request.params.id;
    schema.find('appointment', id)?.update({ status: 'LOCKED' });
    return 200;
  });
  server.post('appt/:id/unlock', (schema, request) => {
    const id = request.params.id;
    schema.find('appointment', id)?.update({ status: 'FREE' });
    return 200;
  });
  server.post('appt/:id/taken', (schema, request) => {
    const id = request.params.id;
    schema.find('appointment', id)?.update({ status: 'TAKEN' });
    return 200;
  });
  server.post('appt/:id/notTaken', (schema, request) => {
    const id = request.params.id;
    schema.find('appointment', id)?.update({ status: 'NOT_TAKEN' });
    return 200;
  });
  server.post('appt/:id/queue', (schema, request) => {
    const id = request.params.id;
    schema.find('appointment', id)?.update({ status: 'QUEUE' });
    return 200;
  });
  mockBookAppt((schema, request) => {
    const id = request.params.id;
    const appointmentUpdate = JSON.parse(request.requestBody);
    schema
      .find('appointment', id)
      ?.update({ ...appointmentUpdate, status: 'BOOKED' });
    return 200;
  }, server);
  server.post('appt/:id/notTaken', (schema, request) => {
    const id = request.params.id;
    schema.find('appointment', id)?.update({ status: 'NOT_TAKEN' });
    return 200;
  });
  server.post('appt/:id/notPrepared', (schema, request) => {
    const id = request.params.id;
    schema.find('appointment', id)?.update({ status: 'NOT_PREPARED' });
    return 200;
  });
  mockCancelUserAppt((schema, request) => {
    const id = request.params.id;
    schema.find('appointment', id)?.update({ status: 'CANCELLED' });
    return 200;
  }, server);
  server.put('appt/:id', (schema, request) => {
    const id = request.params.id;
    const update = JSON.parse(request.requestBody);
    schema.find('appointment', id)?.update(update);
    return 200;
  });
  mockCancelApptByToken(() => 200, server);
  mockSaveSelfCheckIn(() => '', server);
};

export const appointmentSeeds = (server: MockServer) => {
  seedAppointmentsWithAllCalendarCombinations(server);
  seedQueuedAppointments(server);
};

const seedQueuedAppointments = (server: MockServer) => {
  for (let i = 0; i < 6; i++) {
    server.create('appointment', {
      appt_time: getAppointmentTime('today', 8, i * 30),
      status: 'QUEUE',
      checkin_time: getAppointmentTime('today', 8, (i - 1) * 30),
      appt_flags: i % 2 ? getAppointmentFlagId('alert') : 0,
      user_full_name: `${DEFAULT_LOGGED_IN_TEST_USER.firstname} ${DEFAULT_LOGGED_IN_TEST_USER.lastname}`,
      resource_type_name: 'Calendar',
      resource_type_abbreviation: 'CA',
      user_member_id: DEFAULT_LOGGED_IN_TEST_USER.id,
    });
  }
};

const seedAppointmentsWithAllCalendarCombinations = (server: MockServer) => {
  for (let i = 0; i < 2; i++) {
    server.create('appointment', {
      appt_time: getAppointmentTime('tomorrow', 8, 0),
      status: 'FREE',
    });
  }

  for (let i = 0; i < 2; i++) {
    server.create('appointment', {
      appt_time: getAppointmentTime('tomorrow', 8, 30),
      status: 'FREE',
    });
  }
  server.create('appointment', {
    appt_time: getAppointmentTime('tomorrow', 8, 30),
    status: 'BOOKED',
    user_full_name: `${TEST_USER_2.firstname} ${TEST_USER_2.lastname}`,
    user_account_id: TEST_USER_2.user_account_id,
    user_member_id: TEST_USER_2.id,
  });
  server.create('appointment', {
    appt_time: getAppointmentTime('tomorrow', 8, 30),
    status: 'BOOKED',
    user_full_name: `${DEFAULT_LOGGED_IN_TEST_USER.firstname} ${DEFAULT_LOGGED_IN_TEST_USER.lastname}`,
    id: DEFAULT_LOGGED_IN_TEST_USER.id,
    user_member_id: DEFAULT_LOGGED_IN_TEST_USER.id,
  });
  server.create('appointment', {
    appt_time: getAppointmentTime('tomorrow', 8, 30),
    status: 'FREE',
    staff_only: true,
  });

  for (let i = 0; i < 30; i++) {
    server.create('appointment', {
      appt_time: getAppointmentTime('tomorrow', 9, 0),
      status: 'FREE',
      staff_only: true,
    });
  }
  server.create('appointment', {
    appt_time: getAppointmentTime('tomorrow', 9, 0),
    status: 'PROCESSING',
    user_full_name: `${TEST_USER_2.firstname} ${TEST_USER_2.lastname}`,
  });

  for (let i = 0; i < 20; i++) {
    server.create('appointment', {
      appt_time: getAppointmentTime('tomorrow', 9, 30),
      status: 'BOOKED',
      user_full_name: `${TEST_USER_2.firstname} ${TEST_USER_2.lastname}`,
    });
  }

  for (let i = 0; i < 6; i++) {
    server.create('appointment', {
      appt_time: getAppointmentTime('tomorrow', 10, 0),
      status: 'LOCKED',
      user_full_name: `${TEST_USER_2.firstname} ${TEST_USER_2.lastname}`,
    });
  }

  server.create('appointment', {
    appt_time: getAppointmentTime('today', 10, 0),
    status: 'TAKEN',
    user_full_name: `${TEST_USER_2.firstname} ${TEST_USER_2.lastname}`,
  });
  server.create('appointment', {
    appt_time: getAppointmentTime('today', 10, 0),
    status: 'NOT_TAKEN',
    user_full_name: `${TEST_USER_2.firstname} ${TEST_USER_2.lastname}`,
  });
  server.create('appointment', {
    appt_time: getAppointmentTime('today', 10, 0),
    status: 'MIA',
    user_full_name: `${TEST_USER_2.firstname} ${TEST_USER_2.lastname}`,
  });
  server.create('appointment', {
    appt_time: getAppointmentTime('today', 10, 0),
    status: 'MIA',
    appt_flags: getAppointmentFlagId(APPOINTMENT_FLAG_ALERT),
    user_full_name: `${TEST_USER_2.firstname} ${TEST_USER_2.lastname}`,
  });
  server.create('appointment', {
    appt_time: getAppointmentTime('today', 10, 0),
    status: 'DNA',
    user_full_name: `${TEST_USER_2.firstname} ${TEST_USER_2.lastname}`,
  });
  server.create('appointment', {
    appt_time: getAppointmentTime('today', 10, 0),
    status: 'CANCELLED',
    user_full_name: `${TEST_USER_2.firstname} ${TEST_USER_2.lastname}`,
    cancelled_by_member_full_name: `${TEST_USER_2.firstname} ${TEST_USER_2.lastname}`,
    cancelled_by_member_id: TEST_USER_2.id,
    cancel_time: formatISO(new Date()),
  });
  server.create('appointment', {
    appt_time: getAppointmentTime('today', 10, 0),
    status: 'NOT_PREPARED',
    user_full_name: `${TEST_USER_2.firstname} ${TEST_USER_2.lastname}`,
  });

  server.create('appointment', {
    appt_time: getAppointmentTimeStartOfNextHour(),
    status: 'BOOKED',
    user_full_name: `${TEST_USER_2.firstname} ${TEST_USER_2.lastname}`,
  });
  server.create('appointment', {
    appt_time: getAppointmentTimeStartOfNextHour(),
    status: 'QUEUE',
    user_full_name: `${TEST_USER_2.firstname} ${TEST_USER_2.lastname}`,
    user_account_id: TEST_USER_2.user_account_id,
  });
  server.create('appointment', {
    appt_time: getAppointmentTimeStartOfNextHour(),
    status: 'CALLED',
    user_full_name: `Called guy`,
    gp_surgery_id: DEFAULT_LOGGED_IN_TEST_USER.gp_surgery_id,
  });
  server.create('appointment', {
    appt_time: formatISO(
      addDays(parseIsoDate(getAppointmentTimeStartOfNextHour()), 7),
    ),
    status: 'BOOKED',
    user_full_name: `Next Week`,
  });
};
