import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { ProtectedRoute } from '../../components/common/ProtectedRoute';
import { SideMenu } from '../../components/SideMenu/SideMenu';
import { AdminSideMenuTree } from '../../configs/RoutesConfig';
import { AdminPatients } from '../../containers/admin/AdminPatients';
import {
  AdminSitePreferences,
  adminSitePreferencesAllowedTabs,
} from '../../containers/admin/AdminSitePreferences';
import { AdminStaff } from '../../containers/admin/AdminStaff';
import { AuditPage } from '../../containers/admin/AuditPage';
import { AdminDepartmentsPage } from '../../containers/admin/AdminDepartmentsPage';
import { TextsPage } from '../../containers/admin/TextsPage';
import { TrustsPage } from '../../containers/admin/TrustsPage';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import {
  AdminTrustPreferences,
  adminTrustPreferencesTab,
} from '../../containers/admin/AdminTrustPreferences';
import { AdminStatisticsPage } from '../../containers/admin/AdminStatisticsPage';
import {
  ADMIN_STATISTICS_LAST_FIVE_YEARS_URL,
  ADMIN_STATISTICS_OVERVIEW_URL,
  ADMIN_STATISTICS_URL,
  ADMIN_URL,
} from '../AppRoutes';

export const AdminMenuAndRoutes: React.FunctionComponent = () => {
  const { t } = useCommonTranslation();
  return (
    <>
      <SideMenu navigations={AdminSideMenuTree(t)} />
      <Switch>
        <ProtectedRoute
          exact
          path={`${ADMIN_URL}/departments`}
          render={() => <AdminDepartmentsPage />}
        />
        <ProtectedRoute
          exact
          path={`${ADMIN_URL}/departments/trusts`}
          render={() => <TrustsPage />}
        />
        <ProtectedRoute
          exact
          path={`${ADMIN_URL}/audit`}
          render={() => <AuditPage />}
        />
        <ProtectedRoute
          path={`${ADMIN_URL}/texts`}
          render={() => <TextsPage />}
        />
        <ProtectedRoute path={`${ADMIN_URL}/site/preferences/:siteId`}>
          <Switch>
            <ProtectedRoute
              exact
              path={`${ADMIN_URL}/site/preferences/:siteId/:tab(${adminSitePreferencesAllowedTabs.join(
                '|',
              )})`}
              render={() => <AdminSitePreferences />}
            />
            <Redirect to={`${ADMIN_URL}/site/preferences/:siteId/general`} />
          </Switch>
        </ProtectedRoute>
        <ProtectedRoute path={`${ADMIN_URL}/trust/preferences/:trustId`}>
          <Switch>
            <ProtectedRoute
              exact
              path={`${ADMIN_URL}/trust/preferences/:trustId/:tab(${adminTrustPreferencesTab.join(
                '|',
              )})`}
              render={() => <AdminTrustPreferences />}
            />
            <Redirect
              to={`${ADMIN_URL}/trust/preferences/:trustId/mini-site`}
            />
          </Switch>
        </ProtectedRoute>
        <ProtectedRoute path={`${ADMIN_URL}/users`}>
          <Switch>
            <ProtectedRoute
              exact
              path={`${ADMIN_URL}/users/patients`}
              render={() => <AdminPatients />}
            />
            <ProtectedRoute
              exact
              path={`${ADMIN_URL}/users/staff`}
              render={() => <AdminStaff />}
            />
            <Redirect to={`${ADMIN_URL}/users/patients`} />
          </Switch>
        </ProtectedRoute>
        <ProtectedRoute path={ADMIN_STATISTICS_URL}>
          <Switch>
            <ProtectedRoute
              exact
              path={ADMIN_STATISTICS_OVERVIEW_URL}
              render={() => <AdminStatisticsPage selected={'OVERVIEW'} />}
            />
            <ProtectedRoute
              exact
              path={ADMIN_STATISTICS_LAST_FIVE_YEARS_URL}
              render={() => (
                <AdminStatisticsPage selected={'LAST_FIVE_YEARS'} />
              )}
            />
            <Redirect to={`${ADMIN_URL}/statistics/overview`} />
          </Switch>
        </ProtectedRoute>
        <Redirect to={`${ADMIN_URL}/departments`} />
      </Switch>
    </>
  );
};
