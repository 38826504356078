import { WaitingListRoute } from 'containers/patient/waitingList/WaitingListPage';
import { useParams } from 'react-router';
import { patientURL } from 'routes/AppRoutes';

export type WaitingListParams = { memberId: string; page?: WaitingListRoute };

export const useWaitingListURL = (page: WaitingListRoute | '' = '') => {
  const { memberId } = useParams<WaitingListParams>();
  return `${patientURL}/waiting-list/${memberId}/${page}`;
};
