import React, { Fragment } from 'react';
import { HeaderGroup } from 'react-table';
import { CellWrapper, FiltersRow, Th } from './Table.styled';
import { headerProps } from './TableHeaderWithFilters';
import { WrappedCell } from './WrappedCell';

interface TableHeaderFiltersProps<D extends object> {
  headerGroups: Array<HeaderGroup<D>>;
}

export const TableHeaderFilters = <T extends object>({
  headerGroups,
}: TableHeaderFiltersProps<T>) => {
  return (
    <>
      {headerGroups.map((headerGroup, i) => {
        const columnControls = headerGroup.headers.find(
          (h) => h.columnControls,
        );
        const renderedColumnControls = columnControls ? (
          <WrappedCell column={columnControls} renderId={'columnControls'} />
        ) : undefined;
        return (
          <Fragment key={`header_group_filters_${i}`}>
            <FiltersRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps(headerProps)}
                  className="head-input"
                >
                  {column.canFilter && (
                    <CellWrapper
                      style={{
                        marginLeft: 'var(--s2)',
                        marginRight: 'var(--s3)',
                      }}
                    >
                      {column.canFilter && column.render('Filter')}
                    </CellWrapper>
                  )}
                  {column.columnControls &&
                    (!column.renderOnSecondRow ? (
                      renderedColumnControls
                    ) : (
                      <div
                        style={{
                          minWidth: columnControls?.minWidth,
                          maxWidth: columnControls?.maxWidth,
                        }}
                      />
                    ))}
                </Th>
              ))}
            </FiltersRow>
            {columnControls?.renderOnSecondRow && (
              <FiltersRow>{renderedColumnControls}</FiltersRow>
            )}
          </Fragment>
        );
      })}
    </>
  );
};
