import {
  Appointment,
  AppointmentFlags,
  UserAppointmentsTypes,
} from 'types/appointments';
import {
  addMinutes,
  DATE_FNS_TIME,
  formatDate,
  formatDateKeepTZ,
  formatHourMinutes,
  MINUTES_IN_HOUR,
  parseDateKeepTZ,
  SECONDS_IN_MINUTE,
} from './dateUtil';

export const getApptRangeStr = (appt: Appointment) =>
  formatDateKeepTZ(appt.appt_time, DATE_FNS_TIME) +
  ' - ' +
  formatDate(
    addMinutes(parseDateKeepTZ(appt.appt_time), appt.appt_length),
    DATE_FNS_TIME,
  );

export const getAppointmentStartMinutesPerHour = (
  slotLengthInMinutes: number,
) => {
  const slotNumber = MINUTES_IN_HOUR / slotLengthInMinutes;
  return Array.from(
    { length: slotNumber },
    (_, index) => index * slotLengthInMinutes,
  );
};

export const getDaySlotsByHour = ({
  slotLengthInMinutes,
  openHourInSeconds,
  closeHourInSeconds,
}: {
  openHourInSeconds: number;
  closeHourInSeconds: number;
  slotLengthInMinutes: number;
}) => {
  const daySlots = getDaySlots({
    openHourInSeconds,
    closeHourInSeconds,
    slotLengthInMinutes,
  });

  const slotsByHour: {
    hour: number;
    minutes: number[];
  }[] = [];

  daySlots.forEach((slot) => {
    let hourSlot = slotsByHour.find(({ hour }) => hour === slot.hour);
    if (!hourSlot) {
      hourSlot = {
        hour: slot.hour,
        minutes: [],
      };
      slotsByHour.push(hourSlot);
    }
    hourSlot.minutes.push(slot.minute);
  });

  return slotsByHour;
};

export const getDaySlots = ({
  slotLengthInMinutes,
  openHourInSeconds,
  closeHourInSeconds,
}: {
  openHourInSeconds: number;
  closeHourInSeconds: number;
  slotLengthInMinutes: number;
}) => {
  const startHourInMinutes = Math.floor(openHourInSeconds / SECONDS_IN_MINUTE);

  const numberOfSlots =
    (closeHourInSeconds - openHourInSeconds) /
    (SECONDS_IN_MINUTE * slotLengthInMinutes);

  return Array.from(
    {
      length: numberOfSlots,
    },
    (_, slotIndex) => {
      const minutesOfDay = startHourInMinutes + slotIndex * slotLengthInMinutes;
      const slotHour = Math.floor(minutesOfDay / MINUTES_IN_HOUR);
      const slotMinute = minutesOfDay % MINUTES_IN_HOUR;
      return {
        hour: slotHour,
        minute: slotMinute,
        time: formatHourMinutes(slotHour, slotMinute),
      };
    },
  );
};

export const getReferenceNumber = (
  bookedByFullName: string,
  appointmentTime: Date,
) => {
  const names = bookedByFullName?.split(/\s/);
  /**
   * Fallback char used for missing name initial.
   */
  const missingInitialFallbackChar = '0';
  const initials = [
    names[0],
    names.length > 1 ? names[names.length - 1] : undefined,
  ]
    .map((name) => (name ? name[0] : missingInitialFallbackChar))
    .join('');
  const formattedDate = formatDate(appointmentTime, 'ddMMMHHmm');
  return `${initials}${formattedDate}`.toLocaleUpperCase();
};

export const APPOINTMENT_FLAG_ALERT: AppointmentFlags = 'alert';

export const APPOINTMENT_FLAGS_BITWISE_IDS = {
  alert: 1,
  urgent: 2,
  fast: 4,
  inr: 8,
  doac: 16,
};

export const getAppointmentFlagId = (appointmentFlag: AppointmentFlags) => {
  switch (appointmentFlag) {
    case APPOINTMENT_FLAG_ALERT:
      return 1;
    case 'urgent':
      return 2;
    case 'fast':
      return 3;
  }
  console.error('Unknown appointment flag', appointmentFlag);
  return 0;
};
export const USER_APPOINTMENTS_TYPES_BOOKED: UserAppointmentsTypes = 'booked';
export const USER_APPOINTMENTS_TYPES_CANCELLED: UserAppointmentsTypes =
  'cancelled';
export const USER_APPOINTMENTS_TYPES_PAST: UserAppointmentsTypes = 'past';
