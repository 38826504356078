import { CommonLink } from 'components/common/Forms/Button';
import { FormActionsStyle } from 'components/common/Forms/Forms.styled';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { patientURL } from 'routes/AppRoutes';
import { BookedAppointmentsList } from './BookedAppointmentsList';
import { MyAppointmentsCommonPage } from './MyAppointmentsCommonPage';

export const MyAppointmentsBooked = () => {
  const { t } = useCommonTranslation();

  return (
    <MyAppointmentsCommonPage title={t('my-appointments')}>
      <BookedAppointmentsList />
      <FormActionsStyle align="right" topMargin>
        <CommonLink variant="primary" to={`${patientURL}/booking`}>
          {t('book-an-appointment')}
        </CommonLink>
        <CommonLink
          variant="secondary"
          size="large"
          to={`${patientURL}/my-appointments/past`}
        >
          {t('past-appointments')}
        </CommonLink>
        <CommonLink variant="secondary" size="large" to={patientURL}>
          {t('back-to-menu')}
        </CommonLink>
      </FormActionsStyle>
    </MyAppointmentsCommonPage>
  );
};
