import { FormRow } from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import React, { ReactElement, useEffect } from 'react';
import { FormTitle } from './SignupForm.styled';
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form/dist/types/form';
import { getHookFormTyped } from '../../../../utils/helpers';
import { Controller, FieldErrors, UseFormWatch } from 'react-hook-form';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useUserValidationRules } from '../../../../hooks/patient/useUserValidationRules';
import { TrustData } from '../../../../types/trusts';
import { SiteData } from '../../../../types/sites';
import { InputPhoneNumber } from '../../../common/Forms/InputPhoneNumber';

type SubFormType = {
  firstname: string;
  lastname: string;
  password: string;
  confirmPassword: string;
} & { [key: string]: any };

interface Props<T extends SubFormType> {
  completeRegister?: boolean;
  errors: FieldErrors<T>;
  register: UseFormRegister<T>;
  setValue: UseFormSetValue<T>;
  watch: UseFormWatch<T>;
  trigger: UseFormTrigger<T>;
  getValues: UseFormGetValues<T>;
  control: Control<T>;
  isLoading: boolean;
  trust: TrustData;
  site: SiteData;
}

export const SignupStep2ContactDetails = <T extends SubFormType>({
  completeRegister,
  isLoading,
  trust,
  site,
  ...rest
}: Props<T>): ReactElement => {
  const { t } = useCommonTranslation();
  const { register, errors, watch, trigger, getValues, control } =
    getHookFormTyped<SubFormType, T>(rest);
  const { emailRules, surnameRules, firstnameRules, phoneRules } =
    useUserValidationRules({
      trusts: [trust],
      emailRequired: true,
    });

  const password = watch('password');
  useEffect(() => {
    if (password && getValues('confirmPassword')) {
      trigger('confirmPassword');
    }
  }, [password, getValues, trigger]);

  return (
    <>
      <FormTitle>
        {t('lets-get-started') + ' (' + site.short_name + ')'}
      </FormTitle>
      <FormRow>
        <Input
          label={t('first-name')}
          placeholder={t('first-name')}
          disabled={isLoading}
          {...register('firstname', firstnameRules)}
          helperText={errors.firstname?.message}
          hasError={Boolean(errors?.firstname)}
          required
          autoFocus
        />
        <Input
          label={t('surname')}
          placeholder={t('surname')}
          disabled={isLoading}
          {...register('lastname', surnameRules)}
          helperText={errors.lastname?.message}
          hasError={Boolean(errors?.lastname)}
          required
        />
      </FormRow>
      <FormRow>
        <Input
          label={t('email')}
          placeholder={t('email')}
          disabled={completeRegister || isLoading}
          {...register('email', emailRules)}
          helperText={errors.email?.message}
          hasError={Boolean(errors?.email)}
          required
        />
      </FormRow>
      {!completeRegister && (
        <FormRow>
          <Input
            autoComplete={'new-password'}
            label={t('password')}
            placeholder={t('password')}
            type="password"
            prefilled={completeRegister}
            disabled={completeRegister || isLoading}
            {...register('password', {
              required: t('must-not-empty') as string,
              pattern: {
                value: /\d/,
                message: t('password-pattern-validation') as string,
              },
              minLength: {
                value: 8,
                message: t('password-minlength-validation') as string,
              },
            })}
            helperText={errors.password?.message}
            hasError={Boolean(errors?.password)}
            required
          />
          <Input
            autoComplete={'new-password'}
            label={t('confirm-password')}
            type="password"
            placeholder={t('confirm-password')}
            disabled={completeRegister || isLoading}
            prefilled={completeRegister}
            {...register('confirmPassword', {
              required: t('must-not-empty') as string,
              validate: (value) =>
                value === watch('password') ||
                (t('confirm-password-validation') as string),
            })}
            helperText={errors.confirmPassword?.message}
            hasError={Boolean(errors?.confirmPassword)}
            required
          />
        </FormRow>
      )}
      <FormRow>
        <Controller
          name="phone"
          control={control}
          rules={phoneRules}
          render={({ field }) => (
            <InputPhoneNumber
              label={t('phone-number')}
              placeholder={t('phone-number')}
              disabled={isLoading}
              {...field}
              required={!!phoneRules.required}
              helperText={errors.phone?.message}
              hasError={Boolean(errors?.phone)}
            />
          )}
        />
      </FormRow>
    </>
  );
};
