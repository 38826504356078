export const makeCSVBlobWithHeader = <D>(
  headers: Array<{
    label: string;
    key: keyof D;
  }>,
  rows: D[],
) => {
  return makeCsvBlob([
    headers.map((header) => header.label),
    ...rows.map((row) => {
      return headers.map((header) => {
        return row[header.key];
      });
    }),
  ]);
};

const makeCsvBlob = async (rows: any[]) => {
  const separator: string = ';';
  const keys: string[] = Object.keys(rows[0]);
  const csvContent = rows
    .map((row) =>
      keys
        .map((k) => {
          const value = row[k];
          const rawCell = value === null || value === undefined ? '' : value;
          let cell =
            rawCell instanceof Date
              ? rawCell.toLocaleString()
              : `${rawCell}`.replace(/"/g, '""');
          if (cell.search(/([",\n])/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        })
        .join(separator),
    )
    .join('\n');

  return new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
};
