import CheckboxInput from 'components/common/Forms/CheckboxInput';
import {
  CheckBoxesWrapper,
  DialogFormWrapper,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import { CardLoader } from 'components/common/Loading';
import React, { useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { FaEnvelope } from 'react-icons/fa';

import { UseEmailsPreferencesFormReturn } from './EmailsPreferencesHooks';
import { useEmailRulingFormKeysValues } from './EmailingRulingFormHooks';
import {
  SERVER_EMAIL_ALLOW_APPT,
  SERVER_EMAIL_ALLOW_CANCEL,
  SERVER_EMAIL_ALLOW_CONFIRMATION,
  SERVER_EMAIL_ALLOW_CREATE,
  SERVER_EMAIL_ALLOW_DAILY_REPORT,
  SERVER_EMAIL_ALLOW_EMAIL_UPDATE,
  SERVER_EMAIL_ALLOW_REMINDER,
  SERVER_EMAIL_ALLOW_SUPPORT_FORM,
  SERVER_EMAIL_ALLOW_WAITING_LIST,
} from '../../../../configs/siteAndTrustAttributes';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';

interface Props {
  isFetching: boolean;
  attributesForm: UseEmailsPreferencesFormReturn;
}

export const EmailingRulingForm: React.FC<Props> = ({
  isFetching,
  attributesForm,
}) => {
  const { t } = useCommonTranslation();

  const { attributeRegister, control, handleChange } = attributesForm;
  const watchedValues = useWatch({ control });

  const keysValues = useEmailRulingFormKeysValues({ watchedValues });
  const allOn = Object.values(keysValues).every(Boolean);
  const toggleAllOnOff = useCallback(
    (on: boolean) => {
      Object.keys(keysValues).forEach((key) => handleChange(key, on));
    },
    [handleChange, keysValues],
  );

  return (
    <DialogFormWrapper>
      <FormHeader>
        <FaEnvelope />
        <FormTitle>{t('emailing-rules')}</FormTitle>
      </FormHeader>
      <FormRow>
        <CheckBoxesWrapper isVertical>
          <CheckboxInput
            {...attributeRegister(
              SERVER_EMAIL_ALLOW_CREATE,
              (e) => e.target.checked,
            )}
            label={t('allow-sending-create-account-emails')}
            key={SERVER_EMAIL_ALLOW_CREATE}
            id={SERVER_EMAIL_ALLOW_CREATE}
            checked={keysValues.serverEmailAllowCreate}
          />
          <CheckboxInput
            {...attributeRegister(
              SERVER_EMAIL_ALLOW_CONFIRMATION,
              (e) => e.target.checked,
            )}
            label={t('allow-sending-appt-confirmation-emails')}
            key={SERVER_EMAIL_ALLOW_CONFIRMATION}
            id={SERVER_EMAIL_ALLOW_CONFIRMATION}
            checked={keysValues.serverEmailAllowConfirmation}
          />
          <CheckboxInput
            {...attributeRegister(
              SERVER_EMAIL_ALLOW_CANCEL,
              (e) => e.target.checked,
            )}
            label={t('allow-sending-appt-cancel-emails')}
            key={SERVER_EMAIL_ALLOW_CANCEL}
            id={SERVER_EMAIL_ALLOW_CANCEL}
            checked={keysValues.serverEmailAllowCancel}
          />
          <CheckboxInput
            {...attributeRegister(
              SERVER_EMAIL_ALLOW_REMINDER,
              (e) => e.target.checked,
            )}
            label={t('allow-sending-appt-reminder-emails')}
            key={SERVER_EMAIL_ALLOW_REMINDER}
            id={SERVER_EMAIL_ALLOW_REMINDER}
            checked={keysValues.serverEmailAllowReminder}
          />
          <CheckboxInput
            {...attributeRegister(
              SERVER_EMAIL_ALLOW_APPT,
              (e) => e.target.checked,
            )}
            label={t('allow-sending-appt-post-emails')}
            key={SERVER_EMAIL_ALLOW_APPT}
            id={SERVER_EMAIL_ALLOW_APPT}
            checked={keysValues.serverEmailAllowAppt}
          />
        </CheckBoxesWrapper>
        <CheckBoxesWrapper isVertical>
          <CheckboxInput
            {...attributeRegister(
              SERVER_EMAIL_ALLOW_EMAIL_UPDATE,
              (e) => e.target.checked,
            )}
            label={t('allow-sending-email-update-emails')}
            key={SERVER_EMAIL_ALLOW_EMAIL_UPDATE}
            id={SERVER_EMAIL_ALLOW_EMAIL_UPDATE}
            checked={keysValues.serverEmailAllowEmailUpdate}
          />
          <CheckboxInput
            {...attributeRegister(
              SERVER_EMAIL_ALLOW_DAILY_REPORT,
              (e) => e.target.checked,
            )}
            label={t('allow-sending-daily-report-emails')}
            key={SERVER_EMAIL_ALLOW_DAILY_REPORT}
            id={SERVER_EMAIL_ALLOW_DAILY_REPORT}
            checked={keysValues.serverEmailAllowDailyReport}
          />
          <CheckboxInput
            {...attributeRegister(
              SERVER_EMAIL_ALLOW_WAITING_LIST,
              (e) => e.target.checked,
            )}
            label={t('allow-sending-waiting-list-emails')}
            key={SERVER_EMAIL_ALLOW_WAITING_LIST}
            id={SERVER_EMAIL_ALLOW_WAITING_LIST}
            checked={keysValues.serverEmailAllowWaitingList}
          />
          <CheckboxInput
            label={t('allow-sending-password-reset-emails')}
            title={t('allow-sending-password-reset-emails-title')}
            name={SERVER_EMAIL_ALLOW_REMINDER}
            disabled
            checked={true}
          />
          <CheckboxInput
            {...attributeRegister(
              SERVER_EMAIL_ALLOW_SUPPORT_FORM,
              (e) => e.target.checked,
            )}
            label={t('allow-sending-support-emails')}
            key={SERVER_EMAIL_ALLOW_SUPPORT_FORM}
            id={SERVER_EMAIL_ALLOW_SUPPORT_FORM}
            checked={keysValues.serverEmailAllowSupportForm}
          />
        </CheckBoxesWrapper>
      </FormRow>
      <FormRow>
        <CheckboxInput
          name="allOnOff"
          label={t('emails-all-on-off-toggle')}
          isToggle={true}
          onChange={(e) => toggleAllOnOff(e.target.checked)}
          checked={allOn}
        />
      </FormRow>
      {isFetching && <CardLoader fillWrapper={true} />}
    </DialogFormWrapper>
  );
};
