import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';

interface Props extends RouteComponentProps {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

const environment = process.env.NODE_ENV;

const errorPath = '/500';

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public componentDidUpdate(prevProps: Props) {
    /**
     * Reset error state when navigating to a new page, otherwise impossible to navigate from error page.
     */
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ hasError: false });
    }
  }

  public render() {
    const isErrorPage = this.props.location.pathname === errorPath; // prevent infinite loop
    if (this.state.hasError && environment !== 'development' && !isErrorPage) {
      return <Redirect to={errorPath} />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
