import { Col, Grid } from 'components/common/Grid';
import { PageHelmet } from 'components/common/PageHelmet';
import { useAuth } from 'context/AuthContext';
import { CardLoader } from '../../components/common/Loading';
import { StaffForm } from '../../components/views-components/staff/settings/users/StaffForm';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { useUser } from '../../query/users';
import { StaffLayout } from './StaffLayout';
import { useStaffRolesAndGPData } from './settings/users/useStaffRolesAndGPData';
import { useStaffUserCreateUpdate } from '../../hooks/staff/useStaffUserCreateUpdate';
import { errorToast, successToast } from '../../utils/toast';
import {
  FormHeader,
  FormTitle,
} from '../../components/common/Forms/Forms.styled';
import { FaUserCog } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { STAFF_URL } from '../../routes/AppRoutes';
import { getUserProfile } from '../../services/userProfile';
import { useCallback, useState } from 'react';

export const StaffProfile = () => {
  const { t } = useCommonTranslation();
  const history = useHistory();
  const { sangixUser, updateAuthUser } = useAuth();
  const { data: userResponse, isLoading: isUserLoading } = useUser(
    sangixUser?.main_member_id,
  );
  const { rolesData, GPData } = useStaffRolesAndGPData();
  const [isUserUpdateLoading, setIsUserUpdateLoading] = useState(false);
  const { staffUserCreateOrUpdate } = useStaffUserCreateUpdate();
  const submitHandler: typeof staffUserCreateOrUpdate = useCallback(
    async (...input) => {
      setIsUserUpdateLoading(true);
      try {
        await staffUserCreateOrUpdate(...input);
        try {
          const { data } = await getUserProfile();
          updateAuthUser({
            sangixUser: data,
          });
          successToast(t('profile-updated-successfully'));
        } catch (error) {
          errorToast(error);
        }
      } finally {
        setIsUserUpdateLoading(false);
      }
    },
    [t, staffUserCreateOrUpdate, updateAuthUser],
  );

  const isLoading = isUserLoading || isUserUpdateLoading;

  return (
    <StaffLayout>
      <PageHelmet title={t('my-profile')} />
      <Grid>
        <Col md={4}>
          {isLoading ? (
            <CardLoader />
          ) : (
            userResponse && (
              <StaffForm
                closeFormHandler={() => {
                  history.push(STAFF_URL);
                }}
                closeButtonText={t('close')}
                submitHandler={submitHandler}
                isLoading={isLoading}
                prefilledData={userResponse?.data}
                GPData={GPData}
                rolesData={rolesData}
                disabledRolesAndGp={true}
                withPasswordForm={false}
                formHeader={
                  <FormHeader>
                    <FaUserCog />
                    <FormTitle>{t('my-profile')}</FormTitle>
                  </FormHeader>
                }
              />
            )
          )}
        </Col>
      </Grid>
    </StaffLayout>
  );
};
