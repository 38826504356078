import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { get } from 'lodash';
import { Controller, FieldError } from 'react-hook-form';
import { FaCalendarAlt } from 'react-icons/fa';
import DatePickerInput, { GlobalDatePickerProps } from '../DatePickerInput';
import { ControlledComponentProps } from './ControlledComponentProps';

type ControlledDatePickerInputProps<FormValues extends object> =
  ControlledComponentProps<FormValues> &
    Omit<GlobalDatePickerProps, 'onChange'> &
    Partial<Pick<GlobalDatePickerProps, 'onChange'>>;

export const ControlledDatePickerInput = <FormValues extends object>({
  control,
  name,
  formState,
  rules,
  onChange,
  ...rest
}: ControlledDatePickerInputProps<FormValues>) => {
  const { t } = useCommonTranslation();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        const error: FieldError = get(formState.errors, name);
        return (
          <DatePickerInput
            label={t('date')}
            name={field.name}
            onChange={(date) => {
              field.onChange(date);
              if (onChange) {
                onChange(date);
              }
            }}
            selected={!field.value ? undefined : String(field.value)}
            hasError={!!error}
            helperText={error?.message}
            icon={<FaCalendarAlt />}
            {...rest}
          />
        );
      }}
    />
  );
};
