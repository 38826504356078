import { PageHelmet } from 'components/common/PageHelmet';
import { StaffLayout } from './StaffLayout';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { useHistory, useParams } from 'react-router';
import { QueuedAppointmentProcess } from './cubicle/QueuedAppointmentProcess';
import { useCurrentUserUnfinishedAppts } from '../../hooks/staff/useCurrentUserUnfinishedAppts';
import { useEffect } from 'react';
import { Loading } from '../../components/common/Loading';
import { errorToast } from '../../utils/toast';
import { STAFF_ENTERED_CUBICLE_URL } from '../../configs/RoutesConfig';

export const StaffCalledAndProcessingAppt = () => {
  const { apptId } = useParams<{ apptId: string }>();
  const { t } = useCommonTranslation();
  const { isFetching, isLoading, userAppts } = useCurrentUserUnfinishedAppts();
  const { replace } = useHistory();

  const selectedAppointment = userAppts?.find(({ id }) => id === apptId);

  useEffect(() => {
    if (!isFetching && !selectedAppointment) {
      errorToast(
        `Unable to find user appointment with id ${apptId} to process.`,
      );
      replace(STAFF_ENTERED_CUBICLE_URL);
    }
  }, [isFetching, selectedAppointment, apptId, replace]);
  return (
    <StaffLayout>
      <PageHelmet title={t('cubicle-list')} />
      {isLoading && <Loading />}
      {selectedAppointment && (
        <QueuedAppointmentProcess
          appointment={selectedAppointment}
          onClose={() => replace(STAFF_ENTERED_CUBICLE_URL)}
        />
      )}
    </StaffLayout>
  );
};
