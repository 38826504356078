import styled from 'styled-components';
import { BulletListItem } from 'styles/components/BulletListItem.styled';
import { cssIf } from 'styles/partials/cssIf';

export const AppointmentItemDateTime = styled.div`
  flex: 1;
`;

export const AppointmentItemDate = styled.div`
  font-size: var(--h4);
  font-weight: bold;
`;

export const AppointmentItemTime = styled.div`
  color: var(--textsecondary);
  font-size: var(--bodysm);
`;

export const AppointmentItemSite = styled.div`
  color: var(--textsecondary);
  text-align: center;
  flex: 1;
`;

export const AppointmentItemRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: right;
`;

export const AppointmentItemStatus = styled.div`
  flex: 1;
  color: var(--textdisabled);
  text-align: right;
  white-space: nowrap;
`;

type AppointmentItemProps = { disabled?: boolean };
export const AppointmentItem = styled(BulletListItem)<AppointmentItemProps>`
  padding: var(--s2) var(--s3);
  ${cssIf('disabled')`
    box-shadow: none;
    border: 1px solid var(--textdisabled);
    ${AppointmentItemDate}, ${AppointmentItemTime}, ${AppointmentItemSite} {
      color: var(--textdisabled);
    }
  `}
`;

export const NoAppointmentsBarStyled = styled.div`
  background-color: var(--blueprimary);
  color: var(--white);
  border-radius: 50px;
  padding: var(--s2);
  display: flex;
  align-items: center;
  font-size: var(--bodysm);
  font-weight: 500;

  svg {
    margin-right: var(--s2);
    height: 16px;
  }
`;
