import styled from 'styled-components';
import {
  CommonButton,
  CommonLink,
} from '../../../components/common/Forms/Button';

export const SitePreferencesHeaderWrapper = styled.div`
  display: flex;
  column-gap: var(--s7);
  align-items: center;
`;

export const SitePreferencesRightControls = styled.div`
  display: flex;
  margin-left: auto;
`;

export const SitePreferencesBackButton = styled(CommonLink)`
  margin-right: var(--s3);
`;

export const SitePreferencesSaveButton = styled(CommonButton)`
  margin-right: var(--s3);
`;
