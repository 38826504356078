import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import {
  DATE_FNS_HOUR_12,
  DATE_FNS_UK_DATE_FORMAT,
  formatDate,
  getHours,
  getMinutes,
  parseDateKeepTZ,
  setHours,
  setMinutes,
} from 'utils/dateUtil';
import {
  PatientBookingList,
  PatientBookingPickBox,
  PatientBookingStageTitle,
  PatientBookingStageTitle2,
  PatientBookingStageTitle3,
} from '../PatientBooking.styled';
import { PatientBookingApptItem } from './PatientBookingApptItem';
import { BookingSlot } from '../../../../hooks/site/useSiteHourRange';
import { Appointment } from '../../../../types/appointments';

interface PatientBookingPickApptWithSlotProps {
  slot: BookingSlot;
  date: Date;
  appointmentLength: number;
  hourAppts: Appointment[];
}

const findApptByMinutes = (appts: Appointment[], minutes: number) => {
  return appts.find(
    (appt) => getMinutes(parseDateKeepTZ(appt.appt_time)) === minutes,
  );
};

export const PatientBookingPickApptWithSlot = ({
  slot,
  date,
  hourAppts,
  appointmentLength,
}: PatientBookingPickApptWithSlotProps) => {
  const { t } = useCommonTranslation();
  const hourFrom = formatDate(
    setMinutes(setHours(new Date(), slot.hour), slot.minutes[0]),
    DATE_FNS_HOUR_12,
  );
  const hourTo = formatDate(slot.lastAppointmentEndTime, DATE_FNS_HOUR_12);

  return (
    <PatientBookingPickBox>
      <PatientBookingStageTitle>
        {t('pick-appointment')}
      </PatientBookingStageTitle>
      <PatientBookingStageTitle2 doubleGap>
        {formatDate(date, DATE_FNS_UK_DATE_FORMAT)}
      </PatientBookingStageTitle2>
      <PatientBookingStageTitle3>
        {t('appointment-range', { from: hourFrom, to: hourTo })}
      </PatientBookingStageTitle3>
      <PatientBookingList doubleGap>
        {slot.minutes.map((minute: number) => (
          <PatientBookingApptItem
            key={minute}
            appt={findApptByMinutes(hourAppts, minute)}
            hour={getHours(date)}
            minute={minute}
            length={appointmentLength}
          />
        ))}
      </PatientBookingList>
    </PatientBookingPickBox>
  );
};
