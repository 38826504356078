import { AxiosError } from 'axios';
import { Card } from 'components/common';
import {
  CloseButton,
  DialogFormWrapper,
  FormActionsStyle,
  FormBody,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaHospital, FaTimes } from 'react-icons/fa';
import { TrustData, TrustFormValues } from 'types/trusts';
import CheckboxInput from '../../../common/Forms/CheckboxInput';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import CommonSelect from '../../../common/Forms/Select';
import { trustNhsNumberPolicyOptions } from '../../../../utils/trustUtil';
import { CommonButton } from '../../../common/Forms/Button';
import { CardLoader } from '../../../common/Loading';

interface Props {
  closeFormHandler: (err?: true | AxiosError) => void;
  submitNewSiteHandler: (data: TrustFormValues, isUpdateSite: boolean) => void;
  isLoading: boolean;
  prefilledData?: TrustData;
}

export const TrustCard: React.FC<Props> = ({
  closeFormHandler,
  submitNewSiteHandler,
  isLoading,
  prefilledData,
}) => {
  const isEdit = Boolean(prefilledData);
  const { t } = useCommonTranslation();
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
    control,
  } = useForm<TrustFormValues>({
    defaultValues: prefilledData,
  });

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  return (
    <Card>
      <DialogFormWrapper>
        <CloseButton
          variant="primary"
          iconOnly
          onClick={() => closeFormHandler()}
        >
          <FaTimes />
        </CloseButton>
        <FormHeader>
          <FaHospital />
          <FormTitle>{t('trust')}</FormTitle>
        </FormHeader>
        <FormBody
          onSubmit={handleSubmit((data) => submitNewSiteHandler(data, isEdit))}
        >
          <FormRow>
            <Input
              label={t('trust-name')}
              placeholder={t('trust-name')}
              helperText={errors.name?.message}
              hasError={Boolean(errors.name)}
              {...register('name', {
                required: t('must-not-empty') as string,
              })}
              required
            />
          </FormRow>
          <FormRow>
            <Controller
              name="nhs_number_policy"
              render={({ field }) => (
                <CommonSelect
                  {...field}
                  required
                  placeholder={t('trust-nhs-number-policy')}
                  label={t('trust-nhs-number-policy')}
                  options={trustNhsNumberPolicyOptions(t)}
                />
              )}
              control={control}
              rules={{
                required: t('must-not-empty') as string,
              }}
            />
          </FormRow>
          <FormRow
            style={{
              marginBottom: 'var(--s3)',
            }}
          >
            <CheckboxInput
              {...register(`hide_postcode`)}
              label={t('hide-postcode')}
            />
          </FormRow>
          <FormRow
            style={{
              marginBottom: 'var(--s3)',
            }}
          >
            <CheckboxInput
              {...register(`hide_gp_surgery`)}
              label={t('hide-gp-dropdown')}
            />
          </FormRow>
          <FormActionsStyle align="center">
            <CommonButton variant="primary" type="submit">
              {isEdit ? t('save') : t('add')}
            </CommonButton>
            <CommonButton
              type="button"
              variant="secondary"
              onClick={() => closeFormHandler()}
            >
              {t('cancel')}
            </CommonButton>
          </FormActionsStyle>
        </FormBody>
        {isLoading && <CardLoader fillWrapper={true} />}
      </DialogFormWrapper>
    </Card>
  );
};
