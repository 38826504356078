import { useCallback, useEffect, useState } from 'react';

import { useSite } from '../../context/SiteContext';
import { UsersFilters } from '../../types/users';
import { usePaginatedFilters } from '../usePaginatedFilters';

export const useMySiteOnlyUsersFilters = ({
  initialMySiteOnly = false,
}: {
  initialMySiteOnly?: boolean;
}) => {
  const { activeSite } = useSite();
  const [mySiteOnly, setMySiteOnly] = useState(initialMySiteOnly);
  const { filters, setFilters } = usePaginatedFilters<UsersFilters>(
    initialMySiteOnly
      ? {
          activeSiteID: activeSite?.id,
        }
      : undefined,
  );

  const propagateFilters = useCallback(
    (filters: UsersFilters, mySiteOnly: boolean, activeSiteId: string) => {
      if (mySiteOnly) {
        setFilters({
          ...filters,
          activeSiteID: activeSiteId,
          activesite: undefined,
        });
      } else {
        setFilters({
          ...filters,
          activeSiteID: undefined,
        });
      }
    },
    [setFilters],
  );

  const setFilterMySiteAware = useCallback(
    (newFilters: UsersFilters) => {
      propagateFilters(newFilters, mySiteOnly, activeSite?.id);
    },
    [activeSite?.id, mySiteOnly, propagateFilters],
  );

  const setMySiteOnlyWithFilterUpdate = useCallback(
    (newMySiteOnly: boolean) => {
      setMySiteOnly(newMySiteOnly);
      propagateFilters(filters, newMySiteOnly, activeSite?.id);
    },
    [activeSite?.id, filters, propagateFilters],
  );

  useEffect(() => {
    propagateFilters(filters, mySiteOnly, activeSite?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSite?.id, mySiteOnly, propagateFilters]);
  return {
    filters,
    setFilters: setFilterMySiteAware,
    mySiteOnly,
    setMySiteOnly: setMySiteOnlyWithFilterUpdate,
  };
};
