import { useMemo } from 'react';
import { useCommonTranslation } from '../i18n/useCommonTranslation';
import { APPOINTMENT_FLAGS_BITWISE_IDS } from '../../utils/appointmentUtil';
import { FaBolt, FaExclamationCircle, FaUtensils } from 'react-icons/fa';

export type ApptFlagConfig = {
  label: string;
  id: number; //bitwise
  icon?: JSX.Element;
  processingAndGracePeriodVisible?: boolean;
  inDailyReport?: boolean;
};

export const useAllAvailableApptFlags = (): ApptFlagConfig[] => {
  const { t } = useCommonTranslation();
  return useMemo(() => {
    return [
      {
        label: t('appointment-flag-alert'),
        id: APPOINTMENT_FLAGS_BITWISE_IDS.alert,
        icon: <FaExclamationCircle />,
        processingAndGracePeriodVisible: true,
        inDailyReport: false,
      },
      {
        label: t('appointment-flag-fast'),
        id: APPOINTMENT_FLAGS_BITWISE_IDS.fast,
        icon: <FaUtensils />,
        processingAndGracePeriodVisible: false,
        inDailyReport: false,
      },
      {
        label: t('appointment-flag-urgent'),
        id: APPOINTMENT_FLAGS_BITWISE_IDS.urgent,
        icon: <FaBolt />,
        processingAndGracePeriodVisible: false,
        inDailyReport: true,
      },
      {
        label: t('appointment-flag-inr'),
        id: APPOINTMENT_FLAGS_BITWISE_IDS.inr,
        icon: undefined,
        processingAndGracePeriodVisible: false,
        inDailyReport: true,
      },
      {
        label: t('appointment-flag-doac'),
        id: APPOINTMENT_FLAGS_BITWISE_IDS.doac,
        icon: undefined,
        processingAndGracePeriodVisible: false,
        inDailyReport: true,
      },
    ];
  }, [t]);
};
