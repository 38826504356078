import React from 'react';
import {
  IInfoPopupStyleProps,
  InfoPopupContentStyled,
  InfoPopupInfoTextStyled,
  InfoPopupStyled,
} from './Popup.styled';
import { PopupActions, PopupProps } from 'reactjs-popup/dist/types';
import { FaInfoCircle } from 'react-icons/fa';

export type IInfoPopupInfoText = {
  infoText?: string;
  open?: boolean;
};

type ITablePopupProps = IInfoPopupInfoText &
  PopupProps &
  React.RefAttributes<PopupActions> &
  IInfoPopupStyleProps;

export const Popup: React.FunctionComponent<ITablePopupProps> = ({
  infoText,
  children,
  open = true,
  size = 'large',
  ...rest
}) => {
  return (
    <InfoPopupStyled open={open} size={size} {...rest}>
      {infoText && (
        <InfoPopupInfoTextStyled>
          <FaInfoCircle
            style={{
              marginRight: 'var(--s3)',
            }}
          />
          {infoText}
        </InfoPopupInfoTextStyled>
      )}
      <InfoPopupContentStyled size={size}>{children}</InfoPopupContentStyled>
    </InfoPopupStyled>
  );
};
