import CheckboxInput from 'components/common/Forms/CheckboxInput';
import {
  CheckBoxesWrapper,
  DialogFormWrapper,
  FormGroup,
  FormGroupTitle,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import { CardLoader } from 'components/common/Loading';
import {
  ATTRIBUTE_SEPARATOR,
  colorSchemes,
  colorSchemeValueWithBackgroundGraphics,
} from 'configs/constants';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FaPaintRoller } from 'react-icons/fa';

import { UseGeneralSitePreferencesFormReturn } from './GeneralSitePreferencesHooks';
import {
  COLOR_PALETTE,
  ENABLE_BACKGROUND_GRAPHICS,
  VARIANT,
} from '../../../../configs/siteAndTrustAttributes';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { SitePreferencesType, TYPE_ADMIN } from '../SitePreferences';
import Select from '../../../../components/common/Forms/Select';
import { variantResources } from '../../../../locales/variantResources';

interface Props {
  isFetching: boolean;
  attributesForm: UseGeneralSitePreferencesFormReturn;
  type: SitePreferencesType;
}

export const VisualSettings = ({ isFetching, attributesForm, type }: Props) => {
  const { t } = useCommonTranslation();

  const { attributeRegister, control, handleChange } = attributesForm;
  const watchedValues = useWatch({ control });

  const variantOptions = Object.keys(variantResources).map((key) => ({
    value: key,
    label: key,
  }));

  return (
    <DialogFormWrapper>
      <FormHeader>
        <FaPaintRoller />
        <FormTitle>{t('visual-settings')}</FormTitle>
      </FormHeader>
      <FormRow>
        <FormGroup>
          <FormGroupTitle>{t('bg-color-scheme')}</FormGroupTitle>
          <FormRow>
            <CheckBoxesWrapper>
              {colorSchemes(t).map((color) => (
                <CheckboxInput
                  {...attributeRegister(COLOR_PALETTE, (e) => e.target.value, {
                    validate: (value: []) => {
                      return (
                        value.length > 0 || (t('must-not-empty') as string)
                      );
                    },
                  })}
                  onChange={(e) => {
                    handleChange(
                      ATTRIBUTE_SEPARATOR + ENABLE_BACKGROUND_GRAPHICS,
                      e.target.value === colorSchemeValueWithBackgroundGraphics,
                    );
                    handleChange(
                      ATTRIBUTE_SEPARATOR + COLOR_PALETTE,
                      e.target.value,
                    );
                  }}
                  id={'color_' + color.value}
                  key={'color_' + color.value}
                  checked={watchedValues.attributes
                    ?.find((atr) => atr.key === COLOR_PALETTE)
                    ?.value_str?.includes(color.value)}
                  label={color.label}
                  value={color.value}
                  type="radio"
                />
              ))}
            </CheckBoxesWrapper>
          </FormRow>
        </FormGroup>
        {watchedValues.attributes?.find((atr) => atr.key === COLOR_PALETTE)
          ?.value_str === colorSchemeValueWithBackgroundGraphics && (
          <FormRow>
            <FormGroup>
              <FormGroupTitle>{t('graphics')}</FormGroupTitle>
              <FormRow>
                <CheckboxInput
                  {...attributeRegister(
                    ENABLE_BACKGROUND_GRAPHICS,
                    (e) => e.target.checked,
                  )}
                  label={t('enable-bg-graphics')}
                  key={ENABLE_BACKGROUND_GRAPHICS}
                  id={ENABLE_BACKGROUND_GRAPHICS}
                  isToggle={true}
                  checked={Boolean(
                    watchedValues.attributes?.find(
                      (atr) => atr.key === ENABLE_BACKGROUND_GRAPHICS,
                    )?.value_int,
                  )}
                />
              </FormRow>
            </FormGroup>
          </FormRow>
        )}
      </FormRow>
      {type === TYPE_ADMIN && (
        <FormRow>
          <FormGroup>
            <FormGroupTitle>{t('settings-variant')}</FormGroupTitle>
            <FormRow>
              <Select
                options={variantOptions}
                {...attributeRegister(VARIANT, (e) => undefined, {
                  validate: (value: []) => {
                    return value.length > 0 || (t('must-not-empty') as string);
                  },
                })}
                onChange={(lang) => {
                  handleChange(VARIANT, lang);
                }}
                label={t('settings-variant')}
                value={
                  watchedValues?.attributes?.find((atr) => atr.key === VARIANT)
                    ?.value_str
                }
              />
            </FormRow>
          </FormGroup>
        </FormRow>
      )}
      {isFetching && <CardLoader fillWrapper={true} />}
    </DialogFormWrapper>
  );
};
