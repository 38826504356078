import { Report } from '../../types/reports';
import { downloadBinary } from '../../utils/downloadBinary';
import { useCallback, useState } from 'react';
import { useGenerateReportBlob } from '../../query/reports';

const triggerDownload = (
  reportFile: BlobPart,
  report: any,
  {
    fromHour,
    fromDate,
    toDate,
    toHour,
  }: {
    fromDate: string;
    fromHour: number;
    toDate: string;
    toHour: number;
  },
) => {
  const fromHourName = fromHour ? `_${fromHour}` : '';
  const toHourName = toHour ? `_${toHour}` : '';
  const fileName = `${report?.name}_${fromDate}${fromHourName}_${toDate}${toHourName}.xlsx`;
  downloadBinary(reportFile, fileName);
};

export const useGenerateReport = (params: {
  fromDate: string;
  fromHour: number;
  toDate: string;
  toHour: number;
}) => {
  const [trigger, setTrigger] = useState<{
    enabled: boolean;
    report?: Report;
  }>({
    enabled: false,
  });
  const { enabled, report } = trigger;
  const { isFetching } = useGenerateReportBlob(
    { ...params, reportId: report?.id },
    {
      enabled,
      onSuccess: (data) => {
        triggerDownload(data.data, report, params);
        setTrigger((prevParams) => ({ ...prevParams, enabled: false }));
      },
    },
  );

  const onGenerate = useCallback(
    (reportToOpen: Report) => {
      setTrigger((prevParams) => ({
        ...prevParams,
        enabled: true,
        report: reportToOpen,
      }));
    },
    [setTrigger],
  );
  return { onGenerate, isGenerating: isFetching };
};
