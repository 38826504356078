import { TFunction } from 'i18next';
import { DateRange, DateRangeType } from '../types/calendar';
import {
  addDays as dateFnsAddDays,
  addMinutes as dateFnsAddMinutes,
  addWeeks as dateFnsAddWeeks,
  differenceInDays as dateFnsDifferenceInDays,
  differenceInYears as dateFnsDifferenceInYears,
  endOfDay as dateFnsEndOfDay,
  endOfMonth as dateFnsEndOfMonth,
  endOfWeek as dateFnsEndOfWeek,
  format,
  formatISO as dateFnsFormatISO,
  fromUnixTime as dateFnsFromUnixTime,
  getDay as dateFnsGetDay,
  getHours as dateFnsGetHours,
  getMinutes as dateFnsGetMinutes,
  getYear as dateFnsGetYear,
  isAfter as dateFnsIsAfter,
  isBefore as dateFnsIsBefore,
  isSameDay as dateFnsIsSameDay,
  isSameMonth as dateFnsIsSameMonth,
  isToday as dateFnsIsToday,
  isValid as dateFnsIsValid,
  nextMonday as dateFnsNextMonday,
  parse,
  parseISO,
  setDay as dateFnsSetDay,
  setHours as dateFnsSetHours,
  setMinutes as dateFnsSetMinutes,
  startOfDay as dateFnsStartOfDay,
  startOfMonth as dateFnsStartOfMonth,
  startOfWeek as dateFnsStartOfWeek,
  subDays as dateFnsSubDays,
  subMinutes as dateFnsSubMinutes,
  subWeeks as dateFnsSubWeeks,
} from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';

const DEFAULT_OPTIONS: {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
} = {
  locale: enGB,
};

/**
 * Used where only interested in date, no timezone is necessary.
 * Should be used to hold dates and send to server, format only for presenting to the user.
 * @see DATE_FNS_DATE_ONLY_ISO_FORMAT
 * https://en.wikipedia.org/wiki/ISO_8601
 */
export type DateOnlyISOString = string;

/**
 * Missing the timezone information, just date with hours and minutes
 * @see DATE_FNS_DATE_TIME_TO_MINUTE_ONLY_ISO_FORMAT
 */
export type DateTimeToMinuteOnlyISOString = string;

export type DateISOString = string;

/**
 * HH:MM, ie: 09:23
 */
export type HourMinuteFormatted = string;

/**
 * Contains the whole date with timezone.
 * Should be used to hold dates and send to server, format only for presenting to the user.
 * https://en.wikipedia.org/wiki/ISO_8601
 */
export type ISODateTime = string;

export const DATE_FNS_DATE_ONLY_ISO_FORMAT = 'yyyy-MM-dd';
export const DATE_FNS_DATE_TIME_TO_MINUTE_ONLY_ISO_FORMAT =
  "yyyy-MM-dd'T'HH:mm";
export const DATE_FNS_UK_DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_FNS_UK_DATE_MONTH_ONLY_FORMAT = 'dd.MM';
export const DATE_FNS_UK_DATE_SLASH_FORMAT = 'dd/MM/yyyy';
export const DATE_FNS_UK_DATE_WITH_FULL_DATE_FORMAT = 'EEEE - dd.MM.yyyy';
export const DATE_FNS_UK_DATE_WITH_FULL_DATE_FORMAT_NO_DASH = 'EEEE dd.MM.yyyy';
export const DATE_FNS_DAY_FULL_MONTH_FORMAT = 'd.MMMM';
export const DATE_FNS_SHORT_MONTH_YEAR_FORMAT = 'MMM yyyy';
export const DATE_FNS_FULL_MONTH_YEAR_FORMAT = 'MMMM yyyy';
export const DATE_FNS_SHORT_MONTH_FORMAT = 'MMM';
export const DATE_FNS_UK_DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const DATE_FNS_UK_DATE_TIME_FORMAT_WITH_WEEK_DAY =
  'dd.MM.yyyy HH:mm EEE';
export const DATE_FNS_UK_DATE_DASH_TIME_FORMAT = 'dd.MM.yyyy - HH:mm';
export const DATE_FNS_DAY_FULL_MONTH_TIME_FORMAT = 'd. MMMM (HH:mm)';
export const DATE_FNS_DATE_US_SLASH = 'MM/dd/yyyy';
export const DATE_FNS_DATE_WRITTEN = 'do MMMM yyyy';
export const DATE_FNS_TIME = 'HH:mm';
export const DATE_FNS_HOUR_12 = 'haaa';
export const DATE_FNS_HOUR = 'HH';
export const DATE_FNS_MINUTE = 'mm';

export const MILLIS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MILLIS_IN_MINUTE = MILLIS_IN_SECOND * SECONDS_IN_MINUTE;
export const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;
export const MINUTES_IN_HOUR = 60;
export const MILLIS_IN_HOUR = MILLIS_IN_MINUTE * MINUTES_IN_HOUR;
export const HOURS_IN_DAY = 24;
export const MILLIS_IN_DAY = MILLIS_IN_HOUR * HOURS_IN_DAY;

const FIRST_MONDAY_OF_FULL_WEEK_AFTER_UNIX_0 = dateFnsNextMonday(
  dateFnsFromUnixTime(0),
);

export const todayInIso = () => {
  return formatDateOnlyISO(new Date());
};

export const tomorrowInIso = () => {
  return formatDateOnlyISO(addDays(new Date(), 1));
};

export const addDays = dateFnsAddDays;
export const subDays = dateFnsSubDays;

export const formatDateOnlySite = (date: Date) => {
  return format(date, DATE_FNS_UK_DATE_FORMAT);
};

export const formatDateTimeToMinutesSite = (date: Date) => {
  return format(date, DATE_FNS_UK_DATE_TIME_FORMAT);
};

export const formatDateOnlyISO = (date: Date) => {
  return format(date, DATE_FNS_DATE_ONLY_ISO_FORMAT);
};

export const formatISO = (date: Date) => {
  return dateFnsFormatISO(date);
};

export const formatDate = (
  date: Date,
  formatString = DATE_FNS_DATE_ONLY_ISO_FORMAT,
) => {
  return format(date, formatString);
};

export const formatDateFromISO = (
  date: DateISOString,
  formatString: string,
) => {
  return format(parseIsoDate(date), formatString);
};

export const formatDateKeepTZ = (
  date: ISODateTime,
  formatString = DATE_FNS_DATE_ONLY_ISO_FORMAT,
) => {
  return format(parseDateKeepTZ(date), formatString);
};

export const formatDateInServerTZ = (
  date: ISODateTime | Date | number,
  formatString = DATE_FNS_DATE_ONLY_ISO_FORMAT,
) => {
  return formatInTimeZone(date, getServerTZ(), formatString);
};

export const startOfWeek = (date: Date) =>
  dateFnsStartOfWeek(date, DEFAULT_OPTIONS);

export const endOfWeek = (date: Date) =>
  dateFnsEndOfWeek(date, DEFAULT_OPTIONS);

export const startOfMonth = (date: Date) => dateFnsStartOfMonth(date);

export const endOfMonth = (date: Date) => dateFnsEndOfMonth(date);

export const optionalIsoDateToDateOnlyString = (dateString?: DateISOString) =>
  dateString ? formatDateOnlySite(parseIsoDate(dateString)) : '';

export const parseIsoDate = (dateString: string) => {
  return parseISO(dateString);
};

export const parseDateTimeOnly = (dateString: string) => {
  return parseIsoDate(getDateTimeOnlyFromISO(dateString));
};

export const getDateTimeOnlyFromISO = (
  dateString: string,
): DateTimeToMinuteOnlyISOString => {
  if (dateString.length > 16) {
    return dateString.substring(0, 16);
  }
  return formatDate(
    parseIsoDate(dateString),
    DATE_FNS_DATE_TIME_TO_MINUTE_ONLY_ISO_FORMAT,
  );
};

export const parseDate = (
  dateString: string,
  format = DATE_FNS_DATE_ONLY_ISO_FORMAT,
) => {
  return parse(dateString, format, new Date());
};

export const parseDateKeepTZ = (dateString: ISODateTime) => {
  return parseISO(getDateTimeOnlyFromISO(dateString));
};

export const isValidDateString = (
  dateString?: string,
  format = DATE_FNS_DATE_ONLY_ISO_FORMAT,
) => {
  if (!dateString) {
    return false;
  }
  try {
    const date = parse(dateString, format, new Date());
    return dateFnsIsValid(date.getTime());
  } catch (e) {
    return false;
  }
};

export const isValid = (date: Date) => {
  return dateFnsIsValid(date);
};

export const dayInFirstFullWeekAfterUnix0 = (dayIndex: number) =>
  dateFnsSetDay(
    FIRST_MONDAY_OF_FULL_WEEK_AFTER_UNIX_0,
    dayIndex,
    DEFAULT_OPTIONS,
  );

export const convertLocalToUTCDate = (date: Date) => {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  );
  return date;
};

export const getDayRange = (dateIncluded: Date): DateRange => {
  return {
    fromDate: startOfDay(dateIncluded),
    toDate: endOfDay(dateIncluded),
  };
};

export const getWeekRange = (dateIncluded: Date): DateRange => {
  return {
    fromDate: startOfWeek(dateIncluded),
    toDate: endOfWeek(dateIncluded),
  };
};

export const getDateRange = (
  dateIncluded: Date,
  dateRangeType: DateRangeType,
): DateRange => {
  switch (dateRangeType) {
    case 'day':
      return getDayRange(dateIncluded);
    case 'week':
      return getWeekRange(dateIncluded);
  }
};

export const getOrderedWeekdayNames = (
  t: TFunction,
  weekdayNumbers: number[],
) => {
  return weekdayNumbers
    .sort((first, second) => {
      /**
       * Sunday is "0", we move it to the end of the list, as we currently
       * aim for UK time. Will need to be improved if we want to support different week start
       */
      if (first === 0) {
        return 1;
      } else if (second === 0) {
        return -1;
      } else {
        return first - second;
      }
    })
    .map((weekdayIndex) =>
      getWeekDayConfig({ t, weekdayDowIndex: weekdayIndex }),
    );
};

export const getWeekDayConfig = ({
  t,
  shortLabel = false,
  weekdayDowIndex,
}: {
  t: TFunction;
  weekdayDowIndex: number;
  shortLabel?: boolean;
}) => {
  if (shortLabel) {
    return WEEK_DAY_LABELS_SHORT(t).find(
      (dayLabelIndex) => dayLabelIndex.backendDowIndex === weekdayDowIndex,
    )!;
  }
  return WEEK_DAY_LABELS(t).find(
    (dayLabelIndex) => dayLabelIndex.backendDowIndex === weekdayDowIndex,
  )!;
};

export const WEEK_DAY_LABELS_SHORT: (
  t: TFunction,
) => { label: string; backendDowIndex: number }[] = (t) => [
  {
    label: t('monday-short'),
    backendDowIndex: 1,
  },
  {
    label: t('tuesday-short'),
    backendDowIndex: 2,
  },
  {
    label: t('wednesday-short'),
    backendDowIndex: 3,
  },
  {
    label: t('thursday-short'),
    backendDowIndex: 4,
  },
  {
    label: t('friday-short'),
    backendDowIndex: 5,
  },
  {
    label: t('saturday-short'),
    backendDowIndex: 6,
  },
  {
    label: t('sunday-short'),
    backendDowIndex: 0,
  },
];

export const WEEK_DAY_LABELS: (
  t: TFunction,
) => { label: string; backendDowIndex: number }[] = (t) => [
  {
    label: t('monday'),
    backendDowIndex: 1,
  },
  {
    label: t('tuesday'),
    backendDowIndex: 2,
  },
  {
    label: t('wednesday'),
    backendDowIndex: 3,
  },
  {
    label: t('thursday'),
    backendDowIndex: 4,
  },
  {
    label: t('friday'),
    backendDowIndex: 5,
  },
  {
    label: t('saturday'),
    backendDowIndex: 6,
  },
  {
    label: t('sunday'),
    backendDowIndex: 0,
  },
];

export const startOfDay = dateFnsStartOfDay;
export const endOfDay = dateFnsEndOfDay;

export const setHours = dateFnsSetHours;

export const setMinutes = dateFnsSetMinutes;

export const subWeeks = dateFnsSubWeeks;
export const addWeeks = dateFnsAddWeeks;
export const getWeekDay = (date: Date) => {
  return dateFnsGetDay(date);
};

export const getHours = (date: Date) => {
  return dateFnsGetHours(date);
};

export const getMinutes = (date: Date) => {
  return dateFnsGetMinutes(date);
};

export const addMinutes = dateFnsAddMinutes;
export const subMinutes = dateFnsSubMinutes;

export const isSameDay = dateFnsIsSameDay;
export const isSameMonth = dateFnsIsSameMonth;

export const isBefore = dateFnsIsBefore;

export const isBeforeOrSameDay = (date: Date, dateToCompare: Date) => {
  return isSameDay(date, dateToCompare) || isBefore(date, dateToCompare);
};

export const isBeforeNotSameDay = (date: Date, dateToCompare: Date) => {
  return !isSameDay(date, dateToCompare) && isBefore(date, dateToCompare);
};
export const isAfter = dateFnsIsAfter;

export const isAfterOrSameDay = (date: Date, dateToCompare: Date) => {
  return isSameDay(date, dateToCompare) || isAfter(date, dateToCompare);
};

export const isAfterNotSameDay = (date: Date, dateToCompare: Date) => {
  return !isSameDay(date, dateToCompare) && isAfter(date, dateToCompare);
};
export const isInPast = (date: Date) => {
  return isBefore(date, new Date());
};
export const isInFuture = (date: Date) => {
  return !isInPast(date);
};
export const isToday = dateFnsIsToday;

export const differenceInYears = dateFnsDifferenceInYears;
export const differenceInDays = dateFnsDifferenceInDays;

export const setWeekday = (date: Date, weekdayIndex: number) => {
  return dateFnsSetDay(date, weekdayIndex, DEFAULT_OPTIONS);
};

export const formatJustHour = (hours: number) => {
  return formatHourMinutes(hours, 0);
};

export const formatJustMinutesWithDoubleQuote = (minutes: number) => {
  return `:${minutes.toLocaleString(enGB.code, {
    minimumIntegerDigits: 2,
  })}`;
};

export const formatHourMinutes = (hours: number, minutes: number) => {
  return `${hours.toLocaleString(enGB.code, {
    minimumIntegerDigits: 2,
  })}:${minutes.toLocaleString(enGB.code, {
    minimumIntegerDigits: 2,
  })}`;
};

export const getCurrentFormattedTime = () => {
  const date = new Date();
  return formatHourMinutes(date.getHours(), date.getMinutes());
};

export const formatISOStringToSiteLocaleDate = (dateString: string) => {
  return formatDateOnlySite(parseIsoDate(dateString));
};

export const padTime = (n: number) => String(n).padStart(2, '0');

export const getServerTZ = () => {
  return 'Europe/London';
};

export const getServerTime = () => utcToZonedTime(new Date(), getServerTZ());

export const combineDateAndTime = (
  dateString: DateOnlyISOString,
  timeString: string,
) => {
  const date = parseIsoDate(dateString);
  const time = parseDate(timeString, DATE_FNS_TIME);
  date.setHours(time.getHours());
  date.setMinutes(time.getMinutes());
  return date;
};

export const getYear = dateFnsGetYear;

export const getDatesBetween = (fromDate: Date, toDate: Date): Date[] => {
  const dates: Date[] = [];
  let currentDate = new Date(fromDate);

  while (currentDate <= toDate) {
    dates.push(new Date(currentDate));
    currentDate = addDays(currentDate, 1);
  }

  return dates;
};
