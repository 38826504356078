import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { get } from 'lodash';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  WaitingListFormItem,
  WaitingListFormItemFilled,
  WaitingListFormValues,
} from './useWaitingList';
import { useMaxAndMinFutureDateForBookingForOnlineBooking } from '../booking/useMaxAndMinFutureDateForBooking';
import {
  formatDateOnlySite,
  isAfterNotSameDay,
  isBeforeNotSameDay,
  parseDateTimeOnly,
} from '../../../utils/dateUtil';

export const isWaitingListRowFilled = (
  item: WaitingListFormItem,
): item is WaitingListFormItemFilled => {
  return Boolean(item.date && item.hourFrom && item.hourTo);
};

export const isWaitingListLineIncomplete = (item: WaitingListFormItem) => {
  return (
    // Any filled and at the same time any empty means row is incomplete
    Boolean(item.date || item.hourFrom || item.hourTo) &&
    Boolean(!item.date || !item.hourFrom || !item.hourTo)
  );
};

export const useValidateWaitingList = () => {
  const { t } = useCommonTranslation();
  const { setError, getValues } = useFormContext<WaitingListFormValues>();
  const { minDate, maxDate } =
    useMaxAndMinFutureDateForBookingForOnlineBooking();
  const isOutOfRange = useCallback(
    ({ date }: WaitingListFormItem) => {
      if (!date) {
        return false;
      }
      const parsed = parseDateTimeOnly(date);
      return (
        isBeforeNotSameDay(parsed, minDate) ||
        isAfterNotSameDay(parsed, maxDate)
      );
    },
    [minDate, maxDate],
  );
  return useCallback((): boolean => {
    const values = getValues();

    const anyWaitingListLineFilled = values.list.some(isWaitingListRowFilled);
    if (!anyWaitingListLineFilled) {
      const names = [
        'list.0.date',
        'list.0.hourFrom',
        'list.0.hourTo',
      ] as const;
      names.forEach((name) => {
        if (!get(values, name))
          setError(name, { type: 'required', message: t('must-not-empty') });
      });
    }
    const waitingListItemsComplete = !values.list.some(
      isWaitingListLineIncomplete,
    );
    if (!waitingListItemsComplete) {
      values.list.forEach((item, index) => {
        if (isWaitingListLineIncomplete(item)) {
          const names = [
            `list.${index}.date`,
            `list.${index}.hourFrom`,
            `list.${index}.hourTo`,
          ] as const;
          names.forEach((name) => {
            if (!get(values, name))
              setError(name, {
                type: 'required',
                message: t('must-not-empty'),
              });
          });
        }
      });
    }
    const waitingListItemsWithingRange = !values.list.some(isOutOfRange);
    values.list.forEach((item, index) => {
      if (isOutOfRange(item)) {
        setError(`list.${index}.date`, {
          type: 'invalid',
          message: t('date-required-in-range-between', {
            minDate: formatDateOnlySite(minDate),
            maxDate: formatDateOnlySite(maxDate),
          }),
        });
      }
    });
    return (
      waitingListItemsComplete &&
      waitingListItemsWithingRange &&
      anyWaitingListLineFilled
    );
  }, [setError, getValues, t, isOutOfRange, minDate, maxDate]);
};
