import React, { useMemo } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { StaffReferralsRoute } from './StaffReferralsRoute';
import { GPSurgeries } from '../../containers/staff/settings/referrals/GPSurgeries';
import { ICBs } from '../../containers/staff/settings/referrals/ICBs';
import { OtherReferrers } from '../../containers/staff/settings/referrals/OtherReferrers';
import {
  STAFF_GP_SURGERIES_URL,
  STAFF_GP_SURGERY_MERGE_URL,
  STAFF_ICBS_MERGE_URL,
  STAFF_ICBS_URL,
  STAFF_OTHER_REFERRERS_MERGE_URL,
  STAFF_OTHER_REFERRERS_URL,
} from '../AppRoutes';
import { useSite } from '../../context/SiteContext';
import { gpSurgeryRequiredTrust } from '../../utils/trustUtil';
import { ICBsMerge } from '../../containers/staff/settings/referrals/ICBsMerge';
import { GPSurgeriesMerge } from '../../containers/staff/settings/referrals/GPSurgeriesMerge';
import { OtherReferrersMerge } from '../../containers/staff/settings/referrals/OtherReferrersMerge';

export const StaffReferralsSwitch = () => {
  const { activeTrust } = useSite();
  const otherReferrerOnly = useMemo(
    () => !gpSurgeryRequiredTrust(activeTrust),
    [activeTrust],
  );
  return (
    <Switch>
      <StaffReferralsRoute
        exact
        path={STAFF_GP_SURGERIES_URL}
        render={() => <GPSurgeries />}
      />
      <StaffReferralsRoute
        exact
        path={STAFF_ICBS_URL}
        render={() => <ICBs />}
      />
      <StaffReferralsRoute
        exact
        path={STAFF_OTHER_REFERRERS_URL}
        render={() => <OtherReferrers />}
      />
      <StaffReferralsRoute
        exact
        path={STAFF_GP_SURGERY_MERGE_URL}
        render={() => <GPSurgeriesMerge />}
      />
      <StaffReferralsRoute
        exact
        path={STAFF_ICBS_MERGE_URL}
        render={() => <ICBsMerge />}
      />
      <StaffReferralsRoute
        exact
        path={STAFF_OTHER_REFERRERS_MERGE_URL}
        render={() => <OtherReferrersMerge />}
      />
      <Redirect
        to={
          otherReferrerOnly ? STAFF_OTHER_REFERRERS_URL : STAFF_GP_SURGERIES_URL
        }
      />
    </Switch>
  );
};
