import { useSiteStringAttributes } from 'hooks/useAttribute';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import {
  KioskEntryMessageBoxStyled,
  KioskInputGroup,
  KioskPreCheckInMsg,
  KioskPreCheckInNo,
  KioskPreCheckInYes,
} from '../Kiosk.styled';
import { PRE_CHECK_IN_QUESTION } from '../../../../configs/siteAndTrustAttributes';

export type KioskPreCheckinProps = {
  onYes: () => unknown;
  onNo: () => unknown;
};
export const KioskPreCheckInQuestionRender = ({
  onYes,
  onNo,
}: KioskPreCheckinProps) => {
  const [preCheckinQuestion] = useSiteStringAttributes(PRE_CHECK_IN_QUESTION);
  const { t } = useCommonTranslation();
  return (
    <KioskEntryMessageBoxStyled>
      <KioskPreCheckInMsg>{preCheckinQuestion}</KioskPreCheckInMsg>
      <KioskInputGroup>
        <KioskPreCheckInYes onClick={onYes} type="button">
          {t('yes')}
        </KioskPreCheckInYes>
        <KioskPreCheckInNo onClick={onNo} type="button">
          {t('no')}
        </KioskPreCheckInNo>
      </KioskInputGroup>
    </KioskEntryMessageBoxStyled>
  );
};
