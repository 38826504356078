import { IObjectWithId, ISortAndFilters } from '../../types/table';
import { FetchedDataTable, FetchedDataTableProps } from './FetchedDataTable';

type StaticDataTableProps<D extends object, K extends ISortAndFilters> = Omit<
  FetchedDataTableProps<D, K>,
  'isLoading' | 'isFetching'
>;

export const StaticDataTable = <
  D extends IObjectWithId,
  K extends ISortAndFilters,
>(
  allProps: StaticDataTableProps<D, K>,
) => {
  return (
    <FetchedDataTable {...allProps} isLoading={false} isFetching={false} />
  );
};
