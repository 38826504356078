import {
  CacheAndStaleTimeForUnlikelyChanged,
  useMutationWithToastError,
  useQueryWithToastError,
} from './queryUtils';
import {
  addNewGP,
  deleteGP,
  getGps,
  mergeGpSurgeries,
  updateGP,
} from '../services/gp_surgeries';
import {
  GPFilters,
  GPFormValues,
  GPResponseData,
} from '../types/generalPractices';
import { UseQueryOptions } from 'react-query/types/react/types';
import { AxiosResponse } from 'axios';
import { UseMutationOptions, useQueryClient } from 'react-query';

const SITE_SURGERIES = 'surgeries';
export const useGPSurgeries = (
  filters?: GPFilters,
  options?: UseQueryOptions<AxiosResponse<GPResponseData>, any, GPResponseData>,
  noToken?: boolean,
) => {
  return useQueryWithToastError(
    [SITE_SURGERIES, filters],
    () => getGps(filters, noToken),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
      ...options,
      select: (data) => data.data,
    },
  );
};

export const useMutateUpdateGP = (
  options?: UseMutationOptions<any, any, GPFormValues>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<GPFormValues>(
    [SITE_SURGERIES],
    (update: GPFormValues) => updateGP(update),
    {
      onSettled: () => {
        client.invalidateQueries([SITE_SURGERIES]);
      },
      ...options,
    },
  );
};

export const useMutateCreateGP = (
  options?: UseMutationOptions<any, any, GPFormValues>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<GPFormValues>(
    [SITE_SURGERIES],
    (params) => addNewGP(params),
    {
      onSettled: () => client.invalidateQueries([SITE_SURGERIES]),
      ...options,
    },
  );
};

export const useMutateDeleteGP = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<string>(
    [SITE_SURGERIES],
    (params) => deleteGP(params),
    {
      onSettled: () => client.invalidateQueries([SITE_SURGERIES]),
      ...options,
    },
  );
};

type MergeGPsProps = {
  sourceId: string;
  targetId: string;
};

export const useMutateMergeGPSurgeries = (
  options?: UseMutationOptions<any, any, MergeGPsProps>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<MergeGPsProps>(
    [SITE_SURGERIES],
    (mergeProps) => mergeGpSurgeries(mergeProps.sourceId, mergeProps.targetId),
    {
      onSettled: () => {
        client.invalidateQueries([SITE_SURGERIES]);
      },
      ...options,
    },
  );
};
