import { useCallback, useMemo } from 'react';
import { DefaultValues, UnpackNestedValue } from 'react-hook-form';

import { DefaultSiteOrTrustAttribute } from '../../../configs/siteAndTrustAttributes';
import { UseAttributesFormReturn } from '../../../hooks/useAttributesForm';
import { GlobalAttribute } from '../../../types/common';
import { SiteAttribute, SiteData } from '../../../types/sites';
import { ISitePreferencesProps } from './SitePreferences';

export type SitePreferencesTab =
  | 'general'
  | 'emails'
  | 'settings-check-in-tab'
  | 'custom-settings'
  | 'appointment-flags'
  | 'links'
  | 'guidelines';

export const sitePreferencesTabsSubset = <T extends SitePreferencesTab[]>(
  tabs: T,
): T => {
  return tabs;
};

export interface UseSiteFormHooksInput {
  site: SiteData;
}

type BeforeSubmit<T extends SiteData = SiteData> = (
  updatedSite: SiteData,
  formValues: UnpackNestedValue<T>,
) => SiteData;

export interface UseSiteFormHooksReturn<T extends SiteData = SiteData> {
  restAttributes: GlobalAttribute[];
  defaultValues?: DefaultValues<T>;
  beforeSubmit?: BeforeSubmit<T>;
}

export type UseSiteFormHooks<T extends SiteData = SiteData> = (
  input: UseSiteFormHooksInput,
) => UseSiteFormHooksReturn<T>;

export type UseMergeSiteFormHooksReturn<T extends SiteData = SiteData> =
  Required<UseSiteFormHooksReturn<T>>;

export const useMergeSiteFormHooks = <T extends SiteData = SiteData>({
  site,
  hooks,
}: {
  site: SiteData;
  hooks: UseSiteFormHooksReturn<T>[];
}): UseMergeSiteFormHooksReturn<T> => {
  const restAttributesItems = hooks.map((hook) => hook.restAttributes);
  const restAttributes = useMemo(() => {
    return restAttributesItems.reduce((result, restAttributesItem) => {
      result.push(...restAttributesItem);
      return result;
    }, []);
    // We want just shallow comparison of all restAttributesItems elements
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...restAttributesItems]);

  const defaultValuesItems = hooks
    .map((hook) => hook.defaultValues)
    .filter((item): item is DefaultValues<T> => !!item);
  const defaultValues = useMemo(() => {
    return defaultValuesItems.reduce((result, defaultValuesItem) => {
      return {
        ...result,
        ...defaultValuesItem,
      };
    }, site as DefaultValues<T>);
    // We want just shallow comparison of all defaultValuesItems elements
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, ...defaultValuesItems]);

  const beforeSubmitItems = hooks
    .map((hook) => hook.beforeSubmit)
    .filter((item): item is BeforeSubmit => !!item);
  const beforeSubmit: BeforeSubmit<T> = useCallback(
    (updatedSite, formValues) => {
      return beforeSubmitItems.reduce((result, beforeSubmitItem) => {
        return beforeSubmitItem(result, formValues);
      }, updatedSite);
    },
    // We want just shallow comparison of all beforeSubmitItems elements
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...beforeSubmitItems],
  );

  return {
    restAttributes,
    defaultValues,
    beforeSubmit,
  };
};

export const siteAttributeKeysSubset = <
  T extends DefaultSiteOrTrustAttribute[],
>(
  keys: T,
): T => {
  return keys;
};

export const updateSiteAttributes = <T extends DefaultSiteOrTrustAttribute[]>(
  updatedSiteAttributes: SiteAttribute[] = [],
  formSiteAttributes: UnpackNestedValue<SiteAttribute[]> = [],
  keys: T,
  updateAttribute: (
    key: typeof keys[number],
    siteValue: SiteAttribute | undefined,
    formValue: SiteAttribute | undefined,
    /**
     * Modified after each `updateAttribute` call.
     * Is useful for dependent site attribute keys,
     * where dependent attribute needs to know already
     * updated attribute object of previous one.
     */
    updatedSiteAttributes: SiteAttribute[],
  ) => SiteAttribute | undefined,
) => {
  let attributes = updatedSiteAttributes;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    // get original attribute value from site
    const siteValue = attributes.find(({ key: attKey }) => attKey === key);
    // get modified attribute value from form
    const formValue = formSiteAttributes.find(
      ({ key: attKey }) => attKey === key,
    );
    // run attribute update callback to get the final attribute value
    const updatedValue = updateAttribute(key, siteValue, formValue, attributes);
    if (updatedValue) {
      // if updated value is defined
      if (siteValue) {
        // update existing site value
        attributes = updateSiteAttribute(attributes, updatedValue);
      } else {
        // or add new value if site value does not exists yet
        attributes = addSiteAttribute(attributes, updatedValue);
      }
    } else {
      // if updated value is not defined, i.e. should be deleted
      if (siteValue) {
        // remove existing site value
        attributes = removeSiteAttribute(attributes, key);
      }
    }
  }
  return attributes;
};

const updateSiteAttribute = (
  targetAttributes: SiteAttribute[],
  updatedValue: SiteAttribute,
) => {
  return targetAttributes.map((attribute) => {
    if (attribute.key === updatedValue.key) {
      return updatedValue;
    }
    return attribute;
  });
};

const addSiteAttribute = (
  targetAttributes: SiteAttribute[],
  newValue: SiteAttribute,
) => {
  return [...targetAttributes, newValue];
};

const removeSiteAttribute = <T extends DefaultSiteOrTrustAttribute>(
  targetAttributes: SiteAttribute[],
  key: T,
) => {
  return targetAttributes.filter(({ key: attKey }) => attKey !== key);
};

/**
 * Simplifies saving of attributes form and will be
 * rewritten soon after useAttributesForm is removed.
 * It includes check which disable repeated submit
 * while site is fetching.
 */
export const useSiteFormSubmit = <T extends SiteData>({
  site,
  isFetching,
  submitHandler,
  beforeSubmit,
  handleSubmit,
  withAttributes,
}: { site: SiteData } & Pick<
  ISitePreferencesProps,
  'isFetching' | 'submitHandler'
> &
  Pick<UseMergeSiteFormHooksReturn<T>, 'beforeSubmit'> &
  Pick<UseAttributesFormReturn<T>, 'handleSubmit' | 'withAttributes'>) => {
  return (e?: React.BaseSyntheticEvent) => {
    if (!isFetching) {
      return handleSubmit(
        withAttributes((data) => {
          return submitHandler(beforeSubmit(site, data));
        }),
      )(e);
    } else {
      e?.preventDefault();
      return Promise.resolve();
    }
  };
};
