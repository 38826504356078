import React from 'react';
import { FormRow } from '../../../components/common/Forms/Forms.styled';
import {
  DesignType,
  INFO_LABEL_DESIGN_LARGE,
  INFO_LABEL_DESIGN_LARGER,
  InfoLabelText,
} from '../../../components/common/Forms/InfoLabelText';
import { formatISOStringToSiteLocaleDate } from '../../../utils/dateUtil';
import {
  bitwiseNumberToIntValuesArray,
  formatPhoneNumber,
} from '../../../utils/helpers';
import { disabilityOptions } from '../../../configs/constants';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { GeneralPractice } from '../../../types/generalPractices';
import { useSite } from '../../../context/SiteContext';
import { CardSubtitle } from '../../../components/common';
import {
  gpSurgeryRequiredTrust,
  postCodeRequiredTrusts,
  shouldRenderNhsNumberTrusts,
} from '../../../utils/trustUtil';
import { useMemberRelationLabel } from '../../../hooks/user/useMemberRelationLabel';
import { UserData } from '../../../types/users';

export interface IAppointmentUserDetailsProps {
  user?: UserData;
  surgeries?: Array<GeneralPractice>;
  subtitleLabel?: string;
  design?: DesignType;
  showComment?: boolean;
}

const subtitleDesign: { [key in string]: React.CSSProperties } = {
  [INFO_LABEL_DESIGN_LARGE]: {
    fontSize: 'var(--fontlg)',
    marginBottom: 'var(--s4)',
  },
  [INFO_LABEL_DESIGN_LARGER]: {
    fontSize: 'var(--fontlarger)',
    marginBottom: 'var(--s3)',
  },
};

export const PatientReadOnlyDetails: React.FunctionComponent<
  IAppointmentUserDetailsProps
> = ({ user, surgeries, subtitleLabel, design, showComment = true }) => {
  const { t } = useCommonTranslation();
  const { activeTrust } = useSite();
  const emptyString = t('empty-field');
  const relation = useMemberRelationLabel(user?.relation);
  return (
    <>
      {subtitleLabel && (
        <CardSubtitle style={design && subtitleDesign[design]}>
          {subtitleLabel}
        </CardSubtitle>
      )}
      <FormRow>
        <InfoLabelText
          label={t('first-name')}
          text={user?.firstname || emptyString}
          design={design}
        />
        <InfoLabelText
          label={t('surname')}
          text={user?.lastname || emptyString}
          design={design}
        />
      </FormRow>
      <FormRow>
        <InfoLabelText
          design={design}
          label={t('email')}
          text={user?.email || emptyString}
        />
        <InfoLabelText
          design={design}
          label={t('relationship')}
          text={relation}
        />
      </FormRow>
      <FormRow>
        <InfoLabelText
          label={t('phone-number')}
          text={formatPhoneNumber(user?.phone) || emptyString}
          design={design}
        />
        <InfoLabelText
          label={t('date-of-birth')}
          text={
            user && user.birthdate
              ? formatISOStringToSiteLocaleDate(user.birthdate)
              : emptyString
          }
          design={design}
        />
      </FormRow>
      <FormRow>
        {postCodeRequiredTrusts([activeTrust]) && (
          <InfoLabelText
            label={t('postcode')}
            text={user?.postcode || emptyString}
            design={design}
          />
        )}
        {shouldRenderNhsNumberTrusts([activeTrust]) && (
          <InfoLabelText
            label={t('nhs-number')}
            text={user?.nhs_number || emptyString}
            design={design}
          />
        )}
      </FormRow>
      <FormRow>
        {gpSurgeryRequiredTrust(activeTrust) && (
          <InfoLabelText
            label={t('general-practice')}
            text={
              surgeries?.find((surgery) => surgery.id === user?.gp_surgery_id)
                ?.name || emptyString
            }
            design={design}
          />
        )}
        <InfoLabelText
          label={t('disabilities')}
          text={
            bitwiseNumberToIntValuesArray(user?.disabilities)
              .map(
                (disabilityId) =>
                  disabilityOptions(t).find((op) => op.value === disabilityId)
                    ?.label,
              )
              .join(', ') || emptyString
          }
          design={design}
        />
      </FormRow>
      {showComment && (
        <FormRow>
          <InfoLabelText
            label={t('comment')}
            text={user?.comment || emptyString}
            design={design}
            textStyle={{
              whiteSpace: 'pre-wrap',
              maxHeight: 'var(--s6)',
              overflowY: 'auto',
            }}
          />
        </FormRow>
      )}
    </>
  );
};
