import {
  MergeItemContainerStyle,
  MergePatientSelectWrapper,
} from './mergeStyle';
import { CommonButton } from '../Forms/Button';

export interface MergeItemBoxProps {
  onSelectClicked: () => void;
  isSelecting: boolean;
  selectingLabel: string;
  selectLabel: string;
  children: React.ReactNode;
}

export const MergeItemBox = ({
  isSelecting,
  onSelectClicked,
  children,
  selectingLabel,
  selectLabel,
}: MergeItemBoxProps) => {
  return (
    <MergeItemContainerStyle isSelecting={isSelecting}>
      {children}

      <MergePatientSelectWrapper>
        <CommonButton
          size="auto"
          variant={isSelecting ? 'selected' : 'primary'}
          type="button"
          onClick={onSelectClicked}
        >
          {isSelecting ? selectingLabel : selectLabel}
        </CommonButton>
      </MergePatientSelectWrapper>
    </MergeItemContainerStyle>
  );
};
