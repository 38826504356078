import styled from 'styled-components';
import {
  Row,
  WrappingRow,
} from '../../../components/common/BasicStyles.styled';

export const AppointmentDateAndCheckRowStyle = styled(WrappingRow)`
  padding-bottom: 0.8rem;
`;

export const AppointmentCheckWrapperStyle = styled(Row)`
  align-items: baseline;

  & > div {
    margin-left: var(--s3);
  }
`;

export const DateTimeTextStyle = styled.div`
  padding-top: 0.0625rem;
`;
