import React, { useCallback } from 'react';
import { Appointment } from '../../../types/appointments';
import { AppointmentCard } from '../../../components/views-components/staff/appointments/AppointmentCard';
import { FormBody } from '../../../components/common/Forms/Forms.styled';
import { useForm } from 'react-hook-form';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { EditAppointmentData } from '../../../components/views-components/staff/appointments/EditAppointmentData';
import { CommonButton as Button } from '../../../components/common/Forms/Button';
import { ButtonSetWithCloseButton } from '../../../components/Form/ButtonSetWithCloseButton';
import { FaUserClock } from 'react-icons/fa';
import { PatientReadOnlyDetails } from '../../common/patient/PatientReadOnlyDetails';
import { AppointmentForm } from '../../../hooks/appointments/useAppointmentStateWorkflow';
import { PatientAppointmentsAndEditBlock } from '../../../components/views-components/staff/appointments/patient/PatientAppointmentsAndEditBlock';
import {
  EDIT_PATIENT_MODE,
  PATIENT_APPOINTMENTS_LIST_MODE,
  usePatientAppointments,
} from '../../../hooks/staff/usePatientAppointments';
import { PatientAppointmentsAndEditPatientControl } from '../appointments/PatientAppointmentsAndEditPatientControl';
import { useStaffAppointmentBookWithPatient } from './hooks/useStaffAppointmentBookWithPatient';
import { CardSubtitle } from '../../../components/common';
import { UserData } from '../../../types/users';

type IAppointmentFormProps = {
  closeForm: (keepPatient: boolean) => void;
  selectedAppointmentSlot: Appointment[];
  selectedUser: UserData;
  isWalkInAvailable: boolean;
};

export const BookAppointmentWithPatient: React.FunctionComponent<
  IAppointmentFormProps
> = ({
  selectedAppointmentSlot,
  closeForm,
  selectedUser,
  isWalkInAvailable,
}) => {
  const { t } = useCommonTranslation();
  const closeFormKeepPatient = useCallback(() => {
    closeForm(true);
  }, [closeForm]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AppointmentForm>();

  const {
    bookPatientWithAgeValidation,
    isLoading: isBookAppointmentLoading,
    selectedAppointment,
    surgeriesResponse,
    referrerOptions,
  } = useStaffAppointmentBookWithPatient({
    closeForm,
    selectedAppointmentSlot,
  });

  const {
    mode,
    isLoading: isUserAppointmentsLoading,
    setMode,
    bookedAppointments,
    pastAppointments,
    cancelledAppointments,
    setExistingAppointmentMode,
    ...rest
  } = usePatientAppointments({
    user: selectedUser,
  });

  const isLoading = isBookAppointmentLoading || isUserAppointmentsLoading;

  return (
    <>
      {mode === 'closed' ? (
        <AppointmentCard
          isLoading={isLoading}
          title={t('appointment-detail-title')}
          onCloseClicked={closeFormKeepPatient}
          icon={<FaUserClock />}
        >
          <FormBody>
            <PatientReadOnlyDetails
              user={selectedUser}
              surgeries={surgeriesResponse}
              subtitleLabel={t('appointment-book-patient-information')}
            />
            <PatientAppointmentsAndEditBlock
              user={selectedUser}
              onPatientButtonClicked={(type) =>
                type === 'userEdit'
                  ? setMode(EDIT_PATIENT_MODE)
                  : setMode(PATIENT_APPOINTMENTS_LIST_MODE)
              }
            />
            <CardSubtitle>{t('appointment-detail-information')}</CardSubtitle>
            <EditAppointmentData
              register={register}
              control={control}
              errors={errors}
              referrers={referrerOptions}
              selectedAppointment={selectedAppointment}
            />
            <ButtonSetWithCloseButton onCloseClicked={closeFormKeepPatient}>
              <Button
                variant="primary"
                type={'button'}
                onClick={async () => {
                  await handleSubmit((data) =>
                    bookPatientWithAgeValidation({
                      user: selectedUser,
                      appointment: data.appointment,
                      keepPatient: false,
                      queuePatient: isWalkInAvailable,
                    }),
                  )();
                }}
              >
                {isWalkInAvailable
                  ? t('appointment-details-save-and-queue')
                  : t('appointment-details-save-and-clear')}
              </Button>
              <Button
                variant="primary"
                type={'button'}
                onClick={async () => {
                  await handleSubmit((data) => {
                    bookPatientWithAgeValidation({
                      appointment: data.appointment,
                      keepPatient: true,
                      user: selectedUser,
                    });
                  })();
                }}
              >
                {t('appointment-details-save-and-keep')}
              </Button>
            </ButtonSetWithCloseButton>
          </FormBody>
        </AppointmentCard>
      ) : (
        <PatientAppointmentsAndEditPatientControl
          mode={mode}
          setExistingAppointmentMode={setExistingAppointmentMode}
          bookedAppointments={bookedAppointments}
          pastAppointments={pastAppointments}
          cancelledAppointments={cancelledAppointments}
          isLoading={isUserAppointmentsLoading}
          user={selectedUser}
          {...rest}
        />
      )}
    </>
  );
};
