import { AppointmentsResponseData } from '../../types/appointments';
import { useQueryWithToastError } from '../../query/queryUtils';
import { UseQueryOptions } from 'react-query/types/react/types';
import { AxiosResponse } from 'axios';
import { addDays, formatDate, startOfDay } from 'utils/dateUtil';
import { availableAppointmentsConfig } from '../../query/appointments';

export const useDayAvailableAppointments = (
  date: Date | null,
  options?: UseQueryOptions<any, any, AxiosResponse<AppointmentsResponseData>>,
) => {
  const fromDate = startOfDay(date || new Date());
  const toDate = addDays(fromDate, 1);
  const params = { fromDate: formatDate(fromDate), toDate: formatDate(toDate) };
  const config = availableAppointmentsConfig(params);

  return useQueryWithToastError<
    any,
    any,
    AxiosResponse<AppointmentsResponseData>
  >(config.queryKey, config.queryFn, {
    enabled: date !== null,
    ...options,
  });
};
