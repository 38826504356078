import { Card } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import { Col, Grid } from 'components/common/Grid';
import { PageHelmet } from 'components/common/PageHelmet';
import { ConfirmDialog } from 'components/Popup/ConfirmDialog/ConfirmDialog';
import { useCallback } from 'react';
import { FaTrashAlt } from 'react-icons/fa';

import { UNSAFE_FILTER_WITH_NO_PAGINATION } from '../../hooks/usePaginatedFilters';
import { useAdminPatientColumns } from '../../hooks/user/usePatientColumns';
import { usePatientsCrud } from '../../hooks/user/usePatientsCrud';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { useQuerySites } from '../../query/sites';
import { useTrusts } from '../../query/trusts';
import { errorToast } from '../../utils/toast';
import { CommonPatientsTable } from '../common/patient/PatientsTable';
import { AdminLayout } from './AdminLayout';
import { PatientCol } from './AdminPatients.styled';
import { LinkCardTabs } from '../../components/Card/CardTabs/LinkCardTabs';
import { AdminUsersTabsTree } from '../../configs/RoutesConfig';
import { useSite } from '../../context/SiteContext';
import { PatientDetailCard } from '../staff/calendar/PatientDetailCard';

export const AdminPatients = () => {
  const { t } = useCommonTranslation();

  const { activeSite, setActiveSite } = useSite();

  const {
    setFilters,
    closeFormHandler,
    openFormHandler,
    handleDeleteUser,
    handleConfirmDeleteUser,
    showForm,
    prefilledData,
    usersData,
    isLoading: isUsersLoading,
    isUsersFetching,
    confirmDeleteItem,
    filters,
  } = usePatientsCrud({
    ignoreTrust: true,
  });

  const { data: sitesData } = useQuerySites({
    ...UNSAFE_FILTER_WITH_NO_PAGINATION,
  });
  const { data: trustsData, isLoading: isTrustsLoading } = useTrusts();

  const openDetailAndSelectTrustAndSite = useCallback(
    (value?: string) => {
      const selectedUser = usersData?.data.data?.find(
        (user) => user.id === value,
      );
      if (!selectedUser) {
        errorToast(t('error-user-not-found'));
        return;
      }
      if (selectedUser?.active_site_id) {
        const site = sitesData?.data?.find(
          (site) => site.id === selectedUser.active_site_id,
        );
        if (!site) {
          errorToast(t('error-site-not-found'));
          return;
        }
        const trust =
          site &&
          trustsData?.data?.data?.find((trust) => trust.id === site?.trust_id);
        if (!trust) {
          errorToast(t('error-trust-not-found'));
          return;
        }
        /**
         * A lot of BE endpoints in the detail are linked to active trust. If trust is changed here, those endpoints will work.
         */
        if (site.id !== activeSite.id) {
          setActiveSite(site);
        }
        openFormHandler(value);
      }
    },
    [
      openFormHandler,
      t,
      sitesData,
      trustsData,
      usersData,
      setActiveSite,
      activeSite.id,
    ],
  );

  const { columns } = useAdminPatientColumns({
    openFormHandler: openDetailAndSelectTrustAndSite,
    handleDeleteUser,
    allSites: sitesData?.data,
    filters,
    setFilters,
  });

  const isLoading = isTrustsLoading || isUsersLoading;

  return (
    <AdminLayout>
      <PageHelmet title={t('patients')} />
      <Grid>
        <Col md={8}>
          <Card fillHeight flex>
            <LinkCardTabs withDivider tabs={AdminUsersTabsTree(t)} />
            <CommonPatientsTable
              usersData={usersData?.data}
              setFilters={setFilters}
              isLoading={isLoading}
              isFetching={isUsersFetching}
              columns={columns}
              filters={filters}
            />
          </Card>
        </Col>
        <PatientCol md={4}>
          {showForm && prefilledData && (
            <PatientDetailCard
              key={prefilledData.id}
              patient={prefilledData}
              onPatientCloseButtonClicked={() => closeFormHandler(true)}
            />
          )}
        </PatientCol>
      </Grid>
      {Boolean(confirmDeleteItem) && (
        <ConfirmDialog
          isLoading={isLoading}
          handleClose={() => closeFormHandler(true)}
          icon={<FaTrashAlt />}
          title={t('confirm-delete-patient')}
          status="delete"
          actions={[
            <CommonButton
              key={confirmDeleteItem?.id + '-cancel'}
              variant="secondary"
              onClick={() => closeFormHandler(true)}
            >
              {t('cancel')}
            </CommonButton>,
            <CommonButton
              key={confirmDeleteItem?.id + '-delete'}
              variant="danger"
              onClick={handleConfirmDeleteUser}
            >
              {t('delete')}
            </CommonButton>,
          ]}
        />
      )}
    </AdminLayout>
  );
};
