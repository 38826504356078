import {
  DialogFormWrapper,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import { CardLoader } from 'components/common/Loading';
import { ATTRIBUTE_SEPARATOR } from 'configs/constants';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FaEnvelope } from 'react-icons/fa';
import { singleEmailPattern } from 'utils/helpers';

import {
  EMAIL_HOSPITAL_URL,
  SERVER_EMAIL_SENDER,
  SERVER_FORCED_EMAIL_RECEIVER,
} from '../../../../configs/siteAndTrustAttributes';
import { UseEmailsPreferencesFormReturn } from './EmailsPreferencesHooks';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';

interface Props {
  isFetching: boolean;
  attributesForm: UseEmailsPreferencesFormReturn;
}

export const ServerSettings: React.FC<Props> = ({
  isFetching,
  attributesForm,
}) => {
  const { t } = useCommonTranslation();

  const {
    formState: { errors },
    attributeRegister,
    control,
  } = attributesForm;
  const watchedValues = useWatch({ control });

  return (
    <DialogFormWrapper>
      <FormHeader>
        <FaEnvelope />
        <FormTitle>{t('server-settings')}</FormTitle>
      </FormHeader>
      <FormRow>
        <Input
          label={t('email-sender')}
          placeholder={t('email-sender')}
          {...attributeRegister(SERVER_EMAIL_SENDER, (e) => e.target.value, {
            required: t('must-not-empty') as string,
            maxLength: {
              value: 78,
              message: t('max-char-input', { count: 78 }),
            },
            pattern: {
              value: singleEmailPattern,
              message: t('must-valid-address') as string,
            },
          })}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === SERVER_EMAIL_SENDER,
            )?.value_str
          }
          hasError={Boolean(
            errors[(ATTRIBUTE_SEPARATOR + SERVER_EMAIL_SENDER) as 'attributes'],
          )}
          helperText={
            errors[(ATTRIBUTE_SEPARATOR + SERVER_EMAIL_SENDER) as 'id']?.message
          }
        />

        <Input
          label={t('forced-email-receiver')}
          placeholder={t('forced-email-receiver')}
          {...attributeRegister(
            SERVER_FORCED_EMAIL_RECEIVER,
            (e) => e.target.value,
            {
              maxLength: {
                value: 78,
                message: t('max-char-input', { count: 78 }),
              },
              pattern: {
                value: singleEmailPattern,
                message: t('must-valid-address') as string,
              },
            },
          )}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === SERVER_FORCED_EMAIL_RECEIVER,
            )?.value_str
          }
          hasError={Boolean(
            errors[
              (ATTRIBUTE_SEPARATOR +
                SERVER_FORCED_EMAIL_RECEIVER) as 'attributes'
            ],
          )}
          helperText={
            errors[(ATTRIBUTE_SEPARATOR + SERVER_FORCED_EMAIL_RECEIVER) as 'id']
              ?.message
          }
        />
      </FormRow>
      <FormRow>
        <Input
          label={t('site-url')}
          placeholder={t('site-url')}
          {...attributeRegister(EMAIL_HOSPITAL_URL, (e) => e.target.value)}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === EMAIL_HOSPITAL_URL,
            )?.value_str
          }
          hasError={Boolean(
            errors[(ATTRIBUTE_SEPARATOR + EMAIL_HOSPITAL_URL) as 'attributes'],
          )}
          helperText={
            errors[(ATTRIBUTE_SEPARATOR + EMAIL_HOSPITAL_URL) as 'id']?.message
          }
        />
      </FormRow>
      {isFetching && <CardLoader fillWrapper={true} />}
    </DialogFormWrapper>
  );
};
