import { ExpandingText } from '../../../../common/Forms/ExpandingText';

interface Props {
  label: string;
}

export const StaffSiteRoleRowLabel = ({ label }: Props) => {
  return (
    <ExpandingText
      text={label}
      style={{
        zIndex: 5,
      }}
      textStyle={{
        paddingTop: 'var(--s2)',
      }}
    />
  );
};
