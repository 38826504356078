import { useMemo } from 'react';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { usePrevNextDay } from '../../hooks/util/usePrevNextDay';
import {
  DATE_FNS_DAY_FULL_MONTH_FORMAT,
  formatDate,
  getWeekDay,
  getWeekDayConfig,
} from '../../utils/dateUtil';
import { toRem } from '../../utils/helpers';
import {
  InlineCalendarDateWrapper,
  InlineCalendarMonth,
  InlineCalendarYear,
} from '../InlineCalendar/InlineCalendar.styled';
import { CommonButton } from '../common/Forms/Button';
import { DayNavWrapper, TimePlannerDay } from './TimePlanner.styled';

export interface ITimePlannerHeaderProps {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  dateFormat: 'weekDay' | 'fullMonthWithYear';
  filterDate?: (date: Date) => boolean;
  minDate?: Date;
  maxDate?: Date;
}

export const TimePlannerTopControl = ({
  selectedDate,
  setSelectedDate,
  dateFormat,
  filterDate,
  minDate,
  maxDate,
}: ITimePlannerHeaderProps) => {
  const { t } = useCommonTranslation();
  const dayNumber = useMemo(() => getWeekDay(selectedDate), [selectedDate]);

  const { prevDate, nextDate, prevDisabled, nextDisabled } = usePrevNextDay({
    date: selectedDate,
    filterDate,
    minDate,
    maxDate,
  });

  return (
    <DayNavWrapper>
      <CommonButton
        type="button"
        size="auto"
        variant="primary"
        style={{ minWidth: `${toRem(100)}rem` }}
        disabled={prevDisabled}
        onClick={() => setSelectedDate(prevDate)}
      >
        {t('previous')}
      </CommonButton>
      {dateFormat === 'weekDay' ? (
        <TimePlannerDay>
          {getWeekDayConfig({ t, weekdayDowIndex: dayNumber }).label}
        </TimePlannerDay>
      ) : (
        <InlineCalendarDateWrapper
          style={{ textTransform: 'uppercase', textAlign: 'center' }}
        >
          <InlineCalendarMonth>
            {formatDate(selectedDate, DATE_FNS_DAY_FULL_MONTH_FORMAT)}
          </InlineCalendarMonth>
          <InlineCalendarYear>{selectedDate.getFullYear()}</InlineCalendarYear>
        </InlineCalendarDateWrapper>
      )}
      <CommonButton
        style={{ minWidth: `${toRem(100)}rem` }}
        type="button"
        size="auto"
        variant="primary"
        disabled={nextDisabled}
        onClick={() => setSelectedDate(nextDate)}
      >
        {t('next')}
      </CommonButton>
    </DayNavWrapper>
  );
};
