import { ReactElement } from 'react';
import ReactDatePicker from 'react-datepicker';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import {
  convertLocalToUTCDate,
  DATE_FNS_SHORT_MONTH_FORMAT,
  formatDate,
} from 'utils/dateUtil';
import {
  InlineCalendarDateWrapper,
  InlineCalendarDay,
  InlineCalendarHeaderWrapper,
  InlineCalendarMonth,
  InlineCalendarMonthButton,
  InlineCalendarStylesWrapper,
  InlineCalendarYear,
} from './InlineCalendar.styled';

interface HighlightDates {
  [className: string]: Date[];
}

export interface InlineCalendarProps {
  filterDate?: (date: Date) => boolean;
  selectedDate: Date;
  updateDates: (dates: {
    selectedDate: Date;
    dayPickerVisibleMonthDate?: Date;
  }) => void;
  minDate?: Date;
  maxDate?: Date;
  onVisibleMonthChange?: (dateWithinMonth: Date) => void;
  openToDate?: Date;
  includeDates?: Date[];
  highlightDates?: Array<HighlightDates | Date> | undefined;
}

export const InlineCalendar = ({
  filterDate,
  selectedDate,
  updateDates,
  highlightDates,
  onVisibleMonthChange,
  ...rest
}: InlineCalendarProps): ReactElement => {
  return (
    <InlineCalendarStylesWrapper>
      <ReactDatePicker
        /**
         * We need to regenerate the key every time the selected date changes
         * otherwise calendar will not switch month to selected date month
         * when selected date value is changed from outside of the component.
         * It will update only when the month is changed by selected date.
         */
        key={selectedDate.toString()}
        selected={selectedDate}
        filterDate={filterDate}
        highlightDates={highlightDates}
        onChange={(date: Date) =>
          updateDates({ selectedDate: convertLocalToUTCDate(date) })
        }
        inline
        calendarStartDay={1} // Monday
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
        renderDayContents={(day) => (
          <InlineCalendarDay>{day}</InlineCalendarDay>
        )}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <InlineCalendarHeaderWrapper>
            <InlineCalendarMonthButton
              size="auto"
              iconOnly
              variant="primary"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <FaChevronLeft />
            </InlineCalendarMonthButton>
            <InlineCalendarDateWrapper>
              <InlineCalendarMonth>
                {formatDate(date, DATE_FNS_SHORT_MONTH_FORMAT)}
              </InlineCalendarMonth>
              <InlineCalendarYear>{date.getFullYear()}</InlineCalendarYear>
            </InlineCalendarDateWrapper>
            <InlineCalendarMonthButton
              size="auto"
              iconOnly
              variant="primary"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <FaChevronRight />
            </InlineCalendarMonthButton>
          </InlineCalendarHeaderWrapper>
        )}
        onMonthChange={(dateWithinVisibleMonth) =>
          updateDates({
            selectedDate,
            dayPickerVisibleMonthDate: dateWithinVisibleMonth,
          })
        }
        {...rest}
      />
    </InlineCalendarStylesWrapper>
  );
};
