import 'normalize.css';
import React, { Fragment } from 'react';
import { createGlobalStyle } from 'styled-components';
import { toRem, toRemStr } from 'utils/helpers';
import { colors } from './colors';
import { BASE_FONT_SIZE_PX, fonts, spaces, width } from './sizes';
import { h2, h3, h4 } from './Typography';

interface ThemeProviderProps {}

export const fontFamily = `'Roboto', 'Open Sans', helvetica, arial`;
const GlobalStyle = createGlobalStyle`
  :root {
    /* Colors */
    --greenprimary: ${colors.green.primary};
    --greensecondary: ${colors.green.secondary};
    --greentercial: ${colors.green.tercial};
    --greentercial50: ${colors.green.tercial50};
    --greenaccent: ${colors.green.accent};
    --greenmuted: ${colors.green.muted};
    --greenoutline: ${colors.green.outline};
    --blueprimary: ${colors.blue.primary};
    --bluesecondary: ${colors.blue.secondary};
    --bluemainprimary: ${colors.blue.mainprimary};
    --bluemtercial: ${colors.blue.tercial};
    --yellowaction: ${colors.yellow.action};
    --yellowmuted: ${colors.yellow.muted};
    --redaccent: ${colors.red.accent};
    --rednegative: ${colors.red.negative};
    --tealtercial: ${colors.teal.tercial};

    --primary: var(--greenprimary);
    --disabled: ${colors.disabled};
    --disableddimmed: ${colors.dimDisabled};

    --textprimary: ${colors.text.primary};
    --textsecondary: ${colors.text.secondary};
    --textdisabled: ${colors.text.disabled};
    --white: ${colors.white};
    /* Fonts */
    --caption: ${toRem(fonts.caption)}rem;
    --bodysm: ${toRem(fonts.bodysm)}rem;
    --body1: ${toRem(fonts.body1)}rem;
    --h1: ${toRem(fonts.h1)}rem;
    --h2: ${toRem(fonts.h2)}rem;
    --h3: ${toRem(fonts.h3)}rem;
    --h4: ${toRem(fonts.h4)}rem;
    --fontlarger: ${toRem(fonts.fontlarger)}rem;
    --fontlg: ${toRem(fonts.fontlg)}rem;
    --fontxl: ${toRem(fonts.fontxl)}rem;
    --fontxxl: ${toRem(fonts.fontxxl)}rem;
    --fontxxxl: ${toRem(fonts.fontxxxl)}rem;
    /* Spaces */
    --s1: ${toRem(spaces[0])}rem; // 4
    --s2: ${toRem(spaces[1])}rem; // 8
    --s3: ${toRem(spaces[2])}rem; // 16
    --s4: ${toRem(spaces[3])}rem; // 32
    --s5: ${toRem(spaces[4])}rem; // 48
    --s6: ${toRem(spaces[5])}rem; // 64
    --s7: ${toRem(spaces[6])}rem; // 96
    --s8: ${toRem(spaces[7])}rem; // 128
    /* Width */
    --width-standard: ${toRemStr(width.standard)};
    --width-large: ${toRemStr(width.large)};
    --width-xlarge: ${toRemStr(width.xLarge)};
    --width-xxlarge: ${toRemStr(width.xxLarge)};

    --shadow-1: 0rem ${toRem(1)}rem ${toRem(6)}rem #00000029;
    --shadow-2: 0rem ${toRem(2)}rem ${toRem(6)}rem #00000029;
    --shadow-3: 0rem ${toRem(3)}rem ${toRem(6)}rem #00000029;
    --shadow-4: 0rem ${toRem(4)}rem ${toRem(6)}rem #00000029;
    --shadow-5: 0rem ${toRem(4)}rem ${toRem(6)}rem #00000029;
    --shadow-6: 0rem ${toRem(8)}rem ${toRem(6)}rem #00000029;
    --shadow-7: 0rem ${toRem(12)}rem ${toRem(6)}rem #00000029;
    --shadow-8: 0rem ${toRem(16)}rem ${toRem(6)}rem #00000029;
    --shadow-9: 0rem ${toRem(24)}rem ${toRem(6)}rem #00000029;
    
    --bottom-inner-shadow: inset 0px -${toRem(11)}rem -${toRem(8)}rem -${toRem(
  10,
)}rem rgba(0, 0, 0, 0.1);
  }

  * {
    box-sizing: border-box;
  }

  html, body {
    padding: 0;
    margin: 0;
    font-family: ${fontFamily};
    color: var(--textprimary);
    font-size: ${BASE_FONT_SIZE_PX}px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    color: var(--greenaccent);
    text-decoration: none;

    &:hover {
      color: var(--yellowaction);
      text-decoration: underline;
    }

    &[aria-disabled] {
      color: var(--textdisabled);
      text-decoration: none;
    }
  }

  h2 {
    ${h2}
  }

  h3 {
    ${h3}
  }

  h4 {
    ${h4}
  }

  .app-toast {
    max-width: 1120px;
    padding: var(--s2) var(--s3);
    border-radius: var(--s3);
    box-shadow: var(--shadow-3);
    margin-top: var(--s6);

    [role="status"] {
      margin: 0;
      margin-left: var(--s3);
      font-size: var(--bodysm);
      line-height: var(--body1);
    }

    &.message-size-body1 [role="status"] {
      font-size: var(--body1);
      line-height: var(--h4);
    }
  }
`;

function ThemeProvider({
  children,
}: React.PropsWithChildren<ThemeProviderProps>) {
  return (
    <Fragment>
      <GlobalStyle />
      {children}
    </Fragment>
  );
}

export default ThemeProvider;
