import { CommonButton } from 'components/common/Forms/Button';
import DatePickerInput from 'components/common/Forms/DatePickerInput';
import { useMemo } from 'react';
import { FaCalendarAlt, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import {
  formatDateOnlyISO,
  isAfter,
  isBefore,
  parseIsoDate,
} from '../../utils/dateUtil';

export interface CommonPrevNextRangePickerProps {
  selectedDate: Date;
  onSelectDate: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  iconOnly?: boolean;
}

interface PrevNextRangePickerProps extends CommonPrevNextRangePickerProps {
  startOfRange: (date: Date) => Date;
  endOfRange: (date: Date) => Date;
  subRange(date: Date | number, amount: number): Date;
  addRange(date: Date | number, amount: number): Date;
}

export const PrevNextRangePicker = ({
  selectedDate,
  onSelectDate,
  minDate,
  maxDate,
  iconOnly,
  startOfRange,
  endOfRange,
  subRange,
  addRange,
}: PrevNextRangePickerProps) => {
  const { t } = useCommonTranslation();
  const isRangeMin = useMemo(
    () => minDate && isAfter(minDate, startOfRange(selectedDate)),
    [minDate, selectedDate, startOfRange],
  );
  const isRangeMax = useMemo(
    () => maxDate && isBefore(maxDate, endOfRange(selectedDate)),
    [maxDate, selectedDate, endOfRange],
  );
  const size = iconOnly ? 'auto' : 'standard';

  return (
    <>
      <CommonButton
        type="button"
        variant="primary"
        size={size}
        onClick={() => onSelectDate(subRange(selectedDate, 1))}
        disabled={isRangeMin}
        iconOnly={iconOnly}
      >
        {iconOnly ? <FaChevronLeft /> : t('previous')}
      </CommonButton>
      <DatePickerInput
        noSpaceForHelperText
        name="calendar-date"
        className="calendar-datepicker"
        minDate={minDate}
        maxDate={maxDate}
        containerStyle={{
          marginLeft: 'var(--s2)',
          marginRight: 'var(--s2)',
        }}
        onChange={(date) => {
          date && onSelectDate(parseIsoDate(date));
        }}
        selected={formatDateOnlyISO(selectedDate)}
        icon={<FaCalendarAlt />}
      />
      <CommonButton
        type="button"
        variant="primary"
        size={size}
        onClick={() => selectedDate && onSelectDate(addRange(selectedDate, 1))}
        iconOnly={iconOnly}
        disabled={isRangeMax}
      >
        {iconOnly ? <FaChevronRight /> : t('next')}
      </CommonButton>
    </>
  );
};
