import { AxiosResponse } from 'axios';
import { useSite } from 'context/SiteContext';
import { UseQueryOptions } from 'react-query';
import {
  generateReport,
  GenerateReportParams,
  getReports,
  getReportStatisticDetails,
  GetReportStatisticDetailsParams,
  getReportStatistics,
  GetReportStatisticsParams,
  getReportSupervisor,
  getReportSupervisorDetails,
  getSADApptStatistics,
  getSADSiteStatistics,
} from 'services/reports';
import {
  ReportCubiclePatient,
  ReportCubicleResponse,
  ReportsResponse,
  ReportStatisticsPatient,
  Statistics,
} from 'types/reports';
import {
  CacheAndStaleTimeForUnlikelyChanged,
  useQueryWithToastError,
} from './queryUtils';

export const REPORTS_QUERY_KEY = 'reports';

export const useReports = (
  options?:
    | UseQueryOptions<any, any, AxiosResponse<ReportsResponse>>
    | undefined,
) => {
  const { activeSite } = useSite();
  return useQueryWithToastError(
    [REPORTS_QUERY_KEY, activeSite.id],
    () => getReports(),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
      ...options,
    },
  );
};

export const useReportsSupervisor = (
  options?:
    | UseQueryOptions<any, any, AxiosResponse<ReportCubicleResponse>>
    | undefined,
) => {
  const { activeSite } = useSite();
  return useQueryWithToastError(
    [REPORTS_QUERY_KEY, 'supervisor', activeSite.id],
    () => getReportSupervisor(),
    options,
  );
};

export const useReportsSupervisorDetails = (
  id: string,
  options?:
    | UseQueryOptions<any, any, AxiosResponse<ReportCubiclePatient[]>>
    | undefined,
) => {
  const { activeSite } = useSite();
  return useQueryWithToastError(
    [REPORTS_QUERY_KEY, 'supervisor', 'details', id, activeSite.id],
    () => getReportSupervisorDetails(id),
    {
      enabled: !!id,
      ...options,
    },
  );
};

export const useReportsStatistics = (
  params: GetReportStatisticsParams,
  options?: UseQueryOptions<any, any, AxiosResponse<Statistics>> | undefined,
) => {
  const { activeSite } = useSite();
  return useQueryWithToastError(
    [REPORTS_QUERY_KEY, 'statistics', params, activeSite.id],
    () => getReportStatistics(params),
    options,
  );
};

export const useReportStatisticDetails = (
  params: GetReportStatisticDetailsParams,
  options?:
    | UseQueryOptions<any, any, AxiosResponse<ReportStatisticsPatient[]>>
    | undefined,
) => {
  const { activeSite } = useSite();
  return useQueryWithToastError(
    [REPORTS_QUERY_KEY, 'statisticDetails', params, activeSite.id],
    () => getReportStatisticDetails(params),
    {
      enabled: !!params.id,
      ...options,
    },
  );
};

export const useGenerateReportBlob = (
  params: Partial<GenerateReportParams>,
  options?: UseQueryOptions<any, any, AxiosResponse<BlobPart>> | undefined,
) => {
  return useQueryWithToastError(
    [REPORTS_QUERY_KEY, 'blob', params],
    () => generateReport(params as GenerateReportParams),
    {
      enabled: Object.keys(params).every(
        (key) => !!params[key as keyof typeof params],
      ),
      ...options,
    },
  );
};

export const useSADApptStatistics = () => {
  return useQueryWithToastError(
    [REPORTS_QUERY_KEY, 'sadApptStatistics'],
    () => getSADApptStatistics(),
    {
      select: (data) => data.data,
    },
  );
};

export const useSADSiteStatistics = (siteId: string) => {
  return useQueryWithToastError(
    [REPORTS_QUERY_KEY, 'sadSiteStatistics', siteId],
    () => getSADSiteStatistics(siteId),
    {
      select: (data) => data.data,
    },
  );
};
