import { useEffect, useRef, useState } from 'react';
import { ClientRect } from 'types/common';

const emptyRect = () => ({
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

export const useClientRect = <RefType extends HTMLElement = any>() => {
  const ref = useRef<RefType>();
  const [rect, setRect] = useState<ClientRect>(emptyRect);

  const set = () =>
    setRect(
      ref && ref.current ? ref.current.getBoundingClientRect() : emptyRect,
    );

  useEffect(() => {
    set();
    window.addEventListener('resize', set);
    return () => window.removeEventListener('resize', set);
  }, []);

  return { ref, rect };
};
