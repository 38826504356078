import { AxiosResponse } from 'axios';
import { CardLoader } from 'components/common/Loading';
import { QuillPreview } from 'components/WYSIWYG/WYSIWYG.styled';
import React, { Fragment, ReactElement } from 'react';
import ReactQuill from 'react-quill';

type Props = {
  isLoading: boolean;
  data?: AxiosResponse<string>;
};

export default function ReleaseNotesContent({
  isLoading,
  data,
}: Props): ReactElement {
  const content = data?.data;

  return (
    <Fragment>
      {isLoading && !Boolean(content) ? (
        <CardLoader />
      ) : (
        <Fragment>
          <QuillPreview>
            <ReactQuill
              modules={{
                toolbar: false,
              }}
              value={content as string}
              readOnly={true}
              theme="snow"
            />
          </QuillPreview>
        </Fragment>
      )}
    </Fragment>
  );
}
