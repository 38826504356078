import { CommonButton as Button } from 'components/common/Forms/Button';
import React, { ReactElement } from 'react';
import { UserData, UsersFilters } from 'types/users';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { SearchPatientDialog } from '../user/SearchPatientDialog';
import { SearchPatientTable } from '../user/SearchPatientTable';

interface Props {
  onClose: () => void;
  initialFilters?: Partial<UsersFilters>;
  onSelectPatient: (patient: UserData) => void;
  stickyFilters?: Partial<UsersFilters>;
}

export const SearchSelectPatientDialog = ({
  initialFilters = {},
  onClose,
  onSelectPatient,
  stickyFilters,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();
  return (
    <SearchPatientDialog>
      <SearchPatientTable
        stickyFilters={stickyFilters}
        initialFilters={{
          sortField: 'lastname',
          sortDirection: 'asc',
          ...initialFilters,
        }}
        filtersDisplayed={[
          'firstname',
          'lastname',
          'email',
          'phone',
          'birthDate',
        ]}
        rowActionsCreator={(patient) => (
          <Button
            size="auto"
            variant="primary"
            onClick={() => {
              onSelectPatient(patient);
            }}
          >
            {t('select')}
          </Button>
        )}
        underTableElement={
          <Button
            variant="danger"
            onClick={onClose}
            style={{
              marginTop: 'var(--s3)',
            }}
          >
            {t('close-search-results')}
          </Button>
        }
      />
    </SearchPatientDialog>
  );
};
