import toast from 'react-hot-toast';
import { getErrorMessage } from './errors';
import { toastWarningIcon } from './toastIcons';
import { MILLIS_IN_SECOND } from './dateUtil';

const TOAST_DURATION = 5 * MILLIS_IN_SECOND;
const ERROR_TOAST_DURATION = 15 * MILLIS_IN_SECOND;

export const errorToastPromise = async <T>(
  promise: PromiseLike<T>,
  onError?: () => void,
) => {
  try {
    return await promise;
  } catch (e) {
    errorToast(e);
    onError?.();
  }
};

export function errorToast(
  err?: any,
  options?: { messageSize?: 'bodysm' | 'body1' },
) {
  toast.error(getErrorMessage(err), {
    duration: ERROR_TOAST_DURATION,
    className: `app-toast${
      options?.messageSize === 'body1' ? ' message-size-body1' : ''
    }`,
    icon: toastWarningIcon(),
    style: {
      background: 'var(--rednegative)',
      color: 'var(--white)',
    },
  });
}

export function successToast(message: string) {
  toast.success(message, {
    duration: TOAST_DURATION,
    className: 'app-toast',
    icon: '✓',
    style: {
      background: 'var(--greenaccent)',
      color: 'var(--white)',
    },
  });
}
