import styled from 'styled-components';
import { h4, stripButton } from 'styles/Typography';
import { toRem } from 'utils/helpers';
import {
  AppointmentBookingType,
  AppointmentStatus,
} from '../../../types/appointments';
import { POPUP_OVERLAY_Z_INDEX } from '../../../context/PopupContext';

export const ApptTimeWrapper = styled.div`
  margin-top: var(--s3);
  width: 99%;
`;

export const TimeSlotTitle = styled.h4`
  ${h4};
  min-width: ${toRem(52)}rem;
  text-align: right;
  margin-right: var(--s4);
`;

export const APPT_WRAPPER_NEGATIVE_MARGIN_LEFT = `${toRem(28)}rem`;

export const TimeSlotChips = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--s1);
  margin-left: ${APPT_WRAPPER_NEGATIVE_MARGIN_LEFT};
  margin-right: -${APPT_WRAPPER_NEGATIVE_MARGIN_LEFT};
`;

export const TimeSlotWrapper = styled.div`
  min-height: ${toRem(32)}rem;
  display: flex;
  align-items: center;
  background: var(--white);
  box-shadow: var(--shadow-3);
  border-radius: var(--s3);
  margin-bottom: var(--s1);
  padding: 0 var(--s2);
`;

const getApptChipNormalOrInverted = (
  selected: boolean,
  color: string,
  background: string,
  bookingType: AppointmentBookingType,
): string => {
  const borderColor =
    bookingType === 'OVERFLOW'
      ? 'var(--textprimary)'
      : selected
      ? background
      : color;
  const textColor = selected ? background : color;
  const fillColor = selected ? color : background;

  return `color: ${borderColor};& button {color: ${textColor};} background: ${fillColor};`;
};

const white = 'var(--white)';
export const APPOINTMENT_HOVER_INDEX = POPUP_OVERLAY_Z_INDEX - 1;

export const ApptWrapper = styled.div<{
  appointmentStatus: AppointmentStatus;
  appointmentBookingType: AppointmentBookingType;
  reverseIndex: number;
  selected: boolean;
}>`
  min-width: 8.25rem;
  min-height: ${toRem(32)}rem;

  ${({ appointmentStatus, appointmentBookingType, selected }) => {
    switch (appointmentStatus) {
      case 'PROCESSING':
        return getApptChipNormalOrInverted(
          selected,
          white,
          'var(--blueprimary)',
          appointmentBookingType,
        );
      case 'QUEUE':
      case 'CALLED':
        return getApptChipNormalOrInverted(
          selected,
          white,
          'var(--yellowaction)',
          appointmentBookingType,
        );
      case 'BOOKED':
        return getApptChipNormalOrInverted(
          selected,
          white,
          'var(--greenprimary)',
          appointmentBookingType,
        );
      case 'TAKEN':
      case 'NOT_TAKEN':
      case 'NOT_PREPARED':
        return getApptChipNormalOrInverted(
          selected,
          'var(--disableddimmed)',
          white,
          appointmentBookingType,
        );
      case 'DNA':
      case 'MIA':
        return getApptChipNormalOrInverted(
          selected,
          'var(--rednegative)',
          white,
          appointmentBookingType,
        );
    }
    return getApptChipNormalOrInverted(
      false,
      'var(--textdisabled)',
      white,
      appointmentBookingType,
    );
  }};
  border: ${toRem(2)}rem solid;
  border-radius: ${toRem(50)}rem;
  display: flex;
  align-items: center;
  margin-left: -${APPT_WRAPPER_NEGATIVE_MARGIN_LEFT};
  transition: 0.2s ease transform;
  padding: 0 ${toRem(10)}rem;
  z-index: ${({ reverseIndex }) => reverseIndex};

  ${({ selected }) => selected && `z-index: ${APPOINTMENT_HOVER_INDEX - 1};`}
  &:hover {
    z-index: ${APPOINTMENT_HOVER_INDEX};
    transform: scale(1.1);
  }
`;
export const EditAppt = styled.button`
  ${stripButton};
  margin-right: var(--s2);
  color: var(--white);
  line-height: 0;
`;
export const ApptChipTextIndicatorsStyle = styled.div`
  font-size: ${toRem(10)}rem;
  line-height: var(--s3);
  font-weight: 400;
  border-radius: ${toRem(50)}rem;
  text-transform: uppercase;
`;

export const ApptUser = styled.div`
  font-size: var(--bodysm);
  line-height: 1;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: var(--s2);
`;
export const ApptAction = styled.button`
  ${stripButton};
  display: flex;
  align-items: center;
  flex: 1;
  color: var(--white);
`;

export const ApptSlotEnd = styled.div`
  margin-left: auto;
  display: flex;

  & :last-child {
    margin-right: 0;
  }

  & * {
    margin-right: var(--s2);
  }
`;

export const SlotAppointmentRightIndicatorStyle = styled(
  ApptChipTextIndicatorsStyle,
)`
  border: solid 1px;
  color: var(--white);
  min-height: var(--s3);
  min-width: var(--s3);
  padding: 0 var(--s1);
`;
