import { FC, Fragment } from 'react';
import { useLocation } from 'react-router';
import { patientURL } from 'routes/AppRoutes';
import { makeStateContext } from 'utils/makeStateContext';
import { patientBookingInit } from './modules/booking';
import { patientCheckInInit } from './modules/checkIn';

export const initState = () => ({
  booking: patientBookingInit(),
  checkIn: patientCheckInInit(),
});

export type PatientState = ReturnType<typeof initState>;

export const patientContext = makeStateContext({
  initState,
});

export const PatientProvider: FC = (props) => {
  const location = useLocation();
  if (location.pathname.startsWith(patientURL)) {
    return <patientContext.Provider>{props.children}</patientContext.Provider>;
  }
  return <Fragment>{props.children}</Fragment>;
};
