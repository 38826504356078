import {
  MutationFunction,
  MutationKey,
  QueryFunction,
  QueryKey,
} from 'react-query/types/core/types';
import {
  UseMutationOptions,
  UseQueryOptions,
} from 'react-query/types/react/types';
import { QueriesOptions, useMutation, useQueries, useQuery } from 'react-query';
import { errorToast } from '../utils/toast';
import { MILLIS_IN_MINUTE } from '../utils/dateUtil';

export const CacheAndStaleTimeForUnlikelyChanged = {
  cacheTime: 15 * MILLIS_IN_MINUTE,
  staleTime: 10 * MILLIS_IN_MINUTE,
};

export const CacheAndStaleTimeShort = {
  cacheTime: 5 * MILLIS_IN_MINUTE,
  staleTime: 1 * MILLIS_IN_MINUTE,
};

export interface UseSwappedQueryOptions<
  TData = unknown,
  TError = unknown,
  TQueryFnData = TData,
  TQueryKey extends QueryKey = QueryKey,
> extends UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> {}

export const useQueryWithToastError = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
) => {
  return useQuery(queryKey, queryFn, {
    onError: (e) => errorToast(e),
    ...options,
  });
};

export const useQueriesWithToastError = <
  T extends any[],
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queries: QueriesOptions<T>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
) => {
  const queriesPrepared = queries.map((query) => ({
    onError: (e: unknown) => errorToast(e),
    ...options,
    ...query,
  })) as QueriesOptions<T>;
  return useQueries<T>(queriesPrepared);
};

export type UseMutationOptionsWithError<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
> = UseMutationOptions<TData, TError, TVariables, TContext> & {
  onErrorAfterToast?: (error: TError) => void;
};

export const useMutationWithToastError = <
  TVariables = void,
  TData = unknown,
  TError = unknown,
  TContext = unknown,
>(
  mutationKey: MutationKey,
  mutationFn?: MutationFunction<TData, TVariables>,
  options?: UseMutationOptionsWithError<TData, TError, TVariables, TContext>,
) => {
  return useMutation(mutationKey, mutationFn, {
    onError: (e) => {
      errorToast(e);
      options?.onErrorAfterToast?.(e);
    },
    ...options,
  });
};
