import { BookingFor } from 'components/patient/BookingFor';
import { useSite } from 'context/SiteContext';
import { useFamilyMember } from 'hooks/patient/useFamilyMember';
import { WaitingListParams } from 'hooks/patient/waitingList/useWaitingListURL';
import { useParams } from 'react-router';
import { getUserFullname } from 'utils/user';

export const WaitingListFor = () => {
  const { memberId } = useParams<WaitingListParams>();
  const { activeSite } = useSite();
  const user = useFamilyMember(memberId);
  const name = getUserFullname(user);

  return <BookingFor name={name} department={activeSite.name} />;
};
