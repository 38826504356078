import sangixLogoWhiteSvg from 'images/Logo/sangix-logo-white.svg';
import React, { ReactElement } from 'react';
import { MenuItemProps } from 'types/common';
import {
  IconWrapper,
  ListItem,
  NavMenuStatus,
  PoweredByText,
  PoweredByWrapper,
  SangixLogo,
  SideMenuList,
  SideMenuWrapper,
  StyledNavLink,
  SubMenu,
  SubMenuItem,
  SubMenuLink,
} from './SideMenu.styled';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';

interface Props {
  navigations: MenuItemProps[];
}

export const SideMenu = ({ navigations }: Props): ReactElement => {
  const { t } = useCommonTranslation();

  return (
    <SideMenuWrapper>
      <SideMenuList>
        {navigations.map((nav) => (
          <ListItem key={nav.label} haveSubMenu={Boolean(nav.subMenu)}>
            <StyledNavLink to={nav.to}>
              <IconWrapper>{nav.icon}</IconWrapper>
              {nav.label}
              {nav.status && (
                <NavMenuStatus size="auto" variant="secondary">
                  {nav.status}
                </NavMenuStatus>
              )}
            </StyledNavLink>
            {Boolean(nav.subMenu) && (
              <SubMenu>
                {nav.subMenu?.map((sub) => (
                  <SubMenuItem key={'sub-' + sub.to}>
                    <SubMenuLink to={sub.to}>{sub.label}</SubMenuLink>
                  </SubMenuItem>
                ))}
              </SubMenu>
            )}
          </ListItem>
        ))}
      </SideMenuList>
      <PoweredByWrapper>
        <PoweredByText>{t('powered-by')}</PoweredByText>
        <SangixLogo src={sangixLogoWhiteSvg} alt="Sangix" />
      </PoweredByWrapper>
    </SideMenuWrapper>
  );
};
