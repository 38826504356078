import { Card } from 'components/common/index';
import styled from 'styled-components';
import { h3 } from 'styles/Typography';
import { toRem } from 'utils/helpers';

export const FormTitle = styled.h1`
  ${h3};
  text-align: center;
  margin-bottom: var(--s3);
`;

export const FormWrapper = styled(Card)`
  width: 100%;
  max-width: ${toRem(400)}rem;
  padding: 0;
  margin: auto;
`;
export const LoginFormActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${toRem(216)}rem;
  margin: 0 auto;

  & > * {
    margin-bottom: var(--s3);

    &:last-child {
      margin-bottom: 0;
    }
  }

  & > * {
    width: 100%;
  }
`;
export const NewUser = styled.div`
  border-radius: var(--s3);
  background-color: var(--greentercial);
  padding: var(--s3);
  width: 100%;
  margin-top: var(--s3);
`;
export const LoginWithGoogleInner = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: var(--s2);
    height: var(--s3);
  }
`;

export const LoginPartWrapper = styled.div`
  padding: var(--s3) var(--s3) 0;
`;
