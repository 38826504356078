import { AxiosError } from 'axios';
import { Card } from 'components/common';
import { CommonButton as Button } from 'components/common/Forms/Button';
import {
  CloseButton,
  DialogFormWrapper,
  FormActionsStyle,
  FormBody,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import CommonSelect from 'components/common/Forms/Select';
import TextArea from 'components/common/Forms/TextArea';
import { CardLoader } from 'components/common/Loading';
import React, { ReactElement, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaHospital, FaTimes } from 'react-icons/fa';
import { ClinicalCommissioningGroup } from 'types/ccg';
import { GeneralPractice, GPFormValues } from 'types/generalPractices';

interface Props {
  closeFormHandler: (err?: true | AxiosError<any>) => void;
  ccgData?: ClinicalCommissioningGroup[] | null;
  submitHandler: (data: GPFormValues, isUpdateGP: boolean) => void;
  isLoading: boolean;
  prefilledData?: GeneralPractice;
  handleDeleteGPSurgery: (value: string) => void;
}

export default function GPSurgeryForm({
  closeFormHandler,
  ccgData,
  submitHandler,
  isLoading,
  handleDeleteGPSurgery,
  prefilledData,
}: Props): ReactElement {
  const isEdit = Boolean(prefilledData);
  const { t } = useTranslation('common');
  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    control,
    formState: { errors },
  } = useForm<GPFormValues>({
    defaultValues: prefilledData
      ? {
          name: prefilledData.name,
          description: prefilledData.description,
        }
      : {},
  });

  useEffect(() => {
    if (prefilledData && ccgData) {
      const selectedCCG = ccgData.find(
        (ccg) => ccg.id === prefilledData.ccg_id,
      );
      if (selectedCCG) {
        setValue('ccg_id', selectedCCG.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccgData, prefilledData]);

  useEffect(() => {
    // This for setting focus after form mounted for better user experience
    setFocus('name');
  }, [setFocus]);

  return (
    <Card>
      <DialogFormWrapper>
        <CloseButton
          size="auto"
          variant="primary"
          iconOnly
          onClick={() => closeFormHandler()}
        >
          <FaTimes />
        </CloseButton>
        <FormHeader>
          <FaHospital />
          <FormTitle>{t('gp-surgery')}</FormTitle>
        </FormHeader>
        <FormBody
          onSubmit={handleSubmit((data) => submitHandler(data, isEdit))}
        >
          <FormRow>
            <Input
              label={t('gp-surgery-name')}
              placeholder={t('gp-surgery-name')}
              helperText={errors.name?.message}
              hasError={Boolean(errors.name)}
              {...register('name', {
                required: t('must-not-empty') as string,
              })}
              required
            />
          </FormRow>
          <FormRow>
            <TextArea
              label={t('description')}
              placeholder={t('description')}
              helperText={errors.description?.message}
              hasError={Boolean(errors.description)}
              {...register('description')}
            />
          </FormRow>
          <FormRow>
            <Controller
              name="ccg_id"
              render={({ field }) => (
                <CommonSelect
                  {...field}
                  label={t('ccg')}
                  instanceId="ccg_id"
                  placeholder={t('ccg')}
                  isClearable
                  helperText={errors.ccg_id?.message}
                  hasError={Boolean(errors.ccg_id)}
                  disabled={!ccgData}
                  options={
                    ccgData
                      ? ccgData.map((ccg) => ({
                          label: ccg.name,
                          value: ccg.id,
                        }))
                      : [{ label: t('loading'), value: '2' }]
                  }
                  required
                />
              )}
              control={control}
              rules={{
                required: t('must-not-empty') as string,
              }}
            />
          </FormRow>
          <FormActionsStyle align="center">
            <Button variant="primary" type="submit">
              {isEdit ? t('save') : t('add')}
            </Button>
            {prefilledData && (
              <Button
                variant="danger"
                type="button"
                onClick={() => handleDeleteGPSurgery(prefilledData.id)}
              >
                {t('delete')}
              </Button>
            )}
            <Button
              type="button"
              variant="secondary"
              onClick={() => closeFormHandler()}
            >
              {t('cancel')}
            </Button>
          </FormActionsStyle>
        </FormBody>
        {isLoading && <CardLoader fillWrapper={true} />}
      </DialogFormWrapper>
    </Card>
  );
}
