import { LinkCardTabs } from '../../../components/Card/CardTabs/LinkCardTabs';
import { Card } from '../../../components/common';
import { FormTitle } from '../../../components/common/Forms/Forms.styled';
import { Col, Grid } from '../../../components/common/Grid';
import { CardLoader } from '../../../components/common/Loading';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { ICardTabs } from '../../../types/common';
import { TrustData } from '../../../types/trusts';
import {
  TrustPreferencesBackButton,
  TrustPreferencesHeader,
  TrustPreferencesRightControls,
} from './TrustPreferences.styled';
import { TrustPreferencesTab } from './TrustPreferencesHooks';
import { TrustMiniSitePreferences } from './mini-site/TrustMiniSitePreferences';

type Props = {
  trust?: TrustData;
  selectedTabKey: TrustPreferencesTab;
  tabsConfig: ICardTabs[];
};

export const TrustPreferences = ({
  trust,
  selectedTabKey,
  tabsConfig,
}: Props) => {
  const { t } = useCommonTranslation();

  return (
    <Grid>
      {!trust ? (
        <Col>
          <CardLoader />
        </Col>
      ) : (
        <>
          <TrustPreferencesHeader>
            <Card>
              <FormTitle>
                {trust.name} {t('preferences')}
              </FormTitle>
              <LinkCardTabs tabs={tabsConfig} noMargin={true} />
              <TrustPreferencesRightControls>
                <TrustPreferencesBackButton
                  to={'/admin/departments/trusts'}
                  type="button"
                  variant="secondary"
                >
                  {t('back')}
                </TrustPreferencesBackButton>
              </TrustPreferencesRightControls>
            </Card>
          </TrustPreferencesHeader>
          {selectedTabKey === 'mini-site' && (
            <TrustMiniSitePreferences trust={trust} />
          )}
        </>
      )}
    </Grid>
  );
};
