import { css } from 'styled-components';
import { toRem } from 'utils/helpers';
import React from 'react';

export const spaceSelector = '> * + *';

export const fonts = {
  caption: 12,
  bodysm: 14,
  body1: 16,
  h1: 48,
  h2: 34,
  h3: 24,
} as const;

export const caption = css({
  fontSize: 'var(--caption)',
  fontWeight: 'normal',
  color: 'var(--textsecondary)',
  lineHeight: 1.2,
  margin: 0,
});

export const small = css({
  fontSize: 'var(--bodysm)',
  fontWeight: 'normal',
  color: 'var(--textsecondary)',
  lineHeight: 1.2,
  margin: 0,
});

export const h2 = css({
  fontSize: 'var(--h2)',
  fontWeight: 'normal',
  color: 'var(--textprimary)',
  lineHeight: 1.2,
  margin: 0,
});

export const h3 = css({
  fontSize: 'var(--h3)',
  fontWeight: 'normal',
  color: 'var(--textprimary)',
  lineHeight: 1.2,
  margin: 0,
});
export const h4 = css({
  fontSize: 'var(--h4)',
  fontWeight: 600,
  color: 'var(--textprimary)',
  lineHeight: 1.2,
  margin: 0,
});

export const link = css({
  background: 'transparent',
  padding: 0,
  border: 'none',
  cursor: 'pointer',
  color: `var(--greenaccent)`,
  fontSize: 'inherit',
  textDecoration: 'none',
  '&:hover, &:focus': {
    color: `var(--yellowaction)`,
    textDecoration: 'underline',
  },
  '&[aria-disabled]': {
    color: `var(--textdisabled)`,
    textDecoration: 'none',
  },
});

export const stripButton = css({
  appearance: 'none',
  background: 'none',
  outline: 'none',
  border: 'none',
  cursor: 'pointer',
  padding: '0',
});

export const TableHeadStyle: React.CSSProperties = {
  fontSize: `${toRem(20)}rem`,
  fontWeight: '600',
  textTransform: 'uppercase',
  color: 'var(--textprimary)',
};
