import { CardFooter } from 'components/common';
import { CommonButton, CommonLink } from 'components/common/Forms/Button';
import { useGoBackURL } from 'hooks/patient/waitingList/useGoBack';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';

export const WaitingListButtonBar = ({
  isLoading,
  deleteWaitingList,
}: {
  isLoading: boolean;
  deleteWaitingList: () => any;
}) => {
  const { t } = useCommonTranslation();
  const goBackURL = useGoBackURL();

  return (
    <CardFooter spaceBetween>
      <CommonButton
        disabled={isLoading}
        type="submit"
        size="standard"
        variant="primary"
      >
        {t('save-all')}
      </CommonButton>
      <CommonButton
        disabled={isLoading}
        type="button"
        size="standard"
        variant="danger"
        onClick={deleteWaitingList}
      >
        {t('clear-all')}
      </CommonButton>
      <CommonLink size="large" variant="secondary" to={goBackURL}>
        {t('back-to-menu')}
      </CommonLink>
    </CardFooter>
  );
};
