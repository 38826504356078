import { SupportCard } from 'containers/support/SupportCard';
import { StaffLayout } from '../StaffLayout';
import { PageHelmet } from '../../../components/common/PageHelmet';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { Col, Grid } from '../../../components/common/Grid';

export const StaffSupportPage = () => {
  const { t } = useCommonTranslation();
  return (
    <StaffLayout>
      <PageHelmet title={t('support')} />
      <Grid>
        <Col md={8}>
          <SupportCard />
        </Col>
      </Grid>
    </StaffLayout>
  );
};
