import { useMemo } from 'react';
import {
  addDays,
  isAfterNotSameDay,
  isAfterOrSameDay,
  isBeforeNotSameDay,
  isBeforeOrSameDay,
  subDays,
} from '../../utils/dateUtil';

export const usePrevNextDay = ({
  date,
  filterDate,
  minDate,
  maxDate,
}: {
  date: Date;
  filterDate?: (date: Date) => boolean;
  minDate?: Date;
  maxDate?: Date;
}) => {
  return useMemo(() => {
    let prevDate = date,
      nextDate = date;

    if (filterDate) {
      do {
        prevDate = subDays(prevDate, 1);
      } while (
        !filterDate(prevDate) &&
        (!minDate || isAfterOrSameDay(prevDate, minDate))
      );
      do {
        nextDate = addDays(nextDate, 1);
      } while (
        !filterDate(nextDate) &&
        (!maxDate || isBeforeOrSameDay(prevDate, maxDate))
      );
    }

    const prevDisabled = !!minDate && isBeforeNotSameDay(prevDate, minDate);
    const nextDisabled = !!maxDate && isAfterNotSameDay(nextDate, maxDate);

    return {
      prevDate,
      nextDate,
      prevDisabled,
      nextDisabled,
    };
  }, [date, filterDate, minDate, maxDate]);
};
