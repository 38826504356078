import { useApptAvailableDays } from '../../../query/appointments';
import { useCallback, useEffect, useState } from 'react';
import {
  addDays,
  addWeeks,
  DateOnlyISOString,
  endOfWeek,
  formatDateOnlyISO,
  isAfter,
  isBefore,
  parseIsoDate,
  startOfWeek,
  subDays,
} from '../../../utils/dateUtil';
import { useMaxAndMinFutureDateForBookingForOnlineBooking } from './useMaxAndMinFutureDateForBooking';
import { AvailableDaysData } from '../../../types/appointments';
import { AxiosResponse } from 'axios';

type BookingSearchState =
  | 'SEARCHING'
  | 'LOADING_NO_SEARCH'
  | 'FOUND_RESULTS'
  | 'NO_RESULTS_FOUND'
  | 'LAST_PERIOD_NO_RESULTS_FOUND';

const useIsDateInLastWeek = () => {
  const { maxDate } = useMaxAndMinFutureDateForBookingForOnlineBooking();
  return useCallback(
    (date: Date) => {
      return (
        isAfter(maxDate, startOfWeek(date)) &&
        isBefore(maxDate, endOfWeek(date))
      );
    },
    [maxDate],
  );
};

const useDateWithinSearchBounds = (date: DateOnlyISOString) => {
  const { minDate, maxDate } =
    useMaxAndMinFutureDateForBookingForOnlineBooking();

  let dateWithinBounds = parseIsoDate(date);

  if (isAfter(endOfWeek(dateWithinBounds), maxDate)) {
    dateWithinBounds = maxDate;
  }

  if (isBefore(startOfWeek(dateWithinBounds), minDate)) {
    dateWithinBounds = minDate;
  }

  return formatDateOnlyISO(dateWithinBounds);
};

export const useWeekSearchWithStart = ({
  dateWithinBounds,
  startSearch,
}: {
  dateWithinBounds: DateOnlyISOString;
  startSearch: boolean;
}) => {
  const [isSearchRunning, setIsSearchRunning] = useState(startSearch);
  const [dateSearching, setDateSearching] = useState(
    parseIsoDate(dateWithinBounds),
  );

  useEffect(() => {
    setDateSearching(parseIsoDate(dateWithinBounds));
  }, [dateWithinBounds]);

  const stopFreeApptSearch = useCallback(() => {
    setIsSearchRunning(false);
  }, [setIsSearchRunning]);

  const startFreeApptSearchFromNextPeriod = useCallback(() => {
    setIsSearchRunning(true);
    setDateSearching(addWeeks(dateSearching, 1));
  }, [dateSearching, setDateSearching]);

  const searchPeriod = {
    fromDate: startOfWeek(dateSearching),
    toDate: addDays(endOfWeek(dateSearching), 1),
  };

  return {
    isSearchRunning,
    stopFreeApptSearch,
    searchPeriod,
    startFreeApptSearchFromNextPeriod,
    dateSearching,
  };
};

export const LAST_PERIOD_NO_RESULTS_FOUND = 'LAST_PERIOD_NO_RESULTS_FOUND';
export const FOUND_RESULTS = 'FOUND_RESULTS';

const SEARCHING = 'SEARCHING';
const NO_RESULTS_FOUND = 'NO_RESULTS_FOUND';
export const useBookingAppointmentsWithFreeSearch = ({
  dateInSearchBox,
  startSearch,
}: {
  dateInSearchBox: DateOnlyISOString;
  startSearch: boolean;
}) => {
  const [status, setStatus] = useState<BookingSearchState>(
    startSearch ? SEARCHING : 'LOADING_NO_SEARCH',
  );
  const dateWithinBounds = useDateWithinSearchBounds(dateInSearchBox);
  const {
    stopFreeApptSearch,
    isSearchRunning,
    searchPeriod,
    startFreeApptSearchFromNextPeriod,
    dateSearching,
  } = useWeekSearchWithStart({
    dateWithinBounds,
    startSearch,
  });

  const isDateInLastWeek = useIsDateInLastWeek();

  const { minDate, maxDate } =
    useMaxAndMinFutureDateForBookingForOnlineBooking();
  const filterAvailableWithMinAndMaxDate = useCallback(
    (availableDaysUnfiltered?: AxiosResponse<AvailableDaysData>) =>
      availableDaysUnfiltered?.data.data?.filter(
        (date) =>
          isBefore(parseIsoDate(date), maxDate) &&
          isAfter(parseIsoDate(date), subDays(minDate, 1)),
      ),
    [maxDate, minDate],
  );

  const onAvailableSuccess = useCallback(
    (data: AxiosResponse<AvailableDaysData>) => {
      const filtered = filterAvailableWithMinAndMaxDate(data);
      if (filtered && filtered.length > 0) {
        setStatus(FOUND_RESULTS);
        stopFreeApptSearch();
      } else if (isDateInLastWeek(dateSearching)) {
        setStatus(LAST_PERIOD_NO_RESULTS_FOUND);
        stopFreeApptSearch();
      } else if (isSearchRunning) {
        setStatus(SEARCHING);
        startFreeApptSearchFromNextPeriod();
      } else {
        setStatus(NO_RESULTS_FOUND);
      }
    },
    [
      stopFreeApptSearch,
      startFreeApptSearchFromNextPeriod,
      isSearchRunning,
      dateSearching,
      isDateInLastWeek,
      filterAvailableWithMinAndMaxDate,
    ],
  );

  const { isFetching: isAvailableDaysLoading, data: availableDaysUnfiltered } =
    useApptAvailableDays(
      {
        fromDate: formatDateOnlyISO(searchPeriod.fromDate),
        toDate: formatDateOnlyISO(searchPeriod.toDate),
      },
      {
        onSuccess: onAvailableSuccess,
      },
    );

  const availableDays = filterAvailableWithMinAndMaxDate(
    availableDaysUnfiltered,
  );

  const isDayAvailable = useCallback(
    (date: Date) => {
      return (
        (isBefore(date, maxDate) &&
          availableDays?.includes(formatDateOnlyISO(date))) ||
        false
      );
    },
    [availableDays, maxDate],
  );

  return {
    status,
    isLoading: isAvailableDaysLoading || isSearchRunning,
    availableDays,
    dateSearching,
    isDayAvailable,
    startFreeApptSearchFromNextPeriod,
    stopFreeApptSearch,
    searchPeriod,
  };
};
