import { CommonButton as Button } from 'components/common/Forms/Button';
import { RowActions, TableActions } from 'components/Table/Table.styled';
import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import { TableHeadStyle } from 'styles/Typography';
import { CubicleData } from 'types/cubicles';
import { getNewColumnWidth } from 'utils/table';

import { BasicTable } from '../../../components/Table/BasicTable';
import { SHOW_BIRTH_DATE_IN_CUBICLE_QUEUE } from '../../../configs/siteAndTrustAttributes';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { useSiteBooleanAttributes } from '../../../hooks/useAttribute';
import { Appointment } from '../../../types/appointments';
import {
  formatDateOnlySite,
  formatHourMinutes,
  parseDateKeepTZ,
  parseIsoDate,
} from '../../../utils/dateUtil';
import { useQueuedAppointments } from './CubicleQueueHooks';
import { PatientInfoRow } from './PatientInfoRow';

interface Props {
  cubicle: CubicleData;
}

type QueueItem = {
  apptTime: string;
  checkinTime: string;
  resourceAbbr: string;
} & Pick<
  Appointment,
  | 'appt_flags'
  | 'id'
  | 'user_birthdate'
  | 'user_disabilities'
  | 'user_full_name'
>;

export const CubicleQueue = ({ cubicle }: Props) => {
  const { t } = useCommonTranslation();
  const { isLoading, appointments, refetch, callAppointment } =
    useQueuedAppointments({ cubicle });
  const [showBirthDate] = useSiteBooleanAttributes(
    SHOW_BIRTH_DATE_IN_CUBICLE_QUEUE,
  );

  const data: QueueItem[] | undefined = appointments?.map((appt) => {
    const apptTime = parseDateKeepTZ(appt.appt_time);
    const checkinTime = parseDateKeepTZ(appt.checkin_time);
    return {
      apptTime: formatHourMinutes(apptTime.getHours(), apptTime.getMinutes()),
      checkinTime: formatHourMinutes(
        checkinTime.getHours(),
        checkinTime.getMinutes(),
      ),
      user_full_name: appt.user_full_name,
      resourceAbbr: appt.resource_type_name,
      appt_flags: appt.appt_flags,
      id: appt.id,
      user_birthdate: appt.user_birthdate,
      user_disabilities: appt.user_disabilities,
    };
  });

  const columns = useMemo(() => {
    const columnConfig = [
      {
        Header: t('appt-time'),
        accessor: 'apptTime',
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(12),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: t('check-in-time'),
        accessor: 'checkinTime',
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(12),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: t('patient-name'),
        accessor: 'user_full_name',
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(22),
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: t('cal-template'),
        accessor: 'resourceAbbr',
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(15),
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: t('patient-info'),
        cellStyle: TableHeadStyle,
        accessor: (row) => row,
        width: getNewColumnWidth(20),
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value }: CellProps<QueueItem>) => {
          return <PatientInfoRow {...value} />;
        },
      },
    ] as Column<QueueItem>[];
    if (showBirthDate)
      columnConfig.push({
        Header: t('date-of-birth'),
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(15),
        accessor: (item: QueueItem) =>
          item.user_birthdate
            ? formatDateOnlySite(parseIsoDate(item.user_birthdate))
            : '',
        disableSortBy: true,
        disableFilters: true,
        disableResizing: true,
      } as Column<QueueItem>);
    columnConfig.push({
      Header: '',
      accessor: 'id',
      align: 'right',
      disableResizing: true,
      disableFilters: true,
      disableSortBy: true,
      style: {
        flex: 1,
      },
      Cell: ({ value }: { value: string }) => {
        const calledAppointment = appointments?.find((a) => a.id === value);
        return (
          <RowActions>
            <Button
              variant="primary"
              onClick={() => {
                calledAppointment && callAppointment(calledAppointment.id);
              }}
            >
              {t('call')}
            </Button>
          </RowActions>
        );
      },
    } as Column<QueueItem>);
    return columnConfig;
  }, [appointments, callAppointment, t, showBirthDate]);

  return (
    <>
      <BasicTable columns={columns} data={data} isLoading={isLoading} />
      <TableActions belowForm={true}>
        <Button variant="secondary" type="button" onClick={() => refetch()}>
          {t('refresh')}
        </Button>
      </TableActions>
    </>
  );
};
