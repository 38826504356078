import {
  MergeItemBox,
  MergeItemBoxProps,
} from '../../../../../components/common/Merge/MergeItemBox';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { FormRow } from '../../../../../components/common/Forms/Forms.styled';
import { InfoLabelText } from '../../../../../components/common/Forms/InfoLabelText';
import { CardSubtitle } from '../../../../../components/common';
import { OtherReferrer } from '../../../../../types/otherReferrers';

export const MergeOtherReferrerBox = ({
  isSelecting,
  onSelectClicked,
  otherReferrer,
  label,
}: {
  otherReferrer?: OtherReferrer;
  label: string;
} & Pick<MergeItemBoxProps, 'isSelecting' | 'onSelectClicked'>) => {
  const { t } = useCommonTranslation();
  const emptyString = t('empty-field');
  return (
    <MergeItemBox
      onSelectClicked={onSelectClicked}
      isSelecting={isSelecting}
      selectingLabel={t('selecting-other-referrer')}
      selectLabel={t('select-other-referrer')}
    >
      <CardSubtitle>{label}</CardSubtitle>
      <FormRow>
        <InfoLabelText
          label={t('name')}
          text={otherReferrer?.name || emptyString}
        />
      </FormRow>
      <FormRow>
        <InfoLabelText
          label={t('description')}
          text={otherReferrer?.description || emptyString}
        />
      </FormRow>
    </MergeItemBox>
  );
};
