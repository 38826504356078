import styled from 'styled-components';
import { Row } from '../../../components/common/BasicStyles.styled';

export const FutureAppointments = styled.div`
  margin: var(--s3) 0;
  text-align: center;
  color: var(--primary);
  font-weight: 500;
`;

export const PatientButtons = styled(Row)`
  width: 100%;
  justify-content: center;
  margin-bottom: var(--s3);

  & > button {
    margin: 0 var(--s2);
  }
`;
