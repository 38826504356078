import { CommonButton, CommonLink } from 'components/common/Forms/Button';
import {
  useGoBack,
  useOptionalBookingUserMemberId,
} from 'context/patient/modules/booking';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { patientURL } from 'routes/AppRoutes';
import {
  PatientBookingButtonBarRight,
  PatientBookingButtonBarStyled,
} from './PatientBooking.styled';
import { useSiteBooleanAttributes } from '../../../hooks/useAttribute';
import { SHOW_WAITING_LIST } from '../../../configs/siteAndTrustAttributes';

export const PatientBookingButtonBar = () => {
  const { t } = useCommonTranslation();
  const { canGoBack, goBack } = useGoBack();
  const memberId = useOptionalBookingUserMemberId();
  const [showWaitingList] = useSiteBooleanAttributes(SHOW_WAITING_LIST);

  return (
    <PatientBookingButtonBarStyled>
      {memberId && showWaitingList && (
        <CommonLink
          variant="primary"
          size="large"
          to={`${patientURL}/waiting-list/${memberId}`}
        >
          {t('waiting-list')}
        </CommonLink>
      )}
      <PatientBookingButtonBarRight>
        {canGoBack && (
          <CommonButton variant="primary" size="standard" onClick={goBack}>
            {t('go-back')}
          </CommonButton>
        )}
        <CommonLink
          type="button"
          variant="secondary"
          size="large"
          to={patientURL}
        >
          {t('back-to-menu')}
        </CommonLink>
      </PatientBookingButtonBarRight>
    </PatientBookingButtonBarStyled>
  );
};
