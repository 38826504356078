import styled from 'styled-components';

export const DotLoaderWrap = styled.div`

    display:inline-block;
    clip-path: inset(0 1.8ch 0 0);
    animation: keyframe 1.2s steps(4) infinite;


    @keyframes keyframe {
        to {
            clip-path: inset(0 -1ch 0 0)
        }
    }
`;
