import { CommonButton as Button } from 'components/common/Forms/Button';
import DefaultInputFilter from 'components/Table/DefaultInputFilter';
import { RowActions } from 'components/Table/Table.styled';
import React, { ReactElement, useMemo } from 'react';
import { FaEye, FaTrashAlt } from 'react-icons/fa';
import { Column, FilterProps } from 'react-table';
import { TableHeadStyle } from 'styles/Typography';
import { UserData, UsersFilters } from 'types/users';
import {
  getColumn,
  getNewColumnWidth,
  setColumnFilterAndSortKey,
} from 'utils/table';

import { useSite } from '../../../../../context/SiteContext';
import { PaginatedFiltersWithSet } from '../../../../../hooks/usePaginatedFilters';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { ItemsResponse } from '../../../../../types/ItemsWithCountResponse';
import { FetchedDataTable } from '../../../../Table/FetchedDataTable';
import { getFilterControls } from '../../../../Table/TableUtils';

type Props = {
  usersData?: ItemsResponse<UserData>;
  openFormHandler: (value?: string) => void;
  handleDeleteUser: (value: string) => void;
  isLoading: boolean;
  isFetching: boolean;
  showActiveSiteFilter?: boolean;
} & PaginatedFiltersWithSet<UsersFilters>;

export const StaffTable = ({
  usersData,
  openFormHandler,
  handleDeleteUser,
  filters,
  setFilters,
  isLoading,
  showActiveSiteFilter = true,
  isFetching,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();
  const { allSites } = useSite();

  const columns = useMemo(() => {
    return [
      {
        Header: t('first-name'),
        accessor: 'firstname',
        id: setColumnFilterAndSortKey('firstname'),
        cellStyle: {
          ...TableHeadStyle,
          textTransform: 'capitalize',
        },
        width: getNewColumnWidth(15),
        Filter: (props: FilterProps<object>) => (
          <DefaultInputFilter
            {...props}
            inputProps={{
              placeholder: t('first-name'),
            }}
          />
        ),
      },
      {
        Header: t('surname'),
        accessor: 'lastname',
        cellStyle: {
          ...TableHeadStyle,
          textTransform: 'capitalize',
        },
        width: getNewColumnWidth(15),
        Filter: (props: FilterProps<object>) => (
          <DefaultInputFilter
            {...props}
            inputProps={{
              placeholder: t('surname'),
            }}
          />
        ),
      },
      {
        Header: t('email'),
        accessor: 'email',
        id: setColumnFilterAndSortKey('email', 'UserAccount.Email'),
        width: getNewColumnWidth(15),
        Filter: (props: FilterProps<object>) => (
          <DefaultInputFilter
            {...props}
            inputProps={{
              placeholder: t('email'),
            }}
          />
        ),
      },
      ...(showActiveSiteFilter
        ? [
            getColumn({
              Header: t('active-site'),
              accessor: (user) =>
                allSites?.find((site) => site.id === user.active_site_id)
                  ?.name || '',
              id: setColumnFilterAndSortKey(
                'activeSite',
                'UserAccount.ActiveSite',
              ),
              width: 15,
              Filter: (props: FilterProps<UserData>) => (
                <DefaultInputFilter
                  {...(props as FilterProps<object>)}
                  inputProps={{
                    placeholder: t('active-site'),
                  }}
                />
              ),
            }),
          ]
        : []),
      {
        ...getFilterControls({ filters, setFilters, t }),
        Cell: ({ value }: { value: string }) => {
          return (
            <RowActions>
              <Button
                size="auto"
                iconOnly
                variant="primary"
                title={t('edit-patient')}
                onClick={() => openFormHandler(value)}
              >
                <FaEye />
              </Button>
              <Button
                size="auto"
                iconOnly
                variant="danger"
                title={t('remove-patient')}
                onClick={() => handleDeleteUser(value)}
              >
                <FaTrashAlt />
              </Button>
            </RowActions>
          );
        },
      },
    ] as Column<UserData>[];
  }, [
    t,
    openFormHandler,
    handleDeleteUser,
    allSites,
    filters,
    setFilters,
    showActiveSiteFilter,
  ]);

  return (
    <FetchedDataTable
      columns={columns}
      data={usersData}
      isLoading={isLoading}
      filters={filters}
      onSortOrPageChange={setFilters}
      isFetching={isFetching}
    />
  );
};
