import { TABLE_ID_SEPARATOR } from 'configs/constants';
import { Column } from 'react-table';
import { TFuncReturn } from 'react-i18next';
import { TFunctionResult } from 'i18next';

export const getColumn = <D extends object>({
  Header,
  accessor,
  width,
  ...rest
}: {
  Header: string;
  width?: number | string;
} & Required<Pick<Column<D>, 'accessor'>> &
  Omit<Column<D>, 'accessor'>): Column<D> => {
  return {
    Header: Header,
    accessor: accessor,
    width: getNewColumnWidth(width),
    ...rest,
  } as Column<D>; // Should not be necessary, when time allows see what causes type error without this.
};

export const getNewColumnWidth = (
  headerTextOrDesiredTextLength?:
    | TFuncReturn<'common', string, TFunctionResult>
    | number,
) => {
  const multiplier = 8;
  if (typeof headerTextOrDesiredTextLength === 'string') {
    return headerTextOrDesiredTextLength.length * multiplier;
  } else if (typeof headerTextOrDesiredTextLength === 'number') {
    return headerTextOrDesiredTextLength * multiplier;
  }
  return 10 * multiplier;
};

export const getColumnSortKey: (id: string) => string = (id) => {
  if (!id.includes(TABLE_ID_SEPARATOR)) {
    return id;
  } else {
    return id.split(TABLE_ID_SEPARATOR)[1];
  }
};
export const getColumnFilterKey: (id: string) => string = (id) => {
  if (!id.includes(TABLE_ID_SEPARATOR)) {
    return id;
  } else {
    return id.split(TABLE_ID_SEPARATOR)[0];
  }
};

export const setColumnFilterAndSortKey: (
  filterKey?: string,
  sortKey?: string,
) => string = (filterKey, sortKey) => {
  if ([filterKey, sortKey].every(Boolean)) {
    return filterKey + TABLE_ID_SEPARATOR + sortKey;
  }
  if (filterKey && !sortKey) return filterKey;
  if (!filterKey && sortKey) return sortKey;
  return '';
};
