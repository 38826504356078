import { useCallback } from 'react';
import slugify from 'slugify';

/**
 * Takes an SVG element and downloads it as a PNG file.
 * Download name is slugified and lowercased (to make the file name safe).
 */
export const useDownloadSvg = () => {
  return useCallback(
    ({
      svgElementId,
      downloadName,
    }: {
      svgElementId: string;
      downloadName: string;
    }) => {
      const svg = document.getElementById(svgElementId);
      if (!svg) {
        return;
      }
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return;
      }
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.download = slugify(downloadName, { lower: true });
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    },
    [],
  );
};
