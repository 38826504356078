import React from 'react';
import { FetchedDataTable } from '../../../components/Table/FetchedDataTable';
import { SADApptStatistics } from '../../../types/reports';
import { useAdminStatisticsSiteOverviewColumns } from '../../../hooks/admin/useAdminStatisticsSiteOverviewColumns';
import {
  AdminStatisticsFilters,
  useAdminStatisticsSiteOverviewData,
} from '../../../hooks/admin/useAdminStatisticsSiteOverviewData';
import { usePaginatedFilters } from '../../../hooks/usePaginatedFilters';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { CommonButton } from '../../../components/common/Forms/Button';
import {
  getCSVHeader,
  useGenerateAndDownloadCSV,
} from '../../../hooks/util/useGenerateAndDownloadCSV';
import { getYear } from '../../../utils/dateUtil';
import { usePaginateResults } from '../../../hooks/util/usePaginateResults';

type IAdminAuditApptTableProps = {
  sadStatistics?: SADApptStatistics;
  isLoading: boolean;
  onSiteSummaryClicked: (siteName: string) => any;
  selectedSiteId?: string;
};

export const AdminStatisticsSiteOverviewTableWithExport = ({
  sadStatistics,
  isLoading,
  onSiteSummaryClicked,
  selectedSiteId,
}: IAdminAuditApptTableProps) => {
  const { t } = useCommonTranslation();
  const { filters, setFilters } = usePaginatedFilters<AdminStatisticsFilters>();

  const currentYear = getYear(new Date());

  const statisticsDataAll = useAdminStatisticsSiteOverviewData({
    sadStatistics,
    currentYear,
  });

  const { generateAndDownload, isLoading: isGenerating } =
    useGenerateAndDownloadCSV({
      headers: [
        getCSVHeader('trustName', t('trust-name')),
        getCSVHeader('siteName', t('site-name')),
        getCSVHeader('ytd', t('ytd-with-year', { year: currentYear })),
        getCSVHeader(
          'lastYear',
          t('last-year-with-year', { year: currentYear - 1 }),
        ),
        getCSVHeader('allTime', t('all-time')),
      ],
      rows: statisticsDataAll.data,
      filename: 'site_overview.csv',
    });

  const statisticsData = usePaginateResults({
    data: statisticsDataAll,
    filters,
  });

  const { columns } = useAdminStatisticsSiteOverviewColumns({
    currentYear,
    onSiteSummaryClicked,
  });

  return (
    <>
      <FetchedDataTable
        columns={columns}
        data={statisticsData}
        filters={filters}
        onSortOrPageChange={setFilters}
        isLoading={isLoading}
        isFetching={isLoading}
        selectedIds={selectedSiteId ? [selectedSiteId] : undefined}
      />
      <CommonButton
        onClick={generateAndDownload}
        disabled={isGenerating}
        variant={'primary'}
      >
        {t('admin-statistics-download-full-table')}
      </CommonButton>
    </>
  );
};
