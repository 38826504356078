import { useMemo } from 'react';
import { GlobalAttribute } from 'types/common';

import {
  SITE_WEEK_DAYS,
  STAFF_CLOSE_HOUR_IN_SECONDS,
  STAFF_OPEN_HOUR_IN_SECONDS,
} from '../../../../configs/siteAndTrustAttributes';
import {
  formatHourMinutes,
  SECONDS_IN_HOUR,
  SECONDS_IN_MINUTE,
} from '../../../../utils/dateUtil';
import {
  siteAttributeKeysSubset,
  updateSiteAttributes,
} from '../SitePreferencesHooks';
import { UseGeneralSitePreferencesFormHooks } from './GeneralSitePreferencesHooks';

const parseSecondsOfDay = (allSecondsOfDay?: number) => {
  if (!allSecondsOfDay) {
    return undefined;
  }
  const hours = Math.floor(allSecondsOfDay / SECONDS_IN_HOUR);
  const minutes = Math.floor(
    (allSecondsOfDay - hours * SECONDS_IN_HOUR) / SECONDS_IN_MINUTE,
  );
  //Ignore the rest of the seconds, it should never be set to that.
  return formatHourMinutes(hours, minutes);
};

const formatSecondsOfDay = (time?: string) => {
  if (!time) {
    return 0;
  }
  const hourMins = time.split(':');
  return (
    Number(hourMins[0]) * SECONDS_IN_HOUR +
    Number(hourMins[1]) * SECONDS_IN_MINUTE
  );
};

const keys = siteAttributeKeysSubset([SITE_WEEK_DAYS]);

const restAttributes: GlobalAttribute[] = keys.map((key) => ({ key }));

export const useAvailabilityFormHooks: UseGeneralSitePreferencesFormHooks = ({
  site,
}) => {
  const defaultValues = useMemo(
    () => {
      return {
        openHourTime: parseSecondsOfDay(
          site.attributes?.find((atr) => atr.key === STAFF_OPEN_HOUR_IN_SECONDS)
            ?.value_int,
        ),
        closeHourTime: parseSecondsOfDay(
          site.attributes?.find(
            (atr) => atr.key === STAFF_CLOSE_HOUR_IN_SECONDS,
          )?.value_int,
        ),
      };
    },
    // shallow comparison is fine here as we are using it for default form value only
    [site.attributes],
  );

  return {
    restAttributes,
    defaultValues,
    beforeSubmit: (updatedSite, formValues) => {
      const { openHourTime, closeHourTime } = formValues;
      return {
        ...updatedSite,
        attributes: updateSiteAttributes(
          updatedSite.attributes,
          formValues.attributes,
          [...keys, STAFF_OPEN_HOUR_IN_SECONDS, STAFF_CLOSE_HOUR_IN_SECONDS],
          (key, siteValue, formValue) => {
            switch (key) {
              case STAFF_OPEN_HOUR_IN_SECONDS:
              case STAFF_CLOSE_HOUR_IN_SECONDS:
                const time =
                  key === STAFF_OPEN_HOUR_IN_SECONDS
                    ? openHourTime
                    : closeHourTime;
                const inSeconds = formatSecondsOfDay(time);
                return {
                  key,
                  value_int: inSeconds,
                  value_str: time,
                };
              default:
                return formValue || siteValue;
            }
          },
        ),
      };
    },
  };
};
