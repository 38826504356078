import { KioskLayoutWithResetKioskButton } from '../KioskLayoutWithResetKioskButton';
import { KioskSearchResultConfirmAppointment } from './KioskSearchResultConfirmAppointment';
import { KioskAppointment } from '../../../../types/appointments';

export const KioskApptSelect = ({
  onExitClicked,
  appts,
  onApptSelected,
}: {
  onExitClicked: () => any;
  appts: KioskAppointment[];
  onApptSelected: (appt: KioskAppointment) => any;
}) => {
  return (
    <KioskLayoutWithResetKioskButton onExitClicked={onExitClicked}>
      <KioskSearchResultConfirmAppointment
        appts={appts}
        onSelectedAppointment={onApptSelected}
      />
    </KioskLayoutWithResetKioskButton>
  );
};
