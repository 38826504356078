import { useAuth } from 'context/AuthContext';
import { useGetSiteName } from 'hooks/patient/myAppointments/useGetSiteName';
import { useGetFamilyMember } from 'hooks/patient/useFamilyMember';
import { Fragment } from 'react';
import { Appointment } from 'types/appointments';
import { getApptRangeStr } from 'utils/appointmentUtil';
import { DATE_FNS_UK_DATE_FORMAT, formatDateKeepTZ } from 'utils/dateUtil';
import { getUserFullname } from 'utils/user';
import {
  AppointmentItemDate,
  AppointmentItemDateTime,
  AppointmentItemSite,
  AppointmentItemTime,
} from './MyAppointments.styled';

type AppointmentItemCommonProps = { appt: Appointment };
export const AppointmentItemCommon = ({ appt }: AppointmentItemCommonProps) => {
  const { sangixUser } = useAuth();
  const getFamilyMember = useGetFamilyMember();
  const getSiteName = useGetSiteName();

  return (
    <Fragment>
      <AppointmentItemDateTime>
        <AppointmentItemDate>
          {formatDateKeepTZ(appt.appt_time, DATE_FNS_UK_DATE_FORMAT)}
        </AppointmentItemDate>
        <AppointmentItemTime>{getApptRangeStr(appt)}</AppointmentItemTime>
      </AppointmentItemDateTime>
      <AppointmentItemSite>
        <div>{getSiteName(appt.site_id)}</div>
        {sangixUser && sangixUser.other_members && (
          <div>{getUserFullname(getFamilyMember(appt.user_member_id))}</div>
        )}
      </AppointmentItemSite>
    </Fragment>
  );
};
