import React, { ReactElement, useCallback, useMemo } from 'react';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { Popup } from '../../../components/Popup/Popup';
import { Card } from '../../../components/common';
import { ResourceType } from '../../../types/resource-types';
import { formatDateOnlySite } from '../../../utils/dateUtil';
import { Appointment } from '../../../types/appointments';
import { useAppointmentsColumns } from './DnaAndMiaDialogHooks';
import {
  CommonButton as Button,
  CommonButton,
} from '../../../components/common/Forms/Button';
import { useMutateQueueAppointment } from '../../../query/appointments';
import { successToast } from '../../../utils/toast';
import { CardLoader } from '../../../components/common/Loading';
import { BasicTable } from '../../../components/Table/BasicTable';

interface Props {
  appointments?: Appointment[];
  resourceType?: ResourceType;
  onCloseClicked: () => void;
}

export const DnaAndMiaDialog = ({
  resourceType,
  appointments = [],
  onCloseClicked,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();
  const dateText = formatDateOnlySite(new Date());
  const infoText = resourceType
    ? t('appointment-dna-mia-info-text', {
        resourceType: resourceType.name,
        date: dateText,
      })
    : t('appointment-dna-mia-info-text-no-resource', {
        date: dateText,
      });
  const dnaMia = useMemo(
    () =>
      appointments.filter(
        (app) =>
          (app.status === 'DNA' || app.status === 'MIA') &&
          (!resourceType || resourceType.id === app.resource_type_id),
      ),
    [appointments, resourceType],
  );

  const { mutate: checkInAppointment, isLoading } = useMutateQueueAppointment(
    {
      onSuccess: () => {
        successToast(t('appointment-queued-success'));
      },
    },
    true,
  );

  const rowActions = useCallback(
    (apptId: string) => (
      <Button variant={'primary'} onClick={() => checkInAppointment(apptId)}>
        {t('check-in')}
      </Button>
    ),
    [checkInAppointment, t],
  );

  const appointmentColumns = useAppointmentsColumns({
    rowActions,
  });

  return (
    <Popup closeOnDocumentClick={false} open={true} infoText={infoText}>
      <Card
        style={{
          minHeight: 'calc(var(--s8) * 2)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {isLoading ? (
          <CardLoader />
        ) : (
          <BasicTable columns={appointmentColumns} data={dnaMia} />
        )}
        <div
          style={{
            marginTop: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {
            <CommonButton variant={'danger'} onClick={onCloseClicked}>
              {t('close')}
            </CommonButton>
          }
        </div>
      </Card>
    </Popup>
  );
};
