import { KioskLayoutWithResetKioskButton } from '../KioskLayoutWithResetKioskButton';
import { KioskSearchResultSuccessMessage } from '../Kiosk.styled';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useSiteStringAttributes } from '../../../../hooks/useAttribute';
import { CHECK_IN_SUCCESS_TEXT } from '../../../../configs/siteAndTrustAttributes';

export const KioskCheckinSuccess = ({
  onExitClicked,
}: {
  onExitClicked: () => any;
}) => {
  const { t } = useCommonTranslation();
  const [checkinSuccessText = t('kiosk-checkin-success')] =
    useSiteStringAttributes(CHECK_IN_SUCCESS_TEXT);
  return (
    <KioskLayoutWithResetKioskButton
      onExitClicked={onExitClicked}
      resetLabel={t('exit')}
    >
      <KioskSearchResultSuccessMessage>
        {checkinSuccessText}
      </KioskSearchResultSuccessMessage>
    </KioskLayoutWithResetKioskButton>
  );
};
