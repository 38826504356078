import { PageHelmet } from 'components/common/PageHelmet';
import { PatientLayout } from 'containers/PatientLayout';
import { SignupForm } from '../../components/views-components/un-auth/SignupForm/SignupForm';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';

const SignUp = ({ completeRegister }: { completeRegister: boolean }) => {
  const { t } = useCommonTranslation();
  return (
    <PatientLayout graphics="unauth">
      <PageHelmet title={t('signup')} />
      <SignupForm completeRegister={completeRegister} />
    </PatientLayout>
  );
};

export default SignUp;
