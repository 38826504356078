import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { KioskLayout } from '../KioskLayout';
import {
  KioskEntryChangeButton,
  KioskEntryMessageBoxStyled,
} from '../Kiosk.styled';
import { KioskTextInput } from './KioskTextInput';
import { KeyboardEventHandler } from 'react';

export const KioskEntryPhone = ({
  onPhone,
  onInitialsCheckinClicked,
}: {
  onPhone: (phone: string) => any;
  onInitialsCheckinClicked: () => any;
}) => {
  const { t } = useCommonTranslation();
  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const acceptedSpecialKeys = ['Enter', 'Backspace'];
    if (
      acceptedSpecialKeys.indexOf(event.key) === -1 &&
      !event.key.match(/^[0-9]$/)
    ) {
      event.preventDefault();
      return;
    }
  };
  return (
    <KioskLayout>
      <KioskEntryMessageBoxStyled>
        <KioskTextInput
          onInputSubmit={onPhone}
          maxLength={4}
          minLength={4}
          keyDownHandler={onKeyDown}
          inputHint={t('kiosk-checkin-phone-hint')}
        />
        <KioskEntryChangeButton
          type="button"
          onClick={onInitialsCheckinClicked}
        >
          {t('kiosk-checkin-initials')}
        </KioskEntryChangeButton>
      </KioskEntryMessageBoxStyled>
    </KioskLayout>
  );
};
