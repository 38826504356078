import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  errorToast,
  errorToastPromise,
  successToast,
} from '../../../../utils/toast';
import { Appointment } from '../../../../types/appointments';
import {
  UserData,
  UserFormValues,
  UsersFilters,
  UsersResponseData,
} from '../../../../types/users';
import { getAllUsers } from '../../../../services/users';
import {
  EXISTING_APPOINTMENTS_QUERY_KEY,
  useMutateBookAppointment,
  useMutateLockAppointment,
  useMutateQueueAppointment,
  useMutateUnLockAppointment,
} from '../../../../query/appointments';
import { BookAppointmentForm } from '../../../../components/views-components/staff/appointments/EditAppointmentData';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useQueryClient } from 'react-query';
import { getReferrerIds } from '../../../../components/views-components/staff/appointments/AppointmentReferrersUtil';
import { UNSAFE_FILTER_WITH_NO_PAGINATION } from '../../../../hooks/usePaginatedFilters';

type UseLockUnlockAppointment = {
  selectedAppointmentSlot: Appointment[];
  closeForm: () => void;
};

export const useLockUnlockAppointment = ({
  selectedAppointmentSlot,
  closeForm,
}: UseLockUnlockAppointment) => {
  const { t } = useCommonTranslation();
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<string>();

  const freeAppointmentId = useMemo(() => {
    const appt = selectedAppointmentSlot.find(
      ({ id }) => selectedAppointmentId === id,
    );
    if (appt) {
      /**
       * Appointments have been updated from BE, but selected appointment is still present.
       */
      return selectedAppointmentId;
    }
    return selectedAppointmentSlot.find((appt) => appt.status === 'FREE')?.id;
  }, [selectedAppointmentSlot, selectedAppointmentId]);

  const closeFormRef = useRef(closeForm);
  closeFormRef.current = closeForm;
  const unlockAppointmentRef = useRef(true);
  const doNotUnlockOnUnmount = useCallback(() => {
    unlockAppointmentRef.current = false;
  }, [unlockAppointmentRef]);
  const selectedAppointmentSlotRef = useRef<Appointment[]>([]);
  selectedAppointmentSlotRef.current = selectedAppointmentSlot;

  const { mutate: lockAppointment, isLoading } = useMutateLockAppointment({
    onError: (error) => {
      errorToast(error);
      // We were not able to lock, no point unlocking. This was causing BE errors that should now be fixed.
      doNotUnlockOnUnmount();
      closeFormRef.current();
    },
    onSuccess: (data, notLockedAppt) => {
      setSelectedAppointmentId(notLockedAppt);
    },
  });
  const { mutate: unLockAppointment } = useMutateUnLockAppointment();

  useEffect(() => {
    const freeAppointment = selectedAppointmentSlotRef.current.find(
      ({ id }) => id === freeAppointmentId,
    );
    if (freeAppointment) {
      lockAppointment(freeAppointment.id);
      return () => {
        if (unlockAppointmentRef.current) unLockAppointment(freeAppointment.id);
      };
    } else {
      if (unlockAppointmentRef.current) {
        errorToast({
          message: t('appointment-error-all-appointments-locked'),
        });
      }
      closeFormRef.current();
    }
  }, [
    selectedAppointmentSlotRef,
    freeAppointmentId,
    t,
    unlockAppointmentRef,
    closeFormRef,
    lockAppointment,
    unLockAppointment,
  ]);

  return { selectedAppointmentId, isLoading, doNotUnlockOnUnmount };
};

export type DuplicateUserResult = UsersResponseData | undefined;

const getDuplicateUserQuery = async (
  filters: UsersFilters,
): Promise<DuplicateUserResult> => {
  const duplicateUsers = await errorToastPromise(getAllUsers(filters));
  if (duplicateUsers?.data?.data?.length) {
    return duplicateUsers.data;
  }
};

export const getDuplicateUsers = async (
  user: UserFormValues,
): Promise<DuplicateUserResult> => {
  const duplicates = await getDuplicateUserQuery({
    firstname: user.firstname,
    lastname: user.lastname,
    birthDate: user.birthdate,
    ...UNSAFE_FILTER_WITH_NO_PAGINATION,
  });
  if (duplicates) {
    return duplicates;
  }
};

type UseSaveAndBookAppointment = {
  selectedAppointment: Appointment;
  setIsSaving: (val: boolean) => void;
};

export const useSaveAndBookAppointment = ({
  selectedAppointment,
  setIsSaving,
}: UseSaveAndBookAppointment) => {
  const { t } = useCommonTranslation();
  const { mutateAsync: bookAppointment } = useMutateBookAppointment({
    onSuccess: () => {
      successToast(t('appointment-booked-success'));
    },
  });
  const { mutateAsync: queueAppointment } = useMutateQueueAppointment({
    onSuccess: () => {
      successToast(t('appointment-queued-success'));
    },
  });
  const client = useQueryClient();

  const saveAndBookAppointment = async ({
    user,
    appointment,
    shouldQueueAppointment,
  }: {
    user: UserData;
    appointment: BookAppointmentForm;
    shouldQueueAppointment: boolean;
  }) => {
    setIsSaving(true);
    try {
      await bookAppointment({
        id: selectedAppointment!.id,
        client_id: user.id,
        ...appointment,
        ...getReferrerIds(appointment, user),
      });
      if (shouldQueueAppointment) {
        await queueAppointment(selectedAppointment.id);
      }
    } finally {
      // noinspection ES6MissingAwait
      /**
       * Current appointment did not previously exist in existing appts,
       * so it would not be updated by cache updating and needs to be invalidated.
       */
      client.invalidateQueries(EXISTING_APPOINTMENTS_QUERY_KEY);
      setIsSaving(false);
    }
  };
  return { saveAndBookAppointment };
};

export type BookPatientApptsProps = {
  appointment: BookAppointmentForm;
  keepPatient?: boolean;
  queuePatient?: boolean;
  user: UserData;
};

export const useStaffAppointmentBooking = ({
  closeForm,
  selectedAppointmentSlot,
}: {
  closeForm: (keepPatient: boolean) => void;
  selectedAppointmentSlot: Appointment[];
}) => {
  const closeFormKeepPatient = useCallback(() => {
    closeForm(true);
  }, [closeForm]);

  const {
    selectedAppointmentId,
    isLoading: isLockAppointmentLoading,
    doNotUnlockOnUnmount,
  } = useLockUnlockAppointment({
    closeForm: closeFormKeepPatient,
    selectedAppointmentSlot,
  });

  const [isSaving, setIsSaving] = useState(false);

  const selectedAppointment = useMemo(
    () =>
      selectedAppointmentSlot.find(({ id }) => id === selectedAppointmentId),
    [selectedAppointmentSlot, selectedAppointmentId],
  );

  const { saveAndBookAppointment } = useSaveAndBookAppointment({
    selectedAppointment: selectedAppointment!,
    setIsSaving,
  });

  const bookPatientAppointment = async ({
    appointment,
    keepPatient = false,
    queuePatient = false,
    user,
  }: BookPatientApptsProps) => {
    setIsSaving(true);
    try {
      doNotUnlockOnUnmount();
      await saveAndBookAppointment({
        appointment,
        user: user,
        shouldQueueAppointment: queuePatient,
      });
      closeForm(keepPatient);
    } catch (exception) {
      setIsSaving(false);
    }
  };
  return {
    selectedAppointment,
    isLoading: isSaving || isLockAppointmentLoading,
    bookPatientAppointment,
  };
};
