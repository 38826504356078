import { CommonButton, CommonLink } from 'components/common/Forms/Button';
import { usePatientBooking } from 'context/patient/modules/booking';
import { useState } from 'react';
import { FaInfoCircle, FaQuestionCircle } from 'react-icons/fa';

import { CardHeader } from '../../../components/common';
import { PopupTitle } from '../../../components/Popup/ConfirmDialog/ConfirmDialog.styled';
import { InlineConfirm } from '../../../components/Popup/ConfirmDialog/InlineConfirm';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { patientURL } from '../../../routes/AppRoutes';
import { PatientBookingFor } from './PatientBookingFor';

interface Props {
  question: string;
  correctAnswer: string;
  incorrectAnswer: string;
  incorrectAnswerMessage: string;
}

export const PatientBookingAnswerQuestion = ({
  question,
  correctAnswer,
  incorrectAnswer,
  incorrectAnswerMessage,
}: Props) => {
  const { t } = useCommonTranslation();
  const { updateBooking } = usePatientBooking();
  const [showIncorrectAnswerMessage, setShowIncorrectAnswerMessage] =
    useState(false);

  const handleCorrectAnswer = () => {
    // go to next stage with mark of answered question
    updateBooking({
      stage: 'day',
      questionAnsweredState: 'ANSWERED_CORRECTLY',
    });
  };

  const handleIncorrectAnswer = () => {
    setShowIncorrectAnswerMessage(true);
  };

  return !showIncorrectAnswerMessage ? (
    <InlineConfirm
      icon={<FaQuestionCircle />}
      actions={[
        <CommonButton
          key="correct-answer-btn"
          variant="primary"
          size="large"
          onClick={handleCorrectAnswer}
        >
          {correctAnswer}
        </CommonButton>,
        <CommonButton
          key="incorrect-answer-btn"
          variant="secondary"
          size="large"
          onClick={handleIncorrectAnswer}
        >
          {incorrectAnswer}
        </CommonButton>,
      ]}
      inlineIcon
    >
      <CardHeader>
        <PatientBookingFor />
      </CardHeader>
      <PopupTitle uppercase={false}>{question}</PopupTitle>
    </InlineConfirm>
  ) : (
    <InlineConfirm
      icon={<FaInfoCircle />}
      actions={[
        <CommonLink key="back" variant="secondary" size="large" to={patientURL}>
          {t('back-to-menu')}
        </CommonLink>,
      ]}
      inlineIcon
    >
      <PopupTitle uppercase={false}>{incorrectAnswerMessage}</PopupTitle>
    </InlineConfirm>
  );
};
