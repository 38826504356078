import { Dispatch, SetStateAction, useCallback } from 'react';

import { setOrReplaceAxiosInterceptor } from '../configs/Axios';
import { SITE_EXPIRED } from '../configs/errors';
import { useCommonTranslation } from '../hooks/i18n/useCommonTranslation';
import {
  firebaseErrorMessage,
  isFirebaseError,
  signOut,
} from '../services/firebase';
import { loginUser, logoutUser } from '../services/userProfile';
import { IAuth } from '../types/authentication';
import { ErrorCode } from '../utils/errorCodes';
import { checkErrorCode } from '../utils/errors';
import { errorToast } from '../utils/toast';
import { User } from 'firebase/auth';
import { queryClient } from '../query/QueryClient';
import { TFunction } from 'react-i18next';

export const useAuthHooks = ({
  defaultContext,
  setUserState,
}: {
  defaultContext: IAuth;
  setUserState: Dispatch<SetStateAction<IAuth>>;
}) => {
  const { t } = useCommonTranslation();

  const logout = useCallback(async () => {
    try {
      try {
        await logoutUser();
      } catch (error) {
        console.error(error);
      }
      // We want to proceed with sign out from firebase even if logoutUser fails
      await signOut();
      setUserState({
        ...defaultContext,
      });
      // Clear all cache on logout
      queryClient.removeQueries();
    } catch (error: any) {
      errorToast(error);
      console.error(error);
      setUserState({
        ...defaultContext,
        errorMessage: error,
      });
    }
  }, [setUserState, defaultContext]);

  const handleFirebaseUserLogin = useCallback(
    async (user: User) => {
      setOrReplaceAxiosInterceptor(user);
      try {
        const { data: sangixUser } = await loginUser();
        // Clear cache on login just in case previous user did not clear it on logout
        queryClient.removeQueries();
        setUserState((userState) => ({
          ...userState,
          firebaseUser: user,
          errorMessage: null,
          need_complete_signup: false,
          sangixUser,
        }));
      } catch (error: any) {
        /**
         * If we have managed to log in via firebase, but BE gives us error code UM0002
         * it is an indication that the user is not yet registered on the BE.
         */
        if (checkErrorCode(error, ErrorCode.USER_UNAUTHORIZED_UM0002)) {
          setUserState((userState) => ({
            ...userState,
            firebaseUser: user,
            errorMessage: null,
            need_complete_signup: true,
            sangixUser: undefined,
          }));
        } else if (error.response?.data === SITE_EXPIRED) {
          errorToast({
            message: t('site-expired-word'),
          });
          await logout();
        } else {
          errorToast(error);
          await logout();
        }
      }
    },
    [setUserState, logout, t],
  );
  return { handleFirebaseUserLogin, logout };
};

export const errorToastForFirebase = (error: any, t: TFunction) => {
  if (isFirebaseError(error)) {
    errorToast(firebaseErrorMessage(error, t));
  } else {
    errorToast(error);
  }
};
