import enGb from 'date-fns/locale/en-GB/index';
import React, {
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';
import { CommonButton } from './Button';
import {
  DatePickerHeaderButtons,
  DatePickerHeaderDate,
  DatePickerHeaderWrapper,
  DatePickerStylesWrapper,
} from './Forms.styled';
import Input, { GlobalInputProps } from './Input';
import {
  DATE_FNS_DATE_ONLY_ISO_FORMAT,
  DATE_FNS_SHORT_MONTH_YEAR_FORMAT,
  DATE_FNS_UK_DATE_FORMAT,
  DATE_FNS_UK_DATE_SLASH_FORMAT,
  DateOnlyISOString,
  formatDate,
  isValidDateString,
  parseDate,
} from '../../../utils/dateUtil';
import { mq } from 'styles/sizes';
import { useThemeMedia } from 'hooks/useThemeMedia';
import { RequiredLabel } from './RequiredLabel';

registerLocale('en-gb', enGb);

export type DatePickerOnChange = (val: DateOnlyISOString | undefined) => void;

export type GlobalDatePickerProps = Omit<GlobalInputProps, 'onChange'> & {
  selected?: DateOnlyISOString;
  onChange: DatePickerOnChange;
  maxDate?: Date;
  minDate?: Date;
  sizing?: 'MIN_CONTENT' | 'UNDEFINED';
  label?: React.ReactNode;
  required?: boolean;
  iconRight?: boolean;
  width?: string;
  flexWidth?: boolean;
  hideDateFormatInLabel?: boolean;
  containerStyle?: React.CSSProperties;
  popperZIndex?: number;
  filterDate?: (date: Date) => boolean | undefined;
};

export default forwardRef<HTMLInputElement, GlobalDatePickerProps>(
  (
    {
      id,
      name,
      onChange,
      selected,
      placeholder,
      disabled,
      maxDate,
      minDate,
      sizing,
      label,
      hideDateFormatInLabel,
      containerStyle,
      required,
      popperZIndex,
      filterDate,
      ...rest
    },
    ref,
  ): ReactElement => {
    const [selectedDate, setSelectedDate] = useState<null | Date>(null);
    const mobileStyles = !useThemeMedia(mq.xxsm);
    const dateFormat = mobileStyles
      ? DATE_FNS_DATE_ONLY_ISO_FORMAT
      : DATE_FNS_UK_DATE_FORMAT;

    const handleChangeRaw = useCallback(
      (value: string) => {
        if (!value) {
          onChange(undefined);
        } else {
          const validTypedParseDateFormats = [
            dateFormat,
            DATE_FNS_UK_DATE_SLASH_FORMAT,
          ];
          validTypedParseDateFormats.forEach((dateFormat) => {
            if (isValidDateString(value, dateFormat)) {
              onChange(formatDate(parseDate(value, dateFormat)!)!);
            }
          });
        }
      },
      [onChange, dateFormat],
    );

    useEffect(() => {
      setSelectedDate(
        isValidDateString(selected) ? parseDate(selected!)! : null,
      );
    }, [selected]);

    /**
     * When in mobile style date picker is rendered via "date" type,
     * which means it is up to the platform such as android to choose
     * format. So in that case we don't want to show the user the format.
     */
    const dateFormatForUser = mobileStyles ? undefined : 'dd.mm.yyyy';
    let dateLabel = label;
    if (!hideDateFormatInLabel && dateFormatForUser) {
      if (dateLabel) {
        dateLabel = `${dateLabel} (${dateFormatForUser})`;
      } else {
        dateLabel = `(${dateFormatForUser})`;
      }
    }

    return (
      <DatePickerStylesWrapper
        sizing={sizing}
        style={containerStyle}
        popperZIndex={popperZIndex}
      >
        {mobileStyles ? (
          <Input
            {...rest}
            onChange={(event) => {
              const dateValue = event.target.value;
              onChange(dateValue);
            }}
            value={selected}
            label={<RequiredLabel label={dateLabel} required={required} />}
            name={name}
            ref={ref}
            containerStyle={{
              margin: 0,
            }}
            type={'date'}
          />
        ) : (
          <DatePicker
            open={mobileStyles ? false : undefined}
            strictParsing
            disabled={disabled}
            dateFormat={dateFormat}
            showPopperArrow={false}
            placeholderText={placeholder}
            autoComplete="off"
            locale="en-gb"
            maxDate={maxDate}
            minDate={minDate}
            filterDate={filterDate ? (date) => !!filterDate(date) : undefined}
            selected={selectedDate}
            onChangeRaw={(e) => {
              /**
               * This event also gets triggered via click on the open calendar div
               * with empty value and "onChange" is triggered too. We don't want both
               * onChange and rawChange at the same time.
               */
              if (e.target.tagName.toLowerCase() === 'input') {
                handleChangeRaw(e.target.value);
              }
            }}
            onChange={(date: Date) => {
              onChange(date && formatDate(date)!);
            }}
            customInputRef={ref as any}
            customInput={
              <Input
                {...rest}
                label={<RequiredLabel label={dateLabel} required={required} />}
                name={name}
                ref={ref}
                containerStyle={{
                  margin: 0,
                }}
                type={'text'}
                hideNativeDate
              />
            }
            name={name}
            id={id || name}
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
              prevYearButtonDisabled,
              nextYearButtonDisabled,
              increaseYear,
              decreaseYear,
            }) => (
              <DatePickerHeaderWrapper>
                <DatePickerHeaderButtons>
                  <CommonButton
                    size="auto"
                    variant="primary"
                    iconOnly
                    type="button"
                    onClick={decreaseYear}
                    disabled={prevYearButtonDisabled}
                  >
                    <FaAngleDoubleLeft />
                  </CommonButton>
                  <CommonButton
                    size="auto"
                    variant="primary"
                    type="button"
                    iconOnly
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <FaChevronLeft />
                  </CommonButton>
                </DatePickerHeaderButtons>
                <DatePickerHeaderDate>
                  {formatDate(date, DATE_FNS_SHORT_MONTH_YEAR_FORMAT)}
                </DatePickerHeaderDate>
                <DatePickerHeaderButtons>
                  <CommonButton
                    size="auto"
                    variant="primary"
                    iconOnly
                    type="button"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <FaChevronRight />
                  </CommonButton>
                  <CommonButton
                    size="auto"
                    variant="primary"
                    type="button"
                    iconOnly
                    onClick={increaseYear}
                    disabled={nextYearButtonDisabled}
                  >
                    <FaAngleDoubleRight />
                  </CommonButton>
                </DatePickerHeaderButtons>
              </DatePickerHeaderWrapper>
            )}
          />
        )}
      </DatePickerStylesWrapper>
    );
  },
);
