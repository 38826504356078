import styled, { keyframes } from "styled-components";
import { useClientRect } from "hooks/getBoundingClientRect";

const runningText = keyframes`
  from {
    margin-left: 100%;
    transform: translateX(0);
      
  }
  to {
    margin-left: 0;
    transform: translateX(-100%);
  }
`;

const RunningTextBox = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-grow: 1;
`;

type RunningTextStyledProps = {
  renderWidth?: number
}
const RunningTextStyled = styled.div<RunningTextStyledProps>`
  color: var(--textsecondary);
  font-size: var(--h1);
  white-space: nowrap;
  position: relative;
  animation: ${runningText} 50s linear infinite;
  animation-duration: ${({renderWidth = 1000}) => renderWidth * 0.02}s;
`;

export type RunningTextProps = {
  text: string
}
export const RunningText = ({text}: RunningTextProps) => {
  const { rect: boxRect, ref: boxRef } = useClientRect();
  const { rect: textRect, ref: textRef } = useClientRect();

  return (
    <RunningTextBox ref={boxRef}>
      <RunningTextStyled
        ref={textRef}
        renderWidth={Math.max(boxRect.width, textRect.width)}
      >
        {text}
      </RunningTextStyled>
    </RunningTextBox>
  );
};