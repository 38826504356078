import { useEffect, useState } from 'react';
import { useDebounce, useLocalStorage } from 'react-use';

import { MILLIS_IN_DAY } from '../../utils/dateUtil';

export interface ColumnWidths {
  [key: string]: number;
}

export interface TableResizing {
  resizingId: string;
  updatedAt: number;
  columnsWidths: ColumnWidths;
}

const TABLE_RESIZING_STORAGE_KEY = 'tableResizing';
export const useStoredColumnResizing = (resizingId: string) => {
  const [resizingStorage, setResizingStorage] = useLocalStorage<
    TableResizing[]
  >(TABLE_RESIZING_STORAGE_KEY, []);

  const [cachedColumnsWidths, setCachedColumWidths] = useState<ColumnWidths>(
    {},
  );

  useEffect(() => {
    setCachedColumWidths(
      (resizingStorage || []).find((item) => item.resizingId === resizingId)
        ?.columnsWidths || {},
    );
    // Omitting `resizingStorage` dependency as it would
    // start infinite loop with 500ms intervals when storage
    // is persisted on debouncing.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resizingId]);

  // Update local storage resizing data after 500ms as it requires bigger json serializing.
  useDebounce(
    () => {
      if (cachedColumnsWidths && Object.keys(cachedColumnsWidths).length > 0) {
        const now = new Date().getTime();
        const resizing: TableResizing = {
          resizingId,
          updatedAt: now,
          columnsWidths: cachedColumnsWidths,
        };
        const maxResizingLifetime = MILLIS_IN_DAY * 30;
        setResizingStorage([
          ...(resizingStorage || []).filter(
            ({ resizingId: itemResizingId, updatedAt }) =>
              itemResizingId !== resizingId &&
              // clear other tables resizing older than 30 days
              now - updatedAt < maxResizingLifetime,
          ),
          resizing,
        ]);
      }
    },
    500,
    [cachedColumnsWidths],
  );
  return {
    resizedColumnsWidths: cachedColumnsWidths,
    setResizedColumWidths: setCachedColumWidths,
  };
};
