import {
  PatientBookingStage,
  usePatientBooking,
} from 'context/patient/modules/booking';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { useMemo } from 'react';

import {
  PatientBookingStageItem,
  PatientBookingStageName,
  PatientBookingStageNumber,
  PatientBookingStageStyled,
} from './PatientBooking.styled';

export const PatientBookingStageBar = () => {
  const { t } = useCommonTranslation();
  const {
    booking: { stage },
  } = usePatientBooking();

  const stages = useMemo(() => {
    const stages: { key: PatientBookingStage; name: string }[] = [
      { key: 'day', name: 'day' },
      { key: 'hour', name: 'hour' },
      { key: 'appt', name: 'appointment' },
    ];
    return stages;
  }, []);
  return (
    <PatientBookingStageStyled>
      {stages.map((stageI, stageIndex) => (
        <PatientBookingStageItem active={stage === stageI.key} key={stageI.key}>
          <PatientBookingStageNumber>
            {stageIndex + 1}
          </PatientBookingStageNumber>
          <PatientBookingStageName>{t(stageI.name)}</PatientBookingStageName>
        </PatientBookingStageItem>
      ))}
    </PatientBookingStageStyled>
  );
};
