import { Loading } from 'components/common/Loading';
import { PageHelmet } from 'components/common/PageHelmet';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { usePublicSite } from 'hooks/public/usePublicSite';
import { KioskPreCheckinQuestion } from './components/KioskPreCheckinQuestion';
import { KioskEntryInitials } from './components/KioskEntryInitials';
import { KioskLoading } from './components/KioskLoading';
import {
  INITIALS_INPUT,
  KIOSK_APPT_SELECT,
  KIOSK_CHECKIN_SUCCESS,
  KIOSK_EARLY_ERROR,
  KIOSK_GENERAL_ERROR,
  KIOSK_LATE_ERROR,
  KIOSK_LOADING_STATES,
  KIOSK_MULTIPLE_RESULTS_ERROR,
  KIOSK_NO_APPTS_FOUND_ERROR,
  KIOSK_UPDATE_USER,
  PHONE_INPUT,
  PRE_CHECKIN_QUESTION,
} from '../../../hooks/kiosk/useKioskState';
import { KioskError } from './components/KioskError';
import { useKiosk } from '../../../hooks/kiosk/useKiosk';
import { KioskApptSelect } from './components/KioskApptSelect';
import { KioskCheckinSuccess } from './components/KioskCheckinSuccess';
import { KioskEntryPhone } from './components/KioskEntryPhone';
import { KioskUpdateUser } from './components/KioskUpdateUser';

export const Kiosk = () => {
  const { isReady } = usePublicSite();
  /**
   * Until isReady = true useSite hook returns undefined. This can cause
   * issues, as in non-public site environment it is available from context
   * straight away and is typed as defined, that's why there is
   * the loading element first.
   */
  return <>{isReady ? <KioskSiteLoaded /> : <Loading />}</>;
};

const KioskSiteLoaded = () => {
  const { t } = useCommonTranslation();
  const {
    kioskState,
    kioskApptSearchInput,
    appt,

    setInitialsInputState,
    setPhoneInputState,
    setKioskApptSearchInput,
    onApptSelected,
    reset,
    updateUserAndCheckin,
  } = useKiosk();

  return (
    <>
      <PageHelmet title={t('kiosk-tab-title')} />

      {kioskState === PRE_CHECKIN_QUESTION && (
        <KioskPreCheckinQuestion onPreCheckinFinished={setInitialsInputState} />
      )}

      {kioskState === INITIALS_INPUT && (
        <KioskEntryInitials
          onInitials={(initials) =>
            setKioskApptSearchInput({
              searchString: initials,
              type: 'INITIALS',
            })
          }
          onPhoneCheckinClicked={setPhoneInputState}
        />
      )}

      {kioskState === PHONE_INPUT && (
        <KioskEntryPhone
          onPhone={(phone) =>
            setKioskApptSearchInput({ searchString: phone, type: 'PHONE' })
          }
          onInitialsCheckinClicked={setInitialsInputState}
        />
      )}

      {kioskState === KIOSK_APPT_SELECT && (
        <KioskApptSelect
          onExitClicked={reset}
          appts={appt ? [appt] : []}
          onApptSelected={onApptSelected}
        />
      )}

      {kioskState === KIOSK_UPDATE_USER && (
        <KioskUpdateUser
          onUpdateUser={updateUserAndCheckin}
          onExitClicked={reset}
        />
      )}

      {kioskState === KIOSK_CHECKIN_SUCCESS && (
        <KioskCheckinSuccess onExitClicked={reset} />
      )}

      {KIOSK_LOADING_STATES.includes(kioskState) && <KioskLoading />}

      {(kioskState === KIOSK_EARLY_ERROR ||
        kioskState === KIOSK_LATE_ERROR ||
        kioskState === KIOSK_NO_APPTS_FOUND_ERROR ||
        kioskState === KIOSK_GENERAL_ERROR ||
        kioskState === KIOSK_MULTIPLE_RESULTS_ERROR) && (
        <KioskError
          onExitClicked={reset}
          kioskState={kioskState}
          searchParams={kioskApptSearchInput}
        />
      )}
    </>
  );
};
