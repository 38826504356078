import { CommonButton } from 'components/common/Forms/Button';
import {
  DialogFormWrapper,
  FormActionsStyle,
  FormBody,
  FormRow,
} from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import { CardLoader } from 'components/common/Loading';
import { useAuth } from 'context/AuthContext';
import React, { ReactElement, ReactNode, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  firebaseErrorMessage,
  getFirebaseEmailCredentials,
  updateCurrentUserPassword,
} from 'services/firebase';
import { IFirebaseError } from 'types/common';
import { toRem } from 'utils/helpers';
import { errorToast } from 'utils/toast';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { LABEL_BEFORE } from '../../../../styles/fontsStyleUtils';
import { INewPasswordForm, NewPasswordInput } from './NewPasswordInput';

type Props = {
  setIsFormSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  backButton?: ReactNode;
};

type IChangePasswordForm = {
  current_password: string;
} & INewPasswordForm;

export default function ChangePasswordForm({
  setIsFormSuccess,
  backButton,
}: Props): ReactElement {
  const { t } = useCommonTranslation();
  const { sangixUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setError,
    trigger,
    formState: { errors },
  } = useForm<IChangePasswordForm>();

  const submitProfileData: SubmitHandler<IChangePasswordForm> = async ({
    current_password,
    newPasswordForm: { password },
  }) => {
    const isPasswordsValid = await trigger([
      'newPasswordForm.password',
      'newPasswordForm.confirm_password',
    ]);
    if (isPasswordsValid) {
      setIsLoading(true);
      if (!sangixUser) {
        errorToast('User not found');
      } else {
        getFirebaseEmailCredentials(sangixUser.email, current_password)
          .then(() => {
            updateCurrentUserPassword(password)
              .then(() => setIsFormSuccess(true))
              .catch((err: IFirebaseError) => {
                setIsLoading(false);
                errorToast(err);
              });
          })
          .catch((err: IFirebaseError) => {
            setIsLoading(false);
            setError('current_password', {
              type: 'manual',
              message: firebaseErrorMessage(
                err,
                t,
                'firebase-change-pass-errors.',
              ),
            });
          });
      }
    }
  };

  return (
    <DialogFormWrapper style={{ paddingTop: 'var(--s2)' }}>
      <FormBody onSubmit={handleSubmit(submitProfileData)}>
        <FormRow>
          <Input
            label={t('current-password')}
            placeholder={t('current-password')}
            labelPosition={LABEL_BEFORE}
            type="password"
            disabled={isLoading}
            hideTogglePassword={true}
            fixedLabelWidth={`${toRem(140)}rem`}
            helperText={errors.current_password?.message}
            hasError={Boolean(errors.current_password)}
            {...register('current_password', {
              required: t('must-not-empty') as string,
            })}
          />
        </FormRow>
        <NewPasswordInput register={register} errors={errors} watch={watch} />

        <FormActionsStyle>
          <CommonButton
            variant="primary"
            type="submit"
            disabled={Boolean(Object.keys(errors).length)}
            style={{ minWidth: `${toRem(160)}rem` }}
          >
            {t('save-changes')}
          </CommonButton>
          {backButton}
        </FormActionsStyle>
      </FormBody>
      {isLoading && <CardLoader fillWrapper={true} />}
    </DialogFormWrapper>
  );
}
