import { Control, Controller, FieldErrors } from 'react-hook-form';
import { UseFormRegister } from 'react-hook-form/dist/types/form';

import { BitwiseCheckboxArray } from '../../../components/common/Forms/BitwiseCheckboxArray';
import { FormRow } from '../../../components/common/Forms/Forms.styled';
import {
  AutoCompleteLabel,
  GreenBoxWrapper,
} from '../../../components/views-components/un-auth/SignupForm/SignupForm.styled';
import { disabilityOptions } from '../../../configs/constants';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { TrustData } from '../../../types/trusts';
import { getHookFormTyped } from '../../../utils/helpers';
import { PatientNhsPostcodeData } from './PatientNhsPostcodeData';

export type NhsPostcodeDisabilitiesForm = {
  postcode?: string;
  disabilities?: number;
  nhs_number?: string;
} & { [key: string]: any };

interface IPatientNhsAndPostcodeDisabilitiesDataProps<
  T extends NhsPostcodeDisabilitiesForm,
> {
  register: UseFormRegister<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  trusts?: TrustData[];
}

export const PatientNhsPostcodeDisabilitiesData = <
  T extends NhsPostcodeDisabilitiesForm,
>(
  props: IPatientNhsAndPostcodeDisabilitiesDataProps<T>,
) => {
  const { trusts, ...rest } = props;
  const { control } = getHookFormTyped<NhsPostcodeDisabilitiesForm, T>(rest);

  const { t } = useCommonTranslation();

  return (
    <>
      <PatientNhsPostcodeData {...props} />
      <FormRow>
        <GreenBoxWrapper>
          <AutoCompleteLabel as="span">{t('disabilities')}:</AutoCompleteLabel>
          <Controller
            name={'disabilities'}
            control={control}
            render={({ field }) => (
              <BitwiseCheckboxArray
                field={field}
                options={disabilityOptions(t)}
              />
            )}
          />
        </GreenBoxWrapper>
      </FormRow>
    </>
  );
};
