import {
  CacheAndStaleTimeForUnlikelyChanged,
  useMutationWithToastError,
  useQueryWithToastError,
} from './queryUtils';
import { useSite } from '../context/SiteContext';
import {
  addNewResourceType,
  deleteResourceType,
  getAllResourceTypes,
  updateResourceType,
} from '../services/resource_types';
import {
  ResourceTypeFilters,
  ResourceTypeFormValues,
  ResourceTypeResponseData,
} from '../types/resource-types';
import { AxiosResponse } from 'axios';
import { UseQueryOptions } from 'react-query/types/react/types';
import { UseMutationOptions, useQueryClient } from 'react-query';

const RESOURCES_KEYS = 'all-resource-types';

export const useSiteResourceTypes = (
  filters: ResourceTypeFilters,
  options: UseQueryOptions<
    AxiosResponse<ResourceTypeResponseData>,
    any,
    ResourceTypeResponseData
  > = {},
) => {
  const { activeSite } = useSite();
  const combinedFilters = {
    siteID: activeSite.id,
    ...filters,
  };
  return useQueryWithToastError(
    [RESOURCES_KEYS, combinedFilters],
    () => getAllResourceTypes(combinedFilters),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
      select: (axiosResponse) => axiosResponse.data,
      ...options,
    },
  );
};

export const useMutateUpdateResourceType = (
  options?: UseMutationOptions<any, any, ResourceTypeFormValues>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<ResourceTypeFormValues>(
    [RESOURCES_KEYS],
    (update) => updateResourceType(update),
    {
      onSettled: () => {
        client.invalidateQueries([RESOURCES_KEYS]);
      },
      ...options,
    },
  );
};

export const useMutateCreateResourceType = (
  options?: UseMutationOptions<any, any, ResourceTypeFormValues>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<ResourceTypeFormValues>(
    [RESOURCES_KEYS],
    (params) => addNewResourceType(params),
    {
      onSettled: () => client.invalidateQueries([RESOURCES_KEYS]),
      ...options,
    },
  );
};

export const useMutateDeleteResourceType = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<string>(
    [RESOURCES_KEYS],
    (params) => deleteResourceType(params),
    {
      onSettled: () => client.invalidateQueries([RESOURCES_KEYS]),
      ...options,
    },
  );
};
