import { merge } from 'lodash';
import { SetState } from 'types/SetState';
import { PartialDeep } from 'type-fest';
import { useCallback } from 'react';

export const updateStateApply = <State>(
  state: State,
  update: PartialDeep<State>,
) => merge({}, state, update);

export type UpdateState<State> = (update: PartialDeep<State>) => void;

export const useMakeUpdateState = <State>(setState: SetState<State>) => {
  const updateState: UpdateState<State> = useCallback(
    (update: PartialDeep<State>) => {
      return setState((state: State) => updateStateApply(state, update));
    },
    [setState],
  );
  return updateState;
};
