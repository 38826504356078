import { TableHeadStyle } from '../../styles/Typography';
import { getNewColumnWidth } from '../../utils/table';
import DefaultInputFilter from '../../components/Table/DefaultInputFilter';
import { useCommonTranslation } from '../i18n/useCommonTranslation';
import { useMemo } from 'react';
import { Column, FilterProps } from 'react-table';
import { OtherReferrer } from '../../types/otherReferrers';

export const useOtherReferrersColumns = ({
  lastColumn,
}: {
  lastColumn?: Column<OtherReferrer>;
}) => {
  const { t } = useCommonTranslation();

  return useMemo(() => {
    return [
      {
        Header: t('referrers'),
        accessor: 'name',
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(20),
        disableResizing: true,
        style: {
          flex: 1,
        },
        Filter: (props: FilterProps<object>) => (
          <DefaultInputFilter
            {...props}
            inputProps={{
              placeholder: t('referrers'),
            }}
          />
        ),
      },
      lastColumn,
    ] as Column<OtherReferrer>[];
  }, [t, lastColumn]);
};
