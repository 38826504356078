import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import {
  InlineCalendar,
  InlineCalendarProps,
} from 'components/InlineCalendar/InlineCalendar';
import { CardTitle } from 'components/common';
import { TimePlanningTooltipWrapper } from 'components/common/Tooltips/TimePlanningTooltipWrapper';
import {
  TimePlanningItemColor,
  TimePlanningList,
  TimePlanningListItem,
  TimePlanningTitle,
} from 'components/common/Tooltips/Tooltips.styled';
import { StaffSettingsTimePlanningTree } from 'configs/RoutesConfig';
import { Fragment, ReactElement } from 'react';
import { toRem } from 'utils/helpers';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';

type Props = {
  selectedDate: Date;
  updateDates: (dates: {
    selectedDate: Date;
    dayPickerVisibleMonthDate?: Date;
  }) => void;
  datesWithChangesFromDefault: Date[];
  minDate: Date;
} & Pick<InlineCalendarProps, 'onVisibleMonthChange' | 'filterDate'>;

export const TimePlanningDayPicker = ({
  updateDates,
  selectedDate,
  filterDate,
  datesWithChangesFromDefault,
  minDate,
  onVisibleMonthChange,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();

  return (
    <Fragment>
      <LinkCardTabs tabs={StaffSettingsTimePlanningTree(t)} />
      <TimePlanningTooltipWrapper
        position={{ top: 'var(--s3)', right: 'var(--s3)' }}
        minWidth={222}
      >
        <TimePlanningTitle>{t('calendar-legend')}</TimePlanningTitle>
        <TimePlanningList>
          <TimePlanningListItem>
            <TimePlanningItemColor
              style={{
                background: 'var(--disabled)',
              }}
            />
            {t('planning-day-legend-1')}
          </TimePlanningListItem>
          <TimePlanningListItem>
            <TimePlanningItemColor
              style={{
                border: `${toRem(2)}rem solid var(--greenprimary)`,
                background: 'var(--white)',
              }}
            />
            {t('planning-day-legend-2')}
          </TimePlanningListItem>
          <TimePlanningListItem>
            <TimePlanningItemColor
              style={{
                background: 'var(--redaccent)',
              }}
            />
            {t('planning-day-legend-3')}
          </TimePlanningListItem>
          <TimePlanningListItem>
            <TimePlanningItemColor
              style={{
                background: 'var(--greenprimary)',
              }}
            />
            {t('planning-day-legend-4')}
          </TimePlanningListItem>
        </TimePlanningList>
      </TimePlanningTooltipWrapper>
      <CardTitle
        style={{
          marginTop: 'var(--s3)',
        }}
      >
        {t('pick-a-date')}
      </CardTitle>
      <InlineCalendar
        filterDate={filterDate}
        selectedDate={selectedDate}
        updateDates={updateDates}
        highlightDates={datesWithChangesFromDefault}
        minDate={minDate}
        onVisibleMonthChange={onVisibleMonthChange}
      />
    </Fragment>
  );
};
