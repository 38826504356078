import React from 'react';
import { CardDividerHeaderSection } from '../../../components/common';
import DatePickerInput from '../../../components/common/Forms/DatePickerInput';
import { Controller, useForm } from 'react-hook-form';
import {
  addDays,
  DateOnlyISOString,
  formatDateOnlyISO,
  parseIsoDate,
} from '../../../utils/dateUtil';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { FaCalendarAlt } from 'react-icons/fa';
import { AuditApptHeaderForm } from '../AuditPage.styled';
import { CommonButton } from '../../../components/common/Forms/Button';
import CommonSelect from '../../../components/common/Forms/Select';
import { useSite } from '../../../context/SiteContext';
import { SiteData } from '../../../types/sites';
import { errorToast } from '../../../utils/toast';

export type AuditDateRange = {
  from: DateOnlyISOString;
  to: DateOnlyISOString;
};

interface IAdminAuditApptHeaderProps {
  onFindClicked: (range: AuditDateRange) => void;
}

export const AdminAuditApptHeader: React.FunctionComponent<
  IAdminAuditApptHeaderProps
> = ({ onFindClicked }) => {
  const { t } = useCommonTranslation();
  const { allSites, activeSite, setActiveSite } = useSite();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    date: DateOnlyISOString;
  }>();

  return (
    <CardDividerHeaderSection
      style={{
        columnGap: 'var(--s3)',
      }}
    >
      <AuditApptHeaderForm
        onSubmit={handleSubmit(({ date }) => {
          onFindClicked({
            from: date,
            to: formatDateOnlyISO(addDays(parseIsoDate(date), 1)),
          });
        })}
      >
        <CommonSelect
          label={t('active-site')}
          placeholder={t('active-site')}
          disabled={!allSites}
          options={allSites?.map((site) => ({
            key: site.id,
            value: site,
            label: site.name,
          }))}
          value={activeSite}
          onChange={async (val) => {
            try {
              await setActiveSite(val as SiteData);
            } catch (err) {
              errorToast(err);
            }
          }}
        />
        <Controller
          control={control}
          name="date"
          rules={{
            required: t('must-not-empty') as string,
          }}
          render={({ field }) => {
            return (
              <DatePickerInput
                {...field}
                sizing={'MIN_CONTENT'}
                noSpaceForHelperText
                onChange={(value) => {
                  field.onChange(value);
                }}
                label={t('date')}
                hasError={Boolean(errors.date)}
                helperText={errors.date?.message}
                selected={field.value}
                icon={<FaCalendarAlt />}
              />
            );
          }}
        />
        <CommonButton variant={'primary'} type={'submit'} size={'large'}>
          {t('find')}
        </CommonButton>
      </AuditApptHeaderForm>
    </CardDividerHeaderSection>
  );
};
