import CheckboxInput from 'components/common/Forms/CheckboxInput';
import {
  DialogFormRow,
  DialogFormWrapper,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import TextArea from 'components/common/Forms/TextArea';
import { CardLoader } from 'components/common/Loading';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FaQuestion } from 'react-icons/fa';

import { Card } from '../../../../components/common';
import {
  PRE_BOOK_CORRECT_ANSWER,
  PRE_BOOK_INCORRECT_ANSWER,
  PRE_BOOK_INCORRECT_ANSWER_MESSAGE,
  PRE_BOOK_QUESTION,
} from '../../../../configs/siteAndTrustAttributes';
import { UseCustomSettingsFormReturn } from './CustomSettingsHooks';
import { ATTRIBUTE_SEPARATOR } from '../../../../configs/constants';
import CommonInput from '../../../../components/common/Forms/Input';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';

interface Props {
  isFetching: boolean;
  attributesForm: UseCustomSettingsFormReturn;
}

const textAreaMaxChars = 250;
const inputMaxChars = 30;

export const PreBookQuestion: React.FC<Props> = ({
  isFetching,
  attributesForm,
}) => {
  const { t } = useCommonTranslation();

  const {
    formState: { errors },
    attributeRegister,
    control,
    register,
  } = attributesForm;
  const { enablePreBookQuestion, attributes } = useWatch({ control });

  return (
    <Card mb="var(--s4)">
      <DialogFormWrapper>
        <FormHeader>
          <FaQuestion />
          <FormTitle>{t('pre-book-question')}</FormTitle>
        </FormHeader>
        <FormRow>
          <CheckboxInput
            onlyInRow
            label={t('enable-pre-book-question')}
            key={'enablePreBookQuestion'}
            id={'enablePreBookQuestion'}
            isToggle={true}
            {...register('enablePreBookQuestion')}
            checked={enablePreBookQuestion}
          />
        </FormRow>
        <DialogFormRow>
          <TextArea
            label={t('question')}
            placeholder={t('question')}
            {...attributeRegister(PRE_BOOK_QUESTION, (e) => e.target.value, {
              required: {
                value: !!enablePreBookQuestion,
                message: t('must-not-empty') as string,
              },
              maxLength: textAreaMaxChars,
            })}
            value={
              enablePreBookQuestion
                ? attributes?.find((atr) => atr.key === PRE_BOOK_QUESTION)
                    ?.value_str
                : ''
            }
            disabled={!enablePreBookQuestion}
            hasError={Boolean(
              errors[(ATTRIBUTE_SEPARATOR + PRE_BOOK_QUESTION) as 'attributes'],
            )}
            helperText={t('max-chars', { count: textAreaMaxChars })}
          />
        </DialogFormRow>
        <DialogFormRow>
          <CommonInput
            label={t('correct-answer')}
            placeholder={t('correct-answer')}
            {...attributeRegister(
              PRE_BOOK_CORRECT_ANSWER,
              (e) => e.target.value,
              {
                required: {
                  value: !!enablePreBookQuestion,
                  message: t('must-not-empty') as string,
                },
                maxLength: inputMaxChars,
              },
            )}
            value={
              enablePreBookQuestion
                ? attributes?.find((atr) => atr.key === PRE_BOOK_CORRECT_ANSWER)
                    ?.value_str
                : ''
            }
            disabled={!enablePreBookQuestion}
            hasError={Boolean(
              errors[
                (ATTRIBUTE_SEPARATOR + PRE_BOOK_CORRECT_ANSWER) as 'attributes'
              ],
            )}
            helperText={t('max-chars', { count: inputMaxChars })}
          />

          <CommonInput
            label={t('incorrect-answer')}
            placeholder={t('incorrect-answer')}
            {...attributeRegister(
              PRE_BOOK_INCORRECT_ANSWER,
              (e) => e.target.value,
              {
                required: {
                  value: !!enablePreBookQuestion,
                  message: t('must-not-empty') as string,
                },
                maxLength: inputMaxChars,
              },
            )}
            value={
              enablePreBookQuestion
                ? attributes?.find(
                    (atr) => atr.key === PRE_BOOK_INCORRECT_ANSWER,
                  )?.value_str
                : ''
            }
            disabled={!enablePreBookQuestion}
            hasError={Boolean(
              errors[
                (ATTRIBUTE_SEPARATOR +
                  PRE_BOOK_INCORRECT_ANSWER) as 'attributes'
              ],
            )}
            helperText={t('max-chars', { count: inputMaxChars })}
          />
        </DialogFormRow>
        <DialogFormRow>
          <TextArea
            label={t('message-after-incorrect-answer')}
            placeholder={t('message-after-incorrect-answer')}
            {...attributeRegister(
              PRE_BOOK_INCORRECT_ANSWER_MESSAGE,
              (e) => e.target.value,
              {
                required: {
                  value: !!enablePreBookQuestion,
                  message: t('must-not-empty') as string,
                },
                maxLength: textAreaMaxChars,
              },
            )}
            value={
              enablePreBookQuestion
                ? attributes?.find(
                    (atr) => atr.key === PRE_BOOK_INCORRECT_ANSWER_MESSAGE,
                  )?.value_str
                : ''
            }
            disabled={!enablePreBookQuestion}
            hasError={Boolean(
              errors[
                (ATTRIBUTE_SEPARATOR +
                  PRE_BOOK_INCORRECT_ANSWER_MESSAGE) as 'attributes'
              ],
            )}
            helperText={t('max-chars', { count: textAreaMaxChars })}
          />
        </DialogFormRow>
        {isFetching && <CardLoader fillWrapper={true} />}
      </DialogFormWrapper>
    </Card>
  );
};
