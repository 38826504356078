import {
  STAFF_CLOSE_HOUR_IN_SECONDS,
  STAFF_OPEN_HOUR_IN_SECONDS,
} from '../../configs/siteAndTrustAttributes';
import { useSiteResourceTypes } from '../../query/resourceTypes';
import { getDaySlotsByHour } from '../../utils/appointmentUtil';
import { addMinutes, setHours, startOfDay } from '../../utils/dateUtil';
import { getPrimaryResourceType } from '../../utils/resourceTypes';
import { useSiteIntAttributes } from '../useAttribute';
import { UNSAFE_FILTER_WITH_NO_PAGINATION } from '../usePaginatedFilters';

export type BookingSlot = {
  hour: number;
  minutes: number[];
  lastAppointmentEndTime: Date;
};

export const useSiteHourRange = () => {
  const [openHourInSeconds = 0, closeHourInSeconds = 0] = useSiteIntAttributes(
    STAFF_OPEN_HOUR_IN_SECONDS,
    STAFF_CLOSE_HOUR_IN_SECONDS,
  );

  const { data: resourceTypesData, isLoading } = useSiteResourceTypes({
    ...UNSAFE_FILTER_WITH_NO_PAGINATION,
  });

  const primaryResourceType = getPrimaryResourceType(resourceTypesData?.data);

  const slots: BookingSlot[] = primaryResourceType
    ? getDaySlotsByHour({
        openHourInSeconds,
        closeHourInSeconds,
        slotLengthInMinutes: primaryResourceType.length,
      }).map((slot) => ({
        ...slot,
        lastAppointmentEndTime: addMinutes(
          setHours(startOfDay(new Date()), slot.hour),
          slot.minutes[0] + slot.minutes.length * primaryResourceType.length,
        ),
      }))
    : [];

  return {
    slots,
    isLoading,
    appointmentLength: primaryResourceType?.length || 0,
  };
};
