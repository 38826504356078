import React from 'react';
import { useAppointmentColumns } from '../../../hooks/appointments/useAppointmentColumns';
import { Appointment, AppointmentFilters } from '../../../types/appointments';
import { FetchedDataTable } from '../../../components/Table/FetchedDataTable';
import { ItemsResponse } from '../../../types/ItemsWithCountResponse';
import { FiltersWithSet } from '../../../hooks/useFilters';

type IAdminAuditApptTableProps = {
  appointments?: ItemsResponse<Appointment>;
  onAppointmentInfoClicked: (appointment: Appointment) => any;
  isLoading: boolean;
  isFetching: boolean;
} & FiltersWithSet<AppointmentFilters>;

export const AdminAuditApptTable: React.FunctionComponent<
  IAdminAuditApptTableProps
> = ({
  appointments,
  isLoading,
  isFetching,
  onAppointmentInfoClicked,
  filters,
  setFilters,
}) => {
  const { columns } = useAppointmentColumns({
    appointments: appointments?.data,
    onAppointmentInfoClicked,
    filters,
    setFilters,
  });

  return (
    <FetchedDataTable
      columns={columns}
      data={appointments}
      filters={filters}
      onSortOrPageChange={setFilters}
      isLoading={isLoading}
      isFetching={isFetching}
    />
  );
};
