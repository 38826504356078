import React from 'react';
import { Appointment } from '../../../types/appointments';
import { BookAppointmentWithPatient } from './BookAppointmentWithPatient';
import { BookAppointmentNoPatient } from './BookAppointmentNoPatient';
import { UserData } from '../../../types/users';

interface IAppointmentSlotCardProps {
  closeForm: (keepPatient: boolean) => void;
  selectedAppointmentSlot: Appointment[];
  selectedPatient?: UserData;
  isWalkInAvailable: boolean;
}

export const BookAppointmentSlotCard: React.FunctionComponent<
  IAppointmentSlotCardProps
> = ({
  closeForm,
  selectedAppointmentSlot,
  selectedPatient,
  isWalkInAvailable,
}) => {
  return (
    <>
      {selectedPatient ? (
        <BookAppointmentWithPatient
          closeForm={closeForm}
          selectedAppointmentSlot={selectedAppointmentSlot}
          selectedUser={selectedPatient}
          isWalkInAvailable={isWalkInAvailable}
        />
      ) : (
        <BookAppointmentNoPatient
          selectedAppointmentSlot={selectedAppointmentSlot}
          closeForm={() => closeForm(false)}
          isWalkInAvailable={isWalkInAvailable}
        />
      )}
    </>
  );
};
