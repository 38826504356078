import { useSiteStringAttributes } from 'hooks/useAttribute';
import { useCallback } from 'react';

import { ErrorCode } from '../../utils/errorCodes';
import { checkErrorCode } from '../../utils/errors';
import {
  CHECK_IN_EARLY_TEXT,
  CHECK_IN_LATE_TEXT,
} from '../../configs/siteAndTrustAttributes';

export const useValidateCheckIn = () => {
  const [checkinEarlyText = 'Early checkin', checkinLateText = 'Late checkin'] =
    useSiteStringAttributes(CHECK_IN_EARLY_TEXT, CHECK_IN_LATE_TEXT);

  // returns an error text or null if there are no errors
  return useCallback(
    (error) => {
      if (checkErrorCode(error, ErrorCode.EARLY_CHECKIN_AP0012)) {
        return checkinEarlyText;
      }
      if (checkErrorCode(error, ErrorCode.LATE_CHECKIN_AP0013))
        return checkinLateText;
    },
    [checkinEarlyText, checkinLateText],
  );
};
