import { singleEmailPattern } from '../../utils/helpers';
import { useCommonTranslation } from '../i18n/useCommonTranslation';

export const useEmailValidation = () => {
  const { t } = useCommonTranslation();
  return {
    required: t('must-not-empty') as string,
    pattern: {
      value: singleEmailPattern,
      message: t('must-valid-address') as string,
    },
    setValueAs: (value: any) => value.trim(),
  };
};
