import { TrustData } from '../types/trusts';
import { Factory, Model } from 'miragejs';
import { MockServer, wrapCountResponseCollection } from './server';
import {
  NHS_NUMBER_HIDDEN,
  NHS_NUMBER_MANDATORY,
  NHS_NUMBER_OPTIONAL,
} from '../utils/trustUtil';

export type PartialTrust = Partial<TrustData>;

export const trustModel = Model.extend<PartialTrust>({});

export const trustFactory = Factory.extend<PartialTrust>({
  id(index) {
    return `${index}`;
  },
});

export const TEST_TRUST: TrustData = {
  id: 'TRUST_1',
  name: 'Non Mandatory Trust',
  nhs_number_policy: NHS_NUMBER_OPTIONAL,
};

export const TEST_TRUST_NHS_NUMBER_HIDDEN: TrustData = {
  id: 'TRUST_3',
  name: 'Trust hidden NHS number',
  nhs_number_policy: NHS_NUMBER_HIDDEN,
};

export const ALL_MANDATORY_TRUST: TrustData = {
  id: 'TRUST_2',
  name: 'Mandatory Trust',
  nhs_number_policy: NHS_NUMBER_MANDATORY,
  hide_postcode: false,
  hide_gp_surgery: true,
};

export const seedTrusts = (server: MockServer) => {
  server.create('trust', TEST_TRUST);
  server.create('trust', ALL_MANDATORY_TRUST);
  server.create('trust', TEST_TRUST_NHS_NUMBER_HIDDEN);
};

export const trustEndpoints = (server: MockServer) => {
  server.get('trust', (schema) =>
    wrapCountResponseCollection(schema.all('trust')),
  );
  server.get('trust/:id', (schema, request) => {
    return schema.find('trust', request.params.id)?.attrs || 404;
  });
  server.put('trust', (schema, request) => {
    const update = JSON.parse(request.requestBody);
    const id = update.id;
    const model = schema.find('trust', id);
    if (model) {
      model.update(update);
      return 200;
    }
    return 404;
  });
  server.post('trust', (schema, request) => {
    schema.create('trust', JSON.parse(request.requestBody));
    return 200;
  });
  server.delete('trust/:id', (schema, request) => {
    return schema.find('trust', request.params.id)?.destroy() || 404;
  });
};
