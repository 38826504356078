import { CommonLink } from 'components/common/Forms/Button';
import { InlineConfirm } from 'components/Popup/ConfirmDialog/InlineConfirm';
import { usePatientBooking } from 'context/patient/modules/booking';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { FaCalendarCheck } from 'react-icons/fa';
import { patientURL } from 'routes/AppRoutes';
import { getApptRangeStr } from 'utils/appointmentUtil';
import { DATE_FNS_UK_DATE_FORMAT, formatDateFromISO } from 'utils/dateUtil';

import { PopupTitle } from '../../../components/Popup/ConfirmDialog/ConfirmDialog.styled';
import { useSite } from '../../../context/SiteContext';

export const PatientBookingSuccess = () => {
  const { t } = useCommonTranslation();
  const { activeSite } = useSite();
  const {
    booking: { bookedAppt },
  } = usePatientBooking();
  if (!bookedAppt) return null;
  const date = formatDateFromISO(bookedAppt.appt_time, DATE_FNS_UK_DATE_FORMAT);
  const timeRange = getApptRangeStr(bookedAppt);

  return (
    <InlineConfirm
      icon={<FaCalendarCheck />}
      actions={[
        <CommonLink
          key="see-appts"
          variant="primary"
          size="large"
          to={`${patientURL}/my-appointments`}
        >
          {t('see-appointments')}
        </CommonLink>,
        <CommonLink key="back" variant="secondary" size="large" to={patientURL}>
          {t('back-to-menu')}
        </CommonLink>,
      ]}
      inlineIcon
    >
      <PopupTitle compact>
        {t('patient-appointment-booked-success-at')}
      </PopupTitle>
      <PopupTitle>{activeSite.name}</PopupTitle>
      <PopupTitle compact>
        {t('date')}: {date}
      </PopupTitle>
      <PopupTitle compact>
        {t('time')}: {timeRange}
      </PopupTitle>
      <PopupTitle
        compact
        style={{
          marginBottom: 'var(--s3)',
        }}
      >
        {t('site')}: {activeSite.short_name}
      </PopupTitle>
    </InlineConfirm>
  );
};
