import { useSite } from '../../context/SiteContext';
import { useKioskAppointments } from '../../query/public';
import {
  KIOSK_GENERAL_ERROR,
  KIOSK_MULTIPLE_RESULTS_ERROR,
  KIOSK_NO_APPTS_FOUND_ERROR,
  KioskFindAppointmentErrorState,
} from './useKioskState';
import { KioskAppointment } from '../../types/appointments';

export type KioskApptSearchParams = {
  searchString: string;
  type: 'PHONE' | 'INITIALS';
};

export const useKioskFindAppointment = ({
  params: { type, searchString },
  onError,
  onSuccess,
}: {
  params: KioskApptSearchParams;
  onError: (errorType: KioskFindAppointmentErrorState) => any;
  onSuccess: (appt: KioskAppointment) => any;
}) => {
  const { activeSite } = useSite();
  const { isLoading } = useKioskAppointments(
    {
      siteId: activeSite.id,
      input: searchString,
      isPhoneInput: type === 'PHONE',
    },
    {
      onError: () => {
        onError(KIOSK_GENERAL_ERROR);
      },
      onSuccess: (apptsData) => {
        const appts = apptsData?.data?.data || [];
        if (!appts.length) {
          onError(KIOSK_NO_APPTS_FOUND_ERROR);
        } else if (appts.length > 1) {
          onError(KIOSK_MULTIPLE_RESULTS_ERROR);
        } else {
          onSuccess(appts[0]);
        }
      },
    },
  );

  return { isLoading };
};
