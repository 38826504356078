import React, { useState } from 'react';
import { SiteData } from '../../../../types/sites';
import { TrustData } from '../../../../types/trusts';
import { PatientEditCreateCard } from './patient/PatientEditCreateCard';
import { StaffPatientMembers } from './patient/StaffPatientMembers';
import { UserData } from '../../../../types/users';

type IUserCardProps = {
  closeFormHandler: (userAfterChange?: UserData) => void;
  prefilledData?: UserData;
  activeSite: SiteData;
  activeTrust: TrustData;
};

export const PatientEditUpdate: React.FunctionComponent<IUserCardProps> = ({
  prefilledData,
  closeFormHandler,
  activeTrust,
  activeSite,
}) => {
  const [currentCard, setCurrentCard] = useState<'editDetails' | 'viewMembers'>(
    'editDetails',
  );
  return (
    <>
      {currentCard === 'editDetails' && (
        <PatientEditCreateCard
          closeFormHandler={closeFormHandler}
          activeSite={activeSite}
          activeTrust={activeTrust}
          prefilledData={prefilledData}
          onMembersClicked={() => setCurrentCard('viewMembers')}
        />
      )}
      {currentCard === 'viewMembers' && prefilledData && (
        <StaffPatientMembers
          closeFormHandler={closeFormHandler}
          user={prefilledData}
          onBackClicked={() => setCurrentCard('editDetails')}
        />
      )}
    </>
  );
};
