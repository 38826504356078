import { useEffect, useMemo, useState } from 'react';

import { timePlannerHasStaffOnlyItems } from '../../../../components/TimePlanner/timePlannerUtils';
import { ResourceType } from '../../../../types/resource-types';
import { formatDateOnlyISO } from '../../../../utils/dateUtil';
import { getDefaultTemplateDate } from '../../../../utils/apptTemplateUtils';
import { useSaveTimePlanning } from './useSaveTimePlanning';
import { useTimePlanningWeekDays } from './useTimePlanningWeekDays';
import { useDefaultApptTemplate } from '../../../../hooks/apptTemplate/useDefaultApptTemplate';
import { useResourceTypesWithCubiclesAssigned } from '../../../../hooks/resourceTypes/useResourceTypesWithCubiclesAssigned';

const weekDaysWithSundayAsLastDay = (weekDays: number[] | undefined) => {
  /**
   * We want first day of the week, but 0 is sunday...
   */
  return (weekDays || [0]).map((day) => (day === 0 ? 7 : day));
};

const sundayLastDayAsFirstDay = (day: number) => {
  return day === 7 ? 0 : day;
};

export const useSiteFirstDefaultTemplateDate = () => {
  const weekDays = useTimePlanningWeekDays();

  return useMemo(() => {
    const firstDay = Math.min(...weekDaysWithSundayAsLastDay(weekDays));
    return getDefaultTemplateDate(sundayLastDayAsFirstDay(firstDay));
  }, [weekDays]);
};

export const useSiteLastDefaultTemplateDate = () => {
  const weekDays = useTimePlanningWeekDays();

  return useMemo(() => {
    const lastDay = Math.max(...weekDaysWithSundayAsLastDay(weekDays));
    return getDefaultTemplateDate(sundayLastDayAsFirstDay(lastDay));
  }, [weekDays]);
};

export const useDefaultTimePlanning = ({
  selectedDate,
}: {
  selectedDate: Date;
}) => {
  const [showOnline, setShowOnline] = useState(false);
  const [selectedResourceType, setSelectedResourceType] =
    useState<ResourceType>();

  const {
    isLoading: isResourceTypesLoading,
    allResourceTypes,
    resourceTypesWithCubicleAssigned,
    cubicles,
  } = useResourceTypesWithCubiclesAssigned();

  const fullWeekDefaultEditAvailable = allResourceTypes?.length === 1 || false;

  useEffect(() => {
    if (
      resourceTypesWithCubicleAssigned &&
      resourceTypesWithCubicleAssigned.length >= 1
    ) {
      setSelectedResourceType(resourceTypesWithCubicleAssigned[0]);
    } else {
      setSelectedResourceType(undefined);
    }
  }, [resourceTypesWithCubicleAssigned]);

  const selectedResourceCubiclesAssigned = useMemo(
    () =>
      selectedResourceType && cubicles
        ? cubicles.data.filter((cubicle) =>
            cubicle.resource_types?.some(
              (rt) => rt.id === selectedResourceType.id,
            ),
          ).length
        : 0,
    [selectedResourceType, cubicles],
  );

  const { isLoading: isTemplatesLoading, defaultTemplate } =
    useDefaultApptTemplate({
      selectedDate,
      selectedResourceType,
    });

  const { isLoading: isCreateOrUpdateLoading, saveTimePlanning } =
    useSaveTimePlanning({
      defaultTemplate,
      selectedDateOnlyIso: formatDateOnlyISO(selectedDate),
      selectedResourceType,
    });

  const showOnlineMustBeShown = timePlannerHasStaffOnlyItems(defaultTemplate);

  return {
    showOnline,
    setShowOnline,
    selectedResourceType,
    resourceTypesWithCubicleAssigned,
    setSelectedResourceType,
    saveTimePlanning,
    selectedResourceCubiclesAssigned,
    defaultTemplate,
    showOnlineMustBeShown,
    fullWeekDefaultEditAvailable,
    isLoading:
      isTemplatesLoading || isResourceTypesLoading || isCreateOrUpdateLoading,
  };
};
