import { CommonButton } from 'components/common/Forms/Button';
import styled from 'styled-components';
import { boxShadow } from 'styles/partials/boxShadow';
import { listItemMarginX } from 'styles/partials/listItemMargin';
import { mq } from 'styles/sizes';
import { h4 } from 'styles/Typography';
import { toRem } from 'utils/helpers';

export const InlineCalendarStylesWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    display: block;
    border: none;
    font-family: inherit;
    font-size: inherit;
    max-width: ${toRem(432)}rem;
    margin: auto;
  }

  .react-datepicker__header {
    background-color: transparent;
    padding: 0;
    border: none;
    border-radius: 0;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--s2);
  }

  .react-datepicker__day-name {
    ${h4};
    width: auto;
    min-width: ${toRem(48)}rem;
    max-width: ${toRem(48)}rem;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--s3);

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .react-datepicker__day {
    ${boxShadow}
    line-height: 0;
    width: auto;
    min-width: ${toRem(48)}rem;
    max-width: ${toRem(48)}rem;
    min-height: ${toRem(48)}rem;
    max-height: ${toRem(48)}rem;
    border-radius: 50%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--h2);
    border: 2px solid var(--greenprimary);
    box-shadow: var(--shadow-3);
    color: var(--greenprimary);
    transition: 0.2s ease background-color, 0.2s ease border-color,
      0.2s ease color;
    letter-spacing: 0.01px;

    ${listItemMarginX('var(--s2)')}
    &:hover {
      background-color: var(--greentercial50);
    }

    &.react-datepicker__day--highlighted {
      background-color: var(--greenprimary);
      color: var(--white);
    }

    &.react-datepicker__day--selected {
      background-color: var(--redaccent);
      border-color: var(--redaccent);
      color: var(--white);
    }

    &.react-datepicker__day--disabled {
      background-color: var(--disabled);
      border-color: var(--disabled);
      color: var(--white);
      cursor: not-allowed;
      box-shadow: none;
    }

    &--keyboard-selected,
    &--selected {
      background-color: var(--white);
      border: 2px solid var(--greenprimary);
      outline: none;
    }

    ${mq.xsm} {
      ${listItemMarginX('var(--s3)')}
    }
  }

  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: normal;
  }
`;
export const InlineCalendarHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--s3);
`;
export const InlineCalendarDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 var(--s2);
`;
export const InlineCalendarMonth = styled.div`
  ${h4};
  min-width: ${toRem(100)}rem;
`;
export const InlineCalendarYear = styled.div`
  color: var(--textsecondary);
`;

export const InlineCalendarMonthButton = styled(CommonButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: auto;
    height: 90%;
  }
`;

export const InlineCalendarDay = styled.span`
  ${mq.lg} {
    position: relative;
    top: 2px; // browser's center is a bit above from our design's center, manually shifting it
  }
`;
