import { CommonButton as Button } from 'components/common/Forms/Button';
import { RowActions } from 'components/Table/Table.styled';
import React, { ReactElement } from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { Column } from 'react-table';
import {
  OtherReferrer,
  OtherReferrerResponseData,
  OtherReferrersFilters,
} from 'types/otherReferrers';
import { FetchedDataTable } from '../../../../Table/FetchedDataTable';
import { PaginatedFiltersWithSet } from '../../../../../hooks/usePaginatedFilters';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { getFilterControls } from '../../../../Table/TableUtils';
import { useOtherReferrersColumns } from '../../../../../hooks/referrals/useOtherReferrersColumns';

type Props = {
  otherReferrersData?: OtherReferrerResponseData;
  openFormHandler: (value?: string) => void;
  handleDeleteReferrer: (value: string) => void;
  isLoading: boolean;
  isFetching: boolean;
} & PaginatedFiltersWithSet<OtherReferrersFilters>;

export const OtherReferrersTable = ({
  otherReferrersData: referrersData,
  openFormHandler,
  handleDeleteReferrer,
  setFilters,
  isLoading,
  filters,
  isFetching,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();

  const columns = useOtherReferrersColumns({
    lastColumn: {
      ...getFilterControls({ filters, setFilters, t }),
      Cell: ({ value }: { value: string }) => {
        return (
          <RowActions>
            <Button
              size="auto"
              iconOnly
              variant="primary"
              title={t('edit-referrer')}
              type="button"
              onClick={() => openFormHandler(value)}
            >
              <FaPencilAlt />
            </Button>
            <Button
              size="auto"
              iconOnly
              variant="danger"
              title={t('remove-referrer')}
              type="button"
              onClick={() => {
                handleDeleteReferrer(value);
              }}
            >
              <FaTrashAlt />
            </Button>
          </RowActions>
        );
      },
    } as Column<OtherReferrer>,
  });

  return (
    <FetchedDataTable
      columns={columns}
      data={referrersData}
      isLoading={isLoading}
      isFetching={isFetching}
      filters={filters}
      onSortOrPageChange={setFilters}
    />
  );
};
