import { useCallback, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocalStorage, usePrevious } from 'react-use';
import { GA_LOGIN_EVENT_INTERVAL_MINUTES } from '../../configs/constants';

import { useAuth } from '../../context/AuthContext';
import { useSite } from '../../context/SiteContext';
import { IAnalyticsContext } from '../../types/analytics';
import { MILLIS_IN_MINUTE } from '../../utils/dateUtil';
import { useRole } from '../../context/RoleContext';

/**
 * Interval before login event is tracked.
 * It prevents multiple login events on browser refresh.
 */
const loginEventIntervalMillis =
  GA_LOGIN_EVENT_INTERVAL_MINUTES * MILLIS_IN_MINUTE;
const loginEventTimeStorageKey = 'gaLoginEventTime';

/**
 * Manage login and logout event creation.
 */
export const useAuthenticationTracking = () => {
  const { sangixUser } = useAuth();
  const { currentUserRole } = useRole();
  const { activeSite } = useSite();
  const [loginEventTime, setLoginEventTime, removeLoginEventTime] =
    useLocalStorage<number>(loginEventTimeStorageKey);

  const shouldTrackLoginEvent = useCallback(() => {
    const time = loginEventTime || 0;
    // track login when last login was before event interval
    return time + loginEventIntervalMillis < new Date().getTime();
  }, [loginEventTime]);

  const { id: userId } = sangixUser || {};
  const { id: currentUserRoleId } = currentUserRole || {};
  const { id: activeSiteId } = activeSite || {};
  const isFullyLoggedIn = userId && currentUserRoleId && activeSiteId;
  const wasFullyLoggedIn = usePrevious(isFullyLoggedIn);

  useEffect(() => {
    if (isFullyLoggedIn && !wasFullyLoggedIn && shouldTrackLoginEvent()) {
      const { id: role_id, name: role_name } = currentUserRole || {};
      const { id: active_site_id, name: active_site_name } = activeSite || {};
      ReactGA.event('login', {
        role_id,
        role_name,
        active_site_id,
        active_site_name,
      });
      setLoginEventTime(new Date().getTime());
    }
    if (!isFullyLoggedIn && wasFullyLoggedIn) {
      ReactGA.event('logout');
      removeLoginEventTime();
    }
  }, [
    isFullyLoggedIn,
    wasFullyLoggedIn,
    currentUserRole,
    activeSite,
    shouldTrackLoginEvent,
    setLoginEventTime,
    removeLoginEventTime,
  ]);
};

/**
 * Creates send event callback which automatically
 * adds user and active site parameters.
 */
export const useSendEvent = () => {
  const { currentUserRole } = useRole();
  const { activeSite } = useSite();
  const { id: role_id, name: role_name } = currentUserRole || {};
  const { id: active_site_id, name: active_site_name } = activeSite || {};

  const sendEvent: IAnalyticsContext['sendEvent'] = useCallback(
    (name, params) => {
      ReactGA.event(name, {
        role_id,
        role_name,
        active_site_id,
        active_site_name,
        ...(params || {}),
      });
    },
    [role_id, role_name, active_site_id, active_site_name],
  );

  return sendEvent;
};
