import { AxiosError } from 'axios';
import { Card } from 'components/common';
import { CommonButton as Button } from 'components/common/Forms/Button';
import DatePickerInput from 'components/common/Forms/DatePickerInput';
import {
  CloseButton,
  DialogFormWrapper,
  FormActionsStyle,
  FormBody,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import { CardLoader } from 'components/common/Loading';
import React, { ReactElement, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaTimes } from 'react-icons/fa';
import { BankHolidayFormValues } from 'types/bankHoliday';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';

interface Props {
  closeFormHandler: (err?: true | AxiosError<any>) => void;
  submitHandler: (data: BankHolidayFormValues) => void;
  isLoading: boolean;
}

export default function BankHolidayForm({
  closeFormHandler,
  submitHandler,
  isLoading,
}: Props): ReactElement {
  const { t } = useCommonTranslation();
  const {
    register,
    control,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<BankHolidayFormValues>();

  useEffect(() => {
    // This for setting focus after form mounted for better user experience
    setFocus('name');
  }, [setFocus]);

  return (
    <Card>
      <DialogFormWrapper>
        <CloseButton
          size="auto"
          variant="primary"
          iconOnly
          onClick={() => closeFormHandler()}
        >
          <FaTimes />
        </CloseButton>
        <FormHeader>
          <FormTitle>{t('holiday')}</FormTitle>
        </FormHeader>
        <FormBody onSubmit={handleSubmit((data) => submitHandler(data))}>
          <FormRow>
            <Input
              label={t('name')}
              placeholder={t('name')}
              helperText={errors.name?.message}
              hasError={Boolean(errors.name)}
              id={'new_holiday_name'}
              {...register('name', {
                required: t('must-not-empty') as string,
              })}
              required
            />
            <Controller
              control={control}
              name="holiday_date"
              rules={{
                required: t('must-not-empty') as string,
              }}
              render={({ field }) => {
                return (
                  <DatePickerInput
                    {...field}
                    name="holiday_date"
                    label={t('date')}
                    placeholder={t('date')}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    disabled={isLoading}
                    id={'holiday_date'}
                    hasError={Boolean(errors.holiday_date)}
                    helperText={errors.holiday_date?.message}
                    selected={field.value}
                    required
                  />
                );
              }}
            />
          </FormRow>
          <FormActionsStyle align="center">
            <Button variant="primary" type="submit">
              {t('add')}
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={() => closeFormHandler()}
            >
              {t('cancel')}
            </Button>
          </FormActionsStyle>
        </FormBody>
        {isLoading && <CardLoader fillWrapper={true} />}
      </DialogFormWrapper>
    </Card>
  );
}
