import { PageHelmet } from 'components/common/PageHelmet';
import { PatientDashboard } from 'components/views-components/patient/dashboard/PatientDashboard';
import { PatientLayout } from 'containers/PatientLayout';
import { ReactElement } from 'react';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';

export default function PatientHome(): ReactElement {
  const { t } = useCommonTranslation();

  return (
    <PatientLayout graphics="patient">
      <PageHelmet title={t('dashboard')} />
      <PatientDashboard />
    </PatientLayout>
  );
}
