import { PatientBookingPickBox } from '../PatientBooking.styled';
import { usePatientBooking } from '../../../../context/patient/modules/booking';
import {
  FOUND_RESULTS,
  LAST_PERIOD_NO_RESULTS_FOUND,
  useBookingAppointmentsWithFreeSearch,
} from '../../../../hooks/patient/booking/useBookingAppointmentsWithFreeSearch';
import { useCallback, useEffect } from 'react';
import { PatientBookingPickDayLoader } from './PatientBookingPickDayLoader';
import { PatientBookingPickDaySelect } from './PatientBookingPickDaySelect';
import { PatientBookingPickDayNoAppts } from './PatientBookingPickDayNoAppts';
import { formatDateOnlyISO } from '../../../../utils/dateUtil';
import { PatientBookingPickDayNoApptsLastPeriod } from './PatientBookingPickDayNoApptsLastPeriod';

export const PatientBookingPickDay = () => {
  const {
    updateBooking,
    booking: { calendarDateShown },
  } = usePatientBooking();

  const {
    isLoading,
    availableDays,
    dateSearching,
    isDayAvailable,
    startFreeApptSearchFromNextPeriod,
    stopFreeApptSearch,
    searchPeriod,
    status,
  } = useBookingAppointmentsWithFreeSearch({
    dateInSearchBox: formatDateOnlyISO(calendarDateShown),
    startSearch: true,
  });

  useEffect(() => {
    updateBooking({ calendarDateShown: dateSearching });
  }, [dateSearching, updateBooking]);

  const setSelectedDate = useCallback(
    (date: Date) => {
      updateBooking({ calendarDateShown: date, date, stage: 'hour' });
    },
    [updateBooking],
  );

  return (
    <PatientBookingPickBox>
      {isLoading ? (
        <PatientBookingPickDayLoader
          searchPeriod={searchPeriod}
          stopSearchAction={stopFreeApptSearch}
        />
      ) : status === FOUND_RESULTS && availableDays ? (
        <PatientBookingPickDaySelect
          firstFoundInWeek={dateSearching}
          setSelectedDate={setSelectedDate}
          isDayAvailable={isDayAvailable}
          availableDays={availableDays}
          updateBooking={updateBooking}
        />
      ) : status === LAST_PERIOD_NO_RESULTS_FOUND ? (
        <PatientBookingPickDayNoApptsLastPeriod
          firstFoundInWeek={dateSearching}
          updateBooking={updateBooking}
          searchPeriod={searchPeriod}
          onStartSearchClicked={startFreeApptSearchFromNextPeriod}
        />
      ) : (
        <PatientBookingPickDayNoAppts
          firstFoundInWeek={dateSearching}
          updateBooking={updateBooking}
          searchPeriod={searchPeriod}
          onStartSearchClicked={startFreeApptSearchFromNextPeriod}
        />
      )}
    </PatientBookingPickBox>
  );
};
