import { usePatientBooking } from 'context/patient/modules/booking';
import { useCallback } from 'react';

import { GuidelinesCard } from '../guidelines/GuidelinesCard';

interface Props {
  guidelines: string;
}

export const PatientBookingGuidelines = ({ guidelines }: Props) => {
  const { updateBooking } = usePatientBooking();

  const handleNext = useCallback(() => {
    // go to next stage
    updateBooking({ stage: 'member' });
  }, [updateBooking]);

  return <GuidelinesCard text={guidelines} onAccept={handleNext} />;
};
