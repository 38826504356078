import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { usePrevious } from 'react-use';

import { isGeneralPractise } from '../../../components/views-components/staff/appointments/AppointmentReferrersUtil';
import { AppointmentForm } from '../../../hooks/appointments/useAppointmentStateWorkflow';
import { useMutateCancelApptWithConfirm } from '../../../hooks/appointments/useMutateCancelApptWithConfirm';
import { useAllGPSurgeries } from '../../../hooks/referrals/useAllGPSurgeries';
import { UNSAFE_FILTER_WITH_NO_PAGINATION } from '../../../hooks/usePaginatedFilters';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import {
  useAppointmentAudit,
  useMutateUpdateAppointment,
} from '../../../query/appointments';
import { Appointment } from '../../../types/appointments';
import { isToday, parseIsoDate } from '../../../utils/dateUtil';
import { useReferrerOptions } from '../../../utils/options';
import { successToast } from '../../../utils/toast';
import { UserData } from '../../../types/users';
import { useAppointmentNotPreparedOrUpdate } from '../../../hooks/appointments/useAppointmentUpdate';

export const useExistingAppointmentEditCard = ({
  onClose,
  appointment,
  isUpstreamLoading,
  user,
}: {
  onClose: () => unknown;
  appointment: Appointment;
  isUpstreamLoading: boolean;
  user?: UserData;
}) => {
  const { t } = useCommonTranslation();
  const { isLoading: isSurgeriesLoading, data: surgeriesResponse } =
    useAllGPSurgeries();

  const { isLoading: isReferrersLoading, referrerOptions } =
    useReferrerOptions();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<AppointmentForm>();

  const previousAppt = usePrevious(appointment);
  useEffect(() => {
    if (
      previousAppt?.id !== appointment.id ||
      previousAppt?.comment !== appointment.comment ||
      previousAppt?.appt_flags !== appointment.appt_flags ||
      isGeneralPractise(previousAppt) !== isGeneralPractise(appointment) ||
      previousAppt?.referrer_id !== appointment.referrer_id
    ) {
      const update = {
        comment: appointment.comment || '',
        appt_flags: appointment.appt_flags,
        generalPractise: isGeneralPractise(appointment),
        otherRefererId: appointment.referrer_id,
      };
      reset({ appointment: update });
    }
  }, [appointment, setValue, user, reset, previousAppt]);

  const { status: appointmentStatus } = appointment;

  const { mutate: updateAppointment, isLoading: isUpdateAppointmentLoading } =
    useMutateUpdateAppointment({
      onSuccess: () => {
        successToast(t('appointment-update-successful'));
      },
    });

  const { cancelWithConfirm, isLoading: isCancelAppointmentLoading } =
    useMutateCancelApptWithConfirm({
      onSuccess: () => {
        successToast(t('appointment-cancel-successful'));
        onClose();
      },
    });

  const {
    mutate: appointmentNotPreparedOrUpdate,
    isLoading: isAppointmentNotPreparedOrUpdateLoading,
  } = useAppointmentNotPreparedOrUpdate({
    onSuccess: () => {
      successToast(t('appointment-not-prepared-successful'));
      onClose();
    },
  });

  /**
   * I don't think the data are that important to show loader
   */
  const { data: auditData } = useAppointmentAudit({
    ...UNSAFE_FILTER_WITH_NO_PAGINATION,
    apptID: appointment.id,
  });

  const isEditableWithinGracePeriod =
    (appointmentStatus === 'TAKEN' ||
      appointmentStatus === 'NOT_TAKEN' ||
      appointmentStatus === 'NOT_PREPARED') &&
    isToday(parseIsoDate(appointment.appt_time));

  const isLoading =
    isUpstreamLoading ||
    isSurgeriesLoading ||
    isReferrersLoading ||
    isCancelAppointmentLoading ||
    isAppointmentNotPreparedOrUpdateLoading ||
    isUpdateAppointmentLoading;

  return {
    isLoading,
    auditData: auditData?.data,
    appointmentNotPreparedOrUpdate,
    cancelWithConfirm,
    updateAppointment,
    surgeriesResponse,
    appointmentStatus,
    errors,
    register,
    control,
    referrerOptions,
    handleSubmit,
    isEditableWithinGracePeriod,
  };
};
