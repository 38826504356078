import { CommonButton } from 'components/common/Forms/Button';
import { useAuth } from 'context/AuthContext';
import { usePatientBooking } from 'context/patient/modules/booking';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { optionalIsoDateToDateOnlyString } from 'utils/dateUtil';

import { Tooltip } from '../../../components/common/Tooltips/Tooltip';
import { useSiteValidatePatientAge } from '../../../hooks/site/useSiteValidatePatientAge';
import { toRemStr } from '../../../utils/helpers';
import {
  PatientBookingMemberItem,
  PatientBookingMemberItemBidth,
  PatientBookingMemberItemName,
  PatientBookingStageTitle,
} from './PatientBooking.styled';

export const PatientBookingPickMember = () => {
  const { t } = useCommonTranslation();
  const { sangixUser } = useAuth();
  const { updateBooking } = usePatientBooking();
  const { validatePatientAge } = useSiteValidatePatientAge();

  const users = !sangixUser
    ? []
    : [sangixUser, ...(sangixUser?.other_members || [])].map((member) => ({
        ...member,
        validationError: validatePatientAge(member),
      }));

  return (
    <>
      <PatientBookingStageTitle>{t('book-as')}</PatientBookingStageTitle>
      <div>
        {users.map((member) => (
          <PatientBookingMemberItem key={member.id}>
            <PatientBookingMemberItemName>
              {member.firstname} {member.lastname}
            </PatientBookingMemberItemName>
            <PatientBookingMemberItemBidth>
              {optionalIsoDateToDateOnlyString(member.birthdate)}
            </PatientBookingMemberItemBidth>
            <CommonButton
              variant="primary"
              onClick={() =>
                updateBooking({ stage: 'question', memberId: member.id })
              }
              disabled={member.validationError}
            >
              {t('select')}
            </CommonButton>
            {member.validationError && (
              <Tooltip
                variant={'danger'}
                style={{
                  marginLeft: 'var(--s1)',
                }}
                contentStyle={{
                  minWidth: toRemStr(350),
                  right: 0,
                }}
              >
                {member.validationError}
              </Tooltip>
            )}
          </PatientBookingMemberItem>
        ))}
      </div>
    </>
  );
};
