import React, { ReactElement } from 'react';
import { InfoMessageWrapper } from '.';

interface Props {
  icon: React.ReactNode;
  text: string;
  type:
    | 'error'
    | 'error-alt'
    | 'info'
    | 'info-alt'
    | 'success'
    | 'success-alt'
    | 'warning'
    | 'warning-alt';
  style?: React.CSSProperties;
}

export default function InfoMessage({
  type,
  icon,
  text,
  style,
}: Props): ReactElement {
  return (
    <InfoMessageWrapper type={type} style={style}>
      <div className="icon">{icon}</div>
      <div className="text">{text}</div>
    </InfoMessageWrapper>
  );
}
