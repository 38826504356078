import { AxiosResponse } from 'axios';
import { usePrimaryResourceType } from 'hooks/usePrimaryResourceType';
import { range } from 'lodash';
import { useQueryWaitingList } from 'query/waitingList';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { WaitingListResponse } from 'services/waitingList';
import { DateOnlyISOString } from 'utils/dateUtil';
import { useWaitingListMemberId } from './useWaitingListMemberId';

export type WaitingListFormItem = {
  date: DateOnlyISOString | null;
  hourFrom: number | null;
  hourTo: number | null;
};
export type WaitingListFormItemFilled = {
  [Key in keyof WaitingListFormItem]: NonNullable<WaitingListFormItem[Key]>;
};

export type WaitingListFormValues = {
  list: WaitingListFormItem[];
};

// react-hook-form works bad with undefined values
export const emptyRow = (): WaitingListFormItem => ({
  date: null,
  hourFrom: null,
  hourTo: null,
});

export const waitingListItemCount = 5;
export const getFormEmptyInit = (): WaitingListFormValues => ({
  list: range(0, waitingListItemCount).map(emptyRow),
});

export const useGetWaitingListQueryData = () => {
  const memberId = useWaitingListMemberId();
  const { primaryResourceType, isLoading: isResourceTypeFetching } =
    usePrimaryResourceType();
  const { isFetching: isWaitingListFetching, data } = useQueryWaitingList(
    {
      memberId: memberId,
      resourceTypeId: primaryResourceType?.id || '',
    },
    { enabled: !isResourceTypeFetching },
  );
  return { data, isFetching: isResourceTypeFetching || isWaitingListFetching };
};

export const useLoadWaitingListForm = () => {
  const { reset } = useFormContext<WaitingListFormValues>();
  const { data, isFetching } = useGetWaitingListQueryData();
  useEffect(() => {
    if (data) {
      reset(queryDataToFormInit(data));
    }
  }, [data, reset]);
  return { isFetching };
};

export const queryDataToFormInit = (
  queryData: AxiosResponse<WaitingListResponse>,
) => {
  const list = queryData.data.data || [];
  const nextList: WaitingListFormItem[] = list.map((item) => ({
    date: item.reservation_date,
    hourFrom: item.from_hour,
    hourTo: item.to_hour,
  }));
  if (nextList.length < waitingListItemCount) {
    nextList.push(
      ...range(waitingListItemCount - nextList.length).map(emptyRow),
    );
  }
  return { list: nextList };
};
