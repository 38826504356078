import { Card } from 'components/common';
import { FormActionsStyle } from 'components/common/Forms/Forms.styled';
import { CardLoader } from 'components/common/Loading';
import React from 'react';
import {
  PopupDesc,
  PopupIconWrapper,
  PopupTitle,
} from './ConfirmDialog.styled';

interface InlineConfirmCardProps {
  icon?: React.ReactNode;
  title?: string;
  isLoading?: boolean;
  description?: string | React.ReactNode;
  status?: 'delete' | 'success' | 'warning';
  actions: React.ReactNode[];
  inlineIcon?: boolean;
  compact?: boolean;
  actionsTopMargin?: boolean;
}

export const InlineConfirm: React.FunctionComponent<InlineConfirmCardProps> = ({
  icon,
  title,
  description,
  isLoading,
  status,
  actions,
  inlineIcon,
  children,
  actionsTopMargin,
}) => (
  <Card>
    {Boolean(icon) && (
      <PopupIconWrapper inlineIcon={inlineIcon} status={status}>
        {icon}
      </PopupIconWrapper>
    )}
    {Boolean(title) && <PopupTitle compact>{title}</PopupTitle>}
    {Boolean(description) && <PopupDesc compact>{description}</PopupDesc>}
    {children}
    <FormActionsStyle align="center" topMargin={actionsTopMargin}>
      {actions && actions.map((node) => node)}
    </FormActionsStyle>
    {isLoading && <CardLoader fillWrapper={true} />}
  </Card>
);
