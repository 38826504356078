import { useCallback, useMemo, useState } from 'react';

/**
 * This ensures if underlying array has refreshed the item does too.
 */
export const useSelectFromArrayViaId = <T extends { id: string }>({
  items,
  initial,
}: {
  items?: T[];
  initial?: T;
}) => {
  const [selectedId, setSelectedId] = useState(initial?.id);
  const selected = useMemo(
    () => items?.find((item) => item.id === selectedId),
    [items, selectedId],
  );
  const setSelected = useCallback(
    (selected?: T) => {
      setSelectedId(selected?.id);
    },
    [setSelectedId],
  );
  return {
    selectedId: selected?.id,
    selected,
    setSelectedId,
    setSelected,
  };
};
