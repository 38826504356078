import { UseMutationOptions, useQueryClient } from 'react-query';

import { useSite } from '../context/SiteContext';
import {
  addNewBankHoliday,
  deleteBankHoliday,
  getAllBankHoliday,
  importBankHoliday,
} from '../services/bank_holidays';
import {
  BankHolidayFilters,
  BankHolidayFormValues,
} from '../types/bankHoliday';
import {
  CacheAndStaleTimeForUnlikelyChanged,
  useMutationWithToastError,
  useQueryWithToastError,
} from './queryUtils';

const BANK_HOLIDAYS = 'bank_holidays';
export const useBankHolidays = (filters: BankHolidayFilters) => {
  const { activeSite } = useSite();
  const combinedFilter = {
    siteID: activeSite.id,
    ...filters,
  };
  return useQueryWithToastError(
    [BANK_HOLIDAYS, combinedFilter],
    () => getAllBankHoliday(combinedFilter),
    { ...CacheAndStaleTimeForUnlikelyChanged, select: (data) => data?.data },
  );
};

export const useMutateCreateBankHoliday = (
  options?: UseMutationOptions<any, any, BankHolidayFormValues>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<BankHolidayFormValues>(
    [BANK_HOLIDAYS],
    (params) => addNewBankHoliday(params),
    {
      onSettled: () => client.invalidateQueries(BANK_HOLIDAYS),
      ...options,
    },
  );
};

export const useMutateDeleteBankHoliday = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<string>(
    [BANK_HOLIDAYS],
    (params) => deleteBankHoliday(params),
    {
      onSettled: () => client.invalidateQueries(BANK_HOLIDAYS),
      ...options,
    },
  );
};

export const useMutateImportBankHoliday = (
  options?: UseMutationOptions<any, any, void>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<void>(
    [BANK_HOLIDAYS],
    () => importBankHoliday(),
    {
      onSettled: () => client.invalidateQueries(BANK_HOLIDAYS),
      ...options,
    },
  );
};
