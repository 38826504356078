import Axios from 'configs/Axios';
import {
  ActiveSiteResponseData,
  GetSiteByNameResponse,
  SiteData,
  SiteFormValues,
  SiteResponseData,
  SitesFilters,
} from 'types/sites';

export const getAllSites = (filters?: SitesFilters) =>
  Axios.get<SiteResponseData>('/site', {
    params: filters,
  });

export const getSiteByName = (name: string) =>
  Axios.get<GetSiteByNameResponse>(`/site/byName/${name}`);

export const getSiteById = (id: string) => Axios.get<SiteData>(`/site/${id}`);

export const addNewSite = (data: SiteFormValues) =>
  Axios.post<SiteResponseData>('/site', data);

export const updateSite = (data: SiteFormValues) => Axios.put('/site', data);

export const deleteSite = (id: string) => Axios.delete(`/site/${id}`);

export const getActiveTrustSite = () =>
  Axios.get<ActiveSiteResponseData>(`/activeTrust/site`);
