import React, { forwardRef, ReactElement } from 'react';
import {
  CommonInputHelperText,
  LabeledWrapper,
  LegendLabel,
  Required,
  StyledTextArea,
} from './Forms.styled';
import { FontBasedSize } from '../../../styles/fontsStyleUtils';

type GlobalTextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  FontBasedSize & {
    label?: string;
    placeholder?: string;
    hasError?: boolean;
    helperText?: string;
    disabled?: boolean;
    required?: boolean;
  };

export default forwardRef<HTMLTextAreaElement, GlobalTextAreaProps>(
  function TextArea(
    {
      label,
      placeholder,
      hasError,
      helperText,
      disabled,
      required,
      fontBasedSize,
      ...rest
    },
    ref,
  ): ReactElement {
    return (
      <LabeledWrapper>
        <StyledTextArea
          ref={ref}
          placeholder={placeholder}
          disabled={disabled}
          hasError={hasError}
          fontBasedSize={fontBasedSize}
          {...rest}
        />
        {Boolean(label) && (
          <LegendLabel>
            {label} {required && <Required>*</Required>}
          </LegendLabel>
        )}
        <CommonInputHelperText hasError={hasError} disabled={disabled}>
          {Boolean(helperText) && !disabled && helperText}
        </CommonInputHelperText>
      </LabeledWrapper>
    );
  },
);
