import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import ThemeProvider from 'styles/ThemeProvider';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthProvider } from './context/AuthContext';
import { RoleProvider } from 'context/RoleContext';
import { GlobalAttributesProvider } from './context/GlobalAttributesContext';
import { Toaster } from 'react-hot-toast';
import ErrorBoundary from './containers/ErrorBoundary';
import { AppRoutes } from './routes/AppRoutes';
import { SiteProvider } from './context/SiteContext';
import { PopupProvider } from './context/PopupContext';
import AppRouter from 'containers/AppRouter';
import { PatientProvider } from 'context/patient/PatientContext';
import { MockBEAuthRolePick } from './mirage/utilComponents/MockBEAuthRolePick';
import { makeAndSeedMirageServer } from './mirage/server';
import { queryClient } from './query/QueryClient';
import { initGA } from './utils/ga';
import { AnalyticsProvider } from './context/AnalyticsContext';
import { Suspense } from 'react';
import { Loading } from './components/common/Loading';

const environment = process.env.NODE_ENV;

const isMockBE = process.env.REACT_APP_MOCKED_BACKEND;
const showMockBERoleSwitch =
  process.env.REACT_APP_SHOW_MOCKED_BACKEND_ROLE_SWITH;

const isMockBEEnabledOrInTestEnvironment = () =>
  isMockBE || environment === 'test';

if (environment !== 'production' && isMockBEEnabledOrInTestEnvironment()) {
  makeAndSeedMirageServer({ environment });
}

initGA();

const App = () => {
  return (
    // Suspense for lazy loading language files
    <Suspense fallback={<Loading />}>
      <HelmetProvider>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <Toaster />
            <PopupProvider>
              <AppRouter>
                <Helmet title="Sangix Appointments" />
                <AuthProvider>
                  <SiteProvider>
                    <AnalyticsProvider>
                      <RoleProvider>
                        {showMockBERoleSwitch && <MockBEAuthRolePick />}
                        <GlobalAttributesProvider>
                          <PatientProvider>
                            <ErrorBoundary>
                              <AppRoutes />
                            </ErrorBoundary>
                          </PatientProvider>
                        </GlobalAttributesProvider>
                      </RoleProvider>
                    </AnalyticsProvider>
                  </SiteProvider>
                </AuthProvider>
              </AppRouter>
            </PopupProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </HelmetProvider>
    </Suspense>
  );
};

export default App;
