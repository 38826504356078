import React from 'react';
import {
  FormHeader,
  FormTitle,
} from '../../../components/common/Forms/Forms.styled';
import styled from 'styled-components';

interface ICalledAppointmentBodyProps {
  label: () => void;
  icon: React.ReactNode;
}

const HeaderStyled = styled(FormHeader)`
  margin-bottom: var(--s6);

  > * {
    font-size: var(--fontlg);
  }

  > svg {
    margin-right: var(--s4);
  }
`;

export const QueuedProcessingAppointmentHeading: React.FunctionComponent<
  ICalledAppointmentBodyProps
> = ({ label, icon }) => {
  return (
    <HeaderStyled
      style={{
        marginBottom: 'var(--s3)',
      }}
    >
      {icon}
      <FormTitle>{label}</FormTitle>
    </HeaderStyled>
  );
};
