import {
  KioskEntryInput,
  KioskEntrySubmit,
  KioskInputGroup,
} from '../Kiosk.styled';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useForm } from 'react-hook-form';
import { KeyboardEventHandler } from 'react';
import { toRemStr } from '../../../../utils/helpers';

export const KioskTextInput = ({
  onInputSubmit,
  maxLength,
  minLength,
  keyDownHandler,
  inputHint,
  uppercase = true,
}: {
  onInputSubmit: (input: string) => any;
  maxLength: number;
  minLength: number;
  keyDownHandler?: KeyboardEventHandler<HTMLInputElement>;
  inputHint: string;
  uppercase?: boolean;
}) => {
  const { register, watch, handleSubmit } = useForm<{
    input: string;
  }>();
  const { t } = useCommonTranslation();
  const inputProps = register('input', {
    maxLength,
    minLength,
    shouldUnregister: false,
  });
  const searchString = watch('input', '');
  const onInitialsSubmit = handleSubmit((values, event) => {
    event?.preventDefault();
    if (values.input.length >= minLength && values.input.length <= maxLength)
      onInputSubmit(values.input);
  });

  return (
    <>
      <div>{inputHint}</div>
      <form onSubmit={onInitialsSubmit}>
        <KioskInputGroup
          style={maxLength > 6 ? { flexDirection: 'column' } : undefined}
        >
          <KioskEntryInput
            style={uppercase ? { textTransform: 'uppercase' } : undefined}
            {...inputProps}
            type="text"
            maxLength={maxLength}
            minLength={minLength}
            onKeyDown={keyDownHandler}
          />
          {searchString?.length >= minLength &&
            searchString?.length <= maxLength && (
              <KioskEntrySubmit
                style={
                  maxLength > 6
                    ? {
                        minHeight: toRemStr(150),
                        maxHeight: toRemStr(150),
                      }
                    : undefined
                }
                type="submit"
              >
                {t('submit')}
              </KioskEntrySubmit>
            )}
        </KioskInputGroup>
      </form>
      <div>{t('kiosk-button-hint')}</div>
    </>
  );
};
