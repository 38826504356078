import { AxiosError, AxiosResponse } from 'axios';
import React, { ReactElement, useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { GetSiteByNameResponse, SiteByNameModel } from 'types/sites';
import { errorToast } from 'utils/toast';
import { AutoCompleteWrapper, FormError } from './Forms.styled';
import { useDebounce } from 'react-use';

interface Props {
  fetchReq: (name: string) => Promise<AxiosResponse<GetSiteByNameResponse>>;
  setFormValue: (value: any) => void;
  placeholder: string;
  name: string;
  searchFunc: any;
  renderSuggestion: any;
  minChars?: number;
  error?: string;
  disabled?: boolean;
  defaultValue?: string;
}

export default function AutoComplete({
  fetchReq,
  minChars,
  searchFunc,
  setFormValue,
  renderSuggestion,
  error,
  placeholder,
  name,
  disabled,
  defaultValue = '',
}: Props): ReactElement {
  const [searchValue, setSearchValue] = useState<string>(defaultValue);
  const inputProps = {
    name,
    id: name,
    placeholder,
    disabled,
    value: searchValue,
    autoComplete: 'none',
    onChange: (e: any, { newValue }: any) => {
      setFormValue(undefined);
      setSearchValue(newValue);
    },
  };

  const [suggestions, setSuggestions] = useState<SiteByNameModel[] | []>([]);
  const [suggestionRequestedValue, setSuggestionRequestedValue] =
    useState<string>();
  const [, cancel] = useDebounce(
    () => {
      if (suggestionRequestedValue) {
        fetchReq(suggestionRequestedValue.trim())
          .then(({ data }) => {
            setSuggestions(data || []);
          })
          .catch((err: AxiosError) => {
            errorToast(err.response?.data);
          });
      }
    },
    500,
    [suggestionRequestedValue, fetchReq, setSuggestions],
  );
  useEffect(() => {
    return () => {
      cancel();
    };
  }, [cancel]);

  return (
    <AutoCompleteWrapper hasError={Boolean(error)}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={({ value }) => {
          setSuggestionRequestedValue(value);
        }}
        onSuggestionsClearRequested={() => {
          setSuggestions([]);
        }}
        shouldRenderSuggestions={(value) => {
          return minChars ? value.trim().length >= minChars : true;
        }}
        getSuggestionValue={searchFunc}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={(event, { suggestion }) => {
          setFormValue(suggestion);
        }}
      />
      {Boolean(error) && <FormError>{error}</FormError>}
    </AutoCompleteWrapper>
  );
}
