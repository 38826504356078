import { useEffect, useState } from 'react';
import { getServerTime } from '../utils/dateUtil';

const DEFAULT_UPDATE_INTERVAL = 500;

const useTime = ({
  dateConstructor,
  updateInterval,
}: {
  dateConstructor: () => Date;
  updateInterval?: number;
}) => {
  updateInterval = updateInterval || DEFAULT_UPDATE_INTERVAL;
  const [time, setTime] = useState(dateConstructor());
  useEffect(() => {
    const intervalId = setInterval(
      () => setTime(dateConstructor!()),
      updateInterval,
    );
    return () => clearInterval(intervalId);
  }, [updateInterval, setTime, dateConstructor]);
  return time;
};

export const useCurrentTime = ({
  updateInterval,
}: { updateInterval?: number } = {}) => {
  return useTime({ updateInterval, dateConstructor: () => new Date() });
};

export const useCurrentServerTime = ({
  updateInterval,
}: { updateInterval?: number } = {}) => {
  return useTime({ updateInterval, dateConstructor: getServerTime });
};
