import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, PromptProps } from 'react-router-dom';

/**
 * Works in same way as React Router DOM Prompt element
 * but adds the browser prompt dialogue before closing
 * or reloading the page as well.
 */
export const PromptWithBeforeUnload = ({
  when,
  message,
}: Partial<PromptProps>) => {
  const { t } = useTranslation();

  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (when) {
        // prevent closing or reload browser when prompt should be displayed
        // see https://developer.mozilla.org/en-US/docs/Web/API/BeforeUnloadEvent
        e.preventDefault();
        e.returnValue = ' ';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [when]);

  return (
    <Prompt
      when={when}
      message={message ? message : t('prompt-unsaved-changes')}
    />
  );
};
