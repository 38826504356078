import { ISortAndPaginatedFilters } from '../../types/table';
import { ItemsResponse } from '../../types/ItemsWithCountResponse';
import { useMemo } from 'react';

export const usePaginateResults = <D>({
  data,
  filters,
}: {
  data: ItemsResponse<D>;
  filters: ISortAndPaginatedFilters;
}) => {
  return useMemo(() => {
    return {
      data:
        data.data?.slice(filters.offset, filters.offset + filters.limit) ?? [],
    };
  }, [data, filters]);
};
