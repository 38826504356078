import { ReactElement, useEffect, useMemo } from 'react';
import { FaHospital, FaUsers } from 'react-icons/fa';
import { useEffectOnce } from 'react-use';

import { Card } from '../../../components/common';
import { CardLoader } from '../../../components/common/Loading';
import { useSite } from '../../../context/SiteContext';
import {
  PatientBookingStage,
  usePatientBooking,
  usePatientBookingGuidelinesStage,
  usePatientBookingMemberStage,
  usePatientBookingQuestionConfig,
  usePatientBookingSiteStage,
} from '../../../context/patient/modules/booking';
import { PatientBookingAnswerQuestion } from './PatientBookingAnswerQuestion';
import { PatientBookingGuidelines } from './PatientBookingGuidelines';
import { PatientBookingPickMember } from './PatientBookingPickMember';
import { PatientBookingPickSite } from './PatientBookingPickSite';
import { PatientBookingSuccess } from './PatientBookingSuccess';
import { PatientBookingPickAppt } from './datePick/PatientBookingPickAppt';
import { PatientBookingPickDay } from './datePick/PatientBookingPickDay';
import { PatientBookingPickHour } from './datePick/PatientBookingPickHour';

export type PatientBookingLayout = {
  Component: React.FunctionComponent;
  standalone?: boolean;
  icon?: ReactElement;
  showStageBar?: boolean;
  showButtonBar?: boolean;
};

const commonLayout: Omit<PatientBookingLayout, 'Component'> = {
  showStageBar: true,
  showButtonBar: true,
};

const stageToLayoutMap: {
  [key in PatientBookingStage]: PatientBookingLayout;
} = {
  site: {
    Component: () => {
      const { updateBooking, setBooking } = usePatientBooking();
      const { showStage, defaultSiteId } = usePatientBookingSiteStage();

      useEffectOnce(() => {
        if (showStage) {
          // clear site ID on component display
          setBooking((prevBooking) => ({
            ...prevBooking,
            siteId: undefined,
          }));
        } else {
          // go to next stage if we shouldn't show this stage
          updateBooking({ stage: 'guidelines', siteId: defaultSiteId });
        }
      });
      return showStage ? <PatientBookingPickSite /> : null;
    },
    icon: <FaHospital />,
  },
  guidelines: {
    Component: () => {
      const { updateBooking } = usePatientBooking();
      const { isLoading, guidelines, showStage } =
        usePatientBookingGuidelinesStage();

      useEffect(() => {
        if (showStage === false) {
          // go to next stage if we shouldn't show this stage
          updateBooking({ stage: 'member' });
        }
      }, [showStage, updateBooking]);
      if (isLoading) {
        return (
          <Card>
            <CardLoader style={{ top: 'var(--s3)' }} />
          </Card>
        );
      } else if (showStage && guidelines) {
        return <PatientBookingGuidelines guidelines={guidelines} />;
      } else {
        return null;
      }
    },
    standalone: true,
  },
  member: {
    Component: () => {
      const { updateBooking, setBooking } = usePatientBooking();
      const { showStage, defaultMemberId } = usePatientBookingMemberStage();

      useEffectOnce(() => {
        if (showStage) {
          // clear member ID on component display
          setBooking((prevBooking) => ({
            ...prevBooking,
            memberId: undefined,
          }));
        } else {
          // go to next stage if we shouldn't show this stage
          updateBooking({ stage: 'question', memberId: defaultMemberId });
        }
      });
      return showStage ? <PatientBookingPickMember /> : null;
    },
    icon: <FaUsers />,
    showButtonBar: true,
  },
  question: {
    Component: () => {
      const { updateBooking, setBooking } = usePatientBooking();
      const { activeSite } = useSite();
      const config = usePatientBookingQuestionConfig(activeSite);

      useEffectOnce(() => {
        if (config.enabled) {
          // clear answer on component display
          setBooking((prevBooking) => ({
            ...prevBooking,
            questionAnsweredState: 'PENDING',
          }));
        } else {
          // go to next stage if we shouldn't show this stage
          updateBooking({
            stage: 'day',
            questionAnsweredState: 'NOT_CONFIGURED',
          });
        }
      });
      return config.enabled ? (
        <PatientBookingAnswerQuestion {...config} />
      ) : null;
    },
    standalone: true,
  },
  day: {
    ...commonLayout,
    Component: PatientBookingPickDay,
  },
  hour: {
    ...commonLayout,
    Component: PatientBookingPickHour,
  },
  appt: {
    ...commonLayout,
    Component: PatientBookingPickAppt,
  },
  success: {
    Component: PatientBookingSuccess,
    standalone: true,
  },
};

export const usePatientBookingLayout = (stage: PatientBookingStage) => {
  return useMemo(() => {
    const { Component, ...rest } = stageToLayoutMap[stage];
    return {
      ...rest,
      element: <Component />,
    };
  }, [stage]);
};
