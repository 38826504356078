import styled from 'styled-components';
import { toRem } from 'utils/helpers';

export const Wrapper = styled.div`
  margin-bottom: var(--s3);
  position: relative;
  .ql-editor {
    min-height: ${toRem(640)}rem;
  }
  .ql-toolbar.ql-snow {
    border: none;
    & + .ql-container.ql-snow {
      border: ${toRem(2)}rem solid var(--textdisabled);
      border-radius: var(--s3);
    }
  }

  .ql-container.ql-snow.ql-disabled {
    background-color: var(--disabled);
  }
`;

export const QuillPreview = styled.div`
  .ql-container.ql-snow {
    border: none;
  }
  .ql-editor {
    padding: 0;
  }
  hr {
    margin: var(--s1) var(--s2);
    border: none;
    border-top: ${toRem(1)}rem solid var(--disabled);
  }

  .static-text {
    .ql-snow {
      color: var(--textsecondary);
      font-size: ${toRem(12)}rem;
    }
    .ql-editor {
      padding-top: 0;
    }
  }
`;
