import { useCallback, useState } from 'react';
import { makeCSVBlobWithHeader } from '../../utils/csv';
import { errorToast } from '../../utils/toast';
import { downloadBinary } from '../../utils/downloadBinary';

export const getCSVHeader = <D>(key: keyof D, label: string) => {
  return { key, label };
};

export const useGenerateAndDownloadCSV = <D extends object>({
  headers,
  rows,
  filename,
}: {
  headers: Array<{
    label: string;
    key: keyof D;
  }>;
  rows: D[];
  filename: string;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const generateAndDownload = useCallback(async () => {
    setIsLoading(true);
    try {
      const blob = await makeCSVBlobWithHeader(headers, rows);
      downloadBinary(blob, filename);
    } catch (error) {
      errorToast(error);
    } finally {
      setIsLoading(false);
    }
  }, [headers, rows, filename]);
  return {
    isLoading,
    generateAndDownload,
  };
};
