import { CommonButton } from 'components/common/Forms/Button';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import { cssIf } from 'styles/partials/cssIf';
import { h3, h4, small, stripButton } from 'styles/Typography';
import { toRem, toRemStr } from 'utils/helpers';
import { LabelPositionType } from './Select';
import {
  FontBasedSize,
  getFontSize,
  getHeightBasedOnFontSize,
  getMaxMenuHeightBasedOnFontSize,
  getMinHeightBasedOnFontSize,
  heightBasedOnFontSize,
  LABEL_BEFORE,
} from '../../../styles/fontsStyleUtils';

export const LabeledWrapper = styled.label`
  color: var(--textsecondary);
  position: relative;
  /* margin-bottom: var(--s3); */
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const LegendLabel = styled.span`
  position: absolute;
  display: inline-block;
  color: var(--primary);
  font-size: var(--caption);
  padding: 0 var(--s1);
  background: var(--white);
  line-height: 1;
  top: calc(-1 * var(--s3) / 2);
  left: var(--s2);
  transition: 0.2s ease color;
`;
export const StyledTextArea = styled.textarea<
  {
    readonly hasError?: boolean;
  } & FontBasedSize
>`
  line-height: 1.3;
  resize: none;
  padding: var(--s1) var(--s2);
  ${getFontSize()}
  outline: none;
  color: inherit;
  width: 100%;
  border-radius: var(--s2);
  min-height: var(--s3);
  height: var(--s7);
  border: ${toRem(2)}rem solid var(--primary);
  background: var(--white);
  transition: 0.2s ease border-color;

  &::placeholder {
    color: var(--textdisabled);
  }

  &:hover,
  &:focus {
    border-color: var(--yellowaction);

    & + ${LegendLabel} {
      color: var(--yellowaction);
    }
  }

  &:disabled {
    border-color: var(--textdisabled);
    cursor: not-allowed;

    &:hover,
    &:focus {
      border-color: var(--textdisabled);

      & + ${LegendLabel} {
        color: var(--textdisabled);
      }
    }

    & + ${LegendLabel} {
      color: var(--textdisabled);
    }
  }

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: var(--rednegative);

      &:hover,
      &:focus {
        border-color: var(--rednegative);

        & + ${LegendLabel} {
          color: var(--rednegative);
        }
      }

      & + ${LegendLabel} {
        color: var(--rednegative);
      }
    `}
`;

export const BasicLabelStyle = styled.label`
  white-space: nowrap;
  padding: 0 var(--s1);
`;

export const ExpandingLabelStyle = styled(BasicLabelStyle)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 var(--s1);

  :hover {
    overflow: visible;
    z-index: 1;
    background: var(--white);
    padding-right: var(--s1);
  }
`;

export const UnderlineLinkStyle = styled.a`
  text-decoration: underline;
  white-space: nowrap;
  padding: 0 var(--s1);
  cursor: pointer;
  color: var(--textprimary);
`;

export const CommonInputLabel = styled.label<{
  readonly fixedLabelWidth?: string;
  readonly labelBackgroundColor?: string;
}>`
  position: absolute;
  padding: 0 var(--s1);
  top: calc(-1 * var(--s2));
  left: var(--s2);
  color: var(--primary);
  font-size: var(--caption);
  background-color: ${({ labelBackgroundColor }) =>
    labelBackgroundColor || 'var(--white)'};
  line-height: 1;
  transition: 0.2s ease color;
  white-space: nowrap;
  z-index: 4;
  ${({ fixedLabelWidth }) =>
    fixedLabelWidth &&
    css`
      min-width: ${fixedLabelWidth};
      text-align: right;
      margin-right: var(--s1);
    `}
`;

export const StyledSelect = styled(Select)<
  {
    readonly hasError?: boolean;
    readonly hasIcon?: boolean;
    readonly isDisabled?: boolean;
    readonly isMulti?: boolean;
    readonly dropdownMenuStyleOverride?: string;
  } & FontBasedSize
>`
  width: 100%;

  ${({ hasError, isDisabled }) =>
    hasError &&
    css`
      & + ${CommonInputLabel} {
        color: ${isDisabled ? 'var(--textdisabled)' : 'var(--rednegative)'};
      }
    `}
  &:hover {
    .common__control {
      border-color: ${({ hasError, isDisabled }) =>
        hasError
          ? 'var(--rednegative)'
          : isDisabled
          ? 'var(--textdisabled)'
          : 'var(--yellowaction)'};
      box-shadow: none;
    }

    & + ${CommonInputLabel} {
      color: ${({ hasError, isDisabled }) =>
        isDisabled
          ? 'var(--textdisabled)'
          : hasError
          ? 'var(--rednegative)'
          : 'var(--yellowaction)'};
    }
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;

      & + ${CommonInputLabel} {
        color: var(--textdisabled);
      }
    `}
  .common__control {
    margin: 0;
    padding: 0;
    min-height: unset;
    box-shadow: none;
    border: ${toRem(2)}rem solid
      ${({ hasError, isDisabled }) =>
        isDisabled
          ? 'var(--textdisabled)'
          : hasError
          ? 'var(--rednegative)'
          : 'var(--primary)'};
    border-radius: var(--s2);
    ${heightBasedOnFontSize};
    align-items: baseline;
    cursor: pointer;
    z-index: 4;

    &:hover {
      border-color: ${({ hasError, isDisabled }) =>
        isDisabled
          ? 'var(--textdisabled)'
          : hasError
          ? 'var(--rednegative)'
          : 'var(--yellowaction)'};
      box-shadow: none;
    }

    &--is-disabled {
      background: var(--white);
    }
  }

  .common__dropdown-indicator {
    padding: 0 var(--s1) 0 0;
  }

  .common__value-container {
    flex-wrap: nowrap;
    align-items: center;
    width: 0 !important;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 100%;
    padding: ${({ hasIcon }) =>
      hasIcon ? '0 var(--s2) 0 var(--s4)' : '0 var(--s2)'};

    & > div {
      margin: 0;
    }

    & > div.common__multi-value {
      margin-right: var(--s1);
    }
  }

  .common__placeholder {
    color: var(--textdisabled);
    font-size: var(--body1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .common__single-value {
    ${getFontSize()};
    color: var(--textsecondary);
  }

  .common__menu {
    margin: 0;
    border-radius: 0 0 var(--s2) var(--s2);
    box-shadow: var(--shadow-3);
    padding: 0;
    z-index: 5;

    &.canScrollDown {
      &::after {
        content: '';
        pointer-events: none;
        height: var(--s5);
        width: 100%;
        position: absolute;
        display: block;
        left: 0;
        z-index: 1;
        background: linear-gradient(
          180deg,
          var(--white) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        bottom: 0;
        transform: rotate(180deg);
        border-radius: var(--s2) var(--s2) 0 0;
      }
    }

    & > div {
      padding: 0;
      ${getMaxMenuHeightBasedOnFontSize};
      ${({ dropdownMenuStyleOverride }) =>
        dropdownMenuStyleOverride ? dropdownMenuStyleOverride : ''};
    }

    .common__menu-list {
      padding-bottom: var(--s2);
    }
  }

  .common__option {
    padding: var(--s1) var(--s2);
    transition: 0.2s ease color, 0.2s ease background-color;
    cursor: pointer;
    ${getMinHeightBasedOnFontSize};
    /* display: flex; */
    line-height: 1;
    display: block;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    ${({ isMulti }) =>
      isMulti &&
      css`
        padding: var(--s1) var(--s2);

        &--is-selected {
          color: var(--white);

          label div {
            color: inherit;
          }
        }
      `}
    &--is-focused {
      background-color: var(--yellowmuted);
    }

    &--is-selected {
      background-color: var(--yellowaction);
    }
  }

  .common__indicators {
  }

  .common__clear-indicator {
    padding: 0;
  }
`;
export const AutoCompleteWrapper = styled.div<{ readonly hasError?: boolean }>`
  display: flex;
  position: relative;
  margin-bottom: var(--s3);

  & > div {
    flex: 1;
    display: flex;
  }

  .react-autosuggest__input {
    padding: 0 var(--s2);
    height: var(--s4);
    flex: 1;
    align-items: center;
    border: ${toRem(2)}rem solid
      ${({ hasError }) => (hasError ? 'var(--rednegative)' : 'var(--primary)')};
    border-radius: var(--s2);
    background: white;
    color: var(--textsecondary);
    outline: none;
    transition: 0.2s ease border-color;
    z-index: 2;

    &:disabled {
      color: var(--textdisabled);
      border-color: var(--textdisabled);

      &:hover {
        cursor: not-allowed;
        border-color: var(--textdisabled);
      }
    }

    &:hover,
    &:focus {
      border-color: var(--yellowaction);
    }

    &::placeholder {
      color: var(--textdisabled);
    }
  }

  .react-autosuggest__suggestions-container {
    display: none;

    &--open {
      z-index: 1;
      display: block;
      position: absolute;
      top: calc(100% - var(--s2));
      padding: var(--s3) 0 var(--s2);
      border-radius: var(--s2);
      left: 0;
      width: 100%;
      background: var(--white);
      box-shadow: var(--shadow-3);
    }
  }

  .react-autosuggest__suggestions-list {
    max-height: ${toRem(184)}rem;
    overflow-y: auto;
  }

  .react-autosuggest__suggestion {
    padding: var(--s1) var(--s2);
    font-size: var(--body1);
    line-height: 1;
    color: var(--textsecondary);
    cursor: pointer;

    &--highlighted {
      color: var(--white);
      background-color: var(--yellowaction);
    }
  }
`;
export const FormGroup = styled.div``;
export const FormGroupTitle = styled.h4`
  ${h4};
  margin-bottom: var(--s3);
`;
export const FormColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  & > div {
    flex: 1;
    margin-bottom: var(--s2);

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  gap: var(--s3);

  & > div {
    flex: 1;
  }
`;

export const DialogFormRow = styled.div`
  display: flex;
  margin-bottom: var(--s3);

  & > div {
    flex: 1;
    margin-right: var(--s3);

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ButtonSetStyle = styled.div`
  justify-content: center;
  display: flex;
  row-gap: var(--s2);
  column-gap: var(--s3);
`;

export const FormActionsStyle = styled(FormRow)<{
  readonly align?: 'left' | 'center' | 'right';
  readonly buttonSize?: 'standard' | 'auto';
  readonly topMargin?: boolean;
}>`
  justify-content: ${({ align }) =>
    align === 'center'
      ? 'center'
      : align === 'left'
      ? 'flex-start'
      : 'flex-end'};

  flex-wrap: wrap;
  gap: var(--s2) var(--s3);

  ${cssIf('topMargin')`margin-top: var(--s3);`}
  & > * {
    ${({ buttonSize }) =>
      buttonSize === 'auto' ? '' : `min-width: ${toRem(120)}rem`};
  }
`;

export const FormError = styled.span`
  position: absolute;
  font-size: var(--caption);
  line-height: var(--s3);
  padding: 0 ${toRem(10)}rem;
  color: var(--rednegative);
  bottom: calc(-1 * var(--s3));
  left: 0;
`;

// Start Input UI
export const CommonInputIcon = styled.div<{ readonly disabled?: boolean }>`
  position: absolute;
  left: ${toRem(12)}rem;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
  pointer-events: none;
  font-size: var(--body1);
  color: ${({ disabled }) =>
    disabled ? 'var(--textdisabled)' : 'var(--textsecondary)'};
`;
export const CommonInputLabelWrapper = styled.div<
  {
    readonly labelPosition: LabelPositionType;
    readonly disabled?: boolean;
  } & FontBasedSize
>`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  width: 100%;
  ${({ labelPosition }) =>
    labelPosition === LABEL_BEFORE &&
    css`
      ${CommonInputLabel} {
        position: static;
        color: var(--textprimary);
        margin-top: var(--s2);
        max-height: ${toRem(24)}rem;
        ${getFontSize()}
      }
    `}
`;
export const InfoLabelTextValueStyle = styled.div<{
  addStyle?: string;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 var(--s1);
  font-weight: 500;

  :hover {
    overflow: visible;
    z-index: 1;
    background: var(--white);
    padding-right: var(--s1);
  }
`;
type CommonInputWrapperProps = { flexWidth?: boolean; width?: string };
export const CommonInputWrapper = styled.div<
  CommonInputWrapperProps & FontBasedSize
>`
  display: flex;
  position: relative;
  font-size: ${({ fontBasedSize }) =>
    fontBasedSize ? `var(${fontBasedSize})` : `var(--body1)`};
  color: var(--textPrimary);
  margin-bottom: var(--s2);
  min-width: ${({ width }) => width || toRemStr(160)};
  ${cssIf('flexWidth')`
    min-width: auto;
    flex-shrink: 1;
    flex-grow: 1;
  `}
`;
export const CommonInputHelperWrapper = styled.div<{
  readonly noSpaceForHelperText?: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: ${({ noSpaceForHelperText }) =>
    noSpaceForHelperText ? 'unset' : `${toRem(56)}rem`};
`;

export const CommonInputHelperText = styled.span<{
  readonly hasError?: boolean;
  readonly disabled?: boolean;
}>`
  color: ${({ hasError, disabled }) =>
    disabled
      ? 'var(--textdisabled)'
      : hasError
      ? 'var(--rednegative)'
      : 'var(--textsecondary)'};
  font-size: var(--caption);
  padding: 0 ${toRem(10)}rem;
  line-height: var(--s3);
  width: 100%;
  pointer-events: none;
  transition: 0.2s ease color;
  margin-bottom: var(--s2);
  min-height: var(--s3);
`;
export const StyledCommonInput = styled.input<
  {
    readonly hasError?: boolean;
    hasIcon?: boolean;
    autocomplete?: string;
    hideNativeDate?: boolean;
  } & FontBasedSize
>`
  display: block;
  flex: 1;
  outline: none;
  background: var(--white);
  border: ${toRem(2)}rem solid var(--primary);
  border-radius: var(--s2);
  min-height: var(--s4);
  padding: ${({ hasIcon }) =>
    hasIcon ? '0 var(--s2) 0 var(--s4)' : '0 var(--s2)'};
  transition: 0.2s ease border-color;
  ${getFontSize()};
  ${({ fontBasedSize }) =>
    fontBasedSize ? 'padding-bottom: 2px; padding-top:2px;' : ''}
  color: var(--textsecondary);
  width: 100%;

  &:hover,
  &:focus {
    border-color: var(--yellowaction);

    & + ${CommonInputLabel} {
      color: var(--yellowaction);
    }
  }

  &::placeholder {
    color: var(--textdisabled);
  }

  &:disabled {
    border-color: var(--textdisabled);
    cursor: not-allowed;

    &:hover,
    &:focus {
      border-color: var(--textdisabled);

      & + ${CommonInputLabel} {
        color: var(--textdisabled);
      }
    }

    & + ${CommonInputLabel} {
      color: var(--textdisabled);
    }
  }

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: var(--rednegative);

      &:hover,
      &:focus {
        border-color: var(--rednegative);

        & + ${CommonInputLabel} {
          color: var(--rednegative);
        }
      }

      & + ${CommonInputLabel} {
        color: var(--rednegative);
      }
    `}
  &[type="number"] {
    // hide native styles for number inputs
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }

  ${cssIf('hideNativeDate')`
    &::-webkit-calendar-picker-indicator{
      display: none;
    }
    &[type="date"]::-webkit-input-placeholder{
      visibility: hidden !important;
      display: none;
    }
    &::-webkit-datetime-edit-year-field:not([aria-valuenow]),
    &::-webkit-datetime-edit-month-field:not([aria-valuenow]),
    &::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
      color: transparent;
    }
    appearance: none;
    display: flex;
  `}
`;

export const CommonInputInner = styled.div<{
  readonly isDisabled?: boolean;
  iconRight?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  background-color: ${({ isDisabled }) =>
    isDisabled ? 'var(--textdisabled)' : 'var(--greentercial)'};
  border-radius: var(--s2);
  ${cssIf('iconRight')`
    ${CommonInputIcon} {
      left: auto;
      right: ${toRem(12)}rem;
    }
    ${StyledCommonInput} {
      padding: 0 var(--s4) 0 var(--s2);
    }
  `}
`;

export const TogglePassword = styled.button`
  ${stripButton};
  background-color: transparent;
  border-top-right-radius: var(--s2);
  border-bottom-right-radius: var(--s2);
  padding: 0 var(--s2);
  color: var(--greenaccent);
  height: var(--s4);
  display: flex;
  align-items: center;

  &:disabled {
    color: var(--textdisabled);
    cursor: not-allowed;
  }
`;
// End Input UI

export const DatePickerHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 0;
`;
export const DatePickerHeaderButtons = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: var(--s1);

    &:last-of-type {
      margin-right: 0;
    }
  }
`;
export const DatePickerHeaderDate = styled.p`
  font-size: var(--bodysm);
  display: flex;
  justify-content: center;
  white-space: nowrap;
  margin: 0 var(--s1);
`;
export const DatePickerStylesWrapper = styled.div<{
  sizing?: 'MIN_CONTENT' | 'UNDEFINED';
  popperZIndex?: number;
}>`
  position: relative;

  ${({ sizing }) => (sizing === 'MIN_CONTENT' ? 'width: min-content;' : '')}
  input::placeholder {
    color: var(--textdisabled);
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    font-family: inherit;
    font-size: inherit;
    background-color: var(--white);
    color: inherit;
    border: none;
    display: inline-block;
    position: relative;
    padding: var(--s3);
    border-radius: var(--s2);
    box-shadow: var(--shadow-3);
  }

  .react-datepicker-popper {
    transform: none !important;
    top: var(--s4) !important;
    padding: 0 !important;
    z-index: ${({ popperZIndex }) => (popperZIndex ? popperZIndex : 10)};
    inset: 0 0 auto auto;
  }

  .react-datepicker__header {
    padding: 0;
    border-radius: 0;
    border: none;
    background: none;

    button {
      min-width: ${toRem(24)}rem;
      max-width: ${toRem(24)}rem;
      min-height: ${toRem(24)}rem;
      max-height: ${toRem(24)}rem;
      font-size: var(--bodysm);
    }
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__day-name,
  .react-datepicker__month,
  .react-datepicker__week {
    margin: 0;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    min-width: ${toRem(24)}rem;
    max-width: ${toRem(24)}rem;
    min-height: ${toRem(24)}rem;
    max-height: ${toRem(24)}rem;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: var(--caption);
    border-radius: 50%;
    transition: 0.2s ease background-color, 0.2s ease color;
  }

  .react-datepicker__day--outside-month {
    color: var(--textdisabled);
  }

  .react-datepicker__day-name {
    font-weight: 500;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background-color: var(--greenmuted);
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: var(--primary);

    &:hover {
      background-color: var(--primary);
    }
  }
`;

export const DialogFormWrapper = styled.div`
  position: relative;
  padding-top: var(--s1);
`;
export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--h3);
  margin-bottom: var(--s4);

  svg {
    line-height: 1;
    color: var(--greenaccent);
    margin-right: var(--s2);
  }
`;
export const FormTitle = styled.h3`
  ${h3};
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
`;
export const FormBody = styled.form``;
export const FormGridBody = styled.form`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  row-gap: calc(2 * var(--s3));
`;
export const CloseButton = styled(CommonButton)`
  position: absolute;
  top: 0;
  right: 0;
  font-size: var(--h3);
`;
export const HiddenInput = styled.input`
  visibility: hidden;
  display: none;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
`;
export const CheckBoxesWrapper = styled.div<{
  readonly isVertical?: boolean;
}>`
  display: flex;
  margin-bottom: var(--s3);

  & > div {
    & + div {
      margin-left: var(--s3);
    }
  }

  ${({ isVertical }) =>
    isVertical &&
    css`
      flex-direction: column;

      & > div {
        & + div {
          margin-left: 0;
          margin-top: var(--s3);
        }
      }
    `}
`;
export const CheckLabel = styled.div<
  {
    readonly isRadio?: boolean;
    readonly labelPosition: 'before' | 'after';
  } & FontBasedSize
>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${getHeightBasedOnFontSize()};
  max-width: ${getHeightBasedOnFontSize()};
  min-height: ${getHeightBasedOnFontSize()};
  max-height: ${getHeightBasedOnFontSize()};
  border: ${toRem(1)}rem solid var(--textsecondary);
  border-radius: var(--s1);
  transition: 0.2s ease box-shadow, 0.2s ease border-color,
    0.2s ease background-color;
  background: var(--white);
  color: var(--white);
  ${({ isRadio, labelPosition }) =>
    isRadio &&
    css`
      border-radius: 50%;
      position: relative;

      &::${labelPosition} {
        content: '';
        position: absolute;
        width: ${toRem(10)}rem;
        height: ${toRem(10)}rem;
        border-radius: 50%;
        background-color: var(--redaccent);
        opacity: 0;
        transition: 0.2s ease opacity;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`;
export const CheckboxWrapper = styled.label<
  {
    readonly isDisabled?: boolean;
    readonly onlyInRow?: boolean;
    readonly isToggle?: boolean;
    readonly inSelectList?: boolean;
    readonly labelPosition: 'before' | 'after';
  } & FontBasedSize
>`
  display: inline-flex;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  ${getFontSize('')};

  &:hover {
    ${CheckLabel} {
      box-shadow: var(--shadow-3);
      border-color: var(--redaccent);
    }
  }

  ${HiddenInput}[type="checkbox"] {
    &:checked + ${CheckLabel} {
      background-color: var(--redaccent);
      border-color: var(--redaccent);
    }

    &:disabled + ${CheckLabel} {
      background-color: ${({ isToggle }) =>
        isToggle ? 'var(--textdisabled)' : 'var(--white)'};
      border-color: var(--textdisabled);
    }

    &:disabled:checked + ${CheckLabel} {
      background-color: var(--textdisabled);
      border-color: var(--textdisabled);
    }
  }

  ${HiddenInput}[type="radio"] {
    &:checked + ${CheckLabel} {
      border-color: var(--redaccent);

      &::${({ labelPosition }) => labelPosition} {
        opacity: 1;
      }
    }

    &:disabled + ${CheckLabel} {
      border-color: var(--textdisabled);

      &::${({ labelPosition }) => labelPosition} {
        background-color: var(--textdisabled);
      }
    }
  }

  ${({ isToggle, labelPosition }) =>
    isToggle &&
    css`
      ${CheckLabel} {
        min-width: ${toRem(24)}rem;
        max-width: ${toRem(24)}rem;
        min-height: ${toRem(8)}rem;
        max-height: ${toRem(8)}rem;
        margin-top: ${toRem(5)}rem;
        background-color: var(--textsecondary);
        border: none;
        position: relative;

        &::${labelPosition} {
          content: '';
          width: var(--s3);
          height: var(--s3);
          border-radius: 50%;
          left: 0;
          background-color: var(--white);
          border: ${toRem(1)}rem solid var(--textsecondary);
          position: absolute;
          top: 50%;
          transform: translate3d(0, -50%, 0);
          transition: 0.2s ease background-color, 0.2s ease border-color,
            0.2s ease transform;
        }
      }

      ${HiddenInput}[type="checkbox"] {
        &:checked + ${CheckLabel} {
          background-color: var(--rednegative);

          &::${labelPosition} {
            background-color: var(--redaccent);
            border-color: var(--redaccent);
            /* 6px here indicates to circle width - 2px borders (right + left) */
            transform: translate3d(${toRem(6)}rem, -50%, 0);
          }
        }
      }
    `}
  ${({ onlyInRow }) =>
    onlyInRow &&
    css`
      margin-bottom: ${toRem(24)}rem;
    `}
  ${({ inSelectList }) =>
    inSelectList &&
    css`
      pointer-events: none;
    `}
  align-items: center;
`;
const CheckboxName = styled.div`
  color: var(--textprimary);
  user-select: none;
  padding-top: ${toRem(1)}rem;
`;
export const CheckBoxNameAfter = styled(CheckboxName)`
  margin-left: var(--s2);
`;
export const CheckBoxNameBefore = styled(CheckboxName)`
  margin-right: var(--s2);
`;
export const DeleteAccountBtn = styled.button`
  ${stripButton};
  color: var(--rednegative);
  display: block;
  margin-bottom: var(--s3);
`;
export const InputRangeWrapper = styled.div<{
  showMaxTopRight: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  padding: 0 var(--s1);
  ${({ showMaxTopRight }) =>
    showMaxTopRight
      ? css`
          align-items: flex-end;
          min-height: ${toRem(50)}rem;
          padding-bottom: ${toRem(1)}rem;
        `
      : css`
          align-items: center;
          min-height: ${toRem(25)}rem;
        `}
`;

const rangeZIndex = 1;

export const InputRangePrefixWithSliderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: ${rangeZIndex};
`;

export const InputRangeTopRight = styled.div`
  position: absolute;
  top: 0;
  padding: ${toRemStr(5)};
  color: var(--white);
  background-color: var(--disabled);
  height: ${toRemStr(35)};
  font-size: ${toRemStr(20)};
`;

export const InputRangeTrackHolder = styled.div`
  width: 100%;
  min-width: ${toRem(200)}rem;
  max-width: ${toRem(200)}rem;
  position: relative;

  & > div {
    height: var(--s2);
  }
`;

export const InputRangeTrackTrack = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: var(--disabled);
  position: absolute;
`;

export const InputRangeThumb = styled.div<{
  readonly color?: string;
}>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: flex;
  align-items: baseline;
  justify-content: center;
  border: white 1px solid;
  padding-top: 1.5px;
  box-sizing: border-box;
  font-size: ${toRem(16)}rem;
  font-weight: 500;
  color: var(--white);
  outline: none;
  transition: 0.2s ease transform;
`;

export const InputRangePrefix = styled.h4<{
  readonly prefixType?: 'small' | 'big';
}>`
  ${({ prefixType }) => (prefixType === 'small' ? small : h4)};
  text-transform: uppercase;
  margin-right: var(--s2);
  font-weight: 700;
  text-align: right;
`;

export const CheckboxInputStyled = styled.div`
  display: flex;
  align-content: center;
`;

export const Required = styled.span`
  color: var(--rednegative);
`;
