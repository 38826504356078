import CheckboxInput from 'components/common/Forms/CheckboxInput';
import CommonSelect from 'components/common/Forms/Select';
import {
  TimePlanningDesc,
  TimePlanningItemColor,
  TimePlanningList,
  TimePlanningListItem,
  TimePlanningTitle,
} from 'components/common/Tooltips/Tooltips.styled';
import { TimePlanningTooltipWrapper } from 'components/common/Tooltips/TimePlanningTooltipWrapper';
import React, { Fragment, ReactElement } from 'react';
import { SelectOption } from 'types/common';
import { ResourceType } from 'types/resource-types';
import { TimePlanningWrapper } from './index.styled';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import InfoMessage from '../../../../common/InfoMessage';
import { FaInfoCircle } from 'react-icons/fa';

interface Props {
  resourceTypes: ResourceType[];
  selectedResourceType?: ResourceType;
  setSelectedResourceType: React.Dispatch<
    React.SetStateAction<ResourceType | undefined>
  >;
  setShowOnline: React.Dispatch<React.SetStateAction<boolean>>;
  showOnline: boolean;
  showOnlineMustBeShown: boolean;
}

export default function TimePlanningHeader({
  resourceTypes,
  selectedResourceType,
  setSelectedResourceType,
  showOnline,
  setShowOnline,
  showOnlineMustBeShown,
}: Props): ReactElement {
  const { t } = useCommonTranslation();
  const resourceTypesOptions: SelectOption[] = resourceTypes.map((rt) => ({
    label: rt.name,
    value: rt.id,
  }));

  return (
    <TimePlanningWrapper>
      <CommonSelect
        noSpaceForHelperText
        label={t('calendar-template')}
        placeholder={t('calendar-template')}
        disabled={!resourceTypes}
        options={resourceTypesOptions}
        value={selectedResourceType?.id}
        onChange={(data) => {
          setShowOnline(false);
          setSelectedResourceType(resourceTypes.find((rt) => rt.id === data));
        }}
      />
      {selectedResourceType?.id === resourceTypes[0]?.id &&
        (showOnlineMustBeShown ? (
          <InfoMessage
            type="info"
            icon={<FaInfoCircle />}
            text={t('time-planning-must-show-online')}
            style={{
              marginBottom: 'var(--s2)',
              marginLeft: 'var(--s2)',
            }}
          />
        ) : (
          <CheckboxInput
            name="show_online"
            label={t('show-online-time')}
            style={{ marginLeft: 'var(--s3)' }}
            checked={showOnline}
            onChange={(e) => setShowOnline(e.target.checked)}
          />
        ))}
      <TimePlanningTooltipWrapper minWidth={222}>
        <Fragment>
          <TimePlanningTitle>{t('planning-legend')}</TimePlanningTitle>
          <TimePlanningList>
            <TimePlanningListItem>
              <TimePlanningItemColor
                style={{
                  background: 'var(--yellowaction)',
                }}
              />
              {t('planning-legend-1')}
            </TimePlanningListItem>
            <TimePlanningListItem>
              <TimePlanningItemColor
                style={{
                  background: 'var(--greenaccent)',
                }}
              />
              {t('planning-legend-2')}
            </TimePlanningListItem>
            <TimePlanningListItem>
              <TimePlanningItemColor
                style={{
                  background: 'var(--greenprimary)',
                }}
              />
              {t('planning-legend-3')}
            </TimePlanningListItem>
          </TimePlanningList>
          <TimePlanningDesc>{t('planning-legend-4')}</TimePlanningDesc>
        </Fragment>
      </TimePlanningTooltipWrapper>
    </TimePlanningWrapper>
  );
}
