import { CommonButton } from 'components/common/Forms/Button';
import { useAssignedBookingPatientSites } from 'context/patient/modules/booking';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { useCallback } from 'react';
import { FaTrashAlt } from 'react-icons/fa';

import { useConfirmDialog } from '../../../components/Popup/ConfirmDialog/confirmDialogHook';
import { PATIENT } from '../../../configs/constants';
import { useAuth } from '../../../context/AuthContext';
import { getUserProfile } from '../../../services/userProfile';
import { removeUserRole } from '../../../services/users';
import { PatientSite } from '../../../types/sites';
import { errorToast } from '../../../utils/toast';
import {
  PatientBookingSiteItem,
  PatientBookingSiteItemName,
} from './PatientBooking.styled';

interface Props {
  onSiteSelect: (siteId: string) => void;
}

export const PatientBookingPickSiteAssignedList = ({ onSiteSelect }: Props) => {
  const { t } = useCommonTranslation();
  const { sangixUser, updateAuthUser } = useAuth();
  const { sites } = useAssignedBookingPatientSites();
  const { confirm } = useConfirmDialog();

  const handleSiteRemove = useCallback(
    (site: PatientSite) => {
      confirm({
        onConfirm: async () => {
          try {
            await removeUserRole({
              role_id: PATIENT,
              site_id: site.id,
              user_account_id: sangixUser!.id,
            });
            const { data } = await getUserProfile();
            updateAuthUser({
              sangixUser: data,
            });
          } catch (err) {
            errorToast(err);
          }
        },
        title: t('confirm-remove-patient-site', { name: site.name }),
        cancelButtonText: t('back'),
      });
    },
    [updateAuthUser, confirm, t, sangixUser],
  );

  return (
    <>
      {sites?.map((site) => (
        <PatientBookingSiteItem key={site.id}>
          <PatientBookingSiteItemName>{site.name}</PatientBookingSiteItemName>
          {site.deletable && (
            <CommonButton
              size="auto"
              iconOnly
              variant="danger"
              title={t('remove-department')}
              onClick={() => handleSiteRemove(site)}
            >
              <FaTrashAlt />
            </CommonButton>
          )}
          <CommonButton
            variant="primary"
            onClick={() => {
              onSiteSelect(site.id);
            }}
          >
            {t('select')}
          </CommonButton>
        </PatientBookingSiteItem>
      ))}
    </>
  );
};
