import React, { ReactElement } from 'react';
import { Column } from 'react-table';
import { UserData, UsersFilters, UsersResponseData } from 'types/users';
import { FetchedDataTable } from '../../../components/Table/FetchedDataTable';
import { PaginatedFiltersWithSet } from '../../../hooks/usePaginatedFilters';

type Props = {
  usersData?: UsersResponseData;
  isLoading: boolean;
  isFetching: boolean;
  columns: Column<UserData>[];
  selectedIds?: string[];
} & PaginatedFiltersWithSet<UsersFilters>;

export const CommonPatientsTable = ({
  usersData,
  setFilters,
  ...rest
}: Props): ReactElement => {
  return (
    <FetchedDataTable
      data={usersData}
      onSortOrPageChange={setFilters}
      {...rest}
    />
  );
};
