import React from 'react';
import { Appointment } from '../../../types/appointments';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { SlotAppointmentRightIndicatorStyle } from './AppointmentsTimes.styled';

interface ISlotAppointmentRightIndicatorProps {
  appointment: Appointment;
}

export const SlotAppointmentRightIndicator: React.FunctionComponent<
  ISlotAppointmentRightIndicatorProps
> = ({ appointment }) => {
  const { t } = useCommonTranslation();
  return (
    <>
      {appointment.status === 'DNA' && (
        <SlotAppointmentRightIndicatorStyle
          style={{
            background: 'var(--rednegative)',
          }}
        >
          {t('dna')}
        </SlotAppointmentRightIndicatorStyle>
      )}
      {appointment.status === 'MIA' && (
        <SlotAppointmentRightIndicatorStyle
          style={{
            background: 'var(--rednegative)',
          }}
        >
          {t('mia')}
        </SlotAppointmentRightIndicatorStyle>
      )}
      {appointment.status === 'TAKEN' && (
        <SlotAppointmentRightIndicatorStyle
          style={{
            borderColor: 'var(--greenprimary)',
          }}
        />
      )}
      {appointment.status === 'NOT_PREPARED' && (
        <SlotAppointmentRightIndicatorStyle
          style={{
            background: 'var(--rednegative)',
          }}
        >
          {t('not-prepared-abbrev')}
        </SlotAppointmentRightIndicatorStyle>
      )}
      {appointment.status === 'NOT_TAKEN' && (
        <SlotAppointmentRightIndicatorStyle
          style={{
            borderColor: 'var(--rednegative)',
          }}
        />
      )}
    </>
  );
};
