import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WrappingRow = styled(Row)`
  flex-wrap: wrap;
`;
