import DefaultInputFilter from 'components/Table/DefaultInputFilter';
import { RowActions } from 'components/Table/Table.styled';
import {
  CommonButton as Button,
  CommonLink,
} from 'components/common/Forms/Button';
import { ReactElement, useMemo } from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { Column, FilterProps } from 'react-table';
import { TableHeadStyle } from 'styles/Typography';
import { TrustData, TrustsFilters } from 'types/trusts';
import { getNewColumnWidth } from 'utils/table';
import { PaginatedFiltersWithSet } from '../../../../hooks/usePaginatedFilters';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { adminTrustPreferencesUrl } from '../../../../routes/AppRoutes';
import { ItemsResponse } from '../../../../types/ItemsWithCountResponse';
import { FetchedDataTable } from '../../../Table/FetchedDataTable';
import { getFilterControls } from '../../../Table/TableUtils';

type Props = {
  trustsResponse?: ItemsResponse<TrustData>;
  openFormHandler: (value?: string) => void;
  handleDeleteTrust: (value: string) => void;
  isLoading: boolean;
  isFetching: boolean;
} & PaginatedFiltersWithSet<TrustsFilters>;

export const TrustsTable = ({
  openFormHandler,
  handleDeleteTrust,
  filters,
  setFilters,
  trustsResponse,
  isLoading,
  isFetching,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();

  const columns = useMemo(() => {
    return [
      {
        Header: t('trust-name'),
        accessor: 'name',
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(50),
        Filter: (props: FilterProps<object>) => (
          <DefaultInputFilter
            {...props}
            inputProps={{
              placeholder: t('trust-name'),
            }}
          />
        ),
      },
      {
        ...getFilterControls({ t, filters, setFilters }),
        Cell: ({ value }: { value: string }) => {
          return (
            <RowActions>
              <CommonLink
                size="auto"
                variant="secondary"
                to={adminTrustPreferencesUrl(value)}
              >
                {t('preferences')}
              </CommonLink>
              <Button
                size="auto"
                iconOnly
                variant="primary"
                title={t('edit-trust')}
                onClick={() => openFormHandler(value)}
              >
                <FaPencilAlt />
              </Button>
              <Button
                size="auto"
                iconOnly
                variant="danger"
                title={t('remove-trust')}
                onClick={() => handleDeleteTrust(value)}
              >
                <FaTrashAlt />
              </Button>
            </RowActions>
          );
        },
      },
    ] as Column<TrustData>[];
  }, [t, openFormHandler, handleDeleteTrust, filters, setFilters]);

  return (
    <FetchedDataTable
      columns={columns}
      data={trustsResponse}
      onSortOrPageChange={setFilters}
      isLoading={isLoading}
      isFetching={isFetching}
      filters={filters}
    />
  );
};
