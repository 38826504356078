import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { FaCalendarAlt, FaEye, FaTrashAlt } from 'react-icons/fa';
import { Column, FilterProps } from 'react-table';

import {
  CommonButton as Button,
  CommonButton,
} from '../../components/common/Forms/Button';
import DefaultInputFilter from '../../components/Table/DefaultInputFilter';
import FilterByDatePicker from '../../components/Table/FilterByDatePicker';
import {
  FilterInputContainerStyleOverride,
  RowActions,
} from '../../components/Table/Table.styled';
import {
  DEFAULT_COLUMN_WIDTH,
  DEFAULT_COLUMN_WIDTH_DATE,
  getFilterControls,
  isNOTCellSelected,
} from '../../components/Table/TableUtils';
import { SiteData } from '../../types/sites';
import { UserData, UsersFilters } from '../../types/users';
import { optionalIsoDateToDateOnlyString } from '../../utils/dateUtil';
import {
  getNewColumnWidth,
  setColumnFilterAndSortKey,
} from '../../utils/table';
import { PaginatedFiltersWithSet } from '../usePaginatedFilters';
import { useCommonTranslation } from '../i18n/useCommonTranslation';
import { formatPhoneNumber } from '../../utils/helpers';
import { IDCellPropsWithSelected } from '../../types/table';
import { InputPhoneNumber } from '../../components/common/Forms/InputPhoneNumber';

const getCommonUsersColumns = (t: TFunction) => {
  return [
    {
      Header: t('first-name'),
      accessor: 'firstname',
      id: setColumnFilterAndSortKey('firstname'),
      Filter: (props: FilterProps<object>) => (
        <DefaultInputFilter
          {...props}
          inputProps={{
            placeholder: t('first-name'),
          }}
        />
      ),
    },
    {
      Header: t('surname'),
      accessor: 'lastname',
      id: setColumnFilterAndSortKey('lastname'),
      Filter: (props: FilterProps<object>) => (
        <DefaultInputFilter
          {...props}
          inputProps={{
            placeholder: t('surname'),
          }}
        />
      ),
    },
    {
      Header: t('date-of-birth'),
      accessor: (user: UserData) =>
        optionalIsoDateToDateOnlyString(user.birthdate),
      width: getNewColumnWidth(15),
      id: 'birthDate',
      Filter: (props: FilterProps<object>) => (
        <FilterByDatePicker
          {...props}
          inputProps={{
            placeholder: t('date-of-birth'),
            icon: <FaCalendarAlt />,
          }}
        />
      ),
    },
    {
      Header: t('email'),
      accessor: 'email',
      id: setColumnFilterAndSortKey('email', 'UserAccount.Email'),
      Filter: (props: FilterProps<object>) => (
        <DefaultInputFilter
          {...props}
          inputProps={{
            placeholder: t('email'),
          }}
        />
      ),
    },
    {
      Header: t('phone-number'),
      id: 'phone',
      accessor: (user: UserData) => formatPhoneNumber(user.phone),
      width: DEFAULT_COLUMN_WIDTH_DATE,
      Filter: ({
        column: { filterValue, setFilter, id },
        ...inputProps
      }: FilterProps<object>) => {
        const inputName = inputProps?.name || id;
        return (
          <InputPhoneNumber
            placeholder={t('phone-number')}
            {...inputProps}
            noSpaceForHelperText
            id={id}
            value={filterValue || ''}
            name={inputName}
            containerStyle={FilterInputContainerStyleOverride}
            onChange={(e) => {
              setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
          />
        );
      },
    },
  ] as Column<UserData>[];
};

export const useAdminPatientColumns = ({
  openFormHandler,
  handleDeleteUser,
  allSites,
  setFilters,
  filters,
}: {
  openFormHandler: (value?: string) => void;
  handleDeleteUser: (value: string) => void;
  allSites?: SiteData[];
} & PaginatedFiltersWithSet<UsersFilters>) => {
  const { t } = useCommonTranslation();
  const columns = useMemo(() => {
    return [
      ...getCommonUsersColumns(t),
      {
        Header: t('active-site'),
        accessor: (user) =>
          allSites?.find((site) => site.id === user.active_site_id)?.name || '',
        id: setColumnFilterAndSortKey('activeSite', 'UserAccount.ActiveSite'),
        width: DEFAULT_COLUMN_WIDTH,
        Filter: (props: FilterProps<UserData>) => (
          <DefaultInputFilter
            {...(props as FilterProps<object>)}
            inputProps={{
              placeholder: t('active-site'),
            }}
          />
        ),
      },
      {
        ...getFilterControls({
          t,
          filters,
          setFilters,
        }),
        Cell: ({ value }: { value: string }) => {
          return (
            <RowActions>
              <CommonButton
                type="button"
                variant="primary"
                iconOnly
                onClick={() => openFormHandler(value)}
              >
                <FaEye title={t('show-patient-information')} />
              </CommonButton>
              <Button
                size="auto"
                iconOnly
                variant="danger"
                title={t('remove-patient')}
                onClick={() => handleDeleteUser(value)}
              >
                <FaTrashAlt />
              </Button>
            </RowActions>
          );
        },
      },
    ] as Column<UserData>[];
  }, [t, allSites, openFormHandler, handleDeleteUser, filters, setFilters]);
  return { columns };
};

export const useStaffPatientColumns = ({
  onShowUserClicked,
  onDeleteUserClicked,
  onSelectUserClicked,
  allSites,
  filters,
  setFilters,
  showActiveSiteFilter = true,
}: {
  onShowUserClicked?: (value?: string) => void;
  onDeleteUserClicked?: (value: string) => void;
  onSelectUserClicked?: (value: string) => void;
  allSites?: SiteData[];
  showActiveSiteFilter?: boolean;
} & PaginatedFiltersWithSet<UsersFilters>) => {
  const { t } = useCommonTranslation();
  const columns = useMemo(() => {
    return [
      ...getCommonUsersColumns(t),
      ...(showActiveSiteFilter
        ? [
            {
              Header: t('active-site'),
              accessor: (user) =>
                allSites?.find((site) => site.id === user.active_site_id)
                  ?.name || '',
              id: setColumnFilterAndSortKey(
                'activeSite',
                'UserAccount.ActiveSite',
              ),
              width: DEFAULT_COLUMN_WIDTH,
              Filter: (props: FilterProps<UserData>) => (
                <DefaultInputFilter
                  {...(props as FilterProps<object>)}
                  inputProps={{
                    placeholder: t('active-site'),
                  }}
                />
              ),
            } as Column<UserData>,
          ]
        : []),
      {
        ...getFilterControls<UsersFilters>({
          t,
          filters,
          setFilters,
        }),
        Cell: ({ value, ...rest }: IDCellPropsWithSelected<UserData>) => {
          return (
            <RowActions>
              {onShowUserClicked && (
                <CommonButton
                  type="button"
                  variant="primary"
                  iconOnly
                  onClick={() => onShowUserClicked(value)}
                >
                  <FaEye title={t('show-patient-information')} />
                </CommonButton>
              )}
              {onDeleteUserClicked && (
                <Button
                  size="auto"
                  iconOnly
                  variant="danger"
                  title={t('remove-patient')}
                  onClick={() => onDeleteUserClicked(value)}
                >
                  <FaTrashAlt />
                </Button>
              )}
              {onSelectUserClicked && isNOTCellSelected(rest) && (
                <Button
                  size="auto"
                  variant="primary"
                  onClick={() => onSelectUserClicked(value)}
                >
                  {t('select')}
                </Button>
              )}
            </RowActions>
          );
        },
      },
    ] as Column<UserData>[];
  }, [
    t,
    onShowUserClicked,
    onDeleteUserClicked,
    onSelectUserClicked,
    filters,
    setFilters,
    allSites,
    showActiveSiteFilter,
  ]);
  return { columns };
};
