import { AxiosResponse } from 'axios';
import {
  UseMutationOptions,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import {
  deleteWaitingList,
  DeleteWaitingListParams,
  getWaitingList,
  GetWaitingListParams,
  saveWaitingList,
  WaitingListData,
  WaitingListResponse,
} from 'services/waitingList';
import {
  CacheAndStaleTimeForUnlikelyChanged,
  useMutationWithToastError,
  useQueryWithToastError,
} from './queryUtils';

const WAITING_LIST_KEY = 'waiting-list';

export const useQueryWaitingList = (
  params: GetWaitingListParams,
  options?:
    | UseQueryOptions<any, any, AxiosResponse<WaitingListResponse>>
    | undefined,
) => {
  return useQueryWithToastError(
    [WAITING_LIST_KEY, { ...params }],
    () => getWaitingList(params),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
      ...options,
    },
  );
};

export const useMutateSaveWaitingList = (
  options?: UseMutationOptions<any, any, WaitingListData>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<WaitingListData>(
    [WAITING_LIST_KEY],
    (data) => saveWaitingList(data),
    {
      onSettled: () => client.invalidateQueries([WAITING_LIST_KEY]),
      ...options,
    },
  );
};

export const useMutateDeleteWaitingList = (
  options?: UseMutationOptions<any, any, DeleteWaitingListParams>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<DeleteWaitingListParams>(
    [WAITING_LIST_KEY],
    (data) => deleteWaitingList(data),
    {
      onSettled: () => client.invalidateQueries([WAITING_LIST_KEY]),
      ...options,
    },
  );
};
