import { PageHelmet } from 'components/common/PageHelmet';
import React from 'react';
import { StaffLayout } from './StaffLayout';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { StaffAppointmentsCard } from './appointments/StaffAppointmentsCard';
import { useFlowEditAppointment } from '../../hooks/staff/useFlowEditAppointment';
import { PatientAppointmentsAndEditPatientControl } from './appointments/PatientAppointmentsAndEditPatientControl';
import { useResourceTypesWithCubiclesAssigned } from '../../hooks/resourceTypes/useResourceTypesWithCubiclesAssigned';
import { useAppointmentsFlow } from '../../hooks/staff/useAppointmentsFlow';
import { SangixMainGrid } from './SangixMainGrid';

export default function StaffAppointments() {
  const { t } = useCommonTranslation();

  const { resourceTypesWithCubicleAssigned, isLoading } =
    useResourceTypesWithCubiclesAssigned();

  const { appointmentsData, ...rest } = useAppointmentsFlow({
    showAbbreviatedNames: false,
  });

  const {
    setOrToggleEditFlowAppointmentID,
    appointment,
    mode,
    editAppointmentId,
    ...restEditPatientProps
  } = useFlowEditAppointment({
    appointmentsData,
  });
  return (
    <StaffLayout>
      <PageHelmet title={t('appointments')} />
      <SangixMainGrid
        main={
          <StaffAppointmentsCard
            filteredResourceTypesFetching={isLoading}
            filteredResourceTypes={resourceTypesWithCubicleAssigned}
            setOrToggleEditAppointmentId={setOrToggleEditFlowAppointmentID}
            appointmentsData={appointmentsData}
            editAppointmentId={editAppointmentId}
            {...rest}
          />
        }
        secondary={
          <PatientAppointmentsAndEditPatientControl
            appointment={appointment}
            mode={mode}
            {...restEditPatientProps}
          />
        }
      />
    </StaffLayout>
  );
}
