import axios from 'axios';

import i18n from '../i18n';
import { ErrorCode } from './errorCodes';

export interface ErrorWithCode {
  code: string;
  message: string;
}

export const getErrorMessage = (error?: any) => {
  if (typeof error === 'string') {
    return error;
  }

  if (error && axios.isAxiosError(error) && error.response) {
    const { data, status } = error.response;
    if (typeof data === 'string') {
      return data;
    }

    if (status === 500 && isErrorWithCode(data)) {
      const { code, message } = data;
      const key = errorCodeToTranslationKey(code);
      if (i18n.exists(key)) {
        return i18n.t('error-message-with-code', {
          code,
          message: i18n.t(key),
        });
      } else {
        // If i18n key not found or translation is not loaded yet
        // return response data message
        return message;
      }
    }
  }

  if (typeof error?.message === 'string') {
    return error?.message as string;
  }

  // use default message with fallback when translation is not loaded yet
  return i18n.t('error-default-message', 'Something went wrong.');
};

export const checkErrorCode = (error: any, code: ErrorCode) => {
  if (error && code && axios.isAxiosError(error) && error.response) {
    const { data, status } = error.response;
    if (status === 500 && isErrorWithCode(data)) {
      return data.code === code;
    }
  }
  return false;
};

const isErrorWithCode = (object: any): object is ErrorWithCode =>
  object && 'code' in object && 'message' in object;

const errorCodeToTranslationKey = (code: string) => {
  let codeKey: string;
  if (code.startsWith('DB')) {
    codeKey = 'DBXXXX';
  } else {
    codeKey = code;
  }
  return `error-codes.${codeKey}`;
};
