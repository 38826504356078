import { useMemo } from 'react';

import { PATIENT } from '../../configs/constants';
import { getRouteFromRole } from '../../configs/RoutesConfig';
import { useRole } from '../../context/RoleContext';
import { useHashParams } from '../../hooks/router/useHashParams';
import {
  LOGIN_HASH_BOOK_AT_SITE_ID_PARAM,
  patientAddToHospitalUri,
} from '../../routes/AppRoutes';

export const useRoleRoute = () => {
  const { currentUserRole } = useRole();
  const [siteIdForBook] = useHashParams(LOGIN_HASH_BOOK_AT_SITE_ID_PARAM);

  return useMemo(():
    | {
        userLoggedIn: false;
        route: undefined;
      }
    | {
        userLoggedIn: true;
        route: string;
      } => {
    if (!currentUserRole) {
      return {
        userLoggedIn: false,
        route: undefined,
      };
    }
    let route: string;
    if (currentUserRole.id === PATIENT && siteIdForBook) {
      route = patientAddToHospitalUri(siteIdForBook);
    } else {
      route = getRouteFromRole(currentUserRole.id);
    }
    return {
      userLoggedIn: true,
      route,
    };
  }, [currentUserRole, siteIdForBook]);
};
