import { Column } from 'react-table';

import { useBasicSangixTable } from './TableUtils';
import React from 'react';
import { TableComponent } from './TableComponent';
import { IObjectWithId } from '../../types/table';

interface Props<D extends object> {
  data?: D[];
  columns: Column<D>[];
  isLoading?: boolean;
  resizingKey?: string;
}

export const BasicTable = <D extends IObjectWithId>({
  isLoading,
  columns,
  data,
  resizingKey,
}: Props<D>) => {
  const { tableProps } = useBasicSangixTable({
    columns,
    data,
    resizingKey,
  });

  return <TableComponent {...tableProps} isLoading={isLoading} />;
};
