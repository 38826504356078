import { Col } from 'components/common/Grid';
import { CheckInRightSide } from 'containers/common/site/checkIn/CheckInRightSide';
import { KioskAndSelfCheckInForm } from 'containers/common/site/checkIn/KioskAndSelfCheckInForm';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FormGridBody } from '../../../../components/common/Forms/Forms.styled';
import { PromptWithBeforeUnload } from '../../../../components/Form/PromptWithBeforeUnload';
import { useIsDirty } from '../../../../hooks/form/useIsDirty';
import useAttributesForm from '../../../../hooks/useAttributesForm';
import { SiteData } from '../../../../types/sites';
import {
  ISitePreferencesWithTypeProps,
  SITE_PREFERENCES_FORM_ID,
} from '../SitePreferences';
import {
  useMergeSiteFormHooks,
  useSiteFormSubmit,
} from '../SitePreferencesHooks';
import {
  CheckInPreferencesFormFieldValues,
  UseCheckInPreferencesFormHooksReturn,
} from './CheckInPreferencesHooks';
import { useCheckInRightSideFormHooks } from './CheckInRightSideHooks';
import { useKioskAndSelfCheckInFormHooks } from './KioskAndSelfCheckInFormHooks';

type Props = ISitePreferencesWithTypeProps & {
  site: SiteData;
};

export const CheckInPreferences = (props: Props) => {
  const { site } = props;
  const formPartsHooks: UseCheckInPreferencesFormHooksReturn[] = [
    useKioskAndSelfCheckInFormHooks({ site }),
    useCheckInRightSideFormHooks({ site }),
  ];

  const { defaultValues, restAttributes, beforeSubmit } = useMergeSiteFormHooks(
    { site, hooks: formPartsHooks },
  );

  const theForm = useForm<CheckInPreferencesFormFieldValues>({
    defaultValues,
  });

  useEffect(() => {
    theForm.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const attributesForm = useAttributesForm<CheckInPreferencesFormFieldValues>({
    theForm,
    currentAttrs: site.attributes,
    restAttributes,
    dep: [site],
  });

  const isDirty = useIsDirty(attributesForm.formState);

  const submitForm = useSiteFormSubmit({
    beforeSubmit,
    ...props,
    ...attributesForm,
  });

  return (
    <FormGridBody id={SITE_PREFERENCES_FORM_ID} onSubmit={submitForm}>
      <PromptWithBeforeUnload when={isDirty}></PromptWithBeforeUnload>
      <Col md={6}>
        <KioskAndSelfCheckInForm attributesForm={attributesForm} {...props} />
      </Col>
      <Col md={6}>
        <CheckInRightSide attributesForm={attributesForm} {...props} />
      </Col>
    </FormGridBody>
  );
};
