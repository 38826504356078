import { UseMutationOptions } from 'react-query';
import {
  sendStaffSupportMessage,
  sendSupportMessage,
  SendSupportParams,
} from 'services/support';
import { useMutationWithToastError } from './queryUtils';

export const SUPPORT_QUERY_KEY = 'support';

export const useMutateSendSupportMessage = (
  options?: UseMutationOptions<any, any, SendSupportParams>,
) => {
  return useMutationWithToastError<SendSupportParams>(
    [SUPPORT_QUERY_KEY],
    (params) => sendSupportMessage(params),
    options,
  );
};

export const useMutateSendStaffSupportMessage = (
  options?: UseMutationOptions<any, any, SendSupportParams>,
) => {
  return useMutationWithToastError<SendSupportParams>(
    [SUPPORT_QUERY_KEY],
    (params) => sendStaffSupportMessage(params),
    options,
  );
};
