import { usePaginatedFilters } from '../../../../hooks/usePaginatedFilters';
import { GPFilters } from '../../../../types/generalPractices';
import { useQueryCCG } from '../../../../query/ccg';
import { useGPSurgeries } from '../../../../query/gp';

export const useGpSurgeriesTable = () => {
  const { filters, setFilters } = usePaginatedFilters<GPFilters>();
  const { isLoading: isCCGLoading, data: ccgData } = useQueryCCG();

  const { isLoading, isFetching, data } = useGPSurgeries(filters);

  return {
    filters,
    setFilters,
    isLoading: isCCGLoading || isLoading,
    ccgData,
    isFetching,
    gpData: data,
  };
};
