import { bitwiseNumberToIntValuesArray } from '../../utils/helpers';

export const useUserDisabilities = (disabilities?: number) => {
  const bitwiseNumberArray = bitwiseNumberToIntValuesArray(disabilities);
  return bitwiseNumberArray.map((num) => {
    if (num === 1) {
      return 'Movement';
    } else if (num === 2) {
      return 'Hearing';
    } else {
      return 'Sight';
    }
  });
};
