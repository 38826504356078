import { CommonButton, CommonLink } from 'components/common/Forms/Button';
import { DatePickerStylesWrapper } from 'components/common/Forms/Forms.styled';
import styled from 'styled-components';
import {
  listItemMarginX,
  listItemMarginY,
} from 'styles/partials/listItemMargin';
import { mq } from 'styles/sizes';
import { toRemStr } from 'utils/helpers';

export const WaitingListRow = styled.div`
  ${listItemMarginY('var(--s2)')}
  display: flex;
  align-items: center;
  & > * {
    ${listItemMarginX('var(--s3)')}
    flex-shrink: 1;
    flex-basis: ${toRemStr(150)};
    min-width: ${toRemStr(70)};
  }
  ${DatePickerStylesWrapper} {
    flex-basis: ${toRemStr(300)};
    ${mq.sm} {
      flex-basis: ${toRemStr(180)};
      flex-shrink: 0;
    }
  }
  ${CommonButton} {
    flex-grow: 0;
    margin-bottom: var(--s2);
  }
`;

export const WaitingListHelpButton = styled(CommonLink)`
  position: absolute;
  right: var(--s2);
  top: var(--s2);
`;

export const WaitingListHelpContent = styled.div`
  h3 {
    font-weight: 500;
  }
`;
