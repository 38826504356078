export const spaces = [4, 8, 16, 32, 48, 64, 96, 128] as const;

export const width = {
  standard: 120,
  large: 160,
  xLarge: 200,
  xxLarge: 400,
};

export const BASE_FONT_SIZE_PX = 12;
export const DEFAULT_COLUMNS = 12;

export const breakpoints = {
  lg: { width: 1920 },
  mlg: { width: 1620 },
  md: { width: 1366 },
  sm: { width: 1024 },
  xsm: { width: 768 },
  xxsm: { width: 384 },
} as const;

export type MediaBreakpointsNumbers = Partial<{
  [key in keyof typeof breakpoints]: number;
}>;

export const media = (width: number) => `@media (min-width: ${width}px)`;

export const mq: { [key in keyof typeof breakpoints]: string } = Object.entries(
  breakpoints,
).reduce<{ [key in keyof typeof breakpoints]: string }>(
  (acc, [key, bp]) => ({ ...acc, [key]: media(bp.width) }),
  {} as any,
);

export const fonts = {
  caption: 12,
  bodysm: 14,
  body1: 16,
  h1: 48,
  h2: 34,
  h3: 24,
  h4: 20,
  fontlarger: 30,
  fontlg: 48,
  fontxl: 64,
  fontxxl: 84,
  fontxxxl: 126,
} as const;

export const fullscreenMaxContentWithRemPx = 1560;
