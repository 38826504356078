import { useCommonTranslation } from '../i18n/useCommonTranslation';
import { useSiteIntAttributes, useSiteStringAttributes } from '../useAttribute';
import { useSite } from '../../context/SiteContext';
import { useCallback } from 'react';
import { differenceInYears, parseIsoDate } from '../../utils/dateUtil';
import {
  MAX_AGE_ATTRIBUTE,
  MIN_AGE_ATTRIBUTE,
  OVER_MAX_AGE_MESSAGE,
  UNDER_MIN_AGE_MESSAGE,
} from '../../configs/siteAndTrustAttributes';
import { UserData } from '../../types/users';

export const useSiteValidatePatientAge = () => {
  const [minAge, maxAge] = useSiteIntAttributes(
    MIN_AGE_ATTRIBUTE,
    MAX_AGE_ATTRIBUTE,
  );
  const [underMinAgeMessage, overMaxAgeMessage] = useSiteStringAttributes(
    UNDER_MIN_AGE_MESSAGE,
    OVER_MAX_AGE_MESSAGE,
  );
  const { activeSite } = useSite();
  const { t } = useCommonTranslation();
  const validatePatientAge = useCallback(
    (patient: Pick<UserData, 'birthdate'>) => {
      if (!minAge && !maxAge) {
        return;
      }
      if (!patient.birthdate) {
        return t('book-appointment-user-no-birthdate');
      }
      const patientAge = differenceInYears(
        new Date(),
        parseIsoDate(patient.birthdate),
      );
      if (minAge && patientAge < minAge) {
        return (
          underMinAgeMessage ||
          t('book-appointment-user-min-age', {
            patientAge: patientAge,
            departmentMinAge: minAge,
            departmentName: activeSite.name,
          })
        );
      } else if (maxAge && patientAge > maxAge) {
        return (
          overMaxAgeMessage ||
          t('book-appointment-user-max-age', {
            patientAge: patientAge,
            departmentMaxAge: maxAge,
            departmentName: activeSite.name,
          })
        );
      }
    },
    [minAge, maxAge, t, activeSite, overMaxAgeMessage, underMinAgeMessage],
  );
  return {
    validatePatientAge,
  };
};
