import React from 'react';

const NHSLogo: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="102.825"
      height="41.617"
      viewBox="0 0 102.825 41.617"
      className={className}
    >
      <rect
        className="text-white"
        width="102.825"
        height="41.617"
        fill="currentColor"
      />

      <g transform="translate(2.903 3.433)">
        <path
          className="text-nhsblue"
          fill="currentColor"
          d="M17.61,14.475H28.795l6.856,23.233h.1l4.7-23.233h8.448L41.8,47.977H30.668L23.655,24.8h-.094L18.911,47.977H10.463Z"
          transform="translate(-10.463 -13.892)"
        />

        <path
          className="text-nhsblue"
          fill="currentColor"
          d="M142.813,14.475h8.979l-2.643,12.818h10.612L162.4,14.475h8.979l-6.96,33.5h-9.031l2.976-14.348h-10.56l-2.976,14.348h-8.969Z"
          transform="translate(-101.071 -13.892)"
        />

        <path
          className="text-nhsblue"
          fill="currentColor"
          d="M277.253,20.542A16.834,16.834,0,0,0,269.907,19c-3.548,0-6.43.531-6.43,3.215,0,4.755,13.005,2.976,13.005,13.151,0,9.26-8.594,11.663-16.366,11.663a37.455,37.455,0,0,1-10.4-1.727l2.081-6.815c1.779,1.155,5.327,1.925,8.251,1.925s7.148-.531,7.148-3.985c0-5.379-13.005-3.361-13.005-12.818,0-8.636,7.585-11.237,14.93-11.237,4.162,0,8.011.437,10.269,1.488Z"
          transform="translate(-183.333 -12.375)"
        />
      </g>
    </svg>
  );
};

export default NHSLogo;
