import { ReactElement } from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { UseFormRegister } from 'react-hook-form/dist/types/form';

import { useUserValidationRules } from '../../../hooks/patient/useUserValidationRules';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import {
  UserMemberData,
  UserProfileResponse,
} from '../../../types/authentication';
import { SelectOption } from '../../../types/common';
import { SiteData } from '../../../types/sites';
import { TrustData } from '../../../types/trusts';
import { FormDesc, FormTitle } from './AddHospitalForm.styled';
import { AddHospitalMemberForm } from './AddHospitalMemberForm';
import {
  MembersFormType,
  getMemberErrors,
  getMemberRegisterNamePrefix,
} from './AddHospitalStepUpdateMembersUtils';

interface Props<T extends MembersFormType> {
  control: Control<T>;
  errors: FieldErrors<T>;
  register: UseFormRegister<T>;
  trusts: TrustData[];
  gpSurgeriesOptions: SelectOption[];
  members: (UserProfileResponse | UserMemberData)[];
  newSite: SiteData;
}

export const AddHospitalStepUpdateMembers = <T extends MembersFormType>(
  props: Props<T>,
): ReactElement => {
  const { newSite, trusts, members, gpSurgeriesOptions } = props;
  const { t } = useCommonTranslation();

  const patientRules = useUserValidationRules({
    trusts,
  });

  return (
    <>
      <FormTitle>
        {t('lets-get-started') + ' (' + newSite?.short_name + ')'}
      </FormTitle>
      <FormDesc>{t('add-hospital-update-members-title')}</FormDesc>
      {members.map((member, index) => {
        const controlPrefix = getMemberRegisterNamePrefix(member, index);
        return (
          <AddHospitalMemberForm
            key={member.id}
            {...props}
            register={(name, ...rest) =>
              props.register((controlPrefix + name) as any, ...rest)
            }
            errors={getMemberErrors(props.errors, member, index)}
            controlPrefix={controlPrefix}
            patientRules={patientRules}
            trusts={trusts}
            gpOptions={gpSurgeriesOptions}
            member={member}
          />
        );
      })}
    </>
  );
};
