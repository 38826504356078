import { ComponentType } from 'react';
import {
  PatientCheckInView,
  usePatientCheckInView,
  useResetPatientCheckInOnUnmount,
} from '../../../context/patient/modules/checkIn';
import { PatientCheckInForm } from './PatientCheckInForm';
import { PatientCheckInPickSite } from './PatientCheckInPickSite';
import { PatientPreCheckIn } from './PatientPreCheckIn';
import { PatientPreCheckInNo } from './PatientPreCheckInNo';

export const PatientCheckInPage = () => {
  const view = usePatientCheckInView();

  let Page: ComponentType | undefined;
  switch (view) {
    case PatientCheckInView.Disabled:
      Page = undefined;
      break;
    case PatientCheckInView.PickSite:
      Page = PatientCheckInPickSite;
      break;
    case PatientCheckInView.PreCheckIn:
      Page = PatientPreCheckIn;
      break;
    case PatientCheckInView.PreCheckInNo:
      Page = PatientPreCheckInNo;
      break;
    case PatientCheckInView.Form:
      Page = PatientCheckInForm;
  }

  useResetPatientCheckInOnUnmount();

  return Page ? <Page /> : null;
};
