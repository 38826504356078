import { Card, CardHeader } from 'components/common';
import {
  PopupDesc,
  PopupIconWrapper,
  PopupTitle,
} from 'components/Popup/ConfirmDialog/ConfirmDialog.styled';
import { useSaveWaitingList } from 'hooks/patient/waitingList/useSaveWaitingList';
import { useValidateWaitingList } from 'hooks/patient/waitingList/useValidateWaitingList';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { FaClock, FaQuestion } from 'react-icons/fa';
import { WaitingListHelpButton } from './WaitingList.styled';
import { WaitingListButtonBar } from './WaitingListButtonBar';
import { WaitingListFor } from './WaitingListFor';
import { useWaitingListURL } from 'hooks/patient/waitingList/useWaitingListURL';
import { WaitingListFormValues } from 'hooks/patient/waitingList/useWaitingList';
import { useSiteHourRange } from '../../../hooks/site/useSiteHourRange';
import { CardLoader } from '../../../components/common/Loading';
import { WaitingListRows } from './WaitingListRows';
import { useDeleteWaitingList } from '../../../hooks/patient/waitingList/useDeleteWaitingList';

export const WaitingListForm = () => {
  const { t } = useCommonTranslation();
  const { save, isLoading: isSavingWaitingList } = useSaveWaitingList();
  const validate = useValidateWaitingList();
  const helpURL = useWaitingListURL('help');
  const form = useFormContext<WaitingListFormValues>();
  const onSubmit = useCallback(() => {
    if (validate()) {
      save();
    }
  }, [validate, save]);
  const { slots, isLoading: isHourRangeLoading } = useSiteHourRange();
  const { deleteWaitingList, isLoading: isDeleteWaitingListLoading } =
    useDeleteWaitingList();

  const isLoading =
    isSavingWaitingList || isHourRangeLoading || isDeleteWaitingListLoading;
  return (
    <Card>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <CardHeader>
          <WaitingListFor />
          <PopupIconWrapper inlineIcon>
            <FaClock />
          </PopupIconWrapper>
          <PopupTitle>{t('waiting-list')}</PopupTitle>
          <PopupDesc>{t('waiting-list-description')}</PopupDesc>

          <WaitingListHelpButton
            variant="primary"
            type="button"
            iconOnly
            size="auto"
            to={helpURL}
          >
            <FaQuestion />
          </WaitingListHelpButton>
        </CardHeader>
        {isLoading ? (
          <CardLoader />
        ) : (
          <>
            <WaitingListRows slots={slots} />
          </>
        )}
        <WaitingListButtonBar
          isLoading={isLoading}
          deleteWaitingList={deleteWaitingList}
        />
      </form>
    </Card>
  );
};
