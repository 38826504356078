import React from 'react';
import { getHookFormTyped, toRemStr } from '../../../../utils/helpers';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import TextArea from '../../../common/Forms/TextArea';
import {
  AppointmentReferrers,
  IAppointmentReferrers,
} from './AppointmentReferrers';
import { BookAppointmentForm } from './EditAppointmentData';
import { FormRow } from '../../../common/Forms/Forms.styled';
import { Controller } from 'react-hook-form';
import { useActiveSiteValidationRules } from '../../../../hooks/site/useActiveSiteValidationRules';
import styled from 'styled-components';
import { FONT_LARGER, LABEL_BEFORE } from '../../../../styles/fontsStyleUtils';
import Input from '../../../common/Forms/Input';
import { useSiteBooleanAttributes } from '../../../../hooks/useAttribute';
import { MANDATORY_SOURCE } from '../../../../configs/siteAndTrustAttributes';
import { BitwiseCheckboxArray } from '../../../common/Forms/BitwiseCheckboxArray';
import { useApptFlagOptions } from '../../../../hooks/appointments/useApptFlagOptions';
import { APPOINTMENT_FLAGS_BITWISE_IDS } from '../../../../utils/appointmentUtil';

export type ProcessingAppointmentForm = BookAppointmentForm & {
  tubes?: number;
  forms?: number;
};

export type ProcessingAppointmentSubformType = {
  appointment: ProcessingAppointmentForm;
};

const FormAndTubes = styled.div`
  display: flex;
  flex: 0.4;
  gap: var(--s2);
  align-items: center;

  > * {
    flex: 1;
  }
`;

export const AppointmentsProcessingData = <
  T extends ProcessingAppointmentSubformType,
>({
  showComment = true,
  referrers,
  ...rest
}: IAppointmentReferrers<T>) => {
  const { register, control, errors } = getHookFormTyped<
    ProcessingAppointmentSubformType,
    T
  >(rest);
  const { t } = useCommonTranslation();
  const { formsRules, tubesRules, tubesMandatory, formsMandatory } =
    useActiveSiteValidationRules();
  const maxDropdownHeight = `max-height: ${toRemStr(200)};`;
  const [notReferrerMandatory] = useSiteBooleanAttributes(MANDATORY_SOURCE);

  const flagOptions = useApptFlagOptions().filter(
    (flag) => flag.value === APPOINTMENT_FLAGS_BITWISE_IDS.alert,
  );

  return (
    <>
      <FormRow
        style={{
          gap: 'var(--s4)',
        }}
      >
        <FormAndTubes
          style={{
            display: 'flex',
            flex: 0.6,
            gap: 'var(--s4)',
          }}
        >
          <Controller
            name="appointment.tubes"
            rules={tubesRules}
            render={({ field }) => (
              <Input
                {...field}
                type={'number'}
                labelPosition={LABEL_BEFORE}
                fontBasedSize={FONT_LARGER}
                label={t('appointment-tubes') + ':'}
                placeholder={t('appointment-tubes')}
                helperText={errors?.appointment?.tubes?.message}
                hasError={Boolean(errors?.appointment?.tubes)}
                required={tubesMandatory}
              />
            )}
            control={control}
          />
          <Controller
            name="appointment.forms"
            rules={formsRules}
            render={({ field }) => (
              <Input
                {...field}
                type={'number'}
                labelPosition={LABEL_BEFORE}
                fontBasedSize={FONT_LARGER}
                label={t('appointment-forms') + ':'}
                placeholder={t('appointment-forms')}
                helperText={errors.appointment?.forms?.message}
                hasError={Boolean(errors.appointment?.forms)}
                required={formsMandatory}
              />
            )}
            control={control}
          />
        </FormAndTubes>
        <AppointmentReferrers
          {...rest}
          referrers={referrers}
          required={!notReferrerMandatory}
          fontBasedSize={FONT_LARGER}
          generalReferrerPropsOverride={{
            dropdownMenuStyleOverride: maxDropdownHeight,
          }}
        />
      </FormRow>
      {showComment && (
        <TextArea
          label={t('appointment-comment')}
          placeholder={t('appointment-comment')}
          helperText={errors.appointment?.comment?.message}
          fontBasedSize={FONT_LARGER}
          style={{
            maxHeight: 'var(--s6)',
          }}
          {...register('appointment.comment')}
        />
      )}

      <Controller
        name={'appointment.appt_flags'}
        control={control}
        render={({ field }) => (
          <BitwiseCheckboxArray
            fontBasedSize={FONT_LARGER}
            style={{
              marginBottom: 'var(--s3)',
            }}
            field={field}
            options={flagOptions}
          />
        )}
      />
    </>
  );
};
