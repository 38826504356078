import React from 'react';
import { TableLoaderWrapper } from './Table.styled';

export default function TableLoader() {
  return (
    <TableLoaderWrapper>
      <div>
        <div className="loader" />
        <div className="text">Loading...</div>
      </div>
    </TableLoaderWrapper>
  );
}
