import { Card, CardTitle } from '../../../../../components/common';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { CardLoader } from 'components/common/Loading';
import CommonSelect from '../../../../../components/common/Forms/Select';
import { useWeeklyApptTemplateCounts } from './useWeeklyApptTemplateCounts';
import { DayColumnStyle, WeekRowStyle } from './WeeklyApptCountsCard.styled';
import { AppointmentTemplateCounts } from '../../../../../utils/apptTemplateUtils';
import { TemplateDateWithCounts } from '../../../../../hooks/apptTemplate/useDefaultTemplatesApptCounts';
import {
  formatDateOnlyISO,
  getWeekDay,
  getWeekDayConfig,
  parseIsoDate,
} from '../../../../../utils/dateUtil';

export const WeeklyApptCountsCard = ({
  showOnlineMustBeShown,
  selectedDate,
  isLoading,
  templateCounts,
  disabled,
  subtitle,
}: {
  showOnlineMustBeShown: boolean;
  selectedDate: Date;
  isLoading: boolean;
  disabled: boolean;
  templateCounts: TemplateDateWithCounts[];
  subtitle?: string;
}) => {
  const { t } = useCommonTranslation();
  const {
    weekCounts,
    selectedAppointmentType,
    appointmentTypeOptions,
    setSelectedAppointmentType,
  } = useWeeklyApptTemplateCounts({ templateCounts });

  return (
    <Card>
      <CardTitle
        style={{
          marginBottom: subtitle ? 'var(--s1)' : 'var(--s3)',
        }}
      >
        {t('time-planning-weekly-report')}
      </CardTitle>
      {subtitle && (
        <CardTitle
          style={{
            marginTop: '0',
          }}
        >
          {subtitle}
        </CardTitle>
      )}
      {isLoading ? (
        <CardLoader />
      ) : (
        <>
          {showOnlineMustBeShown && (
            <CommonSelect
              noSpaceForHelperText
              label={t('appointment-type')}
              placeholder={t('appointment-type')}
              disabled={disabled}
              options={appointmentTypeOptions}
              value={selectedAppointmentType}
              onChange={(data) => {
                setSelectedAppointmentType(
                  data as keyof AppointmentTemplateCounts,
                );
              }}
            />
          )}
          <WeekRowStyle>
            {weekCounts.dateCounts?.map(({ template_date, count }) => {
              const label = getWeekDayConfig({
                t,
                weekdayDowIndex: getWeekDay(parseIsoDate(template_date)),
                shortLabel: true,
              }).label;

              return (
                <DayColumnStyle
                  key={`dayColumn_${parseIsoDate(template_date)}`}
                  isSelected={formatDateOnlyISO(selectedDate) === template_date}
                >
                  <div>{label}</div>
                  <div>{count}</div>
                </DayColumnStyle>
              );
            })}
            <DayColumnStyle isSelected={false}>
              <div>{t('total')}</div>
              <div>{weekCounts.totalCount}</div>
            </DayColumnStyle>
          </WeekRowStyle>
        </>
      )}
    </Card>
  );
};
