import { AxiosError } from 'axios';
import { Card } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import { Col, Grid } from 'components/common/Grid';
import { CardLoader } from 'components/common/Loading';
import { PageHelmet } from 'components/common/PageHelmet';
import {
  TableActionLeftButton,
  TableActions,
} from 'components/Table/Table.styled';
import { SitesTable } from 'components/views-components/admin/sites/SitesTable';
import { AdminSitesTabsTree } from 'configs/RoutesConfig';
import { useState } from 'react';
import { SiteData, SiteFormValues, SitesFilters } from 'types/sites';
import { errorToast } from 'utils/toast';
import { LinkCardTabs } from '../../components/Card/CardTabs/LinkCardTabs';
import { AdminLayout } from './AdminLayout';
import {
  useMutateCreateSite,
  useMutateDeleteSite,
  useMutateUpdateSite,
  useQuerySites,
} from '../../query/sites';
import { usePaginatedFilters } from '../../hooks/usePaginatedFilters';
import { useTypedConfirmDialog } from '../../components/Popup/ConfirmDialog/typedConfirmDialogHook';
import { FaTrashAlt } from 'react-icons/fa';
import { useTrusts } from '../../query/trusts';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { SiteForm } from '../../components/views-components/admin/sites/SiteForm';

export const AdminDepartmentsPage = () => {
  const { t } = useCommonTranslation();

  const { isLoading: isTrustsLoading, data: trustsData } = useTrusts();
  const [showForm, setShowForm] = useState(false);
  const [prefilledData, setPrefilledData] = useState<SiteData | undefined>();

  const closeFormHandler = (err?: AxiosError | true) => {
    setShowForm(false);
    setPrefilledData(undefined);
    if (err) {
      errorToast(err);
    }
  };

  const [expired, setExpired] = useState(false);
  const { filters: siteFilters, setFilters: setSiteFilters } =
    usePaginatedFilters<SitesFilters>();
  const {
    isLoading: isSitesLoading,
    isFetching: isSitesFetching,
    data: sitesData,
  } = useQuerySites({
    ...siteFilters,
    expirationState: expired ? 'expired' : 'active',
  });

  const { isLoading: isCreateLoading, mutate: createSite } =
    useMutateCreateSite({
      onSuccess: () => closeFormHandler(),
    });
  const { isLoading: isUpdateLoading, mutate: updateSite } =
    useMutateUpdateSite({
      onSuccess: () => closeFormHandler(),
    });

  const { isLoading: isDeleteLoading, mutate: deleteSite } =
    useMutateDeleteSite({
      onSuccess: () => closeFormHandler(),
    });
  const { confirm } = useTypedConfirmDialog();

  const isLoading =
    isUpdateLoading || isCreateLoading || isSitesLoading || isDeleteLoading;

  const submitNewSiteHandler: (
    data: SiteFormValues,
    isUpdateSite: boolean,
  ) => void = (data, isUpdateSite) => {
    const structuredData: SiteFormValues = {
      ...data,
      cubicle_limit: Number(data.cubicle_limit),
      longitude: Number(data.longitude),
      latitude: Number(data.latitude),
      expire_date: data.expire_date,
      sign_up_order: Number(data.sign_up_order),
    };

    Boolean(isUpdateSite) && Boolean(prefilledData)
      ? updateSite({ ...prefilledData, ...structuredData })
      : createSite({
          ...structuredData,
          /**
           * Required by Peter as a temporary measure
           * @see https://sangix.slack.com/archives/C021BNY7KEW/p1642929117002700
           */
          active: true,
        });
  };

  const openFormHandler = (value?: string) => {
    setShowForm(false);
    setTimeout(() => {
      if (value) {
        const selectedSite = sitesData?.data?.find((site) => site.id === value);

        if (selectedSite) {
          setPrefilledData(selectedSite);
          setShowForm(true);
        } else {
          closeFormHandler(true);
        }
      } else {
        setPrefilledData(undefined);
        setShowForm(true);
      }
    }, 50);
  };

  const handleDelete = (id: string) => {
    const siteToDelete = sitesData?.data?.find((site) => site.id === id);
    if (siteToDelete) {
      confirm({
        onConfirm: () => deleteSite(id),
        confirmButtonVariant: 'danger',
        title: t('confirm-delete-site'),
        expectedConfirmText: siteToDelete.name,
        icon: <FaTrashAlt />,
        status: 'delete',
        description: t('typed-confirm-delete-desc', {
          name: siteToDelete.name,
        }),
      });
    }
  };

  return (
    <AdminLayout>
      <PageHelmet title={t('sites')} />
      <Grid>
        <Col md={8}>
          <Card fillHeight>
            <LinkCardTabs tabs={AdminSitesTabsTree(t)} withDivider />
            <TableActions>
              <TableActionLeftButton onClick={() => setExpired(!expired)}>
                {t(
                  expired
                    ? 'show-active-departments'
                    : 'show-expired-departments',
                )}
              </TableActionLeftButton>
              <CommonButton variant="primary" onClick={() => openFormHandler()}>
                {t('add-site')}
              </CommonButton>
            </TableActions>
            {isTrustsLoading ? (
              <CardLoader />
            ) : (
              <SitesTable
                sitesDataWithCount={sitesData}
                setFilters={setSiteFilters}
                filters={siteFilters}
                isTableDataLoading={isLoading}
                trustsData={trustsData?.data.data}
                openFormHandler={openFormHandler}
                selectedSite={prefilledData}
                onDeleteClicked={handleDelete}
                isTableDataFetching={isSitesFetching}
              />
            )}
          </Card>
        </Col>
        <Col md={4}>
          {showForm && (
            <SiteForm
              closeFormHandler={closeFormHandler}
              trustsData={trustsData?.data.data}
              submitNewSiteHandler={submitNewSiteHandler}
              isLoading={isLoading}
              prefilledData={prefilledData}
            />
          )}
        </Col>
      </Grid>
    </AdminLayout>
  );
};
