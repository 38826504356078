import { useCallback, useEffect, useState } from 'react';

import { useFilterDateWeekDayAndNotBankHoliday } from '../../../../components/TimePlanner/timePlannerHooks';
import { UNSAFE_FILTER_WITH_NO_PAGINATION } from '../../../../hooks/usePaginatedFilters';
import { useBankHolidays } from '../../../../query/bankHolidays';
import { useApptTemplates } from '../../../../query/template';
import { ResourceType } from '../../../../types/resource-types';
import {
  addDays,
  addWeeks,
  endOfMonth,
  formatDateOnlyISO,
  startOfMonth,
  subWeeks,
} from '../../../../utils/dateUtil';
import { useDefaultTimePlanning } from './StaffTimePlanningHooks';
import { useTimePlanningWeekDays } from './useTimePlanningWeekDays';

export const useStaffTimeDayPickerData = () => {
  const weekDays = useTimePlanningWeekDays({
    showErrorOnUndefinedWeekDays: true,
  });

  const [
    { selectedDate, plannerDateRange, dayPickerDateRange },
    setSelectedDateAndDateRanges,
  ] = useState(() => {
    const initialDate = new Date();
    return calculateSelectedDateAndDateRanges(initialDate, initialDate);
  });

  const updateDates = useCallback(
    ({
      selectedDate,
      dayPickerVisibleMonthDate,
    }: {
      selectedDate?: Date;
      dayPickerVisibleMonthDate?: Date;
    }) => {
      if (!selectedDate && !dayPickerVisibleMonthDate) {
        return;
      }
      setSelectedDateAndDateRanges(
        ({
          selectedDate: prevSelectedDate,
          dayPickerVisibleMonthDate: prevDayPickerVisibleMonthDate,
        }) =>
          calculateSelectedDateAndDateRanges(
            selectedDate || prevSelectedDate,
            // selecting a new date without explicitly setting visible month will set visible month to the selected date
            dayPickerVisibleMonthDate ||
              selectedDate ||
              prevDayPickerVisibleMonthDate,
          ),
      );
    },
    [],
  );

  const defaultTimePlanning = useDefaultTimePlanning({ selectedDate });

  const { selectedResourceType } = defaultTimePlanning;

  const {
    apptTemplatesData: plannerApptTemplatesData,
    isApptTemplatesLoading: isPlannerApptTemplatesLoading,
    bankHolidaysData: plannerBankHolidaysData,
    isBankHolidaysLoading: isPlannerBankHolidaysLoading,
  } = useApptTemplatesAndBankHolidays({
    dateRange: plannerDateRange,
    selectedResourceType,
  });

  const {
    apptTemplatesData: dayPickerApptTemplatesData,
    isApptTemplatesLoading: isDayPickerApptTemplatesLoading,
    bankHolidaysData: dayPickerBankHolidaysData,
    isBankHolidaysLoading: isDayPickerBankHolidaysLoading,
  } = useApptTemplatesAndBankHolidays({
    dateRange: dayPickerDateRange,
    selectedResourceType,
  });

  const plannerFilterDate = useFilterDateWeekDayAndNotBankHoliday({
    weekDays,
    bankHolidays: plannerBankHolidaysData?.data,
  });
  const dayPickerFilterDate = useFilterDateWeekDayAndNotBankHoliday({
    weekDays,
    bankHolidays: dayPickerBankHolidaysData?.data,
  });

  useEffect(() => {
    // In case we have a selected date that is not a planner date, we need to find the next planner date
    // It should happen only on initial load, when today is not a site week day or is a bank holiday.
    let nextDate: Date | undefined = selectedDate;
    while (!plannerFilterDate(nextDate)) {
      nextDate = addDays(nextDate, 1);
    }
    if (nextDate !== selectedDate) {
      updateDates({ selectedDate: nextDate });
    }
  }, [selectedDate, plannerFilterDate, updateDates]);

  return {
    isLoading: defaultTimePlanning.isLoading,
    isPlannerLoading:
      defaultTimePlanning.isLoading ||
      isPlannerApptTemplatesLoading ||
      isPlannerBankHolidaysLoading,
    isDayPickerLoading:
      defaultTimePlanning.isLoading ||
      isDayPickerApptTemplatesLoading ||
      isDayPickerBankHolidaysLoading,
    selectedDate,
    updateDates,
    defaultTimePlanning,
    plannerApptTemplatesData,
    dayPickerApptTemplatesData,
    plannerFilterDate,
    dayPickerFilterDate,
  };
};

const useApptTemplatesAndBankHolidays = ({
  dateRange,
  selectedResourceType,
}: {
  dateRange: { fromDate: string; toDate: string };
  selectedResourceType?: ResourceType;
}) => {
  const { isLoading: isApptTemplatesLoading, data: apptTemplatesData } =
    useApptTemplates(
      {
        ...dateRange,
        resourceTypeID: selectedResourceType?.id,
      },
      {
        enabled: Boolean(selectedResourceType),
      },
    );

  const { isLoading: isBankHolidaysLoading, data: bankHolidaysData } =
    useBankHolidays({
      ...UNSAFE_FILTER_WITH_NO_PAGINATION,
      ...dateRange,
    });

  return {
    isApptTemplatesLoading,
    isBankHolidaysLoading,
    apptTemplatesData,
    bankHolidaysData,
  };
};

const calculateSelectedDateAndDateRanges = (
  selectedDate: Date,
  dayPickerVisibleMonthDate: Date,
) => {
  const plannerDateRange = {
    fromDate: formatDateOnlyISO(subWeeks(startOfMonth(selectedDate), 1)),
    toDate: formatDateOnlyISO(addWeeks(endOfMonth(selectedDate), 1)),
  };
  const dayPickerDateRange = {
    fromDate: formatDateOnlyISO(
      subWeeks(startOfMonth(dayPickerVisibleMonthDate), 1),
    ),
    toDate: formatDateOnlyISO(
      addWeeks(endOfMonth(dayPickerVisibleMonthDate), 1),
    ),
  };
  return {
    selectedDate,
    dayPickerVisibleMonthDate,
    plannerDateRange,
    dayPickerDateRange,
  };
};
