import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { REPORT_IDS } from '../../../types/reports';
import { TFunction } from 'i18next';
import PhlebotomistMonthlyApptsImage from 'images/reports/PhlebotomistMonthlyAppts.png';
import PhlebotomistDailyApptsImage from 'images/reports/PhlebotomistDailyAppts.png';
import ReferralsCCGImage from 'images/reports/ReferralsCCG.png';
import ReferralsGpAnCCGImage from 'images/reports/ReferralsGpAnCCG.png';
import ReferralsGPImage from 'images/reports/ReferralsGP.png';
import AppointmentsBreakdownImage from 'images/reports/AppointmentsBreakdown.png';
import PhlebotomistLoginTimeImage from 'images/reports/PhlebotomistLoginTime.png';
import ApptsPatientsAndPhlebotomistsImage from 'images/reports/ApptsPatientsAndPhlebotomists.png';
import PossibleDuplicatesImage from 'images/reports/PossibleDuplicates.png';
import styled from 'styled-components';

type ReportInfoType = {
  reportId: string;
};

type Translate = {
  t: TFunction;
};

const InlineImage = styled.img`
  max-width: 100%;
  margin-top: var(--s2);
  margin-bottom: var(--s2);
`;

const MinMax = ({
  t,
  min,
  max,
}: Translate & { min?: string; max?: string }) => (
  <>
    {min && (
      <div>
        {t('minimum')}: {min}
      </div>
    )}
    {max && (
      <div>
        {t('maximum')}: {max}
      </div>
    )}
    {(min || max) && <div>{t('note_last_day_not_included')}</div>}
  </>
);

const ReferralsGPAndCCG = ({ t }: Translate) => (
  <>
    {t('referrals_gp_and_ccgs')}
    <InlineImage src={ReferralsGpAnCCGImage} alt={'unable to find a picture'} />
    <MinMax
      t={t}
      min={t('number_days', { count: 1 })}
      max={t('number_months', { count: 12 })}
    />
  </>
);

const ReferralsCCG = ({ t }: Translate) => (
  <>
    {t('referrals_ccgs')}
    <InlineImage src={ReferralsCCGImage} alt={'unable to find a picture'} />
    <MinMax
      t={t}
      min={t('one_day_min_but_month_suggest')}
      max={t('number_months', { count: 12 })}
    />
  </>
);
const ReferralsGp = ({ t }: Translate) => (
  <>
    {t('referrals_gp')}
    <InlineImage src={ReferralsGPImage} alt={'unable to find a picture'} />
    <MinMax
      t={t}
      min={t('number_days', { count: 1 })}
      max={t('number_months', { count: 12 })}
    />
  </>
);
const PhlebotomistDailyAppts = ({ t }: Translate) => (
  <>
    {t('phlebotomist_daily_appts')}
    <InlineImage
      src={PhlebotomistDailyApptsImage}
      alt={'unable to find a picture'}
    />
    <MinMax
      t={t}
      min={t('number_days', { count: 1 })}
      max={t('number_months', { count: 3 })}
    />
  </>
);
const PhlebotomistMohtlyAppts = ({ t }: Translate) => (
  <>
    {t('phlebotomist_monthly_appts')}
    <InlineImage
      src={PhlebotomistMonthlyApptsImage}
      alt={'unable to find a picture'}
    />
    <MinMax
      t={t}
      min={t('one_day_min_but_month_suggest')}
      max={t('number_months', { count: 12 })}
    />
  </>
);
const AppointmentsBreakdown = ({
  t,
  type,
}: {
  t: TFunction;
  type: 'SINGLE_LOCATION' | 'ALL_LOCATION';
}) => {
  let maxMonths = 3;
  if (type === 'ALL_LOCATION') {
    maxMonths = 1;
  }
  return (
    <>
      {type === 'ALL_LOCATION'
        ? t('appointments_breakdown_all_locations')
        : t('appointments_breakdown')}
      <InlineImage
        src={AppointmentsBreakdownImage}
        alt={'unable to find a picture'}
      />
      <MinMax
        t={t}
        min={t('number_days', { count: 1 })}
        max={t('number_months', { count: maxMonths })}
      />
    </>
  );
};
const PhlebotomistLoginTime = ({ t }: Translate) => (
  <>
    {t('phlebotomist_login_time')}
    <InlineImage
      src={PhlebotomistLoginTimeImage}
      alt={'unable to find a picture'}
    />
    <MinMax
      t={t}
      min={t('number_days', { count: 1 })}
      max={t('number_months', { count: 3 })}
    />
  </>
);
const ApptsPatientsAndPhlebotomists = ({ t }: Translate) => (
  <>
    {t('appts_patients_and_phlebotomists')}
    <InlineImage
      src={ApptsPatientsAndPhlebotomistsImage}
      alt={'unable to find a picture'}
    />
    <MinMax
      t={t}
      min={t('number_days', { count: 1 })}
      max={t('number_months', { count: 1 })}
    />
  </>
);
const PossibleDuplicates = ({ t }: Translate) => (
  <>
    {t('possible_duplicates')}
    <InlineImage
      src={PossibleDuplicatesImage}
      alt={'unable to find a picture'}
    />
  </>
);

export const ReportInfo = ({ reportId }: ReportInfoType) => {
  const { t } = useCommonTranslation();
  const {
    POSSIBLE_DUPLICATE_REPORT,
    APPOINTMENTS_BREAKDOWN,
    APPOINTMENTS_PATIENTS_AND_PHLEBOTOMISTS,
    PHLEBOTOMISTS_DAILY_APPTS,
    PHLEBOTOMISTS_MONTHLY_APPTS,
    PHLEBOTOMISTS_LOGIN_TIMES,
    REFERRALS_GP_SURGERIES,
    REFERRALS_CCG,
    REFERRALS_GPS_AND_CCGS,
    APPOINTMENTS_BREAKDOWN_ALL_LOCATIONS,
  } = REPORT_IDS;

  let element = <></>;
  switch (reportId) {
    case REFERRALS_GPS_AND_CCGS: {
      element = <ReferralsGPAndCCG t={t} />;
      break;
    }
    case REFERRALS_CCG: {
      element = <ReferralsCCG t={t} />;
      break;
    }
    case REFERRALS_GP_SURGERIES: {
      element = <ReferralsGp t={t} />;
      break;
    }
    case PHLEBOTOMISTS_DAILY_APPTS: {
      element = <PhlebotomistDailyAppts t={t} />;
      break;
    }
    case PHLEBOTOMISTS_MONTHLY_APPTS: {
      element = <PhlebotomistMohtlyAppts t={t} />;
      break;
    }
    case APPOINTMENTS_BREAKDOWN: {
      element = <AppointmentsBreakdown t={t} type={'SINGLE_LOCATION'} />;
      break;
    }
    case APPOINTMENTS_BREAKDOWN_ALL_LOCATIONS: {
      element = <AppointmentsBreakdown t={t} type={'ALL_LOCATION'} />;
      break;
    }
    case PHLEBOTOMISTS_LOGIN_TIMES: {
      element = <PhlebotomistLoginTime t={t} />;
      break;
    }
    case APPOINTMENTS_PATIENTS_AND_PHLEBOTOMISTS: {
      element = <ApptsPatientsAndPhlebotomists t={t} />;
      break;
    }
    case POSSIBLE_DUPLICATE_REPORT: {
      element = <PossibleDuplicates t={t} />;
      break;
    }
  }

  return <>{element}</>;
};
