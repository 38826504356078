import { SiteData } from 'types/sites';
import {
  getMockServer,
  MockServer,
  wrapCountResponseArray,
  wrapCountResponseCollection,
} from './server';
import { Factory, Model } from 'miragejs';
import { ALL_MANDATORY_TRUST, TEST_TRUST } from './trusts';
import { DeepPartial } from 'react-hook-form';
import { DeepReadonly } from '../types/utils/DeepReadonly';
import { DEFAULT_LOGGED_IN_TEST_USER } from './testUsers';

export type PartialSite =
  | DeepPartial<SiteData>
  | DeepReadonly<DeepPartial<SiteData>>;

export const siteModel = Model.extend<PartialSite>({});

export const siteFactory = Factory.extend<PartialSite>({
  id(index) {
    return `${index}`;
  },
});

export const TEST_SITE: SiteData = {
  id: 'Site 6',
  name: 'SITE 6',
  search_by_name: 'SITE 6',
  short_name: 'S6',
  active: true,
  expire_date: '2025-07-31',
  cubicle_limit: 2,
  timezone: 'Asia/Anadyr',
  primary_resource_type_id: '',
  trust_id: TEST_TRUST.id,
  trust_name: TEST_TRUST.name,
  street: 'Prague',
  city: '',
  postcode: '',
  country: '',
  phone: '',
  longitude: 0,
  latitude: 0,
  attributes: [
    {
      key: 'enableBackgroundGraphics',
      value_str: 'true',
      value_int: 1,
    },
    { key: 'lcdrunningtext', value_str: 'Haha Text', value_int: 1 },
    { key: 'mandatorypostcode', value_str: 'true', value_int: 1 },
    {
      key: 'forms',
      value_str: '',
      value_int: 0,
    },
    { key: 'tubes', value_str: '', value_int: 0 },
    {
      key: 'showwaitinglist',
      value_str: 'true',
      value_int: 1,
    },
    { key: 'lcdshortnames', value_str: '', value_int: 0 },
    {
      key: 'mandatorysource',
      value_str: 'false',
      value_int: 0,
    },
    {
      key: 'openHour',
      value_str: '8',
      value_int: 8,
    },
    { key: 'closeHour', value_str: '75600', value_int: 75600 },
    {
      key: 'weekDays',
      value_str: '0,1,2,3,4,5,6',
      value_int: 1,
    },
    { key: 'colorPalette', value_str: '--tealtercial', value_int: 1 },
    {
      key: 'preCheckinQuestion',
      value_str: 'Hello. Do you want to check in?',
      value_int: 1,
    },
    {
      key: 'preCheckinQuestionNo',
      value_str: 'Please visit the reception if you have any questions',
      value_int: 1,
    },
    { key: 'checkinEarly', value_str: '10', value_int: 15 },
    {
      key: 'checkinEarlyText',
      value_str:
        'Please check in not earlier than 10 minutes before the appointment',
      value_int: 1,
    },
    { key: 'checkinLate', value_str: '0', value_int: 15 },
    {
      key: 'checkinLateText',
      value_str: 'You are too late to check in for the appointment',
      value_int: 1,
    },
    {
      key: 'checkinSuccessText',
      value_str: 'You have checked in successfully',
      value_int: 1,
    },
  ],
  disable_patient_registration_and_switch: false,
};
const AGE_5_TO_10_SITE: PartialSite = {
  id: 'c9bcdd6c057e46fd84da8f962a9a1421',
  name: 'Age5-50',
  search_by_name: 'Age5-50',
  short_name: 'AA',
  active: false,
  expire_date: '2021-12-17',
  cubicle_limit: 2,
  timezone: '',
  primary_resource_type_id: '',
  trust_id: TEST_TRUST.id,
  trust_name: TEST_TRUST.name,
  street: '71 Foster House',
  city: 'Borehamwood',
  postcode: 'WD61FS',
  country: 'United Kingdom',
  phone: '',
  longitude: 0,
  latitude: 0,
  attributes: [
    { key: 'minAge', value_int: 5, value_str: '5' },
    { key: 'maxAge', value_int: 50, value_str: '50' },
    { key: 'allowGPSurgeryRole', value_str: '', value_int: 0 },
    {
      key: 'weekDays',
      value_str: '1,2,3,4,5',
      value_int: 0,
    },
    { key: 'openHour', value_str: '28800', value_int: 28800 },
    {
      key: 'closeHour',
      value_str: '57600',
      value_int: 57600,
    },
    {
      key: 'lcdrunningtext',
      value_str: 'Wait for your name to appear, then enter the room',
      value_int: 1,
    },
    { key: 'lcdshortnames', value_str: '', value_int: 1 },
  ],
  disable_patient_registration_and_switch: false,
};

const MANDATORY_TRUST_SITE: PartialSite = {
  id: 'fd46f7b3b96a4eb4b12c0dbca01e1d47',
  name: 'Mandatory trust site',
  search_by_name: 'Mandatory trust site',
  short_name: 'T',
  active: false,
  expire_date: '2021-12-17',
  cubicle_limit: 2,
  timezone: '',
  primary_resource_type_id: '',
  trust_id: ALL_MANDATORY_TRUST.id,
  trust_name: ALL_MANDATORY_TRUST.name,
  street: 'ds32',
  city: 'adsfas',
  postcode: 'CB21AB',
  country: 'United Kingdom',
  phone: '',
  longitude: 0,
  latitude: 0,
  attributes: [
    { key: 'allowGPSurgeryRole', value_str: '', value_int: 0 },
    {
      key: 'weekDays',
      value_str: '1,2,3,4,5',
      value_int: 0,
    },
    { key: 'openHour', value_str: '28800', value_int: 28800 },
    {
      key: 'closeHour',
      value_str: '57600',
      value_int: 57600,
    },
  ],
  disable_patient_registration_and_switch: false,
};

export const seedSites = (server: MockServer) => {
  server.create('site', TEST_SITE);
  server.create('site', AGE_5_TO_10_SITE);
  server.create('site', MANDATORY_TRUST_SITE);
};

export const mockSites = (sites: PartialSite[], server = getMockServer()) => {
  server.schema.all('site').destroy();
  sites.forEach((newSite) => {
    server.schema.create('site', newSite);
  });
};

export const siteEndpoints = (server = getMockServer()) => {
  const site = 'site';
  server.get(site, (schema) => wrapCountResponseCollection(schema.all(site)));
  server.get(`${site}/:id`, (schema, request) => {
    return schema.find(site, request.params.id)?.attrs || 404;
  });
  server.put(`${site}`, (schema, request) => {
    const update = JSON.parse(request.requestBody);
    const id = update.id;
    const model = schema.find(site, id);
    if (model) {
      model.update(update);
      return 200;
    }
    return 404;
  });
  server.post(site, (schema, request) => {
    schema.create(site, JSON.parse(request.requestBody));
    return 200;
  });

  server.get(`activeTrust/${site}`, (schema) => {
    const sites = schema.all(site);
    const user = schema.find('user', DEFAULT_LOGGED_IN_TEST_USER.id!!)!!.attrs;
    let activeSiteTrustId = '';
    const sitesWithActive = sites?.models.map((site) => {
      if (site.id === user.active_site_id) {
        activeSiteTrustId = site.trust_id!!;
        return {
          active: true,
          ...site.attrs,
        };
      }
      return {
        active: false,
        ...site.attrs,
      };
    });

    const trust = schema.find('trust', activeSiteTrustId);
    return {
      ...wrapCountResponseArray(sitesWithActive),
      trust,
    };
    // return wrapCountResponseCollection(schema.all('site'));
  });

  server.get('/site/byName/:name', (schema, req) => {
    const sites = schema
      .where(site, (site) => {
        return (
          site.name &&
          site.name.toLowerCase().indexOf(req.params.name.toLowerCase()) !== -1
        );
      })
      .models.map(({ name, id }) => ({ name, id }));
    return sites;
  });
};
