export const colors = {
  green: {
    accent: '#16715D',
    primary: '#1FA085',
    secondary: '#7DC5AF',
    tercial: '#DBEADA',
    muted: '#F2F8F1',
    tercial50: 'rgba(219, 234, 218, 0.5)',
    outline: '#4C9D86',
  },
  blue: {
    primary: '#2378D5',
    secondary: '#BED6EC',
    mainprimary: '#005EB8',
    tercial: '#D4EEFF',
  },
  teal: {
    tercial: '#E1F4F7',
  },
  yellow: {
    action: '#F59D03',
    muted: '#FCF4D9',
  },
  red: {
    accent: '#FF7A5A',
    negative: '#C62800',
  },
  text: {
    primary: '#000000D9',
    secondary: '#00000099',
    disabled: '#0000004D',
  },
  disabled: '#d9d9d9',
  dimDisabled: '#B2B2B2',
  white: '#fff',
} as const;
