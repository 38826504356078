import { useMemo } from 'react';
import { useResourceTypeAllDefaultTemplates } from './useResourceTypeAllDefaultTemplates';
import { ApptTemplate } from '../../types/apptTemplate';
import { getWeekDay, parseIsoDate } from '../../utils/dateUtil';
import { useSiteNumericArrayAttribute } from '../useAttribute';
import {
  AppointmentTemplateCounts,
  getAppointmentTemplateCounts,
} from '../../utils/apptTemplateUtils';

export type TemplateDateWithCounts = {
  template_date: string;
} & AppointmentTemplateCounts;

export const useDefaultTemplatesApptCounts = ({
  resourceTypeId,
}: {
  resourceTypeId?: string;
}) => {
  const weekdays = useSiteNumericArrayAttribute('weekDays')!;
  const { dayToTemplate, isFetching } = useDefaultDayTemplates({
    resourceTypeId,
  });

  const templateCounts = useMemo<TemplateDateWithCounts[]>(() => {
    return weekdays
      .filter((day) => dayToTemplate[day])
      .map((day) => {
        const template = dayToTemplate[day];
        return {
          template_date: template.template_date,
          ...getAppointmentTemplateCounts(template.entries),
        };
      });
  }, [dayToTemplate, weekdays]);

  return { isFetching, templateCounts };
};

export const useDefaultDayTemplates = ({
  resourceTypeId,
}: {
  resourceTypeId?: string;
}) => {
  const { defaultTemplates, isFetching } = useResourceTypeAllDefaultTemplates({
    resourceTypeId,
  });

  const dayToTemplate = useMemo(() => {
    const dayToTemplateCountsInner: {
      [day: number]: ApptTemplate;
    } = {};
    defaultTemplates?.forEach((template) => {
      const templateDate = parseIsoDate(template.template_date);
      dayToTemplateCountsInner[getWeekDay(templateDate)] = template;
    });
    return dayToTemplateCountsInner;
  }, [defaultTemplates]);
  return {
    dayToTemplate,
    isFetching,
  };
};
