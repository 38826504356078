import { usePatientBooking } from 'context/patient/modules/booking';
import { useDayAppts } from 'hooks/patient/booking/usePatientBookingQueries';
import { useSiteHourRange } from 'hooks/site/useSiteHourRange';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { DATE_FNS_UK_DATE_FORMAT, formatDate } from 'utils/dateUtil';
import {
  PatientBookingList,
  PatientBookingPickBox,
  PatientBookingStageTitle,
  PatientBookingStageTitle2,
} from '../PatientBooking.styled';
import { PatientBookingHourItem } from './PatientBookingHourItem';
import { CardLoader } from '../../../../components/common/Loading';

export const PatientBookingPickHour = () => {
  const { t } = useCommonTranslation();
  const {
    booking: { date },
  } = usePatientBooking();
  const { dayAppts, isLoading: isDayApptsLoading } = useDayAppts();
  const { slots, isLoading: isHourRangeLoading } = useSiteHourRange();

  return (
    <PatientBookingPickBox>
      <PatientBookingStageTitle>{t('pick-hour')}</PatientBookingStageTitle>
      <PatientBookingStageTitle2>
        {formatDate(date, DATE_FNS_UK_DATE_FORMAT)}
      </PatientBookingStageTitle2>
      {isDayApptsLoading || isHourRangeLoading ? (
        <CardLoader />
      ) : (
        <PatientBookingList>
          {slots?.map((slot, index) => (
            <PatientBookingHourItem
              key={`slot_${index}`}
              slot={slot}
              appts={dayAppts[slot.hour] || []}
            />
          ))}
        </PatientBookingList>
      )}
    </PatientBookingPickBox>
  );
};
