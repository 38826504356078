import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { PATIENT } from '../../../configs/constants';
import { useAuth } from '../../../context/AuthContext';
import {
  useAssignedBookingPatientSites,
  usePatientBooking,
} from '../../../context/patient/modules/booking';
import { useSite } from '../../../context/SiteContext';
import { useHashParams } from '../../../hooks/router/useHashParams';
import { useRedirectToKeepHash } from '../../../hooks/router/useRedirectToKeepHash';
import {
  BOOKING_ROUTE,
  PATIENT_ADD_HOSPITAL_HASH_SITE_ID_PARAM,
} from '../../../routes/AppRoutes';
import { getSiteById } from '../../../services/sites';
import {
  getUserProfile,
  updateUserProfile,
} from '../../../services/userProfile';
import { addUserRole } from '../../../services/users';
import { SiteData } from '../../../types/sites';
import { AddHospitalFormValues } from '../../../types/users';
import { errorToast } from '../../../utils/toast';
import { getUserFormData } from '../../../utils/user';
import { useAddHospitalStepUpdateMembersData } from './AddHospitalStepUpdateMembersHooks';

export const useAddSite = () => {
  const { sangixUser, updateAuthUser } = useAuth();
  const { activeSite } = useSite();

  return useCallback(
    async ({
      newSiteId,
      userProfileUpdateValues,
    }: {
      newSiteId: string;
      userProfileUpdateValues?: AddHospitalFormValues;
    }) => {
      if (userProfileUpdateValues && sangixUser) {
        // update user info when update values passed
        const { userInfo } = getUserFormData({
          ...userProfileUpdateValues,
          // use original active site ID as new site is added over roles and then activated
          active_site_id: activeSite.id,
        });
        await updateUserProfile({ ...userInfo, email: sangixUser.email });
      }
      await addUserRole({
        role_id: PATIENT,
        site_id: newSiteId,
        user_account_id: sangixUser!.id,
      });
      const { data } = await getUserProfile();
      updateAuthUser({
        sangixUser: data,
      });
    },
    [updateAuthUser, sangixUser, activeSite.id],
  );
};

export const useAddSiteAndGoToBooking = ({
  recover,
}: {
  recover: (error: any) => void;
}) => {
  const { setActiveSite } = useSite();
  const [addedSite, setAddedSite] = useState<SiteData>();
  const addSite = useAddSite();
  const { push } = useHistory();
  const { updateBooking } = usePatientBooking();
  const { sites: assignedSites } = useAssignedBookingPatientSites();

  useEffect(() => {
    // We have new site, let's go back to booking
    if (addedSite) {
      (async () => {
        try {
          setAddedSite(undefined);
          await setActiveSite(addedSite);
          updateBooking({ stage: 'member', siteId: addedSite.id });
          push(BOOKING_ROUTE);
        } catch (err) {
          recover(err);
        }
      })();
    }
  }, [push, setActiveSite, updateBooking, addedSite, recover]);

  return useCallback(
    ({
      newSite,
      userProfileUpdateValues,
    }: {
      newSite: SiteData;
      userProfileUpdateValues?: AddHospitalFormValues;
    }) => {
      (async () => {
        try {
          // Add only not already assigned site
          if (
            !assignedSites ||
            !assignedSites.find((site) => site.id === newSite.id)
          ) {
            await addSite({ newSiteId: newSite.id, userProfileUpdateValues });
          }
          /**
           * Do not update active site as we don't have
           * updated setActiveSite callback here. It still holds previous
           * sangixUser object without new roles (because we are in promise chain).
           * If we update active site here, RoleContext would find no role
           * for active site.
           */
          setAddedSite(newSite);
        } catch (err) {
          recover(err);
        }
      })();
    },
    [recover, addSite, assignedSites],
  );
};

export type State =
  | 'initialLoading'
  | 'loadingSiteFromHash'
  | 'selectingSite'
  | 'savingSelectedSite'
  | 'loadingMembers'
  | 'fillingInMembersData'
  | 'savingMembers';

const searchViewStates: State[] = [
  'initialLoading',
  'loadingSiteFromHash',
  'selectingSite',
  'savingSelectedSite',
  'loadingMembers',
];

const updateMembersViewStates: State[] = [
  'fillingInMembersData',
  'savingMembers',
];

const loadingStates: State[] = [
  'initialLoading',
  'loadingSiteFromHash',
  'savingSelectedSite',
  'loadingMembers',
  'savingMembers',
];

export const useAddHospitalForm = () => {
  const [state, setState] = useState<State>('initialLoading');
  const [selectedSite, setSelectedSite] = useState<SiteData>();
  const [bookingTo] = useRedirectToKeepHash(BOOKING_ROUTE);
  const [siteIdForBook] = useHashParams(
    PATIENT_ADD_HOSPITAL_HASH_SITE_ID_PARAM,
  );
  const { push } = useHistory();

  const { sangixUser } = useAuth();
  const { active_site_id, ...rest } = sangixUser!;
  const { control, register, handleSubmit, formState } =
    useForm<AddHospitalFormValues>({
      defaultValues: {
        ...rest,
      },
    });

  const viewState: 'search' | 'updateMembers' = searchViewStates.includes(state)
    ? 'search'
    : 'updateMembers';

  const addSiteAndGoToBooking = useAddSiteAndGoToBooking({
    recover: (err) => {
      errorToast(err);
      if (searchViewStates.includes(state)) {
        setState('selectingSite');
      } else if (updateMembersViewStates.includes(state)) {
        setState('fillingInMembersData');
      }
    },
  });

  const { trustIds: assignedTrustIds } = useAssignedBookingPatientSites();

  const handleSiteSelect = useCallback(
    (site: SiteData) => {
      setSelectedSite(site);
      setState('savingSelectedSite');
      if (assignedTrustIds) {
        // New site is from same trust as patient is already assigned?
        if (assignedTrustIds.includes(site.trust_id)) {
          // then just add site without user update and go to booking
          addSiteAndGoToBooking({ newSite: site });
        } else {
          // otherwise go to step 2
          setState('loadingMembers');
        }
      }
    },
    [addSiteAndGoToBooking, assignedTrustIds],
  );

  const handleStepSubmit = useCallback(
    (values: AddHospitalFormValues) => {
      if (state === 'fillingInMembersData' && selectedSite) {
        setState('savingMembers');
        // update user profile values and go to booking
        addSiteAndGoToBooking({
          newSite: selectedSite,
          userProfileUpdateValues: values,
        });
      }
    },
    [addSiteAndGoToBooking, state, selectedSite],
  );

  const handleMembersUpdateNotNeeded = useCallback(() => {
    if (selectedSite) {
      // just add site without user update and go to booking
      addSiteAndGoToBooking({
        newSite: selectedSite,
      });
    }
  }, [addSiteAndGoToBooking, selectedSite]);

  const updateMembersData = useAddHospitalStepUpdateMembersData({
    user: sangixUser,
    newSite: selectedSite,
    assignedTrustIds: assignedTrustIds,
    onLoadingFinished: () => setState('fillingInMembersData'),
    onMembersUpdateNotNeeded: () => handleMembersUpdateNotNeeded(),
  });

  useEffect(() => {
    (async () => {
      if (state === 'initialLoading' && sangixUser && assignedTrustIds) {
        if (siteIdForBook) {
          setState('loadingSiteFromHash');
          try {
            const siteForBook = (await getSiteById(siteIdForBook)).data;
            // site for book found, select it
            handleSiteSelect(siteForBook);
          } catch (err) {
            errorToast(err);
            // move to standard site selecting on error
            setState('selectingSite');
          }
        } else {
          // move to selecting site after loading mandatory data
          setState('selectingSite');
        }
      }
    })();
  }, [state, sangixUser, assignedTrustIds, siteIdForBook, handleSiteSelect]);

  const goBack = () => {
    if (viewState === 'updateMembers') {
      setState('selectingSite');
    } else {
      push(bookingTo);
    }
  };

  const isLoading = loadingStates.includes(state);

  return {
    isLoading,
    state,
    viewState,
    control,
    register,
    formState,
    handleSubmit,
    handleStepSubmit,
    handleSiteSelect,
    updateMembersData,
    goBack,
  };
};
