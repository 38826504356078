import { useMemo } from 'react';
import { SADApptStatistics } from '../../types/reports';
import { ISortAndPaginatedFilters } from '../../types/table';

export type AdminStatisticsDataItem = {
  trustName: string;
  id: string;
  siteName: string;
  ytd: number;
  lastYear: number;
  allTime: number;
};

export type AdminStatisticsData = { data: AdminStatisticsDataItem[] };

export interface AdminStatisticsFilters extends ISortAndPaginatedFilters {}

export const useAdminStatisticsSiteOverviewData = ({
  sadStatistics,
  currentYear,
}: {
  sadStatistics?: SADApptStatistics;
  currentYear: number;
}) => {
  return useMemo<AdminStatisticsData>(() => {
    if (!sadStatistics) {
      return {
        data: [],
      };
    }

    const keyedBySiteName: {
      [site_name: string]: Omit<AdminStatisticsDataItem, 'siteName'>;
    } = {};
    sadStatistics.forEach((item) => {
      if (!keyedBySiteName[item.site_name]) {
        keyedBySiteName[item.site_name] = {
          id: item.site_id,
          trustName: item.trust_name,
          ytd: 0,
          lastYear: 0,
          allTime: 0,
        };
      }

      if (item.year === currentYear) {
        keyedBySiteName[item.site_name].ytd = item.appt_count;
      }
      if (item.year === currentYear - 1) {
        keyedBySiteName[item.site_name].lastYear = item.appt_count;
      }
      keyedBySiteName[item.site_name].allTime += item.appt_count;
    });

    return {
      data: Object.entries(keyedBySiteName).map(([siteName, stats]) => ({
        siteName,
        ...stats,
      })),
    };
  }, [sadStatistics, currentYear]);
};
