import { TableLoaderWrapper } from 'components/Table/Table.styled';
import React, { CSSProperties } from 'react';
import styled, { css as CSS } from 'styled-components';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';

const CardLoaderWrapper = styled.div<{
  readonly fillWrapper?: boolean;
}>`
  width: 100%;
  height: var(--s8);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${({ fillWrapper }) =>
    fillWrapper &&
    CSS`
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 9;
      background-color: rgba(255,255,255,0.5);
      border-radius: var(--s3);
      & > div {
        margin: 0
      }
    `}
`;

export const CardLoader = ({
  fillWrapper,
  style,
  ...rest
}: {
  fillWrapper?: boolean;
  style?: React.CSSProperties;
} & LoadingProps) => (
  <CardLoaderWrapper fillWrapper={fillWrapper} style={style}>
    <Loading {...rest} />
  </CardLoaderWrapper>
);

type LoadingProps = {
  text?: string;
  style?: CSSProperties | undefined;
};

export const Loading = ({ text, style }: LoadingProps) => {
  const { t } = useCommonTranslation();
  const textToRender = text || t('loading-with-dots');
  return (
    <TableLoaderWrapper data-testid={'loader'} style={style}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div className="loader" />
        <div className="text">{textToRender}</div>
      </div>
    </TableLoaderWrapper>
  );
};
