import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { Card } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import { Col, Grid } from 'components/common/Grid';
import { PageHelmet } from 'components/common/PageHelmet';
import { ConfirmDialog } from 'components/Popup/ConfirmDialog/ConfirmDialog';
import {
  TableActionLeftButton,
  TableActions,
} from 'components/Table/Table.styled';
import { StaffForm } from 'components/views-components/staff/settings/users/StaffForm';
import { StaffTable } from 'components/views-components/staff/settings/users/StaffTable';
import { StaffUsersTabsTree } from 'configs/RoutesConfig';
import { FaTrashAlt } from 'react-icons/fa';
import { successToast } from 'utils/toast';

import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useHasAdminAccessToSelectedSite } from '../../../../hooks/user/useHasAdminAccessToSelectedSite';
import { useMySiteOnlyUsersFilters } from '../../../../hooks/user/useMySiteOnlyUsersFilters';
import { useUserDeleteMutation, useUsers } from '../../../../query/users';
import { StaffLayout } from '../../StaffLayout';
import { useStaffRolesAndGPData } from './useStaffRolesAndGPData';
import { useStaffCrud } from '../../../../hooks/user/useStaffCrud';

export const StaffUsersPage = () => {
  const { t } = useCommonTranslation();

  const { rolesData, GPData, roleIdsCommaSeparated } = useStaffRolesAndGPData();

  const { mySiteOnly, setMySiteOnly, filters, setFilters } =
    useMySiteOnlyUsersFilters({
      initialMySiteOnly: true,
    });

  const toggleMySiteOnlyHandler = () => {
    setMySiteOnly(!mySiteOnly);
  };

  const {
    isLoading: isListLoading,
    isFetching: isListFetching,
    data,
  } = useUsers({
    roleID: roleIdsCommaSeparated,
    ...filters,
  });
  const { isLoading: isDeleteLoading, mutate: deleteItem } =
    useUserDeleteMutation({
      onSuccess: () => {
        successToast(t('user-deleted'));
        closeFormHandler();
      },
    });

  const {
    showForm,
    openFormHandler,
    handleDeleteUser,
    handleConfirmDeleteUser,
    updatedPrefilledData,
    isLoading: isUserActionLoading,
    closeFormHandler,
    confirmDeleteItem,
    staffUserCreateOrUpdate,
  } = useStaffCrud({
    userListData: data?.data,
    deleteItem: deleteItem,
  });

  const isLoading = isUserActionLoading || isListLoading || isDeleteLoading;

  let hasAdminAccessToSelectedSite = useHasAdminAccessToSelectedSite();
  return (
    <StaffLayout>
      <PageHelmet title={t('staff')} />
      <Grid>
        <Col md={8}>
          <Card>
            <LinkCardTabs
              withDivider
              tabs={StaffUsersTabsTree(hasAdminAccessToSelectedSite, t)}
            />
            <TableActions>
              <TableActionLeftButton onClick={() => toggleMySiteOnlyHandler()}>
                {t(mySiteOnly ? 'show-all-sites-users' : 'show-my-site-users')}
              </TableActionLeftButton>
              <CommonButton variant="primary" onClick={() => openFormHandler()}>
                {t('add-new-staff')}
              </CommonButton>
            </TableActions>
            <StaffTable
              usersData={data?.data}
              openFormHandler={openFormHandler}
              handleDeleteUser={handleDeleteUser}
              filters={filters}
              setFilters={setFilters}
              isLoading={isListLoading}
              showActiveSiteFilter={!mySiteOnly}
              isFetching={isListFetching}
            />
          </Card>
        </Col>
        <Col md={4}>
          {showForm && (
            <StaffForm
              closeFormHandler={closeFormHandler}
              submitHandler={staffUserCreateOrUpdate}
              isLoading={isLoading}
              prefilledData={updatedPrefilledData}
              GPData={GPData}
              rolesData={rolesData}
            />
          )}
        </Col>
      </Grid>
      {Boolean(confirmDeleteItem) && (
        <ConfirmDialog
          isLoading={isLoading}
          handleClose={() => closeFormHandler()}
          icon={<FaTrashAlt />}
          title={t('confirm-delete-patient')}
          status="delete"
          actions={[
            <CommonButton
              variant="secondary"
              onClick={() => closeFormHandler()}
              key={confirmDeleteItem?.id + '-cancel'}
            >
              {t('cancel')}
            </CommonButton>,
            <CommonButton
              variant="danger"
              onClick={handleConfirmDeleteUser}
              key={confirmDeleteItem?.id + '-delete'}
            >
              {t('delete')}
            </CommonButton>,
          ]}
        />
      )}
    </StaffLayout>
  );
};
