import { Card } from 'components/common';
import {
  DialogFormWrapper,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import { CardLoader } from 'components/common/Loading';
import { ATTRIBUTE_SEPARATOR } from 'configs/constants';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FaUserCheck } from 'react-icons/fa';

import {
  CHECK_IN_EARLY_TEXT,
  CHECK_IN_LATE_TEXT,
  CHECK_IN_SEARCH_EARLY,
  CHECK_IN_SEARCH_LATE,
  CHECK_IN_SUCCESS_TEXT,
  CHECKIN_DUPLICATE_TEXT,
  KIOSK_AND_SELF_CHECK_IN_EARLY,
  KIOSK_AND_SELF_CHECK_IN_LATE,
} from '../../../../configs/siteAndTrustAttributes';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { UseCheckInPreferencesFormReturn } from './CheckInPreferencesHooks';
import { SitePreferencesType } from '../SitePreferences';

interface Props {
  isFetching: boolean;
  attributesForm: UseCheckInPreferencesFormReturn;
  type: SitePreferencesType;
}

export const KioskAndSelfCheckInForm: React.FC<Props> = ({
  isFetching,
  attributesForm,
  type,
}) => {
  const { t } = useCommonTranslation();

  const {
    formState: { errors },
    attributeRegister,
    control,
  } = attributesForm;
  const watchedValues = useWatch({ control });

  return (
    <>
      <Card mb="var(--s4)">
        <DialogFormWrapper>
          <FormHeader>
            <FaUserCheck />
            <FormTitle>{t('kiosk-and-self-check-in')}</FormTitle>
          </FormHeader>
          <FormRow>
            <Input
              label={t('kiosk-and-self-check-in-check-early-interval')}
              placeholder={t('kiosk-and-self-check-in-check-early-interval')}
              type="number"
              {...attributeRegister(
                KIOSK_AND_SELF_CHECK_IN_EARLY,
                (e) => e.target.value,
                {
                  required: t('must-not-empty') as string,
                },
              )}
              value={
                watchedValues.attributes?.find(
                  (atr) => atr.key === KIOSK_AND_SELF_CHECK_IN_EARLY,
                )?.value_str
              }
              hasError={Boolean(
                errors[
                  (ATTRIBUTE_SEPARATOR +
                    KIOSK_AND_SELF_CHECK_IN_EARLY) as 'attributes'
                ],
              )}
              helperText={
                errors[
                  (ATTRIBUTE_SEPARATOR + KIOSK_AND_SELF_CHECK_IN_EARLY) as 'id'
                ]?.message
              }
            />

            <Input
              label={t('kiosk-and-self-check-in-check-late-interval')}
              placeholder={t('kiosk-and-self-check-in-check-late-interval')}
              disabled={true}
              type="number"
              {...attributeRegister(
                KIOSK_AND_SELF_CHECK_IN_LATE,
                (e) => e.target.value,
              )}
              value={
                watchedValues.attributes?.find(
                  (atr) => atr.key === KIOSK_AND_SELF_CHECK_IN_LATE,
                )?.value_str
              }
              hasError={Boolean(
                errors[
                  (ATTRIBUTE_SEPARATOR +
                    KIOSK_AND_SELF_CHECK_IN_LATE) as 'attributes'
                ],
              )}
              helperText={
                errors[
                  (ATTRIBUTE_SEPARATOR + KIOSK_AND_SELF_CHECK_IN_LATE) as 'id'
                ]?.message
              }
            />
          </FormRow>
          <FormRow>
            <Input
              label={t('kiosk-and-self-check-in-early-message')}
              placeholder={t('kiosk-and-self-check-in-early-message')}
              {...attributeRegister(
                CHECK_IN_EARLY_TEXT,
                (e) => e.target.value,
                {
                  required: t('must-not-empty') as string,
                },
              )}
              value={
                watchedValues.attributes?.find(
                  (atr) => atr.key === CHECK_IN_EARLY_TEXT,
                )?.value_str
              }
              hasError={Boolean(
                errors[
                  (ATTRIBUTE_SEPARATOR + CHECK_IN_EARLY_TEXT) as 'attributes'
                ],
              )}
              helperText={
                errors[(ATTRIBUTE_SEPARATOR + CHECK_IN_EARLY_TEXT) as 'id']
                  ?.message
              }
            />
          </FormRow>
          <FormRow>
            <Input
              label={t('kiosk-and-self-check-in-late-message')}
              placeholder={t('kiosk-and-self-check-in-late-message')}
              {...attributeRegister(CHECK_IN_LATE_TEXT, (e) => e.target.value, {
                required: t('must-not-empty') as string,
              })}
              value={
                watchedValues.attributes?.find(
                  (atr) => atr.key === CHECK_IN_LATE_TEXT,
                )?.value_str
              }
              hasError={Boolean(
                errors[
                  (ATTRIBUTE_SEPARATOR + CHECK_IN_LATE_TEXT) as 'attributes'
                ],
              )}
              helperText={
                errors[(ATTRIBUTE_SEPARATOR + CHECK_IN_LATE_TEXT) as 'id']
                  ?.message
              }
            />
          </FormRow>
          <FormRow>
            <Input
              label={t('kiosk-and-self-check-in-success-message')}
              placeholder={t('kiosk-and-self-check-in-success-message')}
              {...attributeRegister(
                CHECK_IN_SUCCESS_TEXT,
                (e) => e.target.value,
                {
                  required: t('must-not-empty') as string,
                },
              )}
              value={
                watchedValues.attributes?.find(
                  (atr) => atr.key === CHECK_IN_SUCCESS_TEXT,
                )?.value_str
              }
              hasError={Boolean(
                errors[
                  (ATTRIBUTE_SEPARATOR + CHECK_IN_SUCCESS_TEXT) as 'attributes'
                ],
              )}
              helperText={
                errors[(ATTRIBUTE_SEPARATOR + CHECK_IN_SUCCESS_TEXT) as 'id']
                  ?.message
              }
            />
          </FormRow>
          {isFetching && <CardLoader fillWrapper={true} />}
        </DialogFormWrapper>
      </Card>

      {type === 'admin' && (
        <Card mb="var(--s4)">
          <DialogFormWrapper>
            <FormHeader>
              <FaUserCheck />
              <FormTitle>{t('kiosk-check-in-search')}</FormTitle>
            </FormHeader>
            <FormRow>
              <Input
                label={t('kiosk-search-early-interval')}
                placeholder={t('kiosk-search-early-interval')}
                type="number"
                {...attributeRegister(
                  CHECK_IN_SEARCH_EARLY,
                  (e) => e.target.value,
                )}
                value={
                  watchedValues.attributes?.find(
                    (atr) => atr.key === CHECK_IN_SEARCH_EARLY,
                  )?.value_str
                }
                hasError={Boolean(
                  errors[
                    (ATTRIBUTE_SEPARATOR +
                      CHECK_IN_SEARCH_EARLY) as 'attributes'
                  ],
                )}
                helperText={
                  errors[(ATTRIBUTE_SEPARATOR + CHECK_IN_SEARCH_EARLY) as 'id']
                    ?.message
                }
              />

              <Input
                label={t('kiosk-search-late-interval')}
                placeholder={t('kiosk-search-late-interval')}
                type="number"
                {...attributeRegister(
                  CHECK_IN_SEARCH_LATE,
                  (e) => e.target.value,
                )}
                value={
                  watchedValues.attributes?.find(
                    (atr) => atr.key === CHECK_IN_SEARCH_LATE,
                  )?.value_str
                }
                hasError={Boolean(
                  errors[
                    (ATTRIBUTE_SEPARATOR + CHECK_IN_SEARCH_LATE) as 'attributes'
                  ],
                )}
                helperText={
                  errors[(ATTRIBUTE_SEPARATOR + CHECK_IN_SEARCH_LATE) as 'id']
                    ?.message
                }
              />
            </FormRow>
            <FormRow>
              <Input
                label={t('kiosk-check-duplicate-message')}
                placeholder={t('kiosk-check-duplicate-message')}
                {...attributeRegister(
                  `checkinDuplicateText`,
                  (e) => e.target.value,
                  {
                    required: t('must-not-empty') as string,
                  },
                )}
                value={
                  watchedValues.attributes?.find(
                    (atr) => atr.key === CHECKIN_DUPLICATE_TEXT,
                  )?.value_str
                }
                hasError={Boolean(
                  errors[
                    (ATTRIBUTE_SEPARATOR +
                      CHECKIN_DUPLICATE_TEXT) as 'attributes'
                  ],
                )}
                helperText={
                  errors[(ATTRIBUTE_SEPARATOR + CHECKIN_DUPLICATE_TEXT) as 'id']
                    ?.message
                }
              />
            </FormRow>
            {isFetching && <CardLoader fillWrapper={true} />}
          </DialogFormWrapper>
        </Card>
      )}
    </>
  );
};
