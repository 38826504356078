import { TimePlanner } from 'components/TimePlanner/TimePlanner';
import { Card } from 'components/common';
import { Col, Grid } from 'components/common/Grid';
import { CardLoader } from 'components/common/Loading';
import { PageHelmet } from 'components/common/PageHelmet';
import { TimePlanningDefaultTable } from 'components/views-components/staff/settings/timeplanning/TimePlanningDefaultTable';
import TimePlanningHeader from 'components/views-components/staff/settings/timeplanning/TimePlanningHeader';
import { useCallback, useState } from 'react';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { getWeekDay } from '../../../../utils/dateUtil';
import { StaffLayout } from '../../StaffLayout';
import {
  useDefaultTimePlanning,
  useSiteFirstDefaultTemplateDate,
  useSiteLastDefaultTemplateDate,
} from './StaffTimePlanningHooks';
import { useTimePlanningWeekDays } from './useTimePlanningWeekDays';
import { DefaultTemplateApptCountsCard } from './timePlanning/DefaultTemplateApptCountsCard';

export const StaffDefaultTimePlanning = () => {
  const { t } = useCommonTranslation();
  const weekDays = useTimePlanningWeekDays({
    showErrorOnUndefinedWeekDays: true,
  });
  const firstDate = useSiteFirstDefaultTemplateDate();
  const lastDate = useSiteLastDefaultTemplateDate();

  const [selectedDate, setSelectedDate] = useState(firstDate);
  const filterDate = useCallback(
    (date: Date) => {
      const dayNumber = getWeekDay(date);
      return Boolean(weekDays?.includes(dayNumber));
    },
    [weekDays],
  );

  const {
    isLoading,
    saveTimePlanning,
    resourceTypesWithCubicleAssigned,
    selectedResourceType,
    setSelectedResourceType,
    setShowOnline,
    showOnline,
    selectedResourceCubiclesAssigned,
    defaultTemplate,
    showOnlineMustBeShown,
    fullWeekDefaultEditAvailable,
  } = useDefaultTimePlanning({
    selectedDate,
  });

  return (
    <StaffLayout>
      <PageHelmet title={t('time-planning')} />
      {weekDays ? (
        <Grid>
          <Col
            md={5}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 'var(--s5)',
            }}
          >
            <Card>
              <TimePlanningDefaultTable
                selectedDate={selectedDate}
                weekDays={weekDays}
                setDate={setSelectedDate}
                fullWeekDefaultEditAvailable={fullWeekDefaultEditAvailable}
              />
            </Card>
            <DefaultTemplateApptCountsCard
              resourceType={selectedResourceType}
              showOnlineMustBeShown={showOnlineMustBeShown}
              isLoading={isLoading}
              selectedDate={selectedDate}
            />
          </Col>
          <Col md={7}>
            <Card>
              {isLoading ? (
                <CardLoader />
              ) : !resourceTypesWithCubicleAssigned || !selectedResourceType ? (
                'No Resource Type has Cubicles'
              ) : (
                <>
                  <TimePlanningHeader
                    resourceTypes={resourceTypesWithCubicleAssigned}
                    selectedResourceType={selectedResourceType}
                    setSelectedResourceType={setSelectedResourceType}
                    setShowOnline={setShowOnline}
                    showOnline={showOnline}
                    showOnlineMustBeShown={showOnlineMustBeShown}
                  />
                  <TimePlanner
                    selectedResourceCubiclesAssigned={
                      selectedResourceCubiclesAssigned
                    }
                    setSelectedDate={setSelectedDate}
                    selectedResourceType={selectedResourceType}
                    selectedDate={selectedDate}
                    dateFormat="weekDay"
                    filterDate={filterDate}
                    minDate={firstDate}
                    maxDate={lastDate}
                    showOnline={showOnline}
                    setShowOnline={setShowOnline}
                    submitTimePlanner={saveTimePlanning}
                    defaultTemplate={defaultTemplate}
                  />
                </>
              )}
            </Card>
          </Col>
        </Grid>
      ) : null}
    </StaffLayout>
  );
};
