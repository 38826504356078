import styled, { css } from 'styled-components';
import { stripButton } from 'styles/Typography';
import { toRem } from 'utils/helpers';
import { Link, NavLink } from 'react-router-dom';
import { boxShadow } from 'styles/partials/boxShadow';

export type ButtonTypes =
  | 'primary'
  | 'secondary'
  | 'terciary'
  | 'danger'
  | 'selected'
  | 'warning';

type ButtonSizes = 'auto' | 'standard' | 'large' | 'xlarge' | 'xxLarge';

export type ButtonOrLinkStyle = {
  readonly variant: ButtonTypes;
  readonly size?: ButtonSizes;
  readonly toneColor?: string;
  readonly iconOnly?: boolean;
  disabled?: boolean;
};

export type ButtonOrLinkSizeProps = {
  readonly size?: ButtonSizes;
  readonly iconOnly?: boolean;
};
export const buttonOrLinkSize = ({
  size,
  iconOnly,
}: ButtonOrLinkSizeProps) => css`
  min-width: ${() => {
    const auto = `${toRem(32)}rem`;
    switch (size) {
      case 'standard':
        return `var(--width-standard)`;
      case 'large':
        return `var(--width-large)`;
      case 'xlarge':
        return `var(--width-xlarge)`;
      case 'xxLarge':
        return `var(--width-xxlarge)`;
      case 'auto':
        return auto;
      default:
        return iconOnly ? auto : `${toRem(80)}rem`;
    }
  }};

  ${() => {
    switch (size) {
      case 'xxLarge':
        return `font-size: var(--fontxxl);`;
      case 'xlarge':
        return `font-size: var(--fontxl);`;
      default:
        return 'height: var(--s4);';
    }
  }}

  padding: ${() => {
    if (iconOnly) {
      return '0';
    }
    /**
     * Text is uppercase and because of descender being a bit higher then
     * ascender, it seems to be a bit to top rather then middle. This is fix.
     * https://css-tricks.com/how-to-tame-line-height-in-css/
     */
    const descenderDelta = size === 'xxLarge' ? '2px' : '1px';
    switch (size) {
      case 'auto':
        return `${descenderDelta} var(--s2) 0`;
      case 'xxLarge':
        return 'var(--s3) var(--s5)';
      default:
        return `${descenderDelta} var(--s3) 0`;
    }
  }};
`;

const componentStyle = css<ButtonOrLinkStyle>`
  ${stripButton};
  min-height: var(--s4);
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: var(--s3);
  font-size: ${({ iconOnly }) => (iconOnly ? 'var(--body1)' : 'var(--bodysm)')};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease color, 0.2s ease border-color,
    0.2s ease background-color, 0.2s ease box-shadow;
  border: ${toRem(2)}rem solid transparent;
  font-weight: 500;

  &:hover {
    text-decoration: none;
  }

  ${buttonOrLinkSize}
  ${({ variant, toneColor }) =>
    variant === 'primary'
      ? css`
          ${boxShadow}
          color: var(--white);
          background-color: var(--primary);

          &:hover {
            color: var(--white);
            border-color: var(--yellowaction);
            background-color: var(--yellowaction);
            box-shadow: var(--shadow-3);
          }

          &:disabled {
            background-color: var(--disabled);
            border-color: transparent;
          }
        `
      : variant === 'secondary'
      ? css`
          color: ${toneColor || 'var(--primary)'};
          border-color: ${toneColor || 'var(--primary)'};
          background-color: var(--white);

          &:hover {
            color: var(--yellowaction);
            border-color: var(--yellowaction);
            box-shadow: var(--shadow-3);
          }

          &:disabled,
          &[disabled] {
            border-color: var(--textdisabled);
            color: var(--textdisabled);
            cursor: not-allowed;
          }
        `
      : variant === 'terciary'
      ? css`
          color: var(--primary);

          &:hover {
            color: var(--yellowaction);
            border-color: var(--yellowaction);
            box-shadow: var(--shadow-3);
          }

          &:disabled {
            color: var(--textdisabled);
            border-color: transparent;
          }
        `
      : variant === 'danger'
      ? css`
          background-color: var(--rednegative);
          border-color: var(--rednegative);
          color: var(--white);

          ${boxShadow}
          &:hover {
            color: var(--white);
            border-color: var(--rednegative);
            background-color: var(--rednegative);
            box-shadow: var(--shadow-3);
          }

          &:disabled,
          &[disabled] {
            background-color: var(--textdisabled);
            border-color: transparent;
          }
        `
      : variant === 'selected'
      ? css`
          background-color: var(--redaccent);
          border-color: var(--redaccent);
          color: var(--white);

          &:hover {
            border-color: var(--yellowaction);
            ${boxShadow}
          }
        `
      : variant === 'warning' &&
        css`
          background-color: var(--yellowaction);
          border-color: var(--yellowaction);
          color: var(--white);

          &:hover {
            border-color: var(--yellowaction);
            ${boxShadow}
          }
        `}
  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }
`;

/**
 * @param {ButtonSizes} size - standard: 120px, large: 176px
 */
export const CommonNavLink = styled(NavLink)<ButtonOrLinkStyle>`
  ${componentStyle}
`;

/**
 * @param {ButtonSizes} size - standard: 120px, large: 176px
 */
export const CommonLink = styled(({ iconOnly, ...rest }) => (
  <Link {...rest} />
))<ButtonOrLinkStyle>`
  ${componentStyle}
`;

/**
 * @param {ButtonSizes} size - standard: 120px, large: 176px
 */
export const CommonButton = styled.button.attrs(() => ({
  draggable: false,
}))<ButtonOrLinkStyle>`
  ${componentStyle}
`;
