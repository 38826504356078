import { useState } from 'react';
import { useMedia } from 'react-use';

export const usePagination = () => {
  const defaultRowsLimit = 8;
  const mediumRowsLimit = useMedia('(min-width: 672px)') && 10;
  const largeRowsLimit = useMedia('(min-width: 924px)') && 12;
  const xLargeRowsLimit = useMedia('(min-width: 1260px)') && 16;
  const [offset, setOffset] = useState<number>(0);
  const pageLimit =
    xLargeRowsLimit || largeRowsLimit || mediumRowsLimit || defaultRowsLimit;
  /**
   * We poll one extra from the database in order to know if there is another page
   */
  const dbLimit = pageLimit + 1;
  return {
    offset,
    setOffset,
    limit: dbLimit,
    pageLimit,
  };
};
