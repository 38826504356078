import { GlobalAttribute } from 'types/common';

import {
  SERVER_EMAIL_SUBJ_APPT,
  SERVER_EMAIL_SUBJ_CANCEL,
  SERVER_EMAIL_SUBJ_CONFIRMATION,
  SERVER_EMAIL_SUBJ_CREATE,
  SERVER_EMAIL_SUBJ_DAILY_REPORT,
  SERVER_EMAIL_SUBJ_REMINDER,
  SERVER_EMAIL_SUBJ_SUPPORT_FORM,
  SERVER_EMAIL_SUBJ_WAITING_LIST,
} from '../../../../configs/siteAndTrustAttributes';
import {
  siteAttributeKeysSubset,
  updateSiteAttributes,
} from '../SitePreferencesHooks';
import { UseEmailsPreferencesFormHooks } from './EmailsPreferencesHooks';

const keys = siteAttributeKeysSubset([
  SERVER_EMAIL_SUBJ_CREATE,
  SERVER_EMAIL_SUBJ_CONFIRMATION,
  SERVER_EMAIL_SUBJ_CANCEL,
  SERVER_EMAIL_SUBJ_APPT,
  SERVER_EMAIL_SUBJ_REMINDER,
  SERVER_EMAIL_SUBJ_DAILY_REPORT,
  SERVER_EMAIL_SUBJ_WAITING_LIST,
  SERVER_EMAIL_SUBJ_SUPPORT_FORM,
]);

const restAttributes: GlobalAttribute[] = keys.map((key) => ({ key }));

export const useEmailSubjectsFormHooks: UseEmailsPreferencesFormHooks = () => {
  return {
    restAttributes,
    beforeSubmit: (updatedSite, formValues) => {
      return {
        ...updatedSite,
        attributes: updateSiteAttributes(
          updatedSite.attributes,
          formValues.attributes,
          keys,
          (_, siteValue, formValue) => {
            return formValue || siteValue;
          },
        ),
      };
    },
  };
};
