import Axios from 'configs/Axios';
import {
  ReportCubiclePatient,
  ReportCubicleResponse,
  ReportsResponse,
  ReportStatisticsPatient,
  SADApptStatistics,
  SADSiteStatistics,
  Statistics,
} from 'types/reports';
import { DateISOString } from 'utils/dateUtil';

export const getReports = () => Axios.get<ReportsResponse>(`/report`);

export type GenerateReportParams = {
  reportId: string;
  fromDate: DateISOString;
  fromHour?: number;
  toDate: DateISOString;
  toHour?: number;
};
export const generateReport = ({
  reportId,
  fromDate,
  toDate,
  fromHour,
  toHour,
}: GenerateReportParams) =>
  Axios.get<BlobPart>(`/report/${reportId}`, {
    params: { fromDate, toDate, fromHour, toHour },
    responseType: 'arraybuffer',
  });

export const getReportSupervisor = () =>
  Axios.get<ReportCubicleResponse>(`/report/statistics/supervisor`);

export const getReportSupervisorDetails = (id: string) =>
  Axios.get<ReportCubiclePatient[]>(`/report/statistics/supervisor/${id}`);

export type GetReportStatisticsParams = {
  fromDate: DateISOString;
  toDate: DateISOString;
};
export const getReportStatistics = (params: GetReportStatisticsParams) =>
  Axios.get<Statistics>(`/report/statistics/staff`, { params });

export type GetReportStatisticDetailsParams = GetReportStatisticsParams & {
  id: string;
};
export const getReportStatisticDetails = ({
  id,
  fromDate,
  toDate,
}: GetReportStatisticDetailsParams) =>
  Axios.get<ReportStatisticsPatient[]>(`/report/statistics/staff/${id}`, {
    params: { fromDate, toDate },
  });

export const getSADApptStatistics = () =>
  Axios.get<SADApptStatistics>(`/report/statistics/sad/appts`);

export const getSADSiteStatistics = (siteId: string) =>
  Axios.get<SADSiteStatistics>(`/report/statistics/sad/site/${siteId}`);
