import { BulletTable } from 'components/BulletTable/BulletTable';
import { CommonButton } from 'components/common/Forms/Button';
import { ControlledDatePickerInput } from 'components/common/Forms/controlled/ControlledDatePickerInput';
import { useSelectFromArrayViaId } from 'hooks/useSelectFromArrayViaId';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { groupBy, mapValues } from 'lodash';
import { useReportsStatistics, useReportStatisticDetails } from 'query/reports';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaEye } from 'react-icons/fa';
import { FlexCol } from 'styles/components/FlexCol';
import { ReportStatisticsPatient } from 'types/reports';
import {
  addDays,
  DATE_FNS_UK_DATE_FORMAT,
  DateOnlyISOString,
  formatDate,
  formatDateFromISO,
  parseDate,
} from 'utils/dateUtil';
import { toRemStr } from 'utils/helpers';
import {
  DateRow,
  ReportSelectedDate,
  ReportsHeader,
  StatisticsInfoRow,
} from './Reports.styled';
import { ReportsLayout } from './ReportsLayout';
import { ResourceTypes } from './ResourceTypes';
import { StatisticsInfoItem } from './StatisticsInfoItem';
import { useStatisticValidation } from './ReportsValidation';
import { useSiteBooleanAttributes } from '../../../hooks/useAttribute';
import { SHOW_OVERFLOW_CALENDAR } from '../../../configs/siteAndTrustAttributes';

type StatisticsFormValues = {
  fromDate: DateOnlyISOString;
  toDate: DateOnlyISOString;
};

// back-end agreement
const allPhlebotomistsId = '00000000000000000000000000000000';

const DEFAULT_RANGE = {
  fromDate: formatDate(new Date()),
  toDate: formatDate(addDays(new Date(), 1)),
};

export const Statistics = () => {
  const { t } = useCommonTranslation();
  const statisticsValidation = useStatisticValidation();
  const { control, formState, watch, reset, trigger } =
    useForm<StatisticsFormValues>({
      defaultValues: DEFAULT_RANGE,
      resolver: statisticsValidation,
    });
  const [showOverflowCalendar] = useSiteBooleanAttributes(
    SHOW_OVERFLOW_CALENDAR,
  );

  const [fromDate, toDate] = watch(['fromDate', 'toDate']);
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  useEffect(() => {
    const update = async () => {
      const isValid = await trigger();
      if (isValid) {
        setDateRange({
          fromDate,
          toDate,
        });
      }
    };
    update();
  }, [fromDate, toDate, setDateRange, trigger]);

  const { data: stats, isFetching: isStatsFetching } = useReportsStatistics({
    fromDate: dateRange.fromDate,
    toDate: dateRange.toDate,
  });

  const staffList = useMemo(() => stats?.data.staff || [], [stats]);
  const allStaffItem = useMemo(
    () =>
      staffList.reduce(
        (allStaffI, phleb) => ({
          ...allStaffI,
          seen: allStaffI.seen + phleb.seen,
        }),
        {
          seen: 0,
          name: t('all-phlebotomists'),
          id: allPhlebotomistsId,
        },
      ),
    [staffList, t],
  );
  // staff list + calculated entry of the sum of all phlebotomists
  const outputStaffList = useMemo(
    () => [allStaffItem, ...staffList],
    [allStaffItem, staffList],
  );

  const { selected, setSelectedId } = useSelectFromArrayViaId({
    items: outputStaffList,
  });

  const { data: patients, isFetching: isPatientsFetching } =
    useReportStatisticDetails({
      fromDate: dateRange.fromDate,
      toDate: formatDate(addDays(parseDate(dateRange.toDate), 1)),
      id: selected?.id || '',
    });

  const isLoading = isStatsFetching || isPatientsFetching;

  // the map {['Resource Type']: patientCount}
  const resourceTypeToPatients = useMemo(
    () =>
      mapValues(
        groupBy(patients?.data, 'resource_type'),
        (list) => list.length,
      ),
    [patients],
  );

  return (
    <ReportsLayout
      isLoading={isLoading}
      title={t('statistics')}
      leftColMD={4}
      rightColMD={8}
      onRightCardClose={() => setSelectedId('')}
      rightCardTitle={selected?.name}
      // min height to fit the date picker
      rightCardMinHeight={toRemStr(350)}
      leftCardNode={
        <>
          <ReportsHeader>
            <FlexCol>
              <DateRow>
                <ControlledDatePickerInput
                  control={control}
                  formState={formState}
                  rules={{
                    required: t('must-not-empty') as string,
                  }}
                  name="fromDate"
                  label={t('date-from')}
                  noSpaceForHelperText
                />
                <ControlledDatePickerInput
                  control={control}
                  formState={formState}
                  rules={{
                    required: t('must-not-empty') as string,
                  }}
                  name="toDate"
                  label={t('date-to')}
                  noSpaceForHelperText
                />
              </DateRow>
              {dateRange.fromDate && dateRange.toDate && (
                <ReportSelectedDate>
                  {t('report-selected-date-range', {
                    dateFrom: formatDateFromISO(
                      dateRange.fromDate,
                      DATE_FNS_UK_DATE_FORMAT,
                    ),
                    dateTo: formatDateFromISO(
                      dateRange.toDate,
                      DATE_FNS_UK_DATE_FORMAT,
                    ),
                  })}
                </ReportSelectedDate>
              )}
            </FlexCol>
            <CommonButton
              variant="danger"
              onClick={() => reset()}
              type="button"
            >
              {t('clear')}
            </CommonButton>
          </ReportsHeader>
          <StatisticsInfoRow>
            <StatisticsInfoItem>
              {t('all-appointments')}: {stats?.data.appts}
            </StatisticsInfoItem>
          </StatisticsInfoRow>
          <StatisticsInfoRow>
            <StatisticsInfoItem>
              {t('statistics-past-appointments')}: {stats?.data.past}
            </StatisticsInfoItem>
            <StatisticsInfoItem>
              {t('all-taken-tests')}: {stats?.data.taken_tests}
            </StatisticsInfoItem>
            <StatisticsInfoItem>
              {t('all-not-taken')}: {stats?.data.not_taken_tests}
            </StatisticsInfoItem>
            <StatisticsInfoItem>
              {t('all-dna-mia')}: {stats?.data.dna_mia}
            </StatisticsInfoItem>
            {showOverflowCalendar && (
              <StatisticsInfoItem>
                {t('all-overflow')}: {stats?.data.overflow}
              </StatisticsInfoItem>
            )}
          </StatisticsInfoRow>
          <ResourceTypes resourceTypes={stats?.data.resource_types || {}} />
          <BulletTable
            sort
            headers={[
              { text: t('phlebotomist') },
              { text: t('number-of-seen-patients') },
            ]}
            body={outputStaffList.map((phleb) => ({
              selected: phleb.id === selected?.id,
              cells: [
                { bold: true, text: phleb.name },
                { text: phleb.seen },
                {
                  btn: true,
                  text: (
                    <CommonButton
                      type="button"
                      variant="primary"
                      iconOnly
                      onClick={() => setSelectedId(phleb.id)}
                    >
                      <FaEye title={t('patient-list')} />
                    </CommonButton>
                  ),
                },
              ],
            }))}
          />
        </>
      }
      rightCardNode={
        selected && (
          <>
            <ResourceTypes resourceTypes={resourceTypeToPatients} />
            <div>
              <BulletTable
                sort
                filter
                headers={[
                  { text: t('patient') },
                  { text: t('phlebotomist') },
                  { text: t('resource') },
                  { text: t('date'), type: 'date' },
                  { text: t('appt') },
                  { text: t('checked') },
                  { text: t('called') },
                  { text: t('arrived') },
                  { text: t('processed') },
                ]}
                body={patients?.data.map(
                  (patient: ReportStatisticsPatient) => ({
                    cells: [
                      { text: patient.name, bold: true },
                      { text: patient.staff },
                      { text: patient.resource_type },
                      {
                        text: formatDate(
                          parseDate(patient.appt_date),
                          DATE_FNS_UK_DATE_FORMAT,
                        ),
                      },
                      { text: patient.appt_time },
                      { text: patient.checked },
                      { text: patient.called },
                      { text: patient.arrived },
                      { text: patient.processed },
                    ],
                  }),
                )}
              />
            </div>
          </>
        )
      }
    />
  );
};
