import { useSiteIntAttributes } from '../../../hooks/useAttribute';
import { useEffect, useMemo, useState } from 'react';
import { ResourceType } from '../../../types/resource-types';
import {
  DATE_FNS_HOUR,
  DATE_FNS_MINUTE,
  formatDateInServerTZ,
  formatHourMinutes,
  parseDateTimeOnly,
} from '../../../utils/dateUtil';
import { groupBy } from 'lodash';
import {
  STAFF_CLOSE_HOUR_IN_SECONDS,
  STAFF_OPEN_HOUR_IN_SECONDS,
} from '../../../configs/siteAndTrustAttributes';
import { getDaySlots } from '../../../utils/appointmentUtil';
import { AppointmentForFlow } from '../../../hooks/staff/useAppointmentsFlow';

const getCurrentStartInMinutes = (minSlotLength: number) => {
  /**
   * The hour should probably be in the site timezone. However the staff
   * should certainly be in the timezone of the surgery.
   */
  const date = new Date();
  const currentHour = parseInt(formatDateInServerTZ(date, DATE_FNS_HOUR));
  const currentMinute = parseInt(formatDateInServerTZ(date, DATE_FNS_MINUTE));
  return currentHour * 60 + currentMinute - 2 * minSlotLength;
};

const useUpdateCurrentStartWithUpdate = ({
  minSlotLength,
  isUpdate,
}: {
  minSlotLength: number;
  isUpdate: boolean;
}) => {
  const [currentStartInMinutes, setCurrentStartInMinutes] = useState(
    getCurrentStartInMinutes(minSlotLength),
  );
  useEffect(() => {
    if (isUpdate) {
      /**
       * It should move a minute after the last slot at the very latest.
       */
      const delayTillNextSlotInMinutes =
        minSlotLength - (currentStartInMinutes % minSlotLength) + 1;
      const timeoutId = setTimeout(() => {
        setCurrentStartInMinutes(getCurrentStartInMinutes(minSlotLength));
      }, delayTillNextSlotInMinutes * 60 * 1_000);
      return () => clearTimeout(timeoutId);
    }
  }, [
    currentStartInMinutes,
    setCurrentStartInMinutes,
    minSlotLength,
    isUpdate,
  ]);
  return { currentStartInMinutes };
};

export const useDayAppointmentSlots = ({
  resourceTypes,
  isUpdate,
}: {
  isUpdate: boolean;
  resourceTypes: ResourceType[];
}) => {
  const [openHourInSeconds, closeHourInSeconds] = useSiteIntAttributes(
    STAFF_OPEN_HOUR_IN_SECONDS,
    STAFF_CLOSE_HOUR_IN_SECONDS,
  );

  const minSlotLength = Math.min(
    ...resourceTypes.map((resType) => resType.length),
  );

  const { currentStartInMinutes } = useUpdateCurrentStartWithUpdate({
    minSlotLength,
    isUpdate,
  });

  const slots = useMemo(() => {
    if (openHourInSeconds === undefined || closeHourInSeconds === undefined) {
      return [];
    }

    return getDaySlots({
      openHourInSeconds,
      closeHourInSeconds,
      slotLengthInMinutes: minSlotLength,
    }).filter((slot) => {
      if (!isUpdate) {
        return true;
      }
      const slotStartInMinutes = slot.hour * 60 + slot.minute;
      return currentStartInMinutes < slotStartInMinutes;
    });
  }, [
    openHourInSeconds,
    closeHourInSeconds,
    isUpdate,
    currentStartInMinutes,
    minSlotLength,
  ]);
  return { slots };
};

export const useAppointmentsByTime = ({
  appointments,
}: {
  appointments?: AppointmentForFlow[];
}) => {
  const appointmentsByTime = useMemo(() => {
    const withParsed = appointments?.map((data) => {
      const date = parseDateTimeOnly(data.appt_time);
      return {
        time: formatHourMinutes(date.getHours(), date.getMinutes()),
        ...data,
      };
    });
    return groupBy(withParsed, 'time');
  }, [appointments]);
  return appointmentsByTime || {};
};

export const useDayAppointmentsSlotsWithAppointments = ({
  resourceTypes,
  isUpdate,
  appointments,
}: {
  isUpdate: boolean;
  resourceTypes: ResourceType[];
  appointments?: AppointmentForFlow[];
}) => {
  const { slots } = useDayAppointmentSlots({
    resourceTypes,
    isUpdate,
  });

  const appointmentsByTime = useAppointmentsByTime({ appointments });
  const slotsWithAppointments = useMemo(
    () =>
      slots.map((slot) => ({
        ...slot,
        appointments: appointmentsByTime[slot.time] || [],
      })),
    [slots, appointmentsByTime],
  );
  return { slots: slotsWithAppointments };
};
