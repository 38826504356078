import { TEST_SURGERY } from './surgeries';
import { TEST_SITE } from './sites';
import { SUPERADMIN } from '../configs/constants';
import { PartialUser } from './users';

export const DEFAULT_LOGGED_IN_TEST_USER: PartialUser = {
  id: 'USER_1',
  disabilities: 1,
  gp_surgery_id: TEST_SURGERY.id,
  phone: '01277327005',
  email: 'zaphod@umail.un',
  lastname: 'Beeblebrox',
  firstname: 'Zaphod',
  birthdate: '1986-02-02',
  nhs_number: '5344418507',
  active_site_id: TEST_SITE.id,
  user_account_id: 'USER_1_ACCOUNT_ID',
  main_member_id: 'USER_1_MEMBER_ID',
  gdpr_confirmation: '2021-06-01T18:53:12+01:00',
  sms_optin: false,
  registration: '2021-08-03T06:01:40+01:00',
  postcode: 'HA7 4XR',
  comment: '',
  background_graphics: false,
  language: '',
  release_note: '2021-12-05T16:11:46Z',
  other_members: [
    {
      id: 'member1',
      relation: 2,
      firstname: 'Uber',
      lastname: 'Administratorin',
      birthdate: '1995-05-30',
    },
    {
      id: 'member2',
      relation: 3,
      firstname: 'Supreme',
      lastname: 'Adminmom',
      birthdate: '1974-03-15',
    },
  ],
  roles: [
    {
      role_id: SUPERADMIN,
      role_name: 'SUPERA  DMIN',
      site_id: TEST_SITE.id,
      site_name: TEST_SITE.name,
    },
  ],
};

export const TEST_USER_2: PartialUser = {
  id: 'USER_2',
  disabilities: 1,
  gp_surgery_id: TEST_SURGERY.id,
  phone: '01277327006',
  email: 'zaphod@umail.un',
  lastname: 'Dent',
  firstname: 'Arthur',
  birthdate: '1896-01-03',
  nhs_number: '5344418507',
  user_account_id: 'USER_2_ACCOUNT_ID',
};
