import { LinkCardTabs } from '../../Card/CardTabs/LinkCardTabs';
import { Card, CardHeader } from '../index';
import { ButtonSetStyle } from '../Forms/Forms.styled';
import { CardLoader } from '../Loading';
import { CommonButton } from '../Forms/Button';
import { ICardTabs } from '../../../types/common';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';

export const MergeCard = <T,>({
  tabs,
  cardHeader,
  source,
  target,
  isLoading,
  onClearSelection,
  onMerge,
  children,
}: {
  tabs: ICardTabs[];
  cardHeader: React.ReactNode;
  isLoading: boolean;
  source?: T;
  target?: T;
  onClearSelection: () => any;
  onMerge: () => any;
  children: React.ReactNode;
}) => {
  const { t } = useCommonTranslation();
  return (
    <Card>
      <LinkCardTabs withDivider tabs={tabs} />
      <CardHeader oneLine>{cardHeader}</CardHeader>
      {isLoading ? (
        <CardLoader />
      ) : (
        <>
          {children}
          <ButtonSetStyle>
            <CommonButton
              size="standard"
              variant="secondary"
              type="button"
              disabled={!(source && target)}
              onClick={onMerge}
            >
              {t('merge')}
            </CommonButton>

            <CommonButton
              size="standard"
              variant="secondary"
              disabled={Boolean(!source && !target)}
              type="button"
              onClick={onClearSelection}
            >
              {t('clear-selection')}
            </CommonButton>
          </ButtonSetStyle>
        </>
      )}
    </Card>
  );
};
