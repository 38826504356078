import { Loading } from 'components/common/Loading';
import { useAppointmentStateName } from 'hooks/appointments/useAppointmentStateName';
import { sortBy } from 'lodash';
import {
  DATE_FNS_UK_DATE_DASH_TIME_FORMAT,
  formatDateKeepTZ,
  parseIsoDate,
} from 'utils/dateUtil';
import { AppointmentItemCommon } from './AppointmentItemCommon';
import {
  AppointmentItem,
  AppointmentItemStatus,
} from './MyAppointments.styled';
import { useAuth } from '../../../context/AuthContext';
import { useUserAppointments } from '../../../query/appointments';
import {
  USER_APPOINTMENTS_TYPES_CANCELLED,
  USER_APPOINTMENTS_TYPES_PAST,
} from '../../../utils/appointmentUtil';

const usePastAppointments = () => {
  const { sangixUser } = useAuth();
  const { data: pastAppts, isFetching: pastApptsFetching } =
    useUserAppointments(USER_APPOINTMENTS_TYPES_PAST, sangixUser?.id);
  const { data: cancelledAppts, isFetching: cancelledApptsFetching } =
    useUserAppointments(USER_APPOINTMENTS_TYPES_CANCELLED, sangixUser?.id);
  const pastList = pastAppts || [];
  const cancelledList = cancelledAppts || [];
  // sort by time, newest first
  const list = sortBy(
    [...pastList, ...cancelledList],
    (appt) => -1 * parseIsoDate(appt.appt_time).getTime(),
  );
  return { list, isFetching: pastApptsFetching || cancelledApptsFetching };
};

export const PastAppointmentsList = () => {
  const statusToText = useAppointmentStateName();
  const { list, isFetching } = usePastAppointments();

  return (
    <div>
      {isFetching && <Loading />}
      {list.map((appt) => (
        <AppointmentItem disabled key={appt.id}>
          <AppointmentItemCommon appt={appt} />
          <AppointmentItemStatus>
            <div>{statusToText(appt)}</div>
            <div>
              {appt.cancel_time &&
                formatDateKeepTZ(
                  appt.cancel_time,
                  DATE_FNS_UK_DATE_DASH_TIME_FORMAT,
                )}
            </div>
          </AppointmentItemStatus>
        </AppointmentItem>
      ))}
    </div>
  );
};
