import { GlobalAttribute } from 'types/common';

import {
  MAX_AGE_ATTRIBUTE,
  MIN_AGE_ATTRIBUTE,
  OVER_MAX_AGE_MESSAGE,
  UNDER_MIN_AGE_MESSAGE,
} from '../../../../configs/siteAndTrustAttributes';
import { UseGeneralSitePreferencesFormHooks } from './GeneralSitePreferencesHooks';
import {
  siteAttributeKeysSubset,
  updateSiteAttributes,
} from '../SitePreferencesHooks';

const keys = siteAttributeKeysSubset([
  MIN_AGE_ATTRIBUTE,
  MAX_AGE_ATTRIBUTE,
  UNDER_MIN_AGE_MESSAGE,
  OVER_MAX_AGE_MESSAGE,
]);

const restAttributes: GlobalAttribute[] = keys.map((key) => ({ key }));

export const useAgeRestrictionsFormHooks: UseGeneralSitePreferencesFormHooks =
  () => {
    return {
      restAttributes,
      beforeSubmit: (updatedSite, formValues) => {
        return {
          ...updatedSite,
          attributes: updateSiteAttributes(
            updatedSite.attributes,
            formValues.attributes,
            keys,
            (_, siteValue, formValue) => {
              return formValue || siteValue;
            },
          ),
        };
      },
    };
  };
