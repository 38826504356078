import { CommonButton, CommonLink } from 'components/common/Forms/Button';
import { Loading } from 'components/common/Loading';
import { NhsLogoWrapper, StyledNhsLogo } from 'components/Navbar/Navbar.styled';
import { InlineConfirm } from 'components/Popup/ConfirmDialog/InlineConfirm';
import { PatientLayout } from 'containers/PatientLayout';
import { useSite } from 'context/SiteContext';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { useMutateCancelAppointmentByToken } from 'query/appointments';
import { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { FaCalendarTimes } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router';
import { patientURL } from 'routes/AppRoutes';
import { SiteData } from 'types/sites';
import {
  DATE_FNS_TIME,
  DATE_FNS_UK_DATE_FORMAT,
  formatDateInServerTZ,
} from 'utils/dateUtil';
import { decodeJWT } from 'utils/decodeJWT';
import { successToast } from 'utils/toast';

const useApptTime = () => {
  const history = useHistory();
  const params = useParams<{ token: string }>();
  return useMemo(() => {
    const decoded = decodeJWT(params.token);
    if (
      !decoded ||
      typeof decoded !== 'object' ||
      typeof decoded.exp !== 'number'
    ) {
      history.replace('/404');
      return { date: '', time: '' };
    }
    const datetime = new Date(decoded.exp * 1000);
    const date = formatDateInServerTZ(datetime, DATE_FNS_UK_DATE_FORMAT);
    const time = formatDateInServerTZ(datetime, DATE_FNS_TIME);
    return { date, time };
  }, [params.token, history]);
};
const useOnCancel = () => {
  const { t } = useCommonTranslation();
  const history = useHistory();
  const params = useParams<{ token: string }>();
  const { mutateAsync, isLoading } = useMutateCancelAppointmentByToken();
  const onCancel = useCallback(async () => {
    await mutateAsync(params.token);
    successToast(t('email-cancellation-success'));
    history.push(patientURL);
  }, [mutateAsync, params.token, history, t]);
  return { onCancel, isLoading };
};

export const EmailCancellation = () => {
  const { t } = useCommonTranslation();
  const activeSite = useSite().activeSite as SiteData | null;
  const { date, time } = useApptTime();
  const { onCancel, isLoading } = useOnCancel();

  return (
    <PatientLayout graphics="unauth">
      <Helmet title={t('email-cancel-appt')} />
      {isLoading && <Loading />}
      <NhsLogoWrapper dialog>
        <StyledNhsLogo />
        {activeSite?.name}
      </NhsLogoWrapper>
      <InlineConfirm
        icon={<FaCalendarTimes />}
        inlineIcon
        title={t('email-cancellation-title', { date, time })}
        description={t('email-cancellation-description')}
        status="success"
        actions={[
          <CommonButton
            variant="danger"
            type="button"
            key="yes"
            onClick={onCancel}
          >
            {t('yes-cancel')}
          </CommonButton>,
          <CommonLink
            variant="secondary"
            type="button"
            key="no"
            to={patientURL}
          >
            {t('exit')}
          </CommonLink>,
        ]}
      />
    </PatientLayout>
  );
};
