import { AxiosError } from 'axios';
import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { Card } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import { Col, Grid } from 'components/common/Grid';
import { PageHelmet } from 'components/common/PageHelmet';
import { ConfirmDialog } from 'components/Popup/ConfirmDialog/ConfirmDialog';
import { TableActions } from 'components/Table/Table.styled';
import CCGListForm from 'components/views-components/staff/settings/ccgList/CCGListForm';
import { CCGListTable } from 'components/views-components/staff/settings/ccgList/CCGListTable';
import { StaffSettingsTabsTree } from 'configs/RoutesConfig';
import { useSite } from 'context/SiteContext';
import React, { useEffect, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import {
  CCGFilters,
  CCGFormValues,
  ClinicalCommissioningGroup,
} from 'types/ccg';
import { errorToast, successToast } from 'utils/toast';
import { StaffLayout } from '../../StaffLayout';
import { usePaginatedFilters } from '../../../../hooks/usePaginatedFilters';
import {
  useMutateCreateCCG,
  useMutateDeleteCCG,
  useMutateUpdateCCG,
  useQueryCCG,
} from '../../../../query/ccg';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useHistory } from 'react-router';
import { STAFF_ICBS_MERGE_URL } from '../../../../routes/AppRoutes';
import { checkErrorCode } from '../../../../utils/errors';
import { ErrorCode } from '../../../../utils/errorCodes';
import { useConfirmDialog } from '../../../../components/Popup/ConfirmDialog/confirmDialogHook';

export const ICBs = () => {
  const { t } = useCommonTranslation();
  const { activeSite } = useSite();
  const { push } = useHistory();
  const { setFilters, filters } = usePaginatedFilters<CCGFilters>();
  const {
    isLoading: isListLoading,
    isFetching: isListFetching,
    data: ccgData,
  } = useQueryCCG(filters);
  const { isLoading: isCreateLoading, mutate: createCCG } = useMutateCreateCCG({
    onSuccess: () => {
      successToast(t('ccg-added-successfully'));
      closeFormHandler();
    },
  });
  const { isLoading: isUpdateLoading, mutate: updateCCG } = useMutateUpdateCCG({
    onSuccess: () => {
      successToast(t('changes-have-been-saved'));
      closeFormHandler();
    },
  });
  const { confirm } = useConfirmDialog();
  const { isLoading: isDeleteLoading, mutate: deleteCCG } = useMutateDeleteCCG({
    onError: (err: AxiosError) => {
      if (checkErrorCode(err, ErrorCode.CCG_USED_BY_OTHER_DATA_CG0003)) {
        confirm({
          title: t('delete-ccg-used-dialog-title'),
          confirmButtonText: t('delete-ccg-go-to-merge'),
          icon: <FaTrashAlt />,
          onConfirm: () => {
            push(STAFF_ICBS_MERGE_URL);
          },
        });
      } else {
        errorToast(err);
      }
    },
    onSuccess: () => {
      successToast(t('ccg-delete-success'));
      closeFormHandler();
    },
  });
  const isLoading =
    isCreateLoading || isUpdateLoading || isCreateLoading || isDeleteLoading;
  const [showForm, setShowForm] = useState(false);
  const [prefilledData, setPrefilledData] = useState<
    ClinicalCommissioningGroup | undefined
  >();
  const [confirmDeleteItem, setConfirmDeleteItem] =
    useState<ClinicalCommissioningGroup | null>();

  const submitHandler: (data: CCGFormValues, isUpdateCCG: boolean) => void = (
    data,
    isUpdateCCG,
  ) => {
    const structuredData: CCGFormValues = {
      ...data,
      trust_id: activeSite?.trust_id as string,
    };

    Boolean(isUpdateCCG) && Boolean(prefilledData)
      ? updateCCG({ ...prefilledData, ...structuredData })
      : createCCG(structuredData);
  };

  const closeFormHandler = (err?: AxiosError | true) => {
    setShowForm(false);
    setPrefilledData(undefined);
    setConfirmDeleteItem(null);
    if (err) {
      errorToast(err);
    }
  };

  const openFormHandler = (value?: string) => {
    setShowForm(false);
    setTimeout(() => {
      if (value) {
        const selectedCCG = ccgData?.data.data?.find((ccg) => ccg.id === value);

        if (selectedCCG) {
          setPrefilledData(selectedCCG);
          setShowForm(true);
        } else {
          closeFormHandler(true);
        }
      } else {
        setPrefilledData(undefined);
        setShowForm(true);
      }
    }, 50);
  };

  const handleDeleteCCG = (value: string) => {
    setConfirmDeleteItem(ccgData?.data.data?.find((ccg) => ccg.id === value));
  };

  const handleConfirmDeleteGP = () => {
    deleteCCG(confirmDeleteItem?.id as string);
  };

  useEffect(() => {
    closeFormHandler();
  }, [activeSite?.id, filters]);

  return (
    <StaffLayout>
      <PageHelmet title={t('ccg-list')} />
      <Grid>
        <Col md={8}>
          <Card fillHeight flex>
            <LinkCardTabs withDivider tabs={StaffSettingsTabsTree(t)} />
            <TableActions>
              <CommonButton variant="primary" onClick={() => openFormHandler()}>
                {t('add-ccg')}
              </CommonButton>
              <CommonButton
                variant="primary"
                onClick={() => push(STAFF_ICBS_MERGE_URL)}
              >
                {t('ccgs-merge')}
              </CommonButton>
            </TableActions>
            <CCGListTable
              ccgData={ccgData?.data}
              openFormHandler={openFormHandler}
              handleDeleteCCG={handleDeleteCCG}
              setFilters={setFilters}
              filters={filters}
              isLoading={isListLoading}
              isFetching={isListFetching}
            />
          </Card>
        </Col>
        <Col md={4}>
          {showForm && (
            <CCGListForm
              closeFormHandler={closeFormHandler}
              submitHandler={submitHandler}
              isLoading={isLoading}
              prefilledData={prefilledData}
              handleDeleteCCG={handleDeleteCCG}
            />
          )}
        </Col>
      </Grid>
      {Boolean(confirmDeleteItem) && (
        <ConfirmDialog
          isLoading={isLoading}
          handleClose={() => closeFormHandler()}
          icon={<FaTrashAlt />}
          title={t('confirm-delete-ccg')}
          status="delete"
          actions={[
            <CommonButton
              key={confirmDeleteItem?.id + '-cancel'}
              variant="secondary"
              onClick={() => {
                setConfirmDeleteItem(null);
              }}
            >
              {t('cancel')}
            </CommonButton>,
            <CommonButton
              variant="danger"
              onClick={handleConfirmDeleteGP}
              key={confirmDeleteItem?.id + '-delete'}
            >
              {t('yes')}
            </CommonButton>,
          ]}
        />
      )}
    </StaffLayout>
  );
};
