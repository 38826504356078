import AutoComplete from 'components/common/Forms/AutoComplete';
import { FormRow } from 'components/common/Forms/Forms.styled';
import { AutoCompleteLabel } from 'components/views-components/un-auth/SignupForm/SignupForm.styled';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { get } from 'lodash';
import { ReactNode } from 'react';
import {
  Control,
  Controller,
  FieldError,
  FormState,
  Path,
  RegisterOptions,
} from 'react-hook-form';
import { getSiteByName } from 'services/sites';
import { SiteData } from 'types/sites';

export type SiteAutoCompleteProps<FormValues extends object> = {
  name: Path<FormValues>;
  disabled?: boolean;
  control: Control<FormValues>;
  formState: FormState<FormValues>;
  label: ReactNode;
  rules?: Omit<
    RegisterOptions<FormValues, Path<FormValues>>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
};

export const SiteAutoComplete = <FormValues extends object>({
  name,
  disabled,
  control,
  formState,
  label,
  rules = {},
}: SiteAutoCompleteProps<FormValues>) => {
  const { t } = useCommonTranslation();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: t('must-not-empty') as string,
        ...rules,
      }}
      render={({ field }) => {
        const error: FieldError | undefined = get(formState.errors, name);
        return (
          <FormRow>
            <AutoCompleteLabel htmlFor={field.name}>{label}</AutoCompleteLabel>
            <AutoComplete
              fetchReq={getSiteByName}
              searchFunc={(suggestion: SiteData) => suggestion.name}
              renderSuggestion={(suggestion: any) => (
                <div>{suggestion.name}</div>
              )}
              error={error?.message}
              minChars={4}
              setFormValue={(value: SiteData) => field.onChange(value)}
              name={field.name}
              placeholder={t('suggestion')}
              disabled={disabled}
            />
          </FormRow>
        );
      }}
    />
  );
};
