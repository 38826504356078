import React from 'react';
import { AdminStatisticsSiteOverview } from './statistics/AdminStatisticsSiteOverview';
import { AdminStatisticsLastFiveYears } from './statistics/AdminStatisticsLastFiveYears';
import { useSADApptStatistics } from '../../query/reports';

export const AdminStatisticsPage = ({
  selected,
}: {
  selected: 'OVERVIEW' | 'LAST_FIVE_YEARS';
}) => {
  const { data, isLoading } = useSADApptStatistics();
  return (
    <>
      {selected === 'OVERVIEW' && (
        <AdminStatisticsSiteOverview
          sadStatistics={data}
          isLoading={isLoading}
        />
      )}
      {selected === 'LAST_FIVE_YEARS' && (
        <AdminStatisticsLastFiveYears
          sadStatistics={data}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
