import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '../../components/common/ProtectedRoute';
import { SignupSuccess } from '../../components/views-components/un-auth/SignupForm/SignupSuccess';
import { SIGNUP_SUCCESS } from '../../configs/constants';
import { PatientBookingPage } from '../../containers/patient/booking/PatientBookingPage';
import { PatientCheckInPage } from '../../containers/patient/checkIn/PatientCheckInPage';
import { MyAppointmentsBooked } from '../../containers/patient/myAppointments/MyAppointmentsBooked';
import { MyAppointmentsPast } from '../../containers/patient/myAppointments/MyAppointmentsPast';
import { WaitingListPage } from '../../containers/patient/waitingList/WaitingListPage';
import { BOOKING_ROUTE, patientURL } from '../AppRoutes';
import PatientHome from '../app-routes/patient/PatientHome';
import PatientReleaseNotes from '../app-routes/patient/PatientReleaseNotes';
import ProfileChangePassword from '../app-routes/patient/profile/ProfileChangePassword';
import ProfilePage from '../app-routes/patient/profile/ProfilePage';
import ProfileSettingsPage from '../app-routes/patient/profile/ProfileSettingsPage';
import SharedAccountsPage from '../app-routes/patient/profile/SharedAccountsPage';
import { AddHospital } from './AddHospital';

export const PatientRoutes: React.FunctionComponent = () => {
  return (
    <Switch>
      <ProtectedRoute
        exact
        path={`${patientURL}/release-notes`}
        component={PatientReleaseNotes}
      />
      <ProtectedRoute exact path={SIGNUP_SUCCESS} component={SignupSuccess} />
      <ProtectedRoute
        exact
        path={`${patientURL}/profile`}
        component={ProfilePage}
      />
      <ProtectedRoute
        exact
        path={`${patientURL}/shared-accounts`}
        component={SharedAccountsPage}
      />
      <ProtectedRoute
        exact
        path={`${patientURL}/profile/settings`}
        component={ProfileSettingsPage}
      />
      <ProtectedRoute
        exact
        path={`${patientURL}/change-password`}
        component={ProfileChangePassword}
      />
      <ProtectedRoute
        exact
        path={BOOKING_ROUTE}
        component={PatientBookingPage}
      />
      <ProtectedRoute
        exact
        path={`${patientURL}/waiting-list/:memberId/:page?`}
        component={WaitingListPage}
      />
      <ProtectedRoute
        exact
        path={`${patientURL}/check-in`}
        component={PatientCheckInPage}
      />
      <ProtectedRoute
        exact
        path={`${patientURL}/add-hospital`}
        component={AddHospital}
      />
      <ProtectedRoute
        exact
        path={`${patientURL}/my-appointments`}
        component={MyAppointmentsBooked}
      />
      <ProtectedRoute
        exact
        path={`${patientURL}/my-appointments/past`}
        component={MyAppointmentsPast}
      />
      <ProtectedRoute exact path={patientURL} component={PatientHome} />
    </Switch>
  );
};
