import { CommonButton as Button } from 'components/common/Forms/Button';
import { getRouteFromRole } from 'configs/RoutesConfig';
import { Fragment, ReactElement } from 'react';
import { FaUserCheck } from 'react-icons/fa';
import { useHistory } from 'react-router';

import { PatientLayout } from '../../../../containers/PatientLayout';
import { useRole } from '../../../../context/RoleContext';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { PageHelmet } from '../../../common/PageHelmet';
import {
  FormWrapper,
  SuccessMessage,
  SuccessWrapper,
} from './SignupForm.styled';

export const SignupSuccess = (): ReactElement => {
  const { t } = useCommonTranslation();
  const { replace } = useHistory();
  const { currentUserRole } = useRole();
  return (
    <PatientLayout graphics="unauth">
      <PageHelmet title={t('your-account-created')} />
      <Fragment>
        <FormWrapper as={'div'}>
          <SuccessWrapper>
            <FaUserCheck />
            <SuccessMessage>{t('your-account-created')}</SuccessMessage>
            <Button
              variant="primary"
              onClick={() => {
                replace(getRouteFromRole(currentUserRole!.id));
              }}
            >
              {t('continue')}
            </Button>
          </SuccessWrapper>
        </FormWrapper>
      </Fragment>
    </PatientLayout>
  );
};
