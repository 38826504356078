import { CommonButton } from 'components/common/Forms/Button';
import DatePickerInput from 'components/common/Forms/DatePickerInput';
import Select from 'components/common/Forms/Select';
import { WaitingListFormValues } from 'hooks/patient/waitingList/useWaitingList';
import { useSiteIntAttributes } from 'hooks/useAttribute';
import { BookingSlot } from 'hooks/site/useSiteHourRange';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { get } from 'lodash';
import {
  Controller,
  FieldError,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { FaCalendarAlt } from 'react-icons/fa';
import { SelectOption } from 'types/common';
import { WaitingListRow as WaitingListRowStyled } from './WaitingList.styled';
import { STAFF_CLOSE_HOUR_IN_SECONDS } from '../../../configs/siteAndTrustAttributes';
import { SECONDS_IN_HOUR } from '../../../utils/dateUtil';
import { useMaxAndMinFutureDateForBookingForOnlineBooking } from '../../../hooks/patient/booking/useMaxAndMinFutureDateForBooking';

const hourToSelect = (hour: number) => ({
  key: hour,
  label: String(hour),
  value: hour,
});

type WaitingListRowProps = {
  index: number;
  slots: BookingSlot[];
};
export const WaitingListRow = ({ index, slots }: WaitingListRowProps) => {
  const { t } = useCommonTranslation();
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<WaitingListFormValues>();
  const [closeHourInSeconds = 0] = useSiteIntAttributes(
    STAFF_CLOSE_HOUR_IN_SECONDS,
  );
  const closeHour = Math.ceil(closeHourInSeconds / SECONDS_IN_HOUR);
  const hourRange = slots.map((slot) => slot.hour);
  const hourFrom = useWatch<WaitingListFormValues, `list.${number}.hourFrom`>({
    name: `list.${index}.hourFrom`,
  });
  const hourTo = useWatch<WaitingListFormValues, `list.${number}.hourTo`>({
    name: `list.${index}.hourTo`,
  });
  const hourFromOptions: SelectOption<number>[] = hourRange.map(hourToSelect);
  const hourToOptionsValues = hourFrom
    ? [...hourRange, closeHour].filter((hour) => hour > hourFrom)
    : [...hourRange, closeHour].slice(1); // remove the site open hour, as open hour cannot be "Hour to"
  const hourToOptions = hourToOptionsValues.map(hourToSelect);

  const { minDate, maxDate } =
    useMaxAndMinFutureDateForBookingForOnlineBooking();

  return (
    <WaitingListRowStyled>
      <Controller
        control={control}
        name={`list.${index}.date`}
        render={({ field }) => {
          const error: FieldError = get(errors, `list.${index}.date`);
          return (
            <DatePickerInput
              minDate={minDate}
              maxDate={maxDate}
              label={t('date')}
              noSpaceForHelperText
              iconRight
              name={field.name}
              onChange={(date) => field.onChange(date)}
              selected={field.value || undefined}
              hasError={!!error}
              helperText={error?.message}
              icon={<FaCalendarAlt />}
              flexWidth
            />
          );
        }}
      />
      <Controller
        control={control}
        name={`list.${index}.hourFrom`}
        render={({ field }) => {
          const error: FieldError = get(errors, `list.${index}.hourFrom`);
          return (
            <Select
              label={t('hour-from')}
              options={hourFromOptions}
              value={field.value}
              name={field.name}
              onChange={(hourFromValue) => {
                const hourFrom = hourFromValue as number;
                field.onChange(hourFrom);
                if (hourTo && hourTo <= hourFrom) {
                  setValue(`list.${index}.hourTo`, null);
                }
              }}
              hasError={!!error}
              helperText={error?.message}
              flexWidth
              noSpaceForHelperText
            />
          );
        }}
      />
      <Controller
        control={control}
        name={`list.${index}.hourTo`}
        render={({ field }) => {
          const error: FieldError = get(errors, `list.${index}.hourTo`);

          return (
            <Select
              label={t('hour-to')}
              options={hourToOptions}
              value={field.value}
              name={field.name}
              onChange={(hourFrom) => field.onChange(hourFrom)}
              hasError={!!error}
              helperText={error?.message}
              flexWidth
              noSpaceForHelperText
            />
          );
        }}
      />
      <CommonButton
        type="button"
        variant="danger"
        onClick={() => {
          setValue(`list.${index}.date`, null, { shouldDirty: true });
          setValue(`list.${index}.hourFrom`, null, { shouldDirty: true });
          setValue(`list.${index}.hourTo`, null, { shouldDirty: true });
        }}
      >
        {t('clear')}
      </CommonButton>
    </WaitingListRowStyled>
  );
};
export const WaitingListRows = ({ slots }: { slots: BookingSlot[] }) => {
  const { fields } = useFieldArray<WaitingListFormValues>({ name: 'list' });
  return (
    <div data-testid={'waitingListRows'}>
      {fields.map(({ id }, index) => (
        <WaitingListRow key={id} index={index} slots={slots} />
      ))}
    </div>
  );
};
