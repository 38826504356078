import ReactGA from 'react-ga4';

import { GA_MEASUREMENT_ID } from '../configs/constants';

export const initGA = () => {
  if (GA_MEASUREMENT_ID) {
    ReactGA.initialize(GA_MEASUREMENT_ID);
  } else {
    /**
     * Unfortunately library doesn't support `testMode` without passing `GA_MEASUREMENT_ID`.
     * Test mode doesn't send anything to GA, just process it locally.
     * It is useful to have the test mode enabled in non prod envs to see
     * the processing doesn't cause any issue.
     */
    ReactGA.initialize('google-analytics-test-mode-id', {
      testMode: true,
    });
  }
};
