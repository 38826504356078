import React from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { UseFormRegister } from 'react-hook-form/dist/types/form';

import { FormRow } from '../../../components/common/Forms/Forms.styled';
import Input from '../../../components/common/Forms/Input';
import { useUserValidationRules } from '../../../hooks/patient/useUserValidationRules';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { TrustData } from '../../../types/trusts';
import { getHookFormTyped } from '../../../utils/helpers';
import {
  nhsNumberRequiredTrusts,
  postCodeRequiredTrusts,
  shouldRenderNhsNumberTrusts,
} from '../../../utils/trustUtil';

export type NhsPostcodeForm = {
  postcode?: string;
  nhs_number?: string;
} & { [key: string]: any };

interface IPatientNhsAndPostcodeDataProps<T extends NhsPostcodeForm> {
  register: UseFormRegister<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  trusts?: TrustData[];
}

export const PatientNhsPostcodeData = <T extends NhsPostcodeForm>({
  trusts,
  ...rest
}: IPatientNhsAndPostcodeDataProps<T>) => {
  const { register, errors } = getHookFormTyped<NhsPostcodeForm, T>(rest);

  const { t } = useCommonTranslation();
  const { nhsNumberRules, postcodeRules } = useUserValidationRules({
    trusts,
  });

  return (
    <>
      <FormRow>
        {trusts && postCodeRequiredTrusts(trusts) && (
          <Input
            label={t('postcode')}
            placeholder={t('postcode')}
            {...register('postcode', postcodeRules)}
            helperText={errors.postcode?.message}
            hasError={Boolean(errors?.postcode)}
            required
          />
        )}
        {trusts && shouldRenderNhsNumberTrusts(trusts) && (
          <Input
            label={t('nhs-number')}
            placeholder={t('nhs-number')}
            {...register('nhs_number', nhsNumberRules)}
            helperText={errors?.nhs_number?.message}
            hasError={Boolean(errors?.nhs_number)}
            required={nhsNumberRequiredTrusts(trusts)}
          />
        )}
      </FormRow>
    </>
  );
};
