import { Card } from 'components/common';
import { Col } from 'components/common/Grid';
import { EmailingRulingForm } from 'containers/common/site/emails/EmailingRulingForm';
import { EmailSubjectsForm } from 'containers/common/site/emails/EmailSubjectsForm';
import { ServerSettings } from 'containers/common/site/emails/ServerSettings';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FormGridBody } from '../../../../components/common/Forms/Forms.styled';
import { PromptWithBeforeUnload } from '../../../../components/Form/PromptWithBeforeUnload';
import { useIsDirty } from '../../../../hooks/form/useIsDirty';
import useAttributesForm from '../../../../hooks/useAttributesForm';
import { SiteData } from '../../../../types/sites';
import {
  ISitePreferencesProps,
  SITE_PREFERENCES_FORM_ID,
} from '../SitePreferences';
import {
  useMergeSiteFormHooks,
  useSiteFormSubmit,
} from '../SitePreferencesHooks';
import { useEmailingRulingFormHooks } from './EmailingRulingFormHooks';
import {
  EmailsPreferencesFormFieldValues,
  UseEmailsPreferencesFormHooksReturn,
} from './EmailsPreferencesHooks';
import { useEmailSubjectsFormHooks } from './EmailSubjectsFormHooks';
import { useServerSettingsFormHooks } from './ServerSettingsHooks';

type Props = ISitePreferencesProps & {
  site: SiteData;
};

export const EmailsPreferences = (props: Props) => {
  const { site } = props;
  const formPartsHooks: UseEmailsPreferencesFormHooksReturn[] = [
    useServerSettingsFormHooks({ site }),
    useEmailSubjectsFormHooks({ site }),
    useEmailingRulingFormHooks({ site }),
  ];

  const { defaultValues, restAttributes, beforeSubmit } = useMergeSiteFormHooks(
    { site, hooks: formPartsHooks },
  );

  const theForm = useForm<EmailsPreferencesFormFieldValues>({
    defaultValues,
  });

  useEffect(() => {
    theForm.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const attributesForm = useAttributesForm<EmailsPreferencesFormFieldValues>({
    theForm,
    currentAttrs: site.attributes,
    restAttributes,
    dep: [site],
  });

  const isDirty = useIsDirty(attributesForm.formState);

  const submitForm = useSiteFormSubmit({
    beforeSubmit,
    ...props,
    ...attributesForm,
  });

  return (
    <FormGridBody id={SITE_PREFERENCES_FORM_ID} onSubmit={submitForm}>
      <PromptWithBeforeUnload when={isDirty}></PromptWithBeforeUnload>
      <Col sm={6}>
        <Card mb="var(--s4)">
          <ServerSettings attributesForm={attributesForm} {...props} />
        </Card>
        <Card mb="var(--s4)">
          <EmailSubjectsForm attributesForm={attributesForm} {...props} />
        </Card>
      </Col>
      <Col sm={6}>
        <Card mb="var(--s4)">
          <EmailingRulingForm attributesForm={attributesForm} {...props} />
        </Card>
      </Col>
    </FormGridBody>
  );
};
