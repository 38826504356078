import { CommonButton, CommonLink } from 'components/common/Forms/Button';
import { Loading } from 'components/common/Loading';
import { useValidateCheckIn } from 'hooks/appointments/useValidateCheckIn';
import { useSiteStringAttributes } from 'hooks/useAttribute';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { useMutateCheckInByCode } from 'query/appointments';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { patientURL } from 'routes/AppRoutes';
import { errorToast } from 'utils/toast';

import { useSelfCheckInAppointments } from '../../../hooks/patient/checkIn/useSelfCheckInAppointments';
import { MILLIS_IN_MINUTE } from '../../../utils/dateUtil';
import { ErrorCode } from '../../../utils/errorCodes';
import { checkErrorCode } from '../../../utils/errors';
import {
  CheckInNumberInput,
  FormButtonBar,
  FormInputBar,
  InputLabel,
  PatientCheckInFormStyled,
} from './PatientCheckIn.styled';
import { PatientCheckInLayout } from './PatientCheckInLayout';
import { CHECK_IN_SUCCESS_TEXT } from '../../../configs/siteAndTrustAttributes';

type PatientCheckInFormValues = {
  code: number;
};

export const PatientCheckInForm = () => {
  const { t } = useCommonTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<PatientCheckInFormValues>();
  const codeInput = register('code', {
    required: t('patient-check-in-enter-valid-code') as string,
    minLength: { value: 4, message: t('patient-check-in-enter-valid-code') },
    maxLength: { value: 4, message: t('patient-check-in-enter-valid-code') },
    pattern: {
      value: /^\d\d\d\d$/,
      message: t('patient-check-in-enter-valid-code'),
    },
  });
  const [checkinSuccessText] = useSiteStringAttributes(CHECK_IN_SUCCESS_TEXT);
  const history = useHistory();
  const getEarlyLateCheckinErrorMessage = useValidateCheckIn();
  const { appts: apptsFound, isFetching: isQueryFetching } =
    useSelfCheckInAppointments();
  const [displayInvalidCode, setDisplayInvalidCode] = useState(false);
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    if (displayInvalidCode) {
      timeout = setTimeout(
        () => setDisplayInvalidCode(false),
        MILLIS_IN_MINUTE,
      );
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [displayInvalidCode]);

  const [displaySuccess, setDisplaySuccess] = useState(false);
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    if (displaySuccess) {
      timeout = setTimeout(() => history.push(patientURL), MILLIS_IN_MINUTE);
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [displaySuccess, history]);

  const { mutate, isLoading: isMutationLoading } = useMutateCheckInByCode({
    onSuccess: () => {
      setDisplaySuccess(true);
    },
    onError: (error) => {
      const earlyLateMessage = getEarlyLateCheckinErrorMessage(error);
      if (earlyLateMessage) {
        errorToast(earlyLateMessage);
      } else if (checkErrorCode(error, ErrorCode.INVALID_CODE_AP0011)) {
        setDisplayInvalidCode(true);
      } else {
        errorToast(error);
      }
    },
  });
  const isLoading = isQueryFetching || isMutationLoading;

  const onSubmit = useCallback(
    ({ code }) => {
      if (apptsFound.length === 0) {
        errorToast(t('patient-check-in-no-appointment'));
        return;
      }
      mutate({ apptId: apptsFound[0].id, code });
    },
    [apptsFound, mutate, t],
  );

  let description:
    | {
        html: ReactNode;
        compact?: boolean;
      }
    | undefined = undefined;
  let title: string | undefined = undefined;
  if (displayInvalidCode) {
    title = t('self-checkin-invalid-code-title');
    description = {
      html: t('self-checkin-invalid-code'),
    };
  } else if (displaySuccess) {
    description = {
      html: checkinSuccessText || t('self-checkin-success'),
    };
  } else {
    description = {
      html: (
        <>
          {t('self-checkin-description-line-1')}
          <br />
          {t('self-checkin-description-line-2')}
        </>
      ),
      compact: true,
    };
  }

  return (
    <PatientCheckInLayout description={description} label={title}>
      {isLoading && <Loading />}
      <PatientCheckInFormStyled onSubmit={handleSubmit(onSubmit)}>
        {displayInvalidCode && (
          <FormButtonBar>
            <CommonButton
              variant="primary"
              type="submit"
              size="large"
              onClick={() => setDisplayInvalidCode(false)}
            >
              {t('ok')}
            </CommonButton>
          </FormButtonBar>
        )}
        {displaySuccess && (
          <FormButtonBar>
            <CommonButton
              variant="primary"
              type="submit"
              size="large"
              onClick={() => history.push(patientURL)}
            >
              {t('ok')}
            </CommonButton>
          </FormButtonBar>
        )}
        {!displaySuccess && !displayInvalidCode && (
          <>
            <FormInputBar>
              <InputLabel htmlFor={codeInput.name}>
                {t('patient-check-in-input-label')}
              </InputLabel>
              <CheckInNumberInput
                id={codeInput.name}
                {...codeInput}
                type="number"
                hasError={!!errors.code?.message}
                helperText={errors.code?.message}
                noSpaceForHelperText
              />
            </FormInputBar>
            <FormButtonBar>
              <CommonButton variant="primary" type="submit" size="large">
                {t('check-in')}
              </CommonButton>
              <CommonLink variant="secondary" size="large" to={patientURL}>
                {t('back-to-menu')}
              </CommonLink>
            </FormButtonBar>
          </>
        )}
      </PatientCheckInFormStyled>
    </PatientCheckInLayout>
  );
};
