import styled from 'styled-components';
import { Card } from '../../../components/common';
import { toRemStr } from '../../../utils/helpers';
import { fullscreenMaxContentWithRemPx } from '../../../styles/sizes';

export const FlexBoxSameFlex = styled.div`
  display: flex;
  justify-content: center;
  column-gap: var(--s4);
  flex-wrap: wrap;

  > * {
    flex: 1;
    min-width: fit-content;
  }
`;

export const QueuedAppointmentProcessCard = styled(Card)`
  max-width: ${toRemStr(fullscreenMaxContentWithRemPx)};
  flex: 1;
`;
