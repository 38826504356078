import { useSite } from '../context/SiteContext';
import { SiteAttribute } from '../types/sites';
import {
  useSpecificSiteAttribute,
  useSpecificSiteAttributes,
  useSpecificSiteBooleanAttributes,
  useSpecificSiteIntAttributes,
  useSpecificSiteNumericArrayAttribute,
  useSpecificSiteStringAttributes,
} from './useSpecificSiteAttribute';

const useActiveSite = () => {
  const { activeSite } = useSite();
  return activeSite;
};

export const useSiteAttributes = (...attributeKeys: SiteAttribute['key'][]) => {
  return useSpecificSiteAttributes(useActiveSite(), ...attributeKeys);
};

export const useSiteAttribute = (attributeKey: SiteAttribute['key']) => {
  return useSpecificSiteAttribute(useActiveSite(), attributeKey);
};

export const useSiteNumericArrayAttribute = (
  attributeKey: SiteAttribute['key'],
): number[] | undefined => {
  return useSpecificSiteNumericArrayAttribute(useActiveSite(), attributeKey);
};

export const useSiteStringAttributes = (
  ...attributeKey: SiteAttribute['key'][]
) => {
  return useSpecificSiteStringAttributes(useActiveSite(), ...attributeKey);
};

export const useSiteIntAttributes = (
  ...attributeKey: SiteAttribute['key'][]
) => {
  return useSpecificSiteIntAttributes(useActiveSite(), ...attributeKey);
};

export const useSiteBooleanAttributes = (
  ...attributeKey: SiteAttribute['key'][]
) => {
  return useSpecificSiteBooleanAttributes(useActiveSite(), ...attributeKey);
};
