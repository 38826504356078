import StaffSettingsEmails from 'containers/staff/settings/emails/StaffSettingsEmails';
import { StaffDefaultTimePlanning } from 'containers/staff/settings/time-planning/StaffDefaultTimePlanning';
import { StaffTimeDayPicker } from 'containers/staff/settings/time-planning/StaffTimeDayPicker';
import { StaffSupportPage } from 'containers/staff/support/StaffSupportPage';
import React, { useEffect } from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';

import { ProtectedRoute } from '../../components/common/ProtectedRoute';
import {
  ALL_ROLES_BUT_SPECIALIST,
  ALL_STAFF_ROLES_BUT_RECEPTIONIST_AND_THIRD_PARTY_AND_SURGERY,
  ALL_STAFF_ROLES_BUT_SPECIALIST_AND_THIRD_PARTY_AND_SURGERY,
  GET_EMAIL_TEMPLATE_ROLES,
  GET_STAFF_RELEASENOTE_ROLES,
  SPECIALIST,
} from '../../configs/constants';
import { PatientReport } from '../../containers/staff/reports/PatientReport';
import { Reports } from '../../containers/staff/reports/Reports';
import { Statistics } from '../../containers/staff/reports/Statistics';
import { Supervisor } from '../../containers/staff/reports/Supervisor';
import { StaffSettingsCalendarTemplates } from '../../containers/staff/settings/cubicle/StaffSettingsCalendarTemplates';
import StaffSettingsCubicle from '../../containers/staff/settings/cubicle/StaffSettingsCubicle';
import {
  StaffSettings,
  staffSettingsAllowedTabs,
} from '../../containers/staff/settings/preferences/StaffSettings';
import { StaffBankHolidays } from '../../containers/staff/settings/time-planning/StaffBankHolidays';
import StaffMassCancel from '../../containers/staff/settings/time-planning/StaffMassCancel';
import { StaffPatientsPage } from '../../containers/staff/settings/users/StaffPatientsPage';
import { StaffUsersMerge } from '../../containers/staff/settings/users/StaffUsersMerge';
import { StaffUsersPage } from '../../containers/staff/settings/users/StaffUsersPage';
import StaffAppointments from '../../containers/staff/StaffAppointments';
import { StaffCalendar } from '../../containers/staff/StaffCalendar';
import { StaffCalledAndProcessingAppt } from '../../containers/staff/StaffCalledAndProcessingAppt';
import StaffCubicles from '../../containers/staff/StaffCubicles';
import { StaffMenu } from '../../containers/staff/StaffMenu';
import StaffReleaseNote from '../../containers/staff/StaffReleaseNote';
import { useRole } from '../../context/RoleContext';
import { useCurrentUserUnfinishedAppts } from '../../hooks/staff/useCurrentUserUnfinishedAppts';
import {
  useHasAdminAccessToSelectedSite,
  useHasReportsAccessOnSelectedSite,
} from '../../hooks/user/useHasAdminAccessToSelectedSite';
import { STAFF_SETTINGS_REFERRALS_URL } from '../AppRoutes';
import { StaffReferralsSwitch } from './StaffReferralsSwitch';
import { StaffProfile } from '../../containers/staff/StaffProfile';
import StaffChangePassword from '../../containers/staff/StaffChangePassword';
import { StaffEnteredCubicleSwitch } from './StaffEnteredCubicleSwitch';
import { STAFF_ENTERED_CUBICLE_URL } from '../../configs/RoutesConfig';

export const queuedProcessingApptRoute = (apptId: string) =>
  `/staff/cubicle/appt/${apptId}`;

export const STAFF_CUBICLE_URL = '/staff/cubicle';

export const StaffRoutesAndMenu: React.FunctionComponent = () => {
  const { hasAnyOfRoles } = useRole();
  const hasAdminAccessToSelectedSite = useHasAdminAccessToSelectedSite();
  const hasReportAccessToSelectedSite = useHasReportsAccessOnSelectedSite();
  const { userAppts } = useCurrentUserUnfinishedAppts();
  const { replace } = useHistory();
  useEffect(() => {
    if (userAppts && userAppts.length > 0) {
      replace(queuedProcessingApptRoute(userAppts[0].id));
    }
  }, [userAppts, replace]);

  return (
    <>
      <StaffMenu />
      <Switch>
        <ProtectedRoute
          exact
          path="/staff/calendar"
          component={StaffCalendar}
          hasAnyOfUserRoles={ALL_ROLES_BUT_SPECIALIST}
        />
        <ProtectedRoute
          exact
          path="/staff/appointments"
          component={StaffAppointments}
          hasAnyOfUserRoles={ALL_ROLES_BUT_SPECIALIST}
        />
        <ProtectedRoute
          path={STAFF_CUBICLE_URL}
          exact
          render={() => <StaffCubicles />}
          hasAnyOfUserRoles={
            ALL_STAFF_ROLES_BUT_RECEPTIONIST_AND_THIRD_PARTY_AND_SURGERY
          }
        />

        <ProtectedRoute path={STAFF_ENTERED_CUBICLE_URL}>
          <StaffEnteredCubicleSwitch />
        </ProtectedRoute>
        <ProtectedRoute
          path="/staff/cubicle/appt/:apptId"
          exact
          render={() => <StaffCalledAndProcessingAppt />}
          hasAnyOfUserRoles={
            ALL_STAFF_ROLES_BUT_RECEPTIONIST_AND_THIRD_PARTY_AND_SURGERY
          }
        />
        <ProtectedRoute
          path="/staff/contact-us"
          exact
          render={() => <StaffSupportPage />}
        />
        {hasAnyOfRoles(
          ...ALL_STAFF_ROLES_BUT_SPECIALIST_AND_THIRD_PARTY_AND_SURGERY,
        ) && (
          <ProtectedRoute path="/staff/settings">
            <Switch>
              <ProtectedRoute path="/staff/settings/users">
                <Switch>
                  <ProtectedRoute
                    path="/staff/settings/users/patients"
                    exact
                    render={() => <StaffPatientsPage />}
                  />
                  {hasAdminAccessToSelectedSite && (
                    <ProtectedRoute
                      path="/staff/settings/users/staff"
                      exact
                      render={() => <StaffUsersPage />}
                    />
                  )}
                  <ProtectedRoute
                    path="/staff/settings/users/merge"
                    exact
                    render={() => <StaffUsersMerge />}
                  />
                  <Redirect to="/staff/settings/users/patients" />
                </Switch>
              </ProtectedRoute>
              {/*Has to be one by one, if inside frame it gets broken.*/}
              {hasAdminAccessToSelectedSite && (
                <ProtectedRoute path="/staff/settings/preferences">
                  <Switch>
                    <ProtectedRoute
                      exact
                      path={`/staff/settings/preferences/:tab(${staffSettingsAllowedTabs.join(
                        '|',
                      )})`}
                      render={() => <StaffSettings />}
                    />
                    <Redirect to="/staff/settings/preferences/general" />
                  </Switch>
                </ProtectedRoute>
              )}
              {hasAdminAccessToSelectedSite && (
                <ProtectedRoute
                  path="/staff/settings/cubicle"
                  exact
                  render={() => <StaffSettingsCubicle />}
                />
              )}
              {hasAdminAccessToSelectedSite && (
                <ProtectedRoute
                  path="/staff/settings/cubicle/calendar-templates"
                  exact
                  render={() => <StaffSettingsCalendarTemplates />}
                />
              )}

              {hasAdminAccessToSelectedSite && (
                <ProtectedRoute
                  exact
                  path="/staff/settings/time-planning"
                  render={() => <StaffDefaultTimePlanning />}
                />
              )}

              {hasAdminAccessToSelectedSite && (
                <ProtectedRoute
                  exact
                  path="/staff/settings/time-planning/date-picker"
                  render={() => <StaffTimeDayPicker />}
                />
              )}
              {hasAdminAccessToSelectedSite && (
                <ProtectedRoute
                  exact
                  path="/staff/settings/time-planning/bank-holidays"
                  render={() => <StaffBankHolidays />}
                />
              )}
              {hasAdminAccessToSelectedSite && (
                <ProtectedRoute
                  exact
                  path="/staff/settings/time-planning/mass-cancel"
                  render={() => <StaffMassCancel />}
                />
              )}
              {hasAdminAccessToSelectedSite &&
                hasAnyOfRoles(...GET_EMAIL_TEMPLATE_ROLES) && (
                  <ProtectedRoute
                    path="/staff/settings/emails"
                    component={StaffSettingsEmails}
                  />
                )}
              <ProtectedRoute path={STAFF_SETTINGS_REFERRALS_URL}>
                <StaffReferralsSwitch />
              </ProtectedRoute>
              <Redirect to="/staff/settings/users" />
            </Switch>
          </ProtectedRoute>
        )}
        {hasReportAccessToSelectedSite && (
          <ProtectedRoute path="/staff/reports">
            <Switch>
              <ProtectedRoute
                exact
                path="/staff/reports/reports"
                render={() => <Reports />}
              />
              <ProtectedRoute
                exact
                path="/staff/reports/supervisor"
                render={() => <Supervisor />}
              />
              <ProtectedRoute
                exact
                path="/staff/reports/patients"
                render={() => <PatientReport />}
              />
              <ProtectedRoute
                exact
                path="/staff/reports/statistics"
                render={() => <Statistics />}
              />
              <Redirect to="/staff/reports/reports" />
            </Switch>
          </ProtectedRoute>
        )}
        {hasAnyOfRoles(...GET_STAFF_RELEASENOTE_ROLES) && (
          <ProtectedRoute
            exact
            path="/staff/release-notes"
            render={() => <StaffReleaseNote />}
          />
        )}
        <ProtectedRoute
          exact
          path="/staff/profile"
          render={() => <StaffProfile />}
        />
        <ProtectedRoute
          exact
          path="/staff/change-password"
          render={() => <StaffChangePassword />}
        />
        {!hasAnyOfRoles(SPECIALIST) ? (
          <Redirect to="/staff/calendar" />
        ) : (
          <Redirect to="/staff/cubicle" />
        )}
      </Switch>
    </>
  );
};
