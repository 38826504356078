import { useCommonTranslation } from '../i18n/useCommonTranslation';
import { useMemo } from 'react';
import { getColumn } from '../../utils/table';
import { AdminStatisticsDataItem } from './useAdminStatisticsSiteOverviewData';
import { CellProps, Column } from 'react-table';
import { tableRightHandSquishColumn } from '../../components/Table/TableUtils';
import { RowActions } from '../../components/Table/Table.styled';
import { CommonButton } from '../../components/common/Forms/Button';

export const useAdminStatisticsSiteOverviewColumns = ({
  currentYear,
  onSiteSummaryClicked,
}: {
  onSiteSummaryClicked: (siteName: string) => any;
  currentYear: number;
}) => {
  const { t } = useCommonTranslation();
  const columns = useMemo(() => {
    return [
      getColumn({
        Header: t('trust-name'),
        accessor: 'trustName',
        width: 20,
        disableFilters: true,
        disableSortBy: true,
      }),
      getColumn({
        Header: t('site-name'),
        accessor: 'siteName',
        width: 40,
        disableFilters: true,
        disableSortBy: true,
      }),
      getColumn({
        Header: t('ytd-with-year', {
          year: currentYear,
        }),
        accessor: 'ytd',
        width: 14,
        disableSortBy: true,
        disableFilters: true,
      }),
      getColumn({
        Header: t('last-year-with-year', {
          year: currentYear - 1,
        }),
        accessor: 'lastYear',
        width: 14,
        disableSortBy: true,
        disableFilters: true,
      }),
      getColumn({
        Header: t('all-time'),
        accessor: 'allTime',
        width: 14,
        disableSortBy: true,
        disableFilters: true,
      }),
      {
        accessor: 'id',
        id: 'id',
        ...tableRightHandSquishColumn({ minWidth: 13 }),
        Cell: ({ value }: CellProps<AdminStatisticsDataItem, string>) => {
          return (
            <RowActions>
              <CommonButton
                variant={'secondary'}
                onClick={() => {
                  onSiteSummaryClicked(value);
                }}
              >
                {t('admin-statistics-summary')}
              </CommonButton>
            </RowActions>
          );
        },
      },
    ] as Column<AdminStatisticsDataItem>[];
  }, [t, currentYear, onSiteSummaryClicked]);
  return { columns };
};
