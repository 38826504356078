import Axios from 'configs/Axios';
import { DateISOString } from '../utils/dateUtil';
import {
  AppointmentAuditFilter,
  AppointmentAuditResponseData,
  AppointmentFiltersNoPagination,
  AppointmentMassCancel,
  AppointmentsResponseData,
  AppointmentUpdate,
  AppointmentUpdateData,
  AvailableDaysData,
  ExistingAppointmentFilters,
  UserAppointmentResponseData,
  UserAppointmentsTypes,
} from 'types/appointments';

export const getAllAppointments = (data: AppointmentFiltersNoPagination) =>
  Axios.get<AppointmentsResponseData>('/appt', {
    params: data,
  });

export const getExistingAppointments = (data: ExistingAppointmentFilters) => {
  const params = {
    ...data,
    status: data.statuses?.join(','),
    resourceTypeID: data.resourceTypeIDs?.join(','),
  };
  delete params.statuses;
  delete params.resourceTypeIDs;
  return Axios.get<AppointmentsResponseData>('/appt/existing', {
    params: params,
  });
};

export const getAppointmentAudit = (filter: AppointmentAuditFilter) =>
  Axios.get<AppointmentAuditResponseData>(`/apptAudit`, { params: filter });

export const getUserAppointments = (
  userId: string,
  apptType: UserAppointmentsTypes,
) =>
  Axios.get<UserAppointmentResponseData>(`/appt/user/${userId}`, {
    params: { type: apptType },
  });

export const lockAppointment = (apptId: string) =>
  Axios.post(`/appt/${apptId}/lock`);

export const unlockAppointment = (apptId: string) =>
  Axios.post(`/appt/${apptId}/unlock`);

export const updateAppointment = (appointment: AppointmentUpdate) => {
  return Axios.put(`/appt/${appointment.id}`, appointment);
};

export const bookAppointment = (appointment: AppointmentUpdate) =>
  Axios.post(`/appt/${appointment.id}/book`, appointment);

export const appointmentOverflow = (appointment: AppointmentUpdateData) =>
  Axios.post(`/appt/overflow`, appointment);

export const queueAppointment = (appointmentId: string) =>
  Axios.post(`/appt/${appointmentId}/queue`);

export const callAppointment = (appointmentId: string) =>
  Axios.post(`/appt/${appointmentId}/call`);

export const notPreparedAppointment = (
  appointmentId: string,
  appointment: AppointmentUpdate,
) => Axios.post(`/appt/${appointmentId}/notPrepared`, appointment);

export const cancelAppointment = (appointmentId: string) =>
  Axios.post(`/appt/${appointmentId}/cancel`);

export const miaAppointment = (
  appointmentId: string,
  appointmentUpdate: AppointmentUpdate,
) => Axios.post(`/appt/${appointmentId}/mia`, appointmentUpdate);

export const processAppointment = (appointmentId: string) =>
  Axios.post(`/appt/${appointmentId}/process`);

export const appointmentTaken = (
  appointmentId: string,
  appointment: AppointmentUpdate,
) => Axios.post(`/appt/${appointmentId}/taken`, appointment);

export const appointmentNotTaken = (
  appointmentId: string,
  appointment: AppointmentUpdate,
) => Axios.post<void>(`/appt/${appointmentId}/notTaken`, appointment);

// for the patient
export type AvailableAppointmentsParams = {
  fromDate: DateISOString;
  toDate: DateISOString;
};
export const getAvailableAppointments = (params: AvailableAppointmentsParams) =>
  Axios.get<AppointmentsResponseData>(`/appt/available`, { params });
export const getAvailableDays = (params: AvailableAppointmentsParams) =>
  Axios.get<AvailableDaysData>(`/appt/availableDays`, { params });

export const cancelApptByToken = (token: string) =>
  Axios.get(`/appt/emailCancel/${token}`);

export const getUserCheckIn = () =>
  Axios.get<AppointmentsResponseData>('/userProfile/check-in');

export type CheckInPatientByCodeParams = {
  apptId: string;
  code: string;
};

export const checkInPatientByCode = (params: CheckInPatientByCodeParams) =>
  Axios.post<void>(`/appt/${params.apptId}/check-in/${params.code}`);

export const massCancelAppointment = (
  appointmentMassCancel: AppointmentMassCancel,
) => Axios.post<void>(`/appt/cancel`, appointmentMassCancel);
