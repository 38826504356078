import { PropsWithChildren } from 'react';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import {
  BulletHeaderTDInner,
  BulletHeaderTDStyled,
} from './BulletTable.styled';
import { SortDir } from './SortDir';

type SortIconProps = { dir?: SortDir; onClick?: () => void };
const SortIcon = ({ dir, ...rest }: SortIconProps) => {
  const Icon =
    dir === undefined ? FaSort : dir === 'asc' ? FaSortDown : FaSortUp;
  return <Icon {...rest} />;
};

type BulletHeaderTDProps = PropsWithChildren<{
  sort?: boolean;
  sortDir?: SortDir;
  onSortClick?: () => void;
}>;
export const BulletHeaderTD = ({
  children,
  sort,
  sortDir,
  onSortClick,
}: BulletHeaderTDProps) => {
  return (
    <BulletHeaderTDStyled>
      <BulletHeaderTDInner>
        {children}
        {sort && <SortIcon onClick={onSortClick} dir={sortDir} />}
      </BulletHeaderTDInner>
    </BulletHeaderTDStyled>
  );
};
