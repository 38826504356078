import React from 'react';
import { RouteProps } from 'react-router';
import { ProtectedRoute } from '../../components/common/ProtectedRoute';
import { useSite } from '../../context/SiteContext';
import {
  STAFF_OTHER_REFERRERS_MERGE_URL,
  STAFF_OTHER_REFERRERS_URL,
} from '../AppRoutes';
import { Redirect } from 'react-router-dom';
import { gpSurgeryRequiredTrust } from '../../utils/trustUtil';

export const StaffReferralsRoute: React.FunctionComponent<RouteProps> = ({
  path,
  ...rest
}) => {
  const { activeTrust } = useSite();
  if (
    !gpSurgeryRequiredTrust(activeTrust) &&
    path !== STAFF_OTHER_REFERRERS_URL &&
    path !== STAFF_OTHER_REFERRERS_MERGE_URL
  ) {
    return <Redirect to={STAFF_OTHER_REFERRERS_URL} />;
  }
  return <ProtectedRoute {...rest} path={path} />;
};
