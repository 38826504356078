import axios from 'axios';
import { BASE_URL } from './constants';
import { formatISO } from '../utils/dateUtil';
import { User } from 'firebase/auth';

const Axios = axios.create({
  baseURL: BASE_URL,
  headers: {
    // ! Just for Debugging
    DEBUG_REQ_TIME: formatISO(new Date()),
  },
});
let currentInterceptorId: number | undefined = undefined;

export const setOrReplaceAxiosInterceptor = (user?: User) => {
  if (currentInterceptorId !== undefined) {
    Axios.interceptors.request.eject(currentInterceptorId);
  }
  if (user) {
    currentInterceptorId = Axios.interceptors.request.use(
      async (config) => {
        const accessToken = await user.getIdToken();
        if (accessToken && config.headers['Authorization'] === undefined) {
          config.headers['Authorization'] = 'Bearer ' + accessToken;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
  }
};

export const setSiteHeaders = (siteId?: string) => {
  if (siteId) {
    Axios.defaults.headers.common['active_site_id'] = siteId;
  } else {
    delete Axios.defaults.headers.common['active_site_id'];
  }
};

export default Axios;
