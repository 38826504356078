import { CommonButton } from 'components/common/Forms/Button';
import CommonSelect from 'components/common/Forms/Select';
import React, { ReactElement, useMemo } from 'react';
import { SelectOption } from 'types/common';
import { ResourceType } from 'types/resource-types';
import {
  CalendarTopControlsWrapper,
  CalendarLeftControlsWrapper,
  CalendarMiddleControlsWrapper,
  CalendarPrevNextRangePickerWrapper,
  CalendarWeekViewButtonWrapper,
  CalendarRightControlsWrapper,
} from './calendar.styled';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useSiteBooleanAttributes } from '../../../../hooks/useAttribute';
import { SHOW_OVERFLOW_CALENDAR } from '../../../../configs/siteAndTrustAttributes';
import { isPrimaryResourceType } from '../../../../utils/resourceTypes';
import { PrevNextWeekPicker } from '../../../InlineCalendar/PrevNextWeekPicker';
import { useMaxAndMinFutureDateForStaffBooking } from '../../../../hooks/patient/booking/useMaxAndMinFutureDateForBooking';
import { useRole } from '../../../../context/RoleContext';
import {
  ROLE_OFFSITE_RECEPTIONIST,
  THIRD_PARTY,
} from '../../../../configs/constants';
import { DateRangeType } from '../../../../types/calendar';
import { PrevNextDayPicker } from '../../../InlineCalendar/PrevNextDayPicker';

export interface CalendarHeaderUnderLineProps {
  isResourceTypeFetching: boolean;
  filteredResourceTypes: ResourceType[] | undefined;
  selectedResourceType: ResourceType | undefined;
  setSelectedResourceType: React.Dispatch<
    React.SetStateAction<ResourceType | undefined>
  >;
  selectedDate: Date;
  onSelectDate: (date: Date) => void;
  onOverflowClicked: () => unknown;
  dateRangeType: DateRangeType;
  setDateRangeType: (dateRangeType: DateRangeType) => void;
}

export const CalendarHeaderUnderLine = ({
  isResourceTypeFetching,
  filteredResourceTypes,
  selectedResourceType,
  setSelectedResourceType,
  selectedDate,
  onSelectDate,
  onOverflowClicked,
  dateRangeType,
  setDateRangeType,
}: CalendarHeaderUnderLineProps): ReactElement => {
  const { t } = useCommonTranslation();
  const { hasAnyOfRoles } = useRole();

  const resourceTypesOptions: SelectOption[] | [] = useMemo(
    () =>
      filteredResourceTypes?.map((rt) => ({
        label: rt.name,
        value: rt.id,
      })) || [],
    [filteredResourceTypes],
  );

  const [showOverflowAttribute] = useSiteBooleanAttributes(
    SHOW_OVERFLOW_CALENDAR,
  );

  const showOverflow =
    showOverflowAttribute &&
    isPrimaryResourceType(selectedResourceType) &&
    !hasAnyOfRoles(THIRD_PARTY, ROLE_OFFSITE_RECEPTIONIST);

  const { maxDate, minDate } = useMaxAndMinFutureDateForStaffBooking();

  return (
    <CalendarTopControlsWrapper>
      <CalendarLeftControlsWrapper>
        {showOverflow && (
          <CommonButton
            variant={'secondary'}
            style={{
              borderColor: 'var(--textprimary)',
            }}
            onClick={onOverflowClicked}
          >
            {t('overflow')}
          </CommonButton>
        )}
      </CalendarLeftControlsWrapper>
      <CalendarMiddleControlsWrapper>
        {dateRangeType === 'week' ? (
          <CalendarPrevNextRangePickerWrapper>
            <PrevNextWeekPicker
              selectedDate={selectedDate}
              onSelectDate={onSelectDate}
              maxDate={maxDate}
              minDate={minDate}
            />
          </CalendarPrevNextRangePickerWrapper>
        ) : (
          <>
            <CalendarPrevNextRangePickerWrapper>
              <PrevNextDayPicker
                selectedDate={selectedDate}
                onSelectDate={onSelectDate}
                maxDate={maxDate}
                minDate={minDate}
              />
            </CalendarPrevNextRangePickerWrapper>
            <CalendarWeekViewButtonWrapper>
              <CommonButton
                variant="secondary"
                onClick={() => setDateRangeType('week')}
              >
                {t('week-view')}
              </CommonButton>
            </CalendarWeekViewButtonWrapper>
          </>
        )}
      </CalendarMiddleControlsWrapper>
      <CalendarRightControlsWrapper>
        <CommonSelect
          noSpaceForHelperText
          label={t('calendar-template')}
          placeholder={t('calendar-template')}
          disabled={isResourceTypeFetching}
          options={resourceTypesOptions}
          value={selectedResourceType?.id}
          onChange={(data) => {
            setSelectedResourceType(
              filteredResourceTypes?.find((rt) => rt.id === data),
            );
          }}
        />
      </CalendarRightControlsWrapper>
    </CalendarTopControlsWrapper>
  );
};
