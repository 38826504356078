import { useHistory } from 'react-router';

import { useEffect } from 'react';
import { LinkCardTabs } from '../../components/Card/CardTabs/LinkCardTabs';
import { Card } from '../../components/common';
import { Col, Grid } from '../../components/common/Grid';
import { CardLoader } from '../../components/common/Loading';
import { PageHelmet } from '../../components/common/PageHelmet';
import {
  StaffEnteredCubicleTab,
  StaffEnteredCubicleTabsTree,
} from '../../configs/RoutesConfig';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { useEnteredCubicle } from '../../hooks/staff/useEnteredCubicle';
import { useCubiclePing, useMutateUpdateCubicle } from '../../query/cubicles';
import { STAFF_CUBICLE_URL } from '../../routes/staff/StaffRoutesAndMenu';
import { useGetCubicleUpdateData } from '../../utils/useGetCubicleUpdateData';
import { CubicleQueue } from './cubicle/CubicleQueue';
import { StaffLayout } from './StaffLayout';
import {
  StaffEnteredCubicleHeaderWrapper,
  StaffEnteredCubicleRightBlock,
  StaffEnteredCubicleTitle,
} from './StaffEnteredCubicle.styled';
import { CommonButton } from '../../components/common/Forms/Button';
import { CubicleNext } from './cubicle/CubicleNext';

export const StaffEnteredCubicle = ({
  tab,
}: {
  tab: StaffEnteredCubicleTab;
}) => {
  const { t } = useCommonTranslation();
  const { replace } = useHistory();
  const { mutateAsync: updateCubicle, isLoading: isCubicleUpdating } =
    useMutateUpdateCubicle();
  const getCubicleUpdateData = useGetCubicleUpdateData();
  const { enteredCubicle, isFetching: isEnteredCubicleFetching } =
    useEnteredCubicle();
  const isLoading =
    isCubicleUpdating || isEnteredCubicleFetching || !enteredCubicle;

  const clearSelectedCubicle = async () => {
    await updateCubicle({
      ...getCubicleUpdateData(enteredCubicle),
      user_member_id: null,
    });
  };

  useCubiclePing();
  useEffect(() => {
    if (!isEnteredCubicleFetching && !enteredCubicle) {
      replace(STAFF_CUBICLE_URL);
    }
  }, [isEnteredCubicleFetching, enteredCubicle, replace]);

  return (
    <StaffLayout>
      <PageHelmet title={t('entered-cubicle')} />
      <Grid>
        <Col md={8}>
          <Card mb="var(--s3)">
            <StaffEnteredCubicleHeaderWrapper>
              <LinkCardTabs tabs={StaffEnteredCubicleTabsTree(t)} noMargin />
              {enteredCubicle && (
                <StaffEnteredCubicleRightBlock>
                  <StaffEnteredCubicleTitle>
                    {t('cubicle')}: {enteredCubicle.name}
                  </StaffEnteredCubicleTitle>
                  <CommonButton
                    variant="primary"
                    type="button"
                    onClick={clearSelectedCubicle}
                  >
                    {t('leave-cubicle')}
                  </CommonButton>
                </StaffEnteredCubicleRightBlock>
              )}
            </StaffEnteredCubicleHeaderWrapper>
          </Card>
          <Card>
            {isLoading ? (
              <CardLoader />
            ) : (
              <>
                {tab === 'queue' && <CubicleQueue cubicle={enteredCubicle} />}
                {tab === 'next' && <CubicleNext cubicle={enteredCubicle} />}
              </>
            )}
          </Card>
        </Col>
      </Grid>
    </StaffLayout>
  );
};
