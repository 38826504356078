import { Card, CardFooter, FadeContent, FadeWrapper } from 'components/common';
import CustomScrollbar from 'components/common/CustomScrollbar';
import { CommonLink } from 'components/common/Forms/Button';
import { CardLoader } from 'components/common/Loading';
import ContactUs from 'components/views-components/un-auth/Support/ContactUs';
import { QuillPreview } from 'components/WYSIWYG/WYSIWYG.styled';
import { PatientLayout } from 'containers/PatientLayout';
import { useAuth } from 'context/AuthContext';
import React, { ReactElement } from 'react';
import ReactQuill from 'react-quill';
import { useSupportTemplate } from '../../query/storage';
import { useSiteBooleanAttributes } from '../../hooks/useAttribute';
import { SHOW_PATIENT_SUPPORT_FORM } from '../../configs/siteAndTrustAttributes';

export default function SupportPage(): ReactElement {
  const { sangixUser } = useAuth();
  const [showPatientSupportForm] = useSiteBooleanAttributes(
    SHOW_PATIENT_SUPPORT_FORM,
  );

  const { isLoading, data } = useSupportTemplate();
  const content = data?.data;

  return (
    <PatientLayout graphics={Boolean(sangixUser) ? 'patient' : 'unauth'}>
      <Card fade>
        <FadeWrapper>
          <CustomScrollbar
            style={{ width: '100%' }}
            autoHeight
            autoHeightMin={'100%'}
            autoHeightMax={'100%'}
          >
            <FadeContent>
              {isLoading && !Boolean(content) ? (
                <CardLoader />
              ) : (
                <QuillPreview>
                  <ReactQuill
                    modules={{
                      toolbar: false,
                    }}
                    value={content as string}
                    readOnly={true}
                    theme="snow"
                  />
                </QuillPreview>
              )}
              {(!sangixUser || showPatientSupportForm) && <ContactUs />}
            </FadeContent>
          </CustomScrollbar>
        </FadeWrapper>
        <CardFooter>
          <CommonLink to="/" variant="secondary">
            {Boolean(sangixUser) ? 'Back to Menu' : 'Back to Login'}
          </CommonLink>
        </CardFooter>
      </Card>
    </PatientLayout>
  );
}
