import { PageHelmet } from 'components/common/PageHelmet';
import { LoginForm } from 'components/views-components/un-auth/LoginForm/LoginForm';
import { PatientLayout } from 'containers/PatientLayout';
import React from 'react';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';

const Login: React.FC = () => {
  const { t } = useCommonTranslation();
  return (
    <PatientLayout graphics="unauth">
      <PageHelmet title={t('login')} />
      <LoginForm />
    </PatientLayout>
  );
};

export default Login;
