import { CommonButton as Button } from 'components/common/Forms/Button';
import { RowActions } from 'components/Table/Table.styled';
import React, { ReactElement, useMemo } from 'react';
import { FaCheck, FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { Column, Row } from 'react-table';
import { TableHeadStyle } from 'styles/Typography';
import { ResourceType, ResourceTypeFilters } from 'types/resource-types';
import { getNewColumnWidth } from 'utils/table';
import { FetchedDataTable } from '../../../../Table/FetchedDataTable';
import { PaginatedFiltersWithSet } from '../../../../../hooks/usePaginatedFilters';
import { ItemsResponse } from '../../../../../types/ItemsWithCountResponse';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { getFilterControls } from '../../../../Table/TableUtils';

type Props = {
  resourceTypeData?: ItemsResponse<ResourceType>;
  openFormHandler: (value?: string) => void;
  handleDeleteResourceType: (value: string) => void;
  isLoading: boolean;
  isFetching: boolean;
} & PaginatedFiltersWithSet<ResourceTypeFilters>;

export const ResourceTypeTable = ({
  resourceTypeData,
  openFormHandler,
  handleDeleteResourceType,
  setFilters,
  filters,
  isLoading,
  isFetching,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();

  const columns = useMemo(() => {
    return [
      {
        Header: t('calendar-template-name'),
        accessor: 'name',
        cellStyle: {
          ...TableHeadStyle,
          textTransform: 'capitalize',
        },
        width: getNewColumnWidth(30),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: t('abbreviation'),
        accessor: 'abbreviation',
        width: getNewColumnWidth(12),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: t('duration'),
        accessor: 'length',
        width: getNewColumnWidth(12),
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: { value: number }) => value + ' ' + t('minute-short'),
      },
      {
        Header: t('available'),
        width: getNewColumnWidth(10),
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }: { row: Row<ResourceType> }) => (
          <div style={{ lineHeight: 0, paddingLeft: 16 }}>
            {!row.original.staff_only && <FaCheck />}
          </div>
        ),
      },
      {
        ...getFilterControls({
          t,
          filters,
          setFilters,
        }),
        Cell: ({ value }: { value: string }) => {
          const resourceType = resourceTypeData?.data?.find(
            ({ id }) => id === value,
          );
          return (
            <RowActions>
              <Button
                size="auto"
                iconOnly
                variant="primary"
                title={t('edit-calendar-template')}
                onClick={() => openFormHandler(value)}
              >
                <FaPencilAlt />
              </Button>
              {resourceType?.staff_only === true && (
                <Button
                  size="auto"
                  iconOnly
                  variant="danger"
                  title={t('remove-calendar-template')}
                  onClick={() => handleDeleteResourceType(value)}
                >
                  <FaTrashAlt />
                </Button>
              )}
            </RowActions>
          );
        },
      },
    ] as Column<ResourceType>[];
  }, [
    t,
    resourceTypeData,
    openFormHandler,
    handleDeleteResourceType,
    filters,
    setFilters,
  ]);

  return (
    <FetchedDataTable
      columns={columns}
      data={resourceTypeData}
      filters={filters}
      onSortOrPageChange={setFilters}
      isLoading={isLoading}
      isFetching={isFetching}
    />
  );
};
