import { useCallback, useContext } from 'react';
import { ButtonTypes, CommonButton } from '../../common/Forms/Button';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { ConfirmDialog, ConfirmDialogProps } from './ConfirmDialog';
import { PopupContext } from '../../../context/PopupContext';

type PartialConfirmDialogProps = Omit<
  ConfirmDialogProps,
  'handleClose' | 'open' | 'isLoading' | 'status' | 'actions'
> &
  Partial<Pick<ConfirmDialogProps, 'status'>>;

export type ConfirmFunction = (
  props: PartialConfirmDialogProps & {
    onConfirm: () => void;
    confirmButtonText?: string;
    confirmButtonVariant?: ButtonTypes;
    cancelButtonText?: string;
    hideCancelButton?: boolean;
  },
) => void;

export const useConfirmDialog = (): {
  confirm: ConfirmFunction;
} => {
  const { setPopup } = useContext(PopupContext);

  const { t } = useCommonTranslation();
  const confirm: ConfirmFunction = useCallback(
    ({
      onConfirm,
      confirmButtonText,
      confirmButtonVariant,
      cancelButtonText,
      hideCancelButton,
      status,
      ...dialogProps
    }) => {
      const close = () => setPopup?.(undefined);
      setPopup?.(
        <ConfirmDialog
          key={'confirm-dialog'}
          handleClose={close}
          status={status || 'warning'}
          isLoading={false}
          actions={[
            <CommonButton
              key={'confirm_true'}
              variant={confirmButtonVariant || 'primary'}
              onClick={() => {
                onConfirm();
                close();
              }}
            >
              {confirmButtonText || t('ok')}
            </CommonButton>,
            ...(hideCancelButton
              ? []
              : [
                  <CommonButton
                    key={'confirm_false'}
                    variant="secondary"
                    onClick={close}
                  >
                    {cancelButtonText || t('cancel')}
                  </CommonButton>,
                ]),
          ]}
          {...dialogProps}
        />,
      );
    },
    [setPopup, t],
  );

  return {
    confirm,
  };
};
