import Axios from 'configs/Axios';
import { PostReleaseNotes } from 'types/storage';

export const updateReleaseNote = ({ releaseType, data }: PostReleaseNotes) =>
  Axios.post(`/releaseNote/${releaseType}`, data);

export const getPatientReleaseNote = () =>
  Axios.get<string>(`/patientReleaseNote`);

export const getStaffReleaseNote = () => Axios.get<string>(`/staffReleaseNote`);

export const getGDPRTemplate = () => Axios.get<string>('html/gdpr');

export const updateGDPRTemplate = (data: string) =>
  Axios.post('html/gdpr', data);

export const getSupportTemplate = () => Axios.get<string>('html/support');

export const updateSupportTemplate = (data: string) =>
  Axios.post('html/support', data);

export const getGuidelinesTemplate = () => Axios.get<string>('html/guidelines');

export const updateGuidelinesTemplate = (data: string) =>
  Axios.post('html/guidelines', data);
