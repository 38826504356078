import { AppointmentStatus } from '../../types/appointments';
import { useCallback } from 'react';
import { useCommonTranslation } from '../i18n/useCommonTranslation';

export const useAppointmentStateName = () => {
  const { t } = useCommonTranslation();
  return useCallback(
    ({ status }: { status: AppointmentStatus }) => {
      switch (status) {
        case 'FREE':
          return t('appointment-free');
        case 'TAKEN':
          return t('appointment-taken');
        case 'NOT_TAKEN':
          return t('appointment-not-taken');
        case 'MIA':
          return t('appointment-mia');
        case 'QUEUE':
          return t('appointment-queue');
        case 'PROCESSING':
          return t('appointment-processing');
        case 'CALLED':
          return t('appointment-called');
        case 'BOOKED':
          return t('appointment-booked');
        case 'DNA':
          return t('appointment-dna');
        case 'CANCELLED':
          return t('appointment-cancelled');
        case 'NOT_PREPARED':
          return t('appointment-not-prepared');
        case 'LOCKED':
          return t('appointment-locked');
        case 'EXPIRED':
          return t('appointment-expired');
        default:
          return '';
      }
    },
    [t],
  );
};
