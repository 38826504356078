import { GlobalAttribute } from 'types/common';
import { SiteAttribute, SiteData } from '../types/sites';
import { useMemo } from 'react';

const getAttribute = <T extends GlobalAttribute>(
  attributes: T[],
  attrKey: T['key'],
): T | undefined => attributes.find((attr) => attr.key === attrKey);

export const useSpecificSiteAttributes = (
  site: SiteData | undefined,
  ...attributeKeys: SiteAttribute['key'][]
) => {
  return attributeKeys.map((key) => getAttribute(site?.attributes || [], key));
};

export const useSpecificSiteAttribute = (
  site: SiteData | undefined,
  attributeKey: SiteAttribute['key'],
) => {
  return useSpecificSiteAttributes(site, attributeKey)[0];
};

export const useSpecificSiteNumericArrayAttribute = (
  site: SiteData | undefined,
  attributeKey: SiteAttribute['key'],
): number[] | undefined => {
  const attribute = useSpecificSiteAttribute(site, attributeKey);
  return useMemo(
    () => attribute?.value_str?.split(',').map(Number),
    [attribute],
  );
};

export const useSpecificSiteStringAttributes = (
  site: SiteData | undefined,
  ...attributeKey: SiteAttribute['key'][]
) => {
  return useSpecificSiteAttributes(site, ...attributeKey).map(
    (attribute) => attribute?.value_str,
  );
};

export const useSpecificSiteIntAttributes = (
  site: SiteData | undefined,
  ...attributeKey: SiteAttribute['key'][]
) => {
  return useSpecificSiteAttributes(site, ...attributeKey).map(
    (attribute) => attribute?.value_int,
  );
};

export const useSpecificSiteBooleanAttributes = (
  site: SiteData | undefined,
  ...attributeKey: SiteAttribute['key'][]
) => {
  return useSpecificSiteAttributes(site, ...attributeKey).map((attribute) =>
    Boolean(attribute?.value_int),
  );
};
