import {
  Card,
  CardCloseButton,
  CardHeader,
  FadeContent,
  FadeWrapper,
} from 'components/common';
import CustomScrollbar from 'components/common/CustomScrollbar';
import { FormTitle } from 'components/common/Forms/Forms.styled';
import { Col, Grid } from 'components/common/Grid';
import { CardLoader } from 'components/common/Loading';
import { PageHelmet } from 'components/common/PageHelmet';
import { QuillPreview } from 'components/WYSIWYG/WYSIWYG.styled';
import { useAuth } from 'context/AuthContext';
import React, { Fragment } from 'react';
import { FaClipboardList, FaTimes } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import { useHistory } from 'react-router-dom';
import { StaffLayout } from './StaffLayout';
import { useStaffReleaseNotes } from '../../query/storage';
import { formatISO } from '../../utils/dateUtil';
import { useUpdateUserProfileMutation } from '../../query/users';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';

export default function StaffReleaseNote() {
  const { t } = useCommonTranslation();
  const { updateAuthUser, sangixUser } = useAuth();
  const { goBack, length: historyLength, replace } = useHistory();
  const { mutate: updateUserProfile } = useUpdateUserProfileMutation({
    onSuccess: (_, newUser) => {
      updateAuthUser({
        sangixUser: {
          ...sangixUser!!,
          release_note: newUser.release_note,
        },
      });
    },
  });
  const { isLoading, data } = useStaffReleaseNotes({
    onSuccess: () => {
      updateUserProfile({
        ...sangixUser!!,
        release_note: formatISO(new Date()),
      });
    },
  });

  const content = data?.data;

  return (
    <StaffLayout>
      <PageHelmet title={t('release-notes')} />
      <Grid>
        <Col>
          <Card fade fillHeight>
            <CardCloseButton
              size="auto"
              variant="primary"
              iconOnly
              onClick={() => {
                if (historyLength > 2) goBack();
                else replace('/staff');
              }}
            >
              <FaTimes />
            </CardCloseButton>
            <CardHeader oneLine>
              <FaClipboardList />
              <FormTitle>{t('release-notes')}</FormTitle>
            </CardHeader>
            <FadeWrapper>
              <CustomScrollbar
                style={{ width: '100%' }}
                autoHeight
                autoHeightMin={'100%'}
                autoHeightMax={'100%'}
              >
                <FadeContent>
                  <Fragment>
                    {isLoading && !Boolean(content) ? (
                      <CardLoader />
                    ) : (
                      <QuillPreview>
                        <ReactQuill
                          modules={{
                            toolbar: false,
                          }}
                          value={content as string}
                          readOnly={true}
                          theme="snow"
                        />
                      </QuillPreview>
                    )}
                  </Fragment>
                </FadeContent>
              </CustomScrollbar>
            </FadeWrapper>
          </Card>
        </Col>
      </Grid>
    </StaffLayout>
  );
}
