import Axios from '../configs/Axios';
import {
  CreateTrustMiniSiteCategoryRequest,
  CreateTrustMiniSiteCategoryResponse,
  TrustMiniSite,
  UpdateTrustMiniSiteCategoryRequest,
} from '../types/trustMiniSites';

export const getTrustMiniSite = (trustId: string) =>
  Axios.get<TrustMiniSite>(`/minisiteCategory/${trustId}`);

export const createTrustMiniSiteCategory = (
  data: CreateTrustMiniSiteCategoryRequest,
) => Axios.post<CreateTrustMiniSiteCategoryResponse>('/minisiteCategory', data);

export const updateTrustMiniSiteCategory = (
  data: UpdateTrustMiniSiteCategoryRequest,
) => Axios.put<void>('/minisiteCategory', data);

export const deleteTrustMiniSiteCategory = (categoryId: string) =>
  Axios.delete<void>(`/minisiteCategory/${categoryId}`);
