import { useMemo } from 'react';
import { useApptTemplates } from '../../query/template';
import {
  formatDateOnlyISO,
  getDatesBetween,
  getWeekDay,
} from '../../utils/dateUtil';
import { useSiteNumericArrayAttribute } from '../useAttribute';
import {
  TemplateDateWithCounts,
  useDefaultDayTemplates,
} from './useDefaultTemplatesApptCounts';
import { getAppointmentTemplateCounts } from '../../utils/apptTemplateUtils';

export const useTemplatesApptCounts = ({
  resourceTypeId,
  fromDate,
  toDate,
}: {
  resourceTypeId?: string;
  fromDate: Date;
  toDate: Date;
}) => {
  const weekdays = useSiteNumericArrayAttribute('weekDays')!;
  const rangeSiteDates = useMemo(
    () =>
      getDatesBetween(fromDate, toDate).filter((date) =>
        weekdays.includes(getWeekDay(date)),
      ),
    [fromDate, toDate, weekdays],
  );

  const { dayToTemplate, isFetching: isDefaultTemplatesFetching } =
    useDefaultDayTemplates({ resourceTypeId });

  const { isFetching: isApptTemplatesFetching, data: templates } =
    useApptTemplates(
      {
        fromDate: formatDateOnlyISO(fromDate),
        toDate: formatDateOnlyISO(toDate),
        resourceTypeID: resourceTypeId,
      },
      {
        enabled: !!resourceTypeId,
      },
    );

  const templateCounts: TemplateDateWithCounts[] = useMemo(
    () =>
      rangeSiteDates.map((date) => {
        let dayTemplate = templates?.data?.find(
          (template) => template.template_date === formatDateOnlyISO(date),
        );
        // No specific template for the day, use the default template
        if (!dayTemplate) {
          dayTemplate = dayToTemplate[getWeekDay(date)];
        }
        return {
          template_date: formatDateOnlyISO(date),
          ...getAppointmentTemplateCounts(dayTemplate?.entries),
        };
      }),
    [templates, dayToTemplate, rangeSiteDates],
  );

  return {
    isFetching: isDefaultTemplatesFetching || isApptTemplatesFetching,
    templateCounts,
  };
};
