import {
  CacheAndStaleTimeForUnlikelyChanged,
  useMutationWithToastError,
  useQueryWithToastError,
} from './queryUtils';
import { useQueryClient } from 'react-query';
import { UseMutationOptions } from 'react-query/types/react/types';
import {
  addNewTrust,
  deleteTrust,
  getAllTrusts,
  getTrust,
  updateTrust,
} from '../services/trust';
import { TrustFormValues, TrustsFilters } from '../types/trusts';
import { UNSAFE_FILTER_WITH_NO_PAGINATION } from '../hooks/usePaginatedFilters';

const TRUSTS_QUERY_KEY = 'trusts';

export const useTrusts = (filters?: TrustsFilters) => {
  return useQueryWithToastError(
    [TRUSTS_QUERY_KEY, filters],
    () => getAllTrusts(filters),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
    },
  );
};

export const useTrust = (id?: string) => {
  return useQueryWithToastError(
    [TRUSTS_QUERY_KEY, { id: id }],
    () => getTrust(id!!),
    {
      select: (trustResponse) => trustResponse.data,
      enabled: Boolean(id),
    },
  );
};

export const useTrustsByIds = (ids?: string[]) => {
  return useQueryWithToastError(
    [TRUSTS_QUERY_KEY, { ids }],
    () =>
      getAllTrusts({
        ...UNSAFE_FILTER_WITH_NO_PAGINATION,
        trustId: ids!.join(','),
      }),
    {
      select: (trustResponse) => trustResponse.data.data,
      enabled: Boolean(ids && ids.length > 0),
    },
  );
};

export const useMutateUpdateTrust = (
  options?: UseMutationOptions<any, any, TrustFormValues>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<TrustFormValues>(
    [TRUSTS_QUERY_KEY],
    (params) => updateTrust(params),
    {
      onSettled: () => client.invalidateQueries([TRUSTS_QUERY_KEY]),
      ...options,
    },
  );
};
export const useMutateCreateTrust = (
  options?: UseMutationOptions<any, any, TrustFormValues>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<TrustFormValues>(
    [TRUSTS_QUERY_KEY],
    (params) => addNewTrust(params),
    {
      onSettled: () => client.invalidateQueries([TRUSTS_QUERY_KEY]),
      ...options,
    },
  );
};
export const useMutateDeleteTrust = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<string>(
    [TRUSTS_QUERY_KEY],
    (params) => deleteTrust(params),
    {
      onSettled: () => client.invalidateQueries([TRUSTS_QUERY_KEY]),
      ...options,
    },
  );
};
