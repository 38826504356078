import { CommonLink } from 'components/common/Forms/Button';
import { FormActionsStyle } from 'components/common/Forms/Forms.styled';
import { useSiteStringAttributes } from 'hooks/useAttribute';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { useCallback } from 'react';
import { patientURL } from 'routes/AppRoutes';
import { PRE_CHECK_IN_QUESTION_NO } from '../../../configs/siteAndTrustAttributes';
import { usePatientCheckIn } from '../../../context/patient/modules/checkIn';
import { PatientCheckInLayout } from './PatientCheckInLayout';

export const PatientPreCheckInNo = () => {
  const { t } = useCommonTranslation();
  const [preCheckinQuestionNo] = useSiteStringAttributes(
    PRE_CHECK_IN_QUESTION_NO,
  );
  const { updateCheckIn } = usePatientCheckIn();
  const onBack = useCallback(
    () => updateCheckIn({ answer: null }),
    [updateCheckIn],
  );

  return (
    <PatientCheckInLayout description={{ html: preCheckinQuestionNo }}>
      <FormActionsStyle align="center">
        <CommonLink variant="primary" to={patientURL} onClick={onBack}>
          {t('back-to-menu')}
        </CommonLink>
      </FormActionsStyle>
    </PatientCheckInLayout>
  );
};
