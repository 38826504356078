import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { PatientBookingStageTitle } from '../PatientBooking.styled';
import { WeekDayPicker } from '../../../../components/InlineCalendar/WeekDayPicker';
import { PatientBookingFirstAppts } from './PatientBookingFirstAppts';
import { WeekInlineCalendarPickerWrapper } from '../../../../components/InlineCalendar/WeekDayPicker.styled';
import { PrevNextWeekPicker } from '../../../../components/InlineCalendar/PrevNextWeekPicker';
import { PatientBookingState } from '../../../../context/patient/modules/booking';
import { useMaxAndMinFutureDateForBookingForOnlineBooking } from '../../../../hooks/patient/booking/useMaxAndMinFutureDateForBooking';
import { DateOnlyISOString } from '../../../../utils/dateUtil';

export const PatientBookingPickDaySelect = ({
  availableDays,
  setSelectedDate,
  isDayAvailable,
  firstFoundInWeek,
  updateBooking,
}: {
  setSelectedDate: (date: Date) => any;
  isDayAvailable: (date: Date) => boolean;
  availableDays: DateOnlyISOString[];
  firstFoundInWeek: Date;
  updateBooking: (update: Partial<PatientBookingState>) => any;
}) => {
  const { t } = useCommonTranslation();
  const { minDate, maxDate } =
    useMaxAndMinFutureDateForBookingForOnlineBooking();
  return (
    <>
      <PatientBookingStageTitle>{t('pick-day')}</PatientBookingStageTitle>
      <WeekInlineCalendarPickerWrapper>
        <PrevNextWeekPicker
          selectedDate={firstFoundInWeek}
          minDate={minDate}
          maxDate={maxDate}
          onSelectDate={(date) => updateBooking({ calendarDateShown: date })}
          iconOnly
        />
      </WeekInlineCalendarPickerWrapper>
      <WeekDayPicker
        minDate={minDate}
        weekDate={firstFoundInWeek}
        setSelectedDate={setSelectedDate}
        filterDate={isDayAvailable}
      />
      <PatientBookingFirstAppts availableDays={availableDays} />
    </>
  );
};
