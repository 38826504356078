import { useAuth } from 'context/AuthContext';
import { useCubicles } from 'query/cubicles';
import { useMemo } from 'react';
import { UNSAFE_FILTER_WITH_NO_PAGINATION } from '../usePaginatedFilters';
import { useRole } from '../../context/RoleContext';
import { ALL_STAFF_ROLES_BUT_RECEPTIONIST_AND_THIRD_PARTY_AND_SURGERY } from '../../configs/constants';

export const useEnteredCubicle = () => {
  const { sangixUser } = useAuth();
  const { hasAnyOfRoles } = useRole();
  const {
    data: cubiclesData,
    isLoading,
    isFetching,
  } = useCubicles(UNSAFE_FILTER_WITH_NO_PAGINATION, {
    enabled: hasAnyOfRoles(
      ...ALL_STAFF_ROLES_BUT_RECEPTIONIST_AND_THIRD_PARTY_AND_SURGERY,
    ),
  });
  const enteredCubicle = useMemo(() => {
    return cubiclesData?.data.data.find(
      ({ user_member_id }) => user_member_id === sangixUser?.main_member_id,
    );
  }, [cubiclesData, sangixUser]);
  return {
    enteredCubicle,
    isLoading,
    isFetching,
  };
};
