import { CommonButton } from 'components/common/Forms/Button';
import { useUnassignedTrustBookingSites } from 'context/patient/modules/booking';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { useCallback } from 'react';

import { PATIENT } from '../../../configs/constants';
import { useAuth } from '../../../context/AuthContext';
import { getUserProfile } from '../../../services/userProfile';
import { addUserRole } from '../../../services/users';
import { SiteData } from '../../../types/sites';
import { errorToast } from '../../../utils/toast';
import {
  PatientBookingSiteItem,
  PatientBookingSiteItemName,
} from './PatientBooking.styled';

interface Props {
  onSiteSelect: (siteId: string) => void;
}

export const PatientBookingPickSiteUnassignedTrustList = ({
  onSiteSelect,
}: Props) => {
  const { t } = useCommonTranslation();
  const { sangixUser, updateAuthUser } = useAuth();
  const { sites } = useUnassignedTrustBookingSites();

  const addSiteHandler = useCallback(
    async (site: SiteData, userAccountId: string) => {
      try {
        await addUserRole({
          role_id: PATIENT,
          site_id: site.id,
          user_account_id: userAccountId,
        });
        const { data } = await getUserProfile();
        updateAuthUser({
          sangixUser: data,
        });
        onSiteSelect(site.id);
      } catch (err) {
        errorToast(err);
      }
    },
    [updateAuthUser, onSiteSelect],
  );

  return (
    <>
      {sites?.map((site) => (
        <PatientBookingSiteItem key={site.id}>
          <PatientBookingSiteItemName>{site.name}</PatientBookingSiteItemName>
          <CommonButton
            variant="primary"
            onClick={() => {
              // TODO SGX-348 add loader
              addSiteHandler(site, sangixUser!.id);
            }}
          >
            {t('select')}
          </CommonButton>
        </PatientBookingSiteItem>
      ))}
    </>
  );
};
