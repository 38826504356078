import Axios from 'configs/Axios';
import {
  OtherReferrerResponseData,
  OtherReferrersFilters,
  OtherReferrersFormValues,
} from 'types/otherReferrers';

export const getOtherReferrers = (filters: OtherReferrersFilters) =>
  Axios.get<OtherReferrerResponseData>('/referrer', {
    params: filters,
  });

export const deleteOtherReferrer = (referrerId: string) =>
  Axios.delete(`/referrer/${referrerId}`);

export const addNewOtherReferrer = (data: OtherReferrersFormValues) =>
  Axios.post<OtherReferrerResponseData>('/referrer', data);

export const updateOtherReferrer = (data: OtherReferrersFormValues) =>
  Axios.put('/referrer', data);

export const mergeOtherReferrers = (targetId: string, sourceId: string) =>
  Axios.post(`/referrer/${targetId}/merge/${sourceId}`);
