import { CommonButton as Button } from 'components/common/Forms/Button';
import { RowActions } from 'components/Table/Table.styled';
import { getFilterControls } from 'components/Table/TableUtils';
import { ReactElement, useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import { TableHeadStyle } from 'styles/Typography';
import { getNewColumnWidth } from 'utils/table';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import {
  ApptFlagConfig,
  useAllAvailableApptFlags,
} from '../../../../hooks/appointments/useAllAvailableApptFlags';
import { StaticDataTable } from '../../../../components/Table/StaticDataTable';
import { useFilters } from '../../../../hooks/useFilters';

type Props = {
  selectedFlags: string[];
  onToggleFlag: (flag: string) => void;
};

type FlagRow = Omit<ApptFlagConfig, 'id'> & {
  id: string;
};

export const AppointmentFlagsTable = ({
  selectedFlags,
  onToggleFlag,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();

  const allAvailableApptFlags = useAllAvailableApptFlags().map((flag) => ({
    ...flag,
    id: `${flag.id}`,
  }));
  const allFlagsWithCount = {
    count: allAvailableApptFlags.length,
    data: allAvailableApptFlags,
  };
  const { filters, setFilters } = useFilters();

  const columns = useMemo(() => {
    return [
      {
        Header: t('appointment-flag-label'),
        accessor: 'label',
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(22),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: t('appointment-flag-icon'),
        accessor: (row) => row,
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(10),
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: CellProps<FlagRow>) => {
          return value.icon ? value.icon : <></>;
        },
      },
      {
        Header: t('appointment-flag-in-processing-appt'),
        accessor: (row) => row,
        width: getNewColumnWidth(20),
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: CellProps<FlagRow>) => {
          return value.processingAndGracePeriodVisible ? t('yes') : t('no');
        },
      },
      {
        Header: t('appointment-flag-in-report'),
        accessor: (row) => row,
        width: getNewColumnWidth(20),
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: CellProps<FlagRow>) => {
          return value.inDailyReport ? t('yes') : t('no');
        },
      },
      {
        ...getFilterControls({
          filters: filters,
          setFilters,
          t,
          minWidth: 11,
          maxWidth: undefined,
        }),
        Cell: ({ value }: { value: string }) => {
          const isSelected = selectedFlags.includes(value);
          return (
            <RowActions>
              <Button
                size="auto"
                variant={isSelected ? 'selected' : 'primary'}
                onClick={() => onToggleFlag(value)}
                type={'button'}
              >
                {isSelected ? t('selected') : t('select')}
              </Button>
            </RowActions>
          );
        },
      },
    ] as Column<FlagRow>[];
  }, [t, filters, setFilters, onToggleFlag, selectedFlags]);

  return (
    <StaticDataTable
      data={allFlagsWithCount}
      columns={columns}
      onSortOrPageChange={setFilters}
      filters={filters}
      selectedIds={selectedFlags}
    />
  );
};
