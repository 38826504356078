import { useRole } from 'context/RoleContext';
import { RouteProps, useRouteMatch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { UserRoleId } from '../../configs/constants';

export const ProtectedRoute = ({
  component,
  hasAnyOfUserRoles,
  ...rest
}: RouteProps & {
  hasAnyOfUserRoles?: UserRoleId[];
}) => {
  const { sangixUser } = useAuth();
  const noUserLoggedIn = !sangixUser;
  const { currentUserRole, hasAnyOfRoles } = useRole();
  const match = useRouteMatch(rest);

  if (noUserLoggedIn) {
    return <Redirect to="/login" />;
  }
  /**
   * Redirect to the role home page if route match but user has no access.
   * This was the way it was defined, another possibility would be to show
   * a 404.
   */
  if (match && hasAnyOfUserRoles && !hasAnyOfRoles(...hasAnyOfUserRoles)) {
    return (
      <>
        {currentUserRole && (
          <Redirect to={currentUserRole!!.main_route as string} />
        )}
      </>
    );
  }

  return <Route {...rest} component={component} />;
};
