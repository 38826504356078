import { TEST_SITE } from './sites';

export const TEST_RESOURCE_TYPE_5_MIN = {
  id: '48a1ed3f4a93427f9b5aeda416eec71b',
  site_id: TEST_SITE.id,
  site_name: TEST_SITE.name,
  name: '5 Minutes Res Type',
  abbreviation: 'CalTs6',
  staff_only: true,
  length: 5,
  description: '',
  walkin: false,
};

export const resourceTypesResponse = {
  count: 3,
  data: [
    {
      id: '0c132b9034824013a5d9b6488d4d341c',
      site_id: TEST_SITE.id,
      site_name: TEST_SITE.name,
      name: 'NO CUBICLE 5 Minutes Res Type',
      abbreviation: 's6',
      staff_only: false,
      length: 5,
      description: '',
      walkin: false,
    },
    TEST_RESOURCE_TYPE_5_MIN,
    {
      id: 'a2d31a6d6d20479fa1a95f19cef38578',
      site_id: TEST_SITE.id,
      site_name: TEST_SITE.name,
      name: '15 Minutes Walk in',
      abbreviation: 'WALK',
      staff_only: false,
      length: 15,
      description: 'TEST',
      walkin: true,
    },
  ],
};
