import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import React, { useCallback } from 'react';
import { StaffLayout } from '../StaffLayout';
import { PageHelmet } from '../../../components/common/PageHelmet';
import { Grid } from '../../../components/common/Grid';
import {
  useExistingAppointments,
  useMutateMIAAppointment,
} from '../../../query/appointments';
import {
  addDays,
  formatDateOnlyISO,
  MILLIS_IN_MINUTE,
} from '../../../utils/dateUtil';
import { useAppointmentsColumns } from '../appointments/DnaAndMiaDialogHooks';
import { CommonButton as Button } from '../../../components/common/Forms/Button';
import { BasicTable } from '../../../components/Table/BasicTable';
import { Card } from '../../../components/common';
import { successToast } from '../../../utils/toast';

export const PatientReport = () => {
  const { t } = useCommonTranslation();
  const { isLoading: isExistingApptsLoading, data: appts } =
    useExistingAppointments(
      {
        fromDate: formatDateOnlyISO(new Date()),
        toDate: formatDateOnlyISO(addDays(new Date(), 1)),
        statuses: ['CALLED', 'PROCESSING'],
      },
      {
        refetchInterval: 5 * MILLIS_IN_MINUTE,
      },
    );

  const { mutate: submitMIA, isLoading: isSubmitMiaLoading } =
    useMutateMIAAppointment({
      onSuccess: () => {
        successToast(t('appt-mia-submit-success'));
      },
    });

  const rowActions = useCallback(
    (apptId: string) => (
      <Button
        variant={'primary'}
        onClick={() =>
          !isSubmitMiaLoading &&
          submitMIA({
            id: apptId,
          })
        }
      >
        {t('mia')}
      </Button>
    ),
    [submitMIA, isSubmitMiaLoading, t],
  );

  const appointmentColumns = useAppointmentsColumns({
    rowActions,
  });

  const isLoading = isExistingApptsLoading || isSubmitMiaLoading;

  return (
    <StaffLayout>
      <PageHelmet title={t('report-patients')} />
      <Grid>
        <Card>
          <BasicTable
            columns={appointmentColumns}
            data={appts?.data.data}
            isLoading={isLoading}
          />
        </Card>
      </Grid>
    </StaffLayout>
  );
};
