import React, {
  forwardRef,
  RefCallback,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { components, MenuProps, Props as ReactSelectProps } from 'react-select';
import { SelectOption } from 'types/common';
import CheckboxInput from './CheckboxInput';
import {
  CommonInputHelperText,
  CommonInputHelperWrapper,
  CommonInputIcon,
  CommonInputInner,
  CommonInputLabel,
  CommonInputLabelWrapper,
  CommonInputWrapper,
  StyledSelect,
} from './Forms.styled';
import { isArray } from 'lodash';
import { useThemeMedia } from 'hooks/useThemeMedia';
import { mq } from 'styles/sizes';
import { RequiredLabel } from './RequiredLabel';
import { FontBasedSize } from '../../../styles/fontsStyleUtils';

const MultiOption = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <CheckboxInput
          name={props.name}
          id={props.value}
          checked={props.isSelected}
          label={props.label}
          value={props.value}
          onChange={() => null}
          inSelectList={true}
        />
      </components.Option>
    </div>
  );
};

export type LabelPositionType = 'labelInCorner' | 'labelBefore';

export type SelectProps<T> = Pick<
  ReactSelectProps<SelectOption<T>>,
  'className' | 'instanceId' | 'isClearable' | 'hideSelectedOptions'
> &
  FontBasedSize & {
    required?: boolean;
    labelPosition?: LabelPositionType;
    flexWidth?: boolean;
    style?: React.CSSProperties;
    label?: string;
    name?: string;
    id?: string;
    type?: string;
    disabled?: boolean;
    placeholder?: string;
    hasError?: boolean;
    helperText?: string;
    fixedLabelWidth?: string;
    prefilled?: boolean;
    icon?: React.ReactNode;
    noSpaceForHelperText?: boolean;
    options?: SelectOption<T>[];
    multiOption?: boolean;
    value: T | T[] | undefined;
    onChange: (value: T | T[] | undefined) => void;
    dropdownMenuStyleOverride?: string;
  };

// Menu list that adds the effect to show the shadow the the scrolling is avaialble
// There are css solutions, but they do not fit this case https://stackoverflow.com/questions/44793453/how-do-i-add-a-top-and-bottom-shadow-while-scrolling-but-only-when-needed
// because they work by changing the background while we need to overlap the text
const MenuWithScrollTracked = (
  props: MenuProps<typeof MultiOption, boolean>,
) => {
  const [canScrollDown, setCanScrollDown] = useState(false);
  const ref = useRef<HTMLElement>();
  const onScroll = useCallback(() => {
    const menuList = ref.current?.children[0] as HTMLElement;
    if (!menuList) return;
    const maxScrollTop = Math.round(
      menuList.scrollHeight - menuList.offsetHeight,
    );
    const scrollTop = Math.round(menuList.scrollTop);
    if (scrollTop < maxScrollTop) return setCanScrollDown(true);
    return setCanScrollDown(false);
  }, [ref]);
  // fill our ref value, and lib ref value
  const innerRefWrapper = useCallback(
    (el) => {
      const innerRef = props.innerRef as RefCallback<HTMLElement>;
      innerRef(el);
      if (ref.current !== el) {
        ref.current = el;
      }
    },
    [props.innerRef],
  );
  useEffect(() => {
    ref.current?.children[0]?.addEventListener('scroll', onScroll);
    onScroll();
    return () => {
      ref.current?.children[0]?.removeEventListener('scroll', onScroll);
    };
  }, [ref, onScroll]);
  return (
    <components.Menu
      {...props}
      className={
        (props.className || '') + (canScrollDown ? ' canScrollDown' : '')
      }
      innerRef={innerRefWrapper}
    />
  );
};

function CommonSelect<T>(
  {
    labelPosition = 'labelInCorner',
    flexWidth,
    options,
    label,
    name,
    id,
    disabled,
    placeholder,
    hasError,
    required,
    helperText,
    className,
    icon,
    prefilled,
    fixedLabelWidth,
    noSpaceForHelperText,
    multiOption,
    value,
    onChange,
    style,
    fontBasedSize,
    dropdownMenuStyleOverride,
    ...rest
  }: SelectProps<T>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const selectedOptionValue = options?.filter((option) => {
    if (isArray(value)) {
      return value.includes(option.value);
    }
    return option.value === value;
  });
  const isDesktop = useThemeMedia(mq.sm);
  const moreThanTenOptions = useMemo(
    () => options && options.length > 10,
    [options],
  );
  return (
    <CommonInputWrapper
      className={className}
      style={style}
      flexWidth={flexWidth}
      fontBasedSize={fontBasedSize}
    >
      <CommonInputLabelWrapper
        labelPosition={labelPosition}
        disabled={disabled}
        fontBasedSize={fontBasedSize}
      >
        <CommonInputHelperWrapper noSpaceForHelperText={noSpaceForHelperText}>
          <CommonInputInner isDisabled={disabled}>
            {Boolean(icon) && (
              <CommonInputIcon disabled={disabled}>{icon}</CommonInputIcon>
            )}
            <StyledSelect
              // prevent keyboard opening on mobile if there are less than 10 options
              isSearchable={isDesktop || moreThanTenOptions}
              placeholder={!disabled && placeholder}
              classNamePrefix="common"
              options={options}
              isMulti={multiOption}
              inputId={id || name}
              hasError={hasError}
              innerRef={ref}
              isDisabled={disabled}
              onChange={(selectOption: SelectOption<T> | SelectOption<T>[]) => {
                if (isArray(selectOption)) {
                  onChange(selectOption.map((option) => option.value));
                } else {
                  onChange(selectOption.value);
                }
              }}
              value={selectedOptionValue}
              components={{
                IndicatorSeparator: () => null,
                ...(multiOption
                  ? {
                      Option: MultiOption,
                    }
                  : {}),
                Menu: MenuWithScrollTracked,
              }}
              fontBasedSize={fontBasedSize}
              dropdownMenuStyleOverride={dropdownMenuStyleOverride}
              {...rest}
            />
            {label && labelPosition === 'labelInCorner' && (
              <CommonInputLabel htmlFor={id || name}>
                <RequiredLabel label={label} required={required} />
              </CommonInputLabel>
            )}
          </CommonInputInner>
          {Boolean(helperText) && (
            <CommonInputHelperText hasError={hasError} disabled={disabled}>
              {helperText}
            </CommonInputHelperText>
          )}
        </CommonInputHelperWrapper>
        {label && labelPosition === 'labelBefore' && (
          <CommonInputLabel
            htmlFor={id || name}
            fixedLabelWidth={fixedLabelWidth}
            labelBackgroundColor={'unset'}
          >
            <RequiredLabel label={label} required={required} />
          </CommonInputLabel>
        )}
      </CommonInputLabelWrapper>
    </CommonInputWrapper>
  );
}

export default forwardRef(CommonSelect);
