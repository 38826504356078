import { Col } from 'components/common/Grid';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FormGridBody } from '../../../../components/common/Forms/Forms.styled';
import { PromptWithBeforeUnload } from '../../../../components/Form/PromptWithBeforeUnload';
import { useIsDirty } from '../../../../hooks/form/useIsDirty';
import useAttributesForm from '../../../../hooks/useAttributesForm';
import { SiteData } from '../../../../types/sites';
import {
  ISitePreferencesWithTypeProps,
  SITE_PREFERENCES_FORM_ID,
} from '../SitePreferences';
import {
  useMergeSiteFormHooks,
  useSiteFormSubmit,
} from '../SitePreferencesHooks';
import {
  CustomSettingsFormFieldValues,
  UseCustomSettingsFormHooksReturn,
} from './CustomSettingsHooks';
import { CustomSettingsTab } from './CustomSettingsTab';
import { useCustomSettingsTabFormHooks } from './CustomSettingsTabHooks';
import { PreBookQuestion } from './PreBookQuestion';
import { usePreBookQuestionFormHooks } from './PreBookQuestionHooks';

type Props = ISitePreferencesWithTypeProps & {
  site: SiteData;
};

export const CustomSettings = (props: Props) => {
  const { site } = props;
  const formPartsHooks: UseCustomSettingsFormHooksReturn[] = [
    useCustomSettingsTabFormHooks({ site }),
    usePreBookQuestionFormHooks({ site }),
  ];

  const { defaultValues, restAttributes, beforeSubmit } = useMergeSiteFormHooks(
    { site, hooks: formPartsHooks },
  );

  const theForm = useForm<CustomSettingsFormFieldValues>({
    defaultValues,
  });

  useEffect(() => {
    theForm.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const attributesForm = useAttributesForm<CustomSettingsFormFieldValues>({
    theForm,
    currentAttrs: site.attributes,
    restAttributes,
    dep: [site],
  });

  const isDirty = useIsDirty(attributesForm.formState);

  const submitForm = useSiteFormSubmit({
    beforeSubmit,
    ...props,
    ...attributesForm,
  });

  return (
    <FormGridBody id={SITE_PREFERENCES_FORM_ID} onSubmit={submitForm}>
      <PromptWithBeforeUnload when={isDirty}></PromptWithBeforeUnload>
      <Col md={6}>
        <CustomSettingsTab attributesForm={attributesForm} {...props} />
      </Col>
      <Col md={6}>
        <PreBookQuestion attributesForm={attributesForm} {...props} />
      </Col>
    </FormGridBody>
  );
};
