import { CommonButton } from 'components/common/Forms/Button';
import CheckboxInput from 'components/common/Forms/CheckboxInput';
import {
  CheckBoxesWrapper,
  CommonInputHelperText,
  FormActionsStyle,
  FormBody,
  FormRow,
} from 'components/common/Forms/Forms.styled';
import {
  AutoCompleteLabel,
  CheckboxesWithErrorWrapper,
  GreenBoxWrapper,
} from 'components/views-components/un-auth/SignupForm/SignupForm.styled';
import { RELATIONSHIP } from 'configs/constants';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UserMemberData } from 'types/authentication';
import { UserMemberFormValues } from 'types/users';
import { bitwiseArrayToNumber, toRem } from 'utils/helpers';
import { PatientProfileOrMemberFormData } from '../../../../containers/common/patient/PatientProfileOrMemberFormData';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { TrustData } from '../../../../types/trusts';
import { SelectOption } from '../../../../types/common';
import { DEFAULT_SMS_OPT_IN } from '../../../../utils/user';

type Props = {
  onSaveMember: (memberFormData: UserMemberFormValues) => any;
  member?: UserMemberData;
  trusts: TrustData[];
  onDeleteMember?: (member: UserMemberData) => any;
  onBackClicked: () => any;
  gpSurgeriesOptions: SelectOption[];
  formButtonsAlign?: 'left' | 'center' | 'right';
};

export const MemberAccountFormBody = ({
  onSaveMember,
  member,
  trusts,
  onDeleteMember,
  onBackClicked,
  gpSurgeriesOptions,
  formButtonsAlign,
}: Props) => {
  const { t } = useCommonTranslation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserMemberFormValues>({
    mode: 'onChange',
    defaultValues: {
      sms_optin: DEFAULT_SMS_OPT_IN,
      ...member,
    },
  });

  const submitProfileData: SubmitHandler<UserMemberFormValues> = (data) => {
    const structuredData: UserMemberFormValues = {
      ...data,
      birthdate: data.birthdate,
      relation: Number(data.relation),
      disabilities: bitwiseArrayToNumber(data.disabilities),
      gp_surgery_id: data.gp_surgery_id,
    };
    onSaveMember(structuredData);
  };
  return (
    <FormBody onSubmit={handleSubmit(submitProfileData)}>
      <PatientProfileOrMemberFormData
        register={register}
        control={control}
        errors={errors}
        trusts={trusts}
        hideEmail={true}
        gpSurgeriesOptions={gpSurgeriesOptions}
      />
      <FormRow>
        <CheckboxesWithErrorWrapper>
          <GreenBoxWrapper>
            <AutoCompleteLabel as="span">
              {t('relationship')}:
            </AutoCompleteLabel>
            {RELATIONSHIP(t).map((relation, i) => (
              <CheckboxInput
                key={relation.value + i}
                {...register('relation', {
                  required: t('must-not-empty') as string,
                })}
                label={relation.label}
                id={'new_relation' + i}
                value={relation.value}
                type="radio"
                defaultChecked={relation.value === member?.relation}
              />
            ))}
          </GreenBoxWrapper>
          {errors.relation && (
            <CommonInputHelperText hasError={true}>
              {errors.relation.message}
            </CommonInputHelperText>
          )}
        </CheckboxesWithErrorWrapper>
      </FormRow>
      <CheckBoxesWrapper>
        <CheckboxInput
          {...register('sms_optin')}
          label={t('receive-texts')}
          key={'receiveTexts'}
          id={'receiveTexts'}
          isToggle={true}
        />
      </CheckBoxesWrapper>
      <FormActionsStyle align={formButtonsAlign}>
        <CommonButton
          variant="primary"
          type="submit"
          disabled={Boolean(Object.keys(errors).length)}
          style={{ minWidth: `${toRem(160)}rem` }}
        >
          {member ? t('save-changes') : t('add-shared-user')}
        </CommonButton>
        {member && onDeleteMember && (
          <CommonButton
            variant="danger"
            type="button"
            style={{ minWidth: `${toRem(160)}rem` }}
            onClick={() => onDeleteMember(member)}
          >
            {t('delete-member')}
          </CommonButton>
        )}
        <CommonButton
          type="button"
          variant="secondary"
          style={{ minWidth: `${toRem(160)}rem` }}
          onClick={onBackClicked}
        >
          {t('back')}
        </CommonButton>
      </FormActionsStyle>
    </FormBody>
  );
};
