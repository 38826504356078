import {
  useSiteBooleanAttributes,
  useSiteStringAttributes,
} from 'hooks/useAttribute';
import { useLCD, usePlayLCDSoundOnNewPatient } from 'hooks/public/useLCD';
import {
  LCDContentStyled,
  LCDRow,
  LCDRowCubicle,
  LCDRowMessage,
  LCDRowName,
} from './LCD.styled';
import {
  LCD_SHORT_NAMES,
  SITE_ATTRIBUTE_CALLING_SCREEN_CUBICLE_MESSAGE,
} from '../../../configs/siteAndTrustAttributes';

const lcdMaxRows = 6;
const toShortName = (fullname: string) => {
  const names = fullname.split(' ');
  return names[0][0] + ' ' + names[1];
};
export const LCDContent = () => {
  const { rows: allRows = [] } = useLCD();
  const [shortNames] = useSiteBooleanAttributes(LCD_SHORT_NAMES);
  const [callInScreenMessage] = useSiteStringAttributes(
    SITE_ATTRIBUTE_CALLING_SCREEN_CUBICLE_MESSAGE,
  );
  const rows = allRows.slice(0, lcdMaxRows);
  usePlayLCDSoundOnNewPatient({
    newRows: allRows,
  });
  return (
    <LCDContentStyled>
      {rows.map((row) => (
        <LCDRow key={row.name + row.cubicle}>
          <LCDRowName>
            {shortNames ? toShortName(row.name) : row.name}
          </LCDRowName>
          <LCDRowMessage>{callInScreenMessage}</LCDRowMessage>
          <LCDRowCubicle>{row.cubicle}</LCDRowCubicle>
        </LCDRow>
      ))}
    </LCDContentStyled>
  );
};
