import { DeepPartial, FieldErrors, UnpackNestedValue } from 'react-hook-form';
import { ResolverResult } from 'react-hook-form/dist/types/resolvers';
import {
  addDays,
  differenceInDays,
  differenceInYears,
  formatDateOnlySite,
  isBefore,
  isInFuture,
  isSameDay,
  parseIsoDate,
  startOfDay,
  subDays,
} from '../../../utils/dateUtil';
import { isEmpty } from 'lodash';
import { Report, REPORT_IDS } from '../../../types/reports';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';

type FromToValidate = {
  fromDate: string;
  toDate: string;
};

type ValidateReports = {
  fromHour?: number;
  toHour?: number;
  report?: Report;
} & FromToValidate;

export const useValidateFromTo = () => {
  const { t } = useCommonTranslation();
  return (
    submitValues: UnpackNestedValue<FromToValidate>,
  ): ResolverResult<FromToValidate> => {
    const errors: DeepPartial<FieldErrors<FromToValidate>> = {};
    if (!submitValues.fromDate) {
      errors.fromDate = { message: t('must-not-empty') };
    }
    if (!submitValues.toDate) {
      errors.toDate = { message: t('must-not-empty') };
    }
    const fromDate = parseIsoDate(submitValues.fromDate);
    const toDate = parseIsoDate(submitValues.toDate);
    if (isBefore(toDate, fromDate)) {
      errors.toDate = {
        message: t('must-be-same-or-after', { column: t('date-from') }),
      };
    }
    return {
      values: submitValues,
      errors,
    };
  };
};

export const useStatisticValidation = () => {
  const { t } = useCommonTranslation();
  const validateFormTo = useValidateFromTo();
  return (
    submitValues: UnpackNestedValue<FromToValidate>,
  ): ResolverResult<FromToValidate> => {
    const fromToValidateResult = validateFormTo(submitValues);
    if (!isEmpty(fromToValidateResult.errors)) {
      return fromToValidateResult;
    }
    const fromDate = parseIsoDate(submitValues.fromDate);
    const toDate = parseIsoDate(submitValues.toDate);
    const errors: DeepPartial<FieldErrors<FromToValidate>> = {};
    if (differenceInDays(toDate, fromDate) > 90) {
      errors.toDate = {
        message: t('must-be-less-than-from', {
          count: 90,
          unit: 'days',
          column: t('date-from'),
        }),
      };
    }
    return {
      values: submitValues,
      errors,
    };
  };
};

export const useReportsValidation = () => {
  const { t } = useCommonTranslation();
  const validateFormTo = useValidateFromTo();
  return (
    submitValues: UnpackNestedValue<ValidateReports>,
  ): ResolverResult<ValidateReports> => {
    const selectedReport = submitValues.report;
    const {
      REFERRALS_CCG,
      DNA_REPORT,
      APPOINTMENTS_PATIENTS_AND_PHLEBOTOMISTS,
      PHLEBOTOMISTS_DAILY_APPTS,
      APPOINTMENTS_BREAKDOWN,
      REFERRALS_GPS_AND_CCGS,
      PHLEBOTOMISTS_MONTHLY_APPTS,
      REFERRALS_GP_SURGERIES,
      PHLEBOTOMISTS_LOGIN_TIMES,
      POSSIBLE_DUPLICATE_REPORT,
      APPOINTMENTS_BREAKDOWN_ALL_LOCATIONS,
    } = REPORT_IDS;
    const fromToValidateResult = validateFormTo(submitValues);
    if (selectedReport?.id !== POSSIBLE_DUPLICATE_REPORT) {
      if (!isEmpty(fromToValidateResult.errors)) {
        return fromToValidateResult;
      }
    }

    const errors: DeepPartial<FieldErrors<ValidateReports>> = {};
    const fromDate = parseIsoDate(submitValues.fromDate);
    const toDate = parseIsoDate(submitValues.toDate);
    if (isSameDay(fromDate, toDate)) {
      if (
        submitValues.fromHour &&
        submitValues.toHour &&
        submitValues.fromHour >= submitValues.toHour
      ) {
        errors.toHour = {
          message: t('must-be-after', { column: t('hour-from') }),
        };
      }
    }
    if (!isEmpty(errors)) {
      return {
        values: submitValues,
        errors,
      };
    }

    switch (selectedReport!!.id) {
      case REFERRALS_GPS_AND_CCGS:
      case REFERRALS_CCG:
      case REFERRALS_GP_SURGERIES:
      case PHLEBOTOMISTS_MONTHLY_APPTS:
      case DNA_REPORT:
        if (differenceInYears(toDate, fromDate) > 0) {
          errors.toDate = {
            message: t('must-be-less-than-year', { column: t('date-from') }),
          };
        }
        break;
      case PHLEBOTOMISTS_DAILY_APPTS:
      case APPOINTMENTS_BREAKDOWN:
      case PHLEBOTOMISTS_LOGIN_TIMES:
        if (differenceInDays(toDate, fromDate) > 90) {
          errors.toDate = {
            message: t('must-be-less-than-from', {
              count: 90,
              unit: 'days',
              column: t('date-from'),
            }),
          };
        }
        break;
      case APPOINTMENTS_BREAKDOWN_ALL_LOCATIONS:
      case APPOINTMENTS_PATIENTS_AND_PHLEBOTOMISTS:
        if (differenceInDays(toDate, fromDate) > 31) {
          errors.toDate = {
            message: t('must-be-less-than-from', {
              count: 1,
              unit: 'month',
              column: t('date-from'),
            }),
          };
        }
        break;
    }

    if (!isEmpty(errors)) {
      return {
        values: submitValues,
        errors,
      };
    }

    switch (selectedReport!!.id) {
      case APPOINTMENTS_BREAKDOWN:
      case APPOINTMENTS_BREAKDOWN_ALL_LOCATIONS:
        if (isInFuture(startOfDay(fromDate))) {
          errors.fromDate = {
            message: t('must-be-before', {
              column: formatDateOnlySite(addDays(new Date(), 1)),
            }),
          };
        }
        if (isInFuture(startOfDay(subDays(toDate, 1)))) {
          errors.toDate = {
            message: t('must-be-before', {
              column: formatDateOnlySite(addDays(new Date(), 2)),
            }),
          };
        }
    }

    return {
      values: submitValues,
      errors: errors,
    };
  };
};
