import { CommonButton, CommonLink } from 'components/common/Forms/Button';
import {
  FormActionsStyle,
  FormRow,
  Required,
} from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import TextArea from 'components/common/Forms/TextArea';
import { Loading } from 'components/common/Loading';
import { SiteAutoComplete } from 'components/patient/SiteAutoComplete';
import { FormDesc } from 'components/views-components/un-auth/SignupForm/SignupForm.styled';
import { useAuth } from 'context/AuthContext';
import { useRole } from 'context/RoleContext';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import {
  useMutateSendStaffSupportMessage,
  useMutateSendSupportMessage,
} from 'query/support';
import { Fragment, useCallback, useEffect } from 'react';
import { Controller, useForm, UseFormReset } from 'react-hook-form';
import { useHistory } from 'react-router';
import { patientURL } from 'routes/AppRoutes';
import { SendSupportParams } from 'services/support';
import { UserProfileResponse } from 'types/authentication';
import { SiteData } from 'types/sites';
import { optionalPhoneValidate, singleEmailPattern } from 'utils/helpers';
import { successToast } from 'utils/toast';
import { getUserFullname } from 'utils/user';
import { SupportFormStyled } from './SupportFormPage.styled';
import { ALL_ROLES_BUT_PATIENT, PATIENT } from '../../configs/constants';
import { InputPhoneNumber } from '../../components/common/Forms/InputPhoneNumber';

type SupportFormValues = {
  name: string;
  email: string;
  phone: string;
  message: string;
  site?: SiteData;
};
const supportFormInit = (): SupportFormValues => ({
  name: '',
  email: '',
  phone: '',
  message: '',
});

const useSupportForm = (reset: UseFormReset<SupportFormValues>) => {
  const { sangixUser } = useAuth();
  useEffect(() => {
    if (sangixUser)
      reset({
        name: getUserFullname(sangixUser),
        email: sangixUser.email,
        phone: sangixUser.phone,
        message: '',
      });
  }, [sangixUser, reset]);
};

const getResolution = () => window.screen.width + 'x' + window.screen.height;

const formToEndpointParams = (
  values: SupportFormValues,
  user?: UserProfileResponse,
): SendSupportParams => {
  if (user) return { message: values.message, resolution: getResolution() };
  return {
    message: values.message,
    resolution: getResolution(),
    name: values.name,
    email: values.email,
    phone: values.phone,
    site_id: values.site?.id,
  };
};

const useSendSupport = () => {
  const { mutateAsync: mutateSendSupport, isLoading: isSendSupportLoading } =
    useMutateSendSupportMessage();
  const {
    mutateAsync: mutateSendStaffSupport,
    isLoading: isSendStaffSupportLoading,
  } = useMutateSendStaffSupportMessage();
  const { hasAnyOfRoles } = useRole();
  const sendSupport = useCallback(
    async (params: SendSupportParams) => {
      if (hasAnyOfRoles(...ALL_ROLES_BUT_PATIENT)) {
        await mutateSendStaffSupport(params);
      } else {
        await mutateSendSupport(params);
      }
    },
    [mutateSendSupport, mutateSendStaffSupport, hasAnyOfRoles],
  );
  const isLoading = isSendSupportLoading || isSendStaffSupportLoading;
  return { sendSupport, isLoading };
};

export const SupportForm = () => {
  const { t } = useCommonTranslation();
  const history = useHistory();
  const { sangixUser } = useAuth();
  const { currentUserRole } = useRole();
  const { sendSupport, isLoading } = useSendSupport();
  const { handleSubmit, register, formState, reset, control } =
    useForm<SupportFormValues>({ defaultValues: supportFormInit() });
  const { errors } = formState;
  useSupportForm(reset);
  const onSubmit = useCallback(
    async (values) => {
      const params = formToEndpointParams(values, sangixUser);
      await sendSupport(params);
      successToast(t('support-message-sent'));
      history.push('/');
    },
    [sendSupport, t, history, sangixUser],
  );

  return (
    <SupportFormStyled onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <Loading />}
      <FormRow>
        <Input
          label={t('fullname')}
          required
          helperText={errors.name?.message}
          hasError={!!errors.name}
          disabled={!!sangixUser}
          {...register('name', {
            required: sangixUser ? false : (t('your-message-helper') as string),
          })}
        />
      </FormRow>
      <FormRow>
        <Input
          label={t('email')}
          disabled={!!sangixUser}
          helperText={errors.email?.message}
          hasError={!!errors.email}
          required
          {...register('email', {
            pattern: {
              value: singleEmailPattern,
              message: t('must-valid-address') as string,
            },
            required: sangixUser ? false : (t('your-message-helper') as string),
          })}
        />
        {(!sangixUser || sangixUser.phone) && (
          <Controller
            name="phone"
            control={control}
            rules={{
              validate: (value: string) => optionalPhoneValidate(t, value),
            }}
            render={({ field }) => (
              <InputPhoneNumber
                label={t('phone-number')}
                helperText={errors.phone?.message || ''}
                disabled={!!sangixUser}
                hasError={!!errors.phone}
                {...field}
              />
            )}
          />
        )}
      </FormRow>
      <FormRow>
        <TextArea
          required
          helperText={errors.message?.message}
          hasError={!!errors.message}
          label={t('your-message')}
          {...register('message', {
            required: t('your-message-helper') as string,
          })}
        />
      </FormRow>
      {!sangixUser && (
        <Fragment>
          <FormDesc>{t('support-sites-autocomplete-title')}</FormDesc>
          <SiteAutoComplete
            control={control}
            formState={formState}
            name="site"
            rules={{ required: t('your-message-helper') as string }}
            label={
              <Fragment>
                {t('hospital')}
                <Required>*</Required>
              </Fragment>
            }
          />
        </Fragment>
      )}
      <FormActionsStyle align="center">
        <CommonButton variant="primary" type="submit" size="large">
          {t('send-message')}
        </CommonButton>
        {(!currentUserRole || currentUserRole.id === PATIENT) && (
          <CommonLink variant="secondary" size="large" to={patientURL}>
            {t('back-to-menu')}
          </CommonLink>
        )}
      </FormActionsStyle>
    </SupportFormStyled>
  );
};
