import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';

import { CommonLink } from '../../../components/common/Forms/Button';
import { trustMiniSiteURL } from '../../../routes/AppRoutes';
import { TrustMiniSite } from '../../../types/trustMiniSites';
import {
  TrustMiniSiteListItem,
  TrustMiniSiteListItemName,
} from './TrustMiniSitePage.styled';
import { useMemo } from 'react';

interface Props {
  trustMiniSite: TrustMiniSite;
}

export const TrustMiniSiteCategoriesList = ({
  trustMiniSite: { trust_id, categories },
}: Props) => {
  const { t } = useCommonTranslation();
  categories = useMemo(
    () => categories?.sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [categories],
  );

  return (
    <>
      {categories.map((category) => (
        <TrustMiniSiteListItem key={category.id}>
          <TrustMiniSiteListItemName>{category.name}</TrustMiniSiteListItemName>
          <CommonLink
            variant="primary"
            to={`${trustMiniSiteURL}/${trust_id}/category/${category.id}`}
          >
            {t('book')}
          </CommonLink>
        </TrustMiniSiteListItem>
      ))}
    </>
  );
};
