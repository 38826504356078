import {
  KioskExitButtonLayoutContent,
  KioskSearchResultExitButton,
} from './Kiosk.styled';
import { KioskLayout } from './KioskLayout';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';

export const KioskLayoutWithResetKioskButton = ({
  children,
  onExitClicked,
  resetLabel,
}: {
  children: React.ReactNode;
  onExitClicked: () => any;
  resetLabel?: string;
}) => {
  const { t } = useCommonTranslation();
  return (
    <KioskLayout>
      <KioskExitButtonLayoutContent>{children}</KioskExitButtonLayoutContent>
      <KioskSearchResultExitButton onClick={onExitClicked}>
        {resetLabel || t('kiosk-cancel-check-in')}
      </KioskSearchResultExitButton>
    </KioskLayout>
  );
};
