import React from 'react';
import { Appointment } from '../../../types/appointments';
import { CommonButton } from '../../../components/common/Forms/Button';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import TextArea from '../../../components/common/Forms/TextArea';
import { FONT_LARGER } from '../../../styles/fontsStyleUtils';
import {
  FormBody,
  FormRow,
} from '../../../components/common/Forms/Forms.styled';
import { useForm } from 'react-hook-form';
import { useMutateUpdateAppointment } from '../../../query/appointments';
import { successToast } from '../../../utils/toast';
import { AppointmentForm } from '../../../hooks/appointments/useAppointmentStateWorkflow';
import { CardLoader } from '../../../components/common/Loading';
import { toRemStr } from '../../../utils/helpers';
import {
  INFO_LABEL_DESIGN_LARGER,
  InfoLabelText,
} from '../../../components/common/Forms/InfoLabelText';
import { FlexBoxSameFlex } from './QueuedAppointmentProcess.styled';
import { UserData } from '../../../types/users';

interface IAppointmentAndUserComments {
  onClose: () => void;
  currentAppointment: Appointment;
  user?: UserData;
  setCurrentAppointment: (appt: Appointment) => any;
}

export const AppointmentAndUserComments: React.FunctionComponent<
  IAppointmentAndUserComments
> = ({ onClose, currentAppointment, user, setCurrentAppointment }) => {
  const { t } = useCommonTranslation();

  const { isLoading: isAppointmentUpdateLoading, mutate: updateAppointment } =
    useMutateUpdateAppointment({
      onSuccess: (_, update) => {
        successToast(t('appointment-update-successful'));
        setCurrentAppointment({ ...currentAppointment, ...update });
        onClose();
      },
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AppointmentForm>({
    defaultValues: {
      appointment: {
        ...currentAppointment,
      },
    },
  });

  return (
    <>
      {isAppointmentUpdateLoading ? (
        <CardLoader />
      ) : (
        <FormBody>
          <TextArea
            label={t('appointment-comment')}
            placeholder={t('appointment-comment')}
            helperText={errors.appointment?.comment?.message}
            fontBasedSize={FONT_LARGER}
            {...register('appointment.comment')}
            style={{
              height: toRemStr(250),
            }}
          />

          <FormRow>
            <InfoLabelText
              label={t('user-comment')}
              text={user?.comment || '-'}
              design={INFO_LABEL_DESIGN_LARGER}
              textStyle={{
                whiteSpace: 'pre-wrap',
                maxHeight: toRemStr(250),
                overflowY: 'auto',
              }}
            />
          </FormRow>
          <FlexBoxSameFlex>
            <CommonButton
              type="button"
              size={'xlarge'}
              variant="primary"
              style={{
                flex: 1,
              }}
              onClick={async () => {
                await handleSubmit(async (data) =>
                  updateAppointment({
                    id: currentAppointment.id,
                    ...data.appointment,
                  }),
                )();
              }}
            >
              {t('save')}
            </CommonButton>
            <CommonButton
              variant={'secondary'}
              size={'xlarge'}
              onClick={onClose}
            >
              {t('back')}
            </CommonButton>
          </FlexBoxSameFlex>
        </FormBody>
      )}
    </>
  );
};
