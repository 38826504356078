import React from 'react';
import { Column, HeaderGroup, TableHeaderProps } from 'react-table';
import { getStyles, useHasFilters } from './TableUtils';
import {
  CellWrapper,
  EllipseText,
  FilterWrapper,
  SortWrapper,
  Th,
  Tr,
} from './Table.styled';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { TableHeaderFilters } from './TableHeaderFilters';

interface TableHeaderWithFiltersProps<D extends object> {
  headerGroups: Array<HeaderGroup<D>>;
  columns: Column<D>[];
}

export const headerProps = (
  props: Partial<TableHeaderProps>,
  { column }: { column: HeaderGroup<any> },
) =>
  getStyles(props, column.align, {
    ...(column.disableResizing
      ? {
          minWidth: column.minWidth, // Updated Due to set "TYPE" in Cubicle Details form to minWidth: 80
          maxWidth: column.maxWidth,
          width: 'auto', // Updated Due to set "TYPE" in Cubicle Details form to minWidth: 80
        }
      : {}),
    ...column.style,
  });

export const TableHeaderWithFilters = <T extends object>({
  headerGroups,
  columns,
}: TableHeaderWithFiltersProps<T>) => {
  const hasFilters = useHasFilters(columns);
  return (
    <FilterWrapper
      hasFilters={hasFilters}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {headerGroups.map((headerGroup) => (
        <Tr {...headerGroup.getHeaderGroupProps()} hasNoFilters={!hasFilters}>
          {headerGroup.headers.map((column) => (
            <Th
              {...column.getHeaderProps(headerProps)}
              canSort={!column.disableSortBy}
            >
              <CellWrapper>
                <EllipseText {...column.getSortByToggleProps()}>
                  {column.render('Header')}
                  {column.disableSortBy ? (
                    ''
                  ) : (
                    <SortWrapper>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FaSortDown />
                        ) : (
                          <FaSortUp />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </SortWrapper>
                  )}
                </EllipseText>
                {column.canResize && (
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? 'isResizing' : ''
                    }`}
                  />
                )}
              </CellWrapper>
            </Th>
          ))}
        </Tr>
      ))}
      {hasFilters && <TableHeaderFilters headerGroups={headerGroups} />}
    </FilterWrapper>
  );
};
