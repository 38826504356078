import {
  CacheAndStaleTimeForUnlikelyChanged,
  useMutationWithToastError,
  useQueryWithToastError,
} from './queryUtils';
import { UseMutationOptions, useQueryClient } from 'react-query';
import {
  addNewOtherReferrer,
  deleteOtherReferrer,
  getOtherReferrers,
  mergeOtherReferrers,
  updateOtherReferrer,
} from '../services/other_referrers';
import {
  OtherReferrersFilters,
  OtherReferrersFormValues,
} from '../types/otherReferrers';

const REFERRER_QUERY_KEY = 'referrers';
export const useReferrers = (filters: OtherReferrersFilters) => {
  return useQueryWithToastError(
    [
      REFERRER_QUERY_KEY,
      {
        ...filters,
      },
    ],
    () => getOtherReferrers(filters),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
      select: (data) => data.data,
    },
  );
};

export const useMutateUpdateReferrer = (
  options?: UseMutationOptions<any, any, OtherReferrersFormValues>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<OtherReferrersFormValues>(
    [REFERRER_QUERY_KEY],
    (update) => updateOtherReferrer(update),
    {
      onSettled: () => {
        client.invalidateQueries([REFERRER_QUERY_KEY]);
      },
      ...options,
    },
  );
};

export const useMutateCreateReferrer = (
  options?: UseMutationOptions<any, any, OtherReferrersFormValues>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<OtherReferrersFormValues>(
    [REFERRER_QUERY_KEY],
    (params) => addNewOtherReferrer(params),
    {
      onSettled: () => client.invalidateQueries([REFERRER_QUERY_KEY]),
      ...options,
    },
  );
};

export const useMutateDeleteReferrer = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<string>(
    [REFERRER_QUERY_KEY],
    (params) => deleteOtherReferrer(params),
    {
      onSettled: () => client.invalidateQueries([REFERRER_QUERY_KEY]),
      ...options,
    },
  );
};

interface OtherReferrersMergeProps {
  sourceId: string;
  targetId: string;
}

export const useMutateMergeReferrers = (
  options?: UseMutationOptions<any, any, OtherReferrersMergeProps>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<OtherReferrersMergeProps>(
    [REFERRER_QUERY_KEY],
    ({ sourceId, targetId }) => mergeOtherReferrers(targetId, sourceId),
    {
      onSettled: () => {
        client.invalidateQueries([REFERRER_QUERY_KEY]);
      },
      ...options,
    },
  );
};
