import Axios from 'configs/Axios';
import {
  ResourceTypeFilters,
  ResourceTypeFormValues,
  ResourceTypeResponseData,
} from 'types/resource-types';

export const getAllResourceTypes = (filters?: ResourceTypeFilters) =>
  Axios.get<ResourceTypeResponseData>('/resourceType', {
    params: filters,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const addNewResourceType = (data: ResourceTypeFormValues) =>
  Axios.post<ResourceTypeResponseData>('/resourceType', data);

export const updateResourceType = (data: ResourceTypeFormValues) =>
  Axios.put<ResourceTypeResponseData>('/resourceType', data);

export const deleteResourceType = (resourceTypeId: string) =>
  Axios.delete<ResourceTypeResponseData>(`/resourceType/${resourceTypeId}`);
