import React from 'react';
import { FetchedDataTable } from '../../../components/Table/FetchedDataTable';
import { SADApptStatistics } from '../../../types/reports';
import { usePaginatedFilters } from '../../../hooks/usePaginatedFilters';
import { PageHelmet } from '../../../components/common/PageHelmet';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import {
  AdminStatisticsFiveYearFilters,
  useAdminStatisticsLastFiveYearsData,
} from '../../../hooks/admin/useAdminStatisticsLastFiveYearsData';
import { useAdminStatisticsFiveYearsColumns } from '../../../hooks/admin/useAdminStatisticsFiveYearsColumns';
import { getYear } from '../../../utils/dateUtil';
import {
  getCSVHeader,
  useGenerateAndDownloadCSV,
} from '../../../hooks/util/useGenerateAndDownloadCSV';
import { usePaginateResults } from '../../../hooks/util/usePaginateResults';
import { CommonButton } from '../../../components/common/Forms/Button';
import { Card } from '../../../components/common';
import { AdminStatisticsLayout } from './AdminStatisticsLayout';

type IAdminAuditApptTableProps = {
  sadStatistics?: SADApptStatistics;
  isLoading: boolean;
};

const ADMIN_STATISTICS_LAST_FIVE_YEARS_EXPORT_FILE_NAME = 'site_five_years.csv';
export const AdminStatisticsLastFiveYears = ({
  sadStatistics,
  isLoading,
}: IAdminAuditApptTableProps) => {
  const { t } = useCommonTranslation();
  const { filters, setFilters } =
    usePaginatedFilters<AdminStatisticsFiveYearFilters>();

  const currentYear = getYear(new Date());

  const statisticsDataAll = useAdminStatisticsLastFiveYearsData({
    sadStatistics,
    currentYear,
  });
  const { generateAndDownload, isLoading: isGenerating } =
    useGenerateAndDownloadCSV({
      headers: [
        getCSVHeader('trustName', t('trust-name')),
        getCSVHeader('siteName', t('site-name')),
        getCSVHeader('0', currentYear.toString()),
        getCSVHeader('1', (currentYear - 1).toString()),
        getCSVHeader('2', (currentYear - 2).toString()),
        getCSVHeader('3', (currentYear - 3).toString()),
        getCSVHeader('4', (currentYear - 4).toString()),
        getCSVHeader('5', (currentYear - 5).toString()),
      ],
      rows: statisticsDataAll.data,
      filename: ADMIN_STATISTICS_LAST_FIVE_YEARS_EXPORT_FILE_NAME,
    });

  const statisticsData = usePaginateResults({
    data: statisticsDataAll,
    filters,
  });

  const { columns } = useAdminStatisticsFiveYearsColumns({ currentYear });

  return (
    <AdminStatisticsLayout>
      <PageHelmet title={t('admin-statistics-last-five-years')} />
      <Card>
        <FetchedDataTable
          columns={columns}
          data={statisticsData}
          filters={filters}
          onSortOrPageChange={setFilters}
          isLoading={isLoading}
          isFetching={isLoading}
        />
        <CommonButton
          onClick={generateAndDownload}
          disabled={isGenerating}
          variant={'primary'}
        >
          {t('admin-statistics-download-full-table')}
        </CommonButton>
      </Card>
    </AdminStatisticsLayout>
  );
};
