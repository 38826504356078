import { useSite } from '../../../context/SiteContext';
import { useCurrentServerTime } from '../../../hooks/useTime';
import { ReactComponent as SangixLogoSVG } from '../../../images/Logo/sangix-logo.svg';
import {
  DATE_FNS_DAY_FULL_MONTH_TIME_FORMAT,
  formatDate,
} from '../../../utils/dateUtil';
import {
  KioskHeaderStyled,
  KioskHeaderTime,
  KioskHeaderTitle,
  KioskSangixLogo,
} from './Kiosk.styled';

export const KioskHeader = () => {
  const { activeSite } = useSite();
  const time = useCurrentServerTime();
  const timeString = formatDate(time, DATE_FNS_DAY_FULL_MONTH_TIME_FORMAT);
  return (
    <KioskHeaderStyled>
      <KioskHeaderTime>{timeString}</KioskHeaderTime>
      <KioskHeaderTitle>{activeSite.short_name}</KioskHeaderTitle>
      <KioskSangixLogo>
        <SangixLogoSVG />
      </KioskSangixLogo>
    </KioskHeaderStyled>
  );
};
