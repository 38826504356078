import Axios from 'configs/Axios';
import { CubicleFormValues, CubicleResponseData } from 'types/cubicles';
import { IPaginatedFilters } from '../types/table';

export const getAllCubicles = (filters?: IPaginatedFilters) =>
  Axios.get<CubicleResponseData>('/cubicle', {
    params: filters,
  });

export const addNewCubicle = (data: CubicleFormValues) =>
  Axios.post('/cubicle', data);

// PUT /cubicle replaces all the cubicle data that is not passed
// pass all the cubicle data, not only that you want to change
export const updateCubicle = (data: CubicleFormValues) =>
  Axios.put('/cubicle', data);

export const deleteCubicle = (cubicleId: string) =>
  Axios.delete(`/cubicle/${cubicleId}`);
