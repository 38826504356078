import styled from 'styled-components';

export const StaffEnteredCubicleHeaderWrapper = styled.div`
  display: flex;
  column-gap: var(--s2);
  align-items: center;
  justify-content: space-between;
`;

export const StaffEnteredCubicleRightBlock = styled.div`
  display: flex;
  column-gap: var(--s2);
  align-items: center;
  justify-content: space-between;
`;

export const StaffEnteredCubicleTitle = styled.div`
  color: var(--greenaccent);
  font-weight: 600;
`;
