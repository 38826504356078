import { useCallback, useEffect } from 'react';
import { useKioskCheckin } from './useKioskCheckin';
import {
  INITIALS_INPUT,
  KIOSK_APPT_SELECT,
  KIOSK_CHECKIN_LOADING,
  KIOSK_CHECKIN_SUCCESS,
  KIOSK_GENERAL_ERROR,
  KIOSK_UPDATE_USER,
  KIOSK_UPDATE_USER_LOADING,
  PHONE_INPUT,
  SEARCHING_INITIALS,
  SEARCHING_PHONE,
  useKioskState,
} from './useKioskState';
import { useKioskFindAppointment } from './useKioskFindAppointment';
import { useSite } from '../../context/SiteContext';
import { useMutateUpdateKioskUser } from '../../query/public';
import { isTelephonePatientFromFullName } from '../../utils/user';

/**
 * In kiosk they are encouraged to write name into one input field and expected to write only firstname.
 * However in order to be well seen on patient screen this gets filled into surname and we fill the firstname with
 * placeholder just to keep both fields filled.
 */
const KIOSK_UPDATED_USER_FIRSTNAME_PLACEHOLDER = '-';
export const useKiosk = () => {
  const { activeSite } = useSite();
  const {
    kioskState,
    setKioskState,
    reset,
    kioskApptSearchInput,
    setKioskApptSearchInput,
    appt,
    setAppt,
  } = useKioskState();

  const { isLoading: isFindAppointmentLoading } = useKioskFindAppointment({
    params: kioskApptSearchInput,
    onError: (errorType) => {
      setKioskState(errorType);
    },
    onSuccess: (appt) => {
      setAppt(appt);
      setKioskState(KIOSK_APPT_SELECT);
    },
  });

  useEffect(() => {
    if (isFindAppointmentLoading) {
      if (kioskApptSearchInput.type === 'INITIALS') {
        setKioskState(SEARCHING_INITIALS);
      } else {
        setKioskState(SEARCHING_PHONE);
      }
    }
  }, [setKioskState, kioskApptSearchInput, isFindAppointmentLoading]);

  const { checkin } = useKioskCheckin({
    onError: (errorType) => {
      setKioskState(errorType);
    },
    onSuccess: () => {
      setKioskState(KIOSK_CHECKIN_SUCCESS);
    },
  });

  const appointmentCheckIn = useCallback(() => {
    if (!appt) {
      setKioskState(KIOSK_GENERAL_ERROR);
      return;
    }
    setKioskState(KIOSK_CHECKIN_LOADING);
    checkin({
      siteId: activeSite.id,
      apptId: appt.id,
    });
  }, [activeSite, setKioskState, checkin, appt]);

  const confirmAppt = useCallback(() => {
    if (appt && isTelephonePatientFromFullName(appt?.user_full_name)) {
      setKioskState(KIOSK_UPDATE_USER);
    } else {
      setKioskState(KIOSK_CHECKIN_LOADING);
      appointmentCheckIn();
    }
  }, [setKioskState, appointmentCheckIn, appt]);

  const { mutateAsync: updateKioskUser } = useMutateUpdateKioskUser({
    onError: () => {
      setKioskState(KIOSK_GENERAL_ERROR);
    },
    onSuccess: () => {
      appointmentCheckIn();
    },
  });

  const updateUserAndCheckin = useCallback(
    (name: string) => {
      if (!appt) {
        setKioskState(KIOSK_GENERAL_ERROR);
        return;
      }
      setKioskState(KIOSK_UPDATE_USER_LOADING);
      updateKioskUser({
        site_id: activeSite.id,
        appt_id: appt.id,
        firstname: KIOSK_UPDATED_USER_FIRSTNAME_PLACEHOLDER,
        lastname: name,
      });
    },
    [activeSite, appt, setKioskState, updateKioskUser],
  );

  return {
    kioskState,
    appt,
    reset,
    setInitialsInputState: () => {
      setKioskState(INITIALS_INPUT);
      setKioskApptSearchInput({ searchString: '', type: 'INITIALS' });
    },
    setPhoneInputState: () => {
      setKioskState(PHONE_INPUT);
      setKioskApptSearchInput({ searchString: '', type: 'PHONE' });
    },
    kioskApptSearchInput,
    setKioskApptSearchInput,
    onApptSelected: confirmAppt,
    updateUserAndCheckin,
  };
};
