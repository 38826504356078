import { ReactElement } from 'react';
import { matchPath, useLocation } from 'react-router';
import { ICardTabs } from 'types/common';
import { TabLink, TabsWrapper } from './CardTabs.styled';

interface Props {
  tabs: ICardTabs[];
  withDivider?: boolean;
  fluid?: boolean;
  noMargin?: boolean;
}

export const LinkCardTabs = ({
  tabs,
  withDivider,
  noMargin,
  fluid,
}: Props): ReactElement => {
  const location = useLocation();
  return (
    <TabsWrapper withDivider={withDivider} fluid={fluid} noMargin={noMargin}>
      {tabs.map((tab) => (
        <TabLink
          variant="secondary"
          key={tab.to}
          to={tab.to}
          exact={tab.exact}
          size="standard"
          $active={
            !!matchPath(location.pathname, {
              path: tab.to,
              exact: tab.exact,
            })
          }
        >
          {tab.label}
        </TabLink>
      ))}
    </TabsWrapper>
  );
};
