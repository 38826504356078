import React from 'react';
import {
  BasicLabelStyle,
  CommonInputWrapper,
  InfoLabelTextValueStyle,
} from './Forms.styled';

export type DesignType = 'design_larger' | 'design_large';

interface IInfoLabelTextProps {
  label: string;
  text?: React.ReactNode;
  style?: React.CSSProperties;
  design?: DesignType;
  textStyle?: React.CSSProperties;
}

export const INFO_LABEL_DESIGN_LARGER: DesignType = 'design_larger';
export const INFO_LABEL_DESIGN_LARGE: DesignType = 'design_large';

const keyDesign: { [key in string]: React.CSSProperties } = {
  [INFO_LABEL_DESIGN_LARGE]: {
    fontSize: 'var(--fontlg)',
    marginBottom: 'var(--s4)',
  },
  [INFO_LABEL_DESIGN_LARGER]: {
    fontSize: 'var(--fontlarger)',
    marginBottom: 'var(--s3)',
  },
};

export const InfoLabelText: React.FunctionComponent<IInfoLabelTextProps> = ({
  label,
  text,
  style,
  design,
  textStyle,
}) => {
  return (
    <CommonInputWrapper
      style={{
        ...(design && keyDesign[design]),
        ...style,
      }}
    >
      <BasicLabelStyle>{label}:</BasicLabelStyle>
      {text && (
        <>
          <InfoLabelTextValueStyle style={textStyle}>
            {text}
          </InfoLabelTextValueStyle>
        </>
      )}
    </CommonInputWrapper>
  );
};
