import styled, { css } from 'styled-components';
import { boxShadow } from 'styles/partials/boxShadow';
import { listItemMarginY } from 'styles/partials/listItemMargin';

export const bulletListItemCSS = css`
  ${boxShadow}
  padding: var(--s2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--s3);
  ${listItemMarginY('var(--s2)')}
`;
export const BulletListItem = styled.div`
  ${bulletListItemCSS}
`;
