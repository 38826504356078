import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { Card } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import { Col, Grid } from 'components/common/Grid';
import { PageHelmet } from 'components/common/PageHelmet';
import { ConfirmDialog } from 'components/Popup/ConfirmDialog/ConfirmDialog';
import {
  TableActionLeftButton,
  TableActions,
} from 'components/Table/Table.styled';
import { StaffUsersTabsTree } from 'configs/RoutesConfig';
import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';

import { useSite } from '../../../../context/SiteContext';
import { UNSAFE_FILTER_WITH_NO_PAGINATION } from '../../../../hooks/usePaginatedFilters';
import { useHasAdminAccessToSelectedSite } from '../../../../hooks/user/useHasAdminAccessToSelectedSite';
import { useStaffPatientColumns } from '../../../../hooks/user/usePatientColumns';
import { usePatientsCrud } from '../../../../hooks/user/usePatientsCrud';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useQuerySites } from '../../../../query/sites';
import { CommonPatientsTable } from '../../../common/patient/PatientsTable';
import { StaffLayout } from '../../StaffLayout';
import { PatientEditUpdate } from './PatientEditUpdate';
import { PatientCol } from './StaffPatientsPage.styled';
import { PatientDetailCard } from '../../calendar/PatientDetailCard';

export const StaffPatientsPage = () => {
  const { t } = useCommonTranslation();

  const {
    setFilters,
    closeFormHandler,
    openFormHandler,
    handleDeleteUser,
    handleConfirmDeleteUser,
    showForm,
    prefilledData,
    usersData,
    isLoading,
    isUsersFetching,
    confirmDeleteItem,
    filters,
    mySiteOnly,
    setMySiteOnly,
  } = usePatientsCrud({
    ignoreTrust: false,
    initialMySiteOnly: true,
  });

  const toggleMySiteOnly = () => {
    setMySiteOnly(!mySiteOnly);
  };

  const { data: allSitesData } = useQuerySites({
    ...UNSAFE_FILTER_WITH_NO_PAGINATION,
  });
  const { columns } = useStaffPatientColumns({
    onShowUserClicked: openFormHandler,
    onDeleteUserClicked: handleDeleteUser,
    setFilters,
    filters,
    allSites: allSitesData?.data,
    showActiveSiteFilter: !mySiteOnly,
  });

  const { activeSite, activeTrust } = useSite();
  let hasAdminAccessToSelectedSite = useHasAdminAccessToSelectedSite();

  return (
    <StaffLayout>
      <PageHelmet title={t('patients')} />
      <Grid>
        <Col md={8}>
          <Card fillHeight flex>
            <LinkCardTabs
              withDivider
              tabs={StaffUsersTabsTree(hasAdminAccessToSelectedSite, t)}
            />
            <TableActions>
              <TableActionLeftButton onClick={() => toggleMySiteOnly()}>
                {t(mySiteOnly ? 'show-all-sites-users' : 'show-my-site-users')}
              </TableActionLeftButton>
              <CommonButton variant="primary" onClick={() => openFormHandler()}>
                {t('add-new-patient')}
              </CommonButton>
            </TableActions>
            <CommonPatientsTable
              usersData={usersData?.data}
              filters={filters}
              setFilters={setFilters}
              isFetching={isUsersFetching}
              isLoading={isLoading}
              columns={columns}
            />
          </Card>
        </Col>
        <PatientCol md={4}>
          {showForm &&
            (prefilledData ? (
              <PatientDetailCard
                key={prefilledData.id}
                patient={prefilledData}
                onPatientCloseButtonClicked={() => closeFormHandler(true)}
              />
            ) : (
              <PatientEditUpdate
                closeFormHandler={() => closeFormHandler(true)}
                activeTrust={activeTrust}
                activeSite={activeSite}
              />
            ))}
        </PatientCol>
      </Grid>
      {Boolean(confirmDeleteItem) && (
        <ConfirmDialog
          isLoading={isLoading}
          handleClose={() => closeFormHandler(true)}
          icon={<FaTrashAlt />}
          title={t('confirm-delete-patient')}
          status="delete"
          actions={[
            <CommonButton
              key={confirmDeleteItem?.id + '-cancel'}
              variant="secondary"
              onClick={() => closeFormHandler(true)}
            >
              {t('cancel')}
            </CommonButton>,
            <CommonButton
              key={confirmDeleteItem?.id + '-delete'}
              variant="danger"
              onClick={handleConfirmDeleteUser}
            >
              {t('delete')}
            </CommonButton>,
          ]}
        />
      )}
    </StaffLayout>
  );
};
