import React from 'react';
import { ButtonSetStyle } from '../common/Forms/Forms.styled';
import { CommonButton as Button } from '../common/Forms/Button';
import { useTranslation } from 'react-i18next';

interface IFormActionsProps {
  onCloseClicked: () => void;
  closeLabel?: string;
}

export const ButtonSetWithCloseButton: React.FunctionComponent<
  IFormActionsProps
> = ({ onCloseClicked, closeLabel, children }) => {
  const { t } = useTranslation();
  return (
    <ButtonSetStyle>
      {children}
      <Button
        type="button"
        variant="secondary"
        onClick={() => onCloseClicked()}
      >
        {closeLabel ? closeLabel : t('cancel')}
      </Button>
    </ButtonSetStyle>
  );
};
