import { useLCD } from 'hooks/public/useLCD';
import { useCurrentServerTime } from 'hooks/useTime';
import {
  DATE_FNS_DAY_FULL_MONTH_TIME_FORMAT,
  formatDate,
} from 'utils/dateUtil';
import { DISABLE_SELF_CHECK_IN } from '../../../configs/siteAndTrustAttributes';
import { useSiteBooleanAttributes } from '../../../hooks/useAttribute';
import { ReactComponent as SangixLogoSVG } from '../../../images/Logo/sangix-logo-top-only.svg';
import {
  LCDCheckinNumber,
  LCDCheckinNumberBox,
  LCDFooterStyled,
  LCDFooterTime,
} from './LCD.styled';

export const LCDFooter = () => {
  const time = useCurrentServerTime();
  const timeString = formatDate(time, DATE_FNS_DAY_FULL_MONTH_TIME_FORMAT);
  const { code } = useLCD();
  const [disableSelfCheckin] = useSiteBooleanAttributes(DISABLE_SELF_CHECK_IN);

  return (
    <LCDFooterStyled>
      <LCDFooterTime>{timeString}</LCDFooterTime>
      {!disableSelfCheckin && (
        <LCDCheckinNumberBox>
          Checkin number<LCDCheckinNumber>{code}</LCDCheckinNumber>
        </LCDCheckinNumberBox>
      )}
      <SangixLogoSVG />
    </LCDFooterStyled>
  );
};
