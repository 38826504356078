import { Appointment } from '../../../../types/appointments';
import { FaRegEyeSlash } from 'react-icons/fa';
import React, { Fragment } from 'react';
import { useUserDisabilities } from '../../../../hooks/user/useUserDisabilities';
import { MdDirectionsWalk, MdHearingDisabled } from 'react-icons/md';
import { useParseApptFlags } from '../../../../hooks/appointments/useParseApptFlags';

type IAppointmentIconsProps = Pick<
  Appointment,
  'appt_flags' | 'user_disabilities'
>;

export const AppointmentPatientInfoIcons = ({
  appt_flags,
  user_disabilities,
}: IAppointmentIconsProps) => {
  const userDisabilities = useUserDisabilities(user_disabilities);
  const flags = useParseApptFlags(appt_flags);
  return (
    <>
      {flags.map((flag) => (
        <Fragment key={`flag_${flag.id}`}>{flag.icon}</Fragment>
      ))}
      {userDisabilities.map((disability, index) => {
        if (disability === 'Movement') {
          return <MdDirectionsWalk key={index} />;
        } else if (disability === 'Hearing') {
          return <MdHearingDisabled key={index} />;
        } else {
          return <FaRegEyeSlash key={index} />;
        }
      })}
    </>
  );
};
