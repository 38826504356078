import React from 'react';
import { FaHospitalUser } from 'react-icons/fa';
import { StaffLayout } from '../../StaffLayout';
import { PageHelmet } from '../../../../components/common/PageHelmet';
import { Grid4to8 } from '../../../../components/Layout/Grid4to8';
import { MergeCard } from '../../../../components/common/Merge/MergeCard';
import { StaffSettingsTabsTree } from '../../../../configs/RoutesConfig';
import { FormTitle } from '../../../../components/common/Forms/Forms.styled';
import {
  SOURCE_SELECTION_STATE,
  TARGET_SELECTION_STATE,
  useMergeEntity,
} from '../../../../hooks/util/useMergeEntity';
import { MergeRedInfoBox } from '../../../../components/common/Merge/mergeStyle';
import { Card } from '../../../../components/common';
import { FetchedDataTable } from '../../../../components/Table/FetchedDataTable';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { usePaginatedFilters } from '../../../../hooks/usePaginatedFilters';
import {
  getFilterControls,
  isNOTCellSelected,
} from '../../../../components/Table/TableUtils';
import { RowActions } from '../../../../components/Table/Table.styled';
import { CommonButton as Button } from '../../../../components/common/Forms/Button';
import { successToast } from '../../../../utils/toast';
import {
  OtherReferrer,
  OtherReferrersFilters,
} from '../../../../types/otherReferrers';
import {
  useMutateMergeReferrers,
  useReferrers,
} from '../../../../query/referrers';
import { useOtherReferrersColumns } from '../../../../hooks/referrals/useOtherReferrersColumns';
import { Column } from 'react-table';
import { MergeOtherReferrerBox } from './merge/MergeOtherReferrerBox';
import { IDCellPropsWithSelected } from '../../../../types/table';

export const OtherReferrersMerge = () => {
  const { t } = useCommonTranslation();

  const { setFilters, filters } = usePaginatedFilters<OtherReferrersFilters>();
  const {
    isLoading,
    isFetching,
    data: otherReferrerData,
  } = useReferrers(filters);
  const {
    target,
    selectMergeItem,
    clearSelection,
    toggleSelectionState,
    selectionState,
    source,
    setSource,
    selectedIds,
  } = useMergeEntity({
    entities: otherReferrerData?.data,
  });

  const columns = useOtherReferrersColumns({
    lastColumn: {
      ...getFilterControls({
        t,
        filters,
        setFilters,
      }),
      Cell: ({ value, ...rest }: IDCellPropsWithSelected<OtherReferrer>) => {
        return (
          <RowActions>
            {isNOTCellSelected(rest) && (
              <Button
                size="auto"
                variant="primary"
                onClick={() => selectMergeItem(value)}
              >
                {t('select')}
              </Button>
            )}
          </RowActions>
        );
      },
    } as Column<OtherReferrer>,
  });
  const { mutate: mergeOtherReferrers, isLoading: isMergeReferrersLoading } =
    useMutateMergeReferrers({
      onSuccess: () => {
        setSource(undefined);
        successToast(t('merge-other-referrers-success'));
      },
    });
  return (
    <StaffLayout>
      <PageHelmet title={t('other-referrers-merge')} />
      <Grid4to8
        leftBox={
          <MergeCard
            tabs={StaffSettingsTabsTree(t)}
            cardHeader={
              <>
                <FaHospitalUser />
                <FormTitle>{t('other-referrers-merge')}</FormTitle>
              </>
            }
            isLoading={isMergeReferrersLoading}
            onClearSelection={clearSelection}
            onMerge={() =>
              source &&
              target &&
              mergeOtherReferrers({
                sourceId: source.id,
                targetId: target.id,
              })
            }
            target={target}
            source={source}
          >
            <MergeOtherReferrerBox
              label={t('merge-other-referrers-target')}
              onSelectClicked={() =>
                toggleSelectionState(TARGET_SELECTION_STATE)
              }
              isSelecting={selectionState === TARGET_SELECTION_STATE}
              otherReferrer={target}
            />
            <MergeOtherReferrerBox
              label={t('merge-other-referrers-source')}
              otherReferrer={source}
              onSelectClicked={() =>
                toggleSelectionState(SOURCE_SELECTION_STATE)
              }
              isSelecting={selectionState === SOURCE_SELECTION_STATE}
            />
            <MergeRedInfoBox>
              {source && target
                ? t('merge-other-referrers-ready-info')
                : t('merge-other-referrers-select')}
            </MergeRedInfoBox>
          </MergeCard>
        }
        rightBox={
          selectionState && (
            <Card>
              <FetchedDataTable
                columns={columns}
                data={otherReferrerData}
                isLoading={isLoading}
                filters={filters}
                onSortOrPageChange={setFilters}
                isFetching={isFetching}
                selectedIds={selectedIds}
              />
            </Card>
          )
        }
      />
    </StaffLayout>
  );
};
