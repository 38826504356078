import {
  PopupDesc,
  PopupIconWrapper,
  PopupTitle,
} from 'components/Popup/ConfirmDialog/ConfirmDialog.styled';
import { CardHeader } from 'components/common';
import { PatientLayout } from 'containers/PatientLayout';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { PropsWithChildren, ReactNode } from 'react';
import { FaUserCheck } from 'react-icons/fa';
import { usePatientCheckIn } from '../../../context/patient/modules/checkIn';
import { PatientCheckInCard } from './PatientCheckIn.styled';

export type PatientCheckInLayoutProps = PropsWithChildren<{
  description?: {
    html: ReactNode;
    compact?: boolean;
  };
  label?: string;
  widerCard?: boolean;
}>;

export const PatientCheckInLayout = ({
  description,
  children,
  label,
  widerCard,
}: PatientCheckInLayoutProps) => {
  const { t } = useCommonTranslation();
  const {
    checkIn: { siteName },
  } = usePatientCheckIn();

  return (
    <PatientLayout graphics="patient">
      <PatientCheckInCard
        style={
          widerCard
            ? {
                paddingLeft: 'var(--s3)',
                paddingRight: 'var(--s3)',
              }
            : {}
        }
      >
        <CardHeader>
          <PopupIconWrapper inlineIcon>
            <FaUserCheck />
          </PopupIconWrapper>
          <PopupTitle>
            {label
              ? label
              : siteName
              ? t('check-in-at-name', { siteName })
              : t('check-in-at')}
          </PopupTitle>
          {description && (
            <PopupDesc compact={description.compact}>
              {description.html}
            </PopupDesc>
          )}
        </CardHeader>
        {children}
      </PatientCheckInCard>
    </PatientLayout>
  );
};
