import React from 'react';

import { Card } from '../../../components/common';
import { IInfoPopupInfoText, Popup } from '../../../components/Popup/Popup';
import { UserData } from '../../../types/users';

export type UserRowActionCreator = (user: UserData) => React.ReactNode;

type Props = IInfoPopupInfoText & {
  children: React.ReactNode;
};

export const SearchPatientDialog = ({ infoText, children }: Props) => {
  return (
    <Popup closeOnDocumentClick={false} open={true} infoText={infoText}>
      <Card
        style={{
          minHeight: 'calc(var(--s8) * 2)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {children}
      </Card>
    </Popup>
  );
};
