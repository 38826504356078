import { useSiteStringAttributes } from '../useAttribute';
import {
  PRE_CHECK_IN_QUESTION,
  PRE_CHECK_IN_QUESTION_NO,
} from '../../configs/siteAndTrustAttributes';

export const usePreCheckinQuestion = () => {
  const [preCheckinQuestion, preCheckinQuestionNo] = useSiteStringAttributes(
    PRE_CHECK_IN_QUESTION,
    PRE_CHECK_IN_QUESTION_NO,
  );
  return {
    preCheckinQuestion,
    preCheckinQuestionNo,
    hasPreCheckinQuestion: !!preCheckinQuestion,
  };
};
