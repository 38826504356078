import { addDays, endOfDay, startOfDay, subDays } from '../../utils/dateUtil';
import {
  CommonPrevNextRangePickerProps,
  PrevNextRangePicker,
} from './PrevNextRangePicker';

export type PrevNextDayPickerProps = CommonPrevNextRangePickerProps;

export const PrevNextDayPicker = (props: PrevNextDayPickerProps) => {
  return (
    <PrevNextRangePicker
      {...props}
      startOfRange={startOfDay}
      endOfRange={endOfDay}
      subRange={subDays}
      addRange={addDays}
    ></PrevNextRangePicker>
  );
};
