import React, { useContext, useState } from 'react';

interface IPopupContext {
  setPopup?: (popup: React.ReactNode) => void;
}

const defaultContext: IPopupContext = {};

export const PopupContext = React.createContext<IPopupContext>(defaultContext);
PopupContext.displayName = 'Popup Context'; // Only for debugging

export const usePopup = () => useContext(PopupContext);

/**
 * This is z-index that reactjs-popup applies to overlay
 */
export const POPUP_OVERLAY_Z_INDEX = 999;

export const PopupProvider: React.FC = ({ children }) => {
  const [popup, setPopup] = useState<React.ReactNode>();

  return (
    <PopupContext.Provider value={{ setPopup }}>
      <div id={'popup-root'} />
      {popup}
      {children}
    </PopupContext.Provider>
  );
};
