import { AxiosResponse } from 'axios';
import { UseQueryOptions } from 'react-query';
import { SiteData } from 'types/sites';
import { useEffect } from 'react';
import { useSite } from 'context/SiteContext';
import { useHistory, useParams } from 'react-router';
import { usePublicSiteQuery } from '../../query/public';
import { errorToast } from '../../utils/toast';

export const usePublicSite = (
  options: UseQueryOptions<AxiosResponse<SiteData>> = {},
) => {
  const { siteId } = useParams<{ siteId: string }>();
  const { activeSite, setActiveSite } = useSite();
  const history = useHistory();
  const query = usePublicSiteQuery(siteId, options);
  const isActiveSiteUpdated = activeSite?.id !== query.data?.data.id;
  const isReady = !query.isLoading && !query.isError && !isActiveSiteUpdated;

  useEffect(() => {
    (async () => {
      const invalidResult = !query.isLoading && !query.data?.data;
      if (query.data?.data) {
        try {
          await setActiveSite(query.data?.data, { save: false });
        } catch (err) {
          errorToast(err);
        }
      }
      if (query.isError || invalidResult) {
        history.replace('/404');
      }
    })();
  }, [query.data, query.isError, query.isLoading, history, setActiveSite]);
  return { query, activeSite, isReady };
};
