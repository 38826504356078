import Axios from 'configs/Axios';
import { CCGFilters, CCGFormValues, CCGResponseData } from 'types/ccg';

export const getAllCCG = (filters?: CCGFilters) =>
  Axios.get<CCGResponseData>('/ccg', {
    params: filters,
  });

export const deleteCCG = (ccgId: string) => Axios.delete(`/ccg/${ccgId}`);

export const addNewCCG = (data: CCGFormValues) =>
  Axios.post<CCGResponseData>('/ccg', data);

export const updateCCG = (data: CCGFormValues) => Axios.put('/ccg', data);

export const mergeCCGs = (targetId: string, sourceId: string) =>
  Axios.post(`/ccg/${targetId}/merge/${sourceId}`);
