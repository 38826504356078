import React, { useEffect } from 'react';
import { Appointment } from '../../../types/appointments';
import { UserFormValues } from '../../../types/users';
import { AppointmentCard } from '../../../components/views-components/staff/appointments/AppointmentCard';
import { FormBody } from '../../../components/common/Forms/Forms.styled';
import { StaffOrPatientFormData } from '../../../components/views-components/staff/settings/users/StaffOrPatientFormData';
import { useForm } from 'react-hook-form';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import {
  BookAppointmentForm,
  EditAppointmentData,
} from '../../../components/views-components/staff/appointments/EditAppointmentData';
import { DuplicatePatientDialog } from './DuplicatePatientDialog';
import { ButtonSetWithCloseButton } from '../../../components/Form/ButtonSetWithCloseButton';
import { CommonButton as Button } from '../../../components/common/Forms/Button';
import { FaUserClock } from 'react-icons/fa';
import { useStaffAppointmentBookNoPatient } from './hooks/useStaffAppointmentBookNoPatient';
import { useSite } from '../../../context/SiteContext';
import { CardSubtitle } from '../../../components/common';

interface IAppointmentFormProps {
  closeForm: () => void;
  selectedAppointmentSlot: Appointment[];
  isWalkInAvailable: boolean;
}

type AppointmentWithUser = {
  user: UserFormValues;
  appointment: BookAppointmentForm;
};

export const BookAppointmentNoPatient: React.FunctionComponent<
  IAppointmentFormProps
> = ({ selectedAppointmentSlot, closeForm, isWalkInAvailable }) => {
  const { t } = useCommonTranslation();
  const { activeTrust } = useSite();

  const {
    selectedAppointment,
    createAndBookPatientWithValidation,
    duplicateResolveSelect,
    duplicateResolveCreateNew,
    duplicateUsers,
    isLoading,
    surgeryOptions,
    referrerOptions,
  } = useStaffAppointmentBookNoPatient({
    selectedAppointmentSlot,
    closeForm,
  });

  const {
    register,
    control,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<AppointmentWithUser>();

  useEffect(() => {
    setFocus('user.firstname');
  }, [setFocus]);

  return (
    <AppointmentCard
      isLoading={isLoading}
      title={t('appointment-detail-title')}
      onCloseClicked={closeForm}
      icon={<FaUserClock />}
    >
      <FormBody>
        <StaffOrPatientFormData
          gpOptions={surgeryOptions}
          register={register}
          control={control}
          errors={errors}
          trusts={[activeTrust]}
        />
        <CardSubtitle>{t('appointment-detail-information')}</CardSubtitle>
        <EditAppointmentData
          register={register}
          control={control}
          errors={errors}
          referrers={referrerOptions}
          selectedAppointment={selectedAppointment}
        />
        {duplicateUsers && (
          <DuplicatePatientDialog
            duplicateUsers={duplicateUsers}
            onCreateNewPatientSelected={duplicateResolveCreateNew}
            onPatientSelected={async (patient) => {
              await duplicateResolveSelect({ selectedPatient: patient });
            }}
          />
        )}
        <ButtonSetWithCloseButton onCloseClicked={closeForm}>
          {isWalkInAvailable && (
            <Button
              variant="primary"
              type={'button'}
              onClick={async () => {
                await handleSubmit((data) =>
                  createAndBookPatientWithValidation({
                    queue: isWalkInAvailable,
                    appointment: data.appointment,
                    user: data.user,
                  }),
                )();
              }}
            >
              {t('appointment-details-save-and-queue')}
            </Button>
          )}
          <Button
            variant="primary"
            type={'button'}
            onClick={async () => {
              await handleSubmit((data) => {
                createAndBookPatientWithValidation({
                  queue: false,
                  appointment: data.appointment,
                  user: data.user,
                });
              })();
            }}
          >
            {t('save')}
          </Button>
        </ButtonSetWithCloseButton>
      </FormBody>
    </AppointmentCard>
  );
};
