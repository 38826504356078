import { IStateTab } from '../components/Card/CardTabs/StateTabs';
import { useMemo, useState } from 'react';

export type IUseStateTabsTabs = {
  [tabKey: string]: Omit<IStateTab, 'active' | 'onClick'>;
};

export const useStateTabs = <D extends keyof IUseStateTabsTabs>({
  tabs,
  firstSelected,
}: {
  tabs: IUseStateTabsTabs;
  firstSelected: D;
}): {
  tabsConfig: IStateTab[];
  selectedTabKey: D;
} => {
  const [selectedTabKey, setSelectedTabKey] = useState<D>(firstSelected);
  const tabsConfig: IStateTab[] = useMemo(() => {
    return Object.keys(tabs).map((key) => {
      return {
        ...tabs[key],
        active: key === selectedTabKey,
        onClick: () => setSelectedTabKey(key as D),
      };
    });
  }, [tabs, selectedTabKey]);
  return {
    tabsConfig,
    selectedTabKey,
  };
};
