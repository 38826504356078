import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import styled from 'styled-components';

export const BookingForStyled = styled.div`
  color: var(--greenaccent);
  font-weight: bold;
  letter-spacing: 0.02px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--s3);
`;
export type BookingForProps = {
  name: string;
  department: string;
};
export const BookingFor = ({ name, department }: BookingForProps) => {
  const { t } = useCommonTranslation();

  return (
    <BookingForStyled>
      <div>{t('booking-name', { name })}</div>
      <div>{t('booking-department', { department })}</div>
    </BookingForStyled>
  );
};
