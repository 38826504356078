import { PATIENT } from '../configs/constants';
import { UserProfileResponse } from '../types/authentication';
import { getSiteRolesTrustIds } from './user';
import { UserData } from '../types/users';

export const getPatientSiteRoles = (user: UserProfileResponse | UserData) => {
  return user.roles.filter((role) => role.role_id === PATIENT);
};

export const getPatientTrustIds = (user: UserProfileResponse | UserData) => {
  return getSiteRolesTrustIds(getPatientSiteRoles(user));
};
