import { useHistory } from 'react-router';
import {
  useExistingAppointments,
  useMutateCallAppointment,
} from '../../../query/appointments';
import { Appointment } from '../../../types/appointments';
import { CubicleData } from '../../../types/cubicles';
import { Refetch } from '../../../types/query';
import {
  MILLIS_IN_SECOND,
  todayInIso,
  tomorrowInIso,
} from '../../../utils/dateUtil';
import { queuedProcessingApptRoute } from '../../../routes/staff/StaffRoutesAndMenu';
import { UseMutateFunction } from 'react-query';

export const useQueuedAppointments = ({
  cubicle,
}: {
  cubicle: CubicleData;
}): {
  isLoading: boolean;
  appointments: Appointment[] | null | undefined;
  refetch: Refetch;
  callAppointment: UseMutateFunction<unknown, unknown, string, unknown>;
} => {
  const today = todayInIso();
  const tomorrow = tomorrowInIso();
  const {
    data,
    isLoading: existingAppointmentsLoading,
    refetch,
  } = useExistingAppointments(
    {
      statuses: ['QUEUE'],
      fromDate: today,
      toDate: tomorrow,
      resourceTypeIDs: cubicle.resource_types?.map((r) => r.id),
    },
    {
      refetchInterval: 5 * MILLIS_IN_SECOND,
    },
  );

  const { replace } = useHistory();

  const { isLoading: callAppointmentLoading, mutate: callAppointment } =
    useMutateCallAppointment({
      /**
       * Needs to navigate after the cache has been changed and correct queries invalidated.
       */
      successAfterSettled: (apptId) =>
        replace(queuedProcessingApptRoute(apptId)),
    });

  return {
    isLoading: existingAppointmentsLoading || callAppointmentLoading,
    /**
     * Optimistic cache only update ignore filters, so this is useful for that case
     */
    appointments:
      data?.data.data?.filter(({ status }) => {
        return status === 'QUEUE';
      }) || [],
    refetch,
    callAppointment,
  };
};
