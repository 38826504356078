import {
  CommonButton as Button,
  CommonLink,
} from 'components/common/Forms/Button';
import CommonInput from 'components/common/Forms/Input';
import { useAuth } from 'context/AuthContext';
import GoogleLogoImg from 'images/icons/Google__G__Logo.svg';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { LoginFormValues } from 'types/authentication';
import { hookFromTrimBeforeValidation, toRem } from 'utils/helpers';

import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useRedirectToKeepHash } from '../../../../hooks/router/useRedirectToKeepHash';
import { useEmailValidation } from '../../../../hooks/validation/useEmailValidation';
import { SIGN_UP_ROUTE } from '../../../../routes/AppRoutes';
import { LABEL_BEFORE } from '../../../../styles/fontsStyleUtils';
import {
  FormTitle,
  FormWrapper,
  LoginFormActions,
  LoginPartWrapper,
  LoginWithGoogleInner,
  NewUser,
} from './LoginForm.styled';

export const LoginForm: React.FC = () => {
  const auth = useAuth();
  const { t } = useCommonTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState } = useForm<LoginFormValues>();

  const submitHandler: SubmitHandler<LoginFormValues> = async (values) => {
    setIsLoading(true);
    await auth.loginEmail(values.email, values.password);
    setIsLoading(false);
  };

  const emailValidation = useEmailValidation();
  const [signUpTo] = useRedirectToKeepHash(SIGN_UP_ROUTE);

  return (
    <>
      <FormWrapper as="form" onSubmit={handleSubmit(submitHandler)}>
        <LoginPartWrapper>
          <FormTitle>{t('welcome-back')}</FormTitle>
          <CommonInput
            labelPosition={LABEL_BEFORE}
            label={t('email')}
            placeholder={t('type-email-here')}
            disabled={isLoading}
            fixedLabelWidth={`${toRem(80)}rem`}
            hasError={Boolean(formState.errors.email)}
            helperText={formState?.errors?.email?.message}
            {...register('email', emailValidation)}
          />
          <CommonInput
            labelPosition={LABEL_BEFORE}
            label={t('password')}
            placeholder={t('type-password-here')}
            type="password"
            disabled={isLoading}
            fixedLabelWidth={`${toRem(80)}rem`}
            hasError={Boolean(formState.errors.password)}
            helperText={formState?.errors?.password?.message}
            {...register('password', {
              required: t('must-not-empty') as string,
              minLength: {
                value: 8,
                message: t('password-length-validation') as string,
              },
              ...hookFromTrimBeforeValidation,
            })}
          />

          <LoginFormActions>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {t('login')}
            </Button>
            <Button
              variant="secondary"
              onClick={auth.loginGoogle}
              toneColor="var(--blueprimary)"
              type="button"
              disabled={isLoading}
            >
              <LoginWithGoogleInner>
                <img src={GoogleLogoImg} alt="" />
                {t('login-with-google')}
              </LoginWithGoogleInner>
            </Button>
            <CommonLink
              variant="secondary"
              to="/reset-password"
              disabled={isLoading}
            >
              {t('forgot-password')}
            </CommonLink>
          </LoginFormActions>
        </LoginPartWrapper>
        <NewUser>
          <FormTitle>{t('new-user')}</FormTitle>
          <LoginFormActions>
            <CommonLink to={signUpTo} variant="secondary" disabled={isLoading}>
              {t('signup-with-email')}
            </CommonLink>
            <Button
              variant="secondary"
              onClick={auth.loginGoogle}
              toneColor="var(--blueprimary)"
              type="button"
              disabled={isLoading}
            >
              <LoginWithGoogleInner>
                <img src={GoogleLogoImg} alt="" />
                {t('signup-with-google')}
              </LoginWithGoogleInner>
            </Button>
          </LoginFormActions>
        </NewUser>
      </FormWrapper>
    </>
  );
};
