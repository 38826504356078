import React, { useCallback, useContext } from 'react';
import { ButtonTypes, CommonButton } from '../../common/Forms/Button';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { ConfirmDialog, ConfirmDialogProps } from './ConfirmDialog';
import { PopupContext } from '../../../context/PopupContext';
import { SubmitHandler, useForm } from 'react-hook-form';
import CommonInput from '../../common/Forms/Input';
import { LABEL_BEFORE } from '../../../styles/fontsStyleUtils';

type PartialConfirmDialogProps = Omit<
  ConfirmDialogProps,
  'handleClose' | 'open' | 'isLoading' | 'actions'
>;

export type ConfirmFunction = (
  props: PartialConfirmDialogProps & {
    onConfirm: () => void;
    confirmButtonText?: string;
    confirmButtonVariant?: ButtonTypes;
    expectedConfirmText: string;
  },
) => void;

const TypedConfirmDialog = ({
  confirmButtonText,
  confirmButtonVariant,
  onConfirm,
  onClose,
  expectedConfirmText,
  ...dialogProps
}: PartialConfirmDialogProps & {
  onConfirm: () => void;
  confirmButtonText?: string;
  confirmButtonVariant?: ButtonTypes;
  expectedConfirmText: string;
  onClose: () => any;
}) => {
  const { t } = useCommonTranslation();
  const { register, handleSubmit, formState, watch } =
    useForm<{ confirmText: string }>();

  const confirmText = watch('confirmText');

  const submitHandler: SubmitHandler<{ confirmText: string }> = () => {
    onConfirm();
    onClose();
  };

  return (
    <ConfirmDialog
      key={'confirm-dialog'}
      handleClose={onClose}
      isLoading={false}
      actions={[
        <CommonButton
          key={'confirm_true'}
          variant={confirmButtonVariant || 'primary'}
          disabled={expectedConfirmText !== confirmText}
          onClick={() => {
            handleSubmit(submitHandler)();
          }}
        >
          {confirmButtonText || t('ok')}
        </CommonButton>,
        <CommonButton
          key={'confirm_false'}
          variant="secondary"
          onClick={onClose}
        >
          {t('cancel')}
        </CommonButton>,
      ]}
      {...dialogProps}
    >
      <CommonInput
        labelPosition={LABEL_BEFORE}
        hasError={Boolean(formState.errors.confirmText)}
        helperText={formState?.errors?.confirmText?.message}
        {...register('confirmText')}
      />
    </ConfirmDialog>
  );
};

export const useTypedConfirmDialog = (): {
  confirm: ConfirmFunction;
} => {
  const { setPopup } = useContext(PopupContext);

  const confirm: ConfirmFunction = useCallback(
    ({ ...dialogProps }) => {
      const close = () => setPopup?.(undefined);
      setPopup?.(<TypedConfirmDialog {...dialogProps} onClose={close} />);
    },
    [setPopup],
  );

  return {
    confirm,
  };
};
