import { GlobalAttribute } from 'types/common';

import {
  REPORT_EMAILS_ATTRIBUTE,
  SUPPORT_EMAILS_ATTRIBUTE,
} from '../../../../configs/siteAndTrustAttributes';
import {
  siteAttributeKeysSubset,
  updateSiteAttributes,
} from '../SitePreferencesHooks';
import { UseGeneralSitePreferencesFormHooks } from './GeneralSitePreferencesHooks';

const keys = siteAttributeKeysSubset([SUPPORT_EMAILS_ATTRIBUTE]);

const restAttributes: GlobalAttribute[] = keys.map((key) => ({ key }));

export const useSettingsSupportFormHooks: UseGeneralSitePreferencesFormHooks =
  () => {
    return {
      restAttributes,
      beforeSubmit: (updatedSite, formValues) => {
        const keysToUpdate = siteAttributeKeysSubset([
          ...keys,
          REPORT_EMAILS_ATTRIBUTE,
        ]);
        // Make sure SUPPORT_EMAILS_ATTRIBUTE is processed earlier
        if (
          keysToUpdate.indexOf(SUPPORT_EMAILS_ATTRIBUTE) >
          keysToUpdate.indexOf(REPORT_EMAILS_ATTRIBUTE)
        ) {
          throw new Error(
            'SUPPORT_EMAILS_ATTRIBUTE key must be before REPORT_EMAILS_ATTRIBUTE',
          );
        }
        return {
          ...updatedSite,
          attributes: updateSiteAttributes(
            updatedSite.attributes,
            formValues.attributes,
            [...keys, REPORT_EMAILS_ATTRIBUTE],
            (key, siteValue, formValue, updatedSiteAttributes) => {
              switch (key) {
                case REPORT_EMAILS_ATTRIBUTE:
                  // Just copy same settings as for SUPPORT_EMAILS_ATTRIBUTE
                  const supportEmail = updatedSiteAttributes.find(
                    (atr) => atr.key === SUPPORT_EMAILS_ATTRIBUTE,
                  );
                  return supportEmail
                    ? {
                        ...supportEmail,
                        key: REPORT_EMAILS_ATTRIBUTE,
                      }
                    : siteValue;
                default:
                  return formValue || siteValue;
              }
            },
          ),
        };
      },
    };
  };
