import { Card, CardFooter, FadeContent, FadeWrapper } from 'components/common';
import CustomScrollbar from 'components/common/CustomScrollbar';
import { CommonButton } from 'components/common/Forms/Button';
import { QuillPreview } from 'components/WYSIWYG/WYSIWYG.styled';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import ReactQuill from 'react-quill';

type GuidelinesCardProps = {
  text?: string;
  onAccept: () => unknown;
};
export const GuidelinesCard = ({ text, onAccept }: GuidelinesCardProps) => {
  const { t } = useCommonTranslation();
  return (
    <Card fade>
      <FadeWrapper>
        <CustomScrollbar
          style={{ width: '100%' }}
          autoHeight
          autoHeightMin={'100%'}
          autoHeightMax={'100%'}
        >
          <FadeContent>
            <QuillPreview>
              <ReactQuill
                modules={{
                  toolbar: false,
                }}
                value={text || ''}
                readOnly={true}
                theme="snow"
              />
            </QuillPreview>
          </FadeContent>
        </CustomScrollbar>
      </FadeWrapper>
      <CardFooter>
        <CommonButton variant="primary" onClick={onAccept}>
          {t('confirm')}
        </CommonButton>
      </CardFooter>
    </Card>
  );
};
