import { useMemo, useState } from 'react';
import { SUPERADMIN } from '../../../../../configs/constants';
import { useAuth } from '../../../../../context/AuthContext';
import { useRole } from '../../../../../context/RoleContext';
import { useSite } from '../../../../../context/SiteContext';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { useRoleOptions } from '../../../../../hooks/staff/useRoleOptions';
import {
  useAddUserRoleMutation,
  useRemoveUserRoleMutation,
} from '../../../../../query/users';
import { Backend_Role, SelectOption } from '../../../../../types/common';
import {
  RoleUpdateObject,
  ThirdPartyLimit,
  UserData,
} from '../../../../../types/users';
import { successToast } from '../../../../../utils/toast';
import {
  thirdPartyLimitFromApiValue,
  thirdPartyLimitToApiValue,
} from '../../../../../utils/user';
import {
  CardDividerHeaderSection,
  CardSubtitle,
  CardNote,
} from '../../../../common';
import { CardLoader } from '../../../../common/Loading';
import { NO_ROLE, StaffSiteRole, StaffSiteRoleRow } from './StaffSiteRoleRow';
import { StaffThirdPartyLimit } from './StaffThirdPartyLimit';

interface Props {
  roles?: Backend_Role[] | null;
  userData: UserData;
  disabled?: boolean;
}

export const StaffSiteRoles = ({ roles, userData, disabled }: Props) => {
  const { t } = useCommonTranslation();
  const { sangixUser } = useAuth();
  const { hasAnyOfRoles } = useRole();
  const { allSites, activeSite, activeTrust } = useSite();
  const [rerenderSiteRolesKey, setRerenderSiteRolesKey] = useState(0);
  const [thirdPartyRoleUpdate, setThirdPartyRoleUpdate] = useState<{
    thirdPartyLimit: ThirdPartyLimit;
    roleUpdate: RoleUpdateObject;
    rerenderSiteRolesOnCancel: boolean;
  }>();

  const {
    mutate: mutateAddOrUpdateUserRole,
    isLoading: isAddOrUpdateUserRoleLoading,
  } = useAddUserRoleMutation({
    onSuccess: () => {
      successToast(t('role-updated-successfully'));
    },
  });
  const { mutate: mutateRemoveUserRole, isLoading: isRemoveUserRoleLoading } =
    useRemoveUserRoleMutation({
      onSuccess: () => {
        successToast(t('role-updated-successfully'));
      },
    });

  const rolesOptions: SelectOption[] = useRoleOptions(roles);

  const { activeSiteRole, siteRoles } = useMemo(() => {
    let sitesAvailable = [];
    if (hasAnyOfRoles(SUPERADMIN)) {
      sitesAvailable =
        allSites
          ?.filter((site) => site.trust_id === activeTrust.id)
          .map((site) => ({
            label: site.short_name,
            site_id: site.id,
          })) || [];
    } else {
      sitesAvailable =
        sangixUser?.roles.map((role) => ({
          label: role.site_short_name,
          site_id: role.site_id,
        })) || [];
    }
    const siteRoles: StaffSiteRole[] = sitesAvailable.map((site) => {
      const selectedRole = userData.roles.find(
        ({ site_id }) => site_id === site.site_id,
      );
      return {
        ...site,
        roleOptions:
          site.site_id === userData.active_site_id
            ? rolesOptions
            : [
                {
                  label: t('no-role'),
                  value: NO_ROLE,
                },
                ...rolesOptions,
              ],
        selectedRole: selectedRole?.role_id || NO_ROLE,
        thirdPartyLimit: thirdPartyLimitFromApiValue(
          selectedRole?.third_party_limit,
        ),
      };
    });
    let activeSiteRole: StaffSiteRole | undefined = undefined;
    const activeSiteRoleIdx = siteRoles.findIndex(
      (siteRole) => siteRole.site_id === activeSite.id,
    );
    if (activeSiteRoleIdx !== -1) {
      activeSiteRole = siteRoles[activeSiteRoleIdx];
      siteRoles.splice(activeSiteRoleIdx, 1);
    }
    return {
      activeSiteRole,
      siteRoles: siteRoles.sort((a, b) => a.label.localeCompare(b.label)),
    };
  }, [
    sangixUser,
    hasAnyOfRoles,
    activeSite,
    activeTrust,
    allSites,
    userData,
    rolesOptions,
    t,
  ]);

  return (
    <>
      {siteRoles.length > 0 && (
        <>
          <CardDividerHeaderSection />
          <div style={{ position: 'relative' }}>
            {activeSiteRole && (
              <>
                <CardSubtitle
                  style={{
                    marginTop: 'var(--s3)',
                  }}
                >
                  {t('active-site-role')}
                </CardSubtitle>
                <CardNote>{t('active-site-role-note')}</CardNote>
                <div key={`active_role_${rerenderSiteRolesKey}`}>
                  <StaffSiteRoleRow
                    key={`site_${activeSiteRole.site_id}`}
                    userData={userData}
                    siteRole={activeSiteRole}
                    mutateAddOrUpdateUserRole={mutateAddOrUpdateUserRole}
                    mutateRemoveUserRole={mutateRemoveUserRole}
                    setThirdPartyRoleUpdate={setThirdPartyRoleUpdate}
                    disabled={disabled}
                  />
                </div>
              </>
            )}
            <CardSubtitle
              style={{
                marginTop: 'var(--s3)',
              }}
            >
              {t('other-site-roles')}
            </CardSubtitle>
            <div key={`other_roles_${rerenderSiteRolesKey}`}>
              {siteRoles.map((role) => {
                return (
                  <StaffSiteRoleRow
                    key={`site_${role.site_id}`}
                    userData={userData}
                    siteRole={role}
                    mutateAddOrUpdateUserRole={mutateAddOrUpdateUserRole}
                    mutateRemoveUserRole={mutateRemoveUserRole}
                    setThirdPartyRoleUpdate={setThirdPartyRoleUpdate}
                    disabled={disabled}
                  />
                );
              })}
            </div>
            {(isAddOrUpdateUserRoleLoading || isRemoveUserRoleLoading) && (
              <CardLoader fillWrapper={true} />
            )}
          </div>
        </>
      )}
      {thirdPartyRoleUpdate && (
        <StaffThirdPartyLimit
          thirdPartyLimit={thirdPartyRoleUpdate.thirdPartyLimit}
          submitHandler={
            disabled
              ? undefined
              : (thirdPartyLimit) => {
                  mutateAddOrUpdateUserRole({
                    ...thirdPartyRoleUpdate.roleUpdate,
                    third_party_limit:
                      thirdPartyLimitToApiValue(thirdPartyLimit),
                  });
                  setThirdPartyRoleUpdate(undefined);
                }
          }
          cancelHandler={() => {
            if (thirdPartyRoleUpdate.rerenderSiteRolesOnCancel) {
              // Rerender site roles to show untouched roles options
              setRerenderSiteRolesKey((prev) => prev + 1);
            }
            setThirdPartyRoleUpdate(undefined);
          }}
        />
      )}
    </>
  );
};
