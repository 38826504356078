import { useSite } from '../../context/SiteContext';
import { ISiteContext, SiteData } from '../../types/sites';

export const usePatientSites = (): Pick<
  ISiteContext,
  'activeSite' | 'setActiveSite'
> & {
  filteredSites: SiteData[] | undefined;
} => {
  const { activeSite, setActiveSite, allSites } = useSite();
  if (activeSite?.disable_patient_registration_and_switch) {
    return {
      activeSite,
      setActiveSite,
      filteredSites: [activeSite],
    };
  } else {
    return {
      activeSite,
      setActiveSite,
      filteredSites: allSites?.filter(
        (site) => !site.disable_patient_registration_and_switch,
      ),
    };
  }
};
