import { Appointment } from '../../../../types/appointments';
import { useValidatePatientAgeWarningDialog } from './useValidatePatientAgeWarningDialog';
import { useCallback } from 'react';
import {
  BookPatientApptsProps,
  useStaffAppointmentBooking,
} from './appointmentFormHooks';
import { useReferrerOptions } from '../../../../utils/options';
import { useAllGPSurgeries } from '../../../../hooks/referrals/useAllGPSurgeries';

export const useStaffAppointmentBookWithPatient = ({
  closeForm,
  selectedAppointmentSlot,
}: {
  closeForm: (keepPatient: boolean) => void;
  selectedAppointmentSlot: Appointment[];
}) => {
  const {
    bookPatientAppointment,
    isLoading: isApptBookOrLockLoading,
    selectedAppointment,
  } = useStaffAppointmentBooking({
    closeForm,
    selectedAppointmentSlot,
  });

  const { showSitePatientAgeWarningIfNeeded } =
    useValidatePatientAgeWarningDialog();

  const bookPatientWithAgeValidation = useCallback(
    ({ user, ...rest }: BookPatientApptsProps) => {
      showSitePatientAgeWarningIfNeeded({
        onSuccess: () =>
          bookPatientAppointment({
            user,
            ...rest,
          }),
        user: user,
      });
    },
    [showSitePatientAgeWarningIfNeeded, bookPatientAppointment],
  );

  const { isLoading: isSurgeriesLoading, data: surgeriesResponse } =
    useAllGPSurgeries();

  const { isLoading: isReferrersLoading, referrerOptions } =
    useReferrerOptions();

  return {
    bookPatientWithAgeValidation,
    isLoading:
      isApptBookOrLockLoading || isSurgeriesLoading || isReferrersLoading,
    selectedAppointment,
    surgeriesResponse,
    referrerOptions,
  };
};
