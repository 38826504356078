import { useEffect, useState } from 'react';

import { useSite } from '../../context/SiteContext';
import { useCubicles } from '../../query/cubicles';
import { useSiteResourceTypes } from '../../query/resourceTypes';
import { ResourceType } from '../../types/resource-types';
import { UNSAFE_FILTER_WITH_NO_PAGINATION } from '../usePaginatedFilters';

export type FilteredResourceTypes = {
  filteredResourceTypes?: ResourceType[];
};

export const useResourceTypesWithCubiclesAssigned = () => {
  const [
    resourceTypesWithCubicleAssigned,
    setResourceTypesWithCubicleAssigned,
  ] = useState<ResourceType[]>();

  const { isFetching: isCubiclesFetching, data: cubiclesData } = useCubicles();

  const siteCubicles = cubiclesData?.data.data;

  const { activeSite } = useSite();

  const { isLoading: isResourceTypeFetching, data: resourceTypesData } =
    useSiteResourceTypes(
      { ...UNSAFE_FILTER_WITH_NO_PAGINATION },
      { enabled: !isCubiclesFetching },
    );
  useEffect(() => {
    const siteResourceTypes = resourceTypesData?.data?.filter(
      (rt) => rt.site_id === activeSite.id,
    );
    const theFilteredResourceTypes = siteResourceTypes
      ?.filter((rt) =>
        siteCubicles?.some((cub) =>
          cub.resource_types?.some((cubicleRT) => cubicleRT.id === rt.id),
        ),
      )
      .reverse();
    setResourceTypesWithCubicleAssigned(theFilteredResourceTypes);
  }, [resourceTypesData?.data, activeSite.id, siteCubicles]);

  return {
    resourceTypesWithCubicleAssigned,
    isLoading: isCubiclesFetching || isResourceTypeFetching,
    allResourceTypes: resourceTypesData?.data,
    cubicles: cubiclesData?.data,
  };
};
