import { Card, CardHeader } from 'components/common';
import { PopupIconWrapper } from 'components/Popup/ConfirmDialog/ConfirmDialog.styled';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { FaEnvelope } from 'react-icons/fa';
import { SupportForm } from './SupportForm';
import {
  SupportFormDescription,
  SupportFormTitle,
} from './SupportFormPage.styled';

export const SupportCard = () => {
  const { t } = useCommonTranslation();

  return (
    <Card>
      <CardHeader>
        <PopupIconWrapper inlineIcon>
          <FaEnvelope />
        </PopupIconWrapper>
        <SupportFormTitle>{t('ask-us')}</SupportFormTitle>
        <SupportFormDescription>{t('send-us-message')}</SupportFormDescription>
      </CardHeader>
      <SupportForm />
    </Card>
  );
};
