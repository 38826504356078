import CheckboxInput from 'components/common/Forms/CheckboxInput';
import {
  DialogFormRow,
  DialogFormWrapper,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import TextArea from 'components/common/Forms/TextArea';
import { CardLoader } from 'components/common/Loading';
import { ATTRIBUTE_SEPARATOR } from 'configs/constants';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FaQuestion, FaUserCheck } from 'react-icons/fa';

import { Card } from '../../../../components/common';
import Input from '../../../../components/common/Forms/Input';
import {
  KIOSK_IP_RANGE,
  PRE_CHECK_IN_QUESTION,
  PRE_CHECK_IN_QUESTION_NO,
  SITE_ATTRIBUTE_CALLING_SCREEN_CUBICLE_MESSAGE,
} from '../../../../configs/siteAndTrustAttributes';
import { SitePreferencesType } from '../SitePreferences';
import { UseCheckInPreferencesFormReturn } from './CheckInPreferencesHooks';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';

interface Props {
  isFetching: boolean;
  type: SitePreferencesType;
  attributesForm: UseCheckInPreferencesFormReturn;
}

export const CheckInRightSide: React.FC<Props> = ({
  isFetching,
  type,
  attributesForm,
}) => {
  const { t } = useCommonTranslation();

  const {
    formState: { errors },
    attributeRegister,
    control,
    register,
  } = attributesForm;
  const watchedValues = useWatch({ control });

  return (
    <>
      <Card mb="var(--s4)">
        <DialogFormWrapper>
          <FormHeader>
            <FaUserCheck />
            <FormTitle>{t('kiosk-and-calling-screen')}</FormTitle>
          </FormHeader>
          <FormRow>
            <Input
              label={t('kiosk-and-calling-screen-ip-range')}
              placeholder={t('kiosk-and-calling-screen-ip-range')}
              {...attributeRegister(KIOSK_IP_RANGE, (e) => e.target.value)}
              value={
                watchedValues.attributes?.find(
                  (atr) => atr.key === KIOSK_IP_RANGE,
                )?.value_str
              }
              hasError={Boolean(
                errors[(ATTRIBUTE_SEPARATOR + KIOSK_IP_RANGE) as 'attributes'],
              )}
              helperText={
                errors[(ATTRIBUTE_SEPARATOR + KIOSK_IP_RANGE) as 'id']?.message
              }
            />
          </FormRow>
          {isFetching && <CardLoader fillWrapper={true} />}
        </DialogFormWrapper>
      </Card>

      <Card mb="var(--s4)">
        <DialogFormWrapper>
          <FormHeader>
            <FaUserCheck />
            <FormTitle>{t('call-in-screen')}</FormTitle>
          </FormHeader>
          <FormRow>
            <Input
              label={t('call-in-screen-cubicle-message')}
              placeholder={t('call-in-screen-cubicle-message')}
              {...attributeRegister(
                SITE_ATTRIBUTE_CALLING_SCREEN_CUBICLE_MESSAGE,
                (e) => e.target.value,
              )}
              value={
                watchedValues.attributes?.find(
                  (atr) =>
                    atr.key === SITE_ATTRIBUTE_CALLING_SCREEN_CUBICLE_MESSAGE,
                )?.value_str
              }
              hasError={Boolean(
                errors[
                  (ATTRIBUTE_SEPARATOR +
                    SITE_ATTRIBUTE_CALLING_SCREEN_CUBICLE_MESSAGE) as 'attributes'
                ],
              )}
              helperText={
                errors[
                  (ATTRIBUTE_SEPARATOR +
                    SITE_ATTRIBUTE_CALLING_SCREEN_CUBICLE_MESSAGE) as 'id'
                ]?.message
              }
            />
          </FormRow>
          {isFetching && <CardLoader fillWrapper={true} />}
        </DialogFormWrapper>
      </Card>

      <Card mb="var(--s4)">
        <DialogFormWrapper>
          <FormHeader>
            <FaQuestion />
            <FormTitle>{t('check-in-question')}</FormTitle>
          </FormHeader>
          <FormRow>
            <CheckboxInput
              onlyInRow
              label={t('enable-pre-Check-question')}
              key={'serverSmsOptInHidden'}
              id={'serverSmsOptInHidden'}
              isToggle={true}
              {...register('enablePreCheckInQuestion')}
              checked={watchedValues.enablePreCheckInQuestion}
            />
          </FormRow>
          <DialogFormRow>
            <TextArea
              label={t('question')}
              placeholder={t('question')}
              {...attributeRegister(
                PRE_CHECK_IN_QUESTION,
                (e) => e.target.value,
                {
                  required: {
                    value: !!watchedValues.enablePreCheckInQuestion,
                    message: t('must-not-empty') as string,
                  },
                  maxLength: 200,
                },
              )}
              value={
                watchedValues.enablePreCheckInQuestion
                  ? watchedValues.attributes?.find(
                      (atr) => atr.key === PRE_CHECK_IN_QUESTION,
                    )?.value_str
                  : ''
              }
              disabled={!watchedValues.enablePreCheckInQuestion}
              hasError={Boolean(
                errors[
                  (ATTRIBUTE_SEPARATOR + PRE_CHECK_IN_QUESTION) as 'attributes'
                ],
              )}
              helperText={t('max-chars', { count: 200 })}
            />
          </DialogFormRow>
          <DialogFormRow>
            <TextArea
              label={t('answer-if-no')}
              placeholder={t('answer-if-no')}
              {...attributeRegister(
                PRE_CHECK_IN_QUESTION_NO,
                (e) => e.target.value,
                {
                  required: {
                    value: !!watchedValues.enablePreCheckInQuestion,
                    message: t('must-not-empty') as string,
                  },
                  maxLength: 200,
                },
              )}
              value={
                watchedValues.enablePreCheckInQuestion
                  ? watchedValues.attributes?.find(
                      (atr) => atr.key === PRE_CHECK_IN_QUESTION_NO,
                    )?.value_str
                  : ''
              }
              disabled={!watchedValues.enablePreCheckInQuestion}
              hasError={Boolean(
                errors[
                  (ATTRIBUTE_SEPARATOR +
                    PRE_CHECK_IN_QUESTION_NO) as 'attributes'
                ],
              )}
              helperText={t('max-chars', { count: 200 })}
            />
          </DialogFormRow>
          {isFetching && <CardLoader fillWrapper={true} />}
        </DialogFormWrapper>
      </Card>
    </>
  );
};
