import React from 'react';
import { Tab, TabsWrapper } from './CardTabs.styled';

export interface IStateTab {
  label: string;
  active: boolean;
  onClick: () => unknown;
  hidden?: boolean;
}

interface IStateTabsProps {
  tabs: IStateTab[];
  withDivider?: boolean;
  fluid?: boolean;
  noMargin?: boolean;
}

export const StateTabs: React.FunctionComponent<IStateTabsProps> = ({
  tabs,
  withDivider,
  noMargin,
  fluid,
}) => {
  return (
    <TabsWrapper withDivider={withDivider} fluid={fluid} noMargin={noMargin}>
      {tabs.map(
        ({ active, hidden, onClick, label }, i) =>
          !hidden && (
            <Tab
              key={`tab_${i}`}
              variant="secondary"
              size="standard"
              $active={active}
              onClick={onClick}
            >
              {label}
            </Tab>
          ),
      )}
    </TabsWrapper>
  );
};
