import React from 'react';
import {
  DATE_FNS_DATE_TIME_TO_MINUTE_ONLY_ISO_FORMAT,
  DateTimeToMinuteOnlyISOString,
  formatDate,
} from 'utils/dateUtil';
import CalendarAppointmentSlot from './CalendarAppointmentSlot';
import CalendarTimeSlotNoAppointementsAvailable from './CalendarTimeSlotNoAppointementsAvailable';
import { Appointment } from '../../../../types/appointments';
import { AppointmentSlotStyle } from './calendar.styled';

interface Props {
  appointmentSlotDates: Date[];
  futureAppointmentsByDate: {
    [key in DateTimeToMinuteOnlyISOString]: Appointment[];
  };
  selectedAppointmentSlot?: DateTimeToMinuteOnlyISOString;
  setSelectedAppointmentsSlot: (props: DateTimeToMinuteOnlyISOString) => void;
  startingMinutes: number[];
}

type SlotProps = Omit<Props, 'startingMinutes'> & { startingMinute: number };

const CalendarHourlySlot = ({
  appointmentSlotDates,
  futureAppointmentsByDate,
  selectedAppointmentSlot,
  setSelectedAppointmentsSlot,
  startingMinute,
}: SlotProps) => {
  const timeslotDate = appointmentSlotDates.find(
    (slotDate) => slotDate.getMinutes() === startingMinute,
  );
  if (!timeslotDate) {
    return <AppointmentSlotStyle slotType={'INVISIBLE'} />;
  }
  const timeslotDateISO = formatDate(
    timeslotDate,
    DATE_FNS_DATE_TIME_TO_MINUTE_ONLY_ISO_FORMAT,
  );
  const appointments = futureAppointmentsByDate[timeslotDateISO];
  const isSelected = selectedAppointmentSlot === timeslotDateISO;
  if (appointments) {
    return (
      <CalendarAppointmentSlot
        isSelected={isSelected}
        key={timeslotDateISO}
        appointments={appointments}
        timeslotDateISO={timeslotDateISO}
        handleClickTimeSlot={setSelectedAppointmentsSlot}
      />
    );
  } else {
    return <CalendarTimeSlotNoAppointementsAvailable key={timeslotDateISO} />;
  }
};

export const CalendarHourlySlots = ({ startingMinutes, ...rest }: Props) => {
  return (
    <>
      {startingMinutes.map((startingMinute, index) => (
        <CalendarHourlySlot
          {...rest}
          startingMinute={startingMinute}
          key={`slot_${index}`}
        />
      ))}
    </>
  );
};
