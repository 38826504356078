import React from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import {
  ApptAction,
  ApptChipTextIndicatorsStyle,
  ApptSlotEnd,
  ApptUser,
  ApptWrapper,
  EditAppt,
} from './AppointmentsTimes.styled';
import { useHandleAppointmentBodyClick } from './SlotAppointmentHooks';
import { SlotAppointmentRightIndicator } from './SlotAppointmentRightIndicator';
import { AppointmentPatientInfoIcons } from '../../../components/views-components/staff/appointments/AppointmentPatientInfoIcons';
import { AppointmentForFlow } from '../../../hooks/staff/useAppointmentsFlow';

export interface AppointmentCardControl {
  setOrToggleEditAppointmentId: (appointmentId?: string) => unknown;
  editAppointmentId?: string;
}

type Props = {
  appointment: AppointmentForFlow;
  reverseIndex: number;
} & AppointmentCardControl;

export const SlotAppointment = ({
  appointment,
  reverseIndex,
  setOrToggleEditAppointmentId,
  editAppointmentId,
}: Props) => {
  const { handleBodyClick } = useHandleAppointmentBodyClick({
    appointment,
    setOrToggleEditAppointmentId,
  });

  return (
    <ApptWrapper
      appointmentStatus={appointment.status}
      appointmentBookingType={appointment.booking_type}
      reverseIndex={reverseIndex}
      selected={appointment.id === editAppointmentId}
    >
      <EditAppt onClick={() => setOrToggleEditAppointmentId(appointment.id)}>
        <FaPencilAlt />
      </EditAppt>
      <ApptAction onClick={handleBodyClick}>
        <ApptUser>{appointment.flowChipLabel}</ApptUser>
        <ApptSlotEnd>
          <ApptChipTextIndicatorsStyle>
            {appointment.resource_type_abbreviation}
          </ApptChipTextIndicatorsStyle>
          <SlotAppointmentRightIndicator appointment={appointment} />
          <AppointmentPatientInfoIcons {...appointment} />
        </ApptSlotEnd>
      </ApptAction>
    </ApptWrapper>
  );
};
