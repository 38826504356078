import React, { useState } from 'react';
import { FaQuestion, FaTimes } from 'react-icons/fa';
import { CommonButton } from '../Forms/Button';
import { TooltipContentStyle, TooltipWrapperStyle } from './Tooltips.styled';

interface Props {
  children: React.ReactNode;
  contentStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  variant?: 'primary' | 'danger';
}

export const Tooltip: React.FC<Props> = ({
  children,
  contentStyle,
  icon,
  variant,
  style,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <TooltipWrapperStyle style={style}>
      <CommonButton
        variant={variant || 'primary'}
        type="button"
        iconOnly
        size="auto"
        onClick={() => setOpen((prev) => !prev)}
        style={{ zIndex: 1 }}
      >
        {open ? <FaTimes size={20} /> : icon ? icon : <FaQuestion />}
      </CommonButton>
      {open && (
        <TooltipContentStyle style={contentStyle} variant={variant}>
          {children}
        </TooltipContentStyle>
      )}
    </TooltipWrapperStyle>
  );
};
