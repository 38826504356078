import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';

import { usePatientRole } from '../../context/RoleContext';
import { useUserDeleteMutation, useUsers } from '../../query/users';
import { errorToast, successToast } from '../../utils/toast';
import { useMySiteOnlyUsersFilters } from './useMySiteOnlyUsersFilters';
import { useSelectUserWithHiddenRefetch } from './useSelectUserWithUpdate';
import { useCommonTranslation } from '../i18n/useCommonTranslation';
import { UserData } from '../../types/users';

export const usePatientsList = ({
  ignoreTrust = false,
  initialMySiteOnly,
}: {
  ignoreTrust?: boolean;
  initialMySiteOnly?: boolean;
}) => {
  const { id: persistRoleId } = usePatientRole();
  const { mySiteOnly, setMySiteOnly, filters, setFilters } =
    useMySiteOnlyUsersFilters({
      initialMySiteOnly,
    });

  const { data, isLoading, isFetching } = useUsers({
    ignoreTrust,
    roleID: persistRoleId,
    ...filters,
  });

  return {
    data,
    isLoading,
    isFetching,
    setFilters,
    filters,
    mySiteOnly,
    setMySiteOnly,
  };
};

export const usePatientsCrud = ({
  ignoreTrust,
  initialMySiteOnly,
}: {
  ignoreTrust: boolean;
  initialMySiteOnly?: boolean;
}) => {
  const { t } = useCommonTranslation();
  const [showForm, setShowForm] = useState(false);
  const [isSubmitting, setIsLoading] = useState(false);
  const [confirmDeleteItem, setConfirmDeleteItem] = useState<UserData | null>();
  const { setSelectedUser: setPrefilledData, selectedUser: prefilledData } =
    useSelectUserWithHiddenRefetch();

  const {
    data: usersData,
    setFilters,
    filters,
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
    mySiteOnly,
    setMySiteOnly,
  } = usePatientsList({ ignoreTrust, initialMySiteOnly });

  const closeFormHandler = useCallback(
    (closeForm?: boolean, err?: AxiosError | true) => {
      setIsLoading(false);
      if (closeForm) {
        setShowForm(false);
        setPrefilledData(undefined);
      }
      setConfirmDeleteItem(null);
      if (err) {
        errorToast(err);
      }
    },
    [setPrefilledData],
  );

  const openFormHandler = useCallback(
    (value?: string) => {
      setShowForm(false);
      setTimeout(() => {
        if (value) {
          const selectedUser = usersData?.data.data?.find(
            (user) => user.id === value,
          );

          if (selectedUser) {
            setPrefilledData(selectedUser);
            setShowForm(true);
          } else {
            closeFormHandler(true);
          }
        } else {
          setPrefilledData(undefined);
          setShowForm(true);
        }
      }, 50);
    },
    [usersData?.data.data, closeFormHandler, setPrefilledData],
  );

  const handleDeleteUser = useCallback(
    (value: string) => {
      closeFormHandler(true);
      setConfirmDeleteItem(
        usersData?.data.data?.find((user) => user.id === value),
      );
    },
    [usersData?.data.data, closeFormHandler],
  );

  const { mutate: deleteUser, isLoading: isDeleteLoading } =
    useUserDeleteMutation({
      onError: (err) => {
        successToast(t('user-deleted'));
        closeFormHandler(true, err);
      },
    });
  const handleConfirmDeleteUser = useCallback(() => {
    confirmDeleteItem?.id && deleteUser(confirmDeleteItem?.id);
    setConfirmDeleteItem(undefined);
  }, [confirmDeleteItem, deleteUser]);

  const isLoading = isUsersLoading || isSubmitting || isDeleteLoading;
  return {
    isLoading,
    isUsersFetching,
    usersData,
    setFilters,
    filters,
    handleConfirmDeleteUser,
    handleDeleteUser,
    openFormHandler,
    closeFormHandler,
    showForm,
    prefilledData,
    confirmDeleteItem,
    mySiteOnly,
    setMySiteOnly,
  };
};
