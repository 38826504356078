import { AxiosError } from 'axios';
import { Card } from 'components/common';
import { CommonButton as Button } from 'components/common/Forms/Button';
import {
  CloseButton,
  DialogFormWrapper,
  FormActionsStyle,
  FormBody,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import { CommonInput as Input } from 'components/common/Forms/Input';
import CommonSelect from 'components/common/Forms/Select';
import TextArea from 'components/common/Forms/TextArea';
import { CardLoader } from 'components/common/Loading';
import React, { ReactElement, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaCube, FaTimes } from 'react-icons/fa';
import { CubicleData, CubicleFormValues } from 'types/cubicles';
import { ResourceType } from 'types/resource-types';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';

interface Props {
  closeFormHandler: (err?: true | AxiosError<any>) => void;
  submitHandler: (data: CubicleFormValues, isUpdateCubicle: boolean) => void;
  isLoading: boolean;
  prefilledData?: CubicleData;
  resourceTypeData?: ResourceType[];
  handleDeleteCubicle: (value: string) => void;
}

export const CubiclesForm = ({
  closeFormHandler,
  submitHandler,
  isLoading,
  prefilledData,
  resourceTypeData,
  handleDeleteCubicle,
}: Props): ReactElement => {
  const isEdit = Boolean(prefilledData);
  const { t } = useCommonTranslation();
  const { resource_types, ...rest } = (prefilledData as CubicleData) || {};
  const {
    register,
    control,
    handleSubmit,
    setFocus,
    setValue,
    formState: { errors },
  } = useForm<CubicleFormValues>({
    defaultValues: prefilledData
      ? {
          ...rest,
        }
      : {},
  });

  useEffect(() => {
    if (prefilledData && resourceTypeData && Boolean(resource_types?.length)) {
      const selectedResourceType = resourceTypeData.filter((rt) =>
        resource_types?.some((resource) => resource.id === rt.id),
      );
      if (selectedResourceType) {
        setValue(
          'cubicle_resource_type',
          selectedResourceType.map((rt) => rt.id),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefilledData]);

  useEffect(() => {
    // This for setting focus after form mounted for better user experience
    setFocus('name');
  }, [setFocus]);

  return (
    <Card>
      <DialogFormWrapper>
        <CloseButton
          size="auto"
          variant="primary"
          iconOnly
          onClick={() => closeFormHandler()}
        >
          <FaTimes />
        </CloseButton>
        <FormHeader>
          <FaCube />
          <FormTitle>{t('cubicle')}</FormTitle>
        </FormHeader>
        <FormBody
          onSubmit={handleSubmit((data) => submitHandler(data, isEdit))}
        >
          <FormRow>
            <Input
              label={t('name')}
              placeholder={t('name')}
              helperText={errors.name?.message}
              hasError={Boolean(errors.name)}
              id={prefilledData ? prefilledData.id + '_name' : 'new_name'}
              {...register('name', {
                required: t('must-not-empty') as string,
              })}
              required
            />
          </FormRow>
          <FormRow>
            <TextArea
              label={t('description')}
              placeholder={t('description')}
              helperText={errors.description?.message}
              hasError={Boolean(errors.description)}
              id={
                prefilledData
                  ? prefilledData.id + '_description'
                  : 'new_description'
              }
              {...register('description')}
            />
          </FormRow>
          <FormRow>
            <Controller
              name="cubicle_resource_type"
              render={({ field }) => (
                <CommonSelect
                  {...field}
                  label={t('calendar-template')}
                  instanceId="calendar_template"
                  placeholder={t('calendar-template')}
                  isClearable
                  multiOption
                  hideSelectedOptions={false}
                  helperText={(errors.cubicle_resource_type as any)?.message}
                  hasError={Boolean(errors.cubicle_resource_type)}
                  disabled={!resourceTypeData}
                  options={
                    resourceTypeData
                      ? resourceTypeData
                          .map((rt) => ({
                            label: rt.name,
                            value: rt.id,
                          }))
                          .reverse()
                      : [{ label: t('loading'), value: '2' }]
                  }
                />
              )}
              control={control}
            />
          </FormRow>
          <FormActionsStyle align="center">
            <Button variant="primary" type="submit">
              {isEdit ? t('save') : t('add')}
            </Button>
            {prefilledData && (
              <Button
                variant="danger"
                type="button"
                onClick={() => handleDeleteCubicle(prefilledData.id)}
              >
                {t('delete')}
              </Button>
            )}
            <Button
              type="button"
              variant="secondary"
              onClick={() => closeFormHandler()}
            >
              {t('cancel')}
            </Button>
          </FormActionsStyle>
        </FormBody>
        {isLoading && <CardLoader fillWrapper={true} />}
      </DialogFormWrapper>
    </Card>
  );
};
