/* eslint-disable react-hooks/exhaustive-deps */
import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { Card } from 'components/common';
import { Col, Grid } from 'components/common/Grid';
import { CardLoader } from 'components/common/Loading';
import { PageHelmet } from 'components/common/PageHelmet';
import { ProtectedRoute } from 'components/common/ProtectedRoute';
import { StaffSettingsEmailsTree } from 'configs/RoutesConfig';
import { useAuth } from 'context/AuthContext';
import { useSite } from 'context/SiteContext';
import { useSiteAttribute } from 'hooks/useAttribute';
import { Fragment, lazy, Suspense } from 'react';
import { useQuery } from 'react-query';
import { Redirect, Switch } from 'react-router';
import { getTemplate } from 'services/template';

import { SHOW_WAITING_LIST } from '../../../../configs/siteAndTrustAttributes';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import {
  formatDateOnlySite,
  getCurrentFormattedTime,
} from '../../../../utils/dateUtil';
import { StaffLayout } from '../../StaffLayout';

const EmailTemplate = lazy(
  () =>
    import('components/views-components/staff/settings/emails/EmailTemplate'),
);

export default function StaffSettingsEmails() {
  const { t } = useCommonTranslation();
  const { sangixUser } = useAuth();
  const { activeSite } = useSite();
  const { data: emailTemplateData } = useQuery(
    `email-template-FOOTER`,
    () => getTemplate('FOOTER'),
    {
      retry: false,
    },
  );
  const hideWaitingList = useSiteAttribute(SHOW_WAITING_LIST)?.value_int === 0;

  return (
    <StaffLayout>
      <PageHelmet title={t('bank-holidays')} />
      <Grid>
        <Col>
          <Card>
            <LinkCardTabs
              tabs={StaffSettingsEmailsTree(t, hideWaitingList)}
              withDivider={true}
            />
            <Suspense fallback={<CardLoader />}>
              <Switch>
                <Fragment>
                  <ProtectedRoute
                    exact
                    path="/staff/settings/emails/footer"
                    render={() => (
                      <EmailTemplate
                        emailKey="FOOTER"
                        title={t('email-footer')}
                      />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/staff/settings/emails/confirmation"
                    render={() => (
                      <EmailTemplate
                        footerTemplate={emailTemplateData?.data}
                        emailKey="CONFIRMATION"
                        title={t('email-confirmation')}
                        staticText={`
                            <p>Dear ${sangixUser?.firstname} ${
                          sangixUser?.lastname
                        },</p>
                            <p>This is a confirmation of your booked appointment at ${
                              activeSite.name
                            }:</p>
                            <p>
                              <strong>Date: <b>${formatDateOnlySite(
                                new Date(),
                              )}</b></strong><br/>
                              <strong>Time: <b>${getCurrentFormattedTime()}</b></strong><br/>
                            </p>
                            <p>An email <strong>reminder will be sent 1 day</strong> before your appointment.</p>
                          `}
                      />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/staff/settings/emails/cancel-appointment"
                    render={() => (
                      <EmailTemplate
                        footerTemplate={emailTemplateData?.data}
                        emailKey="CANCEL"
                        title={t('email-cancel-appt')}
                        staticText={`
                          <p>Dear ${sangixUser?.firstname} ${
                          sangixUser?.lastname
                        },</p>
                            <p>This is to confirm that your booked appointment has been CANCELLED by the hospital: ${
                              sangixUser?.firstname + ' ' + sangixUser?.lastname
                            }:
                            </p>
                            <p>
                              <strong>Date: <b>${formatDateOnlySite(
                                new Date(),
                              )}</b></strong><br/>
                              <strong>Time: <b>${getCurrentFormattedTime()}</b></strong><br/>
                            </p>
                          `}
                      />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/staff/settings/emails/post-appointment"
                    render={() => (
                      <EmailTemplate
                        footerTemplate={emailTemplateData?.data}
                        emailKey="APPT"
                        title={t('email-post-appt')}
                        staticText={`
                          <p>Dear ${sangixUser?.firstname} ${
                          sangixUser?.lastname
                        },</p>
                            <p>This is a follow-up email to your appointment at ${
                              activeSite.name
                            }:
                            </p>
                            <p>
                              <strong>Date: <b>${formatDateOnlySite(
                                new Date(),
                              )}</b></strong><br/>
                              <strong>Time: <b>${getCurrentFormattedTime()}</b></strong><br/>
                            </p>
                          `}
                      />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/staff/settings/emails/reminder"
                    render={() => (
                      <EmailTemplate
                        footerTemplate={emailTemplateData?.data}
                        emailKey="REMINDER"
                        title={t('email-reminder')}
                        staticText={`
                          <p>Dear ${sangixUser?.firstname} ${
                          sangixUser?.lastname
                        },</p>
                            <p>This is a reminder of an appointment you booked for tomorrow.</p>
                            <p>
                              <strong>Date: <b>${formatDateOnlySite(
                                new Date(),
                              )}</b></strong><br/>
                              <strong>Time: <b>${getCurrentFormattedTime()}</b></strong><br/>
                            </p>
                          `}
                      />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/staff/settings/emails/create-account"
                    render={() => (
                      <EmailTemplate
                        footerTemplate={emailTemplateData?.data}
                        emailKey="CREATE"
                        title={t('email-create-acc')}
                        staticText={`
                          <p>Dear ${sangixUser?.firstname} ${sangixUser?.lastname},</p>
                            <p>Your account is now active to book appointments at ${activeSite.name}.</p>
                            <p>To book, view or cancel an appointment, please log in by clicking the link below:</p>
                            <p><a href='#'>Link</a></p>
                          `}
                      />
                    )}
                  />
                  {!hideWaitingList ? (
                    <ProtectedRoute
                      exact
                      path="/staff/settings/emails/waiting-list"
                      render={() => (
                        <EmailTemplate
                          footerTemplate={emailTemplateData?.data}
                          emailKey="WAITING_LIST"
                          staticText={`
                              <p>Dear ${sangixUser?.firstname} ${
                            sangixUser?.lastname
                          },</p>
                              <p>The waiting list you have set up has worked.</p>
                              <p>This is a confirmation of your new appointment you made online with Sangix.</p>
                              <p>
                              <strong>Date: <b>${formatDateOnlySite(
                                new Date(),
                              )}</b></strong><br/>
                              <strong>Time: <b>${getCurrentFormattedTime()}</b></strong><br/>
                            </p>
                              <p>An email <strong>reminder will be sent 1 day</strong> before your appointment.</p>
                            `}
                          title={t('email-waiting-list')}
                        />
                      )}
                    />
                  ) : (
                    <Redirect to="/staff/settings/emails/footer" />
                  )}
                  <Redirect to="/staff/settings/emails/footer" />
                </Fragment>
              </Switch>
            </Suspense>
          </Card>
        </Col>
      </Grid>
    </StaffLayout>
  );
}
