import { FormRow } from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import { ReactElement } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { getHookFormTyped } from 'utils/helpers';
import { SelectOption } from '../../../../../types/common';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { UserFormValues } from '../../../../../types/users';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { useUserValidationRules } from '../../../../../hooks/patient/useUserValidationRules';
import { TrustData } from '../../../../../types/trusts';
import DatePickerInput from '../../../../common/Forms/DatePickerInput';
import {
  AutoCompleteLabel,
  GreenBoxWrapper,
} from 'components/views-components/un-auth/SignupForm/SignupForm.styled';
import { BitwiseCheckboxArray } from '../../../../common/Forms/BitwiseCheckboxArray';
import { disabilityOptions } from '../../../../../configs/constants';
import TextArea from '../../../../common/Forms/TextArea';
import CommonSelect from 'components/common/Forms/Select';
import { LABEL_BEFORE } from '../../../../../styles/fontsStyleUtils';
import {
  gpSurgeryRequiredTrusts,
  nhsNumberRequiredTrusts,
  postCodeRequiredTrusts,
  shouldRenderNhsNumberTrusts,
} from '../../../../../utils/trustUtil';
import { InputPhoneNumber } from '../../../../common/Forms/InputPhoneNumber';

type SubFormType = { user: UserFormValues };

export interface PatientFormProps<T extends SubFormType> {
  gpOptions: SelectOption[];
  register: UseFormRegister<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  trusts: TrustData[];
}

export const StaffOrPatientFormData = <T extends SubFormType>({
  gpOptions,
  trusts,
  ...rest
}: PatientFormProps<T>): ReactElement => {
  const { register, control, errors } = getHookFormTyped<SubFormType, T>(rest);
  const { t } = useCommonTranslation();
  const {
    birthdateRules,
    nhsNumberRules,
    postcodeRules,
    phoneRules,
    surnameRules,
    firstnameRules,
    gpRules,
    emailRules,
    commentRules,
  } = useUserValidationRules({
    trusts,
  });
  return (
    <>
      <FormRow>
        <Input
          label={t('first-name')}
          placeholder={t('first-name')}
          helperText={errors?.user?.firstname?.message}
          hasError={Boolean(errors?.user?.firstname)}
          required
          {...register('user.firstname', firstnameRules)}
        />
        <Input
          label={t('surname')}
          placeholder={t('surname')}
          helperText={errors.user?.lastname?.message}
          hasError={Boolean(errors.user?.lastname)}
          required
          {...register('user.lastname', surnameRules)}
        />
      </FormRow>
      <FormRow>
        <Input
          label={t('email')}
          placeholder={t('email')}
          type="email"
          helperText={errors.user?.email?.message}
          hasError={Boolean(errors.user?.email)}
          {...register('user.email', emailRules)}
        />
      </FormRow>
      <FormRow>
        <Controller
          name="user.phone"
          control={control}
          rules={phoneRules}
          render={({ field }) => (
            <InputPhoneNumber
              label={t('phone-number')}
              placeholder={t('phone-number')}
              required={!!phoneRules.required}
              helperText={errors.user?.phone?.message}
              hasError={Boolean(errors?.user?.phone)}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="user.birthdate"
          rules={birthdateRules}
          render={({ field }) => {
            return (
              <DatePickerInput
                name="birthdate"
                label={t('date-of-birth')}
                placeholder={t('date-of-birth')}
                onChange={(value) => field.onChange(value)}
                maxDate={new Date()}
                hasError={Boolean(errors.user?.birthdate)}
                helperText={errors.user?.birthdate?.message}
                selected={field.value}
                required
              />
            );
          }}
        />
      </FormRow>
      <FormRow>
        {postCodeRequiredTrusts(trusts) && (
          <Input
            label={t('postcode')}
            placeholder={t('postcode')}
            {...register('user.postcode', postcodeRules)}
            helperText={errors.user?.postcode?.message}
            hasError={Boolean(errors?.user?.postcode)}
            required
          />
        )}
        {shouldRenderNhsNumberTrusts(trusts) && (
          <Input
            label={t('nhs-number')}
            placeholder={t('nhs-number')}
            {...register('user.nhs_number', nhsNumberRules)}
            helperText={errors.user?.nhs_number?.message}
            hasError={
              nhsNumberRequiredTrusts(trusts) &&
              Boolean(errors?.user?.nhs_number)
            }
            required={nhsNumberRequiredTrusts(trusts)}
          />
        )}
      </FormRow>
      {gpSurgeryRequiredTrusts(trusts) && (
        <FormRow>
          <Controller
            name="user.gp_surgery_id"
            render={({ field }) => (
              <CommonSelect
                {...field}
                labelPosition={LABEL_BEFORE}
                label={t('general-practice')}
                placeholder={t('general-practice')}
                disabled={!gpOptions}
                options={gpOptions}
                helperText={errors.user?.gp_surgery_id?.message}
                hasError={Boolean(errors.user?.gp_surgery_id)}
                onChange={(val) => {
                  field.onChange(val);
                }}
                required
              />
            )}
            control={control}
            rules={gpRules}
          />
        </FormRow>
      )}
      <FormRow>
        <GreenBoxWrapper>
          <AutoCompleteLabel as="span">{t('disabilities')}:</AutoCompleteLabel>
          <Controller
            name={'user.disabilities'}
            control={control}
            render={({ field }) => (
              <BitwiseCheckboxArray
                field={field}
                options={disabilityOptions(t)}
              />
            )}
          />
        </GreenBoxWrapper>
      </FormRow>
      <FormRow>
        <TextArea
          label={t('comment')}
          placeholder={t('comment')}
          helperText={errors.user?.comment?.message}
          {...register('user.comment', commentRules)}
          hasError={Boolean(errors.user?.comment)}
        />
      </FormRow>
    </>
  );
};
