import styled from 'styled-components';

export const WeekRowStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--s3);
  justify-content: center;
  margin-top: var(--s3);
`;

export const DayColumnStyle = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--s2);
  border-radius: var(--s3);
  padding: var(--s3);
  font-size: var(--body1);
  background-color: ${({ isSelected }) =>
    isSelected ? 'var(--redaccent)' : 'var(--white)'};
  color: ${({ isSelected }) =>
    isSelected ? 'var(--white)' : 'var(--textprimary)'};
`;
