import { CommonButton } from 'components/common/Forms/Button';
import CheckboxInput from 'components/common/Forms/CheckboxInput';
import DatePickerInput, {
  DatePickerOnChange,
} from 'components/common/Forms/DatePickerInput';
import CommonSelect from 'components/common/Forms/Select';
import React, { ReactElement } from 'react';
import { SelectOption } from 'types/common';
import { ResourceType } from 'types/resource-types';
import { ApptHeaderContent } from './AppointmentsHeader.styled';
import { DateOnlyISOString } from '../../../../utils/dateUtil';
import { AppointmentsHeaderTooltip } from './AppointmentsHeaderTooltip';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { FaCalendarAlt } from 'react-icons/fa';
import { CardDividerHeaderSection } from '../../../../components/common';
import { APPOINTMENT_HOVER_INDEX } from '../AppointmentsTimes.styled';

interface Props {
  isResourceTypeFetching: boolean;
  filteredResourceTypes: ResourceType[] | undefined;
  selectedResourceType: ResourceType | undefined;
  setSelectedResourceType: React.Dispatch<
    React.SetStateAction<ResourceType | undefined>
  >;
  selectedDate: DateOnlyISOString;
  setSelectedDate: DatePickerOnChange;
  isUpdate: boolean;
  setIsUpdate: (value: boolean) => void;
  onDnaMiaClicked: () => void;
  onRefreshClicked: () => void;
}

const AppointmentsHeader = ({
  isResourceTypeFetching,
  filteredResourceTypes,
  selectedResourceType,
  setSelectedResourceType,
  selectedDate,
  setSelectedDate,
  isUpdate,
  setIsUpdate,
  onDnaMiaClicked,
  onRefreshClicked,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();
  const resourceTypesOptions: SelectOption<string | undefined>[] = [
    {
      label: t('all'),
      value: undefined,
    },
  ];
  if (filteredResourceTypes) {
    resourceTypesOptions.push(
      ...filteredResourceTypes.map((rt) => ({
        label: rt.name,
        value: rt.id,
      })),
    );
  }

  return (
    <CardDividerHeaderSection>
      <ApptHeaderContent>
        <DatePickerInput
          sizing={'MIN_CONTENT'}
          noSpaceForHelperText
          name="Appt-DatePicker"
          onChange={(val) => setSelectedDate(val)}
          selected={selectedDate}
          icon={<FaCalendarAlt />}
          popperZIndex={APPOINTMENT_HOVER_INDEX + 1}
        />
        <CommonSelect
          style={{
            marginBottom: 0,
          }}
          noSpaceForHelperText
          label={t('calendar-template')}
          placeholder={t('calendar-template')}
          disabled={isResourceTypeFetching}
          options={resourceTypesOptions}
          value={selectedResourceType?.id}
          onChange={(data) => {
            setSelectedResourceType(
              filteredResourceTypes?.find((rt) => rt.id === data),
            );
          }}
        />
        <CheckboxInput
          label={t('update')}
          name="Appt-Updated-Now"
          checked={isUpdate}
          onCheckedStateChange={(value) => setIsUpdate(value)}
        />
        <CommonButton variant="primary" onClick={onDnaMiaClicked}>
          {t('dna-mia')}
        </CommonButton>
        <CommonButton variant="secondary" onClick={onRefreshClicked}>
          {t('refresh')}
        </CommonButton>
      </ApptHeaderContent>
      <AppointmentsHeaderTooltip />
    </CardDividerHeaderSection>
  );
};
export default AppointmentsHeader;
