import { TimePlanningTooltipWrapper } from '../../../common/Tooltips/TimePlanningTooltipWrapper';
import {
  TimePlanningDesc,
  TimePlanningItemColor,
  TimePlanningList,
  TimePlanningListItem,
  TooltipListTitle,
} from '../../../common/Tooltips/Tooltips.styled';
import React from 'react';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';

export const CalendarHeaderLegend = () => {
  const { t } = useCommonTranslation();
  return (
    <TimePlanningTooltipWrapper minWidth={200} positionType={'unset'}>
      <TooltipListTitle>{t('calendar-legend')}</TooltipListTitle>
      <TimePlanningList>
        <TimePlanningListItem>
          <TimePlanningItemColor
            style={{
              background: 'var(--disabled)',
              borderRadius: 'var(--s1)',
            }}
          />
          {t('no-appt-available')}
        </TimePlanningListItem>
        <TimePlanningListItem>
          <TimePlanningItemColor
            style={{
              borderRadius: 'var(--s1)',
              background: 'var(--greensecondary)',
            }}
          />
          {t('appt-available')}
        </TimePlanningListItem>
        <TimePlanningListItem>
          <TimePlanningItemColor
            style={{
              background: 'var(--rednegative)',
              borderRadius: 'var(--s1)',
            }}
          />
          {t('booked-out')}
        </TimePlanningListItem>
        <TimePlanningListItem>
          <TimePlanningItemColor
            style={{
              background: 'var(--textprimary)',
              borderRadius: 'var(--s1)',
            }}
          />
          {t('appt-not-available-online')}
        </TimePlanningListItem>
      </TimePlanningList>
      <TimePlanningDesc>{t('calendar-legend-note')}</TimePlanningDesc>
    </TimePlanningTooltipWrapper>
  );
};
