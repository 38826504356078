import { PageHelmet } from 'components/common/PageHelmet';
import { PatientLayout } from 'containers/PatientLayout';

import { AddHospitalForm } from '../../containers/patient/AddHospital/AddHospitalForm';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';

export const AddHospital = () => {
  const { t } = useCommonTranslation();
  return (
    <PatientLayout graphics="patient">
      <PageHelmet title={t('find-hospital')} />
      <AddHospitalForm />
    </PatientLayout>
  );
};
