import React, { useMemo } from 'react';
import { useParams } from 'react-router';

import { PageHelmet } from '../../components/common/PageHelmet';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { useMutateUpdateSite, useQuerySite } from '../../query/sites';
import { ICardTabs } from '../../types/common';
import { SitePreferences } from '../common/site/SitePreferences';
import { sitePreferencesTabsSubset } from '../common/site/SitePreferencesHooks';
import { AdminLayout } from './AdminLayout';

export const adminSitePreferencesAllowedTabs = sitePreferencesTabsSubset([
  'general',
  'emails',
  'settings-check-in-tab',
  'custom-settings',
  'appointment-flags',
  'links',
]);

type TabKey = typeof adminSitePreferencesAllowedTabs[number];

export const AdminSitePreferences: React.FunctionComponent = () => {
  const { siteId, tab: selectedTabKey } =
    useParams<{ siteId: string; tab: TabKey }>();
  const { t } = useCommonTranslation();
  const { data: siteResponse, isLoading: isSiteLoading } = useQuerySite(siteId);
  const { mutate, isLoading: isUpdateSiteLoading } = useMutateUpdateSite();
  const tabsConfig: ICardTabs[] = useMemo(
    () =>
      adminSitePreferencesAllowedTabs.map((key) => ({
        label: t(key),
        to: `/admin/site/preferences/${siteId}/${key}`,
      })),
    [t, siteId],
  );

  const isLoading = isSiteLoading || isUpdateSiteLoading;
  return (
    <AdminLayout>
      <PageHelmet title={t('preferences')} />
      <SitePreferences
        selectedTabKey={selectedTabKey}
        tabsConfig={tabsConfig}
        site={siteResponse?.data}
        submitHandler={(siteData) => mutate(siteData)}
        isFetching={isLoading}
        type={'admin'}
      />
    </AdminLayout>
  );
};
