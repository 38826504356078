import {
  useBookingUserMemberId,
  usePatientBooking,
} from 'context/patient/modules/booking';
import { useMutateBookAppointment } from 'query/appointments';
import { useCallback } from 'react';
import { Appointment } from 'types/appointments';

export const useBookPatientAppointmentBase = () => {
  const { mutateAsync: mutateBook } = useMutateBookAppointment();
  const memberId = useBookingUserMemberId();
  const { updateBooking } = usePatientBooking();
  return useCallback(
    async (appt: Appointment) => {
      await mutateBook({
        id: appt.id,
        client_id: memberId,
      });
      updateBooking({ stage: 'success', bookedAppt: appt });
    },
    [mutateBook, updateBooking, memberId],
  );
};

export const useBookPatientAppointment = (appt: Appointment | undefined) => {
  const bookAppt = useBookPatientAppointmentBase();
  return useCallback(() => appt && bookAppt(appt), [appt, bookAppt]);
};
