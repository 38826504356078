import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { USER_ROLE_IDS_ORDERED } from '../../configs/constants';
import { Backend_Role } from '../../types/common';
import { useCommonTranslation } from '../i18n/useCommonTranslation';

export const getRoleName = (t: TFunction, roleId: string) => {
  return t('role-names.' + roleId);
};

export const useRoleOptions = (roles: Backend_Role[] | null | undefined) => {
  const { t } = useCommonTranslation();
  return useMemo(() => {
    return [
      ...(
        roles?.map((role) => ({
          label: getRoleName(t, role.id) || role.name,
          value: role.id,
        })) || []
      ).sort((a, b) => {
        return (
          USER_ROLE_IDS_ORDERED.indexOf(b.value) -
          USER_ROLE_IDS_ORDERED.indexOf(a.value)
        );
      }),
    ];
  }, [roles, t]);
};
