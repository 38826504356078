import { useSiteBooleanAttributes } from 'hooks/useAttribute';
import { ReactElement } from 'react';
import {
  FaCalendarAlt,
  FaUserCheck,
  FaUserClock,
  FaUserCog,
} from 'react-icons/fa';
import { patientURL } from 'routes/AppRoutes';
import { DashboardLink, DashboardWrapperReversed } from './Dashboard.styled';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { DISABLE_SELF_CHECK_IN } from '../../../../configs/siteAndTrustAttributes';

export const PatientDashboard = (): ReactElement => {
  const { t } = useCommonTranslation();
  const [hideSelfCheckIn] = useSiteBooleanAttributes(DISABLE_SELF_CHECK_IN);

  return (
    <DashboardWrapperReversed data-testid="dashboard">
      {[
        !hideSelfCheckIn && (
          <DashboardLink to={`${patientURL}/check-in`} key={'check-in'}>
            <FaUserCheck className="dashboard-icon" />
            {t('self-check-in')}
          </DashboardLink>
        ),
        <DashboardLink to={`${patientURL}/booking`} key={'booking'}>
          <FaCalendarAlt className="dashboard-icon" />
          {t('book-an-appointment')}
        </DashboardLink>,
        <DashboardLink
          to={`${patientURL}/my-appointments`}
          key={'my-appointments'}
        >
          <FaUserClock className="dashboard-icon" />
          {t('my-appointments')}
        </DashboardLink>,
        <DashboardLink to={`${patientURL}/shared-accounts`} key={'profile'}>
          <FaUserCog className="dashboard-icon" />
          {t('profile-and-family')}
        </DashboardLink>,
      ].reverse()}
    </DashboardWrapperReversed>
  );
};
