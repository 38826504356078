import { usePatientBooking } from 'context/patient/modules/booking';
import { useHourAppts } from 'hooks/patient/booking/usePatientBookingQueries';
import { CardLoader } from '../../../../components/common/Loading';
import { useSiteHourRange } from '../../../../hooks/site/useSiteHourRange';
import { PatientBookingPickApptWithSlot } from './PatientBookingPickApptWithSlot';

export const PatientBookingPickAppt = () => {
  const {
    booking: { date },
  } = usePatientBooking();
  const {
    slots,
    isLoading: isHourRangeLoading,
    appointmentLength,
  } = useSiteHourRange();
  const slot = slots.find((slot) => date.getHours() === slot.hour);
  const { hourAppts, isLoading: isHourApptsLoading } = useHourAppts();

  const isLoading = isHourApptsLoading || isHourRangeLoading || !slot;

  return (
    <>
      {isLoading || isHourRangeLoading ? (
        <CardLoader />
      ) : (
        <PatientBookingPickApptWithSlot
          hourAppts={hourAppts}
          slot={slot}
          date={date}
          appointmentLength={appointmentLength}
        />
      )}
    </>
  );
};
