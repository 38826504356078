import { AxiosError } from 'axios';
import { Card } from 'components/common';
import { CommonButton as Button } from 'components/common/Forms/Button';
import CheckboxInput from 'components/common/Forms/CheckboxInput';
import DatePickerInput from 'components/common/Forms/DatePickerInput';
import {
  CloseButton,
  DialogFormWrapper,
  FormActionsStyle,
  FormBody,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import CommonSelect from 'components/common/Forms/Select';
import { CardLoader } from 'components/common/Loading';
import useAttributesForm from 'hooks/useAttributesForm';
import { ReactElement, useEffect, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { FaHospital, FaTimes } from 'react-icons/fa';
import { GlobalAttribute } from 'types/common';
import { SiteData, SiteFormValues } from 'types/sites';
import { TrustData } from 'types/trusts';
import { UK_POST_CODE_REGEX } from 'utils/helpers';
import { signUpToSiteUri } from '../../../../routes/AppRoutes';
import { gpSurgeryRequiredTrust } from '../../../../utils/trustUtil';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import {
  SITE_ATTRIBUTE_ALLOW_GP_SURGERY_ROLE,
  SITE_ATTRIBUTE_NOTE,
} from '../../../../configs/siteAndTrustAttributes';
import TextArea from '../../../common/Forms/TextArea';
import { ATTRIBUTE_SEPARATOR } from '../../../../configs/constants';

interface Props {
  closeFormHandler: (err?: true | AxiosError) => void;
  trustsData?: TrustData[] | null;
  submitNewSiteHandler: (data: SiteFormValues, isUpdateSite: boolean) => void;
  isLoading: boolean;
  prefilledData?: SiteData;
}

export const SiteForm = ({
  closeFormHandler,
  trustsData,
  submitNewSiteHandler,
  isLoading,
  prefilledData,
}: Props): ReactElement => {
  const isEdit = Boolean(prefilledData);
  const { t } = useCommonTranslation();
  const restAttributes: GlobalAttribute[] = useMemo(
    () => [
      {
        key: SITE_ATTRIBUTE_ALLOW_GP_SURGERY_ROLE,
      },
      {
        key: SITE_ATTRIBUTE_NOTE,
      },
    ],
    [],
  );

  const theForm = useForm<SiteFormValues>({
    defaultValues: {
      disable_patient_registration_and_switch: true,
      ...prefilledData,
    },
  });

  const {
    register,
    control,
    setValue,
    handleSubmit,
    setFocus,
    formState: { errors },
    attributeRegister,
    withAttributes,
  } = useAttributesForm<SiteFormValues>({
    theForm,
    currentAttrs: (prefilledData as SiteData)?.attributes || [],
    restAttributes,
    dep: [prefilledData],
  });

  const watchedValues = useWatch({ control });

  const selectedTrust = prefilledData
    ? trustsData?.find((trust) => trust.id === prefilledData?.trust_id)
    : undefined;

  useEffect(() => {
    if (prefilledData && trustsData) {
      if (selectedTrust) {
        setValue('trust_id', selectedTrust.id);
      }
    }
  }, [prefilledData, trustsData, selectedTrust, setValue]);

  useEffect(() => {
    // This for setting focus after form mounted for better user experience
    setFocus('name');
  }, [setFocus]);

  const isGpSurgeryRequired =
    selectedTrust && gpSurgeryRequiredTrust(selectedTrust);

  return (
    <Card>
      <DialogFormWrapper>
        <CloseButton
          size="auto"
          variant="primary"
          iconOnly
          onClick={() => closeFormHandler()}
        >
          <FaTimes />
        </CloseButton>
        <FormHeader>
          <FaHospital />
          <FormTitle>{t('site')}</FormTitle>
        </FormHeader>
        <FormBody
          onSubmit={handleSubmit(
            withAttributes((data) => submitNewSiteHandler(data, isEdit)),
          )}
        >
          <FormRow>
            <Input
              label={t('site-name')}
              placeholder={t('site-name')}
              helperText={errors.name?.message}
              hasError={Boolean(errors.name)}
              {...register('name', {
                required: t('must-not-empty') as string,
              })}
              required
            />
          </FormRow>
          <FormRow>
            <Input
              label={t('site-search-by-name')}
              placeholder={t('site-search-by-name')}
              helperText={errors.search_by_name?.message}
              hasError={Boolean(errors.search_by_name)}
              {...register('search_by_name', {
                required: t('must-not-empty') as string,
              })}
              required
            />
          </FormRow>

          {isEdit && (
            <FormRow>
              <Input
                label={t('site-id')}
                placeholder={t('site-id')}
                value={prefilledData?.id}
                name={'site-id'}
                disabled
              />
            </FormRow>
          )}
          <FormRow>
            <Input
              label={t('site-short-name')}
              placeholder={t('site-short-name')}
              helperText={errors.short_name?.message}
              hasError={Boolean(errors.short_name)}
              {...register('short_name', {
                required: t('must-not-empty') as string,
              })}
              required
            />
            <Controller
              name="trust_id"
              render={({ field }) => (
                <CommonSelect
                  {...field}
                  label={t('trust')}
                  instanceId="trust_id"
                  placeholder={t('trust')}
                  isClearable
                  helperText={errors.trust_id?.message}
                  hasError={Boolean(errors.trust_id)}
                  disabled={!!prefilledData}
                  options={
                    trustsData
                      ? trustsData.map((trust) => ({
                          label: trust.name,
                          value: trust.id,
                        }))
                      : [{ label: t('loading'), value: '2' }]
                  }
                  required
                />
              )}
              control={control}
              rules={{
                required: t('must-not-empty') as string,
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              label={t('sign_up_short_info')}
              placeholder={t('sign_up_short_info')}
              {...register('sign_up_short_info')}
            />
          </FormRow>
          <FormRow>
            <Input
              label={t('sign_up_order')}
              placeholder={t('sign_up_order')}
              type="number"
              step="any"
              {...register('sign_up_order', {
                min: {
                  value: 0,
                  message: t('must-greater-or-equal-zero'),
                },
              })}
              helperText={errors.sign_up_order?.message}
              hasError={Boolean(errors?.sign_up_order)}
            />
          </FormRow>
          <FormRow>
            <Input
              label={t('street')}
              placeholder={t('street')}
              {...register('street')}
            />
            <Input
              label={t('city')}
              placeholder={t('city')}
              {...register('city')}
            />
          </FormRow>
          <FormRow>
            <Input
              label={t('postcode')}
              placeholder={t('postcode')}
              disabled={isLoading}
              {...register('postcode', {
                pattern: {
                  value: UK_POST_CODE_REGEX,
                  message: t('postcode-validation') as string,
                },
              })}
              helperText={errors.postcode?.message}
              hasError={Boolean(errors?.postcode)}
            />
            <Input
              label={t('country')}
              placeholder={t('country')}
              {...register('country')}
            />
          </FormRow>
          <FormRow>
            <Input
              label={t('latitude')}
              type="number"
              step="any"
              placeholder={t('latitude')}
              {...register('latitude')}
            />
            <Input
              label={t('longitude')}
              type="number"
              step="any"
              placeholder={t('longitude')}
              {...register('longitude')}
            />
          </FormRow>
          <FormRow>
            <Controller
              control={control}
              name="expire_date"
              render={({ field }) => {
                return (
                  <DatePickerInput
                    name="expire_date"
                    label={t('site') + ' ' + t('expiry-date')}
                    placeholder={t('expiry-date')}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    disabled={isLoading}
                    id={
                      prefilledData
                        ? prefilledData.id + '_expire_date'
                        : 'expire_date'
                    }
                    hasError={Boolean(errors.expire_date)}
                    helperText={errors.expire_date?.message}
                    selected={field.value}
                    minDate={new Date()}
                    required
                  />
                );
              }}
              rules={{
                required: t('must-not-empty') as string,
              }}
            />
            <Input
              label={t('number-of-cubicles')}
              placeholder={t('number-of-cubicles')}
              type="number"
              helperText={errors.cubicle_limit?.message}
              hasError={Boolean(errors.cubicle_limit)}
              {...register('cubicle_limit', {
                required: t('must-not-empty') as string,
                min: {
                  value: 1,
                  message: t('must-greater-or-equal-one'),
                },
              })}
              required
            />
          </FormRow>
          <FormRow>
            <TextArea
              label={t('note')}
              placeholder={t('note')}
              {...attributeRegister(
                SITE_ATTRIBUTE_NOTE,
                (e) => e.target.value,
                {
                  maxLength: 255,
                },
              )}
              value={
                watchedValues.attributes?.find(
                  (atr) => atr.key === SITE_ATTRIBUTE_NOTE,
                )?.value_str
              }
              hasError={Boolean(
                errors[
                  (ATTRIBUTE_SEPARATOR + SITE_ATTRIBUTE_NOTE) as 'attributes'
                ],
              )}
            />
          </FormRow>
          <FormRow>
            <CheckboxInput
              label={t('allow-gp-role')}
              id={'allow-gp'}
              onlyInRow={true}
              {...attributeRegister(
                SITE_ATTRIBUTE_ALLOW_GP_SURGERY_ROLE,
                (e) => e.target.checked,
              )}
              checked={Boolean(
                watchedValues.attributes?.find(
                  (atr) => atr.key === SITE_ATTRIBUTE_ALLOW_GP_SURGERY_ROLE,
                )?.value_int,
              )}
              disabled={!isGpSurgeryRequired}
              title={
                !isGpSurgeryRequired
                  ? t('allow-gp-role-disabled-when-hide-gp-surgery')
                  : undefined
              }
            />
          </FormRow>
          <FormRow>
            <CheckboxInput
              label={t('hide-site-in-patient-section')}
              title={t('hide-site-in-patient-section-title', {
                signUpToSiteUri: signUpToSiteUri('SITE_ID'),
              })}
              onlyInRow={true}
              {...register('disable_patient_registration_and_switch')}
            />
          </FormRow>
          <FormActionsStyle align="center">
            <Button variant="primary" type="submit">
              {isEdit ? t('save') : t('add')}
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={() => closeFormHandler()}
            >
              {t('cancel')}
            </Button>
          </FormActionsStyle>
        </FormBody>
        {isLoading && <CardLoader fillWrapper={true} />}
      </DialogFormWrapper>
    </Card>
  );
};
