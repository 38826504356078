import { useCallback, useMemo, useState } from 'react';
import { errorToast } from '../../utils/toast';
import { useCommonTranslation } from '../i18n/useCommonTranslation';

type SelectionState = 'sourceSelection' | 'targetSelection';
export const SOURCE_SELECTION_STATE: SelectionState = 'sourceSelection';
export const TARGET_SELECTION_STATE: SelectionState = 'targetSelection';

export const useMergeEntity = <T extends { id: string }>({
  entities,
}: {
  entities?: T[];
}) => {
  const { t } = useCommonTranslation();
  const [source, setSource] = useState<T>();
  const [target, setTarget] = useState<T>();

  const [selectionState, setSelectionState] = useState<SelectionState>();
  const toggleSelectionState = useCallback(
    (newSelectionState: SelectionState) => {
      if (selectionState === newSelectionState) {
        setSelectionState(undefined);
      } else {
        setSelectionState(newSelectionState);
      }
    },
    [selectionState, setSelectionState],
  );

  const selectMergeItem = useCallback(
    (selectId?: string) => {
      const entity = entities?.find(({ id }) => id === selectId);
      if (selectionState === 'sourceSelection') {
        if (target?.id === selectId) {
          errorToast(t('merge-items-same-error'));
          return;
        }
        setSource(entity);
      } else {
        if (source?.id === selectId) {
          errorToast(t('merge-items-same-error'));
          return;
        }
        setTarget(entity);
      }
      if (selectionState === 'sourceSelection' && !target) {
        setSelectionState('targetSelection');
      } else if (selectionState === 'targetSelection' && !source) {
        setSelectionState('sourceSelection');
      } else {
        setSelectionState(undefined);
      }
    },
    [entities, selectionState, source, target, t],
  );

  const clearSelection = useCallback(() => {
    setSelectionState(undefined);
    setSource(undefined);
    setTarget(undefined);
  }, []);

  const selectedIds = useMemo(
    () => [source?.id, target?.id].filter((id) => id) as string[],
    [source, target],
  );

  return {
    selectionState,
    clearSelection,
    toggleSelectionState,
    selectMergeItem,
    setSource,
    source,
    target,
    selectedIds,
  };
};
