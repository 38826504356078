import { Col, Grid } from '../../components/common/Grid';

interface IMainGrid {
  main: React.ReactNode;
  secondary?: React.ReactNode;
}

export const SangixMainGrid = ({ main, secondary }: IMainGrid) => (
  <Grid>
    <Col sm={8}>{main}</Col>
    <Col
      col={4}
      style={
        !secondary
          ? {
              display: 'none',
            }
          : undefined
      }
    >
      {secondary}
    </Col>
  </Grid>
);
