import {
  RESET_ONLINE_ACTION,
  UPDATE_DAY_ACTION,
  UPDATE_HOUR_ACTION,
  UPDATE_INTERVAL_ACTION,
} from './actions';
import {
  SET_DAY_LIMIT,
  SET_DEFAULT_STATE,
  SET_HOUR_LIMIT,
  SET_INTERVAL_LIMIT,
  TimePlannerActions,
  TimePlannerActionType,
  TimePlannerState,
} from './types';

export const TimePlannerReducer: (
  state: TimePlannerState,
  action: TimePlannerActions,
) => TimePlannerState = (state, { type, payload }) => {
  switch (type) {
    case TimePlannerActionType.SET_DEFAULT_STATE:
      return {
        ...(payload as SET_DEFAULT_STATE['payload']),
      };
    case TimePlannerActionType.SET_ONLINE_ACTION:
      return {
        ...state,
        day: RESET_ONLINE_ACTION({
          state,
        }),
      };
    case TimePlannerActionType.SET_DAY_LIMIT:
      return {
        ...state,
        day: UPDATE_DAY_ACTION({
          state,
          ...(payload as SET_DAY_LIMIT['payload']),
        }),
      };
    case TimePlannerActionType.SET_HOUR_LIMIT:
      return {
        ...state,
        day: UPDATE_HOUR_ACTION({
          state,
          ...(payload as SET_HOUR_LIMIT['payload']),
        }),
      };
    case TimePlannerActionType.SET_INTERVAL_LIMIT:
      return {
        ...state,
        day: UPDATE_INTERVAL_ACTION({
          state,
          ...(payload as SET_INTERVAL_LIMIT['payload']),
        }),
      };
    default:
      return state;
  }
};
