import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { Card } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import { Col, Grid } from 'components/common/Grid';
import { PageHelmet } from 'components/common/PageHelmet';
import { ConfirmDialog } from 'components/Popup/ConfirmDialog/ConfirmDialog';
import { StaffForm } from 'components/views-components/staff/settings/users/StaffForm';
import { StaffTable } from 'components/views-components/staff/settings/users/StaffTable';
import { AdminUsersTabsTree } from 'configs/RoutesConfig';
import { FaTrashAlt } from 'react-icons/fa';
import { errorToast, successToast } from 'utils/toast';
import {
  UNSAFE_FILTER_WITH_NO_PAGINATION,
  usePaginatedFilters,
} from '../../hooks/usePaginatedFilters';
import { useUserDeleteMutation, useUsers } from '../../query/users';
import { UsersFilters } from '../../types/users';
import { AdminLayout } from './AdminLayout';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { useStaffCrud } from '../../hooks/user/useStaffCrud';
import { useStaffRolesAndGPData } from '../staff/settings/users/useStaffRolesAndGPData';
import { useCallback, useState } from 'react';
import { useSite } from '../../context/SiteContext';
import { useQuerySites } from '../../query/sites';

export const AdminStaff = () => {
  const { t } = useCommonTranslation();
  const { activeSite, setActiveSite } = useSite();
  const { rolesData, GPData, roleIdsCommaSeparated } = useStaffRolesAndGPData();
  const { data: sitesData } = useQuerySites({
    ...UNSAFE_FILTER_WITH_NO_PAGINATION,
  });

  const { filters, setFilters } = usePaginatedFilters<UsersFilters>();

  const {
    isLoading: isListLoading,
    isFetching: isListFetching,
    data,
  } = useUsers({
    roleID: roleIdsCommaSeparated,
    ignoreTrust: true,
    ...filters,
  });
  const { isLoading: isDeleteLoading, mutate: deleteItem } =
    useUserDeleteMutation({
      onSuccess: () => {
        successToast(t('user-deleted'));
        closeFormHandler();
      },
    });

  const {
    showForm,
    openFormHandler,
    handleDeleteUser,
    handleConfirmDeleteUser,
    updatedPrefilledData,
    isLoading: isUserActionLoading,
    closeFormHandler,
    confirmDeleteItem,
    staffUserCreateOrUpdate,
  } = useStaffCrud({
    userListData: data?.data,
    deleteItem: deleteItem,
  });

  const [isDetailLoading, setIsDetailLoading] = useState(false);
  const openDetailAndSelectTrustAndSite = useCallback(
    async (value?: string) => {
      setIsDetailLoading(true);
      const selectedUser = data?.data.data?.find((user) => user.id === value);
      if (!selectedUser) {
        errorToast(t('error-user-not-found'));
        return;
      }
      if (selectedUser?.active_site_id) {
        const site = sitesData?.data?.find(
          (site) => site.id === selectedUser.active_site_id,
        );
        if (!site) {
          errorToast(t('error-site-not-found'));
          return;
        }
        /**
         * A lot of BE endpoints in the detail are linked to active trust. If trust is changed here, those endpoints will work.
         */
        if (site.id !== activeSite.id) {
          await setActiveSite(site);
        }
        openFormHandler(value);
      }
      setIsDetailLoading(false);
    },
    [
      activeSite.id,
      data?.data.data,
      openFormHandler,
      setActiveSite,
      sitesData?.data,
      t,
    ],
  );

  const isLoading =
    isUserActionLoading || isListLoading || isDeleteLoading || isDetailLoading;

  return (
    <AdminLayout>
      <PageHelmet title={t('staff')} />
      <Grid>
        <Col md={8}>
          <Card>
            <LinkCardTabs withDivider tabs={AdminUsersTabsTree(t)} />
            <StaffTable
              usersData={data?.data}
              openFormHandler={openDetailAndSelectTrustAndSite}
              handleDeleteUser={handleDeleteUser}
              filters={filters}
              setFilters={setFilters}
              isLoading={isListLoading}
              isFetching={isListFetching}
            />
          </Card>
        </Col>
        <Col md={4}>
          {showForm && (
            <StaffForm
              closeFormHandler={closeFormHandler}
              submitHandler={staffUserCreateOrUpdate}
              isLoading={isLoading}
              prefilledData={updatedPrefilledData}
              GPData={GPData || []}
              rolesData={rolesData || []}
            />
          )}
        </Col>
      </Grid>
      {Boolean(confirmDeleteItem) && (
        <ConfirmDialog
          isLoading={isLoading}
          handleClose={() => closeFormHandler()}
          icon={<FaTrashAlt />}
          title={t('confirm-delete-patient')}
          status="delete"
          actions={[
            <CommonButton
              variant="secondary"
              onClick={() => closeFormHandler()}
              key={confirmDeleteItem?.id + '-cancel'}
            >
              {t('cancel')}
            </CommonButton>,
            <CommonButton
              variant="danger"
              onClick={handleConfirmDeleteUser}
              key={confirmDeleteItem?.id + '-delete'}
            >
              {t('delete')}
            </CommonButton>,
          ]}
        />
      )}
    </AdminLayout>
  );
};
