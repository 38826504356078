import { WrappingRow } from 'components/common/BasicStyles.styled';
import React from 'react';

import {
  AppointmentCheckWrapperStyle,
  AppointmentDateAndCheckRowStyle,
  DateTimeTextStyle,
} from '../../../../containers/staff/calendar/NewAppointmentsDetailsFormData.styled';
import { useAppointmentReferenceNumber } from '../../../../hooks/useAppointmentReferenceNumber';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import {
  Appointment,
  AppointmentAuditItem,
} from '../../../../types/appointments';
import {
  DATE_FNS_UK_DATE_TIME_FORMAT_WITH_WEEK_DAY,
  formatDate,
  parseDateTimeOnly,
} from '../../../../utils/dateUtil';
import { getHookFormTyped } from '../../../../utils/helpers';
import { InfoLabelText } from '../../../common/Forms/InfoLabelText';
import TextArea from '../../../common/Forms/TextArea';
import {
  AppointmentReferrers,
  IAppointmentReferrers,
  IReferrerForm,
} from './AppointmentReferrers';
import { BitwiseCheckboxArray } from '../../../common/Forms/BitwiseCheckboxArray';
import { Controller } from 'react-hook-form';
import { useApptFlagOptions } from '../../../../hooks/appointments/useApptFlagOptions';

export type BookAppointmentForm = {
  comment?: string;
  appt_flags?: number;
} & IReferrerForm;

export type AppointmentSubformType = {
  appointment: BookAppointmentForm & {};
};

type IFormAppointmentsProps<T extends AppointmentSubformType> = {
  selectedAppointment?: Appointment;
  onlyCommentEditable?: boolean;
  audit?: AppointmentAuditItem[];
} & IAppointmentReferrers<T>;

export const EditAppointmentData = <T extends AppointmentSubformType>({
  referrers,
  selectedAppointment,
  onlyCommentEditable = false,
  audit,
  ...rest
}: IFormAppointmentsProps<T>) => {
  const { register, errors, control } = getHookFormTyped<
    AppointmentSubformType,
    T
  >(rest);
  const appointmentDate =
    selectedAppointment && parseDateTimeOnly(selectedAppointment.appt_time);
  const referenceNumber = useAppointmentReferenceNumber({
    appointmentDate,
    auditItems: audit,
  });

  const { t } = useCommonTranslation();
  const flagOptions = useApptFlagOptions();
  return (
    <>
      <AppointmentReferrers
        {...rest}
        referrers={referrers}
        disabled={onlyCommentEditable}
      />
      <AppointmentDateAndCheckRowStyle>
        <WrappingRow>
          <DateTimeTextStyle>
            {appointmentDate && (
              <InfoLabelText
                label={t('date-and-time')}
                text={formatDate(
                  appointmentDate,
                  DATE_FNS_UK_DATE_TIME_FORMAT_WITH_WEEK_DAY,
                )}
              />
            )}
          </DateTimeTextStyle>
          <AppointmentCheckWrapperStyle>
            <Controller
              name={'appointment.appt_flags'}
              control={control}
              render={({ field }) => (
                <BitwiseCheckboxArray
                  disabled={onlyCommentEditable}
                  field={field}
                  options={flagOptions}
                />
              )}
            />
          </AppointmentCheckWrapperStyle>
        </WrappingRow>
        {referenceNumber && (
          <WrappingRow>
            <InfoLabelText
              label={t('appointment-reference-number')}
              text={referenceNumber}
            ></InfoLabelText>
          </WrappingRow>
        )}
      </AppointmentDateAndCheckRowStyle>
      <TextArea
        label={t('appointment-comment')}
        placeholder={t('appointment-comment')}
        helperText={errors.appointment?.comment?.message}
        {...register('appointment.comment')}
      />
    </>
  );
};
