import styled from 'styled-components';
import { h4, stripButton } from 'styles/Typography';
import { toRem } from 'utils/helpers';

export const Title = styled.h2`
  ${h4};
`;
export const AccBirthdate = styled.p`
  font-size: var(--body1);
  color: var(--textsecondary);
  margin: 0;
`;
export const FamilyAccWrapper = styled.div`
  min-height: ${toRem(56)}rem;
  box-shadow: var(--shadow-3);
  padding: var(--s2) var(--s2) var(--s2) var(--s3);
  border-radius: var(--s3);
  margin-top: var(--s3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:last-of-type {
    margin-bottom: var(--s3);
  }
`;
export const FamilyAccActions = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-left: var(--s2);
  }
`;
export const AddFamilyMemberBtn = styled.button`
  ${stripButton};
  ${h4};
  display: flex;
  align-items: center;
  margin: var(--s3) 0;
  & > div {
    margin-right: var(--s2);
  }
  &:hover {
    & > div {
      color: var(--yellowaction);
      border-color: var(--yellowaction);
      box-shadow: var(--shadow-3);
    }
  }
`;
