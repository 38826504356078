import { useMemo } from 'react';
import { SADApptStatistics } from '../../types/reports';
import { ISortAndPaginatedFilters } from '../../types/table';

export type DayKeys = '0' | '1' | '2' | '3' | '4' | '5';

export type AdminStatisticsFiveYearsItem = {
  [dayKey in DayKeys]: number;
} & {
  id: string;
  trustName: string;
  siteName: string; //current year
};

export type AdminStatisticsFiveYearData = {
  data: AdminStatisticsFiveYearsItem[];
};

export interface AdminStatisticsFiveYearFilters
  extends ISortAndPaginatedFilters {}

export const useAdminStatisticsLastFiveYearsData = ({
  sadStatistics,
  currentYear,
}: {
  sadStatistics?: SADApptStatistics;
  currentYear: number;
}) => {
  return useMemo<AdminStatisticsFiveYearData>(() => {
    if (!sadStatistics) {
      return {
        data: [],
      };
    }

    const keyedBySiteName: {
      [site_name: string]: Omit<AdminStatisticsFiveYearsItem, 'siteName'>;
    } = {};
    sadStatistics.forEach((item) => {
      if (!keyedBySiteName[item.site_name]) {
        keyedBySiteName[item.site_name] = {
          id: item.site_id,
          trustName: item.trust_name,
          '0': 0,
          '1': 0,
          '2': 0,
          '3': 0,
          '4': 0,
          '5': 0,
        };
      }

      const yearDifference = currentYear - item.year;

      if (yearDifference <= 5) {
        keyedBySiteName[item.site_name][yearDifference.toString() as DayKeys] =
          item.appt_count;
      }
    });

    return {
      data: Object.entries(keyedBySiteName).map(([siteName, stats]) => ({
        siteName,
        ...stats,
      })),
    };
  }, [sadStatistics, currentYear]);
};
