import { DefaultLayout } from 'components/Layout/DefaultLayout';
import React, { useMemo } from 'react';
import { AdminNavbarDropdowns } from '../../configs/RoutesConfig';
import { useAuth } from '../../context/AuthContext';

interface IAdminLayoutProps {}

export const AdminLayout: React.FunctionComponent<IAdminLayoutProps> = ({
  children,
}) => {
  const { sangixUser, logout } = useAuth();

  const navbarDropdowns = useMemo(
    () => AdminNavbarDropdowns(sangixUser, logout),
    [logout, sangixUser],
  );
  return (
    <DefaultLayout
      navbarDropdowns={navbarDropdowns}
      showNHSLogo={false}
      noReleaseNotes={true}
    >
      {children}
    </DefaultLayout>
  );
};
