import styled from 'styled-components';
import { toRem } from '../../../utils/helpers';

export const CubicleNextWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: var(--s3);
`;

export const CubicleNextText = styled.span`
  font-size: ${toRem(20)}rem;
`;

export const CubicleNextTextMain = styled.span`
  font-weight: 600;
  font-size: ${toRem(20)}rem;
  text-transform: uppercase;
`;
