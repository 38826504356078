import React from 'react';
import {
  KioskSearchResultHint,
  KioskSearchResultSelectButton,
  KioskSelectWrapper,
} from '../Kiosk.styled';
import { KioskAppointment } from '../../../../types/appointments';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { formatDateOnlySite, parseIsoDate } from '../../../../utils/dateUtil';

interface KioskSearchResultSelectAppointmentProps {
  appts: KioskAppointment[];
  onSelectedAppointment: (appt: KioskAppointment) => any;
}

export const KioskSearchResultConfirmAppointment: React.FC<
  KioskSearchResultSelectAppointmentProps
> = ({ appts, onSelectedAppointment }) => {
  const { t } = useCommonTranslation();
  return (
    <>
      <KioskSearchResultHint>
        {t('kiosk-verify-identity')}
      </KioskSearchResultHint>
      {appts.map((appt, index) => {
        return (
          <KioskSelectWrapper
            key={`select_appt_${index}`}
            onClick={() => onSelectedAppointment(appt)}
          >
            <div
              style={{
                flex: 1,
                padding: 'var(--s3) var(--s5) ',
              }}
            >
              {appt.user_full_name}
            </div>
            <div
              style={{
                minWidth: '33%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {formatDateOnlySite(parseIsoDate(appt.user_birthdate))}
            </div>

            <div
              style={{
                minWidth: '33%',
                display: 'flex',
              }}
            >
              <KioskSearchResultSelectButton data-testid={`${appt.id}_select`}>
                {t('check-in')}
              </KioskSearchResultSelectButton>
            </div>
          </KioskSelectWrapper>
        );
      })}
    </>
  );
};
