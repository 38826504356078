import styled from 'styled-components';
import { toRem } from 'utils/helpers';

export const StyledView = styled.div`
  scroll-behavior: smooth;
  padding-right: ${toRem(16)}rem;
`;

export const StyledTrackHorizontal = styled.div`
  height: ${toRem(8)}rem;
  left: 0;
  bottom: 0;
  width: 100%;
`;
export const StyledTrackVertical = styled.div`
  width: ${toRem(8)}rem;
  right: 0;
  top: 0;
  height: 100%;
`;
export const StyledThumb = styled.div`
  background: var(--disabled);
  transition: 0.3s ease background;
  border-radius: ${toRem(4)}rem;
  &:hover {
    background: var(--textdisabled);
  }
  &:active {
    background: var(--textsecondary);
  }
`;
