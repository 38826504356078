import { css } from 'styled-components';

export const listItemMarginY = (margin: string) => css`
  margin-bottom: ${margin};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const listItemMarginX = (margin: string) => css`
  margin-right: ${margin};
  &:last-child {
    margin-right: 0;
  }
`;
