import React from 'react';

import CommonSelect from '../../components/common/Forms/Select';
import * as constants from '../../configs/constants';
import {
  DEPARTMENT_LEAD,
  GP_SURGERY,
  PATIENT,
  RECEPTIONIST,
  ROLE_OFFSITE_RECEPTIONIST,
  ROLE_SPECIALIST_PLUS_WITH_REPORTS,
  SPECIALIST,
  SPECIALIST_PLUS,
  SUPERADMIN,
  THIRD_PARTY,
  USER_ROLE_IDS_ORDERED,
  UserRoleId,
} from '../../configs/constants';
import { useAuth } from '../../context/AuthContext';
import { useRole } from '../../context/RoleContext';
import { SelectOption } from '../../types/common';

const roleName = (
  name: keyof Pick<
    typeof constants,
    | 'SUPERADMIN'
    | 'DEPARTMENT_LEAD'
    | 'RECEPTIONIST'
    | 'THIRD_PARTY'
    | 'SPECIALIST'
    | 'SPECIALIST_PLUS'
    | 'GP_SURGERY'
    | 'PATIENT'
    | 'ROLE_OFFSITE_RECEPTIONIST'
    | 'ROLE_SPECIALIST_PLUS_WITH_REPORTS'
  >,
) => name;

const roleNames = {
  [SUPERADMIN]: roleName('SUPERADMIN'),
  [DEPARTMENT_LEAD]: roleName('DEPARTMENT_LEAD'),
  [RECEPTIONIST]: roleName('RECEPTIONIST'),
  [THIRD_PARTY]: roleName('THIRD_PARTY'),
  [SPECIALIST]: roleName('SPECIALIST'),
  [SPECIALIST_PLUS]: roleName('SPECIALIST_PLUS'),
  [GP_SURGERY]: roleName('GP_SURGERY'),
  [PATIENT]: roleName('PATIENT'),
  [ROLE_OFFSITE_RECEPTIONIST]: roleName('ROLE_OFFSITE_RECEPTIONIST'),
  [ROLE_SPECIALIST_PLUS_WITH_REPORTS]: roleName(
    'ROLE_SPECIALIST_PLUS_WITH_REPORTS',
  ),
};

const roleSelect: SelectOption<UserRoleId>[] = USER_ROLE_IDS_ORDERED.map(
  (roleId) => ({
    key: roleId,
    label: roleNames[roleId],
    value: roleId,
  }),
);

export const MockBEAuthRolePick: React.FunctionComponent = () => {
  const { currentUserRole } = useRole();
  const { updateAuthUser, sangixUser } = useAuth();

  return (
    <>
      {sangixUser && (
        <CommonSelect
          style={{
            position: 'absolute',
            top: 25,
            right: 400,
            zIndex: 80,
          }}
          value={currentUserRole?.id}
          onChange={(newRoleId) => {
            updateAuthUser({
              sangixUser: {
                ...sangixUser,
                roles: sangixUser.roles.map((role) => {
                  if (role.site_id === sangixUser.active_site_id) {
                    return {
                      ...role,
                      role_id: newRoleId as UserRoleId,
                    };
                  } else {
                    return role;
                  }
                }),
              },
            });
          }}
          options={roleSelect}
        />
      )}
    </>
  );
};
