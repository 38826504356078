import { Loading } from 'components/common/Loading';
import { setOrReplaceAxiosInterceptor } from 'configs/Axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { auth, signInWithEmail, signInWithGoogle } from 'services/firebase';
import { IAuth, UpdateUserProps } from 'types/authentication';
import { errorToastForFirebase, useAuthHooks } from './authHooks';
import { useCommonTranslation } from '../hooks/i18n/useCommonTranslation';

const defaultContext: IAuth = {
  errorMessage: null,
  need_complete_signup: false,

  updateAuthUser: () => console.warn('Context not ready'),
  loginEmail: () => console.warn('Context not ready'),
  loginGoogle: () => console.warn('Context not ready'),
  logout: () => {
    console.warn('Context not ready');
    return Promise.resolve();
  },
};

export const AuthContext = React.createContext<IAuth>(defaultContext);
AuthContext.displayName = 'Auth Provider'; // Only for debugging

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const { t } = useCommonTranslation();
  const { replace, location } = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [userState, setUserState] = useState<IAuth>({
    ...defaultContext,
  });

  const updateUser = (newValues: UpdateUserProps) => {
    setUserState((prev) => ({ ...prev, ...newValues }));
  };

  const { handleFirebaseUserLogin, logout } = useAuthHooks({
    setUserState,
    defaultContext,
  });

  useEffect(() => {
    /**
     * Ensure clearing token and user state when the user has signed off (including
     * in a different tab)
     */
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        await handleFirebaseUserLogin(user);
        // Reset guidelines acceptance when user signs in (after sign in he should always see guidelines)
      } else {
        setOrReplaceAxiosInterceptor();
        setUserState(defaultContext);
      }
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, [setUserState, setIsLoading, handleFirebaseUserLogin]);

  /**
   * Redirects auth users from login and signup
   */
  useEffect(() => {
    /**
     * Only really used on signup success, as the user becomes authenticated
     * all the middleware before routes unmount the signup component or redirect
     * and it is therefore
     * not possible to navigate from there.
     */
    if (userState.replacePath) {
      replace(userState.replacePath);
      updateUser({ replacePath: undefined });
    }
  }, [location.pathname, replace, userState]);

  const firebaseLoginError = useCallback(
    (error: any) => {
      setOrReplaceAxiosInterceptor();
      errorToastForFirebase(error, t);
      console.error(error);
      setUserState(defaultContext);
    },
    [setUserState, t],
  );

  const loginEmail = async (email: string, password: string) => {
    try {
      /**
       * side effects handled via onAuthStateChanged
       */
      await signInWithEmail(email, password);
    } catch (error: any) {
      firebaseLoginError(error);
    }
  };
  const loginGoogle = async () => {
    try {
      /**
       * side effects handled via onAuthStateChanged
       */
      await signInWithGoogle();
    } catch (error: any) {
      firebaseLoginError(error);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...userState,
        updateAuthUser: updateUser,
        loginEmail,
        loginGoogle,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
