import React, { useEffect, useMemo, useState } from 'react';
import { StaffOrPatientFormData } from '../../../../../components/views-components/staff/settings/users/StaffOrPatientFormData';
import { FormBody } from '../../../../../components/common/Forms/Forms.styled';
import { generateNewUserId, getUserFormData } from '../../../../../utils/user';
import { UserData, UserFormValues } from '../../../../../types/users';
import { useForm } from 'react-hook-form';
import { AppointmentCard } from '../../../../../components/views-components/staff/appointments/AppointmentCard';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import {
  useCreateUserMutation,
  useUpdateUserMutation,
} from '../../../../../query/users';
import { errorToast, successToast } from '../../../../../utils/toast';
import { FaUserAlt } from 'react-icons/fa';
import { usePatientRole } from '../../../../../context/RoleContext';
import { SiteData } from '../../../../../types/sites';
import { TrustData } from '../../../../../types/trusts';
import { useGPSurgeriesOptions } from '../../../../../hooks/referrals/useGPSurgeriesOptions';
import { ButtonSetWithCloseButton } from '../../../../../components/Form/ButtonSetWithCloseButton';
import { CommonButton as Button } from '../../../../../components/common/Forms/Button';
import { useMemberRelationLabel } from '../../../../../hooks/user/useMemberRelationLabel';
import { getPatientTrustIds } from '../../../../../utils/patient';
import { usePatientTrusts } from '../../../../../hooks/user/usePatientTrusts';

type IUserCardProps = {
  closeFormHandler: (userAfterChange?: UserData) => void;
  prefilledData?: UserData;
  activeSite: SiteData;
  activeTrust: TrustData;
  onMembersClicked: () => void;
};

export const PatientEditCreateCard: React.FunctionComponent<IUserCardProps> = ({
  prefilledData,
  closeFormHandler,
  activeTrust,
  activeSite,
  onMembersClicked,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useCommonTranslation();
  const { isLoading: isPatientTrustsLoading, trusts: patientTrusts } =
    usePatientTrusts(prefilledData);
  const trusts = useMemo(() => {
    return prefilledData ? patientTrusts : [activeTrust];
  }, [activeTrust, patientTrusts, prefilledData]);
  const { isLoading: isSurgeriesLoading, gpSurgeriesOptions } =
    useGPSurgeriesOptions({
      gpFilters: {
        trustIDs: prefilledData
          ? getPatientTrustIds(prefilledData)
          : [activeTrust.id],
      },
    });
  const { id: patientRoleId } = usePatientRole();

  const { mutate: createUser } = useCreateUserMutation({
    onSuccess: (createdUser) => {
      successToast(t('patient-created'));
      closeFormHandler(createdUser);
    },
    onErrorAfterToast: () => closeFormHandler(),
  });
  const { mutate: updateUser } = useUpdateUserMutation({
    onSuccess: (_, updatedUser) => {
      successToast(t('patient-updated'));
      closeFormHandler(updatedUser as UserData);
    },
    onErrorAfterToast: () => closeFormHandler(),
  });

  const submitHandler = (data: UserFormValues) => {
    const structuredData: UserFormValues = {
      ...data,
    };

    setIsLoading(true);
    if (prefilledData) {
      return updateUser({ ...prefilledData, ...structuredData });
    } else {
      if (structuredData.active_site_id) {
        return createUser({
          id: generateNewUserId(),
          ...structuredData,
          role_id: patientRoleId,
          active_site_id: structuredData.active_site_id,
        });
      } else {
        // This should not happen, it is just for insuficient typescript.
        errorToast('Active site missing');
      }
    }
  };

  const {
    register,
    control,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<{ user: UserFormValues }>({
    defaultValues: prefilledData ? { user: prefilledData } : {},
  });

  useEffect(() => {
    // form fields are ready after trusts are loaded
    if (trusts) {
      setFocus('user.firstname');
    }
  }, [setFocus, trusts]);

  const relation = useMemberRelationLabel(prefilledData?.relation);

  return (
    <AppointmentCard
      isLoading={isLoading || isSurgeriesLoading || isPatientTrustsLoading}
      title={t('patient-details (' + relation + ')')}
      onCloseClicked={closeFormHandler}
      icon={<FaUserAlt />}
    >
      <FormBody
        onSubmit={handleSubmit((data) =>
          submitHandler(
            getUserFormData({
              ...data.user,
              active_site_id:
                prefilledData?.active_site_id || (activeSite?.id as string),
            }).userInfo,
          ),
        )}
      >
        {trusts && (
          <StaffOrPatientFormData
            gpOptions={gpSurgeriesOptions}
            register={register}
            control={control}
            errors={errors}
            trusts={trusts}
          />
        )}

        <ButtonSetWithCloseButton onCloseClicked={closeFormHandler}>
          <Button variant="primary" type="submit">
            {t('save')}
          </Button>
          {prefilledData?.id && (
            <Button
              variant="secondary"
              type="button"
              onClick={onMembersClicked}
            >
              {t('members')}
            </Button>
          )}
        </ButtonSetWithCloseButton>
      </FormBody>
    </AppointmentCard>
  );
};
