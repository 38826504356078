import React, { forwardRef, ReactElement, useRef } from 'react';
import {
  positionValues,
  ScrollbarProps,
  Scrollbars,
} from 'react-custom-scrollbars-2';
import { useEffectOnce } from 'react-use';
import {
  StyledThumb,
  StyledTrackHorizontal,
  StyledTrackVertical,
  StyledView,
} from './CustomScrollbar.styled';

interface Props extends ScrollbarProps {
  children: ReactElement;
  setScrollValues?: React.Dispatch<
    React.SetStateAction<positionValues | undefined>
  >;
}

export default forwardRef<any | undefined, Props>(function CustomScrollbar(
  { children, setScrollValues, ...props },
  ref,
): ReactElement {
  const innerRef = useRef(ref);
  const handleScrollUpdate = () => {
    const values = (innerRef?.current as any)?.current?.getValues();
    if (setScrollValues && values) setScrollValues(values);
  };

  useEffectOnce(() => {
    if (setScrollValues) {
      handleScrollUpdate();
    }
  });

  return (
    <Scrollbars
      renderTrackHorizontal={(props) => <StyledTrackHorizontal {...props} />}
      renderTrackVertical={(props) => <StyledTrackVertical {...props} />}
      renderThumbHorizontal={(props) => <StyledThumb {...props} />}
      renderThumbVertical={(props) => <StyledThumb {...props} />}
      renderView={(props) => <StyledView {...props} />}
      onScrollStop={handleScrollUpdate}
      ref={ref}
      {...props}
    >
      {children}
    </Scrollbars>
  );
});
