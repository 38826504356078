import styled, { css } from 'styled-components';
import { fadeIn } from 'styles/partials/animations';
import { h4 } from 'styles/Typography';
import { toRem } from 'utils/helpers';
import { Card } from '..';
import React from 'react';

export const TooltipWrapperStyle = styled.div`
  position: relative;
`;

export const TooltipContentStyle = styled(Card)<{
  readonly minWidth?: number;
  variant?: 'primary' | 'danger';
}>`
  && {
    margin: var(--s1);
    position: absolute;
    z-index: 2;
    animation: ${fadeIn} 0.3s ease forwards;
    border-radius: var(--s3);
    padding: var(--s2) var(--s3);
    color: var(--textprimary);
    min-height: var(--s4);
    ${({ variant }) =>
      variant === 'danger' ? 'color: var(--rednegative);' : ''}
  }
`;

export const TimePlanningTooltipWrapperStyle = styled.div<{
  readonly position?: {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
  };
  readonly positionType?: React.CSSProperties['position'];
}>`
  position: ${({ positionType }) => positionType || 'absolute'};
  margin-left: auto;
  top: 0;
  right: 0;
  z-index: 9;
  ${({ position }) =>
    position &&
    css`
      top: ${position.top || 0};
      right: ${position.right || 0};
    `}
`;

export const TimePlanningTooltipContent = styled(Card)<{
  readonly minWidth?: number;
}>`
  && {
    position: absolute;
    animation: ${fadeIn} 0.3s ease forwards;
    top: 0;
    right: 0;
    border-radius: var(--s3);
    padding: var(--s1) var(--s3) var(--s3);
    ${({ minWidth }) => (minWidth ? `min-width:${toRem(minWidth)}rem` : '')};
    font-size: var(--bodysm);
    color: var(--textsecondary);
    font-weight: 500;
    min-height: var(--s4);
  }
`;

export const TimePlanningTitle = styled.h4`
  ${h4};
  margin-bottom: var(--s3);
`;
export const TimePlanningList = styled.ul`
  /* margin-bottom: var(--s3); */
`;
export const TimePlanningListItem = styled.li`
  margin-bottom: var(--s2);
  display: flex;
  font-weight: 500;

  & > * {
    margin-right: var(--s2);
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;
export const TimePlanningItemColor = styled.div<{
  readonly shape?: 'button' | 'radio';
}>`
  min-width: ${({ shape }) => (shape ? `${toRem(32)}rem` : 'var(--s3)')};
  max-width: ${({ shape }) => (shape ? `${toRem(32)}rem` : 'var(--s3)')};
  min-height: var(--s3);
  max-height: var(--s3);
  border-radius: ${({ shape }) => (shape ? `${toRem(50)}rem` : '50%')};
  color: var(--white);
  text-align: center;
  font-size: ${toRem(10)}rem;
  line-height: var(--s3);
  font-weight: 400;
  ${({ shape, style }) =>
    shape === 'radio' &&
    css`
      border: 1px solid var(--textdisabled);
      position: relative;
      background: var(--white) !important;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: var(--s1);
        transform: translateY(-50%);
        height: ${toRem(8)}rem;
        width: ${toRem(8)}rem;
        border-radius: 50%;
        border: 1px solid ${style?.background};
      }
    `}
`;
export const TimePlanningDesc = styled.p`
  margin: var(--s3) 0 0;
`;
export const TooltipListTitle = styled.h4`
  ${h4};
  margin: var(--s2) 0;
`;
