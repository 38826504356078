import { AxiosError } from 'axios';
import { Card } from 'components/common';
import { CommonButton as Button } from 'components/common/Forms/Button';
import {
  CloseButton,
  DialogFormWrapper,
  FormActionsStyle,
  FormBody,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import TextArea from 'components/common/Forms/TextArea';
import { CardLoader } from 'components/common/Loading';
import React, { ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FaHospital, FaTimes } from 'react-icons/fa';
import { CCGFormValues, ClinicalCommissioningGroup } from 'types/ccg';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';

interface Props {
  closeFormHandler: (err?: true | AxiosError<any>) => void;
  submitHandler: (data: CCGFormValues, isUpdateCCG: boolean) => void;
  isLoading: boolean;
  prefilledData?: ClinicalCommissioningGroup;
  handleDeleteCCG: (value: string) => void;
}

export default function CCGListForm({
  closeFormHandler,
  submitHandler,
  isLoading,
  prefilledData,
  handleDeleteCCG,
}: Props): ReactElement {
  const isEdit = Boolean(prefilledData);
  const { t } = useCommonTranslation();
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<CCGFormValues>({
    defaultValues: prefilledData
      ? {
          name: prefilledData.name,
          description: prefilledData.description,
        }
      : {},
  });

  useEffect(() => {
    // This for setting focus after form mounted for better user experience
    setFocus('name');
  }, [setFocus]);

  return (
    <Card>
      <DialogFormWrapper>
        <CloseButton
          size="auto"
          variant="primary"
          iconOnly
          onClick={() => closeFormHandler()}
        >
          <FaTimes />
        </CloseButton>
        <FormHeader>
          <FaHospital />
          <FormTitle>{t('ccg-list')}</FormTitle>
        </FormHeader>
        <FormBody
          onSubmit={handleSubmit((data) => submitHandler(data, isEdit))}
        >
          <FormRow>
            <Input
              label={t('name')}
              placeholder={t('name')}
              helperText={errors.name?.message}
              hasError={Boolean(errors.name)}
              {...register('name', {
                required: t('must-not-empty') as string,
              })}
              required
            />
          </FormRow>
          <FormRow>
            <TextArea
              label={t('description')}
              placeholder={t('description')}
              helperText={errors.description?.message}
              hasError={Boolean(errors.description)}
              {...register('description')}
            />
          </FormRow>
          <FormActionsStyle align="center">
            <Button variant="primary" type="submit">
              {!isEdit ? t('add') : t('save')}
            </Button>
            {prefilledData && (
              <Button
                variant="danger"
                type="button"
                onClick={() => handleDeleteCCG(prefilledData.id)}
              >
                {t('delete')}
              </Button>
            )}
            <Button
              type="button"
              variant="secondary"
              onClick={() => closeFormHandler()}
            >
              {t('cancel')}
            </Button>
          </FormActionsStyle>
        </FormBody>
        {isLoading && <CardLoader fillWrapper={true} />}
      </DialogFormWrapper>
    </Card>
  );
}
