import { fonts } from 'styles/sizes';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form/dist/types/form';
import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import {
  AsYouType,
  isValidPhoneNumber as libPhoneIsValidPhoneNumber,
} from 'libphonenumber-js';
import { TFunction } from 'i18next';

// The validation rules should follow enhance the same rules as the backend found here: https://github.com/Sangix/sangix4-backend/blob/master/internal/common/Validation.go
export const UK_PHONE_NUMBER_REGEX =
  /^(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?|\(?0)(?:\d{5}\)?[\s-]?\d{4,5}|\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3})|\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4}|\d{2}\)?[\s-]?\d{4}[\s-]?\d{4})(?:[\s-]?(?:x|ext\.?|#)\d{3,4})?$/;
export const UK_POST_CODE_REGEX =
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
const EMAIL_PATTERN = "^[A-Z0-9._%'+-]+@[A-Z0-9.-]+\\.[A-Z]{2,}$";
export const NAME_PATTERN = /^[\p{Letter}0-9 ,.'-]+$/u;

// noinspection CommaExpressionJS
export const stripObjFalsyValues = (obj: any) =>
  Object.entries(obj).reduce((a: any, [k, v]) => (v ? ((a[k] = v), a) : a), {});

export const singleEmailPattern = new RegExp(`^${EMAIL_PATTERN}$`, 'i');

export const emailPatternSeparatedBySpaces = new RegExp(
  `^(${EMAIL_PATTERN})+( ${EMAIL_PATTERN})*$`,
  'i',
);

export function toRem(px: number) {
  return px / fonts.body1;
}

export function toRemStr(px: number) {
  return toRem(px) + 'rem';
}

// input => [1, 2, 4]
// output => 1 | 2 | 4
export function bitwiseArrayToNumber(arr?: number | number[]): number {
  if (!arr) return 0;
  if (typeof arr === 'number') return Number(arr);
  else return arr.reduce((acc, num) => acc | num, 0);
}

// input => 5
// output => [1, 4]
export function bitwiseNumberToIntValuesArray(num?: number | undefined) {
  if (!num) return [];
  const binaryString = num.toString(2).split('').reverse();
  const bitArray = binaryString.map((bit) => parseInt(bit, 2));
  return bitArray
    .map((bit, index) => (bit ? 1 << index : 0))
    .filter((bit) => bit !== 0);
}

/**
 * It seems that even when typed by <T extends something>
 * the wrappers fail to get the correct typing and we get ts errors in form.
 * This is a workaround to retype where useful.
 */
export const getHookFormTyped = <K, T extends K>(props: {
  register: UseFormRegister<T>;
  control?: Control<T>;
  errors: FieldErrors<T>;
  watch?: UseFormWatch<T>;
  trigger?: UseFormTrigger<T>;
  setValue?: UseFormSetValue<T>;
  getValues?: UseFormGetValues<T>;
}) => {
  return {
    register: props.register as unknown as UseFormRegister<K>,
    control: props.control as unknown as Control<K>,
    errors: props.errors as unknown as FieldErrors<K>,
    watch: props.watch as unknown as UseFormWatch<K>,
    trigger: props.trigger as unknown as UseFormTrigger<K>,
    setValue: props.setValue as unknown as UseFormSetValue<K>,
    getValues: props.getValues as unknown as UseFormGetValues<K>,
  };
};

export const optionalPhoneValidate = (t: TFunction, value?: string) => {
  return (
    !value ||
    isValidPhoneNumber(value) ||
    (t('phone-number-validation') as string)
  );
};

export const isValidPhoneNumber = (number: string): boolean => {
  return libPhoneIsValidPhoneNumber(number, 'GB');
};

export const formatPhoneNumber = (
  number: undefined | number | string | readonly string[],
) => {
  const asYouType = new AsYouType('GB');
  return number ? asYouType.input(number.toString()) : undefined;
};

export const hookFromTrimBeforeValidation = {
  setValueAs: (value: any) => value && value.trim(),
};
