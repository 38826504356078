import { TrustData } from '../types/trusts';
import { SelectOption } from '../types/common';
import { TFunction } from 'react-i18next';

export const NHS_NUMBER_MANDATORY = 'NHS_NUMBER_MANDATORY';
export const NHS_NUMBER_HIDDEN = 'NHS_NUMBER_HIDDEN';
export const NHS_NUMBER_OPTIONAL = 'NHS_NUMBER_OPTIONAL';

export const trustNhsNumberPolicyOptions = (t: TFunction): SelectOption[] => [
  {
    label: t('trust-nhs-number-policy-mandatory'),
    value: NHS_NUMBER_MANDATORY,
  },
  {
    label: t('trust-nhs-number-policy-optional'),
    value: NHS_NUMBER_OPTIONAL,
  },
  {
    label: t('trust-nhs-number-policy-hidden'),
    value: NHS_NUMBER_HIDDEN,
  },
];

export const shouldRenderNhsNumberTrusts = (trusts: TrustData[]) => {
  return !trusts.every(
    (trust) => trust.nhs_number_policy === NHS_NUMBER_HIDDEN,
  );
};

export const nhsNumberRequiredTrusts = (trusts: TrustData[]) => {
  return trusts.some(
    (trust) => trust.nhs_number_policy === NHS_NUMBER_MANDATORY,
  );
};

export const postCodeRequiredTrusts = (trusts: TrustData[]) => {
  return !trusts.every((trust) => trust.hide_postcode);
};

export const gpSurgeryRequiredTrusts = (trusts: TrustData[]) => {
  return !trusts.every((trust) => trust.hide_gp_surgery);
};

export const gpSurgeryRequiredTrust = (trust: TrustData) => {
  return gpSurgeryRequiredTrusts([trust]);
};
