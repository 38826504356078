import React from 'react';
import { StaffLayout } from '../../StaffLayout';
import { Grid4to8 } from '../../../../components/Layout/Grid4to8';
import { Card } from '../../../../components/common';
import { PageHelmet } from '../../../../components/common/PageHelmet';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { StaffSettingsTabsTree } from '../../../../configs/RoutesConfig';
import { MergeCard } from '../../../../components/common/Merge/MergeCard';
import {
  SOURCE_SELECTION_STATE,
  TARGET_SELECTION_STATE,
  useMergeEntity,
} from '../../../../hooks/util/useMergeEntity';
import { useGpSurgeriesTable } from './useGpSurgeriesTable';
import { FormTitle } from '../../../../components/common/Forms/Forms.styled';
import { FaStethoscope } from 'react-icons/fa';
import { useGpColumns } from '../../../../hooks/referrals/useGpColumns';
import {
  getFilterControls,
  isNOTCellSelected,
} from '../../../../components/Table/TableUtils';
import { RowActions } from '../../../../components/Table/Table.styled';
import { CommonButton as Button } from '../../../../components/common/Forms/Button';
import { GeneralPractice } from '../../../../types/generalPractices';
import { Column } from 'react-table';
import { FetchedDataTable } from '../../../../components/Table/FetchedDataTable';
import { MergeGpSurgeryBox } from './merge/MergeGpSurgeryBox';
import { MergeRedInfoBox } from '../../../../components/common/Merge/mergeStyle';
import { useMutateMergeGPSurgeries } from '../../../../query/gp';
import { successToast } from '../../../../utils/toast';
import { IDCellPropsWithSelected } from '../../../../types/table';

export const GPSurgeriesMerge = () => {
  const { t } = useCommonTranslation();
  const {
    setFilters,
    filters,
    ccgData,
    gpData,
    isLoading: isGpsLoading,
    isFetching: isGpsFetching,
  } = useGpSurgeriesTable();

  const {
    target,
    selectMergeItem,
    clearSelection,
    toggleSelectionState,
    selectionState,
    source,
    setSource,
    selectedIds,
  } = useMergeEntity({
    entities: gpData?.data,
  });
  const columns = useGpColumns({
    ccgData: ccgData?.data.data || [],
    lastColumn: {
      ...getFilterControls({
        t,
        filters,
        setFilters,
      }),
      Cell: ({ value, ...rest }: IDCellPropsWithSelected<GeneralPractice>) => {
        return (
          <RowActions>
            {isNOTCellSelected(rest) && (
              <Button
                size="auto"
                variant="primary"
                onClick={() => selectMergeItem(value)}
              >
                {t('select')}
              </Button>
            )}
          </RowActions>
        );
      },
    } as Column<GeneralPractice>,
  });

  const { mutate: mergeGpSurgeries, isLoading: isMergeGpSurgeriesLoading } =
    useMutateMergeGPSurgeries({
      onSuccess: () => {
        setSource(undefined);
        successToast(t('merge-gp-surgeries-success'));
      },
    });

  return (
    <StaffLayout>
      <PageHelmet title={t('gp-surgeries-merge')} />
      <Grid4to8
        leftBox={
          <MergeCard
            tabs={StaffSettingsTabsTree(t)}
            cardHeader={
              <>
                <FaStethoscope />
                <FormTitle>{t('gp-surgeries-merge')}</FormTitle>
              </>
            }
            isLoading={isMergeGpSurgeriesLoading}
            onClearSelection={clearSelection}
            onMerge={() =>
              source &&
              target &&
              mergeGpSurgeries({
                sourceId: source.id,
                targetId: target.id,
              })
            }
            target={target}
            source={source}
          >
            <MergeGpSurgeryBox
              label={t('merge-gp-surgeries-target')}
              onSelectClicked={() =>
                toggleSelectionState(TARGET_SELECTION_STATE)
              }
              isSelecting={selectionState === TARGET_SELECTION_STATE}
              gpSurgery={target}
              ccgData={ccgData?.data.data || []}
            />
            <MergeGpSurgeryBox
              label={t('merge-gp-surgeries-source')}
              onSelectClicked={() =>
                toggleSelectionState(SOURCE_SELECTION_STATE)
              }
              isSelecting={selectionState === SOURCE_SELECTION_STATE}
              gpSurgery={source}
              ccgData={ccgData?.data.data || []}
            />
            <MergeRedInfoBox>
              {source && target
                ? t('merge-gp-surgeries-ready-info')
                : t('merge-gp-surgeries-select')}
            </MergeRedInfoBox>
          </MergeCard>
        }
        rightBox={
          selectionState && (
            <Card>
              <FetchedDataTable
                columns={columns}
                data={gpData}
                isLoading={isGpsLoading}
                filters={filters}
                onSortOrPageChange={setFilters}
                isFetching={isGpsFetching}
                selectedIds={selectedIds}
              />
            </Card>
          )
        }
      />
    </StaffLayout>
  );
};
