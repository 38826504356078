import { FaInfoCircle } from 'react-icons/fa';
import { NoAppointmentsBarStyled } from './MyAppointments.styled';

interface Props {
  text: string;
}

export const NoAppointmentsBar = ({ text }: Props) => {
  return (
    <NoAppointmentsBarStyled>
      <FaInfoCircle />
      <span>{text}</span>
    </NoAppointmentsBarStyled>
  );
};
