import { CommonButton } from 'components/common/Forms/Button';
import {
  CommonInputWrapper,
  DatePickerStylesWrapper,
} from 'components/common/Forms/Forms.styled';
import styled from 'styled-components';
import { BulletListItem } from 'styles/components/BulletListItem.styled';
import { cssIf } from 'styles/partials/cssIf';
import { listItemMarginY } from 'styles/partials/listItemMargin';
import { toRemStr } from 'utils/helpers';

export const ReportsHeader = styled.div`
  border-bottom: 2px solid var(--greenaccent);
  display: flex;
  gap: var(--s3);
  margin-bottom: var(--s3);
  align-items: center;
  ${DatePickerStylesWrapper}, ${CommonInputWrapper} {
    width: ${toRemStr(160)};
  }
  ${CommonButton} {
    margin-left: auto;
    margin-bottom: var(--s2);
  }
`;
export const ReportItem = styled(BulletListItem)`
  ${listItemMarginY('var(--s1)')}
  box-shadow: var(--shadow-1);
  padding: 0;
  flex-direction: flex-end;
  gap: var(--s3);
  min-height: var(--s4);
`;
export const ColName = styled.div`
  font-size: var(--bodysm);
  font-weight: 500;
  padding: var(--s1) 0;
  padding-left: var(--s3);
  margin-right: auto;
  color: var(--textsecondary);
`;
export const ReportName = styled.div`
  font-size: var(--h4);
  font-weight: bold;
  padding: var(--s1) 0;
  padding-left: var(--s3);
  margin-right: auto;
`;
export const ReportSelectedDate = styled.div`
  color: var(--greenaccent);
  font-weight: bold;
`;

export const DateCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateRow = styled.div`
  display: flex;
  gap: var(--s3);
`;

export const StatisticsInfoRow = styled.div`
  display: flex;
  font-weight: bold;
  color: var(--greenaccent);
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  & > * {
    border-right: 1px solid var(--greenaccent);
    padding: 0 var(--s1);
    &:last-child {
      border-right: 0;
    }
  }
`;

type StatisticsInfoItemStyledProps = { lastInRow?: boolean };
export const StatisticsInfoItemStyled = styled.div<StatisticsInfoItemStyledProps>`
  ${cssIf('lastInRow')`
    && {
      border-right: 0;
    }
  `}
`;
