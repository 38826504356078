import ReactQuill from 'react-quill';

/**
 * Reads HTML string from `ReactQuill` instance.
 * It returns empty string when wysiwyg is empty.
 */
export const getWysiwygHtml = (reactQuill: ReactQuill) => {
  const editor = reactQuill.getEditor();
  const unprivilegedEditor = reactQuill.makeUnprivilegedEditor(editor);
  const html = unprivilegedEditor.getHTML();
  const trimmedHtml = (typeof html === 'string' ? html : '').trim();
  if (trimmedHtml === '' || trimmedHtml === '<p><br></p>') {
    /**
     * Quill adds `<p><br></p>` for each empty line. Before v2.0 it was able to set
     * `matchVisual` attribute to remove it. But from v2.0 this config value was removed,
     * therefore manual check of empty wysiwyg must be done.
     * See https://github.com/slab/quill/blob/ee827ffb605ba491246f201d497ce0e7d9e193a0/docs/guides/upgrading-to-2-0.md#configuration
     */
    return '';
  }
  return trimmedHtml;
};
