import { Card } from 'components/common';
import { FormHeader, FormTitle } from 'components/common/Forms/Forms.styled';
import { PageHelmet } from 'components/common/PageHelmet';
import ChangePasswordForm from 'components/views-components/patient/profile/ChangePasswordForm';
import { ReactElement, useState } from 'react';
import { FaCheck, FaKey } from 'react-icons/fa';
import { InlineConfirm } from '../../components/Popup/ConfirmDialog/InlineConfirm';
import { Col, Grid } from '../../components/common/Grid';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { StaffLayout } from './StaffLayout';
import { CommonLink } from '../../components/common/Forms/Button';
import { toRem } from '../../utils/helpers';
import { STAFF_URL } from '../../routes/AppRoutes';

export default function StaffChangePassword(): ReactElement {
  const { t } = useCommonTranslation();
  const [isFormSuccess, setIsFormSuccess] = useState(false);

  return (
    <StaffLayout>
      <PageHelmet title={t('change-password')} />
      <Grid>
        <Col md={4}>
          {isFormSuccess ? (
            <InlineConfirm
              isLoading={false}
              icon={<FaCheck />}
              title={t('password-changed')}
              description={t('password-changed-desc')}
              status="success"
              actions={[
                [
                  <CommonLink variant="primary" to={STAFF_URL}>
                    {t('ok')}
                  </CommonLink>,
                ],
              ]}
            />
          ) : (
            <Card>
              <FormHeader>
                <FaKey />
                <FormTitle>{t('change-password')}</FormTitle>
              </FormHeader>
              <ChangePasswordForm
                setIsFormSuccess={setIsFormSuccess}
                backButton={
                  <CommonLink
                    type="button"
                    variant="secondary"
                    style={{ minWidth: `${toRem(160)}rem` }}
                    to={STAFF_URL}
                  >
                    {t('close')}
                  </CommonLink>
                }
              />
            </Card>
          )}
        </Col>
      </Grid>
    </StaffLayout>
  );
}
