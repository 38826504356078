import { css } from 'styled-components';

// injects css if the property value is thruly
// example usage: cssIf('error')`color: red`
export const cssIf = <Props extends object>(propName: keyof Props) => {
  const applyCss = (...args: Parameters<typeof css>) =>
    css<Props>`
      ${(props) => Boolean(props[propName]) && css(...args)}
    `;
  return applyCss as typeof css;
};
