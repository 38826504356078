import { AppointmentStatus, AppointmentUpdate } from '../../types/appointments';
import { useCallback } from 'react';
import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';
import {
  useMutateAppointmentNotTaken,
  useMutateAppointmentTaken,
  useMutateNotPreparedAppointment,
  useMutateUpdateAppointment,
} from '../../query/appointments';

type AppointmentChangeStatusOrUpdate = AppointmentUpdate & {
  /**
   * The current status of the appointment in order to determine
   * if the appointment will be updated only or the status
   * will be changed together with update as well.
   *
   * For example, if the current status is 'TAKEN' and the appointment
   * is about to be updated to 'TAKEN' status, then the appointment
   * will be updated only without change of the status as it is already
   * having expected value. Otherwise, the status will be changed
   * together with the update.
   */
  currentStatus: AppointmentStatus;
};

export const useAppointmentTakenOrUpdate = <
  T extends AppointmentChangeStatusOrUpdate,
>(
  options?: UseMutationOptions<any, any, T>,
) => {
  return useAppointmentChangeStatusOrUpdate({
    updateOnStatus: 'TAKEN',
    changeStatusMutationResult: useMutateAppointmentTaken(
      options as UseMutationOptions<any, any, AppointmentUpdate>,
    ),
    options,
  });
};

export const useAppointmentNotTakenOrUpdate = <
  T extends AppointmentChangeStatusOrUpdate,
>(
  options?: UseMutationOptions<any, any, T>,
) => {
  return useAppointmentChangeStatusOrUpdate({
    updateOnStatus: 'NOT_TAKEN',
    changeStatusMutationResult: useMutateAppointmentNotTaken(
      options as UseMutationOptions<any, any, AppointmentUpdate>,
    ),
    options,
  });
};

export const useAppointmentNotPreparedOrUpdate = <
  T extends AppointmentChangeStatusOrUpdate,
>(
  options?: UseMutationOptions<any, any, T>,
) => {
  return useAppointmentChangeStatusOrUpdate({
    updateOnStatus: 'NOT_PREPARED',
    changeStatusMutationResult: useMutateNotPreparedAppointment(
      options as UseMutationOptions<any, any, AppointmentUpdate>,
    ),
    options,
  });
};

/**
 * Updates or changes status of an appointment based on the current appointment status.
 * The split is based on the `updateOnStatus` parameter.
 */
const useAppointmentChangeStatusOrUpdate = <
  T extends AppointmentChangeStatusOrUpdate,
>({
  updateOnStatus,
  changeStatusMutationResult,
  options,
}: {
  updateOnStatus: AppointmentStatus;
  changeStatusMutationResult: UseMutationResult<any, any, AppointmentUpdate>;
  options?: UseMutationOptions<any, any, T>;
}) => {
  const {
    mutate: updateAppointment,
    mutateAsync: updateAppointmentAsync,
    isLoading: isUpdateAppointmentLoading,
  } = useMutateUpdateAppointment(
    options as UseMutationOptions<any, any, AppointmentUpdate>,
  );

  const {
    mutate: changeStatusAppointment,
    mutateAsync: changeStatusAppointmentAsync,
    isLoading: isChangeStatusAppointmentLoading,
  } = changeStatusMutationResult;

  const mutate: UseMutateFunction<unknown, unknown, T, unknown> = useCallback(
    ({ currentStatus, ...appointment }: T) => {
      if (currentStatus === updateOnStatus) {
        updateAppointment(appointment);
      } else {
        changeStatusAppointment(appointment);
      }
    },
    [updateOnStatus, updateAppointment, changeStatusAppointment],
  );

  const mutateAsync: UseMutateAsyncFunction<unknown, unknown, T, unknown> =
    useCallback(
      ({ currentStatus, ...appointment }: T) => {
        if (currentStatus === updateOnStatus) {
          return updateAppointmentAsync(appointment);
        } else {
          return changeStatusAppointmentAsync(appointment);
        }
      },
      [updateOnStatus, updateAppointmentAsync, changeStatusAppointmentAsync],
    );

  return {
    isLoading: isUpdateAppointmentLoading || isChangeStatusAppointmentLoading,
    mutate,
    mutateAsync,
  };
};
