import CheckboxInput from 'components/common/Forms/CheckboxInput';
import {
  CheckBoxesWrapper,
  DialogFormWrapper,
  FormGroup,
  FormGroupTitle,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import TextArea from 'components/common/Forms/TextArea';
import { CardLoader } from 'components/common/Loading';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FaUserCog } from 'react-icons/fa';

import {
  DISABLE_SELF_CHECK_IN,
  FORMS,
  LCD_RUNNING_TEXT,
  LCD_SHORT_NAMES,
  MANDATORY_SOURCE,
  SHOW_APPOINTMENT_REFERENCE_NUMBER,
  SHOW_BIRTH_DATE_IN_CUBICLE_QUEUE,
  SHOW_MIA_BUTTON_IN_APPOINTMENT_PROCESSING,
  SHOW_OVERFLOW_CALENDAR,
  SHOW_PATIENT_SUPPORT_FORM,
  SHOW_WAITING_LIST,
  TUBES,
} from '../../../../configs/siteAndTrustAttributes';
import { SitePreferencesType, TYPE_STAFF } from '../SitePreferences';
import { UseCustomSettingsFormReturn } from './CustomSettingsHooks';
import { Card } from '../../../../components/common';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';

interface Props {
  isFetching: boolean;
  type: SitePreferencesType;
  attributesForm: UseCustomSettingsFormReturn;
}

export const CustomSettingsTab: React.FC<Props> = ({
  isFetching,
  type,
  attributesForm,
}) => {
  const { t } = useCommonTranslation();

  const { control, attributeRegister } = attributesForm;

  const watchedValues = useWatch({ control });

  return (
    <Card mb="var(--s4)">
      <DialogFormWrapper>
        <FormHeader>
          <FaUserCog />
          <FormTitle>{t('custom-settings')}</FormTitle>
        </FormHeader>
        <FormRow>
          <TextArea
            label={t('lcd-screen-running-text')}
            placeholder={t('suggestion')}
            {...attributeRegister(LCD_RUNNING_TEXT, (e) => e.target.value)}
            value={
              watchedValues.attributes?.find(
                (atr) => atr.key === LCD_RUNNING_TEXT,
              )?.value_str
            }
          />
        </FormRow>
        <FormRow>
          <FormGroup>
            <FormGroupTitle>{t('mandatory-fields')}</FormGroupTitle>
            <CheckBoxesWrapper isVertical>
              <CheckboxInput
                {...attributeRegister(`forms`, (e) => e.target.checked)}
                checked={Boolean(
                  watchedValues.attributes?.find((atr) => atr.key === FORMS)
                    ?.value_int,
                )}
                id="forms"
                label={t('make-forms-mandatory')}
              />
              <CheckboxInput
                {...attributeRegister(`tubes`, (e) => e.target.checked)}
                checked={Boolean(
                  watchedValues.attributes?.find((atr) => atr.key === TUBES)
                    ?.value_int,
                )}
                id="tubes"
                label={t('make-tubes-mandatory')}
              />
            </CheckBoxesWrapper>
          </FormGroup>
          <FormGroup>
            <FormGroupTitle>{t('other')}</FormGroupTitle>
            <CheckBoxesWrapper isVertical>
              <CheckboxInput
                {...attributeRegister(
                  SHOW_WAITING_LIST,
                  (e) => e.target.checked,
                )}
                checked={Boolean(
                  watchedValues.attributes?.find(
                    (atr) => atr.key === SHOW_WAITING_LIST,
                  )?.value_int,
                )}
                id={SHOW_WAITING_LIST}
                label={t('show-waiting-list')}
              />
              <CheckboxInput
                {...attributeRegister(LCD_SHORT_NAMES, (e) => e.target.checked)}
                checked={Boolean(
                  watchedValues.attributes?.find(
                    (atr) => atr.key === LCD_SHORT_NAMES,
                  )?.value_int,
                )}
                id={LCD_SHORT_NAMES}
                label={t('show-only-firstname')}
              />
              <CheckboxInput
                {...attributeRegister(
                  SHOW_OVERFLOW_CALENDAR,
                  (e) => e.target.checked,
                )}
                checked={Boolean(
                  watchedValues.attributes?.find(
                    (atr) => atr.key === SHOW_OVERFLOW_CALENDAR,
                  )?.value_int,
                )}
                id={SHOW_OVERFLOW_CALENDAR}
                label={t('show-overflow-calendar')}
              />
              <CheckboxInput
                {...attributeRegister(
                  SHOW_MIA_BUTTON_IN_APPOINTMENT_PROCESSING,
                  (e) => e.target.checked,
                )}
                checked={Boolean(
                  watchedValues.attributes?.find(
                    (atr) =>
                      atr.key === SHOW_MIA_BUTTON_IN_APPOINTMENT_PROCESSING,
                  )?.value_int,
                )}
                label={t('show-mia-in-appointment-processing')}
                key={SHOW_MIA_BUTTON_IN_APPOINTMENT_PROCESSING}
                id={SHOW_MIA_BUTTON_IN_APPOINTMENT_PROCESSING}
              />
              <CheckboxInput
                {...attributeRegister(
                  SHOW_BIRTH_DATE_IN_CUBICLE_QUEUE,
                  (e) => e.target.checked,
                )}
                label={t('show-birth-date-in-cubicle-queue')}
                key={SHOW_BIRTH_DATE_IN_CUBICLE_QUEUE}
                id={SHOW_BIRTH_DATE_IN_CUBICLE_QUEUE}
                checked={Boolean(
                  watchedValues.attributes?.find(
                    (atr) => atr.key === SHOW_BIRTH_DATE_IN_CUBICLE_QUEUE,
                  )?.value_int,
                )}
              />
              <CheckboxInput
                {...attributeRegister(
                  SHOW_APPOINTMENT_REFERENCE_NUMBER,
                  (e) => e.target.checked,
                )}
                label={t('show-appointment-reference-number')}
                key={SHOW_APPOINTMENT_REFERENCE_NUMBER}
                id={SHOW_APPOINTMENT_REFERENCE_NUMBER}
                checked={Boolean(
                  watchedValues.attributes?.find(
                    (atr) => atr.key === SHOW_APPOINTMENT_REFERENCE_NUMBER,
                  )?.value_int,
                )}
              />
              <CheckboxInput
                {...attributeRegister(
                  SHOW_PATIENT_SUPPORT_FORM,
                  (e) => e.target.checked,
                )}
                label={t('show-support-form')}
                key={SHOW_PATIENT_SUPPORT_FORM}
                id={SHOW_PATIENT_SUPPORT_FORM}
                checked={Boolean(
                  watchedValues.attributes?.find(
                    (atr) => atr.key === SHOW_PATIENT_SUPPORT_FORM,
                  )?.value_int,
                )}
              />
              <CheckboxInput
                {...attributeRegister(
                  DISABLE_SELF_CHECK_IN,
                  (e) => !e.target.checked,
                )}
                label={t('show-patient-Self-check-in')}
                key={DISABLE_SELF_CHECK_IN}
                id={DISABLE_SELF_CHECK_IN}
                checked={
                  !Boolean(
                    watchedValues.attributes?.find(
                      (atr) => atr.key === DISABLE_SELF_CHECK_IN,
                    )?.value_int,
                  )
                }
              />
            </CheckBoxesWrapper>
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <FormGroupTitle>{t('ice-users')}</FormGroupTitle>
            <CheckBoxesWrapper isVertical>
              <CheckboxInput
                {...attributeRegister(
                  MANDATORY_SOURCE,
                  (e) => e.target.checked,
                )}
                checked={Boolean(
                  watchedValues.attributes?.find(
                    (atr) => atr.key === MANDATORY_SOURCE,
                  )?.value_int,
                )}
                id={MANDATORY_SOURCE}
                label={t('make-referal-not-mandatory')}
                disabled={type === TYPE_STAFF}
              />
            </CheckBoxesWrapper>
          </FormGroup>
        </FormRow>
        {isFetching && <CardLoader fillWrapper={true} />}
      </DialogFormWrapper>
    </Card>
  );
};
