import { pick } from 'lodash';
import { Factory, Model } from 'miragejs';
import { CubicleData, CubicleFormValues } from 'types/cubicles';
import { ItemsResponse } from 'types/ItemsWithCountResponse';
import { resourceTypesResponse } from './resourceTypes';
import {
  getMockServer,
  MockEndpoint,
  wrapCountResponseCollection,
} from './server';

export const mockCubiclesData: PartialCubicleData[] = [
  {
    id: '56ba90839ccd4b43ba1e744d59012f02',
    site_id: '6e9b64f613404c3cbb665de0d8741919',
    name: 'CUB',
    resource_types: [resourceTypesResponse.data[0]],
  },
  {
    id: 'b63636d864fc412e8dabc7af397e2a30',
    site_id: '6e9b64f613404c3cbb665de0d8741919',
    name: 'Cub 2',
    resource_types: [resourceTypesResponse.data[1]],
  },
];
export type PartialCubicleData = Partial<CubicleData>;
const mockCubicleDefaults = {
  description: '',
  color: 0,
  sound: '',
  user_member_id: null,
};
export const cubicleFactory = Factory.extend<PartialCubicleData>({
  id(index) {
    return `${index}`;
  },
  ...mockCubicleDefaults,
});
export const cubicleModel = Model.extend<PartialCubicleData>({});

export const mockGetCubicles = (
  endpoint: MockEndpoint<ItemsResponse<PartialCubicleData>>,
  server = getMockServer(),
) => {
  server.get('/cubicle', endpoint);
};

export const mockUpdateCubicle = (
  endpoint: MockEndpoint<''>,
  server = getMockServer(),
) => {
  server.put('/cubicle', endpoint);
};

export const mockCubicles = (server = getMockServer()) => {
  mockCubiclesData.forEach((cubicle) => server.create('cubicle', cubicle));

  mockGetCubicles(
    (schema) => wrapCountResponseCollection(schema.all('cubicle')),
    server,
  );
  mockUpdateCubicle((schema, request) => {
    const update = JSON.parse(request.requestBody) as CubicleFormValues;
    const resource_types = resourceTypesResponse.data.filter(({ id }) =>
      update.cubicle_resource_type?.includes(id),
    );
    schema.find('cubicle', update.id)?.update({
      ...pick(
        update,
        'name',
        'description',
        'color',
        'sound',
        'user_member_id',
      ),
      resource_types,
    });
    return '';
  }, server);
};
