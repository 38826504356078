import styled from 'styled-components';

export const ApptHeaderContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  column-gap: var(--s3);
  row-gap: var(--s1);
  flex-wrap: wrap;
`;
