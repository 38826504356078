import { Col } from 'components/common/Grid';

import { TextChangeHandler } from 'quill';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { PromptWithBeforeUnload } from '../../../../components/Form/PromptWithBeforeUnload';
import { ConfirmDialog } from '../../../../components/Popup/ConfirmDialog/ConfirmDialog';
import WYSIWYG from '../../../../components/WYSIWYG';
import { QuillPreview } from '../../../../components/WYSIWYG/WYSIWYG.styled';
import { Card } from '../../../../components/common';
import { WrappingRow } from '../../../../components/common/BasicStyles.styled';
import { CommonButton } from '../../../../components/common/Forms/Button';
import { FormActionsStyle } from '../../../../components/common/Forms/Forms.styled';
import { CardLoader, Loading } from '../../../../components/common/Loading';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import {
  useGuidelinesTemplate,
  useMutateUpdateGuidelinesTemplate,
} from '../../../../query/storage';
import { Subject, useSubjectListener } from '../../../../utils/subject';
import { getWysiwygHtml } from '../../../../components/WYSIWYG/wysiwyg.utils';

type Props = {
  saveEvent: Subject<void>;
};

export const SiteGuidelines = ({ saveEvent }: Props) => {
  const { t } = useCommonTranslation();

  const [isPreviewOn, setIsPreviewOn] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const { isLoading: isGuidelinesLoading, data } = useGuidelinesTemplate();
  const { mutateAsync: update, isLoading: isGuidelinesUpdating } =
    useMutateUpdateGuidelinesTemplate();
  const isLoading = isGuidelinesLoading || isGuidelinesUpdating;
  const quillRef = useRef<ReactQuill>();
  const quilValue = quillRef.current?.value;

  const onSaveClick = useCallback(async () => {
    const quill = quillRef.current;
    if (!quill) return;
    await update(getWysiwygHtml(quill));
    setIsDirty(false);
  }, [update]);

  useEffect(() => {
    const quill = quillRef.current?.editor;
    if (quill) {
      const handler: TextChangeHandler = () => {
        setIsDirty(true);
      };
      quill.once('text-change', handler);
      return () => {
        quill.off('text-change', handler);
      };
    }
  }, [quillRef.current?.editor]);

  useSubjectListener(
    saveEvent,
    () => {
      if (!isLoading) {
        onSaveClick();
      }
    },
    [isLoading, onSaveClick],
  );

  if (!data) {
    return isGuidelinesLoading ? (
      <WrappingRow style={{ position: 'relative', width: '100%' }}>
        <Loading style={{ marginTop: 'var(--s4)' }} />
      </WrappingRow>
    ) : null;
  }

  return (
    <Col>
      <Card>
        <PromptWithBeforeUnload when={isDirty}></PromptWithBeforeUnload>
        <p>{t('guidelines-description')}</p>
        <WYSIWYG ref={quillRef} defaultValue={data.data || ''} />
        <FormActionsStyle>
          <CommonButton
            variant="secondary"
            type="button"
            onClick={() => setIsPreviewOn(true)}
          >
            {t('preview')}
          </CommonButton>
        </FormActionsStyle>
        {isPreviewOn && (
          <ConfirmDialog
            isLoading={false}
            handleClose={() => setIsPreviewOn(false)}
            description={
              <QuillPreview>
                {
                  <ReactQuill
                    modules={{
                      toolbar: false,
                    }}
                    value={quilValue}
                    readOnly={true}
                    theme="snow"
                  />
                }
              </QuillPreview>
            }
            status="success"
            actions={[
              <CommonButton
                variant="primary"
                onClick={() => setIsPreviewOn(false)}
                key="close-preview"
              >
                {t('close')}
              </CommonButton>,
            ]}
          />
        )}
        {isLoading && <CardLoader fillWrapper={true} />}
      </Card>
    </Col>
  );
};
