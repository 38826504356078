import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';
import { StatisticsInfoItemStyled } from './Reports.styled';

// There is a vertical line between elements, but when they wrap we need to remove the line
// for the last element in the row
export const StatisticsInfoItem = ({ children }: PropsWithChildren<{}>) => {
  const [lastInRow, setLastInRow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  // track the window size to refresh the value on resize
  // track the children since the size can change because of the content
  useEffect(() => {
    const isLastInRow = () => {
      const element = ref.current;
      if (!element || windowSize.width === 0 || !children) return false;
      const nextElement = element.nextSibling as HTMLDivElement | null;
      if (!nextElement) return false;
      if (element.offsetLeft < nextElement.offsetLeft) return false;
      return true;
    };
    setLastInRow(isLastInRow());
  }, [setLastInRow, children, windowSize]);
  return (
    <StatisticsInfoItemStyled ref={ref} lastInRow={lastInRow}>
      {children}
    </StatisticsInfoItemStyled>
  );
};
