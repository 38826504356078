import { DependencyList, useEffect } from 'react';

type Noop = () => void;

export type Observer<T> = {
  next: (value: T) => void;
};

export type Subscription = {
  unsubscribe: Noop;
};

/**
 * Simple Subject inspired by
 * https://github.com/react-hook-form/react-hook-form/blob/master/src/utils/createSubject.ts.
 * It is a very lightweight alternative to https://rxjs.dev/guide/subject
 * and is useful for passing events from parent to child component.
 *
 *  Parent:
 *      const [event] = useState(() => createSubject<void>());
 *      <button onClick={() => event.next()}>Fire event</button>
 *      <Child event={event} />
 *  Child:
 *      useSubjectListener(event, () => { console.log('event handled') }, []);
 */
export type Subject<T> = {
  readonly observers: Observer<T>[];
  subscribe: (value: Observer<T>) => Subscription;
  unsubscribe: Noop;
} & Observer<T>;

export const createSubject = <T>(): Subject<T> => {
  let observers: Observer<T>[] = [];

  const next = (value: T) => {
    for (const observer of observers) {
      observer.next && observer.next(value);
    }
  };

  const subscribe = (observer: Observer<T>): Subscription => {
    observers.push(observer);
    return {
      unsubscribe: () => {
        observers = observers.filter((o) => o !== observer);
      },
    };
  };

  const unsubscribe = () => {
    observers = [];
  };

  return {
    get observers() {
      return observers;
    },
    next,
    subscribe,
    unsubscribe,
  };
};

/**
 * Assign listener to subject.
 * The listener is automatically subscribed and previous
 * version unsubscribed when dependencies are changed.
 * When component is removed the listener is unsubscribed.
 */
export const useSubjectListener = <T>(
  subject: Subject<T>,
  listener: (value: T) => void,
  deps: DependencyList,
) => {
  useEffect(() => {
    const subscription = subject.subscribe({
      next: listener,
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject, ...(deps || [])]);
};
