import React from 'react';
import { StaffAppointmentsCard } from '../appointments/StaffAppointmentsCard';
import { useFlowEditAppointment } from '../../../hooks/staff/useFlowEditAppointment';
import { ResourceType } from '../../../types/resource-types';
import { PatientAppointmentsAndEditPatientControl } from '../appointments/PatientAppointmentsAndEditPatientControl';
import { useAppointmentsFlow } from '../../../hooks/staff/useAppointmentsFlow';

interface IStaffCalendarAppointmentFlowCardProps {
  filteredResourceTypes?: ResourceType[];
  filteredResourceTypesFetching: boolean;
}

export const StaffCalendarAppointmentFlowCard: React.FunctionComponent<
  IStaffCalendarAppointmentFlowCardProps
> = ({ ...filteredResourceTypeInfo }) => {
  const { appointmentsData, ...useAppointmentsDataRest } = useAppointmentsFlow({
    showAbbreviatedNames: true,
  });

  const {
    setOrToggleEditFlowAppointmentID,
    appointment,
    editAppointmentId,
    mode,
    ...rest
  } = useFlowEditAppointment({
    appointmentsData,
  });
  return (
    <>
      <StaffAppointmentsCard
        style={
          mode !== 'closed'
            ? {
                display: 'none',
              }
            : undefined
        }
        setOrToggleEditAppointmentId={setOrToggleEditFlowAppointmentID}
        editAppointmentId={editAppointmentId}
        appointmentsData={appointmentsData}
        {...useAppointmentsDataRest}
        {...filteredResourceTypeInfo}
      />
      {mode !== 'closed' && appointment && (
        <PatientAppointmentsAndEditPatientControl
          appointment={appointment}
          mode={mode}
          {...rest}
        />
      )}
    </>
  );
};
