import React from 'react';
import { CloseButton, FormTitle } from '../../../common/Forms/Forms.styled';
import { FaTimes } from 'react-icons/fa';
import { CardLoader } from '../../../common/Loading';
import { Card, CardHeader } from '../../../common';

interface ICardFormProps {
  onCloseClicked: () => void;
  isLoading?: boolean;
  title: React.ReactNode;
  icon: React.ReactNode;
  scroll?: boolean;
  minHeight?: string;
}

export const AppointmentCard: React.FunctionComponent<ICardFormProps> = ({
  onCloseClicked,
  isLoading = false,
  title,
  icon,
  children,
  scroll,
  minHeight,
}) => {
  return (
    <Card scroll={scroll} minHeight={minHeight}>
      <CardHeader oneLine>
        {icon}
        <FormTitle>{title}</FormTitle>
      </CardHeader>
      <CloseButton
        size="auto"
        variant="primary"
        style={{
          top: 'var(--s3)',
          right: 'var(--s3)',
        }}
        iconOnly
        onClick={() => onCloseClicked()}
      >
        <FaTimes />
      </CloseButton>
      {children}
      {isLoading && <CardLoader fillWrapper={true} />}
    </Card>
  );
};
