import React from 'react';
import { FaUser, FaUserFriends } from 'react-icons/fa';
import { Card, CardHeader } from '../../../../components/common';
import { FormTitle } from '../../../../components/common/Forms/Forms.styled';
import { PageHelmet } from '../../../../components/common/PageHelmet';
import { useAllGPSurgeries } from '../../../../hooks/referrals/useAllGPSurgeries';
import { useHasAdminAccessToSelectedSite } from '../../../../hooks/user/useHasAdminAccessToSelectedSite';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { CommonPatientsTable } from '../../../common/patient/PatientsTable';
import { StaffLayout } from '../../StaffLayout';
import { useStaffMerge } from './staffUsersMergeHooks';
import { Grid4to8 } from '../../../../components/Layout/Grid4to8';
import {
  SOURCE_SELECTION_STATE,
  TARGET_SELECTION_STATE,
} from '../../../../hooks/util/useMergeEntity';
import { MergeCard } from '../../../../components/common/Merge/MergeCard';
import { StaffUsersTabsTree } from '../../../../configs/RoutesConfig';
import { MergePatientSelectBox } from './merge/MergePatientSelectBox';
import { MergeRedInfoBox } from '../../../../components/common/Merge/mergeStyle';

export const StaffUsersMerge: React.FunctionComponent = () => {
  const { t } = useCommonTranslation();
  const { isLoading: isSurgeriesLoading, data: surgeries } =
    useAllGPSurgeries();
  const {
    clearSelection,
    selectionState,
    isUsersLoading,
    isUsersFetching,
    setFilters,
    columns,
    source,
    target,
    toggleSelectionState,
    patients,
    isUsersMergeLoading,
    mergeUsers,
    filters,
    selectedIds,
  } = useStaffMerge();

  const isMergeInfoLoading = isUsersMergeLoading || isSurgeriesLoading;

  let hasAdminAccessToSelectedSite = useHasAdminAccessToSelectedSite();
  return (
    <StaffLayout>
      <PageHelmet title={t('merge-users-title')} />
      <Grid4to8
        leftBox={
          <MergeCard
            tabs={StaffUsersTabsTree(hasAdminAccessToSelectedSite, t)}
            cardHeader={
              <>
                <FaUserFriends />
                <FormTitle>{t('merge-users-title')}</FormTitle>
              </>
            }
            isLoading={isMergeInfoLoading}
            source={source}
            target={target}
            onClearSelection={clearSelection}
            onMerge={mergeUsers}
          >
            <MergePatientSelectBox
              subtitleLabel={t('merge-users-main-patient')}
              surgeries={surgeries}
              user={target}
              isSelecting={selectionState === TARGET_SELECTION_STATE}
              onSelectClicked={() =>
                toggleSelectionState(TARGET_SELECTION_STATE)
              }
            />
            <MergePatientSelectBox
              subtitleLabel={t('merge-users-second-patient')}
              surgeries={surgeries}
              user={source}
              isSelecting={selectionState === SOURCE_SELECTION_STATE}
              onSelectClicked={() =>
                toggleSelectionState(SOURCE_SELECTION_STATE)
              }
            />
            <MergeRedInfoBox>
              {source && target
                ? t('merge-users-ready-info')
                : t('merge-users-select-patients')}
            </MergeRedInfoBox>
          </MergeCard>
        }
        rightBox={
          selectionState && (
            <Card>
              <CardHeader oneLine>
                <FaUser />
                <FormTitle>
                  {selectionState === TARGET_SELECTION_STATE
                    ? t('merge-users-select-main-patient')
                    : t('merge-users-select-second-patient')}
                </FormTitle>
              </CardHeader>
              <CommonPatientsTable
                usersData={patients?.data}
                setFilters={setFilters}
                filters={filters}
                isLoading={isUsersLoading}
                isFetching={isUsersFetching}
                columns={columns}
                selectedIds={selectedIds}
              />
            </Card>
          )
        }
      />
    </StaffLayout>
  );
};
