import { Loading } from 'components/common/Loading';
import { PageHelmet } from 'components/common/PageHelmet';
import { PatientLayout } from 'containers/PatientLayout';
import { usePatientBooking } from 'context/patient/modules/booking';
import {
  getFormEmptyInit,
  useLoadWaitingListForm,
} from 'hooks/patient/waitingList/useWaitingList';
import { WaitingListParams } from 'hooks/patient/waitingList/useWaitingListURL';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { Fragment, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { WaitingListConfirmBack } from './WaitingListConfirmBack';
import { WaitingListForm } from './WaitingListForm';
import { WaitingListHelp } from './WaitingListHelp';
import { WaitingListSuccess } from './WaitingListSuccess';

const pageMap = {
  form: WaitingListForm,
  help: WaitingListHelp,
  success: WaitingListSuccess,
  'confirm-back': WaitingListConfirmBack,
} as const;

export type WaitingListRoute = 'form' | 'help' | 'success' | 'confirm-back';
export const Page = () => {
  const { page = 'form' } = useParams<WaitingListParams>();
  const { isFetching } = useLoadWaitingListForm();
  const PageComponent = pageMap[page];
  return (
    <Fragment>
      {isFetching && <Loading />}
      <PageComponent />
    </Fragment>
  );
};

export const WaitingListPage = () => {
  const { t } = useCommonTranslation();
  const form = useForm({ defaultValues: getFormEmptyInit() });
  const { updateBooking: updatePatientBooking } = usePatientBooking();
  const { memberId } = useParams<WaitingListParams>();

  // this will be used to book an available appointment
  useEffect(() => {
    updatePatientBooking({ memberId });
  }, [updatePatientBooking, memberId]);

  return (
    <PatientLayout graphics="patient">
      <PageHelmet title={t('waiting-list')} />
      <FormProvider {...form}>
        <Page />
      </FormProvider>
    </PatientLayout>
  );
};
