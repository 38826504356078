import { Required } from './Forms.styled';
import React from 'react';

interface RequiredLabelProps {
  label?: React.ReactNode;
  required?: boolean;
}

export const RequiredLabel = ({ label, required }: RequiredLabelProps) => {
  if (!label) {
    return <></>;
  }
  return (
    <>
      {label} {required && <Required>*</Required>}
    </>
  );
};
