import { Card } from 'components/common/index';
import styled from 'styled-components';
import { h3, h4 } from 'styles/Typography';
import { toRem } from 'utils/helpers';
import { FontBasedSize, getFontSize } from '../../../styles/fontsStyleUtils';

export const FormWrapper = styled(Card)``;
export const FormTitle = styled.h1`
  ${h3};
  text-align: center;
  margin-bottom: ${toRem(24)}rem;
`;
export const FormLargeDesc = styled.p`
  margin: 0 0 var(--s3);
  font-size: var(--h4);
`;
export const FormDesc = styled.p`
  margin: 0 0 var(--s3);
`;
export const AutoCompleteLabel = styled.label`
  flex: none;
  gap: var(--s1);
  display: flex;
  align-items: center;
  margin: 0 var(--s2) var(--s3) 0;
`;
export const GreenBoxTitle = styled.div`
  color: var(--primary);
`;

export const CheckboxListWrapper = styled.div`
  display: flex;

  & > div {
    margin-left: var(--s3);
  }
`;

export const GreenBoxWrapper = styled.div<FontBasedSize>`
  background: var(--greentercial50);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: var(--s2);
  border-radius: var(--s2);
  margin-bottom: var(--s3);

  & > span {
    margin: 0;
  }

  column-gap: var(--s3);
  row-gap: var(--s1);
  ${getFontSize('')}
`;
export const CheckboxesWithErrorWrapper = styled.div`
  margin-bottom: var(--s3);
  display: flex;
  flex-direction: column;

  & > ${GreenBoxWrapper} {
    margin-bottom: 0;
  }
`;

export const SuccessWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--greenaccent);
  font-size: ${toRem(96)}rem;

  svg {
    margin-bottom: var(--s3);
  }
`;
export const SuccessMessage = styled.h3`
  ${h3}
  margin-bottom: var(--s3);
`;

export const SignUpHospitalList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--s3);
  overflow: auto;
  margin-bottom: var(--s3);
  padding-bottom: var(--s2);
`;

export const SignUpHospitalWrapper = styled.div`
  border: 0.125rem solid var(--greenaccent);
  border-radius: var(--s3);
  padding: var(--s2) var(--s3);
  display: flex;
  align-items: center;
  gap: var(--s3);
  flex-wrap: wrap;
`;
export const SignUpHospitalName = styled.div`
  ${h3};
  font-weight: 600;
  margin-bottom: var(--s2);
`;

export const SignUpHospitalShortInfo = styled.div`
  ${h4};
  font-weight: normal;
`;

export const MemberFormWrapper = styled.div`
  box-shadow: var(--shadow-3);
  padding: var(--s3) var(--s3) 0 var(--s3);
  border-radius: var(--s3);
  margin-top: var(--s3);
  margin-bottom: var(--s3);
`;

export const MemberFormTitle = styled.h1`
  ${h4};
  text-align: center;
  margin-bottom: ${toRem(24)}rem;
`;
