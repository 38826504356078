import { CommonLink } from 'components/common/Forms/Button';
import { FormActionsStyle } from 'components/common/Forms/Forms.styled';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { patientURL } from 'routes/AppRoutes';
import { MyAppointmentsCommonPage } from './MyAppointmentsCommonPage';
import { PastAppointmentsList } from './PastAppointmentsList';

export const MyAppointmentsPast = () => {
  const { t } = useCommonTranslation();

  return (
    <MyAppointmentsCommonPage title={t('past-appointments-title')}>
      <PastAppointmentsList />
      <FormActionsStyle align="right" topMargin>
        <CommonLink
          variant="primary"
          size="large"
          to={`${patientURL}/my-appointments`}
        >
          {t('back-to-appointments')}
        </CommonLink>
        <CommonLink variant="secondary" size="large" to={patientURL}>
          {t('back-to-menu')}
        </CommonLink>
      </FormActionsStyle>
    </MyAppointmentsCommonPage>
  );
};
