import { CommonButton } from 'components/common/Forms/Button';
import { FormActionsStyle } from 'components/common/Forms/Forms.styled';
import { useSiteStringAttributes } from 'hooks/useAttribute';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { useCallback } from 'react';
import { PRE_CHECK_IN_QUESTION } from '../../../configs/siteAndTrustAttributes';
import { usePatientCheckIn } from '../../../context/patient/modules/checkIn';
import { PatientCheckInLayout } from './PatientCheckInLayout';

export const PatientPreCheckIn = () => {
  const { t } = useCommonTranslation();
  const [preCheckinQuestion] = useSiteStringAttributes(PRE_CHECK_IN_QUESTION);
  const { updateCheckIn } = usePatientCheckIn();
  const onYes = useCallback(
    () => updateCheckIn({ answer: true }),
    [updateCheckIn],
  );
  const onNo = useCallback(
    () => updateCheckIn({ answer: false }),
    [updateCheckIn],
  );

  return (
    <PatientCheckInLayout description={{ html: preCheckinQuestion }}>
      <FormActionsStyle align="center">
        <CommonButton variant="primary" onClick={onYes}>
          {t('yes')}
        </CommonButton>
        <CommonButton variant="secondary" onClick={onNo}>
          {t('no')}
        </CommonButton>
      </FormActionsStyle>
    </PatientCheckInLayout>
  );
};
