import React from 'react';
import { FaHospitalSymbol } from 'react-icons/fa';
import { StaffLayout } from '../../StaffLayout';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { PageHelmet } from '../../../../components/common/PageHelmet';
import { Grid4to8 } from '../../../../components/Layout/Grid4to8';
import { MergeCard } from '../../../../components/common/Merge/MergeCard';
import { StaffSettingsTabsTree } from '../../../../configs/RoutesConfig';
import { FormTitle } from '../../../../components/common/Forms/Forms.styled';
import {
  SOURCE_SELECTION_STATE,
  TARGET_SELECTION_STATE,
  useMergeEntity,
} from '../../../../hooks/util/useMergeEntity';
import { Card } from '../../../../components/common';
import { FetchedDataTable } from '../../../../components/Table/FetchedDataTable';
import { usePaginatedFilters } from '../../../../hooks/usePaginatedFilters';
import { CCGFilters, ClinicalCommissioningGroup } from '../../../../types/ccg';
import { useMutateMergeCCGs, useQueryCCG } from '../../../../query/ccg';
import { useCCGColumns } from '../../../../hooks/referrals/useCCGColumns';
import {
  getFilterControls,
  isNOTCellSelected,
} from '../../../../components/Table/TableUtils';
import { RowActions } from '../../../../components/Table/Table.styled';
import { CommonButton as Button } from '../../../../components/common/Forms/Button';
import { Column } from 'react-table';
import { successToast } from '../../../../utils/toast';
import { MergeRedInfoBox } from 'components/common/Merge/mergeStyle';
import { MergeICBBox } from './merge/MergeICBBox';
import { IDCellPropsWithSelected } from '../../../../types/table';

export const ICBsMerge = () => {
  const { t } = useCommonTranslation();

  const { setFilters, filters } = usePaginatedFilters<CCGFilters>();
  const { isLoading, isFetching, data: ccgData } = useQueryCCG(filters);
  const {
    target,
    selectMergeItem,
    clearSelection,
    toggleSelectionState,
    selectionState,
    source,
    setSource,
    selectedIds,
  } = useMergeEntity({
    entities: ccgData?.data.data,
  });

  const columns = useCCGColumns({
    lastColumn: {
      ...getFilterControls({
        t,
        filters,
        setFilters,
      }),
      Cell: ({
        value,
        ...rest
      }: IDCellPropsWithSelected<ClinicalCommissioningGroup>) => {
        return (
          <RowActions>
            {isNOTCellSelected(rest) && (
              <Button
                size="auto"
                variant="primary"
                onClick={() => selectMergeItem(value)}
              >
                {t('select')}
              </Button>
            )}
          </RowActions>
        );
      },
    } as Column<ClinicalCommissioningGroup>,
  });
  const { mutate: mergeCCGs, isLoading: isMergeCCGsLoading } =
    useMutateMergeCCGs({
      onSuccess: () => {
        setSource(undefined);
        successToast(t('merge-ccgs-success'));
      },
    });

  return (
    <StaffLayout>
      <PageHelmet title={t('ccgs-merge')} />
      <Grid4to8
        leftBox={
          <MergeCard
            tabs={StaffSettingsTabsTree(t)}
            cardHeader={
              <>
                <FaHospitalSymbol />
                <FormTitle>{t('ccgs-merge')}</FormTitle>
              </>
            }
            isLoading={isMergeCCGsLoading}
            onClearSelection={clearSelection}
            onMerge={() =>
              source &&
              target &&
              mergeCCGs({
                sourceId: source.id,
                targetId: target.id,
              })
            }
            target={target}
            source={source}
          >
            <MergeICBBox
              label={t('merge-ccgs-target')}
              onSelectClicked={() =>
                toggleSelectionState(TARGET_SELECTION_STATE)
              }
              isSelecting={selectionState === TARGET_SELECTION_STATE}
              ccg={target}
            />
            <MergeICBBox
              label={t('merge-ccgs-source')}
              ccg={source}
              onSelectClicked={() =>
                toggleSelectionState(SOURCE_SELECTION_STATE)
              }
              isSelecting={selectionState === SOURCE_SELECTION_STATE}
            />
            <MergeRedInfoBox>
              {source && target
                ? t('merge-ccgs-ready-info')
                : t('merge-ccgs-select')}
            </MergeRedInfoBox>
          </MergeCard>
        }
        rightBox={
          selectionState && (
            <Card>
              <FetchedDataTable
                columns={columns}
                data={ccgData?.data}
                isLoading={isLoading}
                filters={filters}
                onSortOrPageChange={setFilters}
                isFetching={isFetching}
                selectedIds={selectedIds}
              />
            </Card>
          )
        }
      />
    </StaffLayout>
  );
};
