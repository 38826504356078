import styled, { css } from 'styled-components';
import {
  breakpoints,
  DEFAULT_COLUMNS,
  MediaBreakpointsNumbers,
  mq,
} from 'styles/sizes';

const colGap = `var(--s3)`;

const colWidth = (col: number) => css`
  min-width: calc(${(100 * col) / DEFAULT_COLUMNS}%);
  max-width: calc(${(100 * col) / DEFAULT_COLUMNS}%);
`;
export const Col = styled.div<
  { readonly col?: number } & Readonly<MediaBreakpointsNumbers>
>`
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  ${({ col }) => colWidth(col || 12)}
  gap: ${colGap};

  ${(props) =>
    Object.keys(props)
      .filter((key) => Object.keys(breakpoints).includes(key))
      .sort((a, b) => {
        const reference_array = Object.keys(breakpoints).reverse();
        return reference_array.indexOf(a) - reference_array.indexOf(b);
      })
      .map((q) => {
        const bp = q as keyof typeof breakpoints;
        const columnsVal = props[bp] as number;
        return css`
          ${mq[bp]} {
            ${colWidth(columnsVal)}
          }
        `;
      })}
`;
export const Grid = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  row-gap: calc(2 * var(--s3));
`;
