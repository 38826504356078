import { CommonButton } from 'components/common/Forms/Button';
import { useBookPatientAppointment } from 'hooks/patient/booking/useBookPatientAppointment';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { Appointment } from 'types/appointments';
import { getApptRangeStr } from 'utils/appointmentUtil';
import {
  DATE_FNS_DATE_WRITTEN,
  DateOnlyISOString,
  formatDateKeepTZ,
} from 'utils/dateUtil';
import {
  PatientBookingFirstApptsItemStyled,
  PatientBookingFirstApptsStyled,
  PatientBookingHint,
  PatientBookingHintText,
  PatientBookingSuggestionDate,
  PatientBookingSuggestionDatetime,
  PatientBookingSuggestionTime,
} from '../PatientBooking.styled';
import { usePatientBookingFirstAppointments } from '../../../../hooks/patient/booking/usePatientBookingFirstAppointments';
import { FaInfoCircle } from 'react-icons/fa';

const PatientBookingFirstApptsItem = ({ appt }: { appt: Appointment }) => {
  const { t } = useCommonTranslation();
  const bookAppt = useBookPatientAppointment(appt);

  return (
    <PatientBookingFirstApptsItemStyled key={appt.id}>
      <PatientBookingSuggestionDatetime>
        <PatientBookingSuggestionDate>
          {formatDateKeepTZ(appt.appt_time, DATE_FNS_DATE_WRITTEN)}
        </PatientBookingSuggestionDate>
        <PatientBookingSuggestionTime>
          {getApptRangeStr(appt)}
        </PatientBookingSuggestionTime>
      </PatientBookingSuggestionDatetime>
      <CommonButton variant="primary" size="standard" onClick={bookAppt}>
        {t('book')}
      </CommonButton>
    </PatientBookingFirstApptsItemStyled>
  );
};

type PatientBookingFirstApptsProps = {
  availableDays?: DateOnlyISOString[];
};

export const PatientBookingFirstAppts = ({
  availableDays,
}: PatientBookingFirstApptsProps) => {
  const { firstApptsAvailable } = usePatientBookingFirstAppointments({
    availableDaysArray: availableDays,
    idealNumberToShow: 3,
  });
  const { t } = useCommonTranslation();

  return (
    <>
      <PatientBookingHint>
        <FaInfoCircle />
        <PatientBookingHintText>
          {t('first-appointments')}
        </PatientBookingHintText>
      </PatientBookingHint>
      <PatientBookingFirstApptsStyled>
        {firstApptsAvailable.map((appt) => (
          <PatientBookingFirstApptsItem appt={appt} key={appt.id} />
        ))}
      </PatientBookingFirstApptsStyled>
    </>
  );
};
