import { FormState } from 'react-hook-form';

/**
 * Working alternative to FormState.isDirty property.
 * See https://github.com/react-hook-form/react-hook-form/issues/3213
 * or https://github.com/react-hook-form/react-hook-form/issues/4740.
 */
export const useIsDirty = ({ isDirty, dirtyFields }: FormState<any>) => {
  return isDirty && Object.keys(dirtyFields).length > 0;
};
