import styled from 'styled-components';
import { toRem } from 'utils/helpers';

export const TimePlanningWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: var(--s2) 0 var(--s3) 0;
  border-bottom: ${toRem(2)}rem solid var(--greenaccent);
  position: relative;
`;
