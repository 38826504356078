import { Context, createContext, FC, useContext, useState } from 'react';
import { SetState } from 'types/SetState';
import { UpdateState, useMakeUpdateState } from './updateState';

// The factory for a state context
// stores an object <State> and provides getters/setters (and provider)
export type StateContextParams<State> = {
  initState: () => State;
  displayName?: string;
};

export type StateContext<State> = {
  Provider: FC;
  useState: () => State;
  useUpdateState: () => UpdateState<State>;
  useSetState: () => SetState<State>;
};

export type StateContextValue<State> = [State, SetState<State>];

const makeUseContext = <Value,>(Context: Context<Value | null>) => {
  return () => {
    const value = useContext(Context);
    if (value === null)
      throw new Error(
        `${Context.displayName} is called outside of the provider`,
      );
    return value;
  };
};

const makeProvider = <State,>(
  Context: Context<StateContextValue<State> | null>,
  initState: () => State,
) => {
  const Provider: FC = ({ children }) => {
    const [state, setState] = useState(initState());
    return (
      <Context.Provider value={[state, setState]}>{children}</Context.Provider>
    );
  };
  return Provider;
};

export const makeStateContext = <State extends object>(
  params: StateContextParams<State>,
): StateContext<State> => {
  const Context = createContext<StateContextValue<State> | null>(null);
  Context.displayName = params.displayName;
  const useStateContext = makeUseContext(Context);
  const Provider = makeProvider(Context, params.initState);
  const useStateValue = () => useStateContext()[0];
  const useSetState = () => useStateContext()[1];
  const useUpdateState = () => useMakeUpdateState(useSetState());

  return {
    Provider,
    useState: useStateValue,
    useUpdateState,
    useSetState,
  };
};
