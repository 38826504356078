import React, { ReactElement } from 'react';
import { StyledPopup } from './ConfirmDialog.styled';
import { InlineConfirm } from './InlineConfirm';

export interface ConfirmDialogProps {
  handleClose?: () => void;
  icon?: React.ReactNode;
  title?: string;
  isLoading: boolean;
  description?: string | React.ReactNode;
  status: 'delete' | 'success' | 'warning';
  actions: React.ReactNode[];
  inlineIcon?: boolean;
  actionsTopMargin?: boolean;
}

export const ConfirmDialog: React.FunctionComponent<ConfirmDialogProps> = ({
  handleClose,
  isLoading,
  ...rest
}): ReactElement => (
  <StyledPopup
    open={true}
    closeOnDocumentClick={!isLoading}
    onClose={handleClose}
  >
    <InlineConfirm isLoading={isLoading} {...rest} />
  </StyledPopup>
);
