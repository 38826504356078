import styled from 'styled-components';

export const AppointmentRow = styled.div`
  display: flex;

  & > div {
    flex: 1;
    margin-right: var(--s3);

    &:last-child {
      margin-right: 0;
    }
  }
`;
