import {
  DeepPartial,
  FieldErrors,
  ResolverResult,
  UnpackNestedValue,
} from 'react-hook-form';

import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import {
  getServerTime,
  isBefore,
  isSameDay,
  parseIsoDate,
  startOfDay,
} from '../../../../utils/dateUtil';

export type MassCancelFormValues = {
  fromDate: string;
  toDate: string;
  fromHour: number;
  toHour: number;
  resourceTypeId: string;
  clearApptTemplate: boolean;
};

export const useMassCancelValidation = ({
  hasMultipleResourceTypes,
}: {
  hasMultipleResourceTypes: boolean;
}) => {
  const { t } = useCommonTranslation();
  return (
    submitValues: UnpackNestedValue<MassCancelFormValues>,
  ): ResolverResult<MassCancelFormValues> => {
    const errors: DeepPartial<FieldErrors<MassCancelFormValues>> = {};
    const serverNow = getServerTime();
    const todayStartOfDay = startOfDay(serverNow);
    let fromDate: Date | undefined;
    if (!submitValues.fromDate) {
      errors.fromDate = { message: t('must-not-empty') };
    } else {
      fromDate = parseIsoDate(submitValues.fromDate);
      if (isBefore(fromDate, todayStartOfDay)) {
        errors.fromDate = {
          message: t('must-not-be-in-past'),
        };
      }
    }

    if (!submitValues.toDate) {
      errors.toDate = { message: t('must-not-empty') };
    } else {
      const toDate = parseIsoDate(submitValues.toDate);
      if (isBefore(toDate, todayStartOfDay)) {
        errors.toDate = {
          message: t('must-not-be-in-past'),
        };
      } else if (fromDate && isBefore(toDate, fromDate)) {
        errors.toDate = {
          message: t('must-be-same-or-after', { column: t('date-from') }),
        };
      }
    }

    if (typeof submitValues.fromHour !== 'number') {
      errors.fromHour = { message: t('must-not-empty') };
    } else if (
      fromDate &&
      isSameDay(fromDate, todayStartOfDay) &&
      submitValues.fromHour <= serverNow.getHours()
    ) {
      errors.fromHour = { message: t('must-be-in-future') };
    }

    if (typeof submitValues.toHour !== 'number') {
      errors.toHour = { message: t('must-not-empty') };
    }

    if (
      typeof submitValues.fromHour === 'number' &&
      typeof submitValues.toHour === 'number' &&
      submitValues.fromHour >= submitValues.toHour
    ) {
      errors.toHour = {
        message: t('must-be-after', { column: t('hour-from') }),
      };
    }

    if (hasMultipleResourceTypes && !submitValues.resourceTypeId) {
      errors.resourceTypeId = { message: t('must-not-empty') };
    }

    return {
      values: submitValues,
      errors,
    };
  };
};
