import styled from 'styled-components';
import { toRemStr } from '../../../utils/helpers';

export const MergeItemContainerStyle = styled.div<{
  isSelecting: boolean;
}>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: ${toRemStr(2)} solid transparent;
  border-radius: var(--s3);
  padding: var(--s2);
  ${({ isSelecting }) => (isSelecting ? 'border-color: var(--redaccent);' : '')}
`;

export const MergePatientSelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: var(--s2);
  margin-bottom: var(--s2);
`;

export const MergeRedInfoBox = styled.div`
  display: flex;
  justify-content: center;
  background-color: var(--rednegative);
  color: var(--white);
  margin-top: var(--s3);
  margin-bottom: var(--s3);
  padding: var(--s3);
  border-radius: var(--s3);
`;
