import { PageHelmet } from 'components/common/PageHelmet';
import { PatientLayout } from 'containers/PatientLayout';
import {
  usePatientBooking,
  useResetPatientBookingOnUnmount,
} from 'context/patient/modules/booking';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';

import { Card, CardHeader } from '../../../components/common';
import { PopupIconWrapper } from '../../../components/Popup/ConfirmDialog/ConfirmDialog.styled';
import { PatientBookingButtonBar } from './PatientBookingButtonBar';
import { PatientBookingFor } from './PatientBookingFor';
import { usePatientBookingLayout } from './PatientBookingPageHooks';
import { PatientBookingStageBar } from './PatientBookingStageBar';

export const PatientBookingPage = () => {
  const { t } = useCommonTranslation();
  const {
    booking: { stage },
  } = usePatientBooking();
  const { element, standalone, icon, showStageBar, showButtonBar } =
    usePatientBookingLayout(stage);
  useResetPatientBookingOnUnmount();

  return (
    <PatientLayout graphics="patient">
      <PageHelmet title={t('book-an-appointment')} />
      {standalone ? (
        element
      ) : (
        <Card>
          {icon && <PopupIconWrapper inlineIcon>{icon}</PopupIconWrapper>}
          <CardHeader>
            <PatientBookingFor />
            {showStageBar && <PatientBookingStageBar />}
          </CardHeader>
          {element}
          {showButtonBar && <PatientBookingButtonBar />}
        </Card>
      )}
    </PatientLayout>
  );
};
