import { CommonButton } from 'components/common/Forms/Button';
import DatePickerInput from 'components/common/Forms/DatePickerInput';
import Input from 'components/common/Forms/Input';
import React, { ReactElement, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { LABEL_BEFORE } from '../../../../styles/fontsStyleUtils';
import {
  CalendarHeaderContentWrapper,
  CalendarHeaderWrapper,
  CalendarInputWrapper,
  SelectedPatientWrapper,
} from './calendar.styled';
import { CalendarHeaderLegend } from './CalendarHeaderLegend';
import {
  CalendarHeaderUnderLine,
  CalendarHeaderUnderLineProps,
} from './CalendarHeaderUnderLine';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { UserData } from '../../../../types/users';

type Props = {
  selectedPatient: UserData | undefined;
  clearSelectedPatient: () => void;
  showPatientSearch: (searchPatientKeys: SearchPatientKeys) => void;
} & CalendarHeaderUnderLineProps;

export interface SearchPatientKeys {
  lastname?: string;
  birthDate?: string;
}

const CalendarHeader = ({
  filteredResourceTypes,
  selectedPatient,
  clearSelectedPatient,
  showPatientSearch,
  ...rest
}: Props): ReactElement => {
  const { t } = useCommonTranslation();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SearchPatientKeys>({
    // must be present to allow reset it
    defaultValues: {
      lastname: '',
    },
  });

  const submitHandler = useCallback(
    ({ lastname, ...restValues }: SearchPatientKeys) => {
      showPatientSearch({
        // do not send empty string to backend
        lastname: (lastname || '').trim() === '' ? undefined : lastname,
        ...restValues,
      });
      reset();
    },
    [reset, showPatientSearch],
  );

  return (
    <>
      <CalendarHeaderWrapper onSubmit={handleSubmit(submitHandler)}>
        <CalendarHeaderContentWrapper>
          <div
            style={{
              display: 'flex',
              gap: 'var(--s3)',
              flex: 1,
            }}
          >
            <CalendarInputWrapper>
              <Input
                labelPosition={LABEL_BEFORE}
                helperText={t('calendar-surname-helper')}
                label={t('surname')}
                hasError={Boolean(errors.lastname)}
                {...register('lastname')}
              />
            </CalendarInputWrapper>
            <CalendarInputWrapper>
              <Controller
                control={control}
                name="birthDate"
                render={({ field }) => {
                  return (
                    <DatePickerInput
                      labelPosition={LABEL_BEFORE}
                      name="birthDate"
                      label={t('date-of-birth')}
                      helperText="DD.MM.YYYY"
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      id={'patient_birth_date'}
                      selected={field.value}
                      hideDateFormatInLabel={true}
                    />
                  );
                }}
              />
            </CalendarInputWrapper>
            <CommonButton variant="primary" type="submit">
              {t('find-patient')}
            </CommonButton>
          </div>
          {Boolean(selectedPatient) && (
            <SelectedPatientWrapper>
              <p>
                {t('selected-patient')}:{' '}
                {selectedPatient?.firstname + ' ' + selectedPatient?.lastname}
              </p>
              <CommonButton
                type="button"
                variant="danger"
                onClick={clearSelectedPatient}
              >
                {t('clear')}
              </CommonButton>
            </SelectedPatientWrapper>
          )}
        </CalendarHeaderContentWrapper>
        <CalendarHeaderLegend />
      </CalendarHeaderWrapper>
      <CalendarHeaderUnderLine
        {...rest}
        filteredResourceTypes={filteredResourceTypes}
      />
    </>
  );
};

export default CalendarHeader;
