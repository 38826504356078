import { Card, CardHeader } from 'components/common';
import { CommonLink } from 'components/common/Forms/Button';
import { FormTitle } from 'components/common/Forms/Forms.styled';
import { PageHelmet } from 'components/common/PageHelmet';
import ChangePasswordForm from 'components/views-components/patient/profile/ChangePasswordForm';
import { PatientLayout } from 'containers/PatientLayout';
import { ReactElement, useState } from 'react';
import { FaCheck, FaLock } from 'react-icons/fa';
import { patientURL } from 'routes/AppRoutes';
import { InlineConfirm } from '../../../../components/Popup/ConfirmDialog/InlineConfirm';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { toRem } from '../../../../utils/helpers';

export default function ProfileChangePassword(): ReactElement {
  const { t } = useCommonTranslation();
  const [isFormSuccess, setIsFormSuccess] = useState(false);

  return (
    <PatientLayout graphics="patient">
      <PageHelmet title={t('change-password')} />
      {!isFormSuccess ? (
        <Card>
          <CardHeader>
            <FaLock />
            <FormTitle>{t('change-password')}</FormTitle>
          </CardHeader>
          <ChangePasswordForm
            setIsFormSuccess={setIsFormSuccess}
            backButton={
              <CommonLink
                type="button"
                variant="secondary"
                style={{ minWidth: `${toRem(160)}rem` }}
                to={patientURL}
              >
                {t('back-to-menu')}
              </CommonLink>
            }
          />
        </Card>
      ) : (
        <InlineConfirm
          isLoading={false}
          icon={<FaCheck />}
          title={t('password-changed')}
          description={t('password-changed-desc')}
          status="success"
          actions={[
            <CommonLink variant="primary" to={`${patientURL}/profile/settings`}>
              {t('ok')}
            </CommonLink>,
          ]}
        />
      )}
    </PatientLayout>
  );
}
