import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { Card } from 'components/common';
import { Col, Grid } from 'components/common/Grid';
import { CardLoader } from 'components/common/Loading';
import { ProtectedRoute } from 'components/common/ProtectedRoute';
import { AdminNavbarDropdowns, AdminTextsTabsTree } from 'configs/RoutesConfig';
import { DefaultLayout } from 'components/Layout/DefaultLayout';
import { useAuth } from 'context/AuthContext';
import { useRole } from 'context/RoleContext';
import { lazy, Suspense, useMemo } from 'react';
import { Redirect, Switch } from 'react-router';
import { GET_PATIENT_RELEASENOTE_ROLES } from '../../configs/constants';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';

const PatientRelease = lazy(
  () => import('components/views-components/admin/texts/PatientRelease'),
);
const StaffRelease = lazy(
  () => import('components/views-components/admin/texts/StaffRelease'),
);
const GDPRRelease = lazy(
  () => import('components/views-components/admin/texts/GDPRRelease'),
);
const SupportText = lazy(
  () => import('components/views-components/admin/texts/SupportText'),
);

export const TextsPage = () => {
  const { sangixUser, logout } = useAuth();
  const { t } = useCommonTranslation();
  const { hasAnyOfRoles } = useRole();

  const navbarDropdowns = useMemo(
    () => AdminNavbarDropdowns(sangixUser, logout),
    [logout, sangixUser],
  );

  return (
    <DefaultLayout
      navbarDropdowns={navbarDropdowns}
      showNHSLogo={false}
      noReleaseNotes={true}
    >
      <Grid>
        <Col>
          <Card>
            <LinkCardTabs tabs={AdminTextsTabsTree(t)} withDivider={true} />
            <Suspense fallback={<CardLoader />}>
              <Switch>
                {hasAnyOfRoles(...GET_PATIENT_RELEASENOTE_ROLES) && (
                  <ProtectedRoute
                    exact
                    path="/admin/texts/patient"
                    component={PatientRelease}
                  />
                )}
                <ProtectedRoute
                  exact
                  path="/admin/texts/staff"
                  component={StaffRelease}
                />
                <ProtectedRoute
                  exact
                  path="/admin/texts/gdpr"
                  component={GDPRRelease}
                />
                <ProtectedRoute
                  exact
                  path="/admin/texts/support"
                  component={SupportText}
                />
                <Redirect to="/admin/texts/patient" />
              </Switch>
            </Suspense>
          </Card>
        </Col>
      </Grid>
    </DefaultLayout>
  );
};
