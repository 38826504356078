import React from 'react';
import { InfoLabelText } from '../../../common/Forms/InfoLabelText';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { AppointmentRow } from './appointmentStyles';
import {
  Appointment,
  AppointmentAuditItem,
} from '../../../../types/appointments';
import { useAppointmentStateWorkflow } from '../../../../hooks/appointments/useAppointmentStateWorkflow';
import {
  useAppointmentAuditTransition,
  useAppointmentBookedByAudit,
} from '../../../../hooks/useAppointmentAuditTransition';
import {
  formatDateTimeToMinutesSite,
  parseDateTimeOnly,
} from '../../../../utils/dateUtil';

interface IAppointmentUserDetailsProps {
  appointment: Appointment;
  audit?: AppointmentAuditItem[];
}

export const ExistingAppointmentWorkflowDetails: React.FunctionComponent<
  IAppointmentUserDetailsProps
> = ({ appointment, audit }) => {
  const { t } = useCommonTranslation();
  const {
    isInProcessingStateOrAfter,
    isInCalledStateOrAfter,
    isTakenOrNotTaken,
  } = useAppointmentStateWorkflow({
    appointment,
  });

  const bookedByAudit = useAppointmentBookedByAudit({ auditItems: audit });
  const [calledAudit, processedAudit] = useAppointmentAuditTransition({
    auditItems: audit,
    statuses: ['CALLED', 'PROCESSING'],
    position: 'last',
  });

  return (
    <div
      style={{
        marginTop: 'var(--s3)',
      }}
    >
      {isInProcessingStateOrAfter && (
        <AppointmentRow>
          <InfoLabelText
            label={t('appointment-phlebotomist')}
            text={appointment.staff_full_name}
          />
          <InfoLabelText
            label={t('appointment-check-in-time')}
            text={formatDateTimeToMinutesSite(
              parseDateTimeOnly(appointment.checkin_time),
            )}
          />
        </AppointmentRow>
      )}
      {isInCalledStateOrAfter && (
        <AppointmentRow>
          <InfoLabelText
            label={t('appointment-call-time')}
            text={
              calledAudit &&
              formatDateTimeToMinutesSite(
                parseDateTimeOnly(calledAudit.event_time),
              )
            }
          />
          <InfoLabelText
            label={t('appointment-processed-time')}
            text={
              processedAudit &&
              formatDateTimeToMinutesSite(
                parseDateTimeOnly(processedAudit.event_time),
              )
            }
          />
        </AppointmentRow>
      )}
      <AppointmentRow>
        <InfoLabelText
          label={t('appointment-booked-by')}
          text={bookedByAudit?.user_full_name}
        />
        <InfoLabelText
          label={t('appointment-booked-on')}
          text={
            bookedByAudit &&
            formatDateTimeToMinutesSite(
              parseDateTimeOnly(bookedByAudit.event_time),
            )
          }
        />
      </AppointmentRow>

      {isTakenOrNotTaken && (
        <AppointmentRow>
          <InfoLabelText
            label={t('appointment-tubes')}
            text={appointment.tubes}
          />
          <InfoLabelText
            label={t('appointment-forms')}
            text={appointment.forms}
          />
        </AppointmentRow>
      )}
    </div>
  );
};
