import React, { ReactElement } from 'react';
import { Appointment } from 'types/appointments';
import { AppointmentSlotStyle } from './calendar.styled';
import { DateTimeToMinuteOnlyISOString } from '../../../../utils/dateUtil';

interface Props {
  appointments: Appointment[];
  handleClickTimeSlot: (slot: DateTimeToMinuteOnlyISOString) => void;
  timeslotDateISO: DateTimeToMinuteOnlyISOString;
  isSelected?: boolean;
}

function getSlotFreeAppointmentsWithState(
  appointments: Appointment[],
  isSelected: boolean | undefined,
): {
  freeAppointments: Appointment[];
  appointmentsState: 'ONLINE_AVAILABLE' | 'STAFF_ONLY_AVAILABLE' | 'ALL_BOOKED';
} {
  const freeAppts = appointments.filter((a) => a.status === 'FREE');
  if (freeAppts.length > 0) {
    if (freeAppts.every((a) => a.staff_only)) {
      return {
        appointmentsState: 'STAFF_ONLY_AVAILABLE',
        freeAppointments: freeAppts,
      };
    }
    return {
      freeAppointments: freeAppts,
      appointmentsState: 'ONLINE_AVAILABLE',
    };
  }
  return {
    freeAppointments: [],
    appointmentsState: 'ALL_BOOKED',
  };
}

const CalendarAppointmentSlot = ({
  isSelected,
  appointments,
  handleClickTimeSlot,
  timeslotDateISO,
}: Props): ReactElement => {
  const slotAppointmentsState = getSlotFreeAppointmentsWithState(
    appointments,
    isSelected,
  );
  return (
    <AppointmentSlotStyle
      slotType={
        isSelected ? 'SELECTED' : slotAppointmentsState.appointmentsState
      }
      type="button"
      onClick={() =>
        slotAppointmentsState.freeAppointments.length > 0 &&
        handleClickTimeSlot(timeslotDateISO)
      }
    >
      {slotAppointmentsState.freeAppointments.length}
    </AppointmentSlotStyle>
  );
};
export default CalendarAppointmentSlot;
