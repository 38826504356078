import { FormRow } from 'components/common/Forms/Forms.styled';
import { ReactElement, useMemo } from 'react';
import { FormDesc, FormTitle } from './SignupForm.styled';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { getHookFormTyped } from '../../../../utils/helpers';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useUserValidationRules } from '../../../../hooks/patient/useUserValidationRules';
import { TrustData } from '../../../../types/trusts';
import DatePickerInput from '../../../common/Forms/DatePickerInput';
import { SelectOption } from '../../../../types/common';
import {
  NhsPostcodeDisabilitiesForm,
  PatientNhsPostcodeDisabilitiesData,
} from '../../../../containers/common/patient/PatientNhsPostcodeDisabilitiesData';
import { DateISOString } from '../../../../utils/dateUtil';
import CommonSelect from '../../../common/Forms/Select';
import { LABEL_BEFORE } from '../../../../styles/fontsStyleUtils';
import { SiteData } from '../../../../types/sites';
import { gpSurgeryRequiredTrust } from '../../../../utils/trustUtil';

type SubFormType = {
  birthdate?: DateISOString;
  gp_surgery_id?: string;
} & NhsPostcodeDisabilitiesForm;

interface Props<T extends SubFormType> {
  completeRegister?: boolean;
  control: Control<T>;
  errors: FieldErrors<T>;
  register: UseFormRegister<T>;
  isLoading: boolean;
  trust: TrustData;
  gpOptions: SelectOption[];
  site: SiteData;
}

export const SignupStep3PatientInfo = <T extends SubFormType>({
  completeRegister,
  isLoading,
  trust,
  gpOptions,
  site,
  ...rest
}: Props<T>): ReactElement => {
  const { t } = useCommonTranslation();
  const { register, errors, control } = getHookFormTyped<SubFormType, T>(rest);
  const { birthdateRules, gpRules } = useUserValidationRules({
    trusts: [trust],
  });

  const otherGPName = useMemo(
    () =>
      gpOptions.find((option) => option.value === trust.unknown_gp_surgery_id)
        ?.label,
    [gpOptions, trust],
  );

  return (
    <>
      <FormTitle>
        {t('lets-get-started') + ' (' + site.short_name + ')'}
      </FormTitle>
      <FormRow>
        <Controller
          control={control}
          name="birthdate"
          rules={birthdateRules}
          render={({ field }) => {
            return (
              <DatePickerInput
                name="birthdate"
                label={t('date-of-birth')}
                placeholder={t('date-of-birth')}
                onChange={(value) => {
                  field.onChange(value);
                }}
                disabled={isLoading}
                id={'new_birthdate'}
                maxDate={new Date()}
                hasError={Boolean(errors.birthdate)}
                helperText={errors.birthdate?.message}
                selected={field.value}
                required
              />
            );
          }}
        />
      </FormRow>
      <PatientNhsPostcodeDisabilitiesData
        register={register}
        control={control}
        errors={errors}
        trusts={[trust]}
      />
      {gpSurgeryRequiredTrust(trust) && (
        <>
          <FormDesc
            style={{
              padding: '0 var(--s1)',
            }}
          >
            {t('general-practice-help', {
              other_gp_label: otherGPName,
            })}
          </FormDesc>
          <FormRow>
            <Controller
              name="gp_surgery_id"
              render={({ field }) => (
                <CommonSelect
                  {...field}
                  labelPosition={LABEL_BEFORE}
                  label={t('general-practice')}
                  placeholder={t('general-practice-placeholder')}
                  disabled={!gpOptions}
                  options={gpOptions}
                  helperText={errors.gp_surgery_id?.message}
                  hasError={Boolean(errors.gp_surgery_id)}
                  onChange={(val) => {
                    field.onChange(val);
                  }}
                  required
                />
              )}
              control={control}
              rules={gpRules}
            />
          </FormRow>
        </>
      )}
    </>
  );
};
