import {
  ATTRIBUTE_SEPARATOR,
  CHECKBOX_ATTRIBUTE_SEPARATOR,
} from 'configs/constants';
import { useEffect } from 'react';
import {
  Controller,
  ControllerProps,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  SubmitHandler,
  UnpackNestedValue,
  UseFormRegisterReturn,
  UseFormReturn,
} from 'react-hook-form';
import { GlobalAttribute } from 'types/common';

export type UseAttributesFormReturn<T extends FieldValues = FieldValues> =
  UseFormReturn<T> & {
    attributeRegister: (
      name: string,
      onChange: React.ChangeEventHandler<HTMLInputElement>,
      options?: RegisterOptions<any, string>,
      multiCheckbox?: boolean,
    ) => UseFormRegisterReturn;
    withAttributes: (cb: SubmitHandler<T>) => (data: any) => any;
    handleChange: (name: any, value: any, multiCheckbox?: boolean) => void;
    AttrController: (props: ControllerProps<any>) => JSX.Element;
  };

function useAttributesForm<T extends FieldValues = FieldValues>({
  theForm,
  currentAttrs = [],
  restAttributes,
  dep,
}: {
  theForm: UseFormReturn<T>;
  currentAttrs?: GlobalAttribute[];
  restAttributes: GlobalAttribute[];
  dep: any[];
}): UseAttributesFormReturn<T> {
  const { register, control } = theForm;
  const mergedKeys = [
    ...Array.from(
      new Set([...currentAttrs, ...restAttributes].map((a) => a.key)),
    ),
  ];
  const attributes: GlobalAttribute[] = mergedKeys.map((key: string) => {
    const isExist = currentAttrs.findIndex((attr) => attr.key === key);
    if (isExist >= 0) {
      return currentAttrs[isExist];
    } else {
      const index = restAttributes.findIndex((attr) => attr.key === key);
      return restAttributes[index];
    }
  });

  const handleChange = (name: any, value: any, multiCheckbox?: boolean) => {
    const strippedName = name
      .replace(ATTRIBUTE_SEPARATOR, '')
      .replace(CHECKBOX_ATTRIBUTE_SEPARATOR, '');
    const theValue: () => string | string[] = () => {
      if (multiCheckbox) {
        const attrValue: any = theForm.getValues(name) || [];
        const valueIndex = attrValue ? attrValue.indexOf(value) : [];
        const temp = [...attrValue];
        if (valueIndex === -1) {
          temp.push(value);
        } else {
          temp.splice(valueIndex, 1);
        }
        return temp.sort();
      } else {
        return value;
      }
    };

    const generateAttrs = (
      theForm.getValues('attributes' as Path<T>) as GlobalAttribute[]
    ).map((attr: GlobalAttribute) => {
      if (attr.key !== strippedName) return attr;
      else {
        const attrValue = multiCheckbox
          ? attr.value_str
            ? attr.value_str.split(',')
            : []
          : [];
        const valueIndex = attrValue.indexOf(value);
        const temp = [...attrValue];
        if (valueIndex === -1) {
          temp.push(value);
        } else {
          temp.splice(valueIndex, 1);
        }
        return multiCheckbox
          ? {
              ...attr,
              value_str: temp.sort().join(),
              value_int: Boolean(temp.length) ? 1 : 0,
            }
          : {
              ...attr,
              value_int:
                typeof value === 'boolean'
                  ? +value
                  : typeof value === 'string'
                  ? isNaN(Number(value))
                    ? 1
                    : Number(value)
                  : Boolean(value)
                  ? value
                  : 0,
              value_str:
                value !== null && value !== undefined ? String(value) : '',
            };
      }
    });
    theForm.setValue(name, theValue() as any, {
      shouldValidate: true,
      shouldDirty: true,
    });
    theForm.setValue('attributes' as Path<T>, generateAttrs as any);
  };

  const attributeRegister: (
    name: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    options?: RegisterOptions<any, string>,
    multiCheckbox?: boolean,
  ) => UseFormRegisterReturn = (name, onChange, options, multiCheckbox) => {
    return {
      ...register(
        ((multiCheckbox ? CHECKBOX_ATTRIBUTE_SEPARATOR : ATTRIBUTE_SEPARATOR) +
          name) as Path<T>,
        options,
      ),
      onChange: (e: any) => {
        const value = onChange(e) as UnpackNestedValue<PathValue<T, Path<T>>>;
        handleChange(
          ((multiCheckbox
            ? CHECKBOX_ATTRIBUTE_SEPARATOR
            : ATTRIBUTE_SEPARATOR) + name) as Path<T>,
          value,
          multiCheckbox,
        );
        return e;
      },
    };
  };

  const withAttributes = (cb: SubmitHandler<T>) => (data: any) => {
    const formattedData = Object.keys(data).reduce((acc: any, curr: string) => {
      if (
        !curr.includes(ATTRIBUTE_SEPARATOR) &&
        !curr.includes(CHECKBOX_ATTRIBUTE_SEPARATOR)
      )
        acc[curr] = data[curr];
      return acc;
    }, {});
    return cb(formattedData);
  };

  useEffect(() => {
    setTimeout(() => {
      theForm.setValue(
        'attributes' as Path<T>,
        attributes as UnpackNestedValue<PathValue<T, Path<T>>>,
      );
      const values = theForm.getValues();
      Object.keys(values)
        .filter(
          (key) =>
            key.includes(ATTRIBUTE_SEPARATOR) ||
            key.includes(CHECKBOX_ATTRIBUTE_SEPARATOR),
        )
        .forEach((key) => {
          if (!key.includes(CHECKBOX_ATTRIBUTE_SEPARATOR)) {
            theForm.setValue(
              key as Path<T>,
              attributes.find(
                (attr) => attr.key === key.replace(ATTRIBUTE_SEPARATOR, ''),
              )?.value_str as UnpackNestedValue<PathValue<T, Path<T>>>,
            );
          } else {
            theForm.setValue(
              key as Path<T>,
              (attributes
                .find(
                  (attr) =>
                    attr.key === key.replace(CHECKBOX_ATTRIBUTE_SEPARATOR, ''),
                )
                ?.value_str?.split(',') || []) as UnpackNestedValue<
                PathValue<T, Path<T>>
              >,
            );
          }
        });
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dep]);

  return {
    ...theForm,
    register,
    attributeRegister,
    withAttributes,
    control,
    handleChange,
    AttrController: ({ name, ...rest }: ControllerProps<any>) => (
      <Controller name={ATTRIBUTE_SEPARATOR + name} {...rest} />
    ),
  };
}

export default useAttributesForm;
