import React, { useEffect } from 'react';
import { UserFormValues } from '../../../types/users';
import { AppointmentCard } from '../../../components/views-components/staff/appointments/AppointmentCard';
import { FormBody } from '../../../components/common/Forms/Forms.styled';
import { StaffOrPatientFormData } from '../../../components/views-components/staff/settings/users/StaffOrPatientFormData';
import { useForm } from 'react-hook-form';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { EditAppointmentData } from '../../../components/views-components/staff/appointments/EditAppointmentData';
import { DuplicatePatientDialog } from './DuplicatePatientDialog';
import { ButtonSetWithCloseButton } from '../../../components/Form/ButtonSetWithCloseButton';
import { CommonButton as Button } from '../../../components/common/Forms/Button';
import { FaUserClock } from 'react-icons/fa';
import { useSite } from '../../../context/SiteContext';
import { CardSubtitle } from '../../../components/common';
import { OverflowAppointmentForm, useOverflowNoPatient } from './overflowHooks';
import { OverflowTimeSelect } from './OverflowTimeSelect';

interface IAppointmentFormProps {
  closeForm: () => void;
}

type OverflowAppointmentWithUser = {
  user: UserFormValues;
  appointment: OverflowAppointmentForm;
};

export const BookOverflowNoPatient: React.FunctionComponent<
  IAppointmentFormProps
> = ({ closeForm }) => {
  const { t } = useCommonTranslation();
  const { activeTrust } = useSite();

  const {
    register,
    control,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<OverflowAppointmentWithUser>();

  const {
    referrerOptions,
    createAndOverflowPatientWithValidation,
    duplicateResolveCreateNew,
    duplicateResolveSelect,
    duplicateUsers,
    surgeryOptions,
    isLoading: isOverflowLoading,
    setShowApptTimeSelect,
    showApptTimeSelect,
  } = useOverflowNoPatient({
    closeForm,
  });

  const isLoading = isOverflowLoading;

  useEffect(() => {
    setFocus('user.firstname');
  }, [setFocus]);

  return (
    <AppointmentCard
      isLoading={isLoading}
      title={t('appointment-detail-title')}
      onCloseClicked={closeForm}
      icon={<FaUserClock />}
    >
      <FormBody>
        <StaffOrPatientFormData
          gpOptions={surgeryOptions}
          register={register}
          control={control}
          errors={errors}
          trusts={[activeTrust]}
        />
        <CardSubtitle>{t('appointment-detail-information')}</CardSubtitle>
        <EditAppointmentData
          register={register}
          control={control}
          errors={errors}
          referrers={referrerOptions}
        />
        {duplicateUsers && (
          <DuplicatePatientDialog
            duplicateUsers={duplicateUsers}
            onCreateNewPatientSelected={duplicateResolveCreateNew}
            onPatientSelected={async (patient) => {
              await duplicateResolveSelect({ selectedPatient: patient });
            }}
          />
        )}
        {showApptTimeSelect && (
          <OverflowTimeSelect
            register={register}
            control={control}
            errors={errors}
          />
        )}
        <ButtonSetWithCloseButton onCloseClicked={closeForm}>
          <Button
            variant="primary"
            type={'button'}
            onClick={async () => {
              await handleSubmit((data) => {
                createAndOverflowPatientWithValidation({
                  appointment: data.appointment,
                  user: data.user,
                });
              })();
            }}
          >
            {showApptTimeSelect
              ? t('save')
              : t('appointment-details-save-and-queue')}
          </Button>
          {!showApptTimeSelect && (
            <Button
              variant="secondary"
              type={'button'}
              onClick={() => setShowApptTimeSelect(true)}
            >
              {t('appointment-overflow-book-for-later')}
            </Button>
          )}
        </ButtonSetWithCloseButton>
      </FormBody>
    </AppointmentCard>
  );
};
