import {
  GreenBoxTitle,
  GreenBoxWrapper,
} from '../../un-auth/SignupForm/SignupForm.styled';
import { FormColumn } from '../../../common/Forms/Forms.styled';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import CheckboxInput from '../../../common/Forms/CheckboxInput';
import CommonSelect, { SelectProps } from '../../../common/Forms/Select';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { getHookFormTyped } from '../../../../utils/helpers';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { SelectOption } from '../../../../types/common';
import { AppointmentSubformType } from './EditAppointmentData';
import { useSite } from '../../../../context/SiteContext';
import { RequiredLabel } from '../../../common/Forms/RequiredLabel';
import {
  FontBasedSize,
  LABEL_BEFORE,
} from '../../../../styles/fontsStyleUtils';
import { gpSurgeryRequiredTrust } from '../../../../utils/trustUtil';

export interface IReferrerForm {
  generalPractise: boolean;
  otherRefererId?: string;
}

export type IAppointmentReferrers<T extends AppointmentSubformType> = {
  register: UseFormRegister<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  referrers?: SelectOption[];
  disabled?: boolean;
  required?: boolean;
  generalReferrerPropsOverride?: Partial<SelectProps<any>>;
  showComment?: boolean;
} & FontBasedSize;

export const AppointmentReferrers = <T extends AppointmentSubformType>({
  referrers,
  disabled = false,
  required,
  fontBasedSize,
  generalReferrerPropsOverride,
  ...rest
}: IAppointmentReferrers<T>) => {
  const { control, errors, register } = getHookFormTyped<
    AppointmentSubformType,
    T
  >(rest);
  const { t } = useCommonTranslation();
  const { activeTrust } = useSite();
  return (
    <GreenBoxWrapper fontBasedSize={fontBasedSize}>
      <FormColumn>
        <div
          style={{
            display: 'flex',
            gap: 'var(--s3)',
            alignItems: 'center',
          }}
        >
          <GreenBoxTitle
            style={{
              flex: 1,
            }}
          >
            <RequiredLabel required={required} label={t('referrer')} />
          </GreenBoxTitle>
          {gpSurgeryRequiredTrust(activeTrust) && (
            <CheckboxInput
              {...register('appointment.generalPractise')}
              disabled={disabled}
              label={t('general-practice')}
              labelPosition={'before'}
              fontBasedSize={fontBasedSize}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            gap: 'var(--s3)',
            alignItems: 'center',
          }}
        >
          <Controller
            name="appointment.otherRefererId"
            render={({ field }) => (
              <CommonSelect
                {...field}
                style={{ marginBottom: 0, flex: 1 }}
                fontBasedSize={fontBasedSize}
                labelPosition={LABEL_BEFORE}
                label={t('appointment-detail-otherReferrer-short') + ':'}
                placeholder={t('appointment-detail-otherReferrer')}
                disabled={!referrers || disabled}
                options={referrers}
                helperText={errors.appointment?.otherRefererId?.message}
                hasError={Boolean(errors.appointment?.otherRefererId)}
                noSpaceForHelperText
                {...generalReferrerPropsOverride}
              />
            )}
            control={control}
          />
        </div>
      </FormColumn>
    </GreenBoxWrapper>
  );
};
