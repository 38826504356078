import { AppointmentAuditItem, AppointmentStatus } from '../types/appointments';
import { parseIsoDate } from '../utils/dateUtil';
import { useMemo } from 'react';

const findAppointmentAuditStatusTransition = ({
  auditItems = [],
  status: checkForStatus,
  position,
}: {
  auditItems?: AppointmentAuditItem[];
  status: AppointmentStatus;
  position: 'first' | 'last';
}): AppointmentAuditItem | undefined => {
  return auditItems
    .filter(({ status }) => status === checkForStatus)
    .sort((a, b) =>
      position === 'last'
        ? parseIsoDate(b.event_time).getTime() -
          parseIsoDate(a.event_time).getTime()
        : parseIsoDate(a.event_time).getTime() -
          parseIsoDate(b.event_time).getTime(),
    )[0];
};

export const useAppointmentAuditTransition = ({
  auditItems,
  statuses,
  enabled = true,
  position,
}: {
  auditItems?: AppointmentAuditItem[];
  statuses: AppointmentStatus[];
  enabled?: boolean;
  position: 'first' | 'last';
}) => {
  return useMemo(
    () =>
      enabled
        ? statuses.map((status) =>
            findAppointmentAuditStatusTransition({
              auditItems,
              status,
              position,
            }),
          )
        : [],
    [position, auditItems, statuses, enabled],
  );
};

export const useAppointmentBookedByAudit = ({
  auditItems,
  enabled = true,
}: {
  auditItems?: AppointmentAuditItem[];
  enabled?: boolean;
}) => {
  /**
   * When appt is overflown it only gets queue audit item.
   * That is where we can get the booked by information from.
   */
  const [bookedAudit, queueAudit] = useAppointmentAuditTransition({
    auditItems,
    statuses: ['BOOKED', 'QUEUE'],
    enabled,
    position: 'first',
  });
  return bookedAudit || queueAudit;
};
