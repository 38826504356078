import CheckboxInput from 'components/common/Forms/CheckboxInput';
import {
  CommonInputHelperText,
  DialogFormWrapper,
  FormGroup,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import { CardLoader } from 'components/common/Loading';
import {
  AutoCompleteLabel,
  CheckboxesWithErrorWrapper,
  CheckboxListWrapper,
  GreenBoxWrapper,
} from 'components/views-components/un-auth/SignupForm/SignupForm.styled';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FaCalendarCheck } from 'react-icons/fa';

import Input from '../../../../components/common/Forms/Input';
import { SITE_WEEK_DAYS } from '../../../../configs/siteAndTrustAttributes';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import {
  DATE_FNS_TIME,
  isBefore,
  isValid,
  parseDate,
  WEEK_DAY_LABELS,
} from '../../../../utils/dateUtil';
import { UseGeneralSitePreferencesFormReturn } from './GeneralSitePreferencesHooks';
import { CHECKBOX_ATTRIBUTE_SEPARATOR } from '../../../../configs/constants';

interface Props {
  isFetching: boolean;
  disabled: boolean;
  attributesForm: UseGeneralSitePreferencesFormReturn;
}

const AvailabilityForm: React.FC<Props> = ({
  isFetching,
  disabled,
  attributesForm,
}) => {
  const { t } = useCommonTranslation();

  const {
    formState: { errors },
    attributeRegister,
    control,
    register,
  } = attributesForm;
  const watchedValues = useWatch({ control });

  const validateCorrectTimeAnd5Minutes = (inputDate: string) => {
    /**
     * The parse will accept things like 09:1 and think it is 09:01.
     * This will ensure there are two minutes if the format is correct.
     */
    if (inputDate.split(':').pop()?.length !== 2) {
      return t('must-valid-24h-time') as string;
    }
    const parsedInputDate = parseDate(inputDate, DATE_FNS_TIME);
    if (!isValid(parsedInputDate)) {
      return t('must-valid-24h-time') as string;
    }
    if (parsedInputDate.getMinutes() % 5 !== 0) {
      return t('must-valid-time-divisible-5-mins') as string;
    }
    return undefined;
  };

  return (
    <DialogFormWrapper>
      <FormHeader>
        <FaCalendarCheck />
        <FormTitle>{t('availability')}</FormTitle>
      </FormHeader>
      <FormRow>
        <Input
          {...register('openHourTime', {
            validate: (value: string) => {
              return validateCorrectTimeAnd5Minutes(value);
            },
            required: t('must-not-empty') as string,
          })}
          placeholder={t('opening-hours')}
          label={t('opening-hours')}
          hasError={Boolean(errors.openHourTime)}
          helperText={errors?.openHourTime?.message}
          disabled={disabled}
        />
        <Input
          {...register('closeHourTime', {
            validate: (value: string) => {
              const parsedCloseHour = parseDate(value, DATE_FNS_TIME);
              const timeAndMinutesError = validateCorrectTimeAnd5Minutes(value);
              if (timeAndMinutesError) return timeAndMinutesError;
              const openHour = watchedValues.openHourTime;
              if (openHour) {
                const parsedOpenHour = parseDate(openHour, DATE_FNS_TIME);

                return (
                  isBefore(parsedOpenHour, parsedCloseHour) ||
                  (t('open-close-hour-validation') as string)
                );
              }
            },
            required: t('must-not-empty') as string,
          })}
          placeholder={t('closing-hours')}
          label={t('closing-hours')}
          hasError={Boolean(errors?.closeHourTime)}
          helperText={errors?.closeHourTime?.message}
          disabled={disabled}
        />
      </FormRow>
      <FormRow>
        <FormGroup>
          <CheckboxesWithErrorWrapper>
            <GreenBoxWrapper>
              <AutoCompleteLabel as="span">{t('days')}:</AutoCompleteLabel>
              <CheckboxListWrapper>
                {WEEK_DAY_LABELS(t).map((day) => (
                  <CheckboxInput
                    {...attributeRegister(
                      SITE_WEEK_DAYS,
                      (e) => e.target.value,
                      {
                        validate: (value: []) => {
                          return (
                            value.length > 0 || (t('must-not-empty') as string)
                          );
                        },
                      },
                      true,
                    )}
                    labelPosition={'before'}
                    label={day.label.substring(0, 3)}
                    key={'week_days_' + day.backendDowIndex}
                    id={'week_days_' + day.backendDowIndex}
                    value={day.backendDowIndex}
                    checked={watchedValues.attributes
                      ?.find((atr) => atr.key === SITE_WEEK_DAYS)
                      ?.value_str?.includes(`${day.backendDowIndex}`)}
                    disabled={disabled}
                  />
                ))}
              </CheckboxListWrapper>
            </GreenBoxWrapper>
            {errors[
              (CHECKBOX_ATTRIBUTE_SEPARATOR + WEEK_DAY_LABELS) as 'attributes'
            ] && (
              <CommonInputHelperText hasError={true}>
                {
                  errors[
                    (CHECKBOX_ATTRIBUTE_SEPARATOR + WEEK_DAY_LABELS) as 'id'
                  ]?.message
                }
              </CommonInputHelperText>
            )}
          </CheckboxesWithErrorWrapper>
        </FormGroup>
      </FormRow>
      {isFetching && <CardLoader fillWrapper={true} />}
    </DialogFormWrapper>
  );
};
export default AvailabilityForm;
