export interface TimePlanner_Interval {
  cubicle: number;
  staff_only: number;
  available_cubicles: number;
  minute: number;
}

export interface TimePlanner_Hour {
  hourNumber: number;
  cubicle: number;
  staff_only: number;
  available_cubicles: number;
  intervals: TimePlanner_Interval[];
}

export interface TimePlanner_Day {
  dayNumber: number;
  cubicle: number;
  staff_only: number;
  available_cubicles: number;
  selected_date?: Date;
  hours: TimePlanner_Hour[];
}

export interface TimePlannerState {
  selectedResourceCubiclesAssigned: number;
  openHourInSeconds: number;
  closeHourInSeconds: number;
  timeInterval: number;
  day: TimePlanner_Day;
}

export enum TimePlannerActionType {
  SET_DEFAULT_STATE,
  SET_DAY_LIMIT,
  SET_HOUR_LIMIT,
  SET_INTERVAL_LIMIT,
  SET_ONLINE_ACTION,
}

export interface SET_DEFAULT_STATE {
  type: TimePlannerActionType.SET_DEFAULT_STATE;
  payload: TimePlannerState;
}

export interface SET_ONLINE_ACTION {
  type: TimePlannerActionType.SET_ONLINE_ACTION;
  payload?: {
    state?: TimePlannerState;
  };
}

export interface SET_DAY_LIMIT {
  type: TimePlannerActionType.SET_DAY_LIMIT;
  payload: {
    value: number;
    staff_only?: boolean;
  };
}

export interface SET_HOUR_LIMIT {
  type: TimePlannerActionType.SET_HOUR_LIMIT;
  payload: {
    hourIndex: number;
    value: number;
    staff_only?: boolean;
  };
}

export interface SET_INTERVAL_LIMIT {
  type: TimePlannerActionType.SET_INTERVAL_LIMIT;
  payload: {
    hourIndex: number;
    intervalIndex: number;
    value: number;
    staff_only?: boolean;
  };
}

export type TimePlannerActions =
  | SET_ONLINE_ACTION
  | SET_DEFAULT_STATE
  | SET_DAY_LIMIT
  | SET_HOUR_LIMIT
  | SET_INTERVAL_LIMIT;
