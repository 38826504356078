import {
  dayInFirstFullWeekAfterUnix0,
  formatDateOnlyISO,
  getWeekDay,
  isSameDay,
} from './dateUtil';
import { ApptTemplate } from '../types/apptTemplate';

/**
 * On Backend this concept for date for full week is not used,
 * however when full week edit was introduced, the calendar templates
 * are so heavily dependent on a date, that it is easier to introduce
 * this concept here, than to refactor the whole calendar templates.
 *
 */
export const APPT_TEMPLATE_FULL_WEEK_DEFAULT_DATE = new Date(0);

export const isDefaultFullWeekTemplateDay = (date: Date) =>
  isSameDay(date, APPT_TEMPLATE_FULL_WEEK_DEFAULT_DATE);

export const getDefaultTemplateDate = (weekdayIndex: number) => {
  return dayInFirstFullWeekAfterUnix0(weekdayIndex);
};

export const isDefaultTemplateDate = (date: Date) => {
  const defaultTemplateDate = getDefaultTemplateDate(getWeekDay(date));
  return formatDateOnlyISO(defaultTemplateDate) === formatDateOnlyISO(date);
};

export const isDefaultFullWeekOrTemplateDate = (date: Date) =>
  isDefaultTemplateDate(date) || isDefaultFullWeekTemplateDay(date);

export type AppointmentTemplateCounts = {
  allApptCount: number;
  staffOnlyCount: number;
  onlineCount: number;
};

export const getAppointmentTemplateCounts = (
  entries: ApptTemplate['entries'],
): AppointmentTemplateCounts => {
  const allApptCount =
    entries?.reduce((acc, { cubicle_count }) => acc + cubicle_count, 0) || 0;
  const staffOnlyCount =
    entries?.reduce((acc, { staff_only }) => acc + staff_only, 0) || 0;
  return {
    allApptCount,
    staffOnlyCount,
    onlineCount: allApptCount - staffOnlyCount,
  };
};
