import { DateRange } from '../../types/calendar';
import { useSiteNumericArrayAttribute } from '../useAttribute';
import { useMemo } from 'react';
import {
  addDays,
  DateOnlyISOString,
  formatDateOnlyISO,
  isBefore,
  parseIsoDate,
  setHours,
  setMinutes,
} from '../../utils/dateUtil';
import { getDaySlotsByHour } from '../../utils/appointmentUtil';

const getWeekdaysDatesWithinDateRange = (
  dateRange: DateRange,
  weekdays: number[],
) => {
  let currentDate = dateRange.fromDate;
  const weekdaysDates: Date[] = [];
  while (isBefore(currentDate, dateRange.toDate)) {
    if (weekdays.includes(currentDate.getDay())) {
      weekdaysDates.push(currentDate);
    }
    currentDate = addDays(currentDate, 1);
  }
  return weekdaysDates.map((date) => formatDateOnlyISO(date));
};

const getStaffCalendarSlots = (
  date: DateOnlyISOString,
  openHourInSeconds: number,
  closeHourInSeconds: number,
  RTInterval: number,
) => {
  const daySlots = getDaySlotsByHour({
    openHourInSeconds,
    closeHourInSeconds,
    slotLengthInMinutes: RTInterval,
  });

  return daySlots.map(({ hour, minutes }) => ({
    hour,
    appointmentSlotDates: minutes.map((minute) =>
      setHours(setMinutes(parseIsoDate(date), minute), hour),
    ),
  }));
};

export const useSiteCalendarWeekDays = ({
  dateRange,
  openHourInSeconds,
  closeHourInSeconds,
  RTInterval,
}: {
  dateRange: DateRange;
  openHourInSeconds: number;
  closeHourInSeconds: number;
  RTInterval: number;
}) => {
  const weekdays = useSiteNumericArrayAttribute('weekDays')!;
  return useMemo(() => {
    const dates = getWeekdaysDatesWithinDateRange(dateRange, weekdays);
    return dates.map((date) => ({
      date: date,
      hours: getStaffCalendarSlots(
        date,
        openHourInSeconds,
        closeHourInSeconds,
        RTInterval,
      ),
    }));
  }, [closeHourInSeconds, dateRange, openHourInSeconds, RTInterval, weekdays]);
};
