import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { toRem } from '../../utils/helpers';
import { screenWidthHeight } from '../../styles/partials/positions';

export interface IInfoPopupStyleProps {
  size?: 'large' | 'wrap' | 'standard' | 'fullScreen' | 'small' | number;
}

export const InfoPopupStyled = styled(Popup)<IInfoPopupStyleProps>`
  &-overlay {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(15px);
  }

  &-content {
    ${({ size }) => {
      let exactWidth: number | undefined = undefined;
      if (!size || size === 'large') {
        exactWidth = 1240;
      } else if (size === 'small') {
        exactWidth = 400;
      } else if (typeof size === 'number') {
        exactWidth = size;
      }

      if (exactWidth !== undefined) {
        return `min-width: calc(${toRem(exactWidth)}rem + (var(--s3) * 2));
               max-width: calc(${toRem(exactWidth)}rem + (var(--s3) * 2));`;
      }
      if (size === 'standard') {
        return `min-width: 40%;`;
      }
      if (size === 'small') {
        return `min-width: 40%;`;
      }
      if (size === 'fullScreen') return screenWidthHeight;
    }}
`;

export const InfoPopupInfoTextStyled = styled.div`
  background-color: var(--blueprimary);
  color: var(--white);
  font-size: var(--bodysm);
  margin: 0 var(--s3);
  padding: var(--s2);
  border-radius: var(--s3);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
`;
export const InfoPopupContentStyled = styled.div<IInfoPopupStyleProps>`
  padding: var(--s3);
  max-height: 100vh;
  overflow-y: auto;
  display: grid;
  ${({ size }) =>
    size === 'fullScreen'
      ? '' +
        'min-height: 100vh;' +
        'display: flex;' +
        'align-items: center;' +
        'justify-content: center;'
      : 'min-height: calc(var(--s8) * 3);'}
`;
