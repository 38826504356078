import { AxiosError } from 'axios';
import { Card } from 'components/common';
import { CommonButton as Button } from 'components/common/Forms/Button';
import {
  CloseButton,
  DialogFormWrapper,
  FormActionsStyle,
  FormBody,
  FormHeader,
  FormRow,
  FormTitle,
  HiddenInput,
} from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import CommonSelect from 'components/common/Forms/Select';
import TextArea from 'components/common/Forms/TextArea';
import { CardLoader } from 'components/common/Loading';
import { APPT_DURATIONS, SUPERADMIN } from 'configs/constants';
import { useSite } from 'context/SiteContext';
import React, { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaCalendarDay, FaTimes } from 'react-icons/fa';
import { ResourceType, ResourceTypeFormValues } from 'types/resource-types';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { useRole } from '../../../../../context/RoleContext';

interface Props {
  closeFormHandler: (err?: true | AxiosError<any>) => void;
  submitHandler: (
    data: ResourceTypeFormValues,
    isUpdateCubicle: boolean,
  ) => void;
  isLoading: boolean;
  prefilledData?: ResourceType;
}

export default function ResourceTypeForm({
  closeFormHandler,
  submitHandler,
  isLoading,
  prefilledData,
}: Props): ReactElement {
  const isEdit = Boolean(prefilledData);
  const { t } = useCommonTranslation();
  const { activeSite } = useSite();
  const { hasAnyOfRoles } = useRole();

  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
  } = useForm<ResourceTypeFormValues>({
    defaultValues: prefilledData
      ? {
          ...prefilledData,
        }
      : {},
  });

  return (
    <Card>
      <DialogFormWrapper>
        <CloseButton
          size="auto"
          variant="primary"
          iconOnly
          onClick={() => closeFormHandler()}
        >
          <FaTimes />
        </CloseButton>
        <FormHeader>
          <FaCalendarDay />
          <FormTitle>{t('calendar-template')}</FormTitle>
        </FormHeader>
        <FormBody
          onSubmit={handleSubmit((data) => submitHandler(data, isEdit))}
        >
          <HiddenInput {...register('site_id')} value={activeSite?.id} />
          <FormRow>
            <Input
              autoFocus
              label={t('name')}
              placeholder={t('name')}
              helperText={errors.name?.message}
              hasError={Boolean(errors.name)}
              id={prefilledData ? prefilledData.id + '_name' : 'new_name'}
              {...register('name', {
                required: t('must-not-empty') as string,
                maxLength: {
                  value: 20,
                  message: t('max-char-input', { count: 20 }),
                },
              })}
              required
            />
          </FormRow>
          <FormRow>
            <Input
              label={t('abbreviation')}
              placeholder={t('abbreviation')}
              helperText={errors.abbreviation?.message}
              hasError={Boolean(errors.abbreviation)}
              id={
                prefilledData
                  ? prefilledData.id + '_abbreviation'
                  : 'new_abbreviation'
              }
              {...register('abbreviation', {
                required: t('must-not-empty') as string,
                maxLength: {
                  value: 5,
                  message: t('max-chars', { count: 5 }),
                },
              })}
              required
            />
            <Controller
              name="length"
              render={({ field }) => (
                <CommonSelect
                  {...field}
                  label={t('appointment-duration')}
                  instanceId={
                    prefilledData
                      ? prefilledData.id + '_appointment-duration'
                      : 'new_appointment-duration'
                  }
                  placeholder={t('appointment-duration')}
                  disabled={!hasAnyOfRoles(SUPERADMIN)}
                  options={APPT_DURATIONS}
                  helperText={errors.length?.message}
                  hasError={Boolean(errors.length)}
                  required
                />
              )}
              control={control}
              rules={{
                required: t('must-not-empty') as string,
              }}
            />
          </FormRow>
          <FormRow>
            <TextArea
              label={t('description')}
              placeholder={t('description')}
              helperText={errors.description?.message}
              hasError={Boolean(errors.description)}
              {...register('description')}
            />
          </FormRow>
          <FormActionsStyle align="center">
            <Button variant="primary" type="submit">
              {isEdit ? t('save') : t('add')}
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={() => closeFormHandler()}
            >
              {t('cancel')}
            </Button>
          </FormActionsStyle>
        </FormBody>
        {isLoading && <CardLoader fillWrapper={true} />}
      </DialogFormWrapper>
    </Card>
  );
}
