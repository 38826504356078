import { css } from 'styled-components';

export const topCenterAbsolutePosition = css`
  top: 50%;
  transform: translateY(-50%);
`;
export const leftCenterAbsolutePosition = css`
  left: 50%;
  transform: translateX(-50%);
`;
// transform props do not add
export const topLeftCenterAbsolutePosition = css`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const absoluteFullStretchBox = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const screenWidthHeight = css`
  min-height: 100vh;
  min-width: 100vw;
`;
