import styled, { css } from 'styled-components';
import { small } from 'styles/Typography';
import { toRem } from 'utils/helpers';
import React from 'react';
import { CommonButton } from '../common/Forms/Button';

const SelectedRowColor = 'background: var(--greensecondary);';

export const FilterInputContainerStyleOverride: React.CSSProperties = {
  marginBottom: 'var(--s2)',
  minWidth: 'unset',
  marginTop: 'var(--s2)',
};

export const EllipseText = styled.div<{
  align?: 'left' | 'right';
  selectedRow?: boolean;
}>`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :hover {
    border-radius: var(--s3);
    overflow: visible;
    z-index: 1;
    background: ${({ selectedRow }) =>
      selectedRow ? SelectedRowColor : 'var(--white);'} ${({ align }) =>
  align && align === 'right'
    ? `padding-left: var(--s2);`
    : 'padding-right: var(--s2);'}

    min-width: fit-content;
  }
`;

export const Td = styled.div`
  margin: 0;
  position: relative;
  min-height: var(--s4);
  display: flex !important;
  align-items: center;
  line-height: 1.2;
`;

// noinspection CssInvalidPropertyValue
/**
 * Wrapping the content of the cell to be able to set different paddings.
 * If different paddings would be set to the top element, the
 * cells than have different sizes.
 */
export const CellWrapper = styled.div<{
  align?: 'left' | 'right';
  display?: string;
}>`
  ${({ align }) =>
    align &&
    align === 'right' &&
    css`
      direction: rtl;
    `}
  display: ${({ display }) => (display ? display : 'flex')};
  flex: 1;
  margin-left: var(--s3);
  align-items: center;
  width: 100%;
  max-width: -webkit-fill-available;

  .head-date-picker {
    min-width: unset;
  }

  &.head-input {
    overflow: visible;
    padding-top: var(--s2);

    & input {
      width: 100%;
    }

    & > div {
      min-width: auto !important;
    }
  }

  .resizer {
    display: inline-block;
    color: var(--textdisabled);
    user-select: none;
    min-width: var(--s3);
    max-width: var(--s3);
    z-index: 1;
    touch-action: none;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before,
    &::after {
      position: relative;
      content: '';
      background-color: var(--textdisabled);
      width: ${toRem(1)}rem;
      height: 1rem;
    }

    &::before {
      margin-right: ${toRem(4)}rem;
    }

    &:hover,
    &.isResizing {
      &::before,
      &::after {
        background-color: var(--yellowaction);
      }
    }
  }
`;

export const Th = styled(Td)<{ readonly canSort?: boolean }>`
  ${small};
  font-weight: 500;

  ${({ canSort }) =>
    canSort &&
    css`
      transition: 0.2s ease background-color;

      &:hover {
        background-color: var(--greenmuted);
      }
    `}
`;
export const Tr = styled.div<{
  readonly hasNoFilters?: boolean;
  readonly selected?: boolean;
}>`
  border-radius: var(--s3);
  box-shadow: var(--shadow-1);
  background: var(--white);
  margin-bottom: ${({ hasNoFilters }) =>
    hasNoFilters ? 'var(--s2)' : 'var(--s1)'};
  width: 100% !important;

  ${({ selected }) =>
    selected && SelectedRowColor + `color: var(--textprimary);`}
  && ${CellWrapper} {
    ${({ selected }) => selected && 'color: var(--textprimary);'}
`;
export const FiltersRow = styled.div`
  width: 100% !important;
`;
export const THead = styled.div``;
export const Tbody = styled.div`
  ${Td} {
    font-size: var(--bodysm);
    color: var(--textsecondary);
  }
`;
export const TableWrapper = styled.div`
  padding: var(--s1) var(--s1) 0;
  display: inline-block;
  border-spacing: 0;
  min-width: 100% !important;
  max-width: 100%;
`;
export const TableOuterWrapper = styled.div`
  /* overflow: auto; TODO: should be a way to fix date dropdown in empty table state, try to add min-height for this div */
  width: 100%;
  width: calc(100% + var(--s1) * 2);
  margin: calc(-1 * var(--s1)) calc(-1 * var(--s1)) calc(-1 * var(--s1));
`;
export const FilterWrapper = styled.form<{ readonly hasFilters?: boolean }>`
  ${({ hasFilters }) =>
    hasFilters &&
    css`
      border-radius: var(--s3);
      box-shadow: var(--shadow-1);
      margin-bottom: var(--s2);
    `}
`;
export const TableActions = styled.div<{
  readonly belowForm?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: var(--s3);
  row-gap: var(--s1);
  margin-bottom: var(--s3);
  position: relative;
  ${({ belowForm }) =>
    belowForm &&
    css`
      margin-bottom: 0;
      margin-top: var(--s3);
    `}
`;
export const TableActionNote = styled.span<{
  readonly isStatic?: boolean;
}>`
  font-size: var(--body1);
  color: var(--greenaccent);
  font-weight: 600;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  ${({ isStatic }) =>
    isStatic &&
    css`
      position: static;
      margin-left: auto;
      transform: none;
    `}
`;
export const RowActions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  direction: ltr;

  & > * + * {
    margin-left: var(--s2);
  }
`;
export const SortWrapper = styled.span`
  color: var(--textdisabled);
  padding: 0 var(--s1);
  cursor: pointer;

  svg {
    transform: translateY(${toRem(2)}rem);
  }
`;

export const TableLoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9;
  width: calc(100% + var(--s1) * 2);
  margin: calc(-1 * var(--s1)) calc(-1 * var(--s1)) calc(-1 * var(--s1));
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    text-align: center;
  }

  .loader {
    width: ${toRem(50)}rem;
    height: ${toRem(15)}rem;
    background: var(--greenaccent);
    border-radius: ${toRem(7.5)}rem;
    -webkit-animation: load 1.8s ease-in-out infinite;
    animation: load 1.8s ease-in-out infinite;
  }

  .loader::before,
  .loader::after {
    position: absolute;
    display: block;
    content: '';
    -webkit-animation: load 1.8s ease-in-out infinite;
    animation: load 1.8s ease-in-out infinite;
    height: ${toRem(15)}rem;
    border-radius: ${toRem(7.5)}rem;
  }

  .loader::before {
    top: -${toRem(20)}rem;
    left: ${toRem(10)}rem;
    width: ${toRem(40)}rem;
    background: var(--greensecondary);
  }

  .loader::after {
    bottom: -${toRem(20)}rem;
    width: ${toRem(35)}rem;
    background: var(--greenprimary);
  }

  .text {
    margin-top: ${toRem(32)}rem;
    font-size: ${toRem(16)}rem;
    color: var(--greenaccent);
    font-weight: 600;
  }

  @-webkit-keyframes load {
    0% {
      -webkit-transform: translateX(${toRem(40)}rem);
      transform: translateX(${toRem(40)}rem);
    }
    50% {
      -webkit-transform: translateX(-${toRem(30)}rem);
      transform: translateX(-${toRem(30)}rem);
    }
    100% {
      -webkit-transform: translateX(${toRem(40)}rem);
      transform: translateX(${toRem(40)}rem);
    }
  }

  @keyframes load {
    0% {
      -webkit-transform: translateX(${toRem(40)}rem);
      transform: translateX(${toRem(40)}rem);
    }
    50% {
      -webkit-transform: translateX(-${toRem(30)}rem);
      transform: translateX(-${toRem(30)}rem);
    }
    100% {
      -webkit-transform: translateX(${toRem(40)}rem);
      transform: translateX(${toRem(40)}rem);
    }
  }
`;

export const TableActionLeftButton = styled(CommonButton).attrs(() => ({
  variant: 'secondary',
}))`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;
