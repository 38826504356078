import { AxiosResponse } from 'axios';
import {
  UseMutationOptions,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import {
  CacheAndStaleTimeForUnlikelyChanged,
  useMutationWithToastError,
  useQueryWithToastError,
} from './queryUtils';
import {
  getGDPRTemplate,
  getGuidelinesTemplate,
  getPatientReleaseNote,
  getStaffReleaseNote,
  getSupportTemplate,
  updateGDPRTemplate,
  updateGuidelinesTemplate,
  updateReleaseNote,
  updateSupportTemplate,
} from '../services/storage';
import { PostReleaseNotes } from '../types/storage';
import { GLOBAL_ATTRIBUTES_QUERY_KEY } from './globalAttributes';

const RELEASE_NOTES_QUERY_KEY = 'RELEASE_NOTES';

export const usePatientReleaseNotes = (
  options?: UseQueryOptions<any, any, AxiosResponse<string>>,
) => {
  return useQueryWithToastError(
    [RELEASE_NOTES_QUERY_KEY, 'PATIENT'],
    () => getPatientReleaseNote(),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
      ...options,
    },
  );
};

export const useStaffReleaseNotes = (
  options?: UseQueryOptions<any, any, AxiosResponse<string>>,
) => {
  return useQueryWithToastError(
    [RELEASE_NOTES_QUERY_KEY, 'STAFF'],
    () => getStaffReleaseNote(),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
      ...options,
    },
  );
};

export const useMutateUpdateReleaseNotes = (
  options?: UseMutationOptions<any, any, PostReleaseNotes>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<PostReleaseNotes>(
    [RELEASE_NOTES_QUERY_KEY],
    (releaseNotes) => updateReleaseNote(releaseNotes),
    {
      onSettled: (_, __, variables) => {
        client.invalidateQueries([
          RELEASE_NOTES_QUERY_KEY,
          variables.releaseType,
        ]);
        client.invalidateQueries([GLOBAL_ATTRIBUTES_QUERY_KEY]);
      },
      ...options,
    },
  );
};

const GDPR_KEY = 'GDPR';

export const useGDPRTemplate = (
  options?: UseQueryOptions<any, any, AxiosResponse<string>>,
) => {
  return useQueryWithToastError([GDPR_KEY], () => getGDPRTemplate(), {
    ...CacheAndStaleTimeForUnlikelyChanged,
    ...options,
  });
};

export const useMutateGDPRTemplate = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<string>(
    [GDPR_KEY],
    (template) => updateGDPRTemplate(template),
    {
      onSettled: () => client.invalidateQueries([GDPR_KEY]),
      ...options,
    },
  );
};

const SUPPORT_KEY = 'SUPPORT';

export const useSupportTemplate = (
  options?: UseQueryOptions<any, any, AxiosResponse<string>>,
) => {
  return useQueryWithToastError(
    [SUPPORT_KEY],
    () => getSupportTemplate(),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
      ...options,
    },
  );
};

export const useMutateUpdateSupportTemplate = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<string>(
    [SUPPORT_KEY],
    (template) => updateSupportTemplate(template),
    {
      onSettled: () => client.invalidateQueries([SUPPORT_KEY]),
      ...options,
    },
  );
};

const GUIDELINES_KEY = 'GUIDELINES';

export const useGuidelinesTemplate = (
  options?: UseQueryOptions<any, any, AxiosResponse<string>>,
) => {
  return useQueryWithToastError(
    [GUIDELINES_KEY],
    () => getGuidelinesTemplate(),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
      ...options,
    },
  );
};

export const useMutateUpdateGuidelinesTemplate = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<string>(
    [GUIDELINES_KEY],
    (template) => updateGuidelinesTemplate(template),
    {
      onSettled: () => client.invalidateQueries([GUIDELINES_KEY]),
      ...options,
    },
  );
};
