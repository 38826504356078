import { KioskEntryMessageBoxStyled } from '../Kiosk.styled';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { KioskLayoutWithResetKioskButton } from '../KioskLayoutWithResetKioskButton';
import { KioskTextInput } from './KioskTextInput';

export const KioskUpdateUser = ({
  onUpdateUser,
  onExitClicked,
}: {
  onUpdateUser: (userInput: string) => any;
  onExitClicked: () => any;
}) => {
  const { t } = useCommonTranslation();

  return (
    <KioskLayoutWithResetKioskButton onExitClicked={onExitClicked}>
      <KioskEntryMessageBoxStyled>
        <KioskTextInput
          onInputSubmit={onUpdateUser}
          maxLength={15}
          minLength={3}
          inputHint={t('kiosk-update-user-hint')}
          uppercase={false}
        />
      </KioskEntryMessageBoxStyled>
    </KioskLayoutWithResetKioskButton>
  );
};
