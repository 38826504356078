import { get } from 'lodash';
import { Controller, FieldError } from 'react-hook-form';
import { SetOptional } from 'type-fest';
import Select, { SelectProps } from '../Select';
import { ControlledComponentProps } from './ControlledComponentProps';

type ControlledSelectProps<
  FormValues extends object,
  OptionType = string,
> = ControlledComponentProps<FormValues> &
  SetOptional<Omit<SelectProps<OptionType>, 'value'>, 'onChange'>;

export const ControlledSelect = <
  FormValues extends object,
  OptionType = string,
>({
  control,
  name,
  formState,
  onChange,
  rules,
  ...rest
}: ControlledSelectProps<FormValues, OptionType>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        const error: FieldError = get(formState.errors, name);
        const defaultOnChange = (value: unknown) => field.onChange(value);
        return (
          <Select
            value={field.value as OptionType}
            name={field.name}
            onChange={defaultOnChange}
            hasError={!!error}
            helperText={error?.message}
            {...rest}
          />
        );
      }}
    />
  );
};
