import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useConfirmDialog } from '../../../../components/Popup/ConfirmDialog/confirmDialogHook';
import { useCallback } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { UserFormValues } from '../../../../types/users';
import { useSiteValidatePatientAge } from '../../../../hooks/site/useSiteValidatePatientAge';

export const useValidatePatientAgeWarningDialog = (): {
  showSitePatientAgeWarningIfNeeded: (props: {
    onSuccess: () => void;
    user: UserFormValues;
  }) => void;
} => {
  const { t } = useCommonTranslation();
  const { confirm } = useConfirmDialog();
  const { validatePatientAge } = useSiteValidatePatientAge();
  const showSitePatientAgeWarningIfNeeded = useCallback(
    ({ onSuccess, user }: { onSuccess: () => void; user: UserFormValues }) => {
      const validationErrorText = validatePatientAge(user);
      if (validationErrorText) {
        confirm({
          icon: <FaExclamationTriangle />,
          inlineIcon: true,
          title: t('appointment-book-age-restriction'),
          description: (
            <>
              <span style={{ display: 'block' }}>{validationErrorText}</span>
              <span style={{ display: 'block', marginTop: 'var(--s2)' }}>
                {t('staff-book-appointment-ignore-restrictions')}
              </span>
            </>
          ),
          onConfirm: onSuccess,
        });
      } else {
        onSuccess();
      }
    },
    [confirm, t, validatePatientAge],
  );
  return {
    showSitePatientAgeWarningIfNeeded,
  };
};
