import { usePatientRole } from 'context/RoleContext';
import React, { ReactElement } from 'react';
import { UserData, UsersFilters } from 'types/users';

import { FetchedDataTable } from '../../../components/Table/FetchedDataTable';
import { usePaginatedFilters } from '../../../hooks/usePaginatedFilters';
import { useUsers } from '../../../query/users';
import { usePatientsColumns, UserFilterKeys } from './SearchPatientTableHooks';

export type UserRowActionCreator = (user: UserData) => React.ReactNode;

export type SearchPatientTableProps = {
  initialFilters: Partial<UsersFilters>;
  stickyFilters?: Partial<UsersFilters>;
  aboveTableElement?: React.ReactNode;
  underTableElement?: React.ReactNode;
  rowActionsCreator: UserRowActionCreator;
  showFilters?: boolean;
  filtersDisplayed?: UserFilterKeys;
};

export const SearchPatientTable = ({
  initialFilters,
  aboveTableElement,
  underTableElement,
  rowActionsCreator,
  showFilters = true,
  filtersDisplayed,
  stickyFilters = {},
}: SearchPatientTableProps): ReactElement => {
  const { filters, setFilters } =
    usePaginatedFilters<UsersFilters>(initialFilters);

  const filterWithSticky = {
    ...filters,
    ...stickyFilters,
  };

  const { id: patientRoleId } = usePatientRole();
  const {
    isLoading,
    isFetching,
    data: usersData,
  } = useUsers({
    roleID: patientRoleId,
    ...filterWithSticky,
  });

  const columns = usePatientsColumns({
    filters: filterWithSticky,
    setFilters,
    filtersDisplayed,
    usersData: usersData?.data,
    rowActionsCreator: rowActionsCreator,
    showFilters,
  });

  return (
    <>
      {aboveTableElement}
      <FetchedDataTable
        columns={columns}
        onSortOrPageChange={setFilters}
        filters={filterWithSticky}
        data={usersData?.data}
        isLoading={isLoading}
        isFetching={isFetching}
      />
      {underTableElement && (
        <div
          style={{
            marginTop: 'auto',
          }}
        >
          {underTableElement}
        </div>
      )}
    </>
  );
};
