import Axios from 'configs/Axios';
import { RoleResponseData } from 'types/common';
import {
  APIUserCreate,
  MergeMemberFormValues,
  OtherUserMemberCreateFromValues,
  OtherUserMemberUpdateFromValues,
  RoleUpdateObject,
  UserData,
  UserFormValues,
  UserPasswordFormValues,
  UsersFilters,
  UsersResponseData,
} from 'types/users';
import { UserAccountResponse } from '../types/authentication';
import { AxiosResponse } from 'axios';

export const getAllRoles = () => Axios.get<RoleResponseData>(`/role`);

export const getAllUsers = (filters?: UsersFilters) =>
  Axios.get<UsersResponseData>(`/user`, {
    params: filters,
  });

export const getUser = (id: string) => Axios.get<UserData>(`/user/${id}`);

export const updateUser = (data: UserFormValues) => {
  return Axios.put<UserFormValues, AxiosResponse<string>>(`/user`, data);
};

export const getUserAccount = (id: string) =>
  Axios.get<UserAccountResponse>(`/user/account/${id}`);

export const createUser = (data: APIUserCreate) =>
  Axios.post<APIUserCreate, AxiosResponse<UserData>>(`/user`, data);

export const deleteUser = (userId: string) => Axios.delete(`/user/${userId}`);

export const changeUserPassword = (
  id: string,
  data: UserPasswordFormValues,
) => {
  return Axios.post(`/user/${id}/password`, data);
};

export const mergeUser = (sourceUserId: string, targetUserId: string) =>
  Axios.post(`/user/${targetUserId}/merge/${sourceUserId}`);

export const updateOtherUserMember = (data: OtherUserMemberUpdateFromValues) =>
  Axios.put('user/member', data);

export const createOtherUserMember = (data: OtherUserMemberCreateFromValues) =>
  Axios.post('user/member', data);

export const postMergeMember = (data: MergeMemberFormValues) =>
  Axios.post('user/mergeMember', data);

export const addUserRole = (data: RoleUpdateObject) =>
  Axios.post('user/role', data);

export const removeUserRole = (data: RoleUpdateObject) =>
  Axios.delete('user/role', { data });
