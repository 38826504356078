import {
  addWeeks,
  endOfWeek,
  startOfWeek,
  subWeeks,
} from '../../utils/dateUtil';
import {
  CommonPrevNextRangePickerProps,
  PrevNextRangePicker,
} from './PrevNextRangePicker';

export type PrevNextWeekPickerProps = CommonPrevNextRangePickerProps;

export const PrevNextWeekPicker = (props: PrevNextWeekPickerProps) => {
  return (
    <PrevNextRangePicker
      {...props}
      startOfRange={startOfWeek}
      endOfRange={endOfWeek}
      subRange={subWeeks}
      addRange={addWeeks}
    ></PrevNextRangePicker>
  );
};
