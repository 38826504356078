import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { INotPaginatedFilters } from '../types/table';

export interface FiltersWithSet<D extends INotPaginatedFilters> {
  filters: D;
  setFilters: (newFilters: D) => any;
}

export const useFilters = <D extends INotPaginatedFilters>(
  initialFilters?: Partial<D>,
): FiltersWithSet<D> & {
  reset: (newInitialFilters?: Partial<D>) => any;
} => {
  const [filters, setFilters] = useState<INotPaginatedFilters>({
    ...initialFilters,
  });
  const reset = useCallback(
    (newInitialFilters?: Partial<D>) => {
      const merge = newInitialFilters ? newInitialFilters : initialFilters;
      setFilters({
        ...merge,
      });
    },
    [setFilters, initialFilters],
  );
  return {
    reset,
    filters: filters as D,
    setFilters: setFilters as Dispatch<SetStateAction<D>>,
  };
};
