import React, { useMemo } from 'react';
import {
  Appointment,
  AppointmentAuditFilter,
  AppointmentAuditItem,
} from '../../../types/appointments';
import { Card, CardHeader } from '../../../components/common';
import { FaTimes, FaUserFriends } from 'react-icons/fa';
import {
  CloseButton,
  FormTitle,
} from '../../../components/common/Forms/Forms.styled';
import { useAppointmentAudit } from '../../../query/appointments';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { getColumn } from '../../../utils/table';
import {
  formatDateTimeToMinutesSite,
  parseDateKeepTZ,
} from '../../../utils/dateUtil';
import { useAppointmentStateName } from '../../../hooks/appointments/useAppointmentStateName';
import { FetchedDataTable } from '../../../components/Table/FetchedDataTable';
import { useFilters } from '../../../hooks/useFilters';

interface IAppointmentAuditTableProps {
  selectedAppointment: Appointment;
  onCloseClicked: () => void;
}

export const AppointmentAuditTableCard: React.FunctionComponent<
  IAppointmentAuditTableProps
> = ({ selectedAppointment, onCloseClicked }) => {
  const { t } = useCommonTranslation();
  const { filters, setFilters } = useFilters<AppointmentAuditFilter>();

  const {
    data: auditResponse,
    isLoading,
    isFetching,
  } = useAppointmentAudit({
    ...filters,
    apptID: selectedAppointment.id,
  });
  const auditData = auditResponse;

  const stateTranslate = useAppointmentStateName();

  const columns = useMemo(() => {
    return [
      getColumn<AppointmentAuditItem>({
        Header: t('date-and-time'),
        width: 14,
        accessor: (a: AppointmentAuditItem) => {
          return formatDateTimeToMinutesSite(parseDateKeepTZ(a.event_time));
        },
        disableSortBy: true,
      }),

      getColumn<AppointmentAuditItem>({
        Header: t('status'),
        width: 12,
        accessor: (a: AppointmentAuditItem) => {
          return stateTranslate(a);
        },
        disableSortBy: true,
      }),

      getColumn<AppointmentAuditItem>({
        Header: t('auto-action'),
        width: 10,
        accessor: (a: AppointmentAuditItem) => {
          return a.auto_action ? t('yes') : '';
        },
        disableSortBy: true,
      }),
      getColumn<AppointmentAuditItem>({
        Header: t('user'),
        width: 20,
        accessor: 'user_full_name',
        disableSortBy: true,
      }),
      getColumn<AppointmentAuditItem>({
        Header: t('email'),
        width: 20,
        accessor: 'user_email',
        disableSortBy: true,
      }),
    ];
  }, [t, stateTranslate]);

  return (
    <Card>
      <CardHeader oneLine>
        <FaUserFriends />
        <FormTitle>{t('appointment-events')}</FormTitle>
        <CloseButton
          variant="primary"
          style={{
            top: 'var(--s3)',
            right: 'var(--s3)',
          }}
          iconOnly
          onClick={() => onCloseClicked()}
        >
          <FaTimes
            style={{
              margin: 0,
            }}
          />
        </CloseButton>
      </CardHeader>
      <FetchedDataTable
        columns={columns}
        data={auditData}
        isLoading={isLoading}
        filters={filters}
        onSortOrPageChange={setFilters}
        isFetching={isFetching}
      />
    </Card>
  );
};
