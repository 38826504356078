import { Loading } from 'components/common/Loading';
import { PageHelmet } from 'components/common/PageHelmet';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { usePublicSite } from 'hooks/public/usePublicSite';
import { LCDStyled } from './LCD.styled';
import { LCDContent } from './LCDContent';
import { LCDFooter } from './LCDFooter';
import { LCDHeader } from './LCDHeader';

export const LCD = () => {
  const { t } = useCommonTranslation();
  const { isReady } = usePublicSite();
  if (!isReady) return <Loading />;

  return (
    <LCDStyled>
      <PageHelmet title={t('lcd-tab-title')} />
      <LCDHeader />
      <LCDContent />
      <LCDFooter />
    </LCDStyled>
  );
};
