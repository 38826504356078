import React, { useContext } from 'react';

import {
  useAuthenticationTracking,
  useSendEvent,
} from '../hooks/analytics/useAnalytics';
import { IAnalyticsContext } from '../types/analytics';

const defaultContext: IAnalyticsContext = {
  sendEvent: undefined as any,
};

export const AnalyticsContext =
  React.createContext<IAnalyticsContext>(defaultContext);
AnalyticsContext.displayName = 'Analytics Context'; // Only for debugging

export const useAnalytics = () => useContext(AnalyticsContext);

export const AnalyticsProvider: React.FC = ({ children }) => {
  useAuthenticationTracking();
  const sendEvent = useSendEvent();

  return (
    <AnalyticsContext.Provider
      value={{
        sendEvent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
