import { useAuth } from 'context/AuthContext';
import { find } from 'lodash';
import { useCallback } from 'react';
import { UserProfileResponse } from 'types/authentication';

const getBookingUser = (user?: UserProfileResponse, memberId?: string) => {
  const memberFound = find(user?.other_members, { id: memberId });
  return memberFound || user;
};

export const useFamilyMember = (memberId?: string) => {
  const { sangixUser } = useAuth();
  return getBookingUser(sangixUser, memberId);
};

export const useGetFamilyMember = () => {
  const { sangixUser } = useAuth();
  return useCallback(
    (memberId?: string) => {
      return getBookingUser(sangixUser, memberId);
    },
    [sangixUser],
  );
};
