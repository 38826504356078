import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin-top: var(--s3);
  & > * + * {
    margin-left: var(--s2);
  }
`;
