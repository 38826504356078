import {
  CacheAndStaleTimeForUnlikelyChanged,
  useQueryWithToastError,
} from './queryUtils';
import { getAllGlobalAttributes } from '../services/global_attributes';
import { UseQueryOptions } from 'react-query/types/react/types';
import { AxiosResponse } from 'axios';
import { GlobalAttributesResponse } from '../types/common';

export const GLOBAL_ATTRIBUTES_QUERY_KEY = 'globalAttributes';
export const useQueryGlobalAttributes = (
  options?: UseQueryOptions<
    AxiosResponse<GlobalAttributesResponse>,
    any,
    GlobalAttributesResponse
  >,
) => {
  return useQueryWithToastError(
    [GLOBAL_ATTRIBUTES_QUERY_KEY],
    () => getAllGlobalAttributes(),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
      select: (data) => data.data,
      ...options,
    },
  );
};
