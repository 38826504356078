import React, { useMemo } from 'react';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { Card } from '../../../components/common';
import {
  CloseButton,
  DialogFormWrapper,
  FormBody,
  FormHeader,
  FormRow,
  FormTitle,
} from '../../../components/common/Forms/Forms.styled';
import { CardLoader } from '../../../components/common/Loading';
import { FaHospital, FaTimes } from 'react-icons/fa';
import { useSADSiteStatistics } from '../../../query/reports';
import {
  ALL_STAFF_ROLES,
  PATIENT,
  UserRoleId,
} from '../../../configs/constants';
import { UserRoleCount } from '../../../types/reports';
import {
  INFO_LABEL_DESIGN_LARGER,
  InfoLabelText,
} from '../../../components/common/Forms/InfoLabelText';
import { ButtonSetWithCloseButton } from '../../../components/Form/ButtonSetWithCloseButton';
import { toRemStr } from '../../../utils/helpers';
import { getRoleName } from '../../../hooks/staff/useRoleOptions';

export const AdminStatisticsSiteSummaryCard = ({
  onCloseClicked,
  siteSummaryId,
}: {
  siteSummaryId: string;
  onCloseClicked: () => any;
}) => {
  const { t } = useCommonTranslation();
  const { data, isLoading } = useSADSiteStatistics(siteSummaryId);

  const totalPatients = useMemo(() => {
    return data?.user_role_counts[PATIENT];
  }, [data]);

  const totalStaff = useMemo(() => {
    if (!data?.user_role_counts) return;
    return Object.keys(data?.user_role_counts)
      .filter((role) => role !== PATIENT)
      .reduce(
        (acc, role) =>
          acc + data?.user_role_counts[role as keyof UserRoleCount],
        0,
      );
  }, [data]);

  const design = INFO_LABEL_DESIGN_LARGER;

  return (
    <Card
      style={{
        minWidth: toRemStr(500),
      }}
    >
      <DialogFormWrapper>
        <CloseButton
          size="auto"
          variant="primary"
          iconOnly
          onClick={() => onCloseClicked()}
        >
          <FaTimes />
        </CloseButton>
        <FormHeader>
          <FaHospital />
          <FormTitle>{t('admin-statistics-summary')}</FormTitle>
        </FormHeader>

        <FormBody>
          <FormRow>
            <InfoLabelText
              label={t('total-patients')}
              text={totalPatients?.toString() || '-'}
              design={design}
            />
          </FormRow>
          <FormRow>
            <InfoLabelText
              label={t('total-staff')}
              text={totalStaff?.toString() || '-'}
              design={design}
            />
          </FormRow>
          {getRolesTuples(ALL_STAFF_ROLES).map((roleTuple, index) => (
            <FormRow key={`role${index}`}>
              <InfoLabelText
                label={getRoleName(t, roleTuple[0])}
                text={
                  data?.user_role_counts[
                    roleTuple[0] as keyof UserRoleCount
                  ]?.toString() || 0
                }
              />
              {roleTuple[1] && (
                <InfoLabelText
                  label={getRoleName(t, roleTuple[1])}
                  text={
                    data?.user_role_counts[
                      roleTuple[1] as keyof UserRoleCount
                    ]?.toString() || 0
                  }
                />
              )}
            </FormRow>
          ))}
          <FormRow>
            <InfoLabelText
              label={t('total-gp-surgeries')}
              text={data?.gp_surgery_count.toString() || 0}
              design={design}
            />
          </FormRow>
          <FormRow>
            <InfoLabelText
              label={t('total-icbs')}
              text={data?.ccg_count.toString() || 0}
              design={design}
            />
          </FormRow>
          <FormRow>
            <InfoLabelText
              label={t('total-other-referrers')}
              text={data?.referrer_count.toString() || 0}
              design={design}
            />
          </FormRow>
          <ButtonSetWithCloseButton
            onCloseClicked={onCloseClicked}
            closeLabel={t('close')}
          />
        </FormBody>
        {isLoading && <CardLoader fillWrapper={true} />}
      </DialogFormWrapper>
    </Card>
  );
};

function getRolesTuples(roleIds: UserRoleId[]) {
  const tuples: ([UserRoleId] | [UserRoleId, UserRoleId])[] = [];
  for (let i = 0; i < roleIds.length; i += 2) {
    const role1 = roleIds[i];
    const role2 = roleIds[i + 1];
    if (!role2) {
      tuples.push([role1]);
    } else {
      tuples.push([role1, role2]);
    }
  }
  return tuples;
}
