import React, { useState } from 'react';
import { Appointment } from '../../../types/appointments';
import { CalledAppointment } from './CalledAppointment';
import { ProcessingAppointment } from './ProcessingAppointment';
import { useUser } from '../../../query/users';
import { useAllGPSurgeries } from '../../../hooks/referrals/useAllGPSurgeries';
import { Popup } from '../../../components/Popup/Popup';
import { QueuedAppointmentProcessCard } from './QueuedAppointmentProcess.styled';
import { AppointmentAndUserComments } from './AppointmentAndUserComments';
import { hideIf } from '../../../styles/displayUtils';

interface IQueuedAppointmentProcessProps {
  appointment: Appointment;
  onClose: () => void;
}

export const QueuedAppointmentProcess: React.FunctionComponent<
  IQueuedAppointmentProcessProps
> = ({ onClose, ...rest }) => {
  const [currentAppointment, setCurrentAppointment] = useState<Appointment>({
    ...rest.appointment,
    tubes: undefined,
    forms: undefined,
  });
  const { isLoading: isUserLoading, data: user } = useUser(
    currentAppointment.user_member_id,
  );
  const { isLoading: isSurgeriesLoading, data: surgeriesResponse } =
    useAllGPSurgeries();

  const isUserOrSurgeriesLoading = isUserLoading || isSurgeriesLoading;

  const [showComment, setShowComment] = useState(false);

  return (
    <Popup
      size={'fullScreen'}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      <QueuedAppointmentProcessCard>
        <div style={hideIf(showComment)}>
          {(currentAppointment.status === 'QUEUE' ||
            currentAppointment.status === 'CALLED') && (
            <CalledAppointment
              onClose={onClose}
              currentAppointment={currentAppointment}
              setCurrentAppointment={setCurrentAppointment}
              user={user?.data}
              isUserOrSurgeriesLoading={isUserOrSurgeriesLoading}
              surgeries={surgeriesResponse}
              onShowCommentClicked={() => setShowComment(true)}
            />
          )}
          {currentAppointment.status === 'PROCESSING' && (
            <ProcessingAppointment
              appointment={currentAppointment}
              user={user?.data}
              isUserOrSurgeriesLoading={isUserOrSurgeriesLoading}
              surgeries={surgeriesResponse}
              onProcessingFinished={onClose}
              showCloseButton={false}
              onShowCommentClicked={() => setShowComment(true)}
              showComment={false}
            />
          )}
        </div>
        {showComment && (
          <AppointmentAndUserComments
            onClose={() => setShowComment(false)}
            currentAppointment={currentAppointment}
            setCurrentAppointment={setCurrentAppointment}
            user={user?.data}
          />
        )}
      </QueuedAppointmentProcessCard>
    </Popup>
  );
};
