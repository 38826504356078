import { useSiteResourceTypes } from 'query/resourceTypes';
import { useMemo } from 'react';

import { UNSAFE_FILTER_WITH_NO_PAGINATION } from './usePaginatedFilters';

export const usePrimaryResourceType = () => {
  const { data, isLoading } = useSiteResourceTypes({
    ...UNSAFE_FILTER_WITH_NO_PAGINATION,
  });
  const primaryResourceType = useMemo(() => {
    return data?.data?.find((item) => !item.staff_only);
  }, [data]);
  return { primaryResourceType, isLoading };
};
