import { useMutateCheckInKioskAppointment } from '../../query/public';
import {
  KIOSK_EARLY_ERROR,
  KIOSK_GENERAL_ERROR,
  KIOSK_LATE_ERROR,
  KioskCheckinErrorState,
} from './useKioskState';
import { checkErrorCode } from '../../utils/errors';
import { ErrorCode } from '../../utils/errorCodes';

export const useKioskCheckin = ({
  onError,
  onSuccess,
}: {
  onError: (type: KioskCheckinErrorState) => any;
  onSuccess?: () => any;
}) => {
  const { isLoading: isCheckinLoading, mutate } =
    useMutateCheckInKioskAppointment({
      onError: (error) => {
        if (checkErrorCode(error, ErrorCode.EARLY_CHECKIN_AP0012)) {
          onError(KIOSK_EARLY_ERROR);
        } else if (checkErrorCode(error, ErrorCode.LATE_CHECKIN_AP0013)) {
          onError(KIOSK_LATE_ERROR);
        } else {
          onError(KIOSK_GENERAL_ERROR);
        }
      },
      onSuccess,
    });

  return {
    isLoading: isCheckinLoading,
    checkin: mutate,
  };
};
