import {
  useOptionalBookingUser,
  usePatientBooking,
} from 'context/patient/modules/booking';
import { getUserFullname } from 'utils/user';

import { PopupTitle } from '../../../components/Popup/ConfirmDialog/ConfirmDialog.styled';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { useSite } from '../../../context/SiteContext';

export const PatientBookingFor = () => {
  const { t } = useCommonTranslation();
  const { booking } = usePatientBooking();
  const { activeSite } = useSite();
  const user = useOptionalBookingUser();
  const name = getUserFullname(user);

  return (
    <>
      {booking.stage !== 'site' &&
        booking.stage !== 'guidelines' &&
        booking.stage !== 'member' && (
          <>
            {activeSite && (
              <PopupTitle>
                {t('book-at-name', { siteName: activeSite.name })}
              </PopupTitle>
            )}
            {user && <PopupTitle>{t('book-as-name', { name })}</PopupTitle>}
          </>
        )}
    </>
  );
};
