import { CellWrapper, EllipseText } from './Table.styled';
import React from 'react';
import { HeaderGroup } from 'react-table';

export const WrappedCell = <D extends object>({
  column,
  renderId,
}: {
  column: HeaderGroup<D>;
  renderId: string;
}) => {
  return (
    <CellWrapper
      align={column.align}
      style={{
        marginLeft: 'var(--s2)',
        marginRight: 'var(--s3)',
      }}
    >
      <EllipseText
        align={column.align}
        style={column.renderWithOverflow ? { overflow: 'visible' } : {}}
      >
        {column.render(renderId)}
      </EllipseText>
    </CellWrapper>
  );
};
