import { TableHeadStyle } from '../../styles/Typography';
import {
  getNewColumnWidth,
  setColumnFilterAndSortKey,
} from '../../utils/table';
import DefaultInputFilter from '../../components/Table/DefaultInputFilter';
import { GeneralPractice } from '../../types/generalPractices';
import { useCommonTranslation } from '../i18n/useCommonTranslation';
import { useMemo } from 'react';
import { Column, FilterProps } from 'react-table';
import { ClinicalCommissioningGroup } from '../../types/ccg';

export const useGpColumns = ({
  ccgData,
  lastColumn,
}: {
  ccgData?: ClinicalCommissioningGroup[] | null;
  lastColumn?: Column<GeneralPractice>;
}) => {
  const { t } = useCommonTranslation();

  return useMemo(() => {
    return [
      {
        Header: t('gp-surgery-name'),
        accessor: 'name',
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(40),
        Filter: (props: FilterProps<object>) => (
          <DefaultInputFilter
            {...props}
            inputProps={{
              placeholder: t('gp-surgery-name'),
            }}
          />
        ),
      },
      {
        Header: t('ccg'),
        id: setColumnFilterAndSortKey('ccgName', 'ccg.Name'),
        accessor: (row: GeneralPractice) =>
          ccgData?.find((ccg) => ccg.id === row.ccg_id)?.name,
        width: getNewColumnWidth(40),
        Filter: (props: FilterProps<object>) => (
          <DefaultInputFilter
            {...props}
            inputProps={{
              placeholder: t('ccg'),
            }}
          />
        ),
      },
      lastColumn,
    ] as Column<GeneralPractice>[];
  }, [t, ccgData, lastColumn]);
};
