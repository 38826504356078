import {
  DialogFormWrapper,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import { CardLoader } from 'components/common/Loading';
import { ATTRIBUTE_SEPARATOR } from 'configs/constants';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FaEnvelope } from 'react-icons/fa';

import {
  SERVER_EMAIL_SUBJ_APPT,
  SERVER_EMAIL_SUBJ_CANCEL,
  SERVER_EMAIL_SUBJ_CONFIRMATION,
  SERVER_EMAIL_SUBJ_CREATE,
  SERVER_EMAIL_SUBJ_DAILY_REPORT,
  SERVER_EMAIL_SUBJ_REMINDER,
  SERVER_EMAIL_SUBJ_SUPPORT_FORM,
  SERVER_EMAIL_SUBJ_WAITING_LIST,
} from '../../../../configs/siteAndTrustAttributes';
import { UseEmailsPreferencesFormReturn } from './EmailsPreferencesHooks';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';

interface Props {
  isFetching: boolean;
  attributesForm: UseEmailsPreferencesFormReturn;
}

export const EmailSubjectsForm: React.FC<Props> = ({
  isFetching,
  attributesForm,
}) => {
  const { t } = useCommonTranslation();

  const {
    formState: { errors },
    attributeRegister,
    control,
  } = attributesForm;
  const watchedValues = useWatch({ control });

  return (
    <DialogFormWrapper>
      <FormHeader>
        <FaEnvelope />
        <FormTitle>{t('email-subject-naming')}</FormTitle>
      </FormHeader>
      <FormRow>
        <Input
          label={t('create-account')}
          placeholder={t('create-account')}
          {...attributeRegister(
            SERVER_EMAIL_SUBJ_CREATE,
            (e) => e.target.value,
            {
              maxLength: {
                value: 78,
                message: t('max-char-input', { count: 78 }),
              },
            },
          )}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === SERVER_EMAIL_SUBJ_CREATE,
            )?.value_str
          }
          hasError={Boolean(
            errors[
              (ATTRIBUTE_SEPARATOR + SERVER_EMAIL_SUBJ_CREATE) as 'attributes'
            ],
          )}
          helperText={
            errors[(ATTRIBUTE_SEPARATOR + SERVER_EMAIL_SUBJ_CREATE) as 'id']
              ?.message
          }
        />
        <Input
          label={t('confirmation')}
          placeholder={t('confirmation')}
          {...attributeRegister(
            SERVER_EMAIL_SUBJ_CONFIRMATION,
            (e) => e.target.value,
            {
              maxLength: {
                value: 78,
                message: t('max-char-input', { count: 78 }),
              },
            },
          )}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === SERVER_EMAIL_SUBJ_CONFIRMATION,
            )?.value_str
          }
          hasError={Boolean(
            errors[
              (ATTRIBUTE_SEPARATOR +
                SERVER_EMAIL_SUBJ_CONFIRMATION) as 'attributes'
            ],
          )}
          helperText={
            errors[
              (ATTRIBUTE_SEPARATOR + SERVER_EMAIL_SUBJ_CONFIRMATION) as 'id'
            ]?.message
          }
        />
      </FormRow>
      <FormRow>
        <Input
          label={t('appt-cancel')}
          placeholder={t('appt-cancel')}
          {...attributeRegister(
            SERVER_EMAIL_SUBJ_CANCEL,
            (e) => e.target.value,
            {
              maxLength: {
                value: 78,
                message: t('max-char-input', { count: 78 }),
              },
            },
          )}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === SERVER_EMAIL_SUBJ_CANCEL,
            )?.value_str
          }
          hasError={Boolean(
            errors[
              (ATTRIBUTE_SEPARATOR + SERVER_EMAIL_SUBJ_CANCEL) as 'attributes'
            ],
          )}
          helperText={
            errors[(ATTRIBUTE_SEPARATOR + SERVER_EMAIL_SUBJ_CANCEL) as 'id']
              ?.message
          }
        />
        <Input
          label={t('appt-post')}
          placeholder={t('appt-post')}
          {...attributeRegister(SERVER_EMAIL_SUBJ_APPT, (e) => e.target.value, {
            maxLength: {
              value: 78,
              message: t('max-char-input', { count: 78 }),
            },
          })}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === SERVER_EMAIL_SUBJ_APPT,
            )?.value_str
          }
          hasError={Boolean(
            errors[
              (ATTRIBUTE_SEPARATOR + SERVER_EMAIL_SUBJ_APPT) as 'attributes'
            ],
          )}
          helperText={
            errors[(ATTRIBUTE_SEPARATOR + SERVER_EMAIL_SUBJ_APPT) as 'id']
              ?.message
          }
        />
      </FormRow>
      <FormRow>
        <Input
          label={t('email-reminder')}
          placeholder={t('email-reminder')}
          {...attributeRegister(
            SERVER_EMAIL_SUBJ_REMINDER,
            (e) => e.target.value,
            {
              maxLength: {
                value: 78,
                message: t('max-char-input', { count: 78 }),
              },
            },
          )}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === SERVER_EMAIL_SUBJ_REMINDER,
            )?.value_str
          }
          hasError={Boolean(
            errors[
              (ATTRIBUTE_SEPARATOR + SERVER_EMAIL_SUBJ_REMINDER) as 'attributes'
            ],
          )}
          helperText={
            errors[(ATTRIBUTE_SEPARATOR + SERVER_EMAIL_SUBJ_REMINDER) as 'id']
              ?.message
          }
        />
        <Input
          label={t('daily-report')}
          placeholder={t('daily-report')}
          {...attributeRegister(
            SERVER_EMAIL_SUBJ_DAILY_REPORT,
            (e) => e.target.value,
            {
              maxLength: {
                value: 78,
                message: t('max-char-input', { count: 78 }),
              },
            },
          )}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === SERVER_EMAIL_SUBJ_DAILY_REPORT,
            )?.value_str
          }
          hasError={Boolean(
            errors[
              (ATTRIBUTE_SEPARATOR +
                SERVER_EMAIL_SUBJ_DAILY_REPORT) as 'attributes'
            ],
          )}
          helperText={
            errors[
              (ATTRIBUTE_SEPARATOR + SERVER_EMAIL_SUBJ_DAILY_REPORT) as 'id'
            ]?.message
          }
        />
      </FormRow>
      <FormRow>
        <Input
          label={t('waiting-list')}
          placeholder={t('waiting-list')}
          {...attributeRegister(
            SERVER_EMAIL_SUBJ_WAITING_LIST,
            (e) => e.target.value,
            {
              maxLength: {
                value: 78,
                message: t('max-char-input', { count: 78 }),
              },
            },
          )}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === SERVER_EMAIL_SUBJ_WAITING_LIST,
            )?.value_str
          }
          hasError={Boolean(
            errors[
              (ATTRIBUTE_SEPARATOR +
                SERVER_EMAIL_SUBJ_WAITING_LIST) as 'attributes'
            ],
          )}
          helperText={
            errors[
              (ATTRIBUTE_SEPARATOR + SERVER_EMAIL_SUBJ_WAITING_LIST) as 'id'
            ]?.message
          }
        />
        <Input
          label={t('support-form')}
          placeholder={t('support-form')}
          {...attributeRegister(
            SERVER_EMAIL_SUBJ_SUPPORT_FORM,
            (e) => e.target.value,
            {
              maxLength: {
                value: 78,
                message: t('max-char-input', { count: 78 }),
              },
            },
          )}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === SERVER_EMAIL_SUBJ_SUPPORT_FORM,
            )?.value_str
          }
          hasError={Boolean(
            errors[
              (ATTRIBUTE_SEPARATOR +
                SERVER_EMAIL_SUBJ_SUPPORT_FORM) as 'attributes'
            ],
          )}
          helperText={
            errors[
              (ATTRIBUTE_SEPARATOR + SERVER_EMAIL_SUBJ_SUPPORT_FORM) as 'id'
            ]?.message
          }
        />
      </FormRow>
      {isFetching && <CardLoader fillWrapper={true} />}
    </DialogFormWrapper>
  );
};
