import { CommonButton as Button } from 'components/common/Forms/Button';
import DefaultInputFilter from 'components/Table/DefaultInputFilter';
import FilterByDatePicker from 'components/Table/FilterByDatePicker';
import { RowActions } from 'components/Table/Table.styled';
import React, { ReactElement, useMemo } from 'react';
import { FaCalendarAlt, FaTrashAlt } from 'react-icons/fa';
import { Column, FilterProps } from 'react-table';
import { TableHeadStyle } from 'styles/Typography';
import { BankHolidayData, BankHolidayFilters } from 'types/bankHoliday';
import { getNewColumnWidth, setColumnFilterAndSortKey } from 'utils/table';
import {
  formatDateOnlySite,
  parseIsoDate,
} from '../../../../../utils/dateUtil';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { FetchedDataTable } from '../../../../Table/FetchedDataTable';
import { PaginatedFiltersWithSet } from '../../../../../hooks/usePaginatedFilters';
import { ItemsResponse } from '../../../../../types/ItemsWithCountResponse';
import { getFilterControls } from '../../../../Table/TableUtils';

type Props = {
  bankHolidayData?: ItemsResponse<BankHolidayData>;
  handleDeleteBankHoliday: (value: string) => void;
  isLoading: boolean;
  isFetching: boolean;
} & PaginatedFiltersWithSet<BankHolidayFilters>;

export const BankHolidayTable = ({
  bankHolidayData,
  handleDeleteBankHoliday,
  setFilters,
  filters,
  isLoading,
  isFetching,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();

  const columns = useMemo(() => {
    return [
      {
        Header: t('holiday-name'),
        accessor: 'name',
        cellStyle: {
          ...TableHeadStyle,
        },
        width: getNewColumnWidth(40),
        Filter: (props: FilterProps<object>) => (
          <DefaultInputFilter
            {...props}
            inputProps={{
              placeholder: t('name'),
            }}
          />
        ),
      },
      {
        Header: t('date'),
        accessor: (bankHoliday: BankHolidayData) =>
          formatDateOnlySite(parseIsoDate(bankHoliday.holiday_date)!),
        id: setColumnFilterAndSortKey('date', 'HolidayDate'),
        width: getNewColumnWidth(20),
        Filter: (props: FilterProps<object>) => (
          <FilterByDatePicker
            {...props}
            inputProps={{
              placeholder: t('date'),
              icon: <FaCalendarAlt />,
            }}
          />
        ),
      },
      {
        ...getFilterControls({ filters, setFilters, t }),
        Cell: ({ value }: { value: string }) => {
          return (
            <RowActions>
              <Button
                size="auto"
                iconOnly
                variant="danger"
                title={t('remove-bank-holday')}
                onClick={() => handleDeleteBankHoliday(value)}
              >
                <FaTrashAlt />
              </Button>
            </RowActions>
          );
        },
      },
    ] as Column<BankHolidayData>[];
  }, [t, handleDeleteBankHoliday, filters, setFilters]);

  return (
    <FetchedDataTable
      columns={columns}
      data={bankHolidayData}
      isLoading={isLoading}
      filters={filters}
      onSortOrPageChange={setFilters}
      isFetching={isFetching}
    />
  );
};
