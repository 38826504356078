import Axios from 'configs/Axios';
import { ItemsResponse } from 'types/ItemsWithCountResponse';

export type WaitingListDataEntry = {
  from_hour: number;
  reservation_date: string;
  to_hour: number;
  user_member_id: string;
  resource_type_id?: string;
};
export type WaitingListData = WaitingListDataEntry[];

export type WaitingListResponse = ItemsResponse<WaitingListDataEntry>;

export const saveWaitingList = (data: WaitingListData) =>
  Axios.post<WaitingListData>(`/waitingList`, data);

export type DeleteWaitingListParams = {
  memberId: string;
  resourceTypeId: string;
};
export const deleteWaitingList = (params: DeleteWaitingListParams) =>
  Axios.delete(`/waitingList/${params.memberId}/${params.resourceTypeId}`);

export type GetWaitingListParams = { memberId: string; resourceTypeId: string };
export const getWaitingList = (params: GetWaitingListParams) =>
  Axios.get<WaitingListData>(
    `/waitingList/${params.memberId}/${params.resourceTypeId}`,
  );
