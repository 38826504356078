import { ResourceType } from '../../../../../types/resource-types';
import {
  formatDateOnlySite,
  getWeekRange,
} from '../../../../../utils/dateUtil';
import { useTemplatesApptCounts } from '../../../../../hooks/apptTemplate/useTemplatesApptCounts';
import { WeeklyApptCountsCard } from './WeeklyApptCountsCard';

export const TemplateApptCountsCard = ({
  resourceType,
  showOnlineMustBeShown,
  selectedDate,
  isLoading,
}: {
  resourceType?: ResourceType;
  showOnlineMustBeShown: boolean;
  selectedDate: Date;
  isLoading: boolean;
}) => {
  const { fromDate, toDate } = getWeekRange(selectedDate);

  const { isFetching: isTemplateApptsCountFetching, templateCounts } =
    useTemplatesApptCounts({
      resourceTypeId: resourceType?.id,
      fromDate,
      toDate,
    });

  const isFetching = isLoading || isTemplateApptsCountFetching;

  return (
    <WeeklyApptCountsCard
      showOnlineMustBeShown={showOnlineMustBeShown}
      selectedDate={selectedDate}
      isLoading={isFetching}
      disabled={resourceType === undefined}
      templateCounts={templateCounts}
      subtitle={`(${formatDateOnlySite(fromDate)} - ${formatDateOnlySite(
        toDate,
      )})`}
    />
  );
};
