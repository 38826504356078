import { usePatientBooking } from 'context/patient/modules/booking';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { useCallback } from 'react';
import { Appointment } from 'types/appointments';
import { DATE_FNS_TIME, formatDate, padTime } from 'utils/dateUtil';
import { PatientBookingListItem } from './PatientBookingListItem';

type PatientBookingHourItemProps = {
  slot: {
    hour: number;
    minutes: number[];
    lastAppointmentEndTime: Date;
  };
  appts: Appointment[];
};
export const PatientBookingHourItem = ({
  slot: { hour, minutes, lastAppointmentEndTime },
  appts,
}: PatientBookingHourItemProps) => {
  const { t } = useCommonTranslation();
  const {
    updateBooking,
    booking: { date },
  } = usePatientBooking();
  const selectHour = useCallback(() => {
    const dateWithHour = new Date(date);
    dateWithHour.setHours(hour);
    updateBooking({ stage: 'appt', date: dateWithHour });
  }, [updateBooking, date, hour]);

  return (
    <PatientBookingListItem
      disabled={appts.length === 0}
      range={`${padTime(hour)}:${padTime(minutes[0])} - ${formatDate(
        lastAppointmentEndTime,
        DATE_FNS_TIME,
      )}`}
      text={t('appointments-available', { amount: appts.length })}
      btnText={t('select')}
      btnOnClick={selectHour}
    />
  );
};
