import { CommonButton } from 'components/common/Forms/Button';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { orderBy } from 'lodash';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { DATE_FNS_UK_DATE_FORMAT, formatDate, parseDate } from 'utils/dateUtil';
import { BulletHeaderTD } from './BulletHeaderTD';
import {
  BulletBodyTD,
  BulletBodyTR,
  BulletHeaderFilterButtonBar,
  BulletHeaderFilterTD,
  BulletHeaderTR,
  BulletTableStyled,
} from './BulletTable.styled';
import { BulletTableFilter, BulletTableFilterType } from './BulletTableFilter';
import { SortDir } from './SortDir';

type TableHeader = {
  text: string;
  type?: BulletTableFilterType;
};
type BodyTD = {
  text: ReactNode;
  bold?: boolean;
  btn?: boolean;
};
type BodyTR = {
  cells: BodyTD[];
  selected?: boolean;
};
export type BulletTableProps = {
  headers: TableHeader[];
  sort?: boolean;
  filter?: boolean;
  body?: BodyTR[];
};

type FilterValue = string | undefined;
export type BulletTableForm = {
  filter: FilterValue[];
};

export const BulletTable = ({
  headers,
  sort,
  filter,
  body,
}: BulletTableProps) => {
  const { t } = useCommonTranslation();

  const defaultFilters = useMemo(() => headers.map(() => ''), [headers]);
  const form = useForm<BulletTableForm>({
    defaultValues: { filter: [...defaultFilters] },
  });
  const { setValue } = form;

  const [sortCol, setSortCol] = useState<number>();
  const [sortDir, setSortDir] = useState<SortDir>('asc');
  const [filtersApplied, setFiltersApplied] = useState<FilterValue[]>([
    ...defaultFilters,
  ]);

  const bodyFiltered = body?.filter((row) => {
    return row.cells.every((cell, cellCol) => {
      const filterValue = filtersApplied[cellCol];
      if (!filterValue) return true;
      if (!cell.text) return false;
      return String(cell.text)
        .toLowerCase()
        .includes(filterValue.toLowerCase());
    });
  });

  const bodySorted =
    sortCol === undefined
      ? bodyFiltered
      : orderBy(bodyFiltered, [(row) => row.cells[sortCol].text], [sortDir]);

  const onFind: SubmitHandler<BulletTableForm> = useCallback(
    (values, event) => {
      event?.preventDefault();
      const filterValuesProcessed = values.filter.map(
        (filterValue, filterCol) => {
          if (headers[filterCol].type === 'date' && filterValue) {
            return formatDate(parseDate(filterValue), DATE_FNS_UK_DATE_FORMAT);
          }
          return filterValue;
        },
      );
      setFiltersApplied(filterValuesProcessed);
    },
    [setFiltersApplied, headers],
  );
  const onClear = useCallback(() => {
    // setting the whole "filter" list at once does not work with
    defaultFilters.map((value, index) => setValue(`filter.${index}`, value));
    setFiltersApplied([...defaultFilters]);
  }, [setValue, defaultFilters, setFiltersApplied]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onFind)}>
        <BulletTableStyled>
          <thead>
            <BulletHeaderTR>
              {headers.map((header, headerCol) => (
                <BulletHeaderTD
                  key={headerCol}
                  sort={sort}
                  sortDir={sortCol === headerCol ? sortDir : undefined}
                  onSortClick={() => {
                    const nextSortCol =
                      sortCol === headerCol && sortDir === 'desc'
                        ? undefined
                        : headerCol;
                    const nextSortDir =
                      sortCol === headerCol && sortDir === 'asc'
                        ? 'desc'
                        : 'asc';
                    setSortCol(nextSortCol);
                    setSortDir(nextSortDir);
                  }}
                >
                  {header.text}
                </BulletHeaderTD>
              ))}
            </BulletHeaderTR>
            {filter && (
              <BulletHeaderTR noShadow>
                {headers.map((header, headerCol) => (
                  <BulletHeaderFilterTD key={headerCol}>
                    <BulletTableFilter
                      type={header.type || 'text'}
                      name={`filter.${headerCol}`}
                      placeholder={header.text}
                    />
                    {headerCol === headers.length - 1 && (
                      <BulletHeaderFilterButtonBar>
                        <CommonButton type="submit" variant="primary">
                          {t('find')}
                        </CommonButton>
                        <CommonButton
                          type="button"
                          variant="danger"
                          onClick={onClear}
                        >
                          {t('clear')}
                        </CommonButton>
                      </BulletHeaderFilterButtonBar>
                    )}
                  </BulletHeaderFilterTD>
                ))}
              </BulletHeaderTR>
            )}
          </thead>
          <tbody>
            {bodySorted?.map((row, index) => (
              <BulletBodyTR key={index} selected={row.selected}>
                {row.cells.map((td, index) => (
                  <BulletBodyTD key={index} bold={td.bold} btn={td.btn}>
                    {td.text}
                  </BulletBodyTD>
                ))}
              </BulletBodyTR>
            ))}
          </tbody>
        </BulletTableStyled>
      </form>
    </FormProvider>
  );
};
