import { ControlledDatePickerInput } from 'components/common/Forms/controlled/ControlledDatePickerInput';
import Input from 'components/common/Forms/Input';
import { useFormContext } from 'react-hook-form';

type SelectedFilterProps = {
  name: string;
  placeholder?: string;
};
export type BulletTableFilterType = 'text' | 'date';
type BulletTableFilterProps = {
  type: BulletTableFilterType;
} & SelectedFilterProps;

const BulletTableFilterText = ({ name, placeholder }: SelectedFilterProps) => {
  const { register } = useFormContext();
  return (
    <Input
      flexWidth
      noSpaceForHelperText
      {...register(name)}
      placeholder={placeholder}
    />
  );
};

const BulletTableFilterDate = ({ name, placeholder }: SelectedFilterProps) => {
  const { control, formState } = useFormContext();
  return (
    <ControlledDatePickerInput
      control={control}
      formState={formState}
      name={name}
      label={undefined}
      noSpaceForHelperText
      width="var(--width-standard)"
      placeholder={placeholder}
    />
  );
};

export const BulletTableFilter = ({
  type,
  ...rest
}: BulletTableFilterProps) => {
  const Filter =
    type === 'text' ? BulletTableFilterText : BulletTableFilterDate;
  return <Filter {...rest} />;
};
