import {
  usePatientBooking,
  useUnassignedTrustBookingSites,
} from 'context/patient/modules/booking';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { useCallback, useEffect, useState } from 'react';

import {
  CommonButton,
  CommonLink,
} from '../../../components/common/Forms/Button';
import { CardLoader } from '../../../components/common/Loading';
import { useSite } from '../../../context/SiteContext';
import { usePublicSiteQuery } from '../../../query/public';
import { patientURL } from '../../../routes/AppRoutes';
import { errorToast } from '../../../utils/toast';
import {
  PatientBookingButtonBarRight,
  PatientBookingButtonBarStyled,
  PatientBookingPickSiteListWrapper,
  PatientBookingStageTitle,
} from './PatientBooking.styled';
import { PatientBookingPickSiteAssignedList } from './PatientBookingPickSiteAssignedList';
import { PatientBookingPickSiteUnassignedTrustList } from './PatientBookingPickSiteUnassignedTrustList';

type State =
  | 'initial'
  | 'loadingSelectedSite'
  | 'updatingBooking'
  | 'errorUpdatingBooking';

const loadingStates: State[] = ['loadingSelectedSite', 'updatingBooking'];

export const PatientBookingPickSite = () => {
  const { t } = useCommonTranslation();
  const { updateBooking } = usePatientBooking();
  const { setActiveSite, activeSite } = useSite();
  const { sites: unassignedTrustSites } = useUnassignedTrustBookingSites();
  const [siteListType, setSiteListType] = useState<'available' | 'trust'>(
    'available',
  );
  const [selectedSiteId, setSelectedSiteId] = useState<string>();
  const { data: selectedSiteRes } = usePublicSiteQuery(selectedSiteId);
  const [state, setState] = useState<State>('initial');

  useEffect(() => {
    if (state === 'loadingSelectedSite' && selectedSiteRes) {
      setState('updatingBooking');
      const site = selectedSiteRes.data;
      (async () => {
        try {
          await setActiveSite(site, {
            save: true,
            showLoader: false,
          });
          updateBooking({ stage: 'guidelines', siteId: site.id });
        } catch (err) {
          setSelectedSiteId(undefined);
          setState('errorUpdatingBooking');
          errorToast(err);
        }
      })();
    }
  }, [setActiveSite, selectedSiteRes, updateBooking, state]);

  const handleSiteSelect = useCallback((siteId: string) => {
    setSelectedSiteId(siteId);
    setState('loadingSelectedSite');
  }, []);

  const isLoading = loadingStates.includes(state);

  const showUnassignedTrustSitesButton =
    siteListType === 'available' &&
    unassignedTrustSites &&
    unassignedTrustSites.length > 0 &&
    !activeSite.disable_patient_registration_and_switch;
  const showAddHospitalButton =
    !showUnassignedTrustSitesButton &&
    !activeSite.disable_patient_registration_and_switch;

  return (
    <>
      <PatientBookingStageTitle>{t('book-at')}</PatientBookingStageTitle>
      {isLoading ? (
        <CardLoader />
      ) : (
        <PatientBookingPickSiteListWrapper>
          {siteListType === 'available' && (
            <PatientBookingPickSiteAssignedList
              onSiteSelect={handleSiteSelect}
            />
          )}
          {siteListType === 'trust' && (
            <PatientBookingPickSiteUnassignedTrustList
              onSiteSelect={handleSiteSelect}
            />
          )}
        </PatientBookingPickSiteListWrapper>
      )}
      <PatientBookingButtonBarStyled>
        {showUnassignedTrustSitesButton && (
          <CommonButton
            variant="primary"
            onClick={() => setSiteListType('trust')}
          >
            {t('book-at-other-location')}
          </CommonButton>
        )}
        {showAddHospitalButton && (
          <CommonLink variant="primary" to={`${patientURL}/add-hospital`}>
            {t('book-add-other-location')}
          </CommonLink>
        )}
        <PatientBookingButtonBarRight>
          {siteListType === 'trust' && (
            <CommonButton
              variant="primary"
              size="standard"
              onClick={() => setSiteListType('available')}
            >
              {t('go-back')}
            </CommonButton>
          )}
          <CommonLink
            type="button"
            variant="secondary"
            size="large"
            to={patientURL}
          >
            {t('back-to-menu')}
          </CommonLink>
        </PatientBookingButtonBarRight>
      </PatientBookingButtonBarStyled>
    </>
  );
};
