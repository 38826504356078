import { useCallback } from 'react';
import { isToday, parseIsoDate } from '../../utils/dateUtil';
import { Appointment } from '../../types/appointments';
import { useRole } from '../../context/RoleContext';
import {
  ROLE_OFFSITE_RECEPTIONIST,
  THIRD_PARTY,
} from '../../configs/constants';

export const useShouldShowNotPreparedButton = () => {
  const { hasAnyOfRoles } = useRole();
  return useCallback(
    (appointment: Appointment) =>
      appointment.status === 'BOOKED' &&
      isToday(parseIsoDate(appointment.appt_time)) &&
      !hasAnyOfRoles(ROLE_OFFSITE_RECEPTIONIST, THIRD_PARTY),
    [hasAnyOfRoles],
  );
};
