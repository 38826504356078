import React, { forwardRef, ReactElement, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import {
  CommonInputHelperText,
  CommonInputHelperWrapper,
  CommonInputIcon,
  CommonInputInner,
  CommonInputLabel,
  CommonInputLabelWrapper,
  CommonInputWrapper,
  Required,
  StyledCommonInput,
  TogglePassword,
} from './Forms.styled';
import { RequiredLabel } from './RequiredLabel';
import { LabelPositionType } from './Select';
import {
  FontBasedSize,
  LABEL_BEFORE,
  LABEL_IN_CORNER,
} from '../../../styles/fontsStyleUtils';

export type GlobalInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'size' | 'disabled'
> &
  FontBasedSize & {
    labelPosition?: LabelPositionType;
    label?: React.ReactNode;
    name: string;
    id?: string;
    type?: string;
    disabled?: boolean;
    placeholder?: string;
    hasError?: boolean;
    helperText?: string;
    fixedLabelWidth?: string;
    prefilled?: boolean;
    icon?: React.ReactNode;
    iconRight?: boolean;
    noSpaceForHelperText?: boolean;
    disableAutoComplete?: boolean;
    hideTogglePassword?: boolean;
    hideNativeDate?: boolean;
    width?: string;
    flexWidth?: boolean;
    containerStyle?: React.CSSProperties;
  };

export const CommonInput = forwardRef<HTMLInputElement, GlobalInputProps>(
  function CommonInput(
    {
      labelPosition = LABEL_IN_CORNER,
      type = 'text',
      label,
      name,
      id,
      disabled,
      placeholder,
      hasError,
      required,
      helperText,
      className,
      icon,
      iconRight,
      prefilled,
      fixedLabelWidth,
      disableAutoComplete,
      noSpaceForHelperText,
      hideTogglePassword,
      hideNativeDate,
      width,
      flexWidth,
      containerStyle,
      fontBasedSize,
      ...rest
    },
    ref,
  ): ReactElement {
    const [inputType, setInputType] = useState<string>(type);

    return (
      <CommonInputWrapper
        className={className}
        flexWidth={flexWidth}
        width={width}
        style={containerStyle}
        fontBasedSize={fontBasedSize}
      >
        <CommonInputLabelWrapper
          labelPosition={labelPosition}
          disabled={disabled}
          fontBasedSize={fontBasedSize}
        >
          <CommonInputHelperWrapper noSpaceForHelperText={noSpaceForHelperText}>
            <CommonInputInner isDisabled={disabled} iconRight={iconRight}>
              {Boolean(icon) && (
                <CommonInputIcon disabled={disabled}>{icon}</CommonInputIcon>
              )}
              {!hideTogglePassword && type === 'password' && (
                <TogglePassword
                  tabIndex={-1}
                  type="button"
                  disabled={disabled}
                  onClick={() =>
                    !disabled &&
                    setInputType((prev) =>
                      prev === 'password' ? 'text' : 'password',
                    )
                  }
                >
                  {inputType === 'password' ? <FaEyeSlash /> : <FaEye />}
                </TogglePassword>
              )}
              <StyledCommonInput
                allow-1password="no" // Disables 1Password
                data-lpignore="true" // Disables LastPass
                ref={ref}
                type={inputType}
                name={name}
                id={id || name}
                disabled={disabled}
                placeholder={placeholder}
                hasError={hasError}
                hasIcon={Boolean(icon)}
                hideNativeDate={hideNativeDate}
                fontBasedSize={fontBasedSize}
                {...(prefilled ? { value: '-----------' } : {})}
                {...(disableAutoComplete ? { autocomplete: 'off' } : {})}
                {...rest}
              />
              {label && labelPosition === LABEL_IN_CORNER && (
                <CommonInputLabel htmlFor={id || name}>
                  <RequiredLabel label={label} required={required} />
                </CommonInputLabel>
              )}
            </CommonInputInner>
            {Boolean(helperText) && !disabled && (
              <CommonInputHelperText hasError={hasError}>
                {helperText}
              </CommonInputHelperText>
            )}
          </CommonInputHelperWrapper>
          {label && labelPosition === LABEL_BEFORE && (
            <CommonInputLabel
              htmlFor={id || name}
              fixedLabelWidth={fixedLabelWidth}
            >
              {label} {required && <Required>*</Required>}
            </CommonInputLabel>
          )}
        </CommonInputLabelWrapper>
      </CommonInputWrapper>
    );
  },
);

/**
 * @deprecated Prefer use the named export
 */
export default CommonInput;
