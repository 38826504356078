import { useMemo } from 'react';
import { DateRange, DateRangeType } from 'types/calendar';
import { ResourceType } from 'types/resource-types';
import {
  DATE_FNS_UK_DATE_WITH_FULL_DATE_FORMAT_NO_DASH,
  DateTimeToMinuteOnlyISOString,
  formatDate,
  formatJustMinutesWithDoubleQuote,
  parseIsoDate,
} from 'utils/dateUtil';
import { toRem } from 'utils/helpers';
import { useSiteCalendarWeekDays } from '../../../../hooks/site/useSiteCalendarWeekDays';
import { Appointment } from '../../../../types/appointments';
import { getAppointmentStartMinutesPerHour } from '../../../../utils/appointmentUtil';
import { CalendarHourlySlots } from './CalendarHourlySlots';
import {
  CalendarDateRangeViewWrapper,
  TimeSlotPlaceHolder,
  DayTitle,
  DayOuterWrapper,
  HourRow,
  DayWrapper,
  CalendarRefreshButtonWrapper,
} from './calendar.styled';
import { FaEye } from 'react-icons/fa';
import { CalendarWithNoDays } from './CalendarWithNoDays';
import { CommonButton } from '../../../common/Forms/Button';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';

interface Props {
  openHourInSeconds: number;
  closeHourInSeconds: number;
  dateRange: DateRange;
  selectedResourceType: ResourceType;
  futureAppointmentsByDate: {
    [key in DateTimeToMinuteOnlyISOString]: Appointment[];
  };
  setSelectedAppointmentsSlot: (props: DateTimeToMinuteOnlyISOString) => void;
  selectedAppointmentSlot?: DateTimeToMinuteOnlyISOString;
  dateRangeType: DateRangeType;
  onDateTitleClick: (date: Date) => void;
  onRefreshClick: () => void;
}

export const CalendarDateRangeView = ({
  openHourInSeconds,
  closeHourInSeconds,
  dateRange,
  selectedResourceType,
  futureAppointmentsByDate,
  setSelectedAppointmentsSlot,
  selectedAppointmentSlot,
  dateRangeType,
  onDateTitleClick,
  onRefreshClick,
}: Props) => {
  const { t } = useCommonTranslation();

  const days = useSiteCalendarWeekDays({
    RTInterval: selectedResourceType.length,
    closeHourInSeconds,
    openHourInSeconds,
    dateRange,
  });

  const startingMinutes = useMemo(
    () => getAppointmentStartMinutesPerHour(selectedResourceType.length),
    [selectedResourceType],
  );

  if (days.length === 0) {
    return (
      <CalendarWithNoDays
        date={dateRange.fromDate}
        dateRangeType={dateRangeType}
        onDateTitleClick={onDateTitleClick}
      />
    );
  }

  return (
    <>
      <CalendarDateRangeViewWrapper
        style={{ marginTop: dateRangeType === 'day' ? 0 : undefined }}
      >
        {days.map((calendar, i) => {
          return (
            <DayOuterWrapper key={`weekOuterWrap_${i}`}>
              <DayTitle
                style={{
                  zoom: dateRangeType === 'day' ? 1.5 : undefined,
                  position: 'relative',
                }}
                onClick={() => onDateTitleClick(parseIsoDate(calendar.date))}
              >
                {formatDate(
                  parseIsoDate(calendar.date),
                  DATE_FNS_UK_DATE_WITH_FULL_DATE_FORMAT_NO_DASH,
                )}
                {dateRangeType === 'week' && (
                  <FaEye
                    style={{
                      zoom: 0.8,
                      marginLeft: 'var(--s2)',
                      color: 'var(--primary)',
                      position: 'absolute',
                      top: 'var(--s1)',
                    }}
                  />
                )}
              </DayTitle>
              <DayWrapper
                key={`weekWrap_${i}`}
                style={{
                  zoom:
                    dateRangeType === 'day'
                      ? selectedResourceType.length < 10
                        ? 2
                        : 3
                      : undefined,
                }}
              >
                <HourRow>
                  <TimeSlotPlaceHolder
                    style={{
                      paddingRight: `${toRem(6)}rem`,
                      maxWidth: `${toRem(24)}rem`,
                      minHeight: 'unset',
                    }}
                  />
                  {startingMinutes.map((startingMinute, index) => {
                    return (
                      <TimeSlotPlaceHolder
                        key={`interval_${index}`}
                        style={{ minHeight: 'unset' }}
                      >
                        {formatJustMinutesWithDoubleQuote(startingMinute)}
                      </TimeSlotPlaceHolder>
                    );
                  })}
                </HourRow>
                {calendar.hours.map((hr) => {
                  return (
                    <HourRow key={hr.hour}>
                      <TimeSlotPlaceHolder
                        style={{
                          maxWidth: `${toRem(24)}rem`,
                          justifyContent: 'flex-end',
                          paddingRight: `${toRem(6)}rem`,
                        }}
                      >
                        {hr.hour}
                      </TimeSlotPlaceHolder>
                      <CalendarHourlySlots
                        appointmentSlotDates={hr.appointmentSlotDates}
                        futureAppointmentsByDate={futureAppointmentsByDate}
                        selectedAppointmentSlot={selectedAppointmentSlot}
                        setSelectedAppointmentsSlot={
                          setSelectedAppointmentsSlot
                        }
                        startingMinutes={startingMinutes}
                      />
                    </HourRow>
                  );
                })}
              </DayWrapper>
            </DayOuterWrapper>
          );
        })}
      </CalendarDateRangeViewWrapper>
      <CalendarRefreshButtonWrapper>
        <CommonButton variant="secondary" onClick={() => onRefreshClick()}>
          {t('refresh')}
        </CommonButton>
      </CalendarRefreshButtonWrapper>
    </>
  );
};
