import Axios from 'configs/Axios';
import {
  ApptTemplate,
  ApptTemplateCreate,
  ApptTemplateFilters,
  ApptTemplateResponseData,
  PostFullWeekApptTemplate,
  UpdateApptTemplate,
} from 'types/apptTemplate';

export const getAllApptTemplate = (filters?: ApptTemplateFilters) =>
  Axios.get<ApptTemplateResponseData>('/apptTemplate', {
    params: filters,
  });

export const postApptTemplate = (data: ApptTemplateCreate) =>
  Axios.post<ApptTemplate>('/apptTemplate', data);

export const updateApptTemplate = (data: UpdateApptTemplate) =>
  Axios.put('/apptTemplate', data);

export const deleteApptTemplate = (id: string) =>
  Axios.delete(`/apptTemplate/${id}`);

export const postApptTemplateForWeek = (data: PostFullWeekApptTemplate) =>
  Axios.post('/apptTemplate/defaultWeek', data);
