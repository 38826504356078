import {
  useSiteNumericArrayAttribute,
  useSiteIntAttributes,
} from '../../useAttribute';
import {
  PATIENTS_EARLIEST_BOOKING_AFTER_IN_DAYS,
  PATIENTS_LATEST_BOOKING_BEFORE_IN_DAYS,
  SITE_WEEK_DAYS,
  STAFF_EARLIEST_BOOKING_AFTER_IN_DAYS,
  STAFF_LATEST_BOOKING_BEFORE_IN_DAYS,
} from '../../../configs/siteAndTrustAttributes';
import { addDays, subDays } from '../../../utils/dateUtil';
import { useMemo } from 'react';

const DEFAULT_EARLIEST_DAYS_AHEAD_FROM_SERVER = 0;
const DEFAULT_LATEST_DAYS_AHEAD_FROM_SERVER = 30;

const useMaxAndMinFutureDateForBooking = (
  earliestDayAhead: number,
  latestDayAhead: number,
) => {
  const weekDays = useSiteNumericArrayAttribute(SITE_WEEK_DAYS);

  return useMemo(() => {
    let minDate = addDays(new Date(), earliestDayAhead);
    while (
      weekDays &&
      weekDays.length > 0 &&
      !weekDays.includes(minDate.getDay())
    ) {
      minDate = addDays(minDate, 1);
    }
    let maxDate = addDays(new Date(), latestDayAhead);
    while (
      weekDays &&
      weekDays.length > 0 &&
      !weekDays.includes(maxDate.getDay())
    ) {
      maxDate = subDays(maxDate, 1);
    }
    return {
      minDate,
      maxDate,
    };
  }, [earliestDayAhead, latestDayAhead, weekDays]);
};

export const useMaxAndMinFutureDateForStaffBooking = () => {
  const [
    earliest = DEFAULT_EARLIEST_DAYS_AHEAD_FROM_SERVER,
    latest = DEFAULT_LATEST_DAYS_AHEAD_FROM_SERVER,
  ] = useSiteIntAttributes(
    STAFF_EARLIEST_BOOKING_AFTER_IN_DAYS,
    STAFF_LATEST_BOOKING_BEFORE_IN_DAYS,
  );
  return useMaxAndMinFutureDateForBooking(earliest, latest);
};
export const useMaxAndMinFutureDateForBookingForOnlineBooking = () => {
  const [
    earliest = DEFAULT_EARLIEST_DAYS_AHEAD_FROM_SERVER,
    latest = DEFAULT_LATEST_DAYS_AHEAD_FROM_SERVER,
  ] = useSiteIntAttributes(
    PATIENTS_EARLIEST_BOOKING_AFTER_IN_DAYS,
    PATIENTS_LATEST_BOOKING_BEFORE_IN_DAYS,
  );
  return useMaxAndMinFutureDateForBooking(earliest, latest);
};
