import { FieldErrors } from 'react-hook-form';
import {
  UserMemberData,
  UserProfileResponse,
} from '../../../types/authentication';
import { TrustData } from '../../../types/trusts';
import { UK_POST_CODE_REGEX } from '../../../utils/helpers';
import { NHSValidate } from '../../../utils/nhsValidator';
import {
  gpSurgeryRequiredTrusts,
  nhsNumberRequiredTrusts,
  postCodeRequiredTrusts,
} from '../../../utils/trustUtil';
import { NhsPostcodeForm } from '../../common/patient/PatientNhsPostcodeData';

export type MemberFormType = {
  gp_surgery_id?: string;
} & NhsPostcodeForm;

export type MembersFormType = MemberFormType & {
  other_members?: MemberFormType[];
};

export const validateMembersTrustsSpecificData = (
  members: (UserProfileResponse | UserMemberData)[],
  trusts: TrustData[],
): boolean => {
  const nhsNumberRequired = nhsNumberRequiredTrusts(trusts);
  const postCodeRequired = postCodeRequiredTrusts(trusts);
  const gpSurgeryRequired = gpSurgeryRequiredTrusts(trusts);
  return members.every(
    (member) =>
      (!nhsNumberRequired ||
        (member.nhs_number && NHSValidate(member.nhs_number))) &&
      (!postCodeRequired ||
        (member.postcode && UK_POST_CODE_REGEX.test(member.postcode))) &&
      (!gpSurgeryRequired || member.gp_surgery_id),
  );
};

export const getMemberRegisterNamePrefix = (
  member: UserProfileResponse | UserMemberData,
  index: number,
): string => {
  if ('relation' in member) {
    return `other_members.${index - 1}.`;
  } else {
    return '';
  }
};

export const getMemberErrors = (
  errors: FieldErrors<MembersFormType>,
  member: UserProfileResponse | UserMemberData,
  index: number,
): FieldErrors<any> => {
  if ('relation' in member) {
    return (errors as MembersFormType).other_members?.[index - 1] || {};
  } else {
    return errors;
  }
};
