import {
  DEPARTMENT_LEAD,
  ROLE_SPECIALIST_PLUS_WITH_REPORTS,
  SUPERADMIN,
} from '../../configs/constants';
import { useRole } from '../../context/RoleContext';

export const useHasAdminAccessToSelectedSite = () => {
  const { hasAnyOfRoles } = useRole();
  return hasAnyOfRoles(SUPERADMIN, DEPARTMENT_LEAD);
};

export const useHasReportsAccessOnSelectedSite = () => {
  const { hasAnyOfRoles } = useRole();
  return (
    useHasAdminAccessToSelectedSite() ||
    hasAnyOfRoles(ROLE_SPECIALIST_PLUS_WITH_REPORTS)
  );
};
