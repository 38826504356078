import Axios from 'configs/Axios';
import {
  BankHolidayFilters,
  BankHolidayFormValues,
  BankHolidayResponseData,
} from 'types/bankHoliday.d';

export const getAllBankHoliday = (filters?: BankHolidayFilters) =>
  Axios.get<BankHolidayResponseData>('/bankHoliday', {
    params: filters,
  });

export const deleteBankHoliday = (id: string) =>
  Axios.delete(`/bankHoliday/${id}`);

export const addNewBankHoliday = (data: BankHolidayFormValues) =>
  Axios.post<BankHolidayResponseData>('/bankHoliday', data);

export const importBankHoliday = () =>
  Axios.post<BankHolidayResponseData>('/bankHoliday/import');
