import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { KioskLayout } from '../KioskLayout';
import {
  KioskEntryChangeButton,
  KioskEntryMessageBoxStyled,
} from '../Kiosk.styled';
import { KioskTextInput } from './KioskTextInput';
import { useTextOnlyKeyDown } from '../../../../hooks/util/useTextOnlyKeyDown';

export const KioskEntryInitials = ({
  onInitials,
  onPhoneCheckinClicked,
}: {
  onInitials: (initials: string) => any;
  onPhoneCheckinClicked: () => any;
}) => {
  const { t } = useCommonTranslation();
  const keyDownHandler = useTextOnlyKeyDown();
  return (
    <KioskLayout>
      <KioskEntryMessageBoxStyled>
        <KioskTextInput
          inputHint={t('kiosk-entry-hint')}
          maxLength={2}
          minLength={2}
          keyDownHandler={keyDownHandler}
          onInputSubmit={onInitials}
        />
        <KioskEntryChangeButton type="button" onClick={onPhoneCheckinClicked}>
          {t('kiosk-checkin-phone-number')}
        </KioskEntryChangeButton>
      </KioskEntryMessageBoxStyled>
    </KioskLayout>
  );
};
