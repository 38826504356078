import { useMemo } from 'react';
import { useParams } from 'react-router';

import { PageHelmet } from '../../components/common/PageHelmet';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { ICardTabs } from '../../types/common';
import { AdminLayout } from './AdminLayout';
import { TrustPreferencesTab } from './trust/TrustPreferencesHooks';
import { useTrust } from '../../query/trusts';
import { TrustPreferences } from './trust/TrustPreferences';

export const adminTrustPreferencesTab: TrustPreferencesTab[] = ['mini-site'];

export const AdminTrustPreferences = () => {
  const { trustId, tab: selectedTabKey } =
    useParams<{ trustId: string; tab: TrustPreferencesTab }>();
  const { t } = useCommonTranslation();
  const { data: trust } = useTrust(trustId);
  const tabsConfig: ICardTabs[] = useMemo(
    () =>
      adminTrustPreferencesTab.map((key) => ({
        label: t(key),
        to: `/admin/trust/preferences/${trustId}/${key}`,
      })),
    [t, trustId],
  );

  return (
    <AdminLayout>
      <PageHelmet title={t('preferences')} />
      <TrustPreferences
        selectedTabKey={selectedTabKey}
        tabsConfig={tabsConfig}
        trust={trust}
      />
    </AdminLayout>
  );
};
