import { useMemo } from 'react';
import { useSiteNumericArrayAttribute } from '../useAttribute';
import { getDefaultTemplateDate } from '../../utils/apptTemplateUtils';
import { useApptTemplates } from '../../query/template';
import {
  addDays,
  formatDateOnlyISO,
  getWeekDay,
  parseIsoDate,
} from '../../utils/dateUtil';

export const useResourceTypeAllDefaultTemplates = ({
  resourceTypeId,
}: {
  resourceTypeId?: string;
}) => {
  const weekdays = useSiteNumericArrayAttribute('weekDays')!;
  const sortedDefaultTemplatesDates = useMemo(
    () =>
      weekdays
        .map((dayOfWeek) => getDefaultTemplateDate(dayOfWeek))
        .sort((a, b) => a.getTime() - b.getTime()),
    [weekdays],
  );

  const { isFetching, data: defaultTemplates } = useApptTemplates(
    {
      fromDate: formatDateOnlyISO(sortedDefaultTemplatesDates[0]),
      toDate: formatDateOnlyISO(
        addDays(
          sortedDefaultTemplatesDates[sortedDefaultTemplatesDates.length - 1],
          1,
        ),
      ),
      resourceTypeID: resourceTypeId,
    },
    {
      enabled: !!resourceTypeId,
    },
  );
  /**
   * The default templates can come back out of order and containing
   * templates for days that are not in the week days list.
   */
  const sortedClearedDefaultTemplates = useMemo(
    () =>
      defaultTemplates?.data
        ?.filter(({ template_date }) =>
          weekdays.includes(getWeekDay(parseIsoDate(template_date))),
        )
        .sort((a, b) => a.template_date.localeCompare(b.template_date)),
    [defaultTemplates, weekdays],
  );
  return {
    isFetching,
    defaultTemplates: sortedClearedDefaultTemplates,
  };
};
