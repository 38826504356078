import { useMemo } from 'react';
import { useUserAppointments } from '../../query/appointments';
import { UserAppointmentsTypes } from '../../types/appointments';
import { UserData } from '../../types/users';

export const useUserMemberAppointments = ({
  user,
  apptType,
  enabled = true,
}: {
  user?: UserData;
  apptType: UserAppointmentsTypes;
  enabled?: boolean;
}) => {
  const { isLoading, data } = useUserAppointments(
    apptType,
    user?.user_account_id,
    {
      enabled: !!user && enabled,
    },
  );

  const appts = useMemo(() => {
    return data?.filter((appt) => appt.user_member_id === user?.id);
  }, [data, user]);

  return {
    isLoading,
    data: appts,
  };
};
