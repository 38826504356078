import { CommonButton } from 'components/common/Forms/Button';
import {
  PatientBookingListItemStyled,
  PatientBookingListItemRange,
  PatientBookingListItemText,
} from '../PatientBooking.styled';

type PatientBookingHourItemProps = {
  disabled: boolean;
  range: string;
  text: string;
  btnText: string;
  btnOnClick: () => unknown;
};
export const PatientBookingListItem = ({
  range,
  text,
  btnText,
  btnOnClick,
  disabled,
}: PatientBookingHourItemProps) => {
  return (
    <PatientBookingListItemStyled unavailable={disabled}>
      <PatientBookingListItemRange>{range}</PatientBookingListItemRange>
      <PatientBookingListItemText>{text}</PatientBookingListItemText>
      <CommonButton
        variant="primary"
        size="standard"
        disabled={disabled}
        onClick={btnOnClick}
      >
        {btnText}
      </CommonButton>
    </PatientBookingListItemStyled>
  );
};
