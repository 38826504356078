import styled from 'styled-components';
import { h3 } from 'styles/Typography';
import { toRem } from 'utils/helpers';

export const ContactUsWrapper = styled.div`
  text-align: center;
`;
export const ContactUsEnvelope = styled.div`
  font-size: ${toRem(120)}rem;
  color: var(--greenaccent);
  line-height: 1;
`;
export const ContactUsTitle = styled.h3`
  ${h3};
  text-transform: uppercase;
`;
export const ContactUsDesc = styled.p`
  font-size: ${toRem(20)}rem;
  color: var(--textsecondary);
  font-weight: bold;
  text-transform: uppercase;
  margin: var(--s1) 0 var(--s3);
`;
export const ContactUsMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
