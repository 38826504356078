import { BookAppointmentForm } from '../../components/views-components/staff/appointments/EditAppointmentData';
import { Appointment } from '../../types/appointments';

export type AppointmentForm = {
  appointment: BookAppointmentForm;
};

export const useAppointmentStateWorkflow = ({
  appointment: { status },
}: {
  appointment: Appointment;
}) => {
  const isTakenOrNotTaken = status === 'TAKEN' || status === 'NOT_TAKEN';
  const isInProcessingStateOrAfter =
    status === 'PROCESSING' || isTakenOrNotTaken;
  const isInCalledStateOrAfter =
    status === 'CALLED' ||
    status === 'MIA' ||
    status === 'PROCESSING' ||
    isTakenOrNotTaken;
  return {
    isTakenOrNotTaken,
    isInCalledStateOrAfter,
    isInProcessingStateOrAfter,
  };
};
