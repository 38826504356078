import { useUserCheckIn } from '../../../query/appointments';
import { ErrorCode } from '../../../utils/errorCodes';
import { checkErrorCode } from '../../../utils/errors';
import { errorToast } from '../../../utils/toast';

export const useSelfCheckInAppointments = () => {
  const { data, isFetching } = useUserCheckIn({
    onError: (e) => {
      if (!checkErrorCode(e, ErrorCode.NO_APPT_FOR_SELF_CHECKIN_FOUND_WC0010)) {
        errorToast(e);
      }
    },
  });
  return { isFetching, appts: data?.data.data || [] };
};
