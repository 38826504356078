import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FaKey } from 'react-icons/fa';

import { Card } from '../../../../components/common';
import {
  DialogFormWrapper,
  FormGridBody,
  FormGroupTitle,
  FormHeader,
  FormTitle,
} from '../../../../components/common/Forms/Forms.styled';
import { Col } from '../../../../components/common/Grid';
import { CardLoader } from '../../../../components/common/Loading';
import { PromptWithBeforeUnload } from '../../../../components/Form/PromptWithBeforeUnload';
import { useIsDirty } from '../../../../hooks/form/useIsDirty';
import useAttributesForm from '../../../../hooks/useAttributesForm';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { SiteData } from '../../../../types/sites';
import { formatISOStringToSiteLocaleDate } from '../../../../utils/dateUtil';
import {
  ISitePreferencesWithTypeProps,
  SITE_PREFERENCES_FORM_ID,
  TYPE_STAFF,
} from '../SitePreferences';
import {
  useMergeSiteFormHooks,
  useSiteFormSubmit,
} from '../SitePreferencesHooks';
import { AgeRestrictions } from './AgeRestrictions';
import { useAgeRestrictionsFormHooks } from './AgeRestrictionsHooks';
import AvailabilityForm from './AvailablityForm';
import { useAvailabilityFormHooks } from './AvailablityFormHooks';
import {
  GeneralSitePreferencesFormFieldValues,
  UseGeneralSitePreferencesFormHooksReturn,
} from './GeneralSitePreferencesHooks';
import { SettingsSupport } from './SettingsSupport';
import { useSettingsSupportFormHooks } from './SettingsSupportHooks';
import { SystemPeriods } from './SystemPeriods';
import { useSystemPeriodsFormHooks } from './SystemPeriodsHooks';
import { VisualSettings } from './VisualSettings';
import { useVisualSettingsFormHooks } from './VisualSettingsHooks';

type Props = ISitePreferencesWithTypeProps & {
  site: SiteData;
};

export const GeneralSitePreferences = (props: Props) => {
  const { type, site } = props;
  const { t } = useCommonTranslation();

  const formPartsHooks: UseGeneralSitePreferencesFormHooksReturn[] = [
    useAvailabilityFormHooks({ site }),
    useSystemPeriodsFormHooks({ site }),
    useAgeRestrictionsFormHooks({ site }),
    useSettingsSupportFormHooks({ site }),
    useVisualSettingsFormHooks({ site }),
  ];

  const { defaultValues, restAttributes, beforeSubmit } = useMergeSiteFormHooks(
    { site, hooks: formPartsHooks },
  );

  const theForm = useForm<GeneralSitePreferencesFormFieldValues>({
    defaultValues,
  });

  useEffect(() => {
    theForm.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const attributesForm =
    useAttributesForm<GeneralSitePreferencesFormFieldValues>({
      theForm,
      currentAttrs: site.attributes,
      restAttributes,
      dep: [site],
    });

  const isDirty = useIsDirty(attributesForm.formState);

  const submitForm = useSiteFormSubmit({
    beforeSubmit,
    ...props,
    ...attributesForm,
  });

  return (
    <FormGridBody id={SITE_PREFERENCES_FORM_ID} onSubmit={submitForm}>
      <PromptWithBeforeUnload when={isDirty}></PromptWithBeforeUnload>
      <Col sm={6}>
        <Card mb="var(--s4)">
          <AvailabilityForm
            disabled={type === TYPE_STAFF}
            attributesForm={attributesForm}
            {...props}
          />
        </Card>
        <Card mb="var(--s4)">
          <SystemPeriods attributesForm={attributesForm} {...props} />
        </Card>
      </Col>
      <Col sm={6}>
        <Card mb="var(--s4)">
          <AgeRestrictions attributesForm={attributesForm} {...props} />
        </Card>
        <Card mb="var(--s4)">
          <SettingsSupport attributesForm={attributesForm} {...props} />
        </Card>
        <Card mb="var(--s4)">
          <DialogFormWrapper>
            <FormHeader>
              <FaKey />
              <FormTitle>{t('license-settings')}</FormTitle>
            </FormHeader>
            <FormGroupTitle style={{ textAlign: 'center' }}>
              {t('current-license-valid')}:{' '}
              {site && formatISOStringToSiteLocaleDate(site.expire_date)}
            </FormGroupTitle>
            {props.isFetching && <CardLoader fillWrapper={true} />}
          </DialogFormWrapper>
        </Card>
        <Card mb="var(--s4)">
          <VisualSettings attributesForm={attributesForm} {...props} />
        </Card>
      </Col>
    </FormGridBody>
  );
};
