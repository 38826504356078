import Axios from 'configs/Axios';

export type SendSupportAuthParams = {
  message: string;
  resolution: string;
};
export type SendSupportGuestParams = SendSupportAuthParams & {
  email: string;
  name: string;
  phone: string;
  site_id: string;
};
export type SendSupportParams = SendSupportAuthParams | SendSupportGuestParams;

export const sendSupportMessage = (params: SendSupportParams) => {
  return Axios.post<void>(`/support`, params);
};

export const sendStaffSupportMessage = (params: SendSupportParams) => {
  return Axios.post<void>(`/staffSupport`, params);
};
