import styled from 'styled-components';
import { boxShadow } from '../../../styles/partials/boxShadow';
import { listItemMarginY } from '../../../styles/partials/listItemMargin';
import { CommonButton } from '../../../components/common/Forms/Button';
import { mq } from '../../../styles/sizes';

export const TrustMiniSiteListWrapper = styled.div`
  max-height: 310px;
  overflow: auto;
  margin: calc(var(--s3) * -1);
  padding: var(--s3);
`;

export const TrustMiniSiteListItem = styled.div`
  ${boxShadow}
  ${listItemMarginY('var(--s3)')}
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: var(--s3);
  padding: var(--s3);

  ${CommonButton} {
    margin-left: var(--s2);
  }
`;

export const TrustMiniSiteListItemName = styled.div`
  font-size: var(--h4);
  font-weight: bold;
  padding-right: var(--s2);
  margin-right: auto;
`;

export const TrustMiniSiteStepTitle = styled.div`
  text-transform: uppercase;
  font-size: var(--h3);
  letter-spacing: 0px;
  margin-bottom: var(--s3);
  text-align: center;
`;

export const TrustMiniSiteButtonBar = styled.div`
  display: flex;
  margin-top: var(--s3);
  gap: var(--s2);
  flex-direction: column;

  ${mq.xsm} {
    gap: var(--s3);
    flex-direction: row;
  }
`;

export const TrustMiniSiteButtonBarRight = styled.div`
  display: flex;
  gap: var(--s2);
  flex-direction: column;

  ${mq.xsm} {
    margin-left: auto;
    gap: var(--s3);
    flex-direction: row;
  }
`;
