import React, { Fragment } from 'react';
import InputRange from '../common/Forms/InputRange';
import {
  TimePlannerActions,
  TimePlannerActionType,
  TimePlannerState,
} from './types';
import { formatJustHour } from '../../utils/dateUtil';
import { TimePlannerInner } from './TimePlanner.styled';

interface ITimePlannerSlidersProps {
  dispatch: React.Dispatch<TimePlannerActions>;
  reducerState: TimePlannerState;
  staffOnly: boolean;
}

export const TimePlannerSliders: React.FunctionComponent<
  ITimePlannerSlidersProps
> = ({
  dispatch,
  reducerState: { day, selectedResourceCubiclesAssigned },
  staffOnly,
}) => {
  return (
    <TimePlannerInner>
      <InputRange
        showMaxTopRight
        max={selectedResourceCubiclesAssigned}
        value={staffOnly ? day.staff_only : day.cubicle}
        availableRange={{
          max: day.available_cubicles,
        }}
        prefix="Day"
        prefixType="big"
        color="var(--yellowaction)"
        onChange={(value) =>
          dispatch({
            type: TimePlannerActionType.SET_DAY_LIMIT,
            payload: {
              value,
              staff_only: staffOnly,
            },
          })
        }
      />

      {day.hours.map((hour, hourIndex) => (
        <Fragment key={hour.hourNumber}>
          <InputRange
            max={selectedResourceCubiclesAssigned}
            value={staffOnly ? hour.staff_only : hour.cubicle}
            prefix={formatJustHour(hour.hourNumber)}
            availableRange={{
              max: hour.available_cubicles,
            }}
            prefixType="big"
            color="var(--greenaccent)"
            onChange={(value) =>
              dispatch({
                type: TimePlannerActionType.SET_HOUR_LIMIT,
                payload: {
                  value,
                  hourIndex,
                  staff_only: staffOnly,
                },
              })
            }
          />
          {hour.intervals.map((interval, intervalIndex) => {
            const intervalValue = staffOnly
              ? interval.staff_only
              : interval.cubicle;
            return (
              <InputRange
                key={'Intervals_' + hour.hourNumber + '_' + intervalIndex * 60}
                prefix={`:${interval.minute}`}
                max={selectedResourceCubiclesAssigned}
                value={Math.min(intervalValue, interval.available_cubicles)}
                availableRange={{
                  max: interval.available_cubicles,
                }}
                color="var(--greenprimary)"
                onChange={(value) =>
                  dispatch({
                    type: TimePlannerActionType.SET_INTERVAL_LIMIT,
                    payload: {
                      value,
                      hourIndex,
                      intervalIndex,
                      staff_only: staffOnly,
                    },
                  })
                }
              />
            );
          })}
        </Fragment>
      ))}
    </TimePlannerInner>
  );
};
