import {
  UseMutationOptions,
  UseQueryOptions,
} from 'react-query/types/react/types';
import { AxiosResponse } from 'axios';
import {
  CacheAndStaleTimeForUnlikelyChanged,
  useMutationWithToastError,
  useQueryWithToastError,
} from './queryUtils';
import {
  deleteApptTemplate,
  getAllApptTemplate,
  postApptTemplate,
  postApptTemplateForWeek,
  updateApptTemplate,
} from '../services/apptTemplate';
import {
  ApptTemplate,
  ApptTemplateCreate,
  ApptTemplateFilters,
  ApptTemplateResponseData,
  PostFullWeekApptTemplate,
  UpdateApptTemplate,
} from '../types/apptTemplate';
import { useQueryClient } from 'react-query';

const APPT_TEMPLATES = 'APPT_TEMPLATES';

export const useApptTemplates = (
  filters: ApptTemplateFilters,
  options?: UseQueryOptions<
    AxiosResponse<ApptTemplateResponseData>,
    any,
    ApptTemplateResponseData
  >,
) => {
  return useQueryWithToastError(
    [APPT_TEMPLATES, filters],
    () => getAllApptTemplate(filters),
    {
      select: (data) => data.data,
      ...CacheAndStaleTimeForUnlikelyChanged,
      ...options,
    },
  );
};

export const useMutateUpdateApptTemplate = (
  options?: UseMutationOptions<any, any, UpdateApptTemplate>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<UpdateApptTemplate>(
    [APPT_TEMPLATES],
    (template) => updateApptTemplate(template),
    {
      onSettled: () => client.invalidateQueries([APPT_TEMPLATES]),
      ...options,
    },
  );
};

export const useMutateUpdateApptTemplateForWeek = (
  options?: UseMutationOptions<any, any, PostFullWeekApptTemplate>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<PostFullWeekApptTemplate>(
    [APPT_TEMPLATES, 'fullWeek'],
    (template) => postApptTemplateForWeek(template),
    {
      onSettled: () => client.invalidateQueries([APPT_TEMPLATES]),
      ...options,
    },
  );
};
export const usePostApptTemplate = (
  options?: UseMutationOptions<
    AxiosResponse<ApptTemplate>,
    any,
    ApptTemplateCreate
  >,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<
    ApptTemplateCreate,
    AxiosResponse<ApptTemplate>
  >([APPT_TEMPLATES], (template) => postApptTemplate(template), {
    onSettled: () => client.invalidateQueries([APPT_TEMPLATES]),
    ...options,
  });
};
export const useDeleteApptTemplate = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<string>(
    [APPT_TEMPLATES],
    (template) => deleteApptTemplate(template),
    {
      onSettled: () => client.invalidateQueries([APPT_TEMPLATES]),
      ...options,
    },
  );
};
