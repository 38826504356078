import { LayoutWrapper } from 'components/common';
import { Navbar } from 'components/Navbar/NavBar';
import React, { useMemo } from 'react';
import { INavbarDropdown } from 'types/common';

interface Props {
  children: React.ReactNode;
  navbarDropdowns: INavbarDropdown[];
  showNHSLogo: boolean;
  noReleaseNotes?: boolean;
  fixedHeight?: boolean;
}

export const DefaultLayout = ({
  children,
  navbarDropdowns,
  showNHSLogo,
  noReleaseNotes,
  fixedHeight,
}: Props) => {
  const releaseNote:
    | {
        to: string;
        key: 'PATIENT' | 'STAFF';
      }
    | undefined = useMemo(
    () =>
      noReleaseNotes
        ? undefined
        : {
            key: 'STAFF',
            to: '/staff/release-notes',
          },
    [noReleaseNotes],
  );
  return (
    <LayoutWrapper fixedHeight={fixedHeight}>
      <Navbar
        nhsLogo={showNHSLogo}
        dropdowns={navbarDropdowns}
        releaseNote={releaseNote}
      />
      {children}
    </LayoutWrapper>
  );
};
