import { CommonLink } from 'components/common/Forms/Button';
import { InlineConfirm } from 'components/Popup/ConfirmDialog/InlineConfirm';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { FaCalendarCheck } from 'react-icons/fa';
import { patientURL } from 'routes/AppRoutes';

export const WaitingListSuccess = () => {
  const { t } = useCommonTranslation();
  return (
    <InlineConfirm
      icon={<FaCalendarCheck />}
      title={t('waiting-list-saved')}
      actions={[
        <CommonLink key="back" variant="secondary" size="large" to={patientURL}>
          {t('back-to-menu')}
        </CommonLink>,
      ]}
      inlineIcon
    />
  );
};
