import React, { useContext } from 'react';
import { IGlobalAttributesContext } from 'types/common';
import { useAuth } from './AuthContext';
import { useQueryGlobalAttributes } from '../query/globalAttributes';

const defaultContext: IGlobalAttributesContext = {
  attributes: [],
};

export const GlobalAttributesContext =
  React.createContext<IGlobalAttributesContext>(defaultContext);
GlobalAttributesContext.displayName = 'Global Attributes Context'; // Only for debugging

export const useGlobalAttributes = () => useContext(GlobalAttributesContext);

export const GlobalAttributesProvider: React.FC = ({ children }) => {
  const { sangixUser } = useAuth();
  const { data: globalAttributes } = useQueryGlobalAttributes({
    enabled: Boolean(sangixUser),
  });

  return (
    <GlobalAttributesContext.Provider
      value={{
        attributes: globalAttributes?.data || [],
      }}
    >
      {children}
    </GlobalAttributesContext.Provider>
  );
};
