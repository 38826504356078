import { AxiosError } from 'axios';
import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { Card } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import { Col, Grid } from 'components/common/Grid';
import { PageHelmet } from 'components/common/PageHelmet';
import { ConfirmDialog } from 'components/Popup/ConfirmDialog/ConfirmDialog';
import { TableActions } from 'components/Table/Table.styled';
import { GPSurgeriesTable } from 'components/views-components/staff/settings/gpSurgeries/GPSurgeriesTable';
import GPSurgeryForm from 'components/views-components/staff/settings/gpSurgeries/GPSurgeryForm';
import { StaffSettingsTabsTree } from 'configs/RoutesConfig';
import { useSite } from 'context/SiteContext';
import React, { useEffect, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { GeneralPractice, GPFormValues } from 'types/generalPractices';
import { errorToast, successToast } from 'utils/toast';
import { StaffLayout } from '../../StaffLayout';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import {
  useMutateCreateGP,
  useMutateDeleteGP,
  useMutateUpdateGP,
} from '../../../../query/gp';
import { useHistory } from 'react-router';
import { STAFF_GP_SURGERY_MERGE_URL } from '../../../../routes/AppRoutes';
import { useGpSurgeriesTable } from './useGpSurgeriesTable';
import { useConfirmDialog } from '../../../../components/Popup/ConfirmDialog/confirmDialogHook';
import { checkErrorCode } from '../../../../utils/errors';
import { ErrorCode } from '../../../../utils/errorCodes';

export const GPSurgeries = () => {
  const { t } = useCommonTranslation();
  const { activeSite } = useSite();
  const { push } = useHistory();

  const {
    setFilters,
    filters,
    gpData,
    isFetching: isListFetching,
    ccgData,
    isLoading: isListLoading,
  } = useGpSurgeriesTable();

  const { confirm } = useConfirmDialog();
  const { isLoading: isUpdateLoading, mutate: update } = useMutateUpdateGP({
    onSuccess: () => {
      successToast(t('changes-have-been-saved'));
      closeFormHandler();
    },
  });
  const { isLoading: isCreateLoading, mutate: create } = useMutateCreateGP({
    onSuccess: () => {
      successToast(t('added-gp-surgery'));
      closeFormHandler();
    },
  });
  const { isLoading: isDeleteLoading, mutate: deleteItem } = useMutateDeleteGP({
    onError: (err: AxiosError) => {
      if (checkErrorCode(err, ErrorCode.GP_SURGERY_USED_BY_OTHER_DATA_GP0003)) {
        confirm({
          title: t('delete-gp-surgery-used-dialog-title'),
          confirmButtonText: t('delete-gp-surgery-go-to-merge'),
          icon: <FaTrashAlt />,
          onConfirm: () => {
            push(STAFF_GP_SURGERY_MERGE_URL);
          },
        });
      } else {
        errorToast(err);
      }
    },
    onSuccess: () => {
      successToast(t('deleted-gp-surgery'));
      closeFormHandler();
    },
  });

  const isLoading =
    isCreateLoading || isUpdateLoading || isListLoading || isDeleteLoading;

  const [showForm, setShowForm] = useState(false);
  const [prefilledData, setPrefilledData] = useState<
    GeneralPractice | undefined
  >();
  const [confirmDeleteItem, setConfirmDeleteItem] =
    useState<GeneralPractice | null>();

  const submitHandler: (data: GPFormValues, isUpdateGP: boolean) => void = (
    data,
    isUpdateGP,
  ) => {
    const structuredData: GPFormValues = {
      ...data,
      trust_id: activeSite?.trust_id as string,
    };

    Boolean(isUpdateGP) && Boolean(prefilledData)
      ? update({ ...prefilledData, ...structuredData })
      : create(structuredData);
  };

  const closeFormHandler = (err?: AxiosError | true) => {
    setShowForm(false);
    setPrefilledData(undefined);
    setConfirmDeleteItem(null);
    if (err) {
      errorToast(err);
    }
  };

  const openFormHandler = (value?: string) => {
    setShowForm(false);
    setTimeout(() => {
      if (value) {
        const selectedSite = gpData?.data?.find((gp) => gp.id === value);

        if (selectedSite) {
          setPrefilledData(selectedSite);
          setShowForm(true);
        } else {
          closeFormHandler(true);
        }
      } else {
        setPrefilledData(undefined);
        setShowForm(true);
      }
    }, 50);
  };

  const handleDeleteGPSurgery = (value: string) => {
    setConfirmDeleteItem(gpData?.data?.find((gp) => gp.id === value));
  };

  const handleConfirmDeleteGP = () => {
    deleteItem(confirmDeleteItem?.id as string);
  };

  useEffect(() => {
    closeFormHandler();
  }, [activeSite?.id, filters]);

  return (
    <StaffLayout>
      <PageHelmet title={t('gp-surgeries')} />
      <Grid>
        <Col md={8}>
          <Card fillHeight flex>
            <LinkCardTabs withDivider tabs={StaffSettingsTabsTree(t)} />
            <TableActions>
              <CommonButton variant="primary" onClick={() => openFormHandler()}>
                {t('add-gp-surgery')}
              </CommonButton>
              <CommonButton
                variant="primary"
                onClick={() => {
                  push(STAFF_GP_SURGERY_MERGE_URL);
                }}
              >
                {t('gp-surgeries-merge')}
              </CommonButton>
            </TableActions>
            <GPSurgeriesTable
              gpData={gpData}
              ccgData={ccgData?.data.data || []}
              openFormHandler={openFormHandler}
              handleDeleteGPSurgery={handleDeleteGPSurgery}
              setFilters={setFilters}
              isLoading={isListLoading}
              isFetching={isListFetching}
              filters={filters}
            />
          </Card>
        </Col>
        <Col md={4}>
          {showForm && (
            <GPSurgeryForm
              closeFormHandler={closeFormHandler}
              ccgData={ccgData?.data.data}
              submitHandler={submitHandler}
              isLoading={isLoading}
              prefilledData={prefilledData}
              handleDeleteGPSurgery={handleDeleteGPSurgery}
            />
          )}
        </Col>
      </Grid>
      {Boolean(confirmDeleteItem) && (
        <ConfirmDialog
          isLoading={isLoading}
          handleClose={() => closeFormHandler()}
          icon={<FaTrashAlt />}
          title={t('confirm-delete-gp')}
          status="delete"
          actions={[
            <CommonButton
              variant="secondary"
              onClick={() => {
                setConfirmDeleteItem(null);
              }}
              key={confirmDeleteItem?.id + '-cancel'}
            >
              {t('cancel')}
            </CommonButton>,
            <CommonButton
              variant="danger"
              onClick={handleConfirmDeleteGP}
              key={confirmDeleteItem?.id + '-delete'}
            >
              {t('yes')}
            </CommonButton>,
          ]}
        />
      )}
    </StaffLayout>
  );
};
