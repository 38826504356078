import React from 'react';
import { SelectOption } from '../../../types/common';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import {
  bitwiseArrayToNumber,
  bitwiseNumberToIntValuesArray,
} from '../../../utils/helpers';
import CheckboxInput, { CheckboxProps } from './CheckboxInput';

type IBitwiseCheckboxArrayProps = {
  disabled?: boolean;
  options: SelectOption<number>[];
  field: ControllerRenderProps<any, any>;
} & Pick<CheckboxProps, 'fontBasedSize' | 'style'>;

export const BitwiseCheckboxArray: React.FunctionComponent<
  IBitwiseCheckboxArrayProps
> = ({ options, field, disabled, ...checkBoxProps }) => {
  const currentOptionsSelected = bitwiseNumberToIntValuesArray(field.value);
  return (
    <>
      {options.map((option) => (
        <CheckboxInput
          {...checkBoxProps}
          key={option.value}
          name={`${field.name}_${option.value}`}
          label={option.label}
          value={option.value}
          disabled={disabled}
          onCheckedStateChange={(checked) => {
            if (checked) {
              field.onChange(
                bitwiseArrayToNumber([option.value, ...currentOptionsSelected]),
              );
            } else {
              field.onChange(
                bitwiseArrayToNumber(
                  currentOptionsSelected.filter((id) => id !== option.value),
                ),
              );
            }
          }}
          checked={currentOptionsSelected.includes(option.value)}
        />
      ))}
    </>
  );
};
