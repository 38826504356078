import { AxiosError } from 'axios';
import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { ConfirmDialog } from 'components/Popup/ConfirmDialog/ConfirmDialog';
import { TableActions } from 'components/Table/Table.styled';
import { Card } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import { Col, Grid } from 'components/common/Grid';
import { PageHelmet } from 'components/common/PageHelmet';
import ResourceTypeForm from 'components/views-components/staff/settings/resourceTypes/ResourceTypeForm';
import { ResourceTypeTable } from 'components/views-components/staff/settings/resourceTypes/ResourceTypeTable';
import { StaffSettingsCubicleTree } from 'configs/RoutesConfig';
import { useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { ResourceType, ResourceTypeFormValues } from 'types/resource-types';
import { errorToast, successToast } from 'utils/toast';
import { SUPERADMIN } from '../../../../configs/constants';
import { useRole } from '../../../../context/RoleContext';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { usePaginatedFilters } from '../../../../hooks/usePaginatedFilters';
import {
  useMutateCreateResourceType,
  useMutateDeleteResourceType,
  useMutateUpdateResourceType,
  useSiteResourceTypes,
} from '../../../../query/resourceTypes';
import { GPFilters } from '../../../../types/generalPractices';
import { StaffLayout } from '../../StaffLayout';

export const StaffSettingsCalendarTemplates = () => {
  const { t } = useCommonTranslation();
  const { filters, setFilters } = usePaginatedFilters<GPFilters>();
  const {
    isLoading: isListLoading,
    isFetching: isListFetching,
    data,
  } = useSiteResourceTypes(filters);
  const { isLoading: isUpdateLoading, mutate: update } =
    useMutateUpdateResourceType({
      onSuccess: () => {
        successToast(t('changes-have-been-saved'));
        closeFormHandler();
      },
    });
  const { isLoading: isCreateLoading, mutate: create } =
    useMutateCreateResourceType({
      onSuccess: () => {
        successToast(t('changes-have-been-saved'));
        closeFormHandler();
      },
    });
  const { isLoading: isDeleteLoading, mutate: deleteItem } =
    useMutateDeleteResourceType({
      onSuccess: () => {
        successToast(t('calendar-template-removed'));
        closeFormHandler();
      },
    });

  const isLoading =
    isCreateLoading || isUpdateLoading || isListLoading || isDeleteLoading;

  const [showForm, setShowForm] = useState(false);
  const [prefilledData, setPrefilledData] = useState<
    ResourceType | undefined
  >();
  const [confirmDeleteItem, setConfirmDeleteItem] =
    useState<ResourceType | null>();

  const submitHandler: (
    data: ResourceTypeFormValues,
    isUpdateResourceType: boolean,
  ) => void = async (data, isUpdateResourceType) => {
    const structuredData: ResourceTypeFormValues = {
      ...data,
    };
    Boolean(isUpdateResourceType) && Boolean(prefilledData)
      ? update({ ...prefilledData, ...structuredData })
      : create(structuredData);
  };

  const closeFormHandler = (err?: AxiosError | true) => {
    setShowForm(false);
    setPrefilledData(undefined);
    setConfirmDeleteItem(null);
    if (err) {
      errorToast(err);
    }
  };

  const openFormHandler = (value?: string) => {
    setShowForm(false);
    setTimeout(() => {
      if (value) {
        const selectedResourceType = data?.data?.find(
          (resourceType) => resourceType.id === value,
        );

        if (selectedResourceType) {
          setPrefilledData(selectedResourceType);
          setShowForm(true);
        } else {
          closeFormHandler(true);
        }
      } else {
        setPrefilledData(undefined);
        setShowForm(true);
      }
    }, 50);
  };

  const handleDeleteResourceType = (value: string) => {
    closeFormHandler();
    setConfirmDeleteItem(
      data?.data?.find((resourceType) => resourceType.id === value),
    );
  };

  const handleConfirmDeleteResourceType = () => {
    deleteItem(confirmDeleteItem?.id as string);
  };

  const { hasAnyOfRoles } = useRole();

  return (
    <StaffLayout>
      <PageHelmet title={t('calendar-templates')} />
      <Grid>
        <Col md={8}>
          <Card>
            <LinkCardTabs tabs={StaffSettingsCubicleTree(t)} withDivider />
            {hasAnyOfRoles(SUPERADMIN) && (
              <TableActions>
                <CommonButton
                  variant="primary"
                  onClick={() => openFormHandler()}
                >
                  {t('add-calendar-template')}
                </CommonButton>
              </TableActions>
            )}
            <ResourceTypeTable
              resourceTypeData={data}
              openFormHandler={openFormHandler}
              handleDeleteResourceType={handleDeleteResourceType}
              isLoading={isListLoading}
              filters={filters}
              setFilters={setFilters}
              isFetching={isListFetching}
            />
          </Card>
        </Col>
        <Col md={4}>
          {showForm && (
            <ResourceTypeForm
              closeFormHandler={closeFormHandler}
              submitHandler={submitHandler}
              isLoading={isLoading}
              prefilledData={prefilledData}
            />
          )}
        </Col>
      </Grid>
      {Boolean(confirmDeleteItem) && (
        <ConfirmDialog
          isLoading={isLoading}
          handleClose={() => closeFormHandler()}
          icon={<FaTrashAlt />}
          title={t('confirm-delete-calendar-template')}
          status="delete"
          actions={[
            <CommonButton
              variant="secondary"
              onClick={() => closeFormHandler()}
              key={confirmDeleteItem?.id + '-cancel'}
            >
              {t('no')}
            </CommonButton>,
            <CommonButton
              variant="danger"
              onClick={handleConfirmDeleteResourceType}
              key={confirmDeleteItem?.id + '-delete'}
            >
              {t('yes')}
            </CommonButton>,
          ]}
        />
      )}
    </StaffLayout>
  );
};
