import { useMemo } from 'react';

import { SHOW_APPOINTMENT_REFERENCE_NUMBER } from '../configs/siteAndTrustAttributes';
import { useAuth } from '../context/AuthContext';
import { AppointmentAuditItem } from '../types/appointments';
import { getReferenceNumber } from '../utils/appointmentUtil';
import { useAppointmentBookedByAudit } from './useAppointmentAuditTransition';
import { useSiteBooleanAttributes } from './useAttribute';

export const useAppointmentReferenceNumber = ({
  appointmentDate,
  auditItems,
}: {
  appointmentDate?: Date;
  auditItems?: AppointmentAuditItem[];
}) => {
  const [showReferenceNumber = false] = useSiteBooleanAttributes(
    SHOW_APPOINTMENT_REFERENCE_NUMBER,
  );
  const bookedByAudit = useAppointmentBookedByAudit({
    auditItems,
    enabled: showReferenceNumber,
  });
  const { sangixUser } = useAuth();

  return useMemo(() => {
    if (showReferenceNumber && appointmentDate) {
      let fullName: string | undefined = undefined;
      if (bookedByAudit) {
        fullName = bookedByAudit.user_full_name;
      } else if (sangixUser) {
        fullName = `${sangixUser.firstname} ${sangixUser.lastname}`;
      }
      return getReferenceNumber(fullName || '', appointmentDate);
    }
    return undefined;
  }, [showReferenceNumber, appointmentDate, bookedByAudit, sangixUser]);
};
