import { usePublicSite } from './usePublicSite';
import { useLCDQuery } from '../../query/public';
import { useEffect } from 'react';
// @ts-ignore
import beepMp3 from 'assets/beep.mp3';
import { LCDEntry } from '../../types/lcd';
import { usePreviousDistinct } from 'react-use';

export const useLCD = () => {
  const { activeSite } = usePublicSite();
  const query = useLCDQuery({ siteId: activeSite.id });
  return query.data?.data || { code: '', rows: [] };
};

export const usePlayLCDSoundOnNewPatient = ({
  newRows,
}: {
  newRows: LCDEntry[];
}) => {
  const beepSound = new Audio(beepMp3);
  const previousRows = usePreviousDistinct<LCDEntry[]>(newRows);
  useEffect(() => {
    const rowInNewRowsMissingFromPrevious = newRows.some(
      (newRow) =>
        !previousRows?.some(
          (previousRow) =>
            newRow.name === previousRow.name &&
            newRow.cubicle === previousRow.cubicle,
        ),
    );
    if (rowInNewRowsMissingFromPrevious) beepSound.play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousRows, newRows]);
};
