import { useMemo } from 'react';
import { useSiteBooleanAttributes } from '../useAttribute';
import { useCommonTranslation } from '../i18n/useCommonTranslation';
import { FORMS, TUBES } from '../../configs/siteAndTrustAttributes';

export const useActiveSiteValidationRules = () => {
  const [formsMandatory, tubesMandatory] = useSiteBooleanAttributes(
    FORMS,
    TUBES,
  );
  const { t } = useCommonTranslation();

  return useMemo(() => {
    const validate = (value?: string | number | null) => {
      if (
        (typeof value === 'string' || typeof value === 'number') &&
        `${value}`.trim() !== ''
      ) {
        const numValue = +value;
        if (parseInt(`${numValue}`.trim(), 10) !== numValue) {
          return t<string>('must-be-whole-number');
        }
        if (numValue < 0) {
          return t<string>('must-greater-or-equal-zero');
        }
        if (numValue > 20) {
          return t<string>('must-be-less-than', { count: 21 });
        }
      }
      return undefined;
    };

    return {
      formsRules: {
        required: formsMandatory && t<string>('must-not-empty'),
        validate,
      },
      tubesRules: {
        required: tubesMandatory && t<string>('must-not-empty'),
        validate,
      },
      formsMandatory,
      tubesMandatory,
    };
  }, [formsMandatory, tubesMandatory, t]);
};
