import { AxiosResponse } from 'axios';
import { UseQueryOptions } from 'react-query/types/react/types';

import { useGPSurgeries } from '../../query/gp';
import {
  GPFiltersNoPagination,
  GPResponseData,
} from '../../types/generalPractices';
import { UNSAFE_FILTER_WITH_NO_PAGINATION } from '../usePaginatedFilters';

export const useAllGPSurgeries = (
  gpFilters?: GPFiltersNoPagination,
  options?: UseQueryOptions<AxiosResponse<GPResponseData>, any, GPResponseData>,
  noToken?: boolean,
) => {
  const { data, ...rest } = useGPSurgeries(
    { ...UNSAFE_FILTER_WITH_NO_PAGINATION, ...gpFilters },
    options,
    noToken,
  );
  return { data: data?.data, ...rest };
};
