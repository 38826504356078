import { useMemo, useState } from 'react';
import { TemplateDateWithCounts } from '../../../../../hooks/apptTemplate/useDefaultTemplatesApptCounts';
import { SelectOption } from '../../../../../types/common';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { AppointmentTemplateCounts } from '../../../../../utils/apptTemplateUtils';

export const useWeeklyApptTemplateCounts = ({
  templateCounts,
}: {
  templateCounts?: TemplateDateWithCounts[];
}) => {
  const { t } = useCommonTranslation();

  const appointmentTypeOptions: SelectOption<
    keyof AppointmentTemplateCounts
  >[] = [
    {
      label: t('all-appointments-type'),
      value: 'allApptCount',
    },
    {
      label: t('online-only-appointments-type'),
      value: 'onlineCount',
    },
    {
      label: t('staff-only-appointments-type'),
      value: 'staffOnlyCount',
    },
  ];

  const [selectedAppointmentType, setSelectedAppointmentType] = useState(
    appointmentTypeOptions[0].value,
  );

  const weekCounts = useMemo(() => {
    const totalAppts = templateCounts?.reduce(
      (acc, { [selectedAppointmentType]: count }) => acc + count,
      0,
    );
    const labelCount = templateCounts?.map(
      ({ template_date, [selectedAppointmentType]: count }) => {
        return {
          template_date,
          count,
        };
      },
    );
    return {
      dateCounts: labelCount,
      totalCount: totalAppts,
    };
  }, [templateCounts, selectedAppointmentType]);
  return {
    weekCounts,
    appointmentTypeOptions,
    selectedAppointmentType,
    setSelectedAppointmentType,
  };
};
