import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { useCallback, useState } from 'react';

import {
  CommonButton,
  CommonLink,
} from '../../../components/common/Forms/Button';
import { CardLoader } from '../../../components/common/Loading';
import { useSite } from '../../../context/SiteContext';
import {
  useCheckInSites,
  usePatientCheckIn,
} from '../../../context/patient/modules/checkIn';
import { patientURL } from '../../../routes/AppRoutes';
import { SiteData } from '../../../types/sites';
import { errorToast } from '../../../utils/toast';
import { NoAppointmentsBar } from '../myAppointments/NoAppointmentsBar';
import {
  PatientCheckInButtonBarRight,
  PatientCheckInButtonBarStyled,
  PatientCheckInPickSiteListWrapper,
  PatientCheckInSiteItem,
  PatientCheckInSiteItemName,
} from './PatientCheckIn.styled';
import { PatientCheckInLayout } from './PatientCheckInLayout';

export const PatientCheckInPickSite = () => {
  const { t } = useCommonTranslation();
  const { updateCheckIn } = usePatientCheckIn();
  const { setActiveSiteById, activeSite } = useSite();
  const [updatingCheckIn, setUpdatingCheckIn] = useState(false);

  const handleSiteSelect = useCallback(
    async (siteId: string) => {
      setUpdatingCheckIn(true);
      try {
        let site: SiteData;
        if (activeSite?.id === siteId) {
          site = activeSite;
        } else {
          site = await setActiveSiteById(siteId);
        }
        updateCheckIn({ siteId: site.id, siteName: site.name });
      } catch (err) {
        errorToast(err);
      } finally {
        setUpdatingCheckIn(false);
      }
    },
    [activeSite, setActiveSiteById, updateCheckIn],
  );

  const { isLoading: sitesLoading, data: sites } = useCheckInSites({
    onSuccess(sites) {
      if (sites.length === 1) {
        // having only one site, auto select it
        handleSiteSelect(sites[0].id);
      }
    },
    refetchOnMount: 'always',
  });

  const isLoading = sitesLoading || updatingCheckIn;

  return (
    <PatientCheckInLayout widerCard label={t('check-in-at')}>
      {isLoading ? (
        <CardLoader />
      ) : (
        <>
          {sites?.length === 0 ? (
            <NoAppointmentsBar
              text={t('patient-check-in-appointments-empty')}
            />
          ) : (
            <PatientCheckInPickSiteListWrapper>
              {sites?.map((site) => (
                <PatientCheckInSiteItem key={site.id}>
                  <PatientCheckInSiteItemName>
                    {site.name}
                  </PatientCheckInSiteItemName>
                  <CommonButton
                    variant="primary"
                    onClick={() => {
                      handleSiteSelect(site.id);
                    }}
                  >
                    {t('select')}
                  </CommonButton>
                </PatientCheckInSiteItem>
              ))}
            </PatientCheckInPickSiteListWrapper>
          )}
        </>
      )}
      <PatientCheckInButtonBarStyled>
        <PatientCheckInButtonBarRight>
          <CommonLink
            type="button"
            variant="secondary"
            size="large"
            to={patientURL}
          >
            {t('back-to-menu')}
          </CommonLink>
        </PatientCheckInButtonBarRight>
      </PatientCheckInButtonBarStyled>
    </PatientCheckInLayout>
  );
};
