import { TimePlannerState } from './types';

export const timePlannerDefaultState: (
  data: Partial<TimePlannerState>,
) => TimePlannerState = ({
  selectedResourceCubiclesAssigned,
  openHourInSeconds,
  closeHourInSeconds,
  timeInterval,
}) => ({
  selectedResourceCubiclesAssigned: selectedResourceCubiclesAssigned || 0,
  openHourInSeconds: openHourInSeconds || 0,
  closeHourInSeconds: closeHourInSeconds || 0,
  timeInterval: timeInterval || 0,
  day: {
    dayNumber: 0,
    cubicle: 0,
    staff_only: 0,
    available_cubicles: 0,
    hours: [
      // length of hours = closeHour - openHour
      {
        hourNumber: 0,
        cubicle: 0,
        staff_only: 0,
        available_cubicles: 0,
        intervals: [
          {
            cubicle: 0,
            staff_only: 0,
            available_cubicles: 0,
            minute: 0,
          },
        ], // length = number of intervals, value= cubicle limit of this interval (index)
      },
    ],
  },
});
