import { Navbar } from 'components/Navbar/NavBar';
import {
  Bars,
  MobileNavItem,
  MobileNavList,
  MobileNavMenuBox,
  NavbarWrapper,
  NhsLogoWrapper,
  StyledNhsLogo,
} from 'components/Navbar/Navbar.styled';
import { PatientNavbarDropdowns } from 'configs/RoutesConfig';
import { useAuth } from 'context/AuthContext';
import { useRole } from 'context/RoleContext';
import { useThemeMedia } from 'hooks/useThemeMedia';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { mq } from 'styles/sizes';

import { LOGIN_ROUTE, patientURL } from '../../routes/AppRoutes';
import { usePatientSites } from './PatientHooks';

type MenuBtnProps = {
  onClick: () => unknown;
};
const MenuBtn = (props: MenuBtnProps) => {
  return <Bars {...props} id="menu-btn" />;
};

interface PatientNavBarProps {
  showNHSLogo?: boolean;
  nhsLogoTitle?: string;
  showNhsLogoForNoUser?: boolean;
  showLoginButtonForNoUser?: boolean;
}

const PatientNavBarDesktop = ({
  showNHSLogo = true,
  nhsLogoTitle,
  showNhsLogoForNoUser,
  showLoginButtonForNoUser,
}: PatientNavBarProps) => {
  const { sangixUser, logout } = useAuth();
  const { hasAnyOfRoles } = useRole();
  const { t } = useCommonTranslation();

  const navbarDropdowns = useMemo(
    () =>
      !sangixUser
        ? null
        : PatientNavbarDropdowns({
            sangixUser,
            logout,
            hasAnyOfRoles,
            t,
          }),
    [hasAnyOfRoles, logout, t, sangixUser],
  );

  if ((showNhsLogoForNoUser || showLoginButtonForNoUser) && !sangixUser) {
    return (
      <Navbar
        nhsLogo={showNhsLogoForNoUser}
        nhsLogoTitle={nhsLogoTitle}
        patientLayout
        loginButtonForNoUser={showLoginButtonForNoUser}
      />
    );
  }

  return (
    navbarDropdowns && (
      <Navbar
        nhsLogo={showNHSLogo}
        nhsLogoTitle={nhsLogoTitle}
        dropdowns={navbarDropdowns}
        patientLayout
        releaseNote={{
          key: 'PATIENT',
          to: `${patientURL}/release-notes`,
        }}
      />
    )
  );
};

const PatientNavBarMobileGuest = ({
  showNhsLogo,
  nhsLogoTitle,
  showLoginButton,
}: {
  showNhsLogo?: boolean;
  nhsLogoTitle?: string;
  showLoginButton?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useCommonTranslation();

  return (
    <>
      {showNhsLogo && (
        <NavbarWrapper patientLayout>
          <NhsLogoWrapper>
            <StyledNhsLogo />
            {nhsLogoTitle}
          </NhsLogoWrapper>
        </NavbarWrapper>
      )}
      <MenuBtn onClick={() => setOpen(!open)} />
      <MobileNavMenuBox open={open}>
        <MobileNavList onClick={() => setOpen(false)}>
          {showLoginButton && (
            <MobileNavItem>
              <Link to={LOGIN_ROUTE}>{t('login')}</Link>
            </MobileNavItem>
          )}
          <MobileNavItem>
            <Link to={`/support`}>{t('support')}</Link>
          </MobileNavItem>
          <MobileNavItem>
            <Link to={`/gdpr`}>{t('personal-data')}</Link>
          </MobileNavItem>
        </MobileNavList>
      </MobileNavMenuBox>
    </>
  );
};

const PatientNavBarMobile = ({
  showNHSLogo = true,
  nhsLogoTitle,
  showNhsLogoForNoUser,
  showLoginButtonForNoUser,
}: PatientNavBarProps) => {
  const { t } = useCommonTranslation();
  const [open, setOpen] = useState(false);
  const { sangixUser, logout } = useAuth();
  const { activeSite } = usePatientSites();

  if (!sangixUser)
    return (
      <PatientNavBarMobileGuest
        showNhsLogo={showNhsLogoForNoUser}
        nhsLogoTitle={nhsLogoTitle}
        showLoginButton={showLoginButtonForNoUser}
      />
    );
  return (
    <>
      <NavbarWrapper patientLayout>
        {showNHSLogo && (
          <NhsLogoWrapper>
            <StyledNhsLogo />
            {nhsLogoTitle !== undefined ? nhsLogoTitle : activeSite.name}
          </NhsLogoWrapper>
        )}
      </NavbarWrapper>
      <MenuBtn onClick={() => setOpen(!open)} />
      <MobileNavMenuBox open={open}>
        <MobileNavList onClick={() => setOpen(false)}>
          <MobileNavItem>
            <Link to={`${patientURL}/release-notes`}>{t('release-notes')}</Link>
          </MobileNavItem>
          <MobileNavItem>
            <Link to={`${patientURL}/profile`}>{t('my-profile')}</Link>
          </MobileNavItem>
          <MobileNavItem>
            <Link to={`${patientURL}/change-password`}>{t('password')}</Link>
          </MobileNavItem>
          <MobileNavItem>
            <Link to={`/support`}>{t('support')}</Link>
          </MobileNavItem>
          <MobileNavItem>
            <Link to={`/gdpr`}>{t('personal-data')}</Link>
          </MobileNavItem>
          <MobileNavItem onClick={logout}>{t('logout')}</MobileNavItem>
        </MobileNavList>
      </MobileNavMenuBox>
    </>
  );
};

export const PatientNavBar = (props: PatientNavBarProps) => {
  const isDesktop = useThemeMedia(mq.sm);
  if (isDesktop) return <PatientNavBarDesktop {...props} />;
  return <PatientNavBarMobile {...props} />;
};
