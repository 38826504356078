import { FormRow } from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import React from 'react';
import { getHookFormTyped, toRem } from 'utils/helpers';
import { LABEL_BEFORE } from '../../../../styles/fontsStyleUtils';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { UseFormWatch } from 'react-hook-form/dist/types/form';

export type INewPasswordForm = {
  newPasswordForm: {
    password: string;
    confirm_password: string;
  };
};

export type INewPasswordInput<T extends INewPasswordForm> = {
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  watch: UseFormWatch<T>;
  isLoading?: boolean;
};

export const NewPasswordInput = <T extends INewPasswordForm>({
  isLoading,
  ...rest
}: INewPasswordInput<T>) => {
  const { t } = useCommonTranslation();
  const { errors, register, watch } = getHookFormTyped<INewPasswordForm, T>(
    rest,
  );

  return (
    <>
      <FormRow>
        <Input
          label={t('new-password')}
          placeholder={t('new-password')}
          labelPosition={LABEL_BEFORE}
          disabled={isLoading}
          fixedLabelWidth={`${toRem(140)}rem`}
          type="password"
          helperText={errors.newPasswordForm?.password?.message}
          hasError={Boolean(errors.newPasswordForm?.password)}
          {...register('newPasswordForm.password', {
            required: t('must-not-empty') as string,
            pattern: {
              value: /\d/,
              message: t('password-pattern-validation') as string,
            },
            minLength: {
              value: 8,
              message: t('password-minlength-validation') as string,
            },
          })}
        />
      </FormRow>
      <FormRow>
        <Input
          label={t('confirm-password')}
          placeholder={t('confirm-password')}
          labelPosition={LABEL_BEFORE}
          disabled={isLoading}
          fixedLabelWidth={`${toRem(140)}rem`}
          type="password"
          helperText={errors.newPasswordForm?.confirm_password?.message}
          hasError={Boolean(errors.newPasswordForm?.confirm_password)}
          {...register('newPasswordForm.confirm_password', {
            required: t('must-not-empty') as string,
            validate: (value) =>
              value === watch('newPasswordForm.password') ||
              (t('confirm-password-validation') as string),
          })}
        />
      </FormRow>
    </>
  );
};
