import Axios from 'configs/Axios';
import {
  GPFilters,
  GPFormValues,
  GPResponseData,
} from 'types/generalPractices';
import { AxiosRequestConfig } from 'axios';

export const getGps = (filters?: GPFilters, noToken?: boolean) => {
  const { trustIDs, ...restFilters } = filters || {};
  const config: AxiosRequestConfig = {
    params: {
      ...restFilters,
      trustID: trustIDs && trustIDs.join(','),
    },
  };
  if (noToken) {
    config['headers'] = { Authorization: '' };
  }
  return Axios.get<GPResponseData>('/gpSurgery', config);
};

export const deleteGP = (gpId: string) => Axios.delete(`/gpSurgery/${gpId}`);

export const addNewGP = (data: GPFormValues) =>
  Axios.post<GPResponseData>('/gpSurgery', data);

export const updateGP = (data: GPFormValues) => Axios.put('/gpSurgery', data);

export const mergeGpSurgeries = (sourceId: string, targetId: string) => {
  return Axios.post(`/gpSurgery/${targetId}/merge/${sourceId}`);
};
