import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import {
  PatientBookingStageTitle,
  PatientBookingStageTitle2,
} from '../PatientBooking.styled';
import { CardLoader } from '../../../../components/common/Loading';
import {
  DATE_FNS_FULL_MONTH_YEAR_FORMAT,
  DATE_FNS_UK_DATE_MONTH_ONLY_FORMAT,
  formatDate,
} from '../../../../utils/dateUtil';
import { CommonButton } from 'components/common/Forms/Button';

export const PatientBookingPickDayLoader = ({
  searchPeriod: { toDate, fromDate },
  stopSearchAction,
}: {
  searchPeriod: {
    fromDate: Date;
    toDate: Date;
  };
  stopSearchAction: () => any;
}) => {
  const { t } = useCommonTranslation();

  return (
    <>
      <PatientBookingStageTitle>
        {t('pick-day-searching')}
      </PatientBookingStageTitle>
      <PatientBookingStageTitle2>
        {t('pick-day-searching-in', {
          in: formatDate(fromDate, DATE_FNS_FULL_MONTH_YEAR_FORMAT),
        })}
      </PatientBookingStageTitle2>
      <CardLoader
        text={t('pick-day-searching-range', {
          from: formatDate(fromDate, DATE_FNS_UK_DATE_MONTH_ONLY_FORMAT),
          to: formatDate(toDate, DATE_FNS_UK_DATE_MONTH_ONLY_FORMAT),
        })}
      />
      <CommonButton variant={'danger'} onClick={() => stopSearchAction()}>
        {t('stop-search')}
      </CommonButton>
    </>
  );
};
