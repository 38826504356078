import {
  ChangeEventHandler,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { CommonInput, GlobalInputProps } from './Input';
import { formatPhoneNumber } from '../../../utils/helpers';

export const InputPhoneNumber = forwardRef(
  ({ onChange, value, ...inputProps }: GlobalInputProps, ref) => {
    const [formattedValue, setFormattedValue] = useState(
      formatPhoneNumber(value),
    );

    useImperativeHandle(ref, () => ({
      formattedValue,
    }));

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      const formattedNumber = formatPhoneNumber(event.target.value);
      setFormattedValue(formattedNumber);

      if (onChange) {
        onChange(event);
      }
    };

    return (
      <CommonInput
        value={formattedValue}
        onChange={handleChange}
        {...inputProps}
        type="tel"
      />
    );
  },
);
