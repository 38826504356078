import styled from 'styled-components';
import {
  Row,
  WrappingRow,
} from '../../../../components/common/BasicStyles.styled';

export const SiteLinksRow = styled(WrappingRow)`
  width: 100%;
  position: relative;
`;

export const QRCodeWrapper = styled(Row)`
  justify-content: center;
  margin-bottom: var(--s4);
`;
