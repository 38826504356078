import { CommonButton as Button } from 'components/common/Forms/Button';
import { RowActions } from 'components/Table/Table.styled';
import React, { Fragment, ReactElement, useMemo } from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { Column } from 'react-table';
import { TableHeadStyle } from 'styles/Typography';
import {
  CubicleData,
  CubicleFilters,
  CubicleResourceType,
  CubicleResponseData,
} from 'types/cubicles';
import { getNewColumnWidth } from 'utils/table';

import { PaginatedFiltersWithSet } from '../../../../../hooks/usePaginatedFilters';
import { FetchedDataTable } from '../../../../Table/FetchedDataTable';
import { getFilterControls } from '../../../../Table/TableUtils';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';

type Props = {
  cubiclesData?: CubicleResponseData;
  openFormHandler: (value?: string) => void;
  handleDeleteCubicle?: (value: string) => void;
  isCubicleOccupied?: (value: string) => boolean;
  isListing?: boolean;
  isLoading: boolean;
  isFetching: boolean;
} & PaginatedFiltersWithSet<CubicleFilters>;

export const CubiclesTable = ({
  cubiclesData,
  openFormHandler,
  handleDeleteCubicle,
  isCubicleOccupied,
  isListing,
  isLoading,
  filters,
  setFilters,
  isFetching,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();

  const columns = useMemo(() => {
    const { fontSize } = TableHeadStyle;
    return [
      {
        Header: t('cubicle-name'),
        accessor: 'name',
        cellStyle: {
          ...TableHeadStyle,
          textTransform: 'capitalize',
        },
        width: getNewColumnWidth(40),
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: t('calendar-template'),
        accessor: (row) => row.resource_types,
        id: 'resource_types',
        style: {
          flex: 1,
        },
        cellStyle: isListing
          ? {
              ...TableHeadStyle,
              textTransform: 'capitalize',
            }
          : {},
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value }: { value: CubicleResourceType[] }) =>
          value.map((rt) => rt.name).join(', '),
      },
      ...(isListing
        ? [
            {
              Header: t('specialist-name'),
              accessor: 'user_full_name',
              cellStyle: {
                fontSize,
              },
              disableSortBy: true,
              disableFilters: true,
            },
          ]
        : []),
      {
        ...getFilterControls({ t, filters, setFilters }),
        Cell: ({ value }: { value: string }) => {
          return (
            <RowActions>
              {!isListing ? (
                <Fragment>
                  <Button
                    size="auto"
                    iconOnly
                    variant="primary"
                    title={t('edit-cubicle')}
                    onClick={() => openFormHandler(value)}
                  >
                    <FaPencilAlt />
                  </Button>
                  <Button
                    size="auto"
                    iconOnly
                    variant="danger"
                    title={t('remove-cubicle')}
                    onClick={() => handleDeleteCubicle?.(value)}
                  >
                    <FaTrashAlt />
                  </Button>
                </Fragment>
              ) : (
                <Button
                  disabled={isCubicleOccupied?.(value)}
                  variant="primary"
                  onClick={() => openFormHandler(value)}
                >
                  {t('enter')}
                </Button>
              )}
            </RowActions>
          );
        },
      },
    ] as Column<CubicleData>[];
  }, [
    t,
    isListing,
    openFormHandler,
    handleDeleteCubicle,
    isCubicleOccupied,
    filters,
    setFilters,
  ]);

  return (
    <FetchedDataTable
      columns={columns}
      data={cubiclesData}
      isLoading={isLoading}
      filters={filters}
      onSortOrPageChange={setFilters}
      isFetching={isFetching}
    />
  );
};
