import { Card, CardHeader } from 'components/common';
import {
  PopupDesc,
  PopupIconWrapper,
  PopupTitle,
} from 'components/Popup/ConfirmDialog/ConfirmDialog.styled';
import { PatientLayout } from 'containers/PatientLayout';
import { PropsWithChildren } from 'react';
import { FaUserClock } from 'react-icons/fa';

type MyAppointmentsCommonPageProps = PropsWithChildren<{
  title: string;
  description?: string;
}>;

export const MyAppointmentsCommonPage = ({
  title,
  description,
  children,
}: MyAppointmentsCommonPageProps) => {
  return (
    <PatientLayout graphics="patient">
      <Card>
        <CardHeader>
          <PopupIconWrapper inlineIcon>
            <FaUserClock />
          </PopupIconWrapper>
          <PopupTitle compact>{title}</PopupTitle>
          {description && <PopupDesc compact>{description}</PopupDesc>}
        </CardHeader>
        {children}
      </Card>
    </PatientLayout>
  );
};
