import { CommonButton } from 'components/common/Forms/Button';
import { Loading } from 'components/common/Loading';
import { useAuth } from 'context/AuthContext';
import { useMutateCancelApptWithConfirm } from 'hooks/appointments/useMutateCancelApptWithConfirm';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { useUserAppointments } from 'query/appointments';
import { successToast } from 'utils/toast';
import { AppointmentItemCommon } from './AppointmentItemCommon';
import { AppointmentItem, AppointmentItemRight } from './MyAppointments.styled';
import { NoAppointmentsBar } from './NoAppointmentsBar';

import { USER_APPOINTMENTS_TYPES_BOOKED } from '../../../utils/appointmentUtil';

export const BookedAppointmentsList = () => {
  const { t } = useCommonTranslation();
  const { sangixUser } = useAuth();
  const { data, isFetching } = useUserAppointments(
    USER_APPOINTMENTS_TYPES_BOOKED,
    sangixUser?.id,
  );
  const { cancelWithConfirm, isLoading: isCancelling } =
    useMutateCancelApptWithConfirm({
      onSuccess: () => successToast(t('appointment-cancel-successful')),
    });
  const list = data || [];
  const isLoading = isCancelling || isFetching;

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && list.length === 0 && (
        <NoAppointmentsBar text={t('my-appointments-empty')} />
      )}
      {list.map((appt) => (
        <AppointmentItem key={appt.id}>
          <AppointmentItemCommon appt={appt} />
          <AppointmentItemRight>
            {appt.status === 'BOOKED' && (
              <CommonButton
                variant="danger"
                size="standard"
                onClick={() => cancelWithConfirm(appt.id)}
              >
                {t('cancel')}
              </CommonButton>
            )}
          </AppointmentItemRight>
        </AppointmentItem>
      ))}
    </div>
  );
};
