import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { CardTitle } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import InfoMessage from 'components/common/InfoMessage';
import { Td, Th } from 'components/Table/Table.styled';
import { StaffSettingsTimePlanningTree } from 'configs/RoutesConfig';
import React, { Fragment, ReactElement } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { TableHeadStyle } from 'styles/Typography';
import {
  getOrderedWeekdayNames,
  isSameDay,
} from '../../../../../utils/dateUtil';
import {
  APPT_TEMPLATE_FULL_WEEK_DEFAULT_DATE,
  getDefaultTemplateDate,
} from '../../../../../utils/apptTemplateUtils';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { DefaultTimePlanningDaySelectRow } from '../../../../../containers/staff/settings/time-planning/StaffDefaultTimePlanning.styled';

interface Props {
  weekDays: number[];
  setDate: (date: Date) => void;
  fullWeekDefaultEditAvailable: boolean;
  selectedDate: Date;
}

const DefaultPlanningRow = ({
  onClick,
  label,
  isSelected,
}: {
  onClick: () => any;
  label: string;
  isSelected: boolean;
}) => {
  const { t } = useCommonTranslation();
  return (
    <DefaultTimePlanningDaySelectRow isSelected={isSelected}>
      <Th style={{ ...(TableHeadStyle as any), textTransform: 'none' }}>
        {label}
      </Th>
      <Td>
        {isSelected ? (
          <CommonButton type="button" variant="selected" disabled>
            {t('selected')}
          </CommonButton>
        ) : (
          <CommonButton type="button" variant="primary" onClick={onClick}>
            {t('edit')}
          </CommonButton>
        )}
      </Td>
    </DefaultTimePlanningDaySelectRow>
  );
};

export const TimePlanningDefaultTable = ({
  weekDays,
  setDate,
  fullWeekDefaultEditAvailable,
  selectedDate,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();

  return (
    <Fragment>
      <LinkCardTabs tabs={StaffSettingsTimePlanningTree(t)} />
      <InfoMessage
        type="info"
        icon={<FaInfoCircle />}
        text={t('time-planning-info')}
      />
      <CardTitle>{t('master-template')}</CardTitle>
      {getOrderedWeekdayNames(t, weekDays).map((day) => {
        const defaultTemplate = getDefaultTemplateDate(day.backendDowIndex);
        return (
          <DefaultPlanningRow
            key={`week_${day.backendDowIndex}`}
            onClick={() => setDate(defaultTemplate)}
            label={day.label}
            isSelected={isSameDay(selectedDate, defaultTemplate)}
          />
        );
      })}
      {fullWeekDefaultEditAvailable && (
        <DefaultPlanningRow
          key={`week_day_template`}
          onClick={() => setDate(APPT_TEMPLATE_FULL_WEEK_DEFAULT_DATE)}
          label={t('day-template')}
          isSelected={selectedDate === APPT_TEMPLATE_FULL_WEEK_DEFAULT_DATE}
        />
      )}
    </Fragment>
  );
};
