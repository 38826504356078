import { useEffect, useState } from 'react';
import { KioskSearchResultHint } from '../Kiosk.styled';
import { KioskLayout } from '../KioskLayout';
import { KioskPreCheckInQuestionRender } from './KioskPreCheckInQuestionRender';
import { KioskLayoutWithResetKioskButton } from '../KioskLayoutWithResetKioskButton';
import { usePreCheckinQuestion } from '../../../../hooks/kiosk/usePreCheckinQuestion';

export const KioskPreCheckinQuestion = ({
  onPreCheckinFinished,
}: {
  onPreCheckinFinished: () => any;
}) => {
  const { preCheckinQuestionNo, hasPreCheckinQuestion } =
    usePreCheckinQuestion();
  useEffect(() => {
    if (!hasPreCheckinQuestion) {
      onPreCheckinFinished();
    }
  }, [hasPreCheckinQuestion, onPreCheckinFinished]);

  const [preCheckInAnsweredNo, setPreCheckInAnsweredNo] = useState(false);
  return (
    <>
      {preCheckInAnsweredNo ? (
        <KioskLayoutWithResetKioskButton
          onExitClicked={() => setPreCheckInAnsweredNo(false)}
        >
          <KioskSearchResultHint>{preCheckinQuestionNo}</KioskSearchResultHint>
        </KioskLayoutWithResetKioskButton>
      ) : (
        <KioskLayout>
          <KioskPreCheckInQuestionRender
            onYes={() => onPreCheckinFinished()}
            onNo={() => setPreCheckInAnsweredNo(true)}
          />
        </KioskLayout>
      )}
    </>
  );
};
