import { CardLoader } from 'components/common/Loading';
import { forwardRef } from 'react';
import ReactQuill from 'react-quill';
import { Wrapper } from './WYSIWYG.styled';

export interface Props {
  isLoading?: boolean;
  defaultValue: string;
}

export default forwardRef<any, Props>(function WYSIWYG(
  { isLoading, defaultValue },
  ref,
) {
  return (
    <Wrapper>
      <ReactQuill
        ref={ref}
        defaultValue={defaultValue}
        theme="snow"
        readOnly={isLoading}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            [
              { list: 'ordered' },
              { list: 'bullet' },
              { indent: '-1' },
              { indent: '+1' },
            ],
            ['link'],
            ['clean'],
          ],
        }}
        formats={[
          'header',
          'font',
          'size',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'list',
          'bullet',
          'indent',
          'link',
        ]}
      ></ReactQuill>
      {isLoading && <CardLoader fillWrapper={true} />}
    </Wrapper>
  );
});
