import { RunningText } from 'components/common/RunningText';
import { NhsLogoWrapper, StyledNhsLogo } from 'components/Navbar/Navbar.styled';
import { useSiteStringAttributes } from 'hooks/useAttribute';
import { usePublicSite } from 'hooks/public/usePublicSite';
import { LCDHeaderStyled, LCDRunningTextBox } from './LCD.styled';
import { LCD_RUNNING_TEXT } from '../../../configs/siteAndTrustAttributes';

export const LCDHeader = () => {
  const { activeSite } = usePublicSite();
  const [runningText = ''] = useSiteStringAttributes(LCD_RUNNING_TEXT);

  return (
    <LCDHeaderStyled>
      <NhsLogoWrapper
        style={{
          fontSize: 'var(--h2)',
        }}
      >
        <StyledNhsLogo />
        {activeSite.name}
      </NhsLogoWrapper>
      <LCDRunningTextBox>
        <RunningText text={runningText} />
      </LCDRunningTextBox>
    </LCDHeaderStyled>
  );
};
