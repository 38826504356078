import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import { CommonButton } from '../../../components/common/Forms/Button';
import CommonInput from '../../../components/common/Forms/Input';
import { CardLoader } from '../../../components/common/Loading';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { useQuerySitesByName } from '../../../query/sites';
import { getSiteById } from '../../../services/sites';
import { SiteData } from '../../../types/sites';
import { errorToast } from '../../../utils/toast';
import {
  FormDesc,
  FormLargeDesc,
  FormTitle,
  SignUpHospitalList,
  SignUpHospitalName,
  SignUpHospitalShortInfo,
  SignUpHospitalWrapper,
} from './AddHospitalForm.styled';

interface Props {
  onSiteSelected: (site: SiteData) => void;
}

export const AddHospitalStepSearch = ({
  onSiteSelected,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();
  const [siteLoading, setSiteLoading] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
  const [, cancel] = useDebounce(
    () => {
      setDebouncedSearchValue(searchValue);
    },
    500,
    [searchValue, setDebouncedSearchValue],
  );

  useEffect(() => {
    return () => {
      cancel();
    };
  }, [cancel]);

  const handleSiteIdSelect = useCallback(
    async (siteId: string) => {
      try {
        setSiteLoading(true);
        onSiteSelected((await getSiteById(siteId)).data);
      } catch (err) {
        errorToast(err);
      } finally {
        setSiteLoading(false);
      }
    },
    [onSiteSelected],
  );

  const { data: suggestions } = useQuerySitesByName(debouncedSearchValue);

  const isLoading = siteLoading;

  return (
    <>
      {isLoading ? (
        <CardLoader />
      ) : (
        <>
          <FormTitle>{t('select-hospital')}</FormTitle>
          <FormLargeDesc>{t('sites-hospital-select-title')}</FormLargeDesc>
          <FormDesc>{t('sites-hospital-select-subtitle')}</FormDesc>
          <CommonInput
            label={t('hospital')}
            labelPosition={'labelBefore'}
            required
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            value={searchValue}
            name={'hospital_search'}
            containerStyle={{ marginBottom: 0 }}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            autoFocus
          />
          <SignUpHospitalList>
            {suggestions?.data.map((siteInfo, i) => (
              <SignUpHospitalWrapper key={`hospital_select_${siteInfo.id}`}>
                <div>
                  <SignUpHospitalName>{siteInfo.name}</SignUpHospitalName>
                  <SignUpHospitalShortInfo>
                    {siteInfo.sign_up_short_info}
                  </SignUpHospitalShortInfo>
                </div>
                <CommonButton
                  style={{
                    marginLeft: 'auto',
                  }}
                  size={'standard'}
                  variant={'primary'}
                  onClick={() => handleSiteIdSelect(siteInfo.id)}
                >
                  {t('select')}
                </CommonButton>
              </SignUpHospitalWrapper>
            ))}
          </SignUpHospitalList>
        </>
      )}
    </>
  );
};
