import React from 'react';
import { CommonInputWrapper, ExpandingLabelStyle } from './Forms.styled';

interface Props {
  text: string;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
}

export const ExpandingText = ({ text, style, textStyle }: Props) => {
  return (
    <CommonInputWrapper style={style}>
      <ExpandingLabelStyle style={textStyle}>{text}</ExpandingLabelStyle>
    </CommonInputWrapper>
  );
};
