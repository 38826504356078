import { useCallback } from 'react';
import { useMutateDeleteWaitingList } from '../../../query/waitingList';
import { usePrimaryResourceType } from '../../usePrimaryResourceType';
import { useWaitingListMemberId } from './useWaitingListMemberId';
import { successToast } from '../../../utils/toast';
import { useCommonTranslation } from '../../i18n/useCommonTranslation';

export const useDeleteWaitingList = () => {
  const { t } = useCommonTranslation();
  const memberId = useWaitingListMemberId();

  const { primaryResourceType } = usePrimaryResourceType();
  const { mutate, isLoading } = useMutateDeleteWaitingList({
    onSuccess: () => {
      successToast(t('waiting-list-delete-successful'));
    },
  });
  const deleteWaitingList = useCallback(() => {
    primaryResourceType &&
      mutate({
        memberId: memberId,
        resourceTypeId: primaryResourceType.id,
      });
  }, [mutate, primaryResourceType, memberId]);
  return {
    deleteWaitingList,
    isLoading,
  };
};
