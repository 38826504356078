import { KioskLayoutWithResetKioskButton } from '../KioskLayoutWithResetKioskButton';
import {
  KioskSearchResultErrorMessage,
  KioskSearchResultHint,
} from '../Kiosk.styled';
import { useSiteStringAttributes } from '../../../../hooks/useAttribute';
import {
  CHECK_IN_EARLY_TEXT,
  CHECK_IN_LATE_TEXT,
  CHECKIN_DUPLICATE_TEXT,
} from '../../../../configs/siteAndTrustAttributes';
import {
  KIOSK_EARLY_ERROR,
  KIOSK_GENERAL_ERROR,
  KIOSK_LATE_ERROR,
  KIOSK_MULTIPLE_RESULTS_ERROR,
  KIOSK_NO_APPTS_FOUND_ERROR,
  KioskErrorState,
} from '../../../../hooks/kiosk/useKioskState';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { KioskApptSearchParams } from '../../../../hooks/kiosk/useKioskFindAppointment';

export const KioskError = ({
  onExitClicked,
  kioskState,
  searchParams,
}: {
  onExitClicked: () => any;
  kioskState: KioskErrorState;
  searchParams: KioskApptSearchParams;
}) => {
  const { t } = useCommonTranslation();
  // We are expecting these to be filled, so defaults not translated
  const [
    checkinEarlyText = 'Early checkin',
    checkinLateText = 'Late checkin',
    checkinDuplicateText = 'Please go to counter',
  ] = useSiteStringAttributes(
    CHECK_IN_EARLY_TEXT,
    CHECK_IN_LATE_TEXT,
    CHECKIN_DUPLICATE_TEXT,
  );
  return (
    <KioskLayoutWithResetKioskButton
      onExitClicked={onExitClicked}
      resetLabel={t('exit')}
    >
      {kioskState === KIOSK_EARLY_ERROR || kioskState === KIOSK_LATE_ERROR ? (
        <KioskSearchResultHint>
          {kioskState === KIOSK_EARLY_ERROR
            ? checkinEarlyText
            : checkinLateText}
        </KioskSearchResultHint>
      ) : (
        <KioskSearchResultErrorMessage>
          {kioskState === KIOSK_NO_APPTS_FOUND_ERROR
            ? t('kiosk-user-not-booked', {
                initials: searchParams.searchString,
              })
            : kioskState === KIOSK_GENERAL_ERROR
            ? t('kiosk-checkin-error')
            : kioskState === KIOSK_MULTIPLE_RESULTS_ERROR
            ? checkinDuplicateText
            : 'Unknown error'}
        </KioskSearchResultErrorMessage>
      )}
    </KioskLayoutWithResetKioskButton>
  );
};
