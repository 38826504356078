import { useCallback } from 'react';
import { RegisterToSangixBE } from './useUserRegisterToSangixBE';
import { firebaseDeleteUser } from '../../../services/firebase';
import { useAuth } from '../../../context/AuthContext';
import { UserRegisterRequest } from '../../../types/users';
import { useHistory } from 'react-router';
import { RESET_PASSWORD_ROUTE } from '../../../routes/AppRoutes';

export type RegisterToSangixFirebaseAlreadySignedUp = (
  user: UserRegisterRequest,
) => Promise<unknown>;

export const useRegisterFirebaseAlreadySignedUp = ({
  registerToSangixBe,
}: {
  registerToSangixBe: RegisterToSangixBE;
}) => {
  const { firebaseUser } = useAuth();
  const { push } = useHistory();
  const registerFirebaseAlreadySignedUp: RegisterToSangixFirebaseAlreadySignedUp =
    useCallback(
      async (user) => {
        const error = await registerToSangixBe(user, firebaseUser);
        if (error !== undefined) {
          await firebaseDeleteUser(firebaseUser);
          push(RESET_PASSWORD_ROUTE);
        }
      },
      [registerToSangixBe, firebaseUser, push],
    );
  return { registerFirebaseAlreadySignedUp };
};
