import { colorSchemeValueWithBackgroundGraphics } from 'configs/constants';
import { GlobalAttribute } from 'types/common';

import {
  siteAttributeKeysSubset,
  updateSiteAttributes,
} from '../SitePreferencesHooks';
import { UseGeneralSitePreferencesFormHooks } from './GeneralSitePreferencesHooks';
import {
  COLOR_PALETTE,
  ENABLE_BACKGROUND_GRAPHICS,
  VARIANT,
} from '../../../../configs/siteAndTrustAttributes';

const keys = siteAttributeKeysSubset([
  COLOR_PALETTE,
  ENABLE_BACKGROUND_GRAPHICS,
  VARIANT,
]);

const restAttributes: GlobalAttribute[] = keys.map((key) => {
  switch (key) {
    case COLOR_PALETTE:
      return {
        key,
        value_int: 0,
        value_str: colorSchemeValueWithBackgroundGraphics,
      };
    case ENABLE_BACKGROUND_GRAPHICS:
      return {
        key,
        value_int: 1,
        value_str: 'true',
      };
    case VARIANT:
      return {
        key,
        value_int: 0,
        value_str: 'phlebotomy',
      };
    default:
      return {
        key,
      };
  }
});

export const useVisualSettingsFormHooks: UseGeneralSitePreferencesFormHooks =
  () => {
    return {
      restAttributes,
      beforeSubmit: (updatedSite, formValues) => {
        return {
          ...updatedSite,
          attributes: updateSiteAttributes(
            updatedSite.attributes,
            formValues.attributes,
            keys,
            (_, siteValue, formValue) => {
              return formValue || siteValue;
            },
          ),
        };
      },
    };
  };
