import Axios from 'configs/Axios';
import {
  TrustData,
  TrustFormValues,
  TrustResponseData,
  TrustsFilters,
} from 'types/trusts';

export const getAllTrusts = (filters?: TrustsFilters) =>
  Axios.get<TrustResponseData>('/trust', {
    params: filters,
  });

export const getTrust = (id: string) => Axios.get<TrustData>(`/trust/${id}`);

export const addNewTrust = (data: TrustFormValues) =>
  Axios.post<TrustResponseData>('/trust', data);

export const updateTrust = (data: any) => Axios.put('/trust', data);

export const deleteTrust = (trustId: string) =>
  Axios.delete(`/trust/${trustId}`);
