import { Appointment } from '../../../types/appointments';
import { useCallback } from 'react';
import {
  useMutateBookAppointment,
  useMutateQueueAppointment,
} from '../../../query/appointments';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { errorToast, successToast } from '../../../utils/toast';
import { useConfirmDialog } from '../../../components/Popup/ConfirmDialog/confirmDialogHook';
import { AppointmentCardControl } from './SlotAppointment';
import { isToday, parseIsoDate } from '../../../utils/dateUtil';

export const useHandleAppointmentBodyClick = ({
  appointment,
  setOrToggleEditAppointmentId,
}: {
  appointment: Appointment;
} & Pick<AppointmentCardControl, 'setOrToggleEditAppointmentId'>) => {
  const { confirm } = useConfirmDialog();
  const { t } = useCommonTranslation();
  const { mutate: queueAppointment, isLoading: isQueueLoading } =
    useMutateQueueAppointment(
      {
        onSuccess: () => {
          successToast(t('appointment-queued-success'));
        },
      },
      true,
    );
  const { mutate: bookAppointment, isLoading: isBookLoading } =
    useMutateBookAppointment({
      onSuccess: () => {
        successToast(t('appointment-booked-success'));
      },
    });

  const handleBodyClick = useCallback(() => {
    switch (appointment.status) {
      case 'BOOKED':
        if (isToday(parseIsoDate(appointment.appt_time))) {
          !isQueueLoading && queueAppointment(appointment.id);
        } else {
          setOrToggleEditAppointmentId(appointment.id);
        }
        break;
      case 'QUEUE':
        !isBookLoading &&
          confirm({
            title: t('appointment-uncheck-confirm-description'),
            onConfirm: () => {
              bookAppointment({
                id: appointment.id,
              });
            },
          });
        break;
      case 'CALLED':
        errorToast({ message: t('appointment-cannot-uncheck-called') });
        break;
      case 'DNA':
      case 'MIA':
        !isQueueLoading && queueAppointment(appointment.id);
        break;
      default:
        setOrToggleEditAppointmentId(appointment.id);
    }
  }, [
    appointment,
    queueAppointment,
    bookAppointment,
    confirm,
    t,
    setOrToggleEditAppointmentId,
    isBookLoading,
    isQueueLoading,
  ]);

  return {
    handleBodyClick,
  };
};
