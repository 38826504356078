import { Card } from 'components/common';
import copy from 'copy-to-clipboard';
import { useCallback, useMemo } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { generatePath } from 'react-router';

import QRCode from 'react-qr-code';
import {
  CommonButton,
  CommonLink,
} from '../../../../components/common/Forms/Button';
import {
  FormActionsStyle,
  FormHeader,
  FormRow,
  FormTitle,
} from '../../../../components/common/Forms/Forms.styled';
import { CommonInput } from '../../../../components/common/Forms/Input';
import { useDownloadSvg } from '../../../../hooks/useDownloadSvg';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { SiteData } from '../../../../types/sites';
import { successToast } from '../../../../utils/toast';
import { QRCodeWrapper } from './SiteLinks.styled';

type Props = {
  site: SiteData;
  path: string;
  title: string;
  description: string;
  hideOpenButton?: boolean;
  /**
   * Defaults to `qr-${title} ${site.short_name}`.
   * Text is slugified and lowercased (to make the file name safe).
   */
  qrCodeDownloadName?: string;
};

const generateLink = (path: string) => {
  return `${window.location.origin}${generatePath(path)}`;
};

// Each input must have unique name and each QR code must have unique id
let uniqueId = 0;

export const SiteLinkCard = ({
  site,
  path,
  title,
  description,
  hideOpenButton,
  qrCodeDownloadName,
}: Props) => {
  const { t } = useCommonTranslation();
  const { inputName, qrCodeId } = useMemo(() => {
    uniqueId++;
    return {
      inputName: `siteLinkInput${uniqueId}`,
      qrCodeId: `siteLinkQRCode${uniqueId}`,
    };
  }, []);

  const copyToClipboard = useCallback(
    (text) => {
      if (copy(text)) {
        successToast(t('copied-link'));
      }
    },
    [t],
  );

  const link = generateLink(path);
  const downloadQRCode = useDownloadSvg();

  return (
    <Card mb="var(--s4)">
      <FormHeader>
        <FormTitle>{title}</FormTitle>
      </FormHeader>
      <FormRow>
        <CommonInput
          label={description}
          name={inputName}
          value={link}
          disabled
        />
      </FormRow>
      <QRCodeWrapper>
        <QRCode id={qrCodeId} value={link} />
      </QRCodeWrapper>
      <FormActionsStyle align="center">
        <CommonButton variant="secondary" onClick={() => copyToClipboard(link)}>
          {t('copy-link')}
        </CommonButton>
        <CommonButton
          variant="secondary"
          onClick={() =>
            downloadQRCode({
              svgElementId: qrCodeId,
              downloadName: qrCodeDownloadName
                ? qrCodeDownloadName
                : `qr-${title} ${site.short_name}`,
            })
          }
        >
          {t('download-qr')}
        </CommonButton>
        {!hideOpenButton && (
          <CommonLink variant="secondary" to={path} target="_blank">
            <span>
              {t('open-link')} <FaExternalLinkAlt />
            </span>
          </CommonLink>
        )}
      </FormActionsStyle>
    </Card>
  );
};
