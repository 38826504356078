import { FormRow } from 'components/common/Forms/Forms.styled';
import { ReactElement } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { UseFormRegister } from 'react-hook-form/dist/types/form';

import CommonSelect from '../../../components/common/Forms/Select';
import { PatientValidationRules } from '../../../hooks/patient/useUserValidationRules';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { LABEL_BEFORE } from '../../../styles/fontsStyleUtils';
import { UserMemberData } from '../../../types/authentication';
import { SelectOption } from '../../../types/common';
import { TrustData } from '../../../types/trusts';
import { formatDateOnlySite, parseIsoDate } from '../../../utils/dateUtil';
import { getHookFormTyped } from '../../../utils/helpers';
import { PatientNhsPostcodeData } from '../../common/patient/PatientNhsPostcodeData';
import { MemberFormTitle, MemberFormWrapper } from './AddHospitalForm.styled';
import { gpSurgeryRequiredTrusts } from '../../../utils/trustUtil';
import { MemberFormType } from './AddHospitalStepUpdateMembersUtils';

interface Props<T extends MemberFormType> {
  control: Control<T>;
  errors: FieldErrors<T>;
  register: UseFormRegister<T>;
  controlPrefix: string;
  trusts: TrustData[];
  patientRules: PatientValidationRules;
  gpOptions: SelectOption[];
  member: Omit<UserMemberData, 'relation'>;
}

export const AddHospitalMemberForm = <T extends MemberFormType>({
  trusts,
  patientRules,
  gpOptions,
  member,
  controlPrefix,
  ...rest
}: Props<T>): ReactElement => {
  const { t } = useCommonTranslation();
  const { register, errors, control } = getHookFormTyped<MemberFormType, T>(
    rest,
  );
  const { gpRules } = patientRules;

  return (
    <MemberFormWrapper>
      <MemberFormTitle>
        {member.firstname} {member.lastname}
        {member.birthdate && (
          <>
            &nbsp;
            <small>
              ({formatDateOnlySite(parseIsoDate(member.birthdate))})
            </small>
          </>
        )}
      </MemberFormTitle>
      <PatientNhsPostcodeData
        register={register}
        control={control}
        errors={errors}
        trusts={trusts}
      />
      {gpSurgeryRequiredTrusts(trusts) && (
        <FormRow>
          <Controller
            name={`${controlPrefix}gp_surgery_id`}
            render={({ field }) => (
              <CommonSelect
                {...field}
                labelPosition={LABEL_BEFORE}
                label={t('general-practice')}
                placeholder={t('general-practice')}
                disabled={!gpOptions}
                options={gpOptions}
                helperText={errors.gp_surgery_id?.message}
                hasError={Boolean(errors.gp_surgery_id)}
                onChange={(val) => {
                  field.onChange(val);
                }}
                required
              />
            )}
            control={control}
            rules={gpRules}
          />
        </FormRow>
      )}
    </MemberFormWrapper>
  );
};
