import styled, { css } from 'styled-components';
import { h4, small, stripButton } from 'styles/Typography';
import { toRem } from 'utils/helpers';

export const CalendarTopControlsWrapper = styled.div`
  margin-top: var(--s3);
  display: flex;
  justify-content: center;
  position: relative;

  .calendar-datepicker {
    min-width: ${toRem(130)}rem;
    max-width: ${toRem(130)}rem;
    margin: 0 var(--s2);
  }
`;

export const CalendarLeftControlsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: left;
  gap: var(--s2);
`;

export const CalendarMiddleControlsWrapper = styled.div``;

export const CalendarPrevNextRangePickerWrapper = styled.div`
  display: flex;
`;

export const CalendarWeekViewButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: var(--s2);
  margin-bottom: var(--s3);
`;

export const CalendarRightControlsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: right;
  gap: var(--s2);
`;

export const CalendarHeaderLegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--s1);
`;

export const CalendarInputWrapper = styled.div`
  .input-inner {
    max-width: ${toRem(120)}rem;
  }
`;

export const CalendarHeaderWrapper = styled.form`
  display: flex;
  border-bottom: ${toRem(2)}rem solid var(--greenaccent);
  position: relative;
  column-gap: var(--s3);
`;

export const CalendarHeaderContentWrapper = styled.div`
  display: flex;
  flex: 1;
  column-gap: var(--s3);
  row-gap: var(--s1);
  flex-wrap: wrap;
`;
export const CalendarRefreshButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: var(--s4);
`;
export const CalendarDateRangeViewWrapper = styled.div`
  margin-top: var(--s3);
  display: flex;
  flex-wrap: wrap;
  gap: var(--s3) var(--s3);
  justify-content: space-evenly;
`;
export const DayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(2)}rem var(--s3);
`;
export const HourRow = styled.div`
  display: flex;
  gap: ${toRem(2)}rem;
  justify-content: space-between;
`;
export const TimeSlotPlaceHolder = styled.div`
  ${small};
  font-weight: 500;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${toRem(24)}rem;
  min-height: ${toRem(24)}rem;
  max-height: ${toRem(24)}rem;
`;
export const AppointmentSlotStyle = styled.button<{
  readonly slotType:
    | 'SELECTED'
    | 'DISABLED'
    | 'STAFF_ONLY_AVAILABLE'
    | 'ONLINE_AVAILABLE'
    | 'ALL_BOOKED'
    | 'INVISIBLE';
}>`
  ${stripButton};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${toRem(24)}rem;
  min-height: ${toRem(24)}rem;
  max-height: ${toRem(24)}rem;
  border-radius: ${toRem(4)}rem;
  font-weight: 500;
  ${({ slotType }) => {
    if (slotType === 'SELECTED')
      return css`
        color: var(--white);
        background-color: var(--redaccent);
      `;
    if (slotType === 'DISABLED')
      return css`
        cursor: not-allowed;
        color: var(--white);
        background-color: var(--disabled);
      `;
    if (slotType === 'ONLINE_AVAILABLE')
      return css`
        color: var(--black);
        background-color: var(--greensecondary);
      `;
    if (slotType === 'STAFF_ONLY_AVAILABLE')
      return css`
        color: var(--white);
        background-color: var(--textprimary);
      `;
    if (slotType === 'ALL_BOOKED')
      return css`
        color: var(--white);
        cursor: not-allowed;
        background-color: var(--rednegative);
      `;
    if (slotType === 'INVISIBLE') {
      return css`
        cursor: auto;
      `;
    }
  }}
`;
export const DayOuterWrapper = styled.div`
  min-width: 14.5rem; // Width of the longest title (Wednesday - XX.XX.XXXX)
  text-align: center;
`;
export const DayTitle = styled.h4`
  ${h4};
  text-align: center;
  margin-bottom: var(--s2);
  cursor: pointer;
`;
export const SelectedPatientWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${toRem(320)}rem;
  margin-left: auto;

  p {
    font-size: ${toRem(16)}rem;
    color: var(--greenaccent);
    font-weight: 600;
    margin: 0;
    padding-top: var(--s2);
    white-space: nowrap;
    overflow: hidden;
    margin-right: var(--s1);
    text-overflow: ellipsis;
  }
`;
