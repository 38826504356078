import { MockServer, wrapCountResponseArray } from './server';
import { GeneralPractice } from '../types/generalPractices';
import { TEST_TRUST } from './trusts';

export const TEST_SURGERY: GeneralPractice = {
  id: 'GENERAL_PRACTISE_1',
  name: 'Trumpington Medical Practice at Clay Farm',
  ccg_id: 'ccg_id',
  description: 'Surgery na kopci',
  trust_id: TEST_TRUST.id,
};

export const TEST_SURGERY_2: Partial<GeneralPractice> = {
  id: 'GENERAL_PRACTISE_2',
  name: 'Practise 2',
  ccg_id: 'ccg_id',
  description: 'Surgery pod kopcem',
  trust_id: TEST_TRUST.id,
};

const testPractises: Array<Partial<GeneralPractice>> = [
  TEST_SURGERY,
  TEST_SURGERY_2,
];

export const surgeriesRoutes = (server: MockServer) => {
  server.get('gpSurgery', () => {
    return wrapCountResponseArray(testPractises);
  });
};
