import { RowActions } from 'components/Table/Table.styled';
import {
  CommonButton as Button,
  CommonLink,
} from 'components/common/Forms/Button';
import { ReactElement, useMemo } from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { Column } from 'react-table';
import { TableHeadStyle } from 'styles/Typography';
import { getNewColumnWidth } from 'utils/table';
import { usePaginatedFilters } from '../../../../../hooks/usePaginatedFilters';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { trustMiniSiteURL } from '../../../../../routes/AppRoutes';
import { ItemsResponse } from '../../../../../types/ItemsWithCountResponse';
import { TrustMiniSiteCategory } from '../../../../../types/trustMiniSites';
import { FetchedDataTable } from '../../../../Table/FetchedDataTable';
import { getFilterControls } from '../../../../Table/TableUtils';

type Props = {
  trustId: string;
  categories?: ItemsResponse<TrustMiniSiteCategory>;
  openFormHandler: (value?: string) => void;
  handleDeleteCategory: (value: string) => void;
  isLoading: boolean;
  isFetching: boolean;
};

export const TrustMiniSiteCategoriesTable = ({
  trustId,
  categories,
  openFormHandler,
  handleDeleteCategory,
  isLoading,
  isFetching,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();

  const { filters, setFilters } = usePaginatedFilters();

  const columns = useMemo(() => {
    return [
      {
        Header: t('trust-mini-site-category-name'),
        accessor: 'name',
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(50),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        ...getFilterControls({ t, filters, setFilters }),
        Cell: ({ value }: { value: string }) => {
          return (
            <RowActions>
              <CommonLink
                size="auto"
                variant="secondary"
                to={`${trustMiniSiteURL}/${trustId}/category/${value}`}
                target="_blank"
              >
                {t('open-mini-site-category')}
              </CommonLink>
              <Button
                size="auto"
                iconOnly
                variant="primary"
                title={t('edit-trust-mini-site-category')}
                onClick={() => openFormHandler(value)}
              >
                <FaPencilAlt />
              </Button>
              <Button
                size="auto"
                iconOnly
                variant="danger"
                title={t('remove-trust-mini-site-category')}
                onClick={() => handleDeleteCategory(value)}
              >
                <FaTrashAlt />
              </Button>
            </RowActions>
          );
        },
      },
    ] as Column<TrustMiniSiteCategory>[];
  }, [t, openFormHandler, handleDeleteCategory, filters, setFilters, trustId]);

  return (
    <FetchedDataTable
      columns={columns}
      data={categories}
      isLoading={isLoading}
      isFetching={isFetching}
      onSortOrPageChange={setFilters}
      filters={filters}
    />
  );
};
