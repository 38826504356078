import { GlobalAttribute } from 'types/common';

import {
  KIOSK_AND_SELF_CHECK_IN_LATE,
  MAX_APPT_ALL,
  MAX_APPT_WEEK,
  PATIENTS_EARLIEST_BOOKING_AFTER_IN_DAYS,
  PATIENTS_LATEST_BOOKING_BEFORE_IN_DAYS,
  QUEUE_TO_DNA,
  STAFF_EARLIEST_BOOKING_AFTER_IN_DAYS,
  STAFF_LATEST_BOOKING_BEFORE_IN_DAYS,
} from '../../../../configs/siteAndTrustAttributes';
import {
  siteAttributeKeysSubset,
  updateSiteAttributes,
} from '../SitePreferencesHooks';
import { UseGeneralSitePreferencesFormHooks } from './GeneralSitePreferencesHooks';

const keys = siteAttributeKeysSubset([
  STAFF_EARLIEST_BOOKING_AFTER_IN_DAYS,
  STAFF_LATEST_BOOKING_BEFORE_IN_DAYS,
  PATIENTS_EARLIEST_BOOKING_AFTER_IN_DAYS,
  PATIENTS_LATEST_BOOKING_BEFORE_IN_DAYS,
  MAX_APPT_WEEK,
  MAX_APPT_ALL,
  QUEUE_TO_DNA,
]);

const restAttributes: GlobalAttribute[] = keys.map((key) => ({ key }));

export const useSystemPeriodsFormHooks: UseGeneralSitePreferencesFormHooks =
  () => {
    return {
      restAttributes,
      beforeSubmit: (updatedSite, formValues) => {
        const keysToUpdate = siteAttributeKeysSubset([
          ...keys,
          KIOSK_AND_SELF_CHECK_IN_LATE,
        ]);
        // Make sure QUEUE_TO_DNA is processed earlier
        if (
          keysToUpdate.indexOf(QUEUE_TO_DNA) >
          keysToUpdate.indexOf(KIOSK_AND_SELF_CHECK_IN_LATE)
        ) {
          throw new Error(
            'QUEUE_TO_DNA key must be before KIOSK_AND_SELF_CHECK_IN_LATE',
          );
        }
        return {
          ...updatedSite,
          attributes: updateSiteAttributes(
            updatedSite.attributes,
            formValues.attributes,
            keysToUpdate,
            (key, siteValue, formValue, updatedSiteAttributes) => {
              switch (key) {
                case KIOSK_AND_SELF_CHECK_IN_LATE:
                  // Just copy same settings as for QUEUE_TO_DNA
                  const queueToDna = updatedSiteAttributes.find(
                    (atr) => atr.key === QUEUE_TO_DNA,
                  );
                  return queueToDna
                    ? {
                        ...queueToDna,
                        key: KIOSK_AND_SELF_CHECK_IN_LATE,
                      }
                    : siteValue;
                default:
                  return formValue || siteValue;
              }
            },
          ),
        };
      },
    };
  };
