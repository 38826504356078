import React from 'react';
import {
  getHookFormTyped,
  hookFromTrimBeforeValidation,
} from '../../../utils/helpers';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from 'react-hook-form';
import { CommonInput } from '../../../components/common/Forms/Input';
import DatePickerInput from '../../../components/common/Forms/DatePickerInput';
import { FormRow } from '../../../components/common/Forms/Forms.styled';
import { DATE_FNS_TIME, isValidDateString } from '../../../utils/dateUtil';

export type AppointmentSubformType = {
  appointment: {
    apptDate?: string;
    apptTime?: string;
  };
};

type IFormAppointmentsProps<T extends AppointmentSubformType> = {
  register: UseFormRegister<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
};

export const OverflowTimeSelect = <T extends AppointmentSubformType>({
  ...rest
}: IFormAppointmentsProps<T>) => {
  const { register, errors, control } = getHookFormTyped<
    AppointmentSubformType,
    T
  >(rest);

  const { t } = useCommonTranslation();
  return (
    <FormRow>
      <Controller
        control={control}
        name="appointment.apptDate"
        rules={{
          ...hookFromTrimBeforeValidation,
          required: t<string>('must-not-empty'),
        }}
        render={({ field }) => {
          return (
            <DatePickerInput
              name="appointment.apptDate"
              label={t('appointment-date')}
              placeholder={t('appointment-date')}
              onChange={(value) => field.onChange(value)}
              minDate={new Date()}
              hasError={Boolean(errors.appointment?.apptDate)}
              helperText={errors.appointment?.apptDate?.message}
              selected={field.value}
              required
            />
          );
        }}
      />
      <CommonInput
        {...register('appointment.apptTime', {
          validate: (value?: string) => {
            if (!value) return t('must-not-empty') as string;
            if (!isValidDateString(value, DATE_FNS_TIME))
              return t('invalid-time-format') as string;
          },
        })}
        placeholder={t('appointment-time')}
        label={t('appointment-time')}
        hasError={Boolean(errors?.appointment?.apptTime)}
        helperText={errors?.appointment?.apptTime?.message}
      />
    </FormRow>
  );
};
