import { CommonButton as Button } from 'components/common/Forms/Button';
import { CubicleData } from 'types/cubicles';

import { CardLoader } from '../../../components/common/Loading';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import {
  CubicleNextText,
  CubicleNextTextMain,
  CubicleNextWrapper,
} from './CubicleNext.styled';
import { useQueuedAppointments } from './CubicleQueueHooks';

interface Props {
  cubicle: CubicleData;
}

export const CubicleNext = ({ cubicle }: Props) => {
  const { t } = useCommonTranslation();
  const { isLoading, appointments, refetch, callAppointment } =
    useQueuedAppointments({ cubicle });

  const apptToCall = appointments?.[0];
  const queueLength = appointments?.length || 0;

  return isLoading ? (
    <CardLoader />
  ) : (
    <CubicleNextWrapper>
      {apptToCall ? (
        <div>
          <CubicleNextText>{t('entered-cubicle-next')}: </CubicleNextText>
          <CubicleNextTextMain>{apptToCall.user_full_name}</CubicleNextTextMain>
        </div>
      ) : (
        <CubicleNextText>
          {t('entered-cubicle-next-empty-queue')}
        </CubicleNextText>
      )}
      <div>
        <Button
          variant="primary"
          size="xxLarge"
          disabled={!apptToCall}
          type="button"
          onClick={() => apptToCall && callAppointment(apptToCall.id)}
        >
          {t('call')}
        </Button>
      </div>
      <div>
        <CubicleNextText>
          {t('entered-cubicle-next-queue-size')}:{' '}
        </CubicleNextText>
        <CubicleNextTextMain>{queueLength}</CubicleNextTextMain>
      </div>
      <div>
        <Button variant="secondary" type="button" onClick={() => refetch()}>
          {t('refresh')}
        </Button>
      </div>
    </CubicleNextWrapper>
  );
};
