import { CommonButton } from '../../../components/common/Forms/Button';
import { FormActionsStyle } from '../../../components/common/Forms/Forms.styled';
import { CardLoader } from '../../../components/common/Loading';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { FormWrapper } from './AddHospitalForm.styled';
import { useAddHospitalForm } from './AddHospitalFormHooks';
import { AddHospitalStepSearch } from './AddHospitalStepSearch';
import { AddHospitalStepUpdateMembers } from './AddHospitalStepUpdateMembers';

export const AddHospitalForm = () => {
  const { t } = useCommonTranslation();

  const {
    isLoading,
    state,
    viewState,
    control,
    register,
    formState,
    handleSubmit,
    handleStepSubmit,
    handleSiteSelect,
    updateMembersData,
    goBack,
  } = useAddHospitalForm();

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit(handleStepSubmit)} autoComplete={'false'}>
        {isLoading ? (
          <CardLoader />
        ) : (
          <>
            {viewState === 'search' && (
              <AddHospitalStepSearch
                onSiteSelected={(site) => {
                  handleSiteSelect(site);
                }}
              />
            )}
            {viewState === 'updateMembers' && !updateMembersData.isLoading && (
              <AddHospitalStepUpdateMembers
                control={control}
                errors={formState.errors}
                register={register}
                {...updateMembersData}
              />
            )}
          </>
        )}
        <FormActionsStyle>
          {viewState === 'updateMembers' && (
            <CommonButton
              variant="primary"
              type="submit"
              disabled={state !== 'fillingInMembersData'}
            >
              {t('save')}
            </CommonButton>
          )}
          <CommonButton type={'button'} variant="secondary" onClick={goBack}>
            {t('back')}
          </CommonButton>
        </FormActionsStyle>
      </form>
    </FormWrapper>
  );
};
