import { Card } from 'components/common';
import { Col, Grid } from 'components/common/Grid';
import { CardLoader } from 'components/common/Loading';
import { PageHelmet } from 'components/common/PageHelmet';
import { CubiclesTable } from 'components/views-components/staff/settings/cubicles/CubiclesTable';
import { useAuth } from 'context/AuthContext';
import { useEnteredCubicle } from 'hooks/staff/useEnteredCubicle';
import { useGetCubicleUpdateData } from 'utils/useGetCubicleUpdateData';

import { CommonButton } from '../../components/common/Forms/Button';
import { TableActions } from '../../components/Table/Table.styled';
import { usePaginatedFilters } from '../../hooks/usePaginatedFilters';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { useCubicles, useMutateUpdateCubicle } from '../../query/cubicles';
import { CubicleFilters } from '../../types/cubicles';
import { StaffLayout } from './StaffLayout';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { STAFF_ENTERED_CUBICLE_URL } from '../../configs/RoutesConfig';

export default function StaffCubicles() {
  const { t } = useCommonTranslation();
  const { replace } = useHistory();
  const { sangixUser } = useAuth();
  const { filters, setFilters } = usePaginatedFilters<CubicleFilters>();
  const {
    isFetching: isCubiclesFetching,
    isRefetching: isCubiclesRefetching,
    data: cubiclesData,
    refetch: refetchCubicles,
  } = useCubicles(filters);
  const { mutateAsync: updateCubicle, isLoading: isCubicleUpdating } =
    useMutateUpdateCubicle();
  const getCubicleUpdateData = useGetCubicleUpdateData();
  const { enteredCubicle, isFetching: isEnteredCubicleFetching } =
    useEnteredCubicle();
  const isLoading =
    (isCubiclesFetching && !isCubiclesRefetching) ||
    isCubicleUpdating ||
    isEnteredCubicleFetching;

  const isCubicleOccupied = (value?: string) => {
    const selectedCubicle = cubiclesData?.data.data?.find(
      (cubicle) => cubicle.id === value,
    );
    return !!selectedCubicle?.user_member_id;
  };

  const openFormHandler = async (value?: string) => {
    const selectedCubicle = cubiclesData?.data.data?.find(
      (cubicle) => cubicle.id === value,
    );
    await updateCubicle({
      ...getCubicleUpdateData(selectedCubicle),
      user_member_id: sangixUser?.main_member_id,
    });
  };

  useEffect(() => {
    if (!isEnteredCubicleFetching && enteredCubicle) {
      replace(STAFF_ENTERED_CUBICLE_URL);
    }
  }, [isEnteredCubicleFetching, enteredCubicle, replace]);

  return (
    <StaffLayout>
      <PageHelmet title={t('cubicle-list')} />
      <Grid>
        <Col md={8}>
          <Card>
            {isLoading ? (
              <CardLoader />
            ) : (
              <>
                <CubiclesTable
                  isListing={true}
                  cubiclesData={cubiclesData?.data}
                  openFormHandler={openFormHandler}
                  isCubicleOccupied={isCubicleOccupied}
                  isLoading={isLoading}
                  isFetching={isCubiclesFetching}
                  filters={filters}
                  setFilters={setFilters}
                />
                <TableActions belowForm={true}>
                  <CommonButton
                    variant="secondary"
                    type="button"
                    onClick={() => refetchCubicles()}
                  >
                    {t('refresh')}
                  </CommonButton>
                </TableActions>
              </>
            )}
          </Card>
        </Col>
      </Grid>
    </StaffLayout>
  );
}
