import { GlobalAttribute } from 'types/common';

import {
  DISABLE_SELF_CHECK_IN,
  FORMS,
  LCD_RUNNING_TEXT,
  LCD_SHORT_NAMES,
  MANDATORY_SOURCE,
  SHOW_APPOINTMENT_REFERENCE_NUMBER,
  SHOW_BIRTH_DATE_IN_CUBICLE_QUEUE,
  SHOW_MIA_BUTTON_IN_APPOINTMENT_PROCESSING,
  SHOW_OVERFLOW_CALENDAR,
  SHOW_PATIENT_SUPPORT_FORM,
  SHOW_WAITING_LIST,
  TUBES,
} from '../../../../configs/siteAndTrustAttributes';
import {
  siteAttributeKeysSubset,
  updateSiteAttributes,
} from '../SitePreferencesHooks';
import { UseCustomSettingsFormHooks } from './CustomSettingsHooks';

const keys = siteAttributeKeysSubset([
  LCD_RUNNING_TEXT,
  FORMS,
  TUBES,
  SHOW_WAITING_LIST,
  LCD_SHORT_NAMES,
  MANDATORY_SOURCE,
  SHOW_OVERFLOW_CALENDAR,
  SHOW_MIA_BUTTON_IN_APPOINTMENT_PROCESSING,
  SHOW_BIRTH_DATE_IN_CUBICLE_QUEUE,
  SHOW_APPOINTMENT_REFERENCE_NUMBER,
  SHOW_PATIENT_SUPPORT_FORM,
  DISABLE_SELF_CHECK_IN,
]);

const restAttributes: GlobalAttribute[] = keys.map((key) => {
  switch (key) {
    case SHOW_WAITING_LIST:
      return {
        key,
        value_int: 1,
        value_str: 'true',
      };
    case MANDATORY_SOURCE:
    case SHOW_OVERFLOW_CALENDAR:
      return {
        key,
        value_int: 0,
        value_str: 'false',
      };
    case DISABLE_SELF_CHECK_IN:
      return {
        key,
        value_int: 0,
        value_str: 'false',
      };
    default:
      return {
        key,
      };
  }
});

export const useCustomSettingsTabFormHooks: UseCustomSettingsFormHooks = () => {
  return {
    restAttributes,
    beforeSubmit: (updatedSite, formValues) => {
      return {
        ...updatedSite,
        attributes: updateSiteAttributes(
          updatedSite.attributes,
          formValues.attributes,
          keys,
          (_, siteValue, formValue) => {
            return formValue || siteValue;
          },
        ),
      };
    },
  };
};
