import React, { useMemo } from 'react';
import { getNewColumnWidth } from '../../../utils/table';
import { TableHeadStyle } from '../../../styles/Typography';
import { Column } from 'react-table';
import { RowActions } from '../../../components/Table/Table.styled';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { Appointment } from '../../../types/appointments';
import {
  formatDateTimeToMinutesSite,
  getDateTimeOnlyFromISO,
  parseIsoDate,
} from '../../../utils/dateUtil';

export const useAppointmentsColumns = ({
  rowActions,
}: {
  rowActions: (apptId: string) => React.ReactNode;
}) => {
  const { t } = useCommonTranslation();
  return useMemo(() => {
    return [
      {
        Header: t('booking-time'),
        accessor: (a) =>
          formatDateTimeToMinutesSite(
            parseIsoDate(getDateTimeOnlyFromISO(a.appt_time)),
          ),
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(20),
        disableSortBy: true,
      },
      {
        Header: t('status'),
        accessor: 'status',
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(15),
        disableSortBy: true,
      },
      {
        Header: t('name'),
        accessor: 'user_full_name',
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(50),
        disableSortBy: true,
      },
      {
        Header: '',
        disableResizing: true,
        accessor: 'id',
        align: 'right',
        disableFilters: true,
        minWidth: 100,
        cellStyle: {
          padding: 0,
          flex: 1,
        },
        style: {
          flex: 1,
        },
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => {
          return <RowActions>{rowActions(value)}</RowActions>;
        },
      },
    ] as Column<Appointment>[];
  }, [t, rowActions]);
};
