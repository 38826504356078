import boySvg from '../../../images/patient-portal/boy.svg';
import doctorSvg from '../../../images/patient-portal/doctor.svg';
import cloudSvg from '../../../images/patient-portal/cloud.svg';
import {
  KioskBGBox,
  KioskBGCloud,
  KioskBGDoctor,
  KioskBGBoy,
} from './Kiosk.styled';

export const KioskBGImage = () => {
  return (
    <KioskBGBox>
      <KioskBGCloud src={cloudSvg} />
      <KioskBGDoctor src={doctorSvg} />
      <KioskBGBoy src={boySvg} />
    </KioskBGBox>
  );
};
