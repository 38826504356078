import { useMemo } from 'react';
import { useSiteSelectedApptFlags } from './useSiteSelectedApptFlags';
import { SelectOption } from '../../types/common';

export const useApptFlagOptions = (): SelectOption<number>[] => {
  const apptFlags = useSiteSelectedApptFlags();
  return useMemo(() => {
    return apptFlags.map((flag) => {
      return {
        label: flag.label,
        value: flag.id,
      };
    });
  }, [apptFlags]);
};
