import { usePatientBooking } from 'context/patient/modules/booking';
import { useDayAvailableAppointments } from 'hooks/appointments/useDayAvailableAppointments';
import groupBy from 'lodash/groupBy';
import { useMemo } from 'react';
import { Appointment } from 'types/appointments';
import { getHours, parseDateKeepTZ } from 'utils/dateUtil';

type HourToApptsMap = { [date: number]: Appointment[] };
const groupApptsByHour = (appts: Appointment[]): HourToApptsMap => {
  return groupBy(appts, (appt: Appointment) => {
    const apptTime = parseDateKeepTZ(appt.appt_time);
    return getHours(apptTime);
  });
};

export const usePatientBookingAvailableHours = () => {
  const {
    booking: { date, stage },
  } = usePatientBooking();
  return useDayAvailableAppointments(date, { enabled: stage === 'hour' });
};

export const useDayAppts = () => {
  const { data: availableApptsResults, isLoading } =
    usePatientBookingAvailableHours();
  const availableAppts = useMemo(
    () => availableApptsResults?.data.data || [],
    [availableApptsResults],
  );
  const dayAppts: HourToApptsMap = useMemo(() => {
    return groupApptsByHour(availableAppts);
  }, [availableAppts]);
  return { dayAppts, isLoading };
};

export const useHourAppts = () => {
  const {
    booking: { date },
  } = usePatientBooking();
  const { dayAppts, isLoading } = useDayAppts();
  const hour = getHours(date);
  return { hourAppts: dayAppts[hour], isLoading };
};
