import React, { ReactElement, useState } from 'react';
import { ResourceType } from 'types/resource-types';
import { ApptTimeWrapper } from './AppointmentsTimes.styled';
import { useDayAppointmentsSlotsWithAppointments } from './staffAppointmentsHooks';
import { TimeSlot } from './TimeSlot';
import { AppointmentCardControl } from './SlotAppointment';
import { LIST_MAX_SCROLL_HEIGHT } from '../StaffLayout';
import { positionValues } from 'react-custom-scrollbars-2';
import CustomScrollbar from '../../../components/common/CustomScrollbar';
import { AppointmentForFlow } from '../../../hooks/staff/useAppointmentsFlow';

type Props = {
  resourceTypes: ResourceType[];
  isUpdate: boolean;
  appointments?: AppointmentForFlow[];
} & AppointmentCardControl;

export const AppointmentTimes = ({
  resourceTypes,
  isUpdate,
  appointments,
  ...rest
}: Props): ReactElement => {
  const { slots } = useDayAppointmentsSlotsWithAppointments({
    resourceTypes,
    isUpdate,
    appointments,
  });

  const scrollRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const [, setScrollValues] = useState<positionValues | undefined>();
  return (
    <CustomScrollbar
      style={{ width: 'auto' }}
      ref={scrollRef}
      setScrollValues={setScrollValues}
      autoHeight
      autoHeightMax={LIST_MAX_SCROLL_HEIGHT}
    >
      <ApptTimeWrapper>
        {slots.map((slot, hourIndex) => (
          <TimeSlot key={hourIndex} {...rest} {...slot} />
        ))}
      </ApptTimeWrapper>
    </CustomScrollbar>
  );
};
