import { DateRange } from '../../../../types/calendar';
import { FilteredResourceTypes } from '../../../../hooks/resourceTypes/useResourceTypesWithCubiclesAssigned';
import { useEffect, useMemo, useState } from 'react';
import { ResourceType } from '../../../../types/resource-types';
import { useAppointments } from '../../../../query/appointments';
import {
  addDays,
  formatDate,
  getDateTimeOnlyFromISO,
  isInFuture,
  MILLIS_IN_MINUTE,
  parseIsoDate,
} from '../../../../utils/dateUtil';
import { getPrimaryResourceType } from '../../../../utils/resourceTypes';
import { groupBy } from 'lodash';

export const useStaffCalendar = ({
  dateRange,
  filteredResourceTypes,
}: { dateRange: DateRange } & FilteredResourceTypes) => {
  const [selectedResourceType, setSelectedResourceType] =
    useState<ResourceType>();
  useEffect(() => {
    const primary = getPrimaryResourceType(filteredResourceTypes);
    if (primary) setSelectedResourceType(primary);
  }, [filteredResourceTypes, setSelectedResourceType]);

  const {
    isLoading: isAppFetching,
    data: appointmentsData,
    dataUpdatedAt,
    refetch,
  } = useAppointments(
    {
      resourceTypeID: selectedResourceType?.id,
      fromDate: formatDate(dateRange.fromDate),
      // We need to add day, as BE looks like cuts of the day before
      toDate: formatDate(addDays(dateRange.toDate, 1)),
      loadUsers: false,
    },
    {
      enabled: !!selectedResourceType,
      refetchInterval: 2 * MILLIS_IN_MINUTE,
    },
  );

  const futureAppointmentsSlots = useMemo(() => {
    const withParsed = appointmentsData?.data.data
      ?.filter((data) => isInFuture(parseIsoDate(data.appt_time)))
      ?.map((data) => {
        return {
          appointmentDate: getDateTimeOnlyFromISO(data.appt_time),
          ...data,
        };
      });
    return groupBy(withParsed, 'appointmentDate');
    /**
     * Appointment response is shallow compared, that's why we
     * need the dataUpdatedAt also to verify if cached data items
     * in appointments response have changed
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentsData, dataUpdatedAt]);

  return {
    isFetching: isAppFetching,
    setSelectedResourceType,
    selectedResourceType,
    futureAppointmentsSlots,
    refetch,
  };
};
