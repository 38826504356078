import { useCallback } from 'react';

import { SIGNUP_SUCCESS } from '../../../configs/constants';
import { useAuth } from '../../../context/AuthContext';
import { register as userRegister } from '../../../services/userProfile';
import { UserRegisterRequest } from '../../../types/users';
import { errorToast } from '../../../utils/toast';
import { UserInfo } from 'firebase/auth';

export type FirebaseInfo = {
  providerData: (UserInfo | null)[];
  getIdToken: () => Promise<string>;
} | null;

export type RegisterToSangixBE = (
  user: UserRegisterRequest,
  firebaseUser: FirebaseInfo,
) => Promise<undefined | unknown>; //unknown is the error type

export const useUserRegisterToSangixBE = () => {
  const { updateAuthUser } = useAuth();

  const registerToSangixBe: RegisterToSangixBE = useCallback(
    async (user, firebaseUser) => {
      try {
        const firebaseProviderIds = firebaseUser?.providerData
          ?.map((provider: any) => provider?.providerId)
          .sort((a: string, b: string) => a.localeCompare(b))
          .join();

        const token = await firebaseUser!!.getIdToken();
        const { data: sangixUser } = await userRegister(token, {
          ...user,
          provider_id: firebaseProviderIds,
        });
        updateAuthUser({
          sangixUser,
          need_complete_signup: false,
          replacePath: SIGNUP_SUCCESS,
        });
        return undefined;
      } catch (error) {
        errorToast(error, { messageSize: 'body1' });
        return error;
      }
    },
    [updateAuthUser],
  );
  return {
    registerToSangixBe,
  };
};
