import { UNSAFE_FILTER_WITH_NO_PAGINATION } from '../../../../hooks/usePaginatedFilters';
import { useStaffPatientColumns } from '../../../../hooks/user/usePatientColumns';
import { usePatientsList } from '../../../../hooks/user/usePatientsCrud';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useQuerySites } from '../../../../query/sites';
import { useUserMergeMutation } from '../../../../query/users';
import { successToast } from '../../../../utils/toast';
import { useMergeEntity } from '../../../../hooks/util/useMergeEntity';
import { useCallback } from 'react';

export const useStaffMerge = () => {
  const { t } = useCommonTranslation();
  const {
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
    data: patients,
    setFilters,
    filters,
  } = usePatientsList({});

  const {
    selectionState,
    toggleSelectionState,
    clearSelection,
    selectMergeItem,
    setSource,
    source,
    target,
    selectedIds,
  } = useMergeEntity({
    entities: patients?.data.data,
  });

  const { data: allSitesData } = useQuerySites({
    ...UNSAFE_FILTER_WITH_NO_PAGINATION,
  });
  const { columns } = useStaffPatientColumns({
    onSelectUserClicked: selectMergeItem,
    filters,
    setFilters,
    allSites: allSitesData?.data,
  });

  const { isLoading: isUsersMergeLoading, mutate: mergeUsersMutation } =
    useUserMergeMutation({
      onSuccess: () => {
        setSource(undefined);
        successToast(t('merge-users-success'));
      },
    });

  const mergeUsers = useCallback(() => {
    if (source && target)
      mergeUsersMutation({
        sourceUserId: source.id,
        targetUserId: target.id,
      });
  }, [source, target, mergeUsersMutation]);

  return {
    filters,
    selectionState,
    columns,
    clearSelection,
    setFilters,
    isUsersLoading,
    isUsersFetching,
    target,
    source,
    toggleSelectionState,
    patients,
    mergeUsers,
    isUsersMergeLoading,
    selectedIds,
  };
};
