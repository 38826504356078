import { PageHelmet } from 'components/common/PageHelmet';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import { useSite } from '../../../../context/SiteContext';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useMutateUpdateSite } from '../../../../query/sites';
import { getSiteById } from '../../../../services/sites';
import { SiteData } from '../../../../types/sites';
import { errorToast, successToast } from '../../../../utils/toast';
import { SitePreferences } from '../../../common/site/SitePreferences';
import { sitePreferencesTabsSubset } from '../../../common/site/SitePreferencesHooks';
import { StaffLayout } from '../../StaffLayout';
import { ICardTabs } from '../../../../types/common';

export const staffSettingsAllowedTabs = sitePreferencesTabsSubset([
  'general',
  'settings-check-in-tab',
  'custom-settings',
  'links',
  'guidelines',
]);

type TabKey = typeof staffSettingsAllowedTabs[number];

export const StaffSettings = () => {
  const { tab: selectedTabKey } = useParams<{ tab: TabKey }>();
  const { t } = useCommonTranslation();
  const { activeSite, setActiveSite } = useSite();
  const { isLoading: isUpdateSiteLoading, mutateAsync: updateSite } =
    useMutateUpdateSite();
  const submitHandler: (data: SiteData) => Promise<void> = useCallback(
    async (data) => {
      try {
        await updateSite(data);
        await setActiveSite((await getSiteById(data.id)).data);
        successToast(t('department-configuration-updated'));
      } catch (err) {
        errorToast(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const tabsConfig: ICardTabs[] = useMemo(
    () =>
      staffSettingsAllowedTabs.map((key) => ({
        label: t(key),
        to: `/staff/settings/preferences/${key}`,
      })),
    [t],
  );

  return (
    <StaffLayout>
      <PageHelmet title={t('preferences')} />
      <SitePreferences
        selectedTabKey={selectedTabKey}
        tabsConfig={tabsConfig}
        site={activeSite}
        submitHandler={submitHandler}
        isFetching={isUpdateSiteLoading}
        type={'staff'}
      />
    </StaffLayout>
  );
};
