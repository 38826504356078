import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { Appointment } from '../../../types/appointments';
import React from 'react';
import { useUserDisabilities } from '../../../hooks/user/useUserDisabilities';
import { useParseApptFlags } from '../../../hooks/appointments/useParseApptFlags';

type IPatientInfoProps = Pick<Appointment, 'appt_flags' | 'user_disabilities'>;

export const PatientInfoRow = ({
  appt_flags,
  user_disabilities,
}: IPatientInfoProps) => {
  const { t } = useCommonTranslation();
  const userDisabilities = useUserDisabilities(user_disabilities);
  const apptFlags = useParseApptFlags(appt_flags);

  const infoText = [];
  infoText.push(...apptFlags.map((flag) => flag.label));
  userDisabilities.forEach((disability) => {
    switch (disability) {
      case 'Movement':
        infoText.push(t('disability-movement'));
        break;
      case 'Hearing':
        infoText.push(t('disability-hearing'));
        break;
      case 'Sight':
        infoText.push(t('disability-sight'));
        break;
    }
  });
  return <>{infoText.join(', ').toUpperCase()}</>;
};
