import { uniq } from 'lodash';
import { useEffect } from 'react';
import { usePrevious } from 'react-use';

import { useGPSurgeriesOptions } from '../../../hooks/referrals/useGPSurgeriesOptions';
import { useTrustsByIds } from '../../../query/trusts';
import { UserProfileResponse } from '../../../types/authentication';
import { SiteData } from '../../../types/sites';
import { gpSurgeryRequiredTrusts } from '../../../utils/trustUtil';
import { validateMembersTrustsSpecificData } from './AddHospitalStepUpdateMembersUtils';

export const useAddHospitalStepUpdateMembersData = ({
  user,
  newSite,
  assignedTrustIds,
  onLoadingFinished,
  onMembersUpdateNotNeeded,
}: {
  user: UserProfileResponse | undefined;
  // site to be added to the user
  newSite: SiteData | undefined;
  // list of user assigned trusts ids without new site trust id
  assignedTrustIds: string[] | undefined;
  onLoadingFinished: () => void;
  // Fired after members are loaded and their data are complete
  onMembersUpdateNotNeeded: () => void;
}) => {
  const trustIds =
    assignedTrustIds && newSite
      ? uniq([...assignedTrustIds, newSite.trust_id])
      : undefined;
  // load all trusts details to get its rules
  const { data: trusts, isLoading: isTrustsLoading } = useTrustsByIds(trustIds);

  const { gpSurgeriesOptions, isLoading: isGpOptionsLoading } =
    useGPSurgeriesOptions({
      gpFilters: {
        trustIDs: trusts?.map(({ id }) => id),
      },
      options: {
        enabled: Boolean(trusts && gpSurgeryRequiredTrusts(trusts)),
      },
    });

  const isLoading =
    !user ||
    !newSite ||
    !assignedTrustIds ||
    isTrustsLoading ||
    !trusts ||
    isGpOptionsLoading;
  const prevIsLoading = usePrevious(isLoading);
  useEffect(() => {
    if (prevIsLoading !== false && !isLoading) {
      if (
        validateMembersTrustsSpecificData(
          [user, ...(user.other_members || [])],
          trusts,
        )
      ) {
        onMembersUpdateNotNeeded();
      } else {
        onLoadingFinished();
      }
    }
  }, [
    prevIsLoading,
    isLoading,
    onLoadingFinished,
    onMembersUpdateNotNeeded,
    user,
    trusts,
  ]);

  return isLoading
    ? {
        isLoading,
      }
    : {
        isLoading,
        trusts,
        gpSurgeriesOptions,
        members: [user, ...(user.other_members || [])],
        newSite,
      };
};
