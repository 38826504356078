import { AxiosError } from 'axios';
import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { Card } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import { Col, Grid } from 'components/common/Grid';
import { PageHelmet } from 'components/common/PageHelmet';
import { TableActions } from 'components/Table/Table.styled';
import { TrustsTable } from 'components/views-components/admin/trusts/TrustsTable';
import { AdminSitesTabsTree } from 'configs/RoutesConfig';
import { useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { TrustData, TrustFormValues, TrustsFilters } from 'types/trusts';
import { errorToast } from 'utils/toast';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { TrustCard } from '../../components/views-components/admin/trusts/TrustCard';
import { AdminLayout } from './AdminLayout';
import {
  useMutateCreateTrust,
  useMutateDeleteTrust,
  useMutateUpdateTrust,
  useTrusts,
} from '../../query/trusts';
import { usePaginatedFilters } from '../../hooks/usePaginatedFilters';
import { useTypedConfirmDialog } from '../../components/Popup/ConfirmDialog/typedConfirmDialogHook';

export const TrustsPage = () => {
  const { t } = useCommonTranslation();

  const { filters, setFilters } = usePaginatedFilters<TrustsFilters>();
  const {
    isLoading: isTrustsLoading,
    isFetching: isTrustsFetching,
    data: trustsData,
  } = useTrusts(filters);

  const { isLoading: isCreateLoading, mutate: addNewTrust } =
    useMutateCreateTrust({
      onSuccess: () => closeFormHandler(),
    });
  const { isLoading: isUpdateLoading, mutate: updateTrust } =
    useMutateUpdateTrust({
      onSuccess: () => closeFormHandler(),
    });
  const { isLoading: isDeleteLoading, mutate: deleteTrust } =
    useMutateDeleteTrust({
      onSuccess: () => closeFormHandler(),
    });

  const isTableLoading =
    isDeleteLoading || isTrustsLoading || isCreateLoading || isUpdateLoading;

  const [showForm, setShowForm] = useState(false);
  const [prefilledData, setPrefilledData] = useState<TrustData | undefined>();
  const { confirm } = useTypedConfirmDialog();

  const closeFormHandler = (err?: AxiosError | true) => {
    setShowForm(false);
    setPrefilledData(undefined);
    if (err) {
      errorToast(err);
    }
  };

  const submitSiteHandler: (
    data: TrustFormValues,
    isUpdateSite: boolean,
  ) => void = (data, isUpdateSite) => {
    const structuredData: TrustFormValues | TrustData = {
      ...data,
    };
    Boolean(isUpdateSite) && Boolean(prefilledData)
      ? updateTrust({ ...prefilledData, ...structuredData })
      : addNewTrust(structuredData);
  };

  const openFormHandler = (value?: string) => {
    setShowForm(false);
    setTimeout(() => {
      if (value) {
        const selectedTrust = trustsData?.data.data?.find(
          (trust) => trust.id === value,
        );

        if (selectedTrust) {
          setPrefilledData(selectedTrust);
          setShowForm(true);
        } else {
          closeFormHandler(true);
        }
      } else {
        setPrefilledData(undefined);
        setShowForm(true);
      }
    }, 50);
  };

  const handleDeleteTrust = (value: string) => {
    const trustToDelete = trustsData?.data.data?.find(
      (trust) => trust.id === value,
    );
    if (trustToDelete) {
      confirm({
        onConfirm: () => deleteTrust(value),
        confirmButtonVariant: 'danger',
        title: t('confirm-delete-trust'),
        expectedConfirmText: trustToDelete.name,
        icon: <FaTrashAlt />,
        status: 'delete',
        description: t('typed-confirm-delete-desc', {
          name: trustToDelete.name,
        }),
      });
    }
  };

  return (
    <AdminLayout>
      <PageHelmet title={t('trusts')} />
      <Grid>
        <Col md={8}>
          <Card fillHeight flex>
            <LinkCardTabs tabs={AdminSitesTabsTree(t)} withDivider />
            <TableActions>
              <CommonButton variant="primary" onClick={() => openFormHandler()}>
                {t('add-trust')}
              </CommonButton>
            </TableActions>
            {
              <TrustsTable
                trustsResponse={trustsData?.data}
                openFormHandler={openFormHandler}
                handleDeleteTrust={handleDeleteTrust}
                filters={filters}
                setFilters={setFilters}
                isLoading={isTableLoading}
                isFetching={isTrustsFetching}
              />
            }
          </Card>
        </Col>
        <Col md={4}>
          {showForm && (
            <TrustCard
              closeFormHandler={closeFormHandler}
              submitNewSiteHandler={submitSiteHandler}
              isLoading={isCreateLoading || isUpdateLoading}
              prefilledData={prefilledData}
            />
          )}
        </Col>
      </Grid>
    </AdminLayout>
  );
};
