import {
  Appointment,
  AppointmentsResponseData,
  ExistingAppointmentFilters,
} from '../types/appointments';
import { useQueryClient } from 'react-query';
import {
  ALL_APPOINTMENTS_QUERY_KEY,
  APPOINTMENT_AUDIT_QUERY_KEY,
  EXISTING_APPOINTMENTS_QUERY_KEY,
  USER_APPOINTMENTS_QUERY_KEY,
} from './appointments';
import { QueryKey } from 'react-query/types/core/types';
import { QueryClient } from 'react-query/core';
import { AxiosResponse } from 'axios';

/**
 * This will update in cache using the update function where it finds it,
 * for filtered appts it like with status filter it will invalidate the query
 * as the mutation may have added/removed an item from cache.
 * -- This could be improved to only invalidate correct existing queries - let's say
 * if status changed from 'BOOKED' => 'QUEUED', the existing query with statuses='BOOKED, QUEUED'
 * does not need to be invalidated.
 */
export const useOnSettledUpdateInCacheAndInvalidateWhereNeeded = <
  PARAMS,
>(params: {
  isDesiredAppt: (params: {
    mutationParams: PARAMS;
    appt: Appointment;
  }) => boolean;
  updateFunction: (mutationParams: PARAMS) => Partial<Appointment>;
  skipInvalidateQueries?: QueryKey[];
}) => {
  const client = useQueryClient();
  return {
    updateCacheOrInvalidateExisting: async (
      _: unknown,
      error: unknown,
      mutationParams: PARAMS,
    ) => {
      await invalidateFilteredAppointmentQueries(
        client,
        error,
        params.skipInvalidateQueries,
      );
      if (!error) {
        replaceAppointmentInCacheIfExists({
          ...params,
          client,
          mutationParams,
          queryKey: EXISTING_APPOINTMENTS_QUERY_KEY,
        });
        replaceAppointmentInCacheIfExists({
          ...params,
          client,
          mutationParams,
          queryKey: ALL_APPOINTMENTS_QUERY_KEY,
        });
      }
    },
  };
};

const replaceAppointmentInCacheIfExists = <PARAMS>({
  isDesiredAppt,
  updateFunction,
  client,
  mutationParams,
  queryKey,
}: {
  queryKey: QueryKey;
  isDesiredAppt: (params: {
    mutationParams: PARAMS;
    appt: Appointment;
  }) => boolean;
  updateFunction: (mutationParams: PARAMS) => Partial<Appointment>;
  client: QueryClient;
  mutationParams: PARAMS;
}) => {
  client.setQueriesData<AxiosResponse<AppointmentsResponseData> | undefined>(
    {
      queryKey,
    },
    (oldData) => {
      if (oldData) {
        const appointments = oldData.data.data || [];
        const index = appointments.findIndex((item) => {
          return isDesiredAppt({ appt: item, mutationParams });
        });
        if (index !== undefined && index !== -1) {
          appointments[index] = {
            ...appointments[index],
            ...updateFunction(mutationParams),
          };
        }
      }
      return oldData;
    },
  );
};

/**
 * Invalidate queries that have filter such as status, because appointment
 * mutation may have changed them.
 */
async function invalidateFilteredAppointmentQueries(
  client: QueryClient,
  error: unknown,
  skipInvalidateQueries: QueryKey[] = [],
) {
  if (
    !skipInvalidateQueries.some(
      (queryKey) => queryKey === USER_APPOINTMENTS_QUERY_KEY,
    )
  ) {
    await client.invalidateQueries(USER_APPOINTMENTS_QUERY_KEY);
  }
  if (
    !skipInvalidateQueries.some(
      (queryKey) => queryKey === APPOINTMENT_AUDIT_QUERY_KEY,
    )
  ) {
    await client.invalidateQueries(APPOINTMENT_AUDIT_QUERY_KEY);
  }

  if (!error) {
    /**
     * We only want to invalidate queries that my have an item added or removed.
     */
    await client.invalidateQueries({
      queryKey: EXISTING_APPOINTMENTS_QUERY_KEY,
      predicate: (p) => {
        if ((p.queryKey[2] as ExistingAppointmentFilters)?.statuses) {
          return true;
        }
        return false;
      },
    });
  } else {
    /**
     * on error we want to invalidate all.
     */
    await client.invalidateQueries(EXISTING_APPOINTMENTS_QUERY_KEY);
    await client.invalidateQueries(ALL_APPOINTMENTS_QUERY_KEY);
  }
}
