import {
  MergeItemBox,
  MergeItemBoxProps,
} from '../../../../../components/common/Merge/MergeItemBox';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { FormRow } from '../../../../../components/common/Forms/Forms.styled';
import { InfoLabelText } from '../../../../../components/common/Forms/InfoLabelText';
import { ClinicalCommissioningGroup } from '../../../../../types/ccg';
import { CardSubtitle } from '../../../../../components/common';

export const MergeICBBox = ({
  isSelecting,
  onSelectClicked,
  ccg,
  label,
}: {
  ccg?: ClinicalCommissioningGroup;
  label: string;
} & Pick<MergeItemBoxProps, 'isSelecting' | 'onSelectClicked'>) => {
  const { t } = useCommonTranslation();
  const emptyString = t('empty-field');
  return (
    <MergeItemBox
      onSelectClicked={onSelectClicked}
      isSelecting={isSelecting}
      selectingLabel={t('selecting-ccg')}
      selectLabel={t('select-ccg')}
    >
      <CardSubtitle>{label}</CardSubtitle>
      <FormRow>
        <InfoLabelText label={t('ccg-name')} text={ccg?.name || emptyString} />
      </FormRow>
      <FormRow>
        <InfoLabelText
          label={t('description')}
          text={ccg?.description || emptyString}
        />
      </FormRow>
    </MergeItemBox>
  );
};
