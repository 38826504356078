import { useDefaultTemplatesApptCounts } from '../../../../../hooks/apptTemplate/useDefaultTemplatesApptCounts';
import { ResourceType } from '../../../../../types/resource-types';
import { WeeklyApptCountsCard } from './WeeklyApptCountsCard';

export const DefaultTemplateApptCountsCard = ({
  resourceType,
  showOnlineMustBeShown,
  isLoading,
  selectedDate,
}: {
  resourceType?: ResourceType;
  showOnlineMustBeShown: boolean;
  isLoading: boolean;
  selectedDate: Date;
}) => {
  const { isFetching: isDefaultTemplatesApptCountsFetching, templateCounts } =
    useDefaultTemplatesApptCounts({
      resourceTypeId: resourceType?.id,
    });

  const isFetching = isLoading || isDefaultTemplatesApptCountsFetching;

  return (
    <WeeklyApptCountsCard
      selectedDate={selectedDate}
      disabled={resourceType === undefined}
      showOnlineMustBeShown={showOnlineMustBeShown}
      isLoading={isFetching}
      templateCounts={templateCounts}
    />
  );
};
