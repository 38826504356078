import { AxiosError } from 'axios';
import { useState } from 'react';
import { errorToast } from '../../utils/toast';
import { useStaffUserCreateUpdate } from '../staff/useStaffUserCreateUpdate';
import { UserData, UsersResponseData } from '../../types/users';

export const useStaffCrud = ({
  userListData,
  deleteItem,
}: {
  userListData?: UsersResponseData;
  deleteItem: (userId: string) => any;
}) => {
  const [showForm, setShowForm] = useState(false);
  const [prefilledData, setPrefilledData] = useState<UserData | undefined>();
  const [confirmDeleteItem, setConfirmDeleteItem] = useState<UserData | null>();

  const closeFormHandler = (err?: AxiosError | true) => {
    setShowForm(false);
    setPrefilledData(undefined);
    setConfirmDeleteItem(null);
    if (err) {
      errorToast(err);
    }
  };

  const openFormHandler = (value?: string) => {
    setShowForm(false);
    setTimeout(() => {
      if (value) {
        const selectedUser = userListData?.data?.find(
          (user) => user.id === value,
        );

        if (selectedUser) {
          setPrefilledData(selectedUser);
          setShowForm(true);
        } else {
          closeFormHandler(true);
        }
      } else {
        setPrefilledData(undefined);
        setShowForm(true);
      }
    }, 50);
  };

  const handleDeleteUser = (value: string) => {
    closeFormHandler();
    setConfirmDeleteItem(userListData?.data?.find((user) => user.id === value));
  };

  const handleConfirmDeleteUser = () => {
    deleteItem(confirmDeleteItem?.id as string);
  };

  const { isLoading: isUserActionLoading, staffUserCreateOrUpdate } =
    useStaffUserCreateUpdate({
      onFinished: async () => {
        closeFormHandler();
      },
    });

  const updatedPrefilledData = userListData?.data?.find(
    (user) => user.id === prefilledData?.id,
  );
  return {
    staffUserCreateOrUpdate,
    updatedPrefilledData,
    isLoading: isUserActionLoading,
    handleConfirmDeleteUser,
    handleDeleteUser,
    openFormHandler,
    showForm,
    closeFormHandler,
    confirmDeleteItem,
  };
};
