import { useSite } from 'context/SiteContext';
import { useCallback } from 'react';
import { useAuth } from '../../../context/AuthContext';

export const useGetSiteName = () => {
  const { sangixUser } = useAuth();
  const { allSites } = useSite();
  return useCallback(
    (siteId: string) =>
      sangixUser?.roles.find((role) => role.site_id === siteId)?.site_name ||
      allSites?.find((site) => site.id === siteId)?.name,
    [sangixUser, allSites],
  );
};
