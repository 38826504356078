import { usePreCheckinQuestion } from './usePreCheckinQuestion';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { KioskApptSearchParams } from './useKioskFindAppointment';
import { KioskAppointment } from '../../types/appointments';

type KioskLoadingState =
  | 'searching_initials'
  | 'searching_phone'
  | 'kiosk_update_user_loading'
  | 'kiosk_checkin_loading';

type KioskGeneralErrorState = 'kiosk_general_error';
export type KioskFindAppointmentErrorState =
  | KioskGeneralErrorState
  | 'kiosk_multiple_results_error'
  | 'kiosk_no_appts_found_error';
export type KioskCheckinErrorState =
  | KioskGeneralErrorState
  | 'kiosk_early_error'
  | 'kiosk_late_error';
export type KioskErrorState =
  | KioskCheckinErrorState
  | KioskFindAppointmentErrorState;

export type KioskState =
  | 'pre-checkin-question'
  | 'initials_input'
  | 'phone_input'
  | 'kiosk_appt_select'
  | 'kiosk_update_user' // for updating telephone user name for calling screen
  | 'kiosk_checkin_success'
  | KioskErrorState
  | KioskLoadingState;

export const PRE_CHECKIN_QUESTION: KioskState = 'pre-checkin-question';
export const INITIALS_INPUT: KioskState = 'initials_input';
export const PHONE_INPUT: KioskState = 'phone_input';
export const KIOSK_EARLY_ERROR: KioskCheckinErrorState = 'kiosk_early_error';
export const KIOSK_LATE_ERROR: KioskCheckinErrorState = 'kiosk_late_error';
export const KIOSK_NO_APPTS_FOUND_ERROR: KioskFindAppointmentErrorState =
  'kiosk_no_appts_found_error';
export const KIOSK_GENERAL_ERROR: KioskGeneralErrorState =
  'kiosk_general_error';
export const KIOSK_MULTIPLE_RESULTS_ERROR: KioskFindAppointmentErrorState =
  'kiosk_multiple_results_error';
export const KIOSK_APPT_SELECT: KioskState = 'kiosk_appt_select';
export const KIOSK_UPDATE_USER: KioskState = 'kiosk_update_user';
export const KIOSK_CHECKIN_SUCCESS: KioskState = 'kiosk_checkin_success';

export const SEARCHING_INITIALS: KioskLoadingState = 'searching_initials';
export const SEARCHING_PHONE: KioskLoadingState = 'searching_phone';
export const KIOSK_UPDATE_USER_LOADING: KioskLoadingState =
  'kiosk_update_user_loading';
export const KIOSK_CHECKIN_LOADING: KioskLoadingState = 'kiosk_checkin_loading';
export const KIOSK_LOADING_STATES: KioskState[] = [
  SEARCHING_INITIALS,
  SEARCHING_PHONE,
  KIOSK_UPDATE_USER_LOADING,
  KIOSK_CHECKIN_LOADING,
];

/**
 * Kiosk should reset after 30 seconds of inactivity so the next user coming to kiosk
 * is at the start of the process. This could be for example when previous user does
 * not click exit button on success screen and leaves the kiosk.
 */
const KIOSK_RESET_TIMEOUT = 30_000;

export const useKioskState = () => {
  const { hasPreCheckinQuestion } = usePreCheckinQuestion();

  const initialState = useMemo(
    () => (hasPreCheckinQuestion ? PRE_CHECKIN_QUESTION : INITIALS_INPUT),
    [hasPreCheckinQuestion],
  );

  const [kioskState, setKioskState] = useState<KioskState>(initialState);
  const [kioskApptSearchInput, setKioskApptSearchInput] =
    useState<KioskApptSearchParams>({
      searchString: '',
      type: 'INITIALS',
    });
  const [appt, setAppt] = useState<KioskAppointment>();

  const reset = useCallback(() => {
    setKioskApptSearchInput({
      searchString: '',
      type: 'INITIALS',
    });
    setAppt(undefined);
    setKioskState(initialState);
  }, [initialState, setKioskState]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (kioskState !== initialState) {
      timeoutId = setTimeout(() => {
        reset();
      }, KIOSK_RESET_TIMEOUT);
    }
    return () => timeoutId && clearTimeout(timeoutId);
  }, [setKioskState, kioskState, initialState, reset]);

  return {
    kioskState,
    setKioskState,
    reset,
    kioskApptSearchInput,
    setKioskApptSearchInput,
    appt,
    setAppt,
  };
};
