import { Col } from 'components/common/Grid';

import { Loading } from '../../../../components/common/Loading';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useTrustMiniSite } from '../../../../query/trustMiniSites';
import {
  kioskURL,
  lcdURL,
  loginToBookAtSiteUri,
  trustMiniSiteURL,
} from '../../../../routes/AppRoutes';
import { SiteData } from '../../../../types/sites';
import { SiteLinkCard } from './SiteLinkCard';
import { SiteLinksRow } from './SiteLinks.styled';

type Props = {
  site: SiteData;
};

export const SiteLinks = ({ site }: Props) => {
  const { t } = useCommonTranslation();
  const { data: trustMiniSite, isLoading } = useTrustMiniSite(site.trust_id);
  const hasMiniSite = (trustMiniSite?.categories?.length ?? 0) > 0;

  return (
    <SiteLinksRow>
      {!isLoading && (
        <>
          <Col sm={6}>
            <SiteLinkCard
              site={site}
              path={`${kioskURL}/${site.id}`}
              title={t('kiosk-link')}
              description={t('kiosk-link-description')}
            />
          </Col>
          <Col sm={6}>
            <SiteLinkCard
              site={site}
              path={`${lcdURL}/${site.id}`}
              title={t('calling-screen-link')}
              description={t('calling-screen-link-description')}
            />
          </Col>
          <Col sm={6}>
            <SiteLinkCard
              site={site}
              path={loginToBookAtSiteUri(site.id)}
              title={t('book-at-site-link')}
              description={t('book-at-site-link-description')}
              hideOpenButton
            />
          </Col>
          {hasMiniSite && (
            <Col sm={6}>
              <SiteLinkCard
                site={site}
                path={`${trustMiniSiteURL}/${site.trust_id}`}
                title={t('trust-mini-site-link')}
                description={t('trust-mini-site-link-description')}
                qrCodeDownloadName={`qr-${t('trust-mini-site-link')} ${
                  site.trust_name
                }`}
              />
            </Col>
          )}
        </>
      )}
      {isLoading && <Loading style={{ marginTop: 'var(--s4)' }} />}
    </SiteLinksRow>
  );
};
