import { DateRangeType } from 'types/calendar';
import {
  DATE_FNS_UK_DATE_WITH_FULL_DATE_FORMAT_NO_DASH,
  formatDate,
} from 'utils/dateUtil';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { DayTitle } from './calendar.styled';
import InfoMessage from '../../../common/InfoMessage';
import { FaExclamationCircle } from 'react-icons/fa';

interface Props {
  date: Date;
  dateRangeType: DateRangeType;
  onDateTitleClick: (date: Date) => void;
}

export const CalendarWithNoDays = ({
  date,
  dateRangeType,
  onDateTitleClick,
}: Props) => {
  const { t } = useCommonTranslation();

  if (dateRangeType === 'day') {
    return (
      <DayTitle style={{ zoom: 1.5 }} onClick={() => onDateTitleClick(date)}>
        {t('date-is-not-a-working-day', {
          date: formatDate(
            date,
            DATE_FNS_UK_DATE_WITH_FULL_DATE_FORMAT_NO_DASH,
          ),
        })}
      </DayTitle>
    );
  } else {
    // This shouldn't happen, but just in case
    return (
      <InfoMessage
        type="error"
        icon={<FaExclamationCircle />}
        text={t('no-working-days-configured')}
      />
    );
  }
};
