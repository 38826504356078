import { useCommonTranslation } from '../i18n/useCommonTranslation';
import React, { useMemo } from 'react';
import { Appointment, AppointmentFilters } from '../../types/appointments';
import {
  formatDateTimeToMinutesSite,
  parseDateKeepTZ,
} from '../../utils/dateUtil';
import { useAppointmentStateName } from './useAppointmentStateName';
import { RowActions } from '../../components/Table/Table.styled';
import { CommonButton } from '../../components/common/Forms/Button';
import { BsEyeFill } from 'react-icons/bs';
import { Column } from 'react-table';
import { getFilterControls } from '../../components/Table/TableUtils';
import { getColumn } from '../../utils/table';
import { FiltersWithSet } from '../useFilters';

export const useAppointmentColumns = ({
  appointments,
  onAppointmentInfoClicked,
  setFilters,
  filters,
}: {
  appointments: Appointment[] | undefined;
  onAppointmentInfoClicked: (appointment: Appointment) => any;
} & FiltersWithSet<AppointmentFilters>) => {
  const { t } = useCommonTranslation();
  const stateTranslate = useAppointmentStateName();
  const columns = useMemo(() => {
    return [
      getColumn<Appointment>({
        Header: t('date-and-time'),
        width: 14,
        accessor: (a: Appointment) => {
          return formatDateTimeToMinutesSite(parseDateKeepTZ(a.appt_time));
        },
        disableSortBy: true,
      }),

      getColumn<Appointment>({
        width: 10,
        Header: t('type'),
        accessor: 'resource_type_abbreviation',
        disableSortBy: true,
      }),

      getColumn<Appointment>({
        Header: t('internal'),
        width: 10,
        accessor: (a: Appointment) => {
          return a.staff_only ? '' : t('yes');
        },
        disableSortBy: true,
      }),

      getColumn<Appointment>({
        Header: t('status'),
        width: 10,
        accessor: (a: Appointment) => {
          return stateTranslate(a);
        },
        disableSortBy: true,
      }),

      getColumn<Appointment>({
        Header: t('patient'),
        width: 20,
        accessor: (a: Appointment) => {
          return a.user_full_name;
        },
        disableSortBy: true,
      }),
      {
        ...getFilterControls({ filters, setFilters, t }),
        Cell: ({ value }: { value: string }) => {
          const selectedAppointment = appointments?.find((a) => a.id === value);
          return (
            <RowActions>
              <CommonButton
                iconOnly
                variant={'primary'}
                onClick={() =>
                  selectedAppointment &&
                  onAppointmentInfoClicked(selectedAppointment)
                }
              >
                <BsEyeFill />
              </CommonButton>
            </RowActions>
          );
        },
      } as Column<Appointment>,
    ];
  }, [
    t,
    appointments,
    stateTranslate,
    onAppointmentInfoClicked,
    setFilters,
    filters,
  ]);
  return { columns };
};
