import React, { useState } from 'react';
import { FaQuestion, FaTimes } from 'react-icons/fa';
import { CommonButton } from '../Forms/Button';
import {
  TimePlanningTooltipContent,
  TimePlanningTooltipWrapperStyle,
} from './Tooltips.styled';

interface Props {
  children: React.ReactNode;
  minWidth?: number; // in Pixels
  positionType?: React.CSSProperties['position'];
  position?: {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
  };
  icon?: React.ReactNode;
}

export const TimePlanningTooltipWrapper: React.FC<Props> = ({
  children,
  position,
  positionType,
  minWidth,
  icon,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <TimePlanningTooltipWrapperStyle
      position={position}
      positionType={positionType}
    >
      <CommonButton
        variant="primary"
        type="button"
        iconOnly
        size="auto"
        onClick={() => setOpen((prev) => !prev)}
        style={{ zIndex: 1, position: 'relative' }}
      >
        {!open && <FaQuestion />}
        {open && (icon ? icon : <FaTimes size={20} />)}
      </CommonButton>
      {open && (
        <TimePlanningTooltipContent minWidth={minWidth}>
          {children}
        </TimePlanningTooltipContent>
      )}
    </TimePlanningTooltipWrapperStyle>
  );
};
