import { PopupTitle } from 'components/Popup/ConfirmDialog/ConfirmDialog.styled';
import styled from 'styled-components';

export const SupportFormTitle = styled(PopupTitle)`
  margin-bottom: var(--s1);
`;
export const SupportFormDescription = styled.div`
  color: var(--textsecondary);
  font-size: var(--h4);
  text-transform: uppercase;
  letter-spacing: 0;
  font-weight: bold;
`;

export const SupportFormStyled = styled.form``;
