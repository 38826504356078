import { AxiosError, AxiosResponse } from 'axios';
import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { Card } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import { Col, Grid } from 'components/common/Grid';
import { PageHelmet } from 'components/common/PageHelmet';
import { ConfirmDialog } from 'components/Popup/ConfirmDialog/ConfirmDialog';
import { TableActionNote, TableActions } from 'components/Table/Table.styled';
import { CubiclesForm } from 'components/views-components/staff/settings/cubicles/CubiclesForm';
import { CubiclesTable } from 'components/views-components/staff/settings/cubicles/CubiclesTable';
import { StaffSettingsCubicleTree } from 'configs/RoutesConfig';
import { useSite } from 'context/SiteContext';
import {
  useCubicles,
  useMutateCreateCubicle,
  useMutateDeleteCubicle,
  useMutateUpdateCubicle,
} from 'query/cubicles';
import React, { useEffect, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import {
  CubicleData,
  CubicleFilters,
  CubicleFormValues,
  CubicleResponseData,
} from 'types/cubicles';
import { SiteData } from 'types/sites';
import { errorToast, successToast } from 'utils/toast';

import {
  UNSAFE_FILTER_WITH_NO_PAGINATION,
  usePaginatedFilters,
} from '../../../../hooks/usePaginatedFilters';
import { useSiteResourceTypes } from '../../../../query/resourceTypes';
import { StaffLayout } from '../../StaffLayout';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';

export default function StaffSettingsCubicle() {
  const { t } = useCommonTranslation();
  const { activeSite } = useSite();
  const { filters, setFilters } = usePaginatedFilters<CubicleFilters>();
  const {
    isLoading: isListLoading,
    isFetching: isListFetching,
    data,
  } = useCubicles(filters);
  const { isLoading: isUpdateLoading, mutate: update } = useMutateUpdateCubicle(
    {
      onSuccess: () => {
        successToast(t('cubicle-updated-successfully'));
        closeFormHandler();
      },
    },
  );
  const { isLoading: isCreateLoading, mutate: create } = useMutateCreateCubicle(
    {
      onSuccess: () => {
        successToast(t('cubicle-added-successfully'));
        closeFormHandler();
      },
    },
  );
  const { isLoading: isDeleteLoading, mutate: deleteItem } =
    useMutateDeleteCubicle({
      onSuccess: () => {
        successToast(t('cubicle-removed-successfully'));
        closeFormHandler();
      },
    });
  const isLoading =
    isCreateLoading || isUpdateLoading || isListLoading || isDeleteLoading;

  const { data: resourceTypeData } = useSiteResourceTypes({
    ...UNSAFE_FILTER_WITH_NO_PAGINATION,
  });

  const [showForm, setShowForm] = useState(false);
  const [prefilledData, setPrefilledData] = useState<CubicleData | undefined>();
  const [confirmDeleteItem, setConfirmDeleteItem] =
    useState<CubicleData | null>();

  const submitHandler = async (
    {
      name,
      description,
      cubicle_resource_type,
      id,
      user_member_id,
    }: CubicleFormValues,
    isUpdateCubicle: boolean,
  ) => {
    const structuredData: CubicleFormValues = {
      id,
      name,
      description,
      user_member_id: user_member_id,
      site_id: activeSite.id,
      cubicle_resource_type: cubicle_resource_type,
    };
    Boolean(isUpdateCubicle) && Boolean(prefilledData)
      ? update({ ...structuredData })
      : create(structuredData);
  };

  const closeFormHandler = (err?: AxiosError | true) => {
    setShowForm(false);
    setPrefilledData(undefined);
    setConfirmDeleteItem(null);
    if (err) {
      errorToast(err);
    }
  };

  const openFormHandler = (value?: string) => {
    setShowForm(false);
    setTimeout(() => {
      if (value) {
        const selectedCubicle = data?.data.data?.find(
          (cubicle) => cubicle.id === value,
        );

        if (selectedCubicle) {
          setPrefilledData(selectedCubicle);
          setShowForm(true);
        } else {
          closeFormHandler(true);
        }
      } else {
        setPrefilledData(undefined);
        setShowForm(true);
      }
    }, 50);
  };

  const handleDeleteCubicle = (value: string) => {
    closeFormHandler();
    setConfirmDeleteItem(
      data?.data.data?.find((cubicle) => cubicle.id === value),
    );
  };

  const handleConfirmDeleteCubicle = () => {
    deleteItem(confirmDeleteItem?.id as string);
  };

  useEffect(() => {
    closeFormHandler();
  }, [activeSite.id]);

  return (
    <StaffLayout>
      <PageHelmet title={t('cubicle')} />
      <Grid>
        <Col md={8}>
          <Card flex>
            <LinkCardTabs tabs={StaffSettingsCubicleTree(t)} withDivider />
            <TableActions>
              <CommonButton
                variant="primary"
                onClick={() => openFormHandler()}
                disabled={
                  Boolean(data?.data.data) &&
                  Boolean(activeSite?.cubicle_limit) &&
                  ((data as AxiosResponse<CubicleResponseData>).data.data
                    ?.length ?? 0) >= (activeSite as SiteData).cubicle_limit
                }
              >
                {t('add-cubicle')}
              </CommonButton>
              <TableActionNote>
                {t('licence-covers-cubicles', {
                  count: activeSite?.cubicle_limit || 0,
                })}
              </TableActionNote>
            </TableActions>
            <CubiclesTable
              cubiclesData={data?.data}
              openFormHandler={openFormHandler}
              handleDeleteCubicle={handleDeleteCubicle}
              isLoading={isListLoading}
              isFetching={isListFetching}
              filters={filters}
              setFilters={setFilters}
            />
          </Card>
        </Col>
        <Col md={4}>
          {showForm && (
            <CubiclesForm
              closeFormHandler={closeFormHandler}
              submitHandler={submitHandler}
              isLoading={isLoading}
              prefilledData={prefilledData}
              resourceTypeData={resourceTypeData?.data}
              handleDeleteCubicle={handleDeleteCubicle}
            />
          )}
        </Col>
      </Grid>
      {Boolean(confirmDeleteItem) && (
        <ConfirmDialog
          isLoading={isLoading}
          handleClose={() => closeFormHandler()}
          icon={<FaTrashAlt />}
          title={t('confirm-delete-cubicle')}
          status="delete"
          actions={[
            <CommonButton
              variant="secondary"
              onClick={() => closeFormHandler()}
              key={confirmDeleteItem?.id + '-cancel'}
            >
              {t('no')}
            </CommonButton>,
            <CommonButton
              variant="danger"
              onClick={handleConfirmDeleteCubicle}
              key={confirmDeleteItem?.id + '-delete'}
            >
              {t('yes')}
            </CommonButton>,
          ]}
        />
      )}
    </StaffLayout>
  );
}
