import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { AdminLayout } from '../AdminLayout';
import { Col, Grid } from '../../../components/common/Grid';
import { Card } from '../../../components/common';
import { SitePreferencesHeaderWrapper } from '../../common/site/sitePreferences.styled';
import { LinkCardTabs } from '../../../components/Card/CardTabs/LinkCardTabs';
import {
  ADMIN_STATISTICS_LAST_FIVE_YEARS_URL,
  ADMIN_STATISTICS_OVERVIEW_URL,
} from '../../../routes/AppRoutes';

export const AdminStatisticsLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useCommonTranslation();
  return (
    <AdminLayout>
      <Grid>
        <Col
          style={{
            minWidth: '100%',
          }}
        >
          <Card mb="var(--s4)">
            <SitePreferencesHeaderWrapper>
              <LinkCardTabs
                tabs={[
                  {
                    label: t('admin-statistics-overview'),
                    to: ADMIN_STATISTICS_OVERVIEW_URL,
                  },
                  {
                    label: t('admin-statistics-last-five-years'),
                    to: ADMIN_STATISTICS_LAST_FIVE_YEARS_URL,
                  },
                ]}
                noMargin={true}
              />
            </SitePreferencesHeaderWrapper>
          </Card>
        </Col>
        {children}
      </Grid>
    </AdminLayout>
  );
};
