import { GlobalAttribute } from 'types/common';

import {
  CHECK_IN_EARLY_TEXT,
  CHECK_IN_LATE_TEXT,
  CHECK_IN_SEARCH_EARLY,
  CHECK_IN_SEARCH_LATE,
  CHECK_IN_SUCCESS_TEXT,
  CHECKIN_DUPLICATE_TEXT,
  KIOSK_AND_SELF_CHECK_IN_EARLY,
  KIOSK_AND_SELF_CHECK_IN_LATE,
} from '../../../../configs/siteAndTrustAttributes';
import {
  siteAttributeKeysSubset,
  updateSiteAttributes,
} from '../SitePreferencesHooks';
import { UseCheckInPreferencesFormHooks } from './CheckInPreferencesHooks';

const keys = siteAttributeKeysSubset([
  CHECK_IN_SEARCH_EARLY,
  CHECK_IN_SEARCH_LATE,
  KIOSK_AND_SELF_CHECK_IN_EARLY,
  CHECK_IN_EARLY_TEXT,
  KIOSK_AND_SELF_CHECK_IN_LATE,
  CHECK_IN_LATE_TEXT,
  CHECK_IN_SUCCESS_TEXT,
  CHECKIN_DUPLICATE_TEXT,
]);

const restAttributes: GlobalAttribute[] = keys.map((key) => ({ key }));

export const useKioskAndSelfCheckInFormHooks: UseCheckInPreferencesFormHooks =
  () => {
    return {
      restAttributes,
      beforeSubmit: (updatedSite, formValues) => {
        return {
          ...updatedSite,
          attributes: updateSiteAttributes(
            updatedSite.attributes,
            formValues.attributes,
            keys,
            (_, siteValue, formValue) => {
              return formValue || siteValue;
            },
          ),
        };
      },
    };
  };
