import { AxiosError } from 'axios';
import { CommonButton, CommonLink } from 'components/common/Forms/Button';
import CheckboxInput from 'components/common/Forms/CheckboxInput';
import {
  CheckBoxesWrapper,
  DeleteAccountBtn,
  DialogFormWrapper,
  FormActionsStyle,
  FormBody,
  FormRow,
} from 'components/common/Forms/Forms.styled';
import { CardLoader } from 'components/common/Loading';
import { useAuth } from 'context/AuthContext';
import useAttributesForm from 'hooks/useAttributesForm';
import React, { ReactElement, useMemo, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { patientURL } from 'routes/AppRoutes';
import { getUserProfile, updateUserProfile } from 'services/userProfile';
import { GlobalAttribute } from 'types/common';
import { UserProfileRegisterUpdateFormValues } from 'types/users';
import { toRem } from 'utils/helpers';
import { errorToast } from 'utils/toast';
import { canChangePassword, getUserFormData } from 'utils/user';
import { useSiteStringAttributes } from '../../../../hooks/useAttribute';
import {
  COLOR_PALETTE,
  ENABLE_BACKGROUND_GRAPHICS,
} from '../../../../configs/siteAndTrustAttributes';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';

type Props = {
  setIsFormSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFormDeleteAccount: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ProfileSettingsForm({
  setIsFormSuccess,
  setIsFormDeleteAccount,
}: Props): ReactElement {
  const { t } = useCommonTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { sangixUser, updateAuthUser } = useAuth();
  const restAttributes: GlobalAttribute[] = useMemo(
    () => [
      {
        key: 'language',
        value_str: 'en',
        value_int: 1,
      },
      {
        key: ENABLE_BACKGROUND_GRAPHICS,
        value_str: 'true',
        value_int: 1,
      },
    ],
    [],
  );

  const theForm = useForm<UserProfileRegisterUpdateFormValues>({
    defaultValues: {
      ...sangixUser,
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    attributeRegister,
    withAttributes,
    control,
  } = useAttributesForm<UserProfileRegisterUpdateFormValues>({
    theForm,
    currentAttrs: [],
    restAttributes,
    dep: [sangixUser],
  });
  const watchedValues = useWatch({ control });

  const submitProfileData: SubmitHandler<
    UserProfileRegisterUpdateFormValues
  > = (data) => {
    const { userInfo } = getUserFormData(data);
    setIsLoading(true);
    updateUserProfile({ ...userInfo, email: data.email })
      .then(() => {
        getUserProfile()
          .then(({ data }) => {
            updateAuthUser({
              sangixUser: data,
            });
            setIsFormSuccess(true);
          })
          .catch((err: AxiosError) => {
            errorToast(err);
          });
      })
      .catch((err: AxiosError) => {
        errorToast(err);
      })
      .finally(() => setIsLoading(false));
  };

  const [colorScheme] = useSiteStringAttributes(COLOR_PALETTE);

  return (
    <DialogFormWrapper style={{ paddingTop: 'var(--s2)' }}>
      <FormBody onSubmit={handleSubmit(withAttributes(submitProfileData))}>
        <FormRow>
          <CheckBoxesWrapper
            style={{
              display: 'none', // Hidden, but not removed as of 14.10.2022 as I was asked to keep it, and it is supposed to soon reappear.
            }}
          >
            {(!Boolean(colorScheme) || colorScheme === '--greentercial') && (
              <CheckboxInput
                {...attributeRegister(
                  ENABLE_BACKGROUND_GRAPHICS,
                  (e) => e.target.checked,
                )}
                label={t('enable-background-graphics')}
                key={ENABLE_BACKGROUND_GRAPHICS}
                id={ENABLE_BACKGROUND_GRAPHICS}
                isToggle={true}
                checked={Boolean(watchedValues.background_graphics)}
              />
            )}
          </CheckBoxesWrapper>
          <DeleteAccountBtn
            style={{
              marginLeft: 'auto',
            }}
            type="button"
            onClick={() => setIsFormDeleteAccount(true)}
          >
            {t('delete-my-account')}
          </DeleteAccountBtn>
        </FormRow>

        <FormActionsStyle>
          <CommonButton
            variant="primary"
            type="submit"
            disabled={Boolean(Object.keys(errors).length)}
            style={{ minWidth: `${toRem(160)}rem` }}
          >
            {t('save-changes')}
          </CommonButton>
          {canChangePassword(sangixUser) && (
            <CommonLink
              type="button"
              variant="danger"
              to={`${patientURL}/change-password`}
              style={{ minWidth: `${toRem(160)}rem` }}
            >
              {t('change-password')}
            </CommonLink>
          )}
          <CommonLink
            type="button"
            variant="secondary"
            style={{ minWidth: `${toRem(160)}rem` }}
            to={patientURL}
          >
            {t('back-to-menu')}
          </CommonLink>
        </FormActionsStyle>
      </FormBody>
      {isLoading && <CardLoader fillWrapper={true} />}
    </DialogFormWrapper>
  );
}
