import { CommonLink } from 'components/common/Forms/Button';
import { InlineConfirm } from 'components/Popup/ConfirmDialog/InlineConfirm';
import { useWaitingListURL } from 'hooks/patient/waitingList/useWaitingListURL';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { FaCheck } from 'react-icons/fa';
import { patientURL } from 'routes/AppRoutes';

export const WaitingListConfirmBack = () => {
  const { t } = useCommonTranslation();
  const formURL = useWaitingListURL();

  return (
    <InlineConfirm
      icon={<FaCheck />}
      title={t('waiting-list-confirm-back')}
      status="delete"
      actions={[
        <CommonLink variant="danger" type="button" key="yes" to={patientURL}>
          {t('yes')}
        </CommonLink>,
        <CommonLink variant="secondary" type="button" key="no" to={formURL}>
          {t('no')}
        </CommonLink>,
      ]}
    />
  );
};
