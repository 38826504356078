import { useConfirmDialog } from '../../components/Popup/ConfirmDialog/confirmDialogHook';
import { useMutateCancelAppointment } from '../../query/appointments';
import { useCallback } from 'react';
import { useCommonTranslation } from '../i18n/useCommonTranslation';
import { UseMutationOptions } from 'react-query/types/react/types';

export const useMutateCancelApptWithConfirm = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const { confirm } = useConfirmDialog();
  const { t } = useCommonTranslation();
  const { mutate: cancelAppointment, ...rest } =
    useMutateCancelAppointment(options);
  const cancelWithConfirm = useCallback(
    (appointmentId: string) => {
      confirm({
        onConfirm: () => {
          cancelAppointment(appointmentId);
        },
        title: t('appointment-cancel-confirm-title'),
        cancelButtonText: t('back'),
      });
    },
    [confirm, cancelAppointment, t],
  );
  return {
    cancelWithConfirm,
    ...rest,
  };
};
