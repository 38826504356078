import { CommonButton } from 'components/common/Forms/Button';
import { InlineCalendarStylesWrapper } from 'components/InlineCalendar/InlineCalendar.styled';
import styled from 'styled-components';
import { BulletListItem } from 'styles/components/BulletListItem.styled';
import { boxShadow } from 'styles/partials/boxShadow';
import { listItemMarginY } from 'styles/partials/listItemMargin';
import { mq } from 'styles/sizes';
import { toRemStr } from 'utils/helpers';

export const PatientBookingStageStyled = styled.div`
  ${boxShadow}
  display: flex;
  border-radius: ${toRemStr(100)};
  margin-bottom: var(--s3);
`;
export type PatientBookingStageItemProps = { active: boolean };
export const PatientBookingStageItem = styled.div<PatientBookingStageItemProps>`
  display: flex;
  align-items: center;
  color: var(--textdisabled);
  padding-right: var(--s3);

  &:last-child {
    padding-right: var(--s2);
  }

  ${(props) =>
    props.active &&
    `
    ${PatientBookingStageNumber} {
      color: var(--redaccent);
    }
    ${PatientBookingStageName} {
      color: var(--textsecondary);
    }
  `}
`;
export const PatientBookingStageNumber = styled.div`
  ${boxShadow}
  border-radius: 50%;
  font-size: var(--h3);
  flex-shrink: 0;
  flex-grow: 0;
  width: var(--s4);
  height: var(--s4);
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const PatientBookingStageName = styled.div`
  font-size: var(--bodysm);
  padding-left: var(--s2);
`;

export const PatientBookingStageTitle = styled.div`
  text-transform: uppercase;
  font-size: var(--h3);
  letter-spacing: 0px;
  margin-bottom: var(--s3);
  text-align: center;
`;

export type PatientBookingStageTitle2Props = { doubleGap?: boolean };
export const PatientBookingStageTitle2 = styled(
  PatientBookingStageTitle,
)<PatientBookingStageTitle2Props>`
  font-size: var(--h4);
  font-weight: bold;
  margin-top: var(--s2);
  ${({ doubleGap }) =>
    doubleGap &&
    `
    margin-top: var(--s3);
  `}
`;
export const PatientBookingStageTitle3 = styled(PatientBookingStageTitle2)`
  font-size: var(--body1);
  font-weight: normal;
  margin-top: 0;
  text-transform: none;
  letter-spacing: 0.01px;
  color: var(--textsecondary);
`;

export const PatientBookingPickBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${InlineCalendarStylesWrapper} {
    margin-top: var(--s3);
  }
`;

export const PatientBookingHint = styled.div`
  ${boxShadow}
  background-color: var(--blueprimary);
  border-radius: var(--s3);
  display: flex;
  align-items: center;
  color: var(--white);
  padding: var(--s2);
  margin: var(--s3) 0;
  width: 100%;
`;

export const PatientBookingHintText = styled.div`
  padding-left: var(--s2);
`;

export const PatientBookingFirstApptsStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: var(--s2);
`;
export const PatientBookingFirstApptsItemStyled = styled(BulletListItem)`
  padding-left: var(--s3);
`;

export const PatientBookingSuggestionDatetime = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const PatientBookingSuggestionDate = styled.div`
  font-weight: bold;
  font-size: var(--h4);
  letter-spacing: 0;
`;
export const PatientBookingSuggestionTime = styled.div`
  font-size: var(--bodysm);
  color: var(--textsecondary);
`;

export const PatientBookingButtonBarStyled = styled.div`
  display: flex;
  margin-top: var(--s3);
  gap: var(--s2);
  flex-direction: column;

  ${mq.xsm} {
    gap: var(--s3);
    flex-direction: row;
  }
`;

export const PatientBookingButtonBarRight = styled.div`
  display: flex;
  gap: var(--s2);
  flex-direction: column;

  ${mq.xsm} {
    margin-left: auto;
    gap: var(--s3);
    flex-direction: row;
  }
`;

export type PatientBookingListProps = { doubleGap?: boolean };
export const PatientBookingList = styled.div<PatientBookingListProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: var(--s2);
  ${({ doubleGap }) =>
    doubleGap &&
    `
    margin-top: var(--s3);
  `}
`;

export type PatientBookingHourItemProps = { unavailable: boolean };

export const PatientBookingListItemStyled = styled(
  BulletListItem,
)<PatientBookingHourItemProps>`
  padding: var(--s3);
  ${({ unavailable }) =>
    unavailable &&
    `
    box-shadow: none;
    ${PatientBookingListItemRange} {
      color: var(--textdisabled);
    }
    ${PatientBookingListItemText} {
      color: var(--rednegative);
    }
  `}
`;

export const PatientBookingListItemRange = styled.div`
  font-weight: bold;
  font-size: var(--h4);
  letter-spacing: 0;
`;

export const PatientBookingListItemText = styled.div`
  color: var(--textsecondary);
`;

export const PatientBookingMemberItem = styled.div`
  ${boxShadow}
  ${listItemMarginY('var(--s3)')}
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--s3);
  padding: var(--s3);

  ${CommonButton} {
    margin-left: auto;
  }
`;

export const PatientBookingMemberItemName = styled.div`
  font-size: var(--h4);
  font-weight: bold;
  padding-right: var(--s2);
  // the name can take all width up the the next column (date)
  // which is 86px long and positioned in the center
  width: calc(50% - ${toRemStr(86 / 2)});
`;

export const PatientBookingMemberItemBidth = styled.div`
  color: var(--textsecondary);
`;

export const PatientBookingPickSiteListWrapper = styled.div`
  max-height: 310px;
  overflow: auto;
  margin: calc(var(--s3) * -1);
  padding: var(--s3);
`;

export const PatientBookingSiteItem = styled.div`
  ${boxShadow}
  ${listItemMarginY('var(--s3)')}
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: var(--s3);
  padding: var(--s3);

  ${CommonButton} {
    margin-left: var(--s2);
  }
`;

export const PatientBookingSiteItemName = styled.div`
  font-size: var(--h4);
  font-weight: bold;
  padding-right: var(--s2);
  margin-right: auto;
`;
