import variantGastronomyCommonEN from './gastronomy/en/variantCommon.json';
import variantPhlebotomyCommonEN from './phlebotomy/en/variantCommon.json';

export const variantResources: {
  [variant: string]: {
    [lng: string]: {
      variantCommon: {
        [key: string]: any;
      };
    };
  };
} = {
  gastronomy: {
    en: {
      variantCommon: variantGastronomyCommonEN,
    },
  },
  phlebotomy: {
    en: {
      variantCommon: variantPhlebotomyCommonEN,
    },
  },
};
