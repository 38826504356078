import {
  MergeItemBox,
  MergeItemBoxProps,
} from '../../../../../components/common/Merge/MergeItemBox';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { FormRow } from '../../../../../components/common/Forms/Forms.styled';
import { InfoLabelText } from '../../../../../components/common/Forms/InfoLabelText';
import { GeneralPractice } from '../../../../../types/generalPractices';
import { ClinicalCommissioningGroup } from '../../../../../types/ccg';
import { useMemo } from 'react';
import { CardSubtitle } from '../../../../../components/common';

export const MergeGpSurgeryBox = ({
  isSelecting,
  onSelectClicked,
  gpSurgery,
  ccgData,
  label,
}: {
  gpSurgery?: GeneralPractice;
  ccgData?: ClinicalCommissioningGroup[];
  label: string;
} & Pick<MergeItemBoxProps, 'isSelecting' | 'onSelectClicked'>) => {
  const { t } = useCommonTranslation();
  const emptyString = t('empty-field');
  const ccgName = useMemo(() => {
    const selectedCCG = ccgData?.find((ccg) => ccg.id === gpSurgery?.ccg_id);
    return selectedCCG?.name || emptyString;
  }, [ccgData, gpSurgery?.ccg_id, emptyString]);
  return (
    <MergeItemBox
      onSelectClicked={onSelectClicked}
      isSelecting={isSelecting}
      selectingLabel={t('selecting-gp-surgery')}
      selectLabel={t('select-gp-surgery')}
    >
      <CardSubtitle>{label}</CardSubtitle>
      <FormRow>
        <InfoLabelText
          label={t('gp-surgery-name')}
          text={gpSurgery?.name || emptyString}
        />
      </FormRow>
      <FormRow>
        <InfoLabelText label={t('ccg-name')} text={ccgName} />
      </FormRow>
      <FormRow>
        <InfoLabelText
          label={t('description')}
          text={gpSurgery?.description || emptyString}
        />
      </FormRow>
    </MergeItemBox>
  );
};
