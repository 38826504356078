import {
  Card,
  CardFooter,
  CardHeader,
  FadeContent,
  FadeWrapper,
} from 'components/common';
import CustomScrollbar from 'components/common/CustomScrollbar';
import { CommonLink } from 'components/common/Forms/Button';
import { FormTitle } from 'components/common/Forms/Forms.styled';
import { PageHelmet } from 'components/common/PageHelmet';
import ReleaseNotesContent from 'components/views-components/patient/ReleaseNotesContent';
import { PatientLayout } from 'containers/PatientLayout';
import { useAuth } from 'context/AuthContext';
import { ReactElement } from 'react';
import { FaClipboardList } from 'react-icons/fa';
import { patientURL } from 'routes/AppRoutes';
import { usePatientReleaseNotes } from '../../../query/storage';
import { useUpdateUserProfileMutation } from '../../../query/users';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { formatISO } from '../../../utils/dateUtil';

export default function PatientReleaseNotes(): ReactElement {
  const { updateAuthUser, sangixUser } = useAuth();
  const { t } = useCommonTranslation();

  const { mutate: updateUserProfile } = useUpdateUserProfileMutation({
    onSuccess: (_, newUser) => {
      updateAuthUser({
        sangixUser: {
          ...sangixUser!!,
          release_note: newUser.release_note,
        },
      });
    },
  });
  const { isLoading, data } = usePatientReleaseNotes({
    onSuccess: () => {
      updateUserProfile({
        ...sangixUser!!,
        release_note: formatISO(new Date()),
      });
    },
  });

  return (
    <PatientLayout graphics="patient">
      <PageHelmet title={t('release-notes')} />
      <Card fade>
        <CardHeader>
          <FaClipboardList />
          <FormTitle>{t('release-notes')}</FormTitle>
        </CardHeader>
        <FadeWrapper>
          <CustomScrollbar
            style={{ width: '100%' }}
            autoHeight
            autoHeightMin={'100%'}
            autoHeightMax={'100%'}
          >
            <FadeContent>
              <ReleaseNotesContent isLoading={isLoading} data={data} />
            </FadeContent>
          </CustomScrollbar>
        </FadeWrapper>
        <CardFooter>
          <CommonLink variant="secondary" to={patientURL}>
            {t('back-to-menu')}
          </CommonLink>
        </CardFooter>
      </Card>
    </PatientLayout>
  );
}
