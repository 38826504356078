import { CommonButton as Button } from 'components/common/Forms/Button';
import { RowActions } from 'components/Table/Table.styled';
import React, { ReactElement } from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { Column } from 'react-table';
import {
  CCGFilters,
  CCGResponseData,
  ClinicalCommissioningGroup,
} from 'types/ccg';
import { FetchedDataTable } from '../../../../Table/FetchedDataTable';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { PaginatedFiltersWithSet } from '../../../../../hooks/usePaginatedFilters';
import { getFilterControls } from '../../../../Table/TableUtils';
import { useCCGColumns } from '../../../../../hooks/referrals/useCCGColumns';

type Props = {
  ccgData?: CCGResponseData;
  openFormHandler: (value?: string) => void;
  handleDeleteCCG: (value: string) => void;
  isLoading: boolean;
  isFetching: boolean;
} & PaginatedFiltersWithSet<CCGFilters>;

export const CCGListTable = ({
  ccgData,
  openFormHandler,
  handleDeleteCCG,
  isLoading,
  filters,
  setFilters,
  isFetching,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();
  const columns = useCCGColumns({
    lastColumn: {
      ...getFilterControls({
        filters,
        setFilters,
        t,
      }),
      Cell: ({ value }: { value: string }) => {
        return (
          <RowActions>
            <Button
              size="auto"
              iconOnly
              variant="primary"
              title={t('edit-ccg')}
              onClick={() => openFormHandler(value)}
            >
              <FaPencilAlt />
            </Button>
            <Button
              size="auto"
              iconOnly
              variant="danger"
              title={t('remove-ccg')}
              onClick={() => handleDeleteCCG(value)}
            >
              <FaTrashAlt />
            </Button>
          </RowActions>
        );
      },
    } as Column<ClinicalCommissioningGroup>,
  });

  return (
    <FetchedDataTable
      columns={columns}
      data={ccgData}
      onSortOrPageChange={setFilters}
      isLoading={isLoading}
      filters={filters}
      isFetching={isFetching}
    />
  );
};
