import { FormRow } from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import { ReactElement, useMemo } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { getHookFormTyped } from 'utils/helpers';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { useUserValidationRules } from '../../../hooks/patient/useUserValidationRules';
import { TrustData } from '../../../types/trusts';
import DatePickerInput from '../../../components/common/Forms/DatePickerInput';
import { DateOnlyISOString } from '../../../utils/dateUtil';
import {
  NhsPostcodeDisabilitiesForm,
  PatientNhsPostcodeDisabilitiesData,
} from './PatientNhsPostcodeDisabilitiesData';
import CommonSelect from '../../../components/common/Forms/Select';
import { SelectOption } from '../../../types/common';
import { LABEL_BEFORE } from '../../../styles/fontsStyleUtils';
import { gpSurgeryRequiredTrusts } from '../../../utils/trustUtil';
import { FormDesc } from '../../../components/views-components/un-auth/SignupForm/SignupForm.styled';
import { InputPhoneNumber } from '../../../components/common/Forms/InputPhoneNumber';

type SubFormType = {
  birthdate?: DateOnlyISOString;
  firstname: string;
  lastname: string;
  phone?: string;
} & NhsPostcodeDisabilitiesForm;

export interface PatientFormProps<T extends SubFormType> {
  register: UseFormRegister<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  trusts: TrustData[];
  hideEmail: boolean;
  gpSurgeriesOptions?: SelectOption[];
}

export const PatientProfileOrMemberFormData = <T extends SubFormType>({
  trusts,
  hideEmail,
  gpSurgeriesOptions,
  ...rest
}: PatientFormProps<T>): ReactElement => {
  const { register, control, errors } = getHookFormTyped<SubFormType, T>(rest);
  const { t } = useCommonTranslation();
  const {
    birthdateRules,
    phoneRules,
    surnameRules,
    firstnameRules,
    emailRules,
    gpRules,
  } = useUserValidationRules({
    trusts,
    emailRequired: true,
  });

  const otherGPName = useMemo(
    () =>
      gpSurgeriesOptions?.find(
        (option) => option.value === trusts[0].unknown_gp_surgery_id,
      )?.label,
    [gpSurgeriesOptions, trusts],
  );

  return (
    <>
      <FormRow>
        <Input
          label={t('first-name')}
          placeholder={t('first-name')}
          helperText={errors.firstname?.message}
          hasError={Boolean(errors.firstname)}
          {...register('firstname', firstnameRules)}
          required
        />
        <Input
          label={t('surname')}
          placeholder={t('surname')}
          helperText={errors.lastname?.message}
          hasError={Boolean(errors.lastname)}
          {...register('lastname', surnameRules)}
          required
        />
      </FormRow>
      {!hideEmail && (
        <FormRow>
          <Input
            label={t('email')}
            placeholder={t('email')}
            helperText={errors.email?.message}
            hasError={Boolean(errors.email)}
            {...register('email', emailRules)}
            required
          />
        </FormRow>
      )}
      <FormRow>
        <Controller
          control={control}
          name="birthdate"
          rules={birthdateRules}
          render={({ field }) => {
            return (
              <DatePickerInput
                name="birthdate"
                label={t('date-of-birth')}
                placeholder={t('date-of-birth')}
                onChange={(value) => {
                  field.onChange(value);
                }}
                id={'new_birthdate'}
                maxDate={new Date()}
                hasError={Boolean(errors.birthdate)}
                helperText={errors.birthdate?.message}
                selected={field.value}
                required
              />
            );
          }}
        />
        <Controller
          name="phone"
          control={control}
          rules={phoneRules}
          render={({ field }) => (
            <InputPhoneNumber
              label={t('phone-number')}
              placeholder={t('phone-number')}
              {...field}
              required={!!phoneRules.required}
              helperText={errors.phone?.message}
              hasError={Boolean(errors?.phone)}
            />
          )}
        />
      </FormRow>
      <PatientNhsPostcodeDisabilitiesData
        register={register}
        control={control}
        errors={errors}
        trusts={trusts}
      />
      {gpSurgeryRequiredTrusts(trusts) && (
        <>
          <FormDesc
            style={{
              padding: '0 var(--s1)',
            }}
          >
            {t('general-practice-help', {
              other_gp_label: otherGPName,
            })}
          </FormDesc>
          <FormRow>
            <Controller
              name="gp_surgery_id"
              render={({ field }) => (
                <CommonSelect
                  {...field}
                  labelPosition={LABEL_BEFORE}
                  label={t('general-practice')}
                  placeholder={t('general-practice-placeholder')}
                  disabled={!gpSurgeriesOptions}
                  options={gpSurgeriesOptions}
                  helperText={errors.gp_surgery_id?.message}
                  hasError={Boolean(errors.gp_surgery_id)}
                  onChange={(val) => {
                    field.onChange(val);
                  }}
                  required
                />
              )}
              control={control}
              rules={gpRules}
            />
          </FormRow>
        </>
      )}
    </>
  );
};
