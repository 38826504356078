import { Card } from 'components/common';
import { Col, Grid } from 'components/common/Grid';
import { CardLoader } from 'components/common/Loading';
import { PageHelmet } from 'components/common/PageHelmet';
import { AppointmentCard } from 'components/views-components/staff/appointments/AppointmentCard';
import { ReactNode } from 'react';
import { FaLightbulb } from 'react-icons/fa';
import { StaffLayout } from '../StaffLayout';
import { toRemStr } from '../../../utils/helpers';

type ReportsLayoutProps = {
  isLoading: boolean;
  title: string;
  leftColMD: number;
  rightColMD: number;
  leftCardNode: ReactNode;
  rightCardNode?: ReactNode;
  rightCardTitle?: string;
  rightCardMinHeight?: string;
  onRightCardClose: () => unknown;
};
export const ReportsLayout = ({
  isLoading,
  title,
  leftColMD,
  rightColMD,
  leftCardNode,
  rightCardNode,
  rightCardTitle,
  rightCardMinHeight,
  onRightCardClose,
}: ReportsLayoutProps) => {
  return (
    <StaffLayout fixedHeight>
      <PageHelmet title={title} />
      <Grid style={{ maxHeight: '100%' }}>
        <Col style={{ maxHeight: '100%' }} md={leftColMD}>
          <Card scroll style={{ minHeight: toRemStr(300) }}>
            {leftCardNode}
            {isLoading && <CardLoader fillWrapper={true} />}
          </Card>
        </Col>
        {rightCardNode && (
          <Col style={{ maxHeight: '100%' }} col={rightColMD}>
            <AppointmentCard
              icon={<FaLightbulb />}
              title={rightCardTitle}
              onCloseClicked={onRightCardClose}
              minHeight={rightCardMinHeight}
              scroll
            >
              {rightCardNode}
              {isLoading && <CardLoader fillWrapper={true} />}
            </AppointmentCard>
          </Col>
        )}
      </Grid>
    </StaffLayout>
  );
};
