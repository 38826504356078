import { AxiosResponse } from 'axios';
import { useQueryClient } from 'react-query';
import {
  UseMutationOptions,
  UseQueryOptions,
} from 'react-query/types/react/types';

import { useAuth } from '../context/AuthContext';
import {
  appointmentNotTaken,
  appointmentOverflow,
  appointmentTaken,
  AvailableAppointmentsParams,
  bookAppointment,
  callAppointment,
  cancelAppointment,
  cancelApptByToken,
  checkInPatientByCode,
  CheckInPatientByCodeParams,
  getAllAppointments,
  getAppointmentAudit,
  getAvailableAppointments,
  getAvailableDays,
  getExistingAppointments,
  getUserAppointments,
  getUserCheckIn,
  lockAppointment,
  massCancelAppointment,
  miaAppointment,
  notPreparedAppointment,
  processAppointment,
  queueAppointment,
  unlockAppointment,
  updateAppointment,
} from '../services/appointments';
import {
  Appointment,
  AppointmentAuditFilter,
  AppointmentFiltersNoPagination,
  AppointmentMassCancel,
  AppointmentsResponseData,
  AppointmentUpdate,
  AppointmentUpdateData,
  AvailableDaysData,
  ExistingAppointmentFilters,
  UserAppointmentResponseData,
  UserAppointmentsTypes,
} from '../types/appointments';
import { useOnSettledUpdateInCacheAndInvalidateWhereNeeded } from './appointmentsCacheControl';
import {
  CacheAndStaleTimeShort,
  UseMutationOptionsWithError,
  useMutationWithToastError,
  useQueryWithToastError,
} from './queryUtils';

export const APPOINTMENT_QUERY_KEY = 'appointments';

export const EXISTING_APPOINTMENTS_QUERY_KEY = [
  APPOINTMENT_QUERY_KEY,
  'existing',
];

export const ALL_APPOINTMENTS_QUERY_KEY = [APPOINTMENT_QUERY_KEY, 'all'];

export const APPOINTMENT_AUDIT_QUERY_KEY = [APPOINTMENT_QUERY_KEY, 'audit'];

export const USER_APPOINTMENTS_QUERY_KEY = [APPOINTMENT_QUERY_KEY, 'user'];

export const useExistingAppointments = (
  filters: ExistingAppointmentFilters,
  options?: UseQueryOptions<any, any, AxiosResponse<AppointmentsResponseData>>,
) => {
  return useQueryWithToastError<
    any,
    any,
    AxiosResponse<AppointmentsResponseData>
  >(
    [...EXISTING_APPOINTMENTS_QUERY_KEY, filters],
    () => getExistingAppointments(filters),
    {
      ...CacheAndStaleTimeShort,
      ...options,
    },
  );
};

export const useAppointments = (
  filters: AppointmentFiltersNoPagination,
  options?: UseQueryOptions<any, any, AxiosResponse<AppointmentsResponseData>>,
) => {
  return useQueryWithToastError<
    any,
    any,
    AxiosResponse<AppointmentsResponseData>
  >(
    [...ALL_APPOINTMENTS_QUERY_KEY, filters],
    () =>
      getAllAppointments({
        ...filters,
      }),
    {
      ...CacheAndStaleTimeShort,
      ...options,
    },
  );
};

export const useUserAppointments = <T = Appointment[] | undefined>(
  apptType: UserAppointmentsTypes,
  userId?: string,
  options?: UseQueryOptions<AxiosResponse<UserAppointmentResponseData>, any, T>,
) => {
  return useQueryWithToastError(
    [
      ...USER_APPOINTMENTS_QUERY_KEY,
      {
        userId: userId,
        apptType: apptType,
      },
    ],
    () => getUserAppointments(userId!!, apptType),
    {
      enabled: !!userId,
      ...CacheAndStaleTimeShort,
      select: (data) => {
        const appts: Appointment[] | undefined = (() => {
          if (!data) return undefined;
          if (apptType === 'booked') return data.data.booked;
          if (apptType === 'cancelled') return data.data.cancelled;
          if (apptType === 'past') return data.data.past;
        })();
        return appts as unknown as T;
      },
      ...options,
    },
  );
};

export const useAppointmentAudit = (filter: AppointmentAuditFilter) => {
  return useQueryWithToastError(
    /**
     * Change in appointments would likely change audit, that's why we use this key.
     */
    [...APPOINTMENT_AUDIT_QUERY_KEY, filter],
    () => getAppointmentAudit(filter),
    { select: (data) => data?.data },
  );
};

export const useMutateUpdateAppointment = (
  options?: UseMutationOptions<any, any, AppointmentUpdate>,
) => {
  const { updateCacheOrInvalidateExisting } =
    useOnSettledUpdateInCacheAndInvalidateWhereNeeded<AppointmentUpdate>({
      isDesiredAppt: ({ appt, mutationParams }) =>
        appt.id === mutationParams.id,
      updateFunction: (appointmentUpdate) => appointmentUpdate,
    });
  return useMutationWithToastError<AppointmentUpdate>(
    [APPOINTMENT_QUERY_KEY],
    (appointment) => updateAppointment(appointment),
    {
      onSettled: updateCacheOrInvalidateExisting,
      ...options,
    },
  );
};

export const useMutateAppointmentOverflow = (
  options?: UseMutationOptions<any, any, AppointmentUpdateData>,
) => {
  const queryClient = useQueryClient();
  return useMutationWithToastError<AppointmentUpdateData>(
    [APPOINTMENT_QUERY_KEY],
    (appointment) => appointmentOverflow(appointment),
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries(APPOINTMENT_QUERY_KEY);
        if (options?.onSuccess) options?.onSuccess(...args);
      },
    },
  );
};

export const useMutateBookAppointment = (
  options?: UseMutationOptions<any, any, AppointmentUpdate>,
) => {
  const { updateCacheOrInvalidateExisting } =
    useOnSettledUpdateInCacheAndInvalidateWhereNeeded<AppointmentUpdate>({
      isDesiredAppt: ({ appt, mutationParams }) => {
        return appt.id === mutationParams.id;
      },
      updateFunction: (update) => ({ ...update, status: 'BOOKED' }),
    });
  return useMutationWithToastError<AppointmentUpdate>(
    [APPOINTMENT_QUERY_KEY],
    (appointment) => bookAppointment(appointment),
    {
      onSuccess: (...args) => {
        if (options?.onSuccess) options?.onSuccess(...args);
      },
      onSettled: updateCacheOrInvalidateExisting,
      ...options,
    },
  );
};

export const useMutateQueueAppointment = (
  options?: UseMutationOptions<any, any, string>,
  invalidateAppointments = false,
) => {
  const { updateCacheOrInvalidateExisting } =
    useOnSettledUpdateInCacheAndInvalidateWhereNeeded<string>({
      isDesiredAppt: ({ appt, mutationParams }) => appt.id === mutationParams,
      updateFunction: () => ({
        status: 'QUEUE',
      }),
    });

  return useMutationWithToastError<string>(
    [APPOINTMENT_QUERY_KEY],
    (appointmentId) => queueAppointment(appointmentId),
    {
      onSettled: invalidateAppointments
        ? updateCacheOrInvalidateExisting
        : undefined,
      ...options,
    },
  );
};

export const useMutateCallAppointment = (
  options?: UseMutationOptionsWithError<any, any, string> & {
    successAfterSettled?: (apptId: string) => any;
  },
) => {
  const { sangixUser } = useAuth();
  const { updateCacheOrInvalidateExisting } =
    useOnSettledUpdateInCacheAndInvalidateWhereNeeded<string>({
      isDesiredAppt: ({ appt, mutationParams }) => {
        return appt.id === mutationParams;
      },
      updateFunction: () => ({
        status: 'CALLED',
        staff_id: sangixUser?.main_member_id,
      }),
    });
  return useMutationWithToastError<string>(
    [APPOINTMENT_QUERY_KEY],
    (appointmentId) => callAppointment(appointmentId),
    {
      onSettled: async (data, error, variables, context) => {
        await updateCacheOrInvalidateExisting(data, error, variables);
        options?.successAfterSettled?.(variables);
      },
      ...options,
    },
  );
};

export const useMutateNotPreparedAppointment = <T extends AppointmentUpdate>(
  options?: UseMutationOptions<any, any, T>,
) => {
  const { updateCacheOrInvalidateExisting } =
    useOnSettledUpdateInCacheAndInvalidateWhereNeeded<AppointmentUpdate>({
      isDesiredAppt: ({ appt, mutationParams }) => {
        return appt.id === mutationParams.id;
      },
      updateFunction: (update) => ({ ...update, status: 'NOT_PREPARED' }),
    });
  return useMutationWithToastError<T>(
    [APPOINTMENT_QUERY_KEY],
    (appointment) => notPreparedAppointment(appointment.id, appointment),
    {
      onSettled: updateCacheOrInvalidateExisting,
      ...options,
    },
  );
};

export const useMutateCancelAppointment = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<string>(
    [APPOINTMENT_QUERY_KEY],
    (appointmentId) => cancelAppointment(appointmentId),
    {
      onSettled: () => client.invalidateQueries([APPOINTMENT_QUERY_KEY]),
      ...options,
    },
  );
};

export const useMutateMIAAppointment = (
  options?: UseMutationOptions<any, any, AppointmentUpdate>,
) => {
  const { updateCacheOrInvalidateExisting } =
    useOnSettledUpdateInCacheAndInvalidateWhereNeeded<AppointmentUpdate>({
      isDesiredAppt: ({ appt, mutationParams }) => {
        return appt.id === mutationParams.id;
      },
      updateFunction: (update) => ({ ...update, status: 'MIA' }),
    });
  return useMutationWithToastError<AppointmentUpdate>(
    [APPOINTMENT_QUERY_KEY],
    (appointmentUpdate) =>
      miaAppointment(appointmentUpdate.id, appointmentUpdate),
    {
      onSettled: updateCacheOrInvalidateExisting,
      ...options,
    },
  );
};

export const useMutateProcessAppointment = (
  options?: UseMutationOptionsWithError<any, any, string>,
) => {
  const { updateCacheOrInvalidateExisting } =
    useOnSettledUpdateInCacheAndInvalidateWhereNeeded<string>({
      isDesiredAppt: ({ appt, mutationParams }) => {
        return appt.id === mutationParams;
      },
      updateFunction: () => ({ status: 'PROCESSING' }),
    });
  return useMutationWithToastError<string>(
    [APPOINTMENT_QUERY_KEY],
    (appointmentId) => processAppointment(appointmentId),
    { onSettled: updateCacheOrInvalidateExisting, ...options },
  );
};

export const useMutateLockAppointment = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const { updateCacheOrInvalidateExisting } =
    useOnSettledUpdateInCacheAndInvalidateWhereNeeded<string>({
      isDesiredAppt: ({ appt, mutationParams }) => {
        return appt.id === mutationParams;
      },
      updateFunction: () => ({ status: 'LOCKED' }),
      skipInvalidateQueries: [
        USER_APPOINTMENTS_QUERY_KEY,
        APPOINTMENT_AUDIT_QUERY_KEY,
      ], //locking appointment does not require us to invalidate user appts and audit
    });
  return useMutationWithToastError<string>(
    [APPOINTMENT_QUERY_KEY, 'lock'],
    (appointmentId) => lockAppointment(appointmentId),
    {
      onSettled: updateCacheOrInvalidateExisting,
      ...options,
    },
  );
};

/**
 * Unlocking creates an expired appt
 * @param options
 */
export const useMutateUnLockAppointment = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const client = useQueryClient();
  // Unlocking appt moves it to EXPIRED state and the appt is replaced with a new FREE apt, so we invalidate here.
  return useMutationWithToastError<string>(
    [APPOINTMENT_QUERY_KEY, 'unLock'],
    (appointmentId) => unlockAppointment(appointmentId),
    {
      onSettled: () => client.invalidateQueries([APPOINTMENT_QUERY_KEY]),
      ...options,
    },
  );
};

export const useMutateAppointmentTaken = <T extends AppointmentUpdate>(
  options?: UseMutationOptions<any, any, T>,
) => {
  const { updateCacheOrInvalidateExisting } =
    useOnSettledUpdateInCacheAndInvalidateWhereNeeded<AppointmentUpdate>({
      isDesiredAppt: ({ appt, mutationParams }) => {
        return appt.id === mutationParams.id;
      },
      updateFunction: (update) => ({ ...update, status: 'TAKEN' }),
    });
  return useMutationWithToastError<T>(
    [APPOINTMENT_QUERY_KEY],
    (appointment) => appointmentTaken(appointment.id, appointment),
    {
      onSettled: updateCacheOrInvalidateExisting,
      ...options,
    },
  );
};

export const useMutateAppointmentNotTaken = <T extends AppointmentUpdate>(
  options?: UseMutationOptions<any, any, T>,
) => {
  const { updateCacheOrInvalidateExisting } =
    useOnSettledUpdateInCacheAndInvalidateWhereNeeded<AppointmentUpdate>({
      isDesiredAppt: ({ appt, mutationParams }) => {
        return appt.id === mutationParams.id;
      },
      updateFunction: (update) => ({ ...update, status: 'NOT_TAKEN' }),
    });
  return useMutationWithToastError<T>(
    [APPOINTMENT_QUERY_KEY],
    (appointment) => appointmentNotTaken(appointment.id, appointment),
    {
      onSettled: updateCacheOrInvalidateExisting,
      ...options,
    },
  );
};

export const useApptAvailableDays = (
  params: AvailableAppointmentsParams,
  options?: UseQueryOptions<any, any, AxiosResponse<AvailableDaysData>>,
) => {
  return useQueryWithToastError<any, any, AxiosResponse<AvailableDaysData>>(
    [APPOINTMENT_QUERY_KEY, 'available_days', params],
    () => getAvailableDays(params),
    options,
  );
};

export const useMutateCancelAppointmentByToken = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<string>(
    [APPOINTMENT_QUERY_KEY],
    (token) => cancelApptByToken(token),
    {
      onSettled: () => client.invalidateQueries([APPOINTMENT_QUERY_KEY]),
      ...options,
    },
  );
};

export const availableAppointmentsConfig = (
  params: AvailableAppointmentsParams,
) => {
  return {
    queryKey: [APPOINTMENT_QUERY_KEY, 'available', params],
    queryFn: () => getAvailableAppointments(params),
  };
};

export const useUserCheckIn = (
  options?: UseQueryOptions<any, any, AxiosResponse<AppointmentsResponseData>>,
) => {
  return useQueryWithToastError<
    any,
    any,
    AxiosResponse<AppointmentsResponseData>
  >([APPOINTMENT_QUERY_KEY, 'check-in'], () => getUserCheckIn(), options);
};

export const useMutateCheckInByCode = (
  options?: UseMutationOptions<any, any, CheckInPatientByCodeParams>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<CheckInPatientByCodeParams>(
    [APPOINTMENT_QUERY_KEY],
    (params: CheckInPatientByCodeParams) => checkInPatientByCode(params),
    {
      onSettled: () => client.invalidateQueries([APPOINTMENT_QUERY_KEY]),
      ...options,
    },
  );
};

export const useMutateMassCancelAppointment = (
  options?: UseMutationOptions<any, any, AppointmentMassCancel>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<AppointmentMassCancel>(
    [APPOINTMENT_QUERY_KEY, 'massCancel'],
    (appointmentMassCancel) => massCancelAppointment(appointmentMassCancel),
    {
      onSettled: () => client.invalidateQueries([APPOINTMENT_QUERY_KEY]),
      ...options,
    },
  );
};
