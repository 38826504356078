import { APPT_FLAGS_SELECTED } from '../../configs/siteAndTrustAttributes';
import { useSite } from '../../context/SiteContext';
import { SiteData } from '../../types/sites';
import { useSpecificSiteAttribute } from '../useSpecificSiteAttribute';
import { useAllAvailableApptFlags } from './useAllAvailableApptFlags';

export const useSiteSelectedApptFlags = () => {
  const { activeSite } = useSite();
  return useSpecificSiteSelectedApptFlags(activeSite);
};

export const useSpecificSiteSelectedApptFlags = (site: SiteData) => {
  const allApptFlags = useAllAvailableApptFlags();
  const bitwiseFlagsSelected = useSpecificSiteAttribute(
    site,
    APPT_FLAGS_SELECTED,
  );
  return allApptFlags.filter(
    (flag) =>
      bitwiseFlagsSelected &&
      bitwiseFlagsSelected.value_int &&
      (flag.id & bitwiseFlagsSelected?.value_int) !== 0,
  );
};
