import {
  CacheAndStaleTimeForUnlikelyChanged,
  useMutationWithToastError,
  useQueryWithToastError,
} from './queryUtils';
import {
  addNewSite,
  deleteSite,
  getAllSites,
  getSiteById,
  getSiteByName,
  updateSite,
} from '../services/sites';
import { SiteData, SiteFormValues, SitesFilters } from '../types/sites';
import { useQueryClient } from 'react-query';
import {
  UseMutationOptions,
  UseQueryOptions,
} from 'react-query/types/react/types';
import { AxiosResponse } from 'axios';

const SITES_QUERY_KEY = 'sites';

export const useQuerySites = (filters?: SitesFilters) => {
  return useQueryWithToastError(
    [SITES_QUERY_KEY, filters],
    () => getAllSites(filters),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
      select: (data) => data.data,
    },
  );
};

export const useQuerySitesByName = (namePart?: string) => {
  return useQueryWithToastError(
    [SITES_QUERY_KEY, 'byName', namePart],
    () => getSiteByName(namePart!),
    {
      enabled: !!namePart && namePart.trim().length >= 3,
    },
  );
};

export const useQuerySite = (
  id?: string,
  options?: UseQueryOptions<
    AxiosResponse<SiteData>,
    any,
    AxiosResponse<SiteData>
  >,
) => {
  return useQueryWithToastError(
    [SITES_QUERY_KEY, { id: id }],
    () => getSiteById(id!),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
      ...options,
    },
  );
};

export const useMutateCreateSite = (
  options?: UseMutationOptions<any, any, SiteFormValues>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<SiteFormValues>(
    [SITES_QUERY_KEY],
    (siteUpdate) => addNewSite(siteUpdate),
    {
      onSettled: () => client.invalidateQueries([SITES_QUERY_KEY]),
      ...options,
    },
  );
};

export const useMutateUpdateSite = (
  options?: UseMutationOptions<any, any, SiteFormValues>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<SiteFormValues>(
    [SITES_QUERY_KEY],
    (siteUpdate) => updateSite(siteUpdate),
    {
      onSettled: () => client.invalidateQueries([SITES_QUERY_KEY]),
      ...options,
    },
  );
};

export const useMutateDeleteSite = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<string>(
    [SITES_QUERY_KEY],
    (id) => deleteSite(id),
    {
      onSettled: () => client.invalidateQueries([SITES_QUERY_KEY]),
      ...options,
    },
  );
};
