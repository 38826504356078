import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { Card, CardHeader } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import { FormTitle } from 'components/common/Forms/Forms.styled';
import { PageHelmet } from 'components/common/PageHelmet';
import ProfileSettingsForm from 'components/views-components/patient/profile/ProfileSettingsForm';
import { ProfileTabsTree } from 'configs/RoutesConfig';
import { PatientLayout } from 'containers/PatientLayout';
import { useAuth } from 'context/AuthContext';
import React, { ReactElement, useCallback, useState } from 'react';
import { FaCheck, FaUserCog } from 'react-icons/fa';
import { firebaseRemoveUser } from 'services/firebase';
import { deleteUserProfile } from 'services/userProfile';
import { errorToast, successToast } from 'utils/toast';
import { InlineConfirm } from '../../../../components/Popup/ConfirmDialog/InlineConfirm';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';

export default function ProfileSettingsPage(): ReactElement {
  const { logout } = useAuth();
  const [isFormDeleteAccount, setIsFormDeleteAccount] = useState(false);
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useCommonTranslation();

  const handleDeleteAccount = useCallback(
    (e) => {
      e.preventDefault();
      setIsLoading(true);
      deleteUserProfile()
        .then(() => {
          firebaseRemoveUser()?.then(() => {
            successToast(t('account-removed-successfully'));
            logout();
          });
        })
        .catch((err) => {
          errorToast(err);
        });
    },
    [t, logout],
  );

  return (
    <PatientLayout graphics="patient">
      <PageHelmet title={t('settings')} />
      {!isFormSuccess ? (
        isFormDeleteAccount ? (
          <InlineConfirm
            icon={<FaCheck />}
            isLoading={isLoading}
            title={t('delete-primary-acc-confirm-title')}
            description={t('delete-primary-acc-confirm-desc')}
            status="delete"
            actions={[
              <CommonButton
                variant="danger"
                type="button"
                key={'ok-confirmation-settings'}
                onClick={handleDeleteAccount}
              >
                {t('yes')}
              </CommonButton>,
              <CommonButton
                variant="secondary"
                type="button"
                key={'ok-confirmation-settings'}
                onClick={() => setIsFormDeleteAccount(false)}
              >
                {t('no')}
              </CommonButton>,
            ]}
          />
        ) : (
          <Card>
            <CardHeader>
              <FaUserCog />
              <FormTitle>{t('my-profile')}</FormTitle>
            </CardHeader>
            <LinkCardTabs tabs={ProfileTabsTree(t)} fluid />
            <ProfileSettingsForm
              setIsFormDeleteAccount={setIsFormDeleteAccount}
              setIsFormSuccess={setIsFormSuccess}
            />
          </Card>
        )
      ) : (
        <InlineConfirm
          isLoading={false}
          icon={<FaCheck />}
          title={t('changes-have-been-saved')}
          status="success"
          actions={[
            <CommonButton
              variant="primary"
              type="button"
              key={'ok-confirmation-settings'}
              onClick={() => setIsFormSuccess(false)}
            >
              {t('ok')}
            </CommonButton>,
          ]}
        />
      )}
    </PatientLayout>
  );
}
