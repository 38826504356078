import styled from 'styled-components';
import { CommonLink } from '../../../components/common/Forms/Button';
import { Col } from '../../../components/common/Grid';
import { Card } from '../../../components/common';

export const TrustPreferencesHeader = styled(Col)`
  min-width: 100%;

  ${Card} {
    margin-bottom: var(--s4);
    display: flex;
    column-gap: var(--s7);
    align-items: center;
  }
`;

export const TrustPreferencesRightControls = styled.div`
  display: flex;
  margin-left: auto;
`;

export const TrustPreferencesBackButton = styled(CommonLink)`
  margin-right: var(--s3);
`;
