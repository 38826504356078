import { GlobalAttribute } from 'types/common';

import {
  siteAttributeKeysSubset,
  updateSiteAttributes,
} from '../SitePreferencesHooks';
import {
  EmailsPreferencesFormFieldValues,
  UseEmailsPreferencesFormHooks,
} from './EmailsPreferencesHooks';
import { DeepPartialSkipArrayKey, UnpackNestedValue } from 'react-hook-form';
import {
  SERVER_EMAIL_ALLOW_APPT,
  SERVER_EMAIL_ALLOW_CANCEL,
  SERVER_EMAIL_ALLOW_CONFIRMATION,
  SERVER_EMAIL_ALLOW_CREATE,
  SERVER_EMAIL_ALLOW_DAILY_REPORT,
  SERVER_EMAIL_ALLOW_EMAIL_UPDATE,
  SERVER_EMAIL_ALLOW_REMINDER,
  SERVER_EMAIL_ALLOW_SUPPORT_FORM,
  SERVER_EMAIL_ALLOW_WAITING_LIST,
} from '../../../../configs/siteAndTrustAttributes';

const keys = siteAttributeKeysSubset([
  SERVER_EMAIL_ALLOW_CREATE,
  SERVER_EMAIL_ALLOW_CONFIRMATION,
  SERVER_EMAIL_ALLOW_CANCEL,
  SERVER_EMAIL_ALLOW_REMINDER,
  SERVER_EMAIL_ALLOW_APPT,
  SERVER_EMAIL_ALLOW_EMAIL_UPDATE,
  SERVER_EMAIL_ALLOW_DAILY_REPORT,
  SERVER_EMAIL_ALLOW_WAITING_LIST,
  SERVER_EMAIL_ALLOW_SUPPORT_FORM,
]);

const restAttributes: GlobalAttribute[] = keys.map((key) => ({ key }));

export const useEmailingRulingFormHooks: UseEmailsPreferencesFormHooks = () => {
  return {
    restAttributes,
    beforeSubmit: (updatedSite, formValues) => {
      return {
        ...updatedSite,
        attributes: updateSiteAttributes(
          updatedSite.attributes,
          formValues.attributes,
          keys,
          (_, siteValue, formValue) => {
            return formValue || siteValue;
          },
        ),
      };
    },
  };
};

export const useEmailRulingFormKeysValues = ({
  watchedValues,
}: {
  watchedValues: UnpackNestedValue<
    DeepPartialSkipArrayKey<EmailsPreferencesFormFieldValues>
  >;
}): { [key in typeof keys[number]]: boolean } => {
  const attributes = watchedValues.attributes;
  return keys.reduce((result, key) => {
    return {
      ...result,
      [key]: Boolean(
        (attributes?.find((atr) => atr.key === key)?.value_int || 0) !== 0,
      ),
    };
  }, {} as { [key in typeof keys[number]]: boolean });
};
