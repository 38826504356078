import {
  buttonOrLinkSize,
  ButtonOrLinkStyle,
  CommonButton,
  CommonNavLink,
} from 'components/common/Forms/Button';
import styled, { css } from 'styled-components';
import { mq } from 'styles/sizes';
import { toRem } from 'utils/helpers';

export type TabLinkProps = ButtonOrLinkStyle & {
  $active: boolean;
};

const tabStyle = css<TabLinkProps>`
  border-color: var(--textdisabled);
  color: var(--textdisabled);
  min-width: auto;

  ${mq.xsm} {
    ${(props) => buttonOrLinkSize(props)}
  }

  ${({ $active }) =>
    $active &&
    `
    background-color: var(--redaccent);
    border-color: var(--redaccent);
    &, &:hover {
      color: var(--white);
    }
  `}
`;

export const Tab = styled(CommonButton)<TabLinkProps>`
  ${tabStyle}
`;

export const TabLink = styled(CommonNavLink)<TabLinkProps>`
  ${tabStyle}
`;

export const TabsWrapper = styled.nav<{
  readonly withDivider?: boolean;
  readonly fluid?: boolean;
  readonly noMargin?: boolean;
}>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 'var(--s3)')};

  & * {
    margin-right: var(--s3);

    &:last-child {
      margin-right: 0;
    }
  }

  ${({ withDivider }) =>
    withDivider &&
    css`
      padding-bottom: var(--s3);
      border-bottom: ${toRem(2)}rem solid var(--greenaccent);
    `}
  ${({ fluid }) =>
    fluid &&
    css`
      width: 100%;

      ${CommonNavLink} {
        flex: 1;
      }
    `}
`;
