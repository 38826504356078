import { CommonLink } from 'components/common/Forms/Button';
import React, { ReactElement } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import {
  ContactUsDesc,
  ContactUsEnvelope,
  ContactUsMessageWrapper,
  ContactUsTitle,
  ContactUsWrapper,
} from './Support.styled';

export default function ContactUs(): ReactElement {
  return (
    <ContactUsWrapper>
      <ContactUsEnvelope>
        <FaEnvelope />
      </ContactUsEnvelope>
      <ContactUsTitle>DID NOT FIND THE ANSWER?</ContactUsTitle>
      <ContactUsDesc>SEND US A MESSAGE</ContactUsDesc>
      <ContactUsMessageWrapper>
        <CommonLink variant="primary" to="/contact-us" size="large">
          WRITE A MESSAGE
        </CommonLink>
      </ContactUsMessageWrapper>
    </ContactUsWrapper>
  );
}
