import { toRem } from '../utils/helpers';

export type FontBasedSize = { fontBasedSize?: '--fontlg' | '--fontlarger' };

export const LABEL_IN_CORNER = 'labelInCorner';
export const LABEL_BEFORE = 'labelBefore';
export const FONT_LG = '--fontlg';
export const FONT_LARGER = '--fontlarger';

export const getFontSize =
  (defaultFont = 'font-size:var(--body1);') =>
  ({ fontBasedSize }: FontBasedSize & any) =>
    fontBasedSize ? `font-size:var(${fontBasedSize});` : defaultFont;

export const getHeightBasedOnFontSize =
  (defaultFont = `${toRem(18)}rem`) =>
  ({ fontBasedSize }: FontBasedSize & any) => {
    switch (fontBasedSize) {
      case FONT_LG:
        return `${toRem(30)}rem`;
      default:
        return defaultFont;
    }
  };

export const heightBasedOnFontSize = ({
  fontBasedSize,
}: FontBasedSize & any) => {
  switch (fontBasedSize) {
    case FONT_LG:
    case FONT_LARGER:
      return '';
    default:
      return 'height: var(--s4);';
  }
};

export const getMaxMenuHeightBasedOnFontSize = ({
  fontBasedSize,
}: FontBasedSize & any) => {
  switch (fontBasedSize) {
    case FONT_LG:
    case FONT_LARGER:
      return '';
    default:
      return `max-height: ${toRem(166)}rem;`;
  }
};

export const getMinHeightBasedOnFontSize = ({
  fontBasedSize,
}: FontBasedSize & any) => {
  switch (fontBasedSize) {
    case FONT_LG:
      return `min-height: ${toRem(48)}rem;`;
    default:
      return `min-height: ${toRem(24)}rem;`;
  }
};
