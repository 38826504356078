import { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import { useRoleRoute } from './UnAuthRouteOnlyHooks';

export const UnAuthRouteOnly = ({ component, ...rest }: RouteProps) => {
  const { userLoggedIn, route } = useRoleRoute();
  if (!userLoggedIn) {
    return <Route {...rest} component={component} />;
  }
  return <Redirect to={route!} />;
};
