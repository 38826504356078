import { CommonLink } from 'components/common/Forms/Button';
import NavbarDropdown from 'components/NavbarDropdown';
import { useAuth } from 'context/AuthContext';
import { useGlobalAttributes } from 'context/GlobalAttributesContext';
import { useSite } from 'context/SiteContext';
import { ReactElement } from 'react';
import { FaBell, FaExclamationTriangle } from 'react-icons/fa';
import { INavbarDropdown } from 'types/common';
import {
  ExpireIn,
  NavActions,
  NavbarWrapper,
  NhsLogoWrapper,
  StyledNhsLogo,
} from './Navbar.styled';
import { isBefore, parseDate, parseIsoDate } from '../../utils/dateUtil';
import { differenceInDays } from 'date-fns';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { LOGIN_ROUTE } from '../../routes/AppRoutes';

interface Props {
  dropdowns?: INavbarDropdown[];
  nhsLogo?: boolean;
  /**
   * If not passed, active site name is used
   */
  nhsLogoTitle?: string;
  patientLayout?: boolean;
  releaseNote?: {
    to: string;
    key: 'PATIENT' | 'STAFF';
  };
  loginButtonForNoUser?: boolean;
}

export const Navbar = ({
  dropdowns,
  nhsLogo,
  nhsLogoTitle,
  releaseNote,
  patientLayout,
  loginButtonForNoUser,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();
  const { attributes } = useGlobalAttributes();
  const { sangixUser } = useAuth();
  const { activeSite } = useSite();
  const expireDays = differenceInDays(
    parseDate(activeSite?.expire_date),
    new Date(),
  );

  const releaseNoteAttr = sangixUser?.release_note;
  const userReleaseNoteDate = releaseNoteAttr && parseIsoDate(releaseNoteAttr);
  const globalReleaseNotAttr = attributes?.find(
    (attr) => attr.key === releaseNote?.key,
  )?.value_str;
  const globalReleaseNoteDate =
    globalReleaseNotAttr && parseIsoDate(globalReleaseNotAttr);

  const releaseNoteHasUpdated =
    userReleaseNoteDate &&
    globalReleaseNoteDate &&
    isBefore(userReleaseNoteDate, globalReleaseNoteDate);

  return (
    <NavbarWrapper patientLayout={patientLayout}>
      {nhsLogo && (
        <NhsLogoWrapper>
          <StyledNhsLogo />
          {nhsLogoTitle !== undefined ? nhsLogoTitle : activeSite.name}
        </NhsLogoWrapper>
      )}
      {!patientLayout && expireDays <= 30 && (
        <ExpireIn>
          <FaExclamationTriangle />
          {t('licence-expire-in', { days: expireDays })}
        </ExpireIn>
      )}
      <NavActions>
        {dropdowns?.map(
          (dropdown, index) =>
            Boolean(dropdown.links.length) && (
              <NavbarDropdown
                dropdown={dropdown}
                key={dropdown.label + index}
              />
            ),
        )}
        {loginButtonForNoUser && !sangixUser && (
          <CommonLink
            variant="secondary"
            to={LOGIN_ROUTE}
            style={{
              marginLeft: 'var(--s2)',
            }}
          >
            {t('login')}
          </CommonLink>
        )}
        {Boolean(releaseNote) && (
          <CommonLink
            size="auto"
            variant="danger"
            disabled={!releaseNoteHasUpdated}
            iconOnly
            to={releaseNote?.to as string}
            style={{
              marginLeft: 'var(--s2)',
            }}
          >
            <FaBell />
          </CommonLink>
        )}
      </NavActions>
    </NavbarWrapper>
  );
};
