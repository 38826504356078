import styled, { css } from 'styled-components';
import {
  screenWidthHeight,
  topCenterAbsolutePosition,
  topLeftCenterAbsolutePosition,
} from 'styles/partials/positions';
import { toRemStr } from 'utils/helpers';
import { CommonButton } from 'components/common/Forms/Button';
import { StyledCommonInput } from 'components/common/Forms/Forms.styled';
import { boxShadow } from '../../../styles/partials/boxShadow';
import { fullscreenMaxContentWithRemPx } from '../../../styles/sizes';

/*
  This screen is made for the iPad, therefore all sizes will be scaled down
  they are intentionally bigger to target the iPad sizes
*/

export const KioskWrapper = styled.div`
  background: var(--white);
  margin: 0;
  padding: 0;
  font-weight: 600;
  display: flex;
  ${screenWidthHeight}
`;

export const KioskBGBox = styled.div`
  opacity: 0.25;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const KioskBGImg = styled.img`
  position: absolute;
  ${topCenterAbsolutePosition}
`;

// translate used to shift images by value relative to their own widths
export const KioskBGDoctor = styled(KioskBGImg)`
  height: 76%;
  transform: translate(-72%, -50%);
`;
export const KioskBGBoy = styled(KioskBGImg)`
  right: 0;
  height: 74%;
  transform: translate(79%, -50%);
`;
export const KioskBGCloud = styled(KioskBGImg)`
  height: 86%;
  ${topLeftCenterAbsolutePosition}
`;

export const KioskPage = styled.div`
  flex: 1;
  background-color: var(--greentercial);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const KioskContent = styled.div`
  z-index: 1;
  min-width: 80%;
  max-width: ${toRemStr(fullscreenMaxContentWithRemPx)};
  flex: 0.8;
  display: flex;
  flex-direction: column;
`;

export const KioskHeaderStyled = styled.div`
  ${boxShadow}
  min-width: 80%;
  max-width: ${toRemStr(fullscreenMaxContentWithRemPx)};
  background-color: var(--white);
  border-radius: 0px 0px var(--s5) var(--s5);
  height: ${toRemStr(104)};
  display: flex;
  align-items: center;
  column-gap: var(--s4);
  padding: var(--s4) var(--s5);
`;

export const KioskHeaderTime = styled.div`
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: left;
  letter-spacing: 0.01px;
  color: #00000099;
  font-size: var(--h1);
  text-transform: uppercase;
  white-space: nowrap;
`;

export const KioskHeaderTitle = styled.div`
  font-size: var(--h2);
  text-align: center;
`;

export const KioskSangixLogo = styled.div`
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: right;
`;

export const KioskEntryMessageBoxStyled = styled.div`
  ${boxShadow}
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  background: var(--white);
  border-radius: var(--s4);
  margin-top: var(--s5);
  font-size: var(--fontxl);
  padding: var(--s3) var(--s5);
  text-align: center;
  min-height: ${toRemStr(570)};
`;

export const KioskPreCheckInMsg = styled.div`
  // If the message is short, fill the box and put the message at the middle
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const KioskInputGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
`;

const inputButtonStyles = css`
  flex: 0;
  flex-basis: ${toRemStr(780)};
  min-height: ${toRemStr(150)};
  max-height: ${toRemStr(150)};
  border-radius: var(--s4);
  margin: var(--s4) 0;
  padding: 0;
  border-width: 5px;
  text-align: center;
`;

export const KioskEntryInput = styled(StyledCommonInput)`
  font-size: ${toRemStr(200)};
  line-height: ${toRemStr(200)};
  ${inputButtonStyles}
  min-height: ${toRemStr(260)};
  max-height: ${toRemStr(260)};
`;

export const KioskEntrySubmit = styled(CommonButton).attrs(() => ({
  variant: 'primary',
}))`
  ${inputButtonStyles}
  margin-left: var(--s3);
  font-size: var(--fontxl);
  min-height: ${toRemStr(260)};
  max-height: ${toRemStr(260)};
`;

export const KioskEntryChangeButton = styled(CommonButton).attrs(() => ({
  variant: 'primary',
}))`
  ${inputButtonStyles}
  flex-basis: ${toRemStr(200)};
  margin-left: var(--s3);
  font-size: var(--fontlg);
`;

export const KioskPreCheckInYes = styled(CommonButton).attrs(() => ({
  variant: 'primary',
}))`
  ${inputButtonStyles}
  margin-left: var(--s3);
  font-size: var(--fontxl);
`;
export const KioskPreCheckInNo = styled(KioskPreCheckInYes).attrs(() => ({
  variant: 'danger',
}))``;

export const KioskRoundedItem = styled.div`
  ${boxShadow}
  border-radius: ${toRemStr(130)};
  font-size: var(--fontxl);
  padding: var(--s3) var(--s5);
  margin: var(--s3) 0;
  display: flex;
`;

export const KioskSelectWrapper = styled(KioskRoundedItem)`
  ${boxShadow}
  padding: 0;
  background-color: var(--white);
  display: flex;
  align-items: center;
`;

export const KioskSearchResultHint = styled(KioskRoundedItem)`
  ${boxShadow}
  background-color: var(--yellowaction);
  color: var(--white);
`;

export const KioskSearchResultErrorMessage = styled(KioskRoundedItem)`
  text-align: center;
  background-color: var(--rednegative);
  color: var(--white);
  text-transform: uppercase;
`;

export const KioskSearchResultSuccessMessage = styled(KioskRoundedItem)`
  background-color: var(--primary);
  color: var(--white);
`;

const kioskItemHeight = toRemStr(104);

const searchResultButton = css`
  ${boxShadow}
  padding: 0 var(--s5);
  font-size: var(--fontxl);
  color: var(--white);
  border-radius: ${toRemStr(130)};
  border: none;
  margin-left: auto;
  font-weight: inherit;
  height: ${kioskItemHeight};
`;
styled(CommonButton).attrs(() => ({
  variant: 'primary',
}))`
  ${searchResultButton}
  height: 100%;
`;

export const KioskExitButtonLayoutContent = styled.div`
  margin-bottom: var(--s5);
`;

export const KioskSearchResultExitButton = styled(CommonButton).attrs(() => ({
  variant: 'danger',
}))`
  ${searchResultButton}
  height: ${kioskItemHeight};
  margin: auto;
  margin-bottom: var(--s5);
  min-width: ${toRemStr(300)};
`;

export const KioskSearchResultSelectButton = styled(CommonButton).attrs(() => ({
  variant: 'primary',
}))`
  ${searchResultButton}
  margin-left: auto;
  margin-bottom: 0;
`;
