import { useCallback } from 'react';
import {
  firebaseDeleteUser,
  firebaseSignup,
  signInWithEmail,
} from '../../../services/firebase';
import { FirebaseInfo, RegisterToSangixBE } from './useUserRegisterToSangixBE';
import { UserFormValues } from '../../../types/users';

const UserRegisterOnFirebaseError: FirebaseInfo = {
  getIdToken: async () => {
    return '00000000000000000000000000000000';
  },
  providerData: [],
};

export const useRegisterWithFirebaseSignUp = ({
  registerToSangixBe,
}: {
  registerToSangixBe: RegisterToSangixBE;
}) => {
  const registerWithFirebaseSignup = useCallback(
    async (
      emailPwd: { email: string; password: string },
      user: UserFormValues,
    ) => {
      try {
        const result = await firebaseSignup(emailPwd.email, emailPwd.password);
        const error = await registerToSangixBe(user, result.user);
        if (error !== undefined) {
          await firebaseDeleteUser(result.user);
        }
        return error;
      } catch (error) {
        /**
         * It can happen that user is in firebase but not on sangix BE. This is a workaround solution
         * to register user on sangix BE, which will search firebase for it.
         * https://sangix.atlassian.net/browse/SGX-166
         */
        const registerError = await registerToSangixBe(
          { ...user, password: emailPwd.password },
          UserRegisterOnFirebaseError,
        );

        if (!registerError) {
          /**
           * This above will register user in sangix BE, and firebase, but we need to log him in firebase to send the
           * correct auth headers.
           */
          await signInWithEmail(emailPwd.email, emailPwd.password);
        }
      }
    },
    [registerToSangixBe],
  );
  return { registerWithFirebaseSignup };
};
