import { UNSAFE_FILTER_WITH_NO_PAGINATION } from '../hooks/usePaginatedFilters';
import { useReferrers } from '../query/referrers';
import { SelectOption } from '../types/common';

export const useReferrerOptions = () => {
  const { isLoading, data: referrerResponseData } = useReferrers({
    ...UNSAFE_FILTER_WITH_NO_PAGINATION,
  });
  const referrerOptions: SelectOption[] =
    referrerResponseData?.data
      ?.map((referrer) => ({
        label: referrer.name,
        value: referrer.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];
  return {
    isLoading,
    referrerOptions,
  };
};
