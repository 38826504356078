import React, { useState } from 'react';
import { SADApptStatistics } from '../../../types/reports';
import { PageHelmet } from '../../../components/common/PageHelmet';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { AdminStatisticsLayout } from './AdminStatisticsLayout';
import { Card } from '../../../components/common';
import { AdminStatisticsSiteOverviewTableWithExport } from './AdminStatisticsSiteOverviewTableWithExport';
import { AdminStatisticsSiteSummaryCard } from './AdminStatisticsSiteSummaryCard';
import { Col } from '../../../components/common/Grid';

type IAdminAuditApptTableProps = {
  sadStatistics?: SADApptStatistics;
  isLoading: boolean;
};

export const AdminStatisticsSiteOverview = ({
  ...input
}: IAdminAuditApptTableProps) => {
  const { t } = useCommonTranslation();

  const [siteSummaryId, setSiteSummaryId] = useState<string>();

  return (
    <AdminStatisticsLayout>
      <PageHelmet title={t('admin-statistics-overview')} />
      <Col md={7}>
        <Card>
          <AdminStatisticsSiteOverviewTableWithExport
            {...input}
            onSiteSummaryClicked={setSiteSummaryId}
            selectedSiteId={siteSummaryId}
          />
        </Card>
      </Col>
      <Col md={4}>
        {siteSummaryId && (
          <AdminStatisticsSiteSummaryCard
            siteSummaryId={siteSummaryId}
            onCloseClicked={() => setSiteSummaryId(undefined)}
          />
        )}
      </Col>
    </AdminStatisticsLayout>
  );
};
