import { CommonButton } from 'components/common/Forms/Button';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { toRem } from 'utils/helpers';

export const SideMenuList = styled.ul`
  width: 100%;
  overflow-y: auto;
  padding: var(--s2) 0;
`;
export const SideMenuWrapper = styled.nav`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: ${toRem(160)}rem;
  background: var(--greenaccent);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${SideMenuList} {
    margin-top: auto;
  }
`;
export const NavMenuStatus = styled(CommonButton)`
  && {
    cursor: inherit;
    transition: none;
    color: var(--greenaccent);
    margin-top: var(--s1);
    border: none;
    padding: var(--s1) var(--s2);
    min-width: auto;
    height: auto;
    line-height: normal;
  }
`;
export const StyledNavLink = styled(NavLink)`
  color: var(--white);
  min-height: var(--s7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--bodysm);
  text-transform: uppercase;
  font-weight: 500;
  padding: var(--s2) var(--s1);
  &:hover,
  &:focus {
    color: var(--white);
    text-decoration: none;
  }
  &.active {
    background: var(--redaccent);
    border-radius: 0 var(--s4) var(--s4) 0;
    ${NavMenuStatus} {
      color: var(--redaccent);
    }
  }
`;
export const ListItem = styled.li<{ readonly haveSubMenu?: boolean }>`
  padding-right: var(--s2);
  ${({ haveSubMenu }) =>
    haveSubMenu &&
    css`
      padding-right: 0;
      ${SubMenu} {
        display: none;
      }
      ${StyledNavLink} {
        &.active {
          background: var(--greenprimary);
          height: auto;
          min-height: var(--s7);
          border-bottom-right-radius: 0;
          padding: var(--s3) 0 ${toRem(56)}rem;
          & + ${SubMenu} {
            display: block;
            margin-top: -${toRem(40)}rem;
          }
        }
      }
    `}
`;
export const IconWrapper = styled.div`
  font-size: ${toRem(36)}rem;
  line-height: 0;
  margin-bottom: var(--s3);
`;
export const PoweredByWrapper = styled.div`
  display: flex;
  margin-top: auto;
  min-height: var(--s7);
  max-height: var(--s7);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--greenprimary);
  width: 100%;
`;
export const PoweredByText = styled.p`
  color: var(--white);
  text-transform: uppercase;
  font-size: var(--bodysm);
  margin: 0 0 var(--s2);
  pointer-events: none;
  user-select: none;
`;
export const SangixLogo = styled.img`
  display: block;
  margin: 0 auto;
  height: ${toRem(34)}rem;
  user-select: none;
  pointer-events: none;
`;
export const SubMenu = styled.ul`
  width: 100%;
  background: var(--greentercial);
  padding: var(--s3) 0;
  border-radius: 0 var(--s4) var(--s4) 0;
`;
export const SubMenuLink = styled(NavLink)`
  font-size: var(--bodysm);
  height: var(--s4);
  border-radius: 0 var(--s4) var(--s4) 0;
  color: var(--greenaccent);
  display: flex;
  align-items: center;
  padding-left: var(--s3);
  text-transform: uppercase;
  font-weight: 500;
  &:hover,
  &:focus {
    color: var(--greenaccent);
    text-decoration: none;
  }
  &.active {
    background: var(--redaccent);
    border-radius: 0 var(--s4) var(--s4) 0;
    box-shadow: var(--shadow-3);
    color: var(--white);
  }
`;
export const SubMenuItem = styled.li`
  padding-right: var(--s2);
`;
