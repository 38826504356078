import { Loading } from '../../../../components/common/Loading';
import { KioskLayout } from '../KioskLayout';

export const KioskLoading = () => {
  return (
    <KioskLayout>
      <Loading />
    </KioskLayout>
  );
};
