import { Appointment } from '../../../../types/appointments';
import { useValidatePatientAgeWarningDialog } from './useValidatePatientAgeWarningDialog';
import { useCallback } from 'react';
import {
  BookPatientApptsProps,
  useStaffAppointmentBooking,
} from './appointmentFormHooks';
import { useReferrerOptions } from '../../../../utils/options';
import { useCreateUserMutation } from '../../../../query/users';
import { successToast } from '../../../../utils/toast';
import { useSite } from '../../../../context/SiteContext';
import { usePatientRole } from '../../../../context/RoleContext';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { BookAppointmentForm } from '../../../../components/views-components/staff/appointments/EditAppointmentData';
import { UserFormValues } from '../../../../types/users';
import { useDuplicateUserCheck } from '../../../../hooks/user/useDuplicateUserCheck';
import { useGPSurgeriesOptions } from '../../../../hooks/referrals/useGPSurgeriesOptions';

type CreateAndBookFunctionParams = {
  user: UserFormValues;
  appointment: BookAppointmentForm;
  queue: boolean;
};
type CreateAndBookFunction = (props: CreateAndBookFunctionParams) => any;

const useCreateAndBookWithDuplicateCheck = ({
  bookPatientAppointment,
  createUserAndBookAppointment,
}: {
  createUserAndBookAppointment: CreateAndBookFunction;
  bookPatientAppointment: (props: BookPatientApptsProps) => void;
}) => {
  const {
    actionWithDuplicateCheck: createAndBookWithDuplicateCheck,
    duplicateUsers,
    resolveAsDuplicate,
    resolveAsNOTDuplicate,
  } = useDuplicateUserCheck<CreateAndBookFunctionParams>({
    onResolvedAsDuplicate: (params) => {
      bookPatientAppointment({
        user: params.user,
        queuePatient: params.queue,
        appointment: params.appointment,
      });
    },
    onResolvedAsNOTDuplicate: (params) => {
      createUserAndBookAppointment(params);
    },
  });
  return {
    createAndBookWithDuplicateCheck,
    duplicateUsers,
    duplicateResolveCreateNew: resolveAsNOTDuplicate,
    duplicateResolveSelect: resolveAsDuplicate,
  };
};

const useCreateUserAndBook = ({
  closeForm,
  selectedAppointmentSlot,
}: {
  closeForm: () => void;
  selectedAppointmentSlot: Appointment[];
}) => {
  const { t } = useCommonTranslation();
  const { activeSite } = useSite();
  const { id: patientRoleId } = usePatientRole();

  const {
    bookPatientAppointment,
    isLoading: isApptBookOrLockLoading,
    selectedAppointment,
  } = useStaffAppointmentBooking({
    closeForm,
    selectedAppointmentSlot,
  });

  const { mutateAsync: createUserAsync, isLoading: isCreateUserLoading } =
    useCreateUserMutation({
      onSuccess: () => {
        successToast(t('patient-created'));
      },
    });

  const createUserAndBookAppointment = useCallback<CreateAndBookFunction>(
    async ({ appointment, user, queue }) => {
      const savedUser = await createUserAsync({
        ...user,
        active_site_id: activeSite.id,
        role_id: patientRoleId,
      });
      await bookPatientAppointment({
        user: savedUser.data,
        queuePatient: queue,
        appointment: appointment,
        keepPatient: false,
      });
    },
    [activeSite, bookPatientAppointment, createUserAsync, patientRoleId],
  );
  return {
    createUserAndBookAppointment,
    bookPatientAppointment: bookPatientAppointment,
    isLoading: isCreateUserLoading || isApptBookOrLockLoading,
    selectedAppointment,
  };
};

const useValidateSiteBookRestrictions = ({
  createUserAndBookAppointment,
}: {
  createUserAndBookAppointment: CreateAndBookFunction;
}) => {
  const { showSitePatientAgeWarningIfNeeded } =
    useValidatePatientAgeWarningDialog();

  return useCallback<CreateAndBookFunction>(
    ({ user, ...rest }) => {
      showSitePatientAgeWarningIfNeeded({
        onSuccess: () =>
          createUserAndBookAppointment({
            user,
            ...rest,
          }),
        user: user,
      });
    },
    [showSitePatientAgeWarningIfNeeded, createUserAndBookAppointment],
  );
};

export const useStaffAppointmentBookNoPatient = (props: {
  closeForm: () => void;
  selectedAppointmentSlot: Appointment[];
}) => {
  const {
    createUserAndBookAppointment,
    bookPatientAppointment,
    selectedAppointment,
    isLoading: isCreateAndBookLoading,
  } = useCreateUserAndBook(props);

  const {
    createAndBookWithDuplicateCheck,
    duplicateUsers,
    duplicateResolveCreateNew,
    duplicateResolveSelect,
  } = useCreateAndBookWithDuplicateCheck({
    createUserAndBookAppointment,
    bookPatientAppointment,
  });

  const validateRestrictionsAndCreateAndBook = useValidateSiteBookRestrictions({
    createUserAndBookAppointment: createAndBookWithDuplicateCheck,
  });

  const { isLoading: isSurgeriesLoading, gpSurgeriesOptions: surgeryOptions } =
    useGPSurgeriesOptions({});

  const { isLoading: isReferrersLoading, referrerOptions } =
    useReferrerOptions();

  return {
    createAndBookPatientWithValidation: validateRestrictionsAndCreateAndBook,
    isLoading:
      isCreateAndBookLoading || isSurgeriesLoading || isReferrersLoading,
    selectedAppointment,
    surgeryOptions,
    referrerOptions,
    duplicateUsers,
    duplicateResolveCreateNew,
    duplicateResolveSelect,
  };
};
