import styled from 'styled-components';
import { cssIf } from 'styles/partials/cssIf';
import { toRemStr } from 'utils/helpers';

export const BulletTableStyled = styled.table`
  border-collapse: separate;
  border-spacing: 0 var(--s1);
  font-size: var(--bodysm);
  color: var(--textsecondary);
  width: 100%;
  thead {
    border-radius: 16px;
    box-shadow: var(--shadow-1);
  }
  tr {
    box-shadow: var(--shadow-1);
    height: var(--s4);
    border-radius: 16px;
    td:first-child {
      border-radius: 16px 0 0 16px;
    }
    td:last-child {
      border-radius: 0 16px 16px 0;
    }
  }
  td {
    padding: var(--s1) var(--s3);
  }
`;

export const BulletHeaderTR = styled.tr<{ noShadow?: boolean }>`
  font-size: var(--bodysm);
  font-weight: 500;
  padding: var(--s1) 0;
  padding-left: var(--s3);
  margin-right: auto;
  color: var(--textsecondary);
  ${cssIf('noShadow')`
    && {
      box-shadow: none;
    }
  `}
`;

export const BulletTR = styled.tr<{ selected?: boolean }>`
  ${cssIf('selected')`
    td {
      background-color: var(--greensecondary);
      color: var(--white);
    }
  `}
`;

export const BulletHeaderTDStyled = styled.td`
  svg {
    color: var(--textdisabled);
    height: ${toRemStr(16)};
    margin-left: var(--s1);
  }
`;

export const BulletHeaderFilterTD = styled.td`
  && {
    padding: var(--s1);
    vertical-align: top;
  }
`;

export const BulletHeaderFilterButtonBar = styled.div`
  display: flex;
  gap: var(--s2);
`;

export const BulletHeaderTDInner = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

export const BulletBodyTR = styled.tr<{ selected?: boolean }>`
  ${cssIf('selected')`
    td {
      background-color: var(--greensecondary);
      color: var(--white);
    }
  `}
`;

type BulletBodyTDProps = {
  bold?: boolean;
  btn?: boolean;
};

export const BulletBodyTD = styled.td<BulletBodyTDProps>`
  ${cssIf('bold')`
    font-size: var(--h4);
    font-weight: bold;
    color: var(--textprimary);
  `}
  ${cssIf('btn')`
    && {
      padding: 0;
      padding-left: var(--s3);
      width: 1px; // do not expand a cell
    }
  `}
`;

export const SortIconBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 16px;
  svg {
    height: 50%;
  }
`;
