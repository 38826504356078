import { Card } from 'components/common';
import {
  buttonOrLinkSize,
  CommonButton,
  CommonLink,
} from 'components/common/Forms/Button';
import Input from 'components/common/Forms/Input';
import styled from 'styled-components';
import { mq } from 'styles/sizes';
import { toRemStr } from 'utils/helpers';
import { boxShadow } from '../../../styles/partials/boxShadow';
import { listItemMarginY } from '../../../styles/partials/listItemMargin';

export const PatientCheckInCard = styled(Card)`
  padding-right: var(--s4);
  padding-left: var(--s4);
`;
export const PatientCheckInFormStyled = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: var(--s3);

  ${mq.sm} {
    flex-direction: row;
    align-items: flex-start;

    ${CommonButton}, ${CommonLink} {
      ${buttonOrLinkSize({ size: 'large' })}
    }
  }
`;

export const FormInputBar = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: var(--s2);

  ${mq.sm} {
    flex-direction: row;
  }
`;

export const InputLabel = styled.label`
  padding-bottom: var(--s2);

  ${mq.sm} {
    padding-bottom: 0;
    padding-top: var(--s2);
  }
`;

export const CheckInNumberInput = styled(Input)`
  min-width: ${toRemStr(120)};
  margin-bottom: 0;
  flex-grow: 1;

  ${mq.sm} {
    margin-left: var(--s2);
    flex-grow: 0;
  }
`;

export const FormButtonBar = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.sm} {
    margin-left: auto;
  }

  ${CommonButton} {
    margin-bottom: var(--s3);
  }
`;

export const PatientCheckInButtonBarStyled = styled.div`
  display: flex;
  margin-top: var(--s3);
  gap: var(--s2);
  flex-direction: column;

  ${mq.xsm} {
    gap: var(--s3);
    flex-direction: row;
  }
`;

export const PatientCheckInButtonBarRight = styled.div`
  display: flex;
  gap: var(--s2);
  flex-direction: column;

  ${mq.xsm} {
    margin-left: auto;
    gap: var(--s3);
    flex-direction: row;
  }
`;

export const PatientCheckInPickSiteListWrapper = styled.div`
  max-height: 310px;
  overflow: auto;
  margin: calc(var(--s3) * -1);
  padding: var(--s3);
`;

export const PatientCheckInSiteItem = styled.div`
  ${boxShadow}
  ${listItemMarginY('var(--s3)')}
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: var(--s3);
  padding: var(--s3);

  ${CommonButton} {
    margin-left: var(--s2);
  }
`;

export const PatientCheckInSiteItemName = styled.div`
  font-size: var(--h4);
  font-weight: bold;
  padding-right: var(--s2);
  margin-right: auto;
`;
