import { PatientBookingState } from 'context/patient/modules/booking';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { PatientBookingStageTitle2 } from '../PatientBooking.styled';
import { PrevNextWeekPicker } from '../../../../components/InlineCalendar/PrevNextWeekPicker';
import { CommonButton } from '../../../../components/common/Forms/Button';
import { WeekInlineCalendarPickerWrapper } from '../../../../components/InlineCalendar/WeekDayPicker.styled';
import { useMaxAndMinFutureDateForBookingForOnlineBooking } from '../../../../hooks/patient/booking/useMaxAndMinFutureDateForBooking';

export type PatientBookingControlProps = {
  firstFoundInWeek: Date;
  updateBooking: (update: Partial<PatientBookingState>) => any;
  onStartSearchClicked: () => any;
};

export const PatientBookingControl = ({
  firstFoundInWeek,
  onStartSearchClicked,
  updateBooking,
}: PatientBookingControlProps) => {
  const { t } = useCommonTranslation();
  const { minDate, maxDate } =
    useMaxAndMinFutureDateForBookingForOnlineBooking();

  return (
    <>
      <CommonButton
        style={{
          marginBottom: 'var(--s1)',
        }}
        variant={'primary'}
        onClick={() => onStartSearchClicked()}
      >
        {t('pick-day-search-button')}
      </CommonButton>
      <PatientBookingStageTitle2>
        {t('or-pick-another-day')}
      </PatientBookingStageTitle2>
      <WeekInlineCalendarPickerWrapper>
        <PrevNextWeekPicker
          selectedDate={firstFoundInWeek}
          onSelectDate={(date) => updateBooking({ calendarDateShown: date })}
          iconOnly
          minDate={minDate}
          maxDate={maxDate}
        />
      </WeekInlineCalendarPickerWrapper>
    </>
  );
};
