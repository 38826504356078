import React, { forwardRef } from 'react';
import { FaCheck } from 'react-icons/fa';
import { toRem } from 'utils/helpers';
import {
  CheckboxInputStyled,
  CheckBoxNameAfter,
  CheckBoxNameBefore,
  CheckboxWrapper,
  CheckLabel,
  HiddenInput,
} from './Forms.styled';
import { FontBasedSize } from '../../../styles/fontsStyleUtils';

export type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> &
  FontBasedSize & {
    label: string;
    name: string;
    id?: string;
    onlyInRow?: boolean;
    isToggle?: boolean;
    inSelectList?: boolean;
    labelPosition?: 'before' | 'after';
    onCheckedStateChange?: (checked: boolean) => any;
  };

export default forwardRef<HTMLInputElement, CheckboxProps>(
  function CheckboxInput(
    {
      id,
      name,
      label,
      disabled,
      onlyInRow,
      isToggle,
      inSelectList,
      style,
      className,
      type = 'checkbox',
      labelPosition = 'after',
      onCheckedStateChange,
      title,
      fontBasedSize,
      ...rest
    },
    ref,
  ) {
    return (
      <CheckboxInputStyled style={style}>
        <CheckboxWrapper
          labelPosition={labelPosition}
          htmlFor={id || name}
          isDisabled={disabled}
          onlyInRow={onlyInRow}
          isToggle={isToggle}
          inSelectList={inSelectList}
          style={style}
          className={className}
          title={title}
          fontBasedSize={fontBasedSize}
        >
          {labelPosition === 'before' && (
            <CheckBoxNameBefore>{label}</CheckBoxNameBefore>
          )}
          <HiddenInput
            onChange={(event) => {
              onCheckedStateChange?.(event.target.checked);
            }}
            {...rest}
            type={type}
            ref={ref}
            name={name}
            id={id || name}
            disabled={disabled}
          />
          <CheckLabel
            labelPosition={labelPosition}
            isRadio={type === 'radio'}
            fontBasedSize={fontBasedSize}
          >
            {!isToggle && type === 'checkbox' && (
              <FaCheck size={`${toRem(12)}rem`} />
            )}
          </CheckLabel>
          {labelPosition === 'after' && (
            <CheckBoxNameAfter>{label}</CheckBoxNameAfter>
          )}
        </CheckboxWrapper>
      </CheckboxInputStyled>
    );
  },
);
