import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { patientURL } from 'routes/AppRoutes';
import { WaitingListFormValues } from './useWaitingList';
import { useWaitingListURL } from './useWaitingListURL';

export const useGoBackURL = () => {
  const {
    formState: { isDirty },
  } = useFormContext<WaitingListFormValues>();
  const confirmationURL = useWaitingListURL('confirm-back');

  const goBackURL = useMemo(() => {
    return isDirty ? confirmationURL : patientURL;
  }, [isDirty, confirmationURL]);

  return goBackURL;
};
