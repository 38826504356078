import React from 'react';
import { ExistingAppointmentEditCard } from './ExistingAppointmentEditCard';
import { PatientEditUpdate } from '../settings/users/PatientEditUpdate';
import { successToast } from '../../../utils/toast';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import {
  Appointment,
  UserAppointmentsTypes,
} from '../../../types/appointments';
import { UserAppointmentSelect } from '../../../hooks/staff/useFlowEditAppointment';
import {
  EDIT_PATIENT_MODE,
  EditAppointmentMode,
  PATIENT_APPOINTMENT_DETAIL_MODE,
  PATIENT_APPOINTMENTS_LIST_MODE,
  VIEW_APPOINTMENT_AUDIT_MODE,
} from '../../../hooks/staff/usePatientAppointments';
import {
  IPatientAppointmentsCardProps,
  PatientAppointmentsCard,
} from '../../../components/views-components/staff/appointments/patient/PatientAppointmentsCard';
import { useMutateCancelApptWithConfirm } from '../../../hooks/appointments/useMutateCancelApptWithConfirm';
import { useSite } from '../../../context/SiteContext';
import { AppointmentAuditTableCard } from '../../common/audit/AppointmentAuditTableCard';
import { Popup } from '../../../components/Popup/Popup';
import { UserData } from '../../../types/users';

type IAppointmentEditProps = {
  appointment?: Appointment;
  selectPatientAppointment: (selected: UserAppointmentSelect) => void;
  close: () => void;
  mode: EditAppointmentMode;
  isLoading: boolean;
  setExistingAppointmentMode: (mode: EditAppointmentMode) => void;
  closeEditPatient: () => void;
  bookedAppointments?: Appointment[];
  pastAppointments?: Appointment[];
  cancelledAppointments?: Appointment[];
  setSelectedAppointmentsTab: (newTab: UserAppointmentsTypes) => any;
  user?: UserData;
} & Pick<IPatientAppointmentsCardProps, 'firstSelected'>;

export const PatientAppointmentsAndEditPatientControl: React.FC<
  IAppointmentEditProps
> = ({
  appointment,
  bookedAppointments,
  pastAppointments,
  cancelledAppointments,
  setSelectedAppointmentsTab,
  mode,
  closeEditPatient,
  selectPatientAppointment,
  isLoading,
  close,
  setExistingAppointmentMode,
  user,
  firstSelected,
}) => {
  const { t } = useCommonTranslation();
  const { cancelWithConfirm, isLoading: isCancelLoading } =
    useMutateCancelApptWithConfirm({
      onSuccess: () => {
        successToast(t('appointment-cancel-successful'));
      },
    });

  const { activeSite, activeTrust } = useSite();
  return (
    <>
      {appointment && mode === PATIENT_APPOINTMENT_DETAIL_MODE && (
        <ExistingAppointmentEditCard
          onClose={close}
          appointment={appointment}
          user={user}
          isUpstreamLoading={isLoading}
          setExistingAppointmentMode={setExistingAppointmentMode}
        />
      )}
      {mode === PATIENT_APPOINTMENTS_LIST_MODE && (
        <PatientAppointmentsCard
          isLoading={isCancelLoading || isLoading}
          bookedAppointments={bookedAppointments}
          pastAppointments={pastAppointments}
          cancelledAppointments={cancelledAppointments}
          onClose={close}
          user={user}
          onAppointmentInfoClicked={(appointmentInfo) => {
            selectPatientAppointment(appointmentInfo);
          }}
          onAppointmentDeleteClicked={cancelWithConfirm}
          firstSelected={firstSelected}
          setSelectedAppointmentsTab={setSelectedAppointmentsTab}
        />
      )}
      {mode === EDIT_PATIENT_MODE && (
        <PatientEditUpdate
          closeFormHandler={closeEditPatient}
          prefilledData={user}
          activeSite={activeSite}
          activeTrust={activeTrust}
        />
      )}
      {mode === VIEW_APPOINTMENT_AUDIT_MODE && appointment && (
        <Popup
          size={'fullScreen'}
          onClose={() =>
            setExistingAppointmentMode(PATIENT_APPOINTMENT_DETAIL_MODE)
          }
        >
          <AppointmentAuditTableCard
            selectedAppointment={appointment}
            onCloseClicked={() =>
              setExistingAppointmentMode(PATIENT_APPOINTMENT_DETAIL_MODE)
            }
          />
        </Popup>
      )}
    </>
  );
};
