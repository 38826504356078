import {
  DialogFormWrapper,
  FormGroup,
  FormGroupTitle,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import { CardLoader } from 'components/common/Loading';
import React, { ReactElement } from 'react';
import { useWatch } from 'react-hook-form';
import { FaClock } from 'react-icons/fa';

import {
  MAX_APPT_ALL,
  MAX_APPT_WEEK,
  PATIENTS_EARLIEST_BOOKING_AFTER_IN_DAYS,
  PATIENTS_LATEST_BOOKING_BEFORE_IN_DAYS,
  QUEUE_TO_DNA,
  STAFF_EARLIEST_BOOKING_AFTER_IN_DAYS,
  STAFF_LATEST_BOOKING_BEFORE_IN_DAYS,
} from '../../../../configs/siteAndTrustAttributes';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { SitePreferencesType, TYPE_STAFF } from '../SitePreferences';
import { UseGeneralSitePreferencesFormReturn } from './GeneralSitePreferencesHooks';
import { ATTRIBUTE_SEPARATOR } from '../../../../configs/constants';

interface Props {
  isFetching: boolean;
  type: SitePreferencesType;
  attributesForm: UseGeneralSitePreferencesFormReturn;
}

export const SystemPeriods: React.FC<Props> = ({
  isFetching,
  type,
  attributesForm,
}): ReactElement => {
  const { t } = useCommonTranslation();

  const {
    formState: { errors },
    attributeRegister,
    control,
  } = attributesForm;
  const watchedValues = useWatch({ control });

  const greaterOrEqualZeroValidation = {
    min: {
      value: 0,
      message: t('must-greater-or-equal-zero'),
    },
  };

  return (
    <DialogFormWrapper>
      <FormHeader>
        <FaClock />
        <FormTitle>{t('system-periods')}</FormTitle>
      </FormHeader>
      <FormGroupTitle>{t('staff-booking-forward-period')}</FormGroupTitle>
      <FormRow>
        <Input
          type="number"
          label={t('earliest-staff-booking-after')}
          placeholder={t('earliest-staff-booking-after')}
          {...attributeRegister(
            STAFF_EARLIEST_BOOKING_AFTER_IN_DAYS,
            (e) => e.target.value,
            greaterOrEqualZeroValidation,
          )}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === STAFF_EARLIEST_BOOKING_AFTER_IN_DAYS,
            )?.value_str
          }
          hasError={Boolean(
            errors[
              (ATTRIBUTE_SEPARATOR +
                STAFF_EARLIEST_BOOKING_AFTER_IN_DAYS) as 'attributes'
            ],
          )}
          helperText={
            errors[
              (ATTRIBUTE_SEPARATOR +
                STAFF_EARLIEST_BOOKING_AFTER_IN_DAYS) as 'id'
            ]?.message
          }
          disabled={type === TYPE_STAFF}
        />
        <Input
          type="number"
          label={t('latest-staff-booking-before')}
          placeholder={t('latest-staff-booking-before')}
          {...attributeRegister(
            STAFF_LATEST_BOOKING_BEFORE_IN_DAYS,
            (e) => e.target.value,
            {
              ...greaterOrEqualZeroValidation,
            },
          )}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === STAFF_LATEST_BOOKING_BEFORE_IN_DAYS,
            )?.value_str
          }
          hasError={Boolean(
            errors[
              (ATTRIBUTE_SEPARATOR +
                STAFF_LATEST_BOOKING_BEFORE_IN_DAYS) as 'attributes'
            ],
          )}
          helperText={
            errors[
              (ATTRIBUTE_SEPARATOR +
                STAFF_LATEST_BOOKING_BEFORE_IN_DAYS) as 'id'
            ]?.message
          }
        />
      </FormRow>
      <FormGroupTitle>{t('patients-booking-forward-period')}</FormGroupTitle>
      <FormRow>
        <Input
          type="number"
          label={t('earliest-patient-booking-after')}
          placeholder={t('earliest-patient-booking-after')}
          {...attributeRegister(
            PATIENTS_EARLIEST_BOOKING_AFTER_IN_DAYS,
            (e) => e.target.value,
            greaterOrEqualZeroValidation,
          )}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === PATIENTS_EARLIEST_BOOKING_AFTER_IN_DAYS,
            )?.value_str
          }
          hasError={Boolean(
            errors[
              (ATTRIBUTE_SEPARATOR +
                PATIENTS_EARLIEST_BOOKING_AFTER_IN_DAYS) as 'attributes'
            ],
          )}
          helperText={
            errors[
              (ATTRIBUTE_SEPARATOR +
                PATIENTS_EARLIEST_BOOKING_AFTER_IN_DAYS) as 'id'
            ]?.message
          }
          disabled={type === TYPE_STAFF}
        />
        <Input
          type="number"
          label={t('latest-patient-booking-before')}
          placeholder={t('latest-patient-booking-before')}
          {...attributeRegister(
            PATIENTS_LATEST_BOOKING_BEFORE_IN_DAYS,
            (e) => e.target.value,
            greaterOrEqualZeroValidation,
          )}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === PATIENTS_LATEST_BOOKING_BEFORE_IN_DAYS,
            )?.value_str
          }
          hasError={Boolean(
            errors[
              (ATTRIBUTE_SEPARATOR +
                PATIENTS_LATEST_BOOKING_BEFORE_IN_DAYS) as 'attributes'
            ],
          )}
          helperText={
            errors[
              (ATTRIBUTE_SEPARATOR +
                PATIENTS_LATEST_BOOKING_BEFORE_IN_DAYS) as 'id'
            ]?.message
          }
        />
      </FormRow>
      <FormGroupTitle>{t('appointments')}</FormGroupTitle>
      <FormRow>
        <Input
          type="number"
          {...attributeRegister(
            MAX_APPT_WEEK,
            (e) => e.target.value,
            greaterOrEqualZeroValidation,
          )}
          value={
            watchedValues.attributes?.find((atr) => atr.key === MAX_APPT_WEEK)
              ?.value_str
          }
          label={t('max-appt-per-week')}
          placeholder={t('max-appt-per-week')}
          hasError={Boolean(
            errors[(ATTRIBUTE_SEPARATOR + MAX_APPT_WEEK) as 'attributes'],
          )}
          helperText={
            errors[(ATTRIBUTE_SEPARATOR + MAX_APPT_WEEK) as 'id']?.message
          }
        />
        <Input
          type="number"
          {...attributeRegister(
            MAX_APPT_ALL,
            (e) => e.target.value,
            greaterOrEqualZeroValidation,
          )}
          value={
            watchedValues.attributes?.find((atr) => atr.key === MAX_APPT_ALL)
              ?.value_str
          }
          label={t('total-max-appt')}
          placeholder={t('total-max-appt')}
          hasError={Boolean(
            errors[(ATTRIBUTE_SEPARATOR + MAX_APPT_ALL) as 'attributes'],
          )}
          helperText={
            errors[(ATTRIBUTE_SEPARATOR + MAX_APPT_ALL) as 'id']?.message
          }
        />
      </FormRow>
      <FormRow>
        <FormGroup>
          <FormGroupTitle>{t('dna')}</FormGroupTitle>
          <FormRow>
            <Input
              type="number"
              {...attributeRegister(
                QUEUE_TO_DNA,
                (e) => e.target.value,
                greaterOrEqualZeroValidation,
              )}
              value={
                watchedValues.attributes?.find(
                  (atr) => atr.key === QUEUE_TO_DNA,
                )?.value_str
              }
              label={t('marked-as-dna')}
              placeholder={t('marked-as-dna')}
              hasError={Boolean(
                errors[(ATTRIBUTE_SEPARATOR + QUEUE_TO_DNA) as 'attributes'],
              )}
              helperText={
                errors[(ATTRIBUTE_SEPARATOR + QUEUE_TO_DNA) as 'id']?.message
              }
            />
          </FormRow>
        </FormGroup>
      </FormRow>
      {isFetching && <CardLoader fillWrapper={true} />}
    </DialogFormWrapper>
  );
};
