import { ReportResourceTypeMap } from 'types/reports';
import { StatisticsInfoRow } from './Reports.styled';
import { StatisticsInfoItem } from './StatisticsInfoItem';

type ResourceTypesProps = {
  resourceTypes: ReportResourceTypeMap;
};
export const ResourceTypes = ({ resourceTypes }: ResourceTypesProps) => {
  return (
    <StatisticsInfoRow>
      {Object.keys(resourceTypes).map((resourceType) => (
        <StatisticsInfoItem key={resourceType}>
          {resourceType}: {resourceTypes[resourceType]}
        </StatisticsInfoItem>
      ))}
    </StatisticsInfoRow>
  );
};
