import { useBookPatientAppointment } from 'hooks/patient/booking/useBookPatientAppointment';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { Appointment } from 'types/appointments';
import { padTime } from 'utils/dateUtil';
import { PatientBookingListItem } from './PatientBookingListItem';

export type PatientBookingApptItemProps = {
  appt: Appointment | undefined;
  hour: number;
  minute: number;
  length: number;
};
export const PatientBookingApptItem = ({
  appt,
  hour,
  minute,
  length,
}: PatientBookingApptItemProps) => {
  const { t } = useCommonTranslation();
  const bookAppt = useBookPatientAppointment(appt);
  const nextMinutesOverflow = minute + length;
  const nextMinutes =
    nextMinutesOverflow >= 60 ? nextMinutesOverflow - 60 : nextMinutesOverflow;
  const nextHour = nextMinutesOverflow >= 60 ? hour + 1 : hour;

  return (
    <PatientBookingListItem
      disabled={!appt}
      range={`${padTime(hour)}:${padTime(minute)} - ${padTime(
        nextHour,
      )}:${padTime(nextMinutes)}`}
      text={appt ? t('appointment-available') : t('appointment-not-available')}
      btnText={t('book')}
      btnOnClick={bookAppt}
    />
  );
};
