import { PatientLayout } from 'containers/PatientLayout';
import { useAuth } from 'context/AuthContext';
import { SupportCard } from './SupportCard';

export const UnAuthAndPatientSupportFormPage = () => {
  const { sangixUser } = useAuth();

  return (
    <PatientLayout graphics={Boolean(sangixUser) ? 'patient' : 'unauth'}>
      <SupportCard />
    </PatientLayout>
  );
};
