import { AxiosError } from 'axios';
import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { Card } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import { Col, Grid } from 'components/common/Grid';
import { PageHelmet } from 'components/common/PageHelmet';
import { ConfirmDialog } from 'components/Popup/ConfirmDialog/ConfirmDialog';
import { TableActions } from 'components/Table/Table.styled';
import OtherReferrersForm from 'components/views-components/staff/settings/otherReferrers/OtherReferrersForm';
import { OtherReferrersTable } from 'components/views-components/staff/settings/otherReferrers/OtherReferrersTable';
import { StaffSettingsTabsTree } from 'configs/RoutesConfig';
import { useSite } from 'context/SiteContext';
import { useEffect, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import {
  OtherReferrer,
  OtherReferrersFilters,
  OtherReferrersFormValues,
} from 'types/otherReferrers';
import { errorToast, successToast } from 'utils/toast';
import { StaffLayout } from '../../StaffLayout';
import { usePaginatedFilters } from '../../../../hooks/usePaginatedFilters';
import {
  useMutateCreateReferrer,
  useMutateDeleteReferrer,
  useMutateUpdateReferrer,
  useReferrers,
} from '../../../../query/referrers';
import { gpSurgeryRequiredTrust } from '../../../../utils/trustUtil';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useHistory } from 'react-router';
import { STAFF_OTHER_REFERRERS_MERGE_URL } from '../../../../routes/AppRoutes';
import { useConfirmDialog } from '../../../../components/Popup/ConfirmDialog/confirmDialogHook';
import { checkErrorCode } from '../../../../utils/errors';
import { ErrorCode } from '../../../../utils/errorCodes';

export const OtherReferrers = () => {
  const { t } = useCommonTranslation();
  const { activeSite, activeTrust } = useSite();
  const { push } = useHistory();
  const { filters, setFilters } = usePaginatedFilters<OtherReferrersFilters>();
  const {
    isLoading: isListLoading,
    isFetching: isListFetching,
    data,
  } = useReferrers(filters);
  const { isLoading: isUpdateLoading, mutate: update } =
    useMutateUpdateReferrer({
      onSuccess: () => {
        successToast(t('changes-have-been-saved'));
        closeFormHandler();
      },
    });
  const { isLoading: isCreateLoading, mutate: create } =
    useMutateCreateReferrer({
      onSuccess: () => {
        successToast(t('referrer-added'));
        closeFormHandler();
      },
    });
  const { confirm } = useConfirmDialog();
  const { isLoading: isDeleteLoading, mutate: deleteItem } =
    useMutateDeleteReferrer({
      onError: (err: AxiosError) => {
        if (
          checkErrorCode(
            err,
            ErrorCode.OTHER_REFERRER_USED_BY_OTHER_DATA_RE0003,
          )
        ) {
          confirm({
            title: t('delete-other-referrer-used-dialog-title'),
            confirmButtonText: t('delete-other-referrer-go-to-merge'),
            icon: <FaTrashAlt />,
            onConfirm: () => {
              push(STAFF_OTHER_REFERRERS_MERGE_URL);
            },
          });
        } else {
          errorToast(err);
        }
      },
      onSuccess: () => {
        successToast(t('deleted-referrer'));
        closeFormHandler();
      },
    });

  const isLoading =
    isCreateLoading || isUpdateLoading || isListLoading || isDeleteLoading;

  const [showForm, setShowForm] = useState(false);
  const [prefilledData, setPrefilledData] = useState<
    OtherReferrer | undefined
  >();
  const [confirmDeleteItem, setConfirmDeleteItem] =
    useState<OtherReferrer | null>();

  const submitHandler: (
    data: OtherReferrersFormValues,
    isUpdateReferrer: boolean,
  ) => void = (data, isUpdateReferrer) => {
    const structuredData: OtherReferrersFormValues = {
      ...data,
      trust_id: activeSite?.trust_id as string,
    };

    Boolean(isUpdateReferrer) && Boolean(prefilledData)
      ? update({ ...prefilledData, ...structuredData })
      : create(structuredData);
  };

  const closeFormHandler = (err?: AxiosError | true) => {
    setShowForm(false);
    setPrefilledData(undefined);
    setConfirmDeleteItem(null);
    if (err) {
      errorToast(err);
    }
  };

  const openFormHandler = (value?: string) => {
    setShowForm(false);
    setTimeout(() => {
      if (value) {
        const selectedReferrer = data?.data?.find(
          (referrer) => referrer.id === value,
        );

        if (selectedReferrer) {
          setPrefilledData(selectedReferrer);
          setShowForm(true);
        } else {
          closeFormHandler(true);
        }
      } else {
        setPrefilledData(undefined);
        setShowForm(true);
      }
    }, 50);
  };

  const handleDeleteReferrer = (value: string) => {
    setConfirmDeleteItem(data?.data?.find((referrer) => referrer.id === value));
  };

  const handleConfirmDeleteReferrer = () => {
    deleteItem(confirmDeleteItem?.id as string);
  };

  useEffect(() => {
    closeFormHandler();
  }, [activeSite?.id, filters]);

  return (
    <StaffLayout>
      <PageHelmet title={t('other-referrers')} />
      <Grid>
        <Col md={8}>
          <Card fillHeight flex>
            {gpSurgeryRequiredTrust(activeTrust) && (
              <LinkCardTabs withDivider tabs={StaffSettingsTabsTree(t)} />
            )}
            <TableActions>
              <CommonButton variant="primary" onClick={() => openFormHandler()}>
                {t('add-referrer')}
              </CommonButton>
              <CommonButton
                variant="primary"
                onClick={() => push(STAFF_OTHER_REFERRERS_MERGE_URL)}
              >
                {t('other-referrers-merge')}
              </CommonButton>
            </TableActions>
            <OtherReferrersTable
              otherReferrersData={data}
              openFormHandler={openFormHandler}
              handleDeleteReferrer={handleDeleteReferrer}
              setFilters={setFilters}
              isLoading={isListLoading}
              isFetching={isListFetching}
              filters={filters}
            />
          </Card>
        </Col>
        <Col md={4}>
          {showForm && (
            <OtherReferrersForm
              closeFormHandler={closeFormHandler}
              submitHandler={submitHandler}
              isLoading={isLoading}
              prefilledData={prefilledData}
              handleDeleteReferrer={handleDeleteReferrer}
            />
          )}
        </Col>
      </Grid>
      {Boolean(confirmDeleteItem) && (
        <ConfirmDialog
          isLoading={isLoading}
          handleClose={() => closeFormHandler()}
          icon={<FaTrashAlt />}
          title={t('confirm-delete-referrer')}
          status="delete"
          actions={[
            <CommonButton
              variant="secondary"
              onClick={() => {
                setConfirmDeleteItem(null);
              }}
              key={confirmDeleteItem?.id + '-cancel'}
            >
              {t('cancel')}
            </CommonButton>,
            <CommonButton
              variant="danger"
              onClick={handleConfirmDeleteReferrer}
              key={confirmDeleteItem?.id + '-delete'}
            >
              {t('delete')}
            </CommonButton>,
          ]}
        />
      )}
    </StaffLayout>
  );
};
